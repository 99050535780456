import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import {
  DetailContainer,
  Banner,
  MainDetail,
  InvestDetail,
  CampaignInfo,
  ComplementaryInfo,
  CardStatus,
  MainInfo,
  CardIcons,
  FilterSection,
  FilterButton
} from './campaignsDash.styles';
import { getApplicantProfile } from 'src/graphql/customized_queries';
import { ModalCampaignSuccess } from 'src/components/shared/modals/CampaignSuccess/campaignSuccess.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import CancelCampaignModal from 'src/components/shared/modals/cancelCampaign/campaignCanceled';

export default function CampaignsDash() {
  const [campaignData, setCampaignData] = useState(null);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [campaignCreated, setCampaignCreated] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [filter, setFilter] = useState('all');
  const navigate = useNavigate();
  const campaignId = useSelector((state) => state.campaign.currentCampaignId);
  const user = useSelector((state) => state.user.currentUser);
  const location = useLocation();
  const url = location.state;
  const handleConfirm = () => {
    setCampaignCreated(true);
    // navigate('/solicitante/dashboard');
  };

  function getDaysLeft(startDateString, endDateString) {
    const today = new Date();
    const endDate = new Date(endDateString);
    let todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    let endDateDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const diffTime = Math.abs(endDateDate - todayDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }

  const percentageFinanced = (item) => {
    return item.collectedAmount >= 0
      ? Math.round((item.collectedAmount / item.montoSolicitado) * 100)
      : 0;
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const userId = user.id;
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await API.graphql({
          query: getApplicantProfile,
          variables: { id: userId }
        });
        const campaign = data?.data?.getApplicant?.campaigns?.items;
        setCampaignData(campaign);
      } catch (error) {
        console.error(error);
      }
    };
    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  return (
    <DetailContainer>
      <ModalCampaignSuccess
        showModalCampaignSuccess={campaignCreated}
        setCampaignCreated={setCampaignCreated}
      />
      <CancelCampaignModal
        showCancelCampaign={showCancelModal}
        setCampaignCancel={setShowCancelModal}
        campaignId={selectedCampaignId}
      />
      <Banner>
        <h2>Mis campañas</h2>
      </Banner>
      <FilterSection>
        <p>Filtrar por:</p>
        <FilterButton isActive={filter === 'all'} onClick={() => handleFilterChange('all')}>
          Todas
        </FilterButton>
        <FilterButton isActive={filter === 'active'} onClick={() => handleFilterChange('active')}>
          Activas
        </FilterButton>
        <FilterButton isActive={filter === 'expired'} onClick={() => handleFilterChange('expired')}>
          Cerradas
        </FilterButton>
      </FilterSection>

      {campaignData &&
        campaignData.map((item, index) => {
          if (
            filter === 'all' ||
            (filter === 'active' && item.isExpired === '0') ||
            (filter === 'expired' && item.isExpired === '1')
          ) {
            return (
              <MainDetail key={index}>
                {item.isExpired === '0' ? (
                  <CardIcons>
                    <div>
                      <h3>
                        Días de vigencia de la campaña:{' '}
                        {getDaysLeft(
                          formatDate(item.dateStartCampaign),
                          formatDate(item.dateEndCampaign)
                        )}
                      </h3>
                      <Button
                        size="small"
                        onClick={() => {
                          setShowCancelModal(true);
                          setSelectedCampaignId(item.id);
                        }}
                      >
                        Quiero cancelar campaña
                      </Button>
                    </div>
                    <div>
                      <img
                        src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
                        alt="icono de éxito"
                      />
                      <h2>VIGENTE</h2>
                    </div>
                  </CardIcons>
                ) : (
                  <CardIcons>
                    <div>
                      <p>Tu campaña expira el día:</p>
                      <h3>{formatDate(item.dateEndCampaign)}</h3>
                    </div>
                    <div>
                      <img
                        src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_error.png`}
                        alt="icono de aviso"
                      />
                      <h2>CERRADA</h2>
                    </div>
                  </CardIcons>
                )}
                <InvestDetail>
                  <div>
                    <h2>Nombre de campaña</h2>
                    <p>{item.nombreCampania}</p>
                  </div>
                  <div>
                    <h2>Monto a financiar</h2>
                    <p>{formatCurrency(item.montoSolicitado)}</p>
                  </div>
                </InvestDetail>
                <ComplementaryInfo>
                  <div>
                    <h2>Destino del crédito:</h2>
                    <p>{item.destinoCredito}</p>
                  </div>
                  <div>
                    <h2>Plazo en días:</h2>
                    <p>{item.plazoCredito}</p>
                  </div>
                  <div>
                    <h2>Fecha de publicación:</h2>
                    <p>{formatDate(item.dateStartCampaign)}</p>
                  </div>
                  <div>
                    <h2>Fuente de pago:</h2>
                    <p>{item.fuentePago}</p>
                  </div>
                  {item.isActive === '1' ? (
                    <>
                      <div>
                        <h2>Pagaré:</h2>
                        {item.isPromissoryNoteSigned === '1' ? <p>Firmado</p> : <p>No Firmado</p>}
                      </div>
                      <div>
                        <h2>Monto recaudado:</h2>
                        <p>
                          {item.collectedAmount === -1
                            ? '0.00'
                            : formatCurrency(item.collectedAmount)}
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <h2>Pagaré:</h2>
                        {item.isPromissoryNoteSigned === '1' ? <p>Firmado</p> : <p>No firmado</p>}
                      </div>
                      <div>
                        <h2>Monto final recaudado:</h2>
                        <p>{formatCurrency(item.collectedAmount)}</p>
                      </div>
                    </>
                  )}
                </ComplementaryInfo>
                <CampaignInfo>
                  <Button size="small" onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Ver menos' : 'Ver más'}
                  </Button>
                  <MainInfo>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <th>Tasa de interés del crédito:</th>
                            <td>
                              {Number(item.tasaCreditoPorcentaje * 100).toFixed(2)
                                ? Number(item.tasaCreditoPorcentaje * 100).toFixed(2)
                                : '-'}{' '}
                              %
                            </td>
                          </tr>
                          <tr>
                            <th>Interes del crédito en pesos:</th>
                            <td>
                              {formatCurrency(item.interesCreditoPesos)
                                ? formatCurrency(item.interesCreditoPesos)
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA del interes del crédito en pesos:</th>
                            <td>
                              {formatCurrency(item.IVAinteresCreditoPesos)
                                ? formatCurrency(item.IVAinteresCreditoPesos)
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <th>Interes del crédito en pesos + IVA:</th>
                            <td>
                              {formatCurrency(item.interesesCreditoPesosConIVA)
                                ? formatCurrency(item.interesesCreditoPesosConIVA)
                                : '-'}{' '}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <table>
                        <tbody>
                          {showMore && (
                            <>
                              <tr>
                                <th>Tasa de interés adicional:</th>
                                <td>
                                  {item.tasaInteresAdicionalPorcentaje * 100
                                    ? item.tasaInteresAdicionalPorcentaje * 100
                                    : '-'}{' '}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <th>Tasa de interés moratoria:</th>
                                <td>
                                  {Number(item.tasaInteresMoratorioPorcentaje * 100).toFixed(2)
                                    ? Number(item.tasaInteresMoratorioPorcentaje * 100).toFixed(2)
                                    : '-'}{' '}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <th>Comisión del crédito:</th>
                                <td>
                                  {formatCurrency(item.comisionCreditoPesos)
                                    ? formatCurrency(item.comisionCreditoPesos)
                                    : '-'}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>IVA de la comisión del crédito:</th>
                                <td>
                                  {formatCurrency(item.IVAcomisionCreditoPesos)
                                    ? formatCurrency(item.IVAcomisionCreditoPesos)
                                    : '-'}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>Comisión del crédito + IVA:</th>
                                <td>
                                  {formatCurrency(item.comisionCreditoPesosConIVA)
                                    ? formatCurrency(item.comisionCreditoPesosConIVA)
                                    : '-'}{' '}
                                </td>
                              </tr>
                              <tr>
                                <th>Porcentaje de inversión de Finsphera en la campaña:</th>
                                <td>
                                  {item.inversionFinspheraCampaniaPorcentaje
                                    ? item.inversionFinspheraCampaniaPorcentaje * 100
                                    : '-'}{' '}
                                  %
                                </td>
                              </tr>
                              <tr>
                                <th>Monto de inversión de Finsphera en la campaña:</th>
                                <td>
                                  {formatCurrency(item.inversionFinspheraCampaniaPesos)
                                    ? formatCurrency(item.inversionFinspheraCampaniaPesos)
                                    : '-'}{' '}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </MainInfo>
                  <CardStatus percentage={percentageFinanced(item)}>
                    <h3>% financiado</h3>
                    <div>
                      <div></div>
                    </div>
                  </CardStatus>
                </CampaignInfo>
              </MainDetail>
            );
          } else {
            return null;
          }
        })}
    </DetailContainer>
  );
}
