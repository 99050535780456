import Modal from 'src/utilities/UILibrary/Feedback/Modal/Modal';
import styled from 'styled-components/macro';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { greenSp } from 'src/utilities/UIStyles/variables/colors';

export const ComposedModalWrapper = styled(Modal)`
  a {
    color: ${greenSp};
    font-weight: bold;
  }
  .modal-header {
    img {
      width: 5rem;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .modal-header {
      img {
        width: 7rem;
      }
    }
  }
`;
