// Color palette
//Brand
export const brandYellowgreen = '#E7FD4F';
export const brandBlack = '#0C0C0C';
export const whiteSphera = '#FFFFFF';

//Neutrals
export const neutralGray = '#333333';
export const neutralBlueGray = '#576177';
export const neutralBlue = '#C5D0E5';
export const neutralLight = '#F8F8F9';

//Supporting Colors
export const supportOliveGreen = '#7E9900';
export const supportLemonGreen = '#B2CA06';
export const supportDarkGreen = '#003200';
export const supportHunterGreen = '#245D2A';
export const supportMiddleGreen = '#528B54';

// Complementary
export const error = 'rgb(255,0,0)';
export const warning = '#FFA500';
export const blackSphera = '#0C0C0C';
export const greenSp = '#B2CA06';
export const grayLight = '#E5E5E5';

export const yellowSphera = '#F8AD03';

export const newYellowSphera = '#E7FD4F';

export const blueSphera = '#46C0EF';

export const blueDarkSphera = '#00A0D8';

export const graySphera = '#576176';

export const grayLightSphera = '#8C96AA';

export const grayMediumSphera = '#455A64';

export const grayDarkSphera = '#2A3248';

export const grayPlaceholder = '#C5D0E5';
