import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import {
  creditCircle,
  literaxCreditCircle,
  literaxSatws,
  setLoadingMessage,
  setLoadingState,
  signOutAll,
  simafReq,
  updateApplicantOnboarding
} from 'src/store/User/user.slice';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { CreditInformationContainer, Banner, Container } from './creditInformation.styles';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import governanceOptions from 'src/constants/corporateGovernance';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import ModalErrorUploadingInput from 'src/components/shared/modals/errorUploadingInput/errorUploadingInput';
import { getAddress_creditInfo, getApplicant_creditInfo } from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { setAppError } from 'src/store/app/app.slice';
import axios from 'axios';
import { url } from 'src/environments';

const loadingMessage =
  'Estimado usuario, estamos procesando tu información, esto puede tardar unos minutos. Por favor, no cierres esta ventana.';

export default function CreditInformation() {
  const [formData, setFormData] = useState({
    distributionChannel: [],
    industryActivity: []
  });
  const [errorUploadingInput, setErrorUploadingInput] = useState(false);
  const [, setUserData] = useState(null);
  const [showCreditModal, setShowCreditModal] = useState(false);
  const [isAtLeastOneDistributionChecked, setIsAtLeastOneDistributionChecked] = useState(false);
  const [isAtLeastOneIndustryChecked, setIsAtLeastOneIndustryChecked] = useState(false);
  const { nextStep } = useManageSteps();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const handleData = (value, metadata) => {
    const { name, type } = metadata;
    if (type === 'checkbox') {
      setFormData((prevState) => {
        const updatedArray = prevState[name] ? prevState[name].slice() : [];
        if (updatedArray.includes(value)) {
          const index = updatedArray.indexOf(value);
          updatedArray.splice(index, 1);
        } else {
          updatedArray.push(value);
        }
        return { ...prevState, [name]: updatedArray };
      });
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors }
  } = useForm();
  //Genera los archivos de Literax
  useEffect(() => {
    const body = {
      id: user.id,
      typeOfUser: 'applicant',
      //Timestamp sólo aplica para el endpoint de literax/emision/mati-files
      timestamp: Date.now()
    };
  }, []);

  const handleDataDistribution = (e) => {
    const { name, checked } = e.target;
    const group = 'distributionChannel';
    if (e.target.type === 'checkbox') {
      setFormData((prevState) => {
        const updatedArray = prevState[group] ? prevState[group].slice() : [];
        if (checked) {
          updatedArray.push(name);
        } else {
          const index = updatedArray.indexOf(name);
          updatedArray.splice(index, 1);
        }
        setIsAtLeastOneDistributionChecked(updatedArray.length > 0);
        return { ...prevState, [group]: updatedArray };
      });
    }
  };

  const handleDataindustryActivity = (e) => {
    const { name, checked } = e.target;
    const group = 'industryActivity';
    if (e.target.type === 'checkbox') {
      setFormData((prevState) => {
        const updatedArray = prevState[group] ? prevState[group].slice() : [];
        if (checked) {
          updatedArray.push(name);
        } else {
          const index = updatedArray.indexOf(name);
          updatedArray.splice(index, 1);
        }
        setIsAtLeastOneIndustryChecked(updatedArray.length > 0);
        return { ...prevState, [group]: updatedArray };
      });
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getApplicant_creditInfo,
          variables: { id: user.id }
        });
        setUserData(data.getApplicant);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getAddress_creditInfo,
          variables: { id: `${user.id}_c` }
        });
        setUserData(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, []);

  const closeFlow = () => {
    navigate('/');
  };

  const handleFormSubmit = async (dataFromReactHookForm) => {
    const finalData = {
      ...formData,
      ...dataFromReactHookForm
    };
    formData.userId = user.id;
    const inputCreditAutofill = {
      id: user.id,
      maximumPaymentTerm: formData.termLoan,
      requestedCredit: formData.requestedAmount,
      folioOtorgante: '1000001',
      persona: {
        RFC: 'EDC930121E01',
        nombre: 'RESTAURANTE SA DE CV',
        domicilio: {
          direccion: 'AV. PASEO DE LA REFORMA 01',
          CP: '68370',
          coloniaPoblacion: 'GUERRERO',
          delegacionMunicipio: 'CUAUHTEMOC',
          ciudad: 'CIUDAD DE MEXICO',
          estado: 'CDMX',
          pais: 'MX'
        }
      }
    };
    const satwsLiterax = {
      id: user.id,
      RFC: 'TQX961126ZT7',
      timestamp: String(Date.now())
    };
    const inputGovernance = {
      id: user.id,
      independenceDirectorGeneralValue: finalData.independenceDirectorGeneralValue === 'yes',
      experienceOfMainShareholderValue: finalData.experienceOfMainShareholder,
      independetConselorsValue: finalData.independetConselors,
      numberOfCommitteesValue: finalData.numberOfCommittees
    };
    const updateInput = {
      id: user.id,
      requestedAmount: formData.requestedAmount,
      termLoan: formData.termLoan,
      loanDestination: formData.loanDestination,
      distributionChannel: formData.distributionChannel,
      industryActivity: formData.industryActivity
    };
    const creditBody = {
      id: user.id,
      typeOfUser: 'applicant',
      timestamp: String(Date.now())
    };
    const amount = {
      id: user.id
    };
    try {
      dispatch(setLoadingState(true));
      setLoadingMessage(loadingMessage);
      const updateResponse = await dispatch(updateApplicantOnboarding(updateInput)).unwrap();
      const amountResponse = await axios.post(
        `${url}/satws/credit-analysis/approved-amount`,
        amount
      );
      dispatch(simafReq(`${user.id}_c`));
      const creditResponse = await dispatch(
        creditCircle({ inputCreditAutofill, inputGovernance })
      ).unwrap();
      if (creditResponse) {
        const literaxCreditResponse = await dispatch(literaxCreditCircle(creditBody)).unwrap();
        if (literaxCreditResponse) {
          dispatch(literaxSatws(satwsLiterax));
        }
      } else {
        setAppError();
      }
      const creditResultObject = creditResponse.find((response) => response.creditResult);
      if (creditResultObject) {
        const creditResultBody = JSON.parse(creditResultObject.creditResult.body);
        if (creditResultBody.isApproved === false) {
          setShowCreditModal(true);
        } else {
          await nextStep();
          dispatch(setLoadingState(false));
          navigate('/solicitante/onboarding-perfil-uso');
        }
      } else {
        console.error('Credit result data not found');
        setErrorUploadingInput(true);
      }
    } catch (error) {
      dispatch(setLoadingState(false));
      console.error(error);
      setErrorUploadingInput(true);
    }
  };

  const options = [
    { value: 'NOMINA', label: 'Nómina' },
    { value: 'COMPRAS', label: 'Compras' },
    { value: 'PROVEEDORES', label: 'Proveedores' },
    { value: 'SERVICIOS', label: 'Servicios' }
  ];

  //Constants Governance
  const [experienceOfMainShareholder, independetConselors, numberOfCommittees] = governanceOptions;
  const experienceOfMainShareholderOptions = experienceOfMainShareholder.map(
    (experienceShareholder) => ({
      value: experienceShareholder,
      label: experienceShareholder
    })
  );
  const independetConselorsOptions = independetConselors.map((conselor) => ({
    value: conselor,
    label: conselor
  }));
  const numberOfCommitteesOptions = numberOfCommittees.map((numberOfCommittees) => ({
    value: numberOfCommittees,
    label: numberOfCommittees
  }));

  const handleClick = () => {
    dispatch(signOutAll());
  };
  const generateModalMessage = () => {
    return (
      <>
        <p>
          Lamentamos informarte que no alcanzaste la calificación mínima para tomar crédito a través
          de Finpshera. Tu cuenta se eliminará automáticamente en las siguientes 24hrs. Si tienes
          alguna duda o comentario escríbenos a:{' '}
          <Link to="mailto:atencionaclientes@finsphera.com"> atencionaclientes@finsphera.com</Link>
        </p>
      </>
    );
  };
  return (
    <CreditInformationContainer>
      <ModalErrorUploadingInput
        showModalErrorUploadingInput={errorUploadingInput}
        setErrorUploadingInput={setErrorUploadingInput}
      />
      <ComposedModal
        messageTitle={'Solictud de crédito no aprobada'}
        messageChildren={generateModalMessage()}
        type="error"
        isOpen={showCreditModal}
        onClose={() => handleClick}
        buttons={[
          {
            label: 'Continuar',
            onClick: () => handleClick,
            color: 'green'
          }
        ]}
        isCloseButton={false}
        onOverlayClose={false}
      />
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_personalInformation2_banner.png`}
            alt="ícono información personal"
          />
        </figure>
        <h2>Solicitud de crédito:</h2>
      </Banner>
      <Card className="card-container" isForm onSubmit={handleSubmit(handleFormSubmit)}>
        <Card.Row>
          <h2>Infomación general:</h2>
        </Card.Row>
        <Card.Row>
          <InputField
            onChangeInternalValue={handleData}
            label="Monto máximo que vas a solicitar:"
            type="number"
            name="requestedAmount"
            placeholder="Monto"
            min={250000}
            max={12000000}
            required
          />
          <InputField
            onChangeInternalValue={handleData}
            name="loanDestination"
            isDropdown
            options={options}
            placeholder="Selecciona una opción"
            label="¿En que utilizarás este crédito?"
            required
          />
        </Card.Row>
        <Card.Row>
          <InputField
            onChangeInternalValue={handleData}
            label="Máximo número de días para pagar tus créditos (20-150):"
            type="number"
            name="termLoan"
            placeholder="Días"
            min={20}
            max={150}
            required
            wrapLabel
          />
        </Card.Row>
        <Card.Row>
          <h2>
            ¿Qué canales de distribución y comercialización utilizas? (puedes elegir una o varias
            opciones):
          </h2>
        </Card.Row>
        <Card.Row itemPosition="left">
          <Checkbox
            onChange={handleDataDistribution}
            label="a. Los clientes compran en mis tiendas"
            type="checkbox"
            name="storeSales"
          />
          <Checkbox
            onChange={handleDataDistribution}
            label="b. Los clientes compran en mis almacenes"
            type="checkbox"
            name="warehouseSales"
          />
          <Checkbox
            onChange={handleDataDistribution}
            label="c. Visito a mis clientes en sus oficinas y ahí me compran"
            type="checkbox"
            name="personalSales"
          />
          <Checkbox
            onChange={handleDataDistribution}
            label="d. Vendo por teléfono"
            type="checkbox"
            name="phoneSales"
          />
          <Checkbox
            onChange={handleDataDistribution}
            label="e. Vendo a través de mi página web"
            type="checkbox"
            name="webSales"
          />
          <Checkbox
            onChange={handleDataDistribution}
            label="f. Otro."
            type="checkbox"
            name="other"
          />
        </Card.Row>
        <Card.Row>
          <h2>
            ¿Qué función realizas en la industria en la que participas? (puedes elegir una o varias
            opciones):
          </h2>
        </Card.Row>
        <Card.Row itemPosition="left">
          <Checkbox
            onChange={handleDataindustryActivity}
            label="a. Soy productor"
            name="producer"
          />
          <Checkbox
            onChange={handleDataindustryActivity}
            label="b. Soy comercializador al mayoreo"
            name="wholesaler"
          />
          <Checkbox
            onChange={handleDataindustryActivity}
            label="c. Soy comercializador al menudeo"
            name="retailTrader"
          />
          <Checkbox onChange={handleDataindustryActivity} label="d. Otro." name="other" />
        </Card.Row>
        <Card.Row>
          <Controller
            name="experienceOfMainShareholder"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field }) => (
              <InputField
                onChangeInternalValue={field.onChange}
                isDropdown
                options={experienceOfMainShareholderOptions}
                placeholder="Selecciona una opción"
                label="Número de años que el accionista principal tiene en la Pyme:"
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="independetConselors"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field }) => (
              <InputField
                onChangeInternalValue={field.onChange}
                isDropdown
                options={independetConselorsOptions}
                placeholder="Selecciona una opción"
                label="Porcentaje de Consejeros Independientes vs el total de Consejeros:"
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="numberOfCommittees"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field }) => (
              <InputField
                onChangeInternalValue={field.onChange}
                isDropdown
                options={numberOfCommitteesOptions}
                placeholder="Selecciona una opción"
                label="Número de Comités dentro de la PyME:"
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <p>
            <strong>¿El Director General también es accionista de la Pyme?:</strong>
          </p>
          <div className="radio-group">
            <p>Sí</p>
            <input type="radio" value="yes" {...register('independenceDirectorGeneralValue')} />
            <p>No</p>
            <input type="radio" value="no" {...register('independenceDirectorGeneralValue')} />
          </div>
        </Card.Row>
        <Card.Row>
          <Button size="small" onClick={closeFlow}>
            Terminar después
          </Button>
          <Button
            size="small"
            type="submit"
            disabled={!isAtLeastOneDistributionChecked || !isAtLeastOneIndustryChecked}
          >
            Siguiente
          </Button>
        </Card.Row>
      </Card>
      <Container></Container>
    </CreditInformationContainer>
  );
}
