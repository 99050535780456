import styled from 'styled-components';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';

export const InsightsSectionContainer = styled.section`
  margin-bottom: 5rem;
  width: 100%;

  span {
    display: inline;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 3.2rem;
    margin-bottom: 3.475rem;
  }

  .insights__cards-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 1.25rem;
    column-gap: 1.25rem;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 2.5rem;
    }

    .image-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      width: 100%;
      padding: 0 2rem;

      svg,
      img {
        width: 100%;
        height: 100%;
      }
    }

    .body-container {
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      h3,
      p {
        text-align: center;
      }

      h1 {
        font-size: ${uIFontSize.subTitle};
        color: ${colors.text.black};
      }

      p {
        font-size: ${uIFontSize.small};
        color: ${colors.text.gray};
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .insights__cards-section {
      gap: 4rem;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    span {
      display: block;
    }

    margin-bottom: 13.625rem;
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    margin-bottom: 16.625rem;

    h1 {
      font-size: 5rem;
      margin-bottom: 6.875rem;
    }

    .insights__cards-section {
      column-gap: 7.75rem;
    }
  }
`;
