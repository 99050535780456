import { forwardRef, useState } from 'react';
import { InvestmentCampaignDemoProps } from './types';
import { InvestmentCampaignDemoContainer } from './styles';
import { IconBuilding, IconBuildingGreen } from 'src/assets/icons/various';
import { ProgressBar } from 'src/utilities/UILibrary2024/components';

export const InvestmentCampaignDemo = forwardRef<HTMLDivElement, InvestmentCampaignDemoProps>(
  (props, ref) => {
    const { campaignName, percentageToAnimate = 50, mobileView = false, ...rest } = props;
    const [isHovered, setIsHovered] = useState(false);

    return (
      <InvestmentCampaignDemoContainer
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={ref}
        mobileView={mobileView}
        campaignName={campaignName}
        {...rest}
      >
        {isHovered ? (
          <IconBuildingGreen className={`icon ${isHovered ? 'hovered' : ''}`} />
        ) : (
          <IconBuilding />
        )}
        <div className="campaign-info">
          <p className="campaign-info--text">{campaignName}</p>
          <div className="progress-bar-container">
            <ProgressBar
              className="progress-bar"
              size="small"
              actualPercentage={isHovered ? percentageToAnimate : 0}
            />
            {isHovered ? <p>Financiamiento</p> : null}
          </div>
        </div>
        <p className="choose-button">Elegir</p>
      </InvestmentCampaignDemoContainer>
    );
  }
);

InvestmentCampaignDemo.displayName = 'InvestmentCampaignDemo';
