import { url } from 'src/environments';
import axios from 'axios';

export const getUserLastDateLogin = async (userId: string, typeOfUser: string) => {
  const response = await axios.post(`${url}/admin/get-geolocation`, { id: userId, typeOfUser });
  const responseBody = JSON.parse(response.data.body);
  const date = responseBody.date;

  return date;
};
