import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import axios from 'axios';
import {
  ModalWithdrawVirtualAccount,
  DetailWithdraw,
  Success,
  ActionSection,
  Headers
} from './withdrawVirtualAccount.styles';
import { useNavigate } from 'react-router-dom';
import { getApplicantBankAccount, getInvestorBankAccount } from 'src/graphql/customized_queries';
import createNumber from 'src/functions/createNumber';
import { url } from 'src/environments';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export default function WithdrawVirtualAccount({
  showModalWithdrawVirtualAccount,
  setModal,
  amount,
  personalInformationApplicant,
  personalInformation
}) {
  //SET USER TYPE
  const user = useSelector((state) => state.user.currentUser);
  const [userType, setUserType] = useState('');
  //
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [responseId, setResponseId] = useState(null);
  const [available] = useState(true);
  // const closeModal = () => setModal(false);
  const [accountInfo, setAccountInfo] = useState(null);
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const [conceptoPago, setConceptoPago] = useState('');
  const [monto, setMonto] = useState('');
  const [CLABE, setCLABE] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const test = personalInformationApplicant;

  const personalData = personalInformation?.data?.getInvestor?.personalInformation;
  const companyData = personalInformation?.data?.getInvestor?.companyInformation;
  //Se queda esto comentado porque para ambiente prod no se van a harcodear los campos de cuenta
  const stpData = personalInformation?.data?.getInvestor?.STP;
  // const personalDataApplicant = test?.personalInformation;
  const stpDataApplicant = test?.STP;
  const companyName = test?.companyInformation?.companyName?.legalName;
  const companyDataApplicant = test?.companyInformation;

  function formatCurrency(availableAmount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(availableAmount);
  }
  useEffect(() => {
    setUserType(user.groupName);
    const fetchUserData = async () => {
      try {
        let query;
        let dataPath;
        if (user.groupName === 'applicant') {
          query = getApplicantBankAccount;
          dataPath = 'getApplicant';
        } else {
          query = getInvestorBankAccount;
          dataPath = 'getInvestor';
        }
        const { data } = await API.graphql({
          query: query,
          variables: { id: user.id }
        });
        setAccountInfo(data);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id, user.groupName]);

  const closeModal = () => {
    setModal(false);
    user.groupName === 'applicant'
      ? navigate(`/solicitante/dashboard`)
      : navigate(`/inversionista/dashboard`);
  };

  const handleBankChange = (event) => {
    let selectedBank;
    if (user.groupName === 'applicant') {
      selectedBank = accountInfo?.getApplicant?.banks?.items?.find(
        (item) => item.bankName === event.target.value
      );
    } else {
      selectedBank = accountInfo?.getInvestor?.banks?.items?.find(
        (item) => item.bankName === event.target.value
      );
    }
    setSelectedBank(selectedBank);
    if (selectedBank) {
      setSelectedBankCode(selectedBank.bankCode);
      if (selectedBank.CLABE) {
        setCLABE(selectedBank.CLABE);
      } else {
        setCLABE('');
      }
    } else {
      setSelectedBankCode('');
      setCLABE('');
    }
  };
  const bankItems =
    user.groupName === 'applicant'
      ? accountInfo?.getApplicant?.banks?.items
      : accountInfo?.getInvestor?.banks?.items;

  const handleSendEmail = () => {
    let input;
    if (userType === 'applicant') {
      input = {
        id: user.id,
        typeOfUser: 'applicant',
        typeMessage: 'withdrawMoney',
        email: user.email,
        timestamp: Date.now()
      };
    } else if (userType === 'investor') {
      input = {
        id: user.id,
        typeOfUser: 'investor',
        typeMessage: 'withdrawMoney',
        email: user.email,
        timestamp: Date.now()
      };
    }
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setLocalLoading(true);
    let input;
    if (userType === 'applicant') {
      input = {
        id: user.id,
        messageId: messageId,
        code: otp,
        timestamp: Date.now(),
        typeOfUser: 'applicant'
      };
    } else if (userType === 'investor') {
      input = {
        id: user.id,
        messageId: messageId,
        code: otp,
        timestamp: Date.now(),
        typeOfUser: 'investor'
      };
    }
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        alert('Validación de código fallida');
        return;
      }
      let stpBody;
      if (userType === 'applicant') {
        stpBody = {
          clienteId: user.id,
          tipoCliente: 'applicant',
          conceptoPago: conceptoPago,
          cuentaBeneficiario: CLABE,
          institucionContraparte: '90646',
          monto: monto,
          nombreBeneficiario: companyName,
          referenciaNumerica: createNumber(),
          nombreOrdenante: companyName,
          rfcCurpBeneficiario: companyDataApplicant.RFC,
          rfcCurpOrdenante: companyDataApplicant.RFC,
          cuentaOrdenante: stpDataApplicant.CLABE,
          clienteOrdenanteId: user.id
        };
      } else if (userType === 'investor' && user.typeOfPerson === 'FISICA') {
        stpBody = {
          clienteId: user.id,
          tipoCliente: 'investor',
          conceptoPago: conceptoPago,
          cuentaBeneficiario: CLABE,
          institucionContraparte: '90646',
          monto: monto,
          nombreBeneficiario: personalData.fullName,
          referenciaNumerica: createNumber(),
          nombreOrdenante: personalData.fullName,
          rfcCurpBeneficiario: personalData.RFC,
          rfcCurpOrdenante: personalData.RFC,
          cuentaOrdenante: stpData.CLABE,
          clienteOrdenanteId: user.id
        };
      } else if (userType === 'investor' && user.typeOfPerson === 'MORAL') {
        stpBody = {
          cuentaBeneficiario: CLABE,
          nombreBeneficiario: companyData.companyName,
          rfcCurpBeneficiario: companyData.RFC,
          conceptoPago: conceptoPago,
          rfcCurpOrdenante: companyData.RFC,
          nombreOrdenante: companyData.companyName,
          clienteId: user.id,
          tipoCliente: 'investor',
          institucionContraparte: '90646',
          monto: monto,
          referenciaNumerica: createNumber(),
          cuentaOrdenante: stpData.CLABE,
          clienteOrdenanteId: user.id
        };
      }
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registra-orden`,
        headers: {
          apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
          'Content-Type': 'application/json'
        },
        data: stpBody
      };
      try {
        const stpResponse = await axios(config);
        const id = stpResponse.data.id;
        setResponseId(id);
        setLocalLoading(false);
        if (id.toString().length > 6) {
          setSuccessText(true);
          //Agregar aquí lambda de Charles a RETIRADO
        }
      } catch (error) {
        console.error(error);
        setLocalLoading(false);
      }
      setLocalLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ModalWithdrawVirtualAccount showModalWithdrawVirtualAccount={showModalWithdrawVirtualAccount}>
      {!successText ? (
        <div>
          <Headers>
            <h3>Retirar fondos de cuenta virtual Finsphera</h3>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </Headers>
          <DetailWithdraw isAvailable={available}>
            <h3>
              Tu saldo actual: <p>{amount ? formatCurrency(amount) : '0.00'}</p>
            </h3>
            <div>
              <p>Concepto de pago</p>
              <input
                type="text"
                name="conceptoPago"
                placeholder="Descripción"
                value={conceptoPago}
                onChange={(e) => setConceptoPago(e.target.value)}
              />
            </div>
            <div>
              <p>Monto a retirar:</p>
              <input
                type="number"
                name="monto"
                placeholder="$0.00"
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                onBlur={(e) => {
                  let value = parseFloat(e.target.value);
                  if (isNaN(value) || value <= 0) {
                    alert('Por favor ingrese un monto válido.');
                    setMonto('');
                    return;
                  }
                  if (value > amount) {
                    alert('No puedes ingresar un monto mayor a tu saldo disponible');
                    setMonto('');
                    return;
                  }
                  setMonto(value.toFixed(2));
                }}
              />
            </div>
            <div>
              <p>Selecciona a que CLABE deseas retirar:</p>
              <select name="CLABE" value={selectedBank?.bankName || ''} onChange={handleBankChange}>
                <option value="none">Seleccione:</option>
                {bankItems?.map((item, index) => (
                  <option key={index} value={item.bankName}>
                    {`${item.bankName} | ${item.bankCode}`}
                  </option>
                ))}
              </select>
            </div>
          </DetailWithdraw>
          {showInput ? (
            <ActionSection>
              <p>Ingresa el código que enviamos a tu correo:</p>
              <input
                type="text"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <Button onClick={handleOtpSubmit}>Confirmar código</Button>
            </ActionSection>
          ) : (
            <ActionSection>
              <Button isLoading={localLoading} onClick={handleSendEmail}>
                Confirmar retiro
              </Button>
            </ActionSection>
          )}
        </div>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu retiro se ha registrado de forma exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalWithdrawVirtualAccount>
  );
}
