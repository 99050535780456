import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { API } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import ModalError from 'src/components/shared/modals/Error/Error';
//import ErrorCreditAnalysis from 'src/components/modals/creditAnalysis/ModalCreditAnalysis';
import {
  creditAnalysis,
  literaxCreditAnalysis,
  satwsInfo,
  selectIsApproved,
  setLoadingMessage,
  setLoadingState,
  signOutAll,
  updateOnboardingBlockingStatus
} from 'src/store/User/user.slice';
import {
  PersonalInformationContainer,
  Banner,
  FormSection,
  ContactData,
  ConsultCredit,
  BiometricsSection,
  Container
} from './biometrics.styles';
import { url } from 'src/environments';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { FormValues } from './biometrics.types';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { fileToBase64 } from 'src/functions/fileToBase64';
import { useManageSteps } from 'src/hooks/useManageSteps';
import Modal from 'src/utilities/UILibrary/Feedback/Modal/Modal';
import { getApplicantProfile } from 'src/graphql/customized_queries';
import { GetApplicantQuery } from 'src/graphql/API';
import { renderShowInfoModal } from 'src/components/shared/componentFactory/renderShowInfoModal';
import { mutateCreateResourceProvider, sendPLDEmail } from 'src/requests';
import { CountrySelector } from 'src/utilities/UILibrary';
import { blockedCountriesInSpanish } from 'src/constants/blockedCountries';
import economicActivitiesGeneral from 'src/constants/economicActivitiesGeneral';
import economicActivitiesMapping from 'src/constants/economicActivitiesMapping';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { getReasonOfBlocking } from 'src/functions';

const loadingMessage =
  'Estimado usuario, estamos procesando tu información, esto puede tardar unos minutos. Por favor, no cierres esta ventana.';

export default function BiometricsApplicant() {
  //SATWS
  const [type, setType] = useState<string>();
  const [showCreditModal, setShowCreditModal] = useState(false);
  const user = useSelector((state: RootState) => state.user.currentUser);
  const onboarding = user.onboarding;
  const [fullName, setFullName] = useState<string | null>();
  const personalInformation = useSelector(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (state: RootState) => state.profile?.personalInformation as any
  );
  const isApproved = useSelector(selectIsApproved);
  const [showInput, setShowInput] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const [messageId, setMessageId] = useState<string>();
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    setError,
    watch
  } = useForm<FormValues>();
  const { nextStep } = useManageSteps();

  const resourceProviderChecked = watch('resourceProvider');
  const realOwnerChecked = watch('realOwner');
  const economicActivity = watch('economicActivity');
  const optionsBusinessName = economicActivitiesGeneral.map((activity, index) => ({
    value: activity,
    label: activity,
    key: index
  }));

  // This useEffect is TODO
  useEffect(() => {
    if (isApproved) {
      navigate('/solicitante/onboarding-creditInfo');
    }
  }, [isApproved, navigate]);
  const closeFlow = () => {
    navigate('/');
  };

  //FIle to base64
  const handleFile = async (file: File) => {
    if (!file) return;
    try {
      const base64String = await fileToBase64(file);
      return base64String;
      // Aquí puedes hacer uso del base64String, por ejemplo, enviarlo a un servidor
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = (await API.graphql({
          query: getApplicantProfile,
          variables: { id: user.id }
        })) as { data: GetApplicantQuery };
        setFullName(data?.getApplicant?.personalInformation?.fullName);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id, fullName]);

  const handleType = (value: string | number | readonly string[]) => setType(value.toString());

  const handleValidateCode: SubmitHandler<FormValues> = async (data) => {
    const { certificate, privateKey, finspheraPassword } = data;
    const codeInput = {
      id: user.id,
      messageId: messageId,
      code: finspheraPassword,
      timestamp: Date.now(),
      typeOfUser: 'applicant'
    };
    let validationCode;
    dispatch(setLoadingState(true));
    dispatch(setLoadingMessage(loadingMessage));

    try {
      validationCode = await axios.post(`${url}/admin/email/validate-otp`, codeInput);
    } catch (error) {
      dispatch(setLoadingState(false));
      console.error(error);
    }
    if (!validationCode) {
      dispatch(setLoadingState(false));
      //Definir lógica para notificar que ocurrió un error y que solicite reenviar código de verif. y cuántas veces lo puede hacer.
      return;
    }
    const parsedBody = JSON.parse(validationCode.data.body);
    if (!parsedBody.isVerified) {
      dispatch(setLoadingState(false));
      return setError('finspheraPassword', {
        type: 'custom',
        message: 'Ups, el código que ingresaste es incorrecto.'
      });
    }
    const certificateBase64 = await handleFile(certificate);
    const privateKeyBase64 = await handleFile(privateKey);
    const creditBody = {
      id: user.id,
      typeOfUser: 'applicant',
      timestamp: Date.now()
    };
    const serialNum = {
      type: 'applicant_companyInformation_numeroSerie',
      input: {
        id: user.id,
        personalInformation: {
          countryOfResidence: data.residenceCountry,
          RFC: data.RFC
        },
        companyInformation: {
          generalActivity: data.economicActivity,
          specificActivity: data.specificEconomicActivity,
          numeroSerie: data.serialNumber
        }
      }
    };
    //Esto se manda a satws
    let body = {};
    if (type === 'ciec') {
      body = {
        type: type,
        rfc: data.RFC,
        password: data.password,
        id: user.id,
        typeOfUser: 'applicant'
      };
    }

    if (type === 'efirma') {
      body = {
        type: type,
        password: data.password,
        id: user.id,
        typeOfUser: 'applicant',
        certificate: certificateBase64,
        privateKey: privateKeyBase64,
        rfc: data.RFC
      };
    }
    await dispatch(satwsInfo(body));
    const approvedObject = await dispatch(creditAnalysis(body)).unwrap();
    const isApproved = approvedObject.isApproved;
    if (isApproved === '0') {
      setShowCreditModal(true);
    } else {
      await dispatch(literaxCreditAnalysis(creditBody));
      await axios.post(`${url}/admin/dynamo/update`, serialNum);
      dispatch(setLoadingState(false));
      await nextStep();
      navigate('/solicitante/onboarding-creditInfo');
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const isBlockedCountry = blockedCountriesInSpanish.includes(data.residenceCountry);
    const isResourceProviderOrRealOwner = data.resourceProvider || data.realOwner;

    if (isResourceProviderOrRealOwner || isBlockedCountry) {
      if (isResourceProviderOrRealOwner) {
        await mutateCreateResourceProvider({
          investorId: user.id,
          personalInformation: {
            email: ''
          }
        });
        await sendPLDEmail(user.id, 'investor');
      }

      const reasonOfBlocking = getReasonOfBlocking(isBlockedCountry, isResourceProviderOrRealOwner);

      await dispatch(
        updateOnboardingBlockingStatus({
          type: 'applicant',
          input: {
            id: user.id,
            onboarding: {
              isBlocked: true,
              reasonOfBlocking: reasonOfBlocking,
              currentStep: onboarding.currentStep - 1 // Is minus 1 in this case because the logic of this view we don't want to send the user to the next step
            }
          }
        })
      );

      dispatch(setLoadingState(false));
      return navigate('/pagina-validacion');
    }
    // HARD CODED
    //Función para enviar código de validación al email
    const input = {
      id: user.id,
      typeOfUser: 'applicant',
      typeMessage: 'checkCdC',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const selectOptions = [
    { value: 'ciec', label: 'CIEC' },
    { value: 'efirma', label: 'e.firma' }
  ];

  const handleClick = () => {
    dispatch(signOutAll());
  };
  const generateModalMessage = () => {
    return (
      <>
        <p>
          Lamentamos informarte que no alcanzaste la calificación mínima para tomar crédito a través
          de Finpshera. Tu cuenta se eliminará automáticamente en las siguientes 24hrs. Si tienes
          alguna duda o comentario escríbenos a:{' '}
          <Link to="mailto:atencionaclientes@finsphera.com"> atencionaclientes@finsphera.com</Link>
        </p>
      </>
    );
  };

  return (
    <PersonalInformationContainer>
      {renderShowInfoModal(showInfoModal, () => setShowInfoModal(false))}
      <Modal onClose={() => setShowModal(false)} isOpen={showModal}>
        <Modal.Header isCentered>
          <h2>Estimado usuario. Esta es tu información introducida</h2>
        </Modal.Header>
        <Modal.Body isCentered className="modal-body">
          <dl>
            <dt>
              <b>Nombre</b>
            </dt>
            <dd>{personalInformation?.fullName || 'Indefinido'}</dd>
            <dt>
              <b>Fecha de nacimiento</b>
            </dt>
            <dd>{personalInformation?.dateOfBirth || 'Indefinido'}</dd>
            <dt>
              <b>Dirección</b>
            </dt>
            <dd>Indefinido</dd> {/* Replace 'Indefinido' with actual field once it is available. */}
            <dt>
              <b>CURP</b>
            </dt>
            <dd>{personalInformation?.CURP || 'Indefinido'}</dd>
            <dt>
              <b>Email</b>
            </dt>
            <dd>{personalInformation?.email}</dd>
          </dl>
        </Modal.Body>
        <Modal.Footer>
          <Button isSlim onClick={() => setShowModal(false)}>
            Entendido
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalError />
      <ComposedModal
        messageTitle={'Solictud de crédito no aprobada'}
        messageChildren={generateModalMessage()}
        type="error"
        isOpen={showCreditModal}
        onClose={() => handleClick}
        buttons={[
          {
            label: 'Continuar',
            onClick: () => handleClick,
            color: 'green'
          }
        ]}
        isCloseButton={false}
        onOverlayClose={false}
      />
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_personalInformation_banner.png`}
            alt="Finsphera ícono información personal"
          />
        </figure>
        <h1>Información personal</h1>
        <p>
          Bienvenido a FinSphera donde podrás pre calificarte para un crédito desde 500 mil hasta 14
          millones de pesos, sólo es necesario que completes tu registro.
        </p>
      </Banner>
      <Card isForm className="card-container">
        <FormSection>
          <h2>Datos del Apoderado Legal</h2>
          <BiometricsSection>
            <Controller
              name="RFC"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                <InputField
                  label="Ingresa el RFC del representante legal:"
                  onChangeInternalValue={onChange}
                  errorMessages={error && error.message ? [error.message] : []}
                  {...restFieldProps}
                />
              )}
            />
            <Card.Row className="full-width">
              <CountrySelector
                label="Selecciona tu país de residencia:"
                errorMessages={
                  errors.residenceCountry && errors.residenceCountry.message
                    ? [errors.residenceCountry.message]
                    : []
                }
                placeholder="Selecciona una opción"
                {...register('residenceCountry', { required: 'Es un campo requerido.' })}
              />
            </Card.Row>
          </BiometricsSection>
          <ContactData>
            <p>
              En este segundo paso, proporciónanos la CIEC o e.firma para continuar con el registro
              de tu solicitud. Después de llenar la información da click en Siguiente.
            </p>
            <Controller
              name="type"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => {
                const handleCombinedChange = (value: string | number | readonly string[]) => {
                  handleType(value);
                  onChange(value);
                };

                return (
                  <InputField
                    isDropdown
                    onChangeInternalValue={handleCombinedChange}
                    options={selectOptions}
                    wrapLabel
                    placeholder="Selecciona una opción"
                    errorMessages={error && error.message ? [error.message] : []}
                    {...restFieldProps}
                  />
                );
              }}
            />

            {type === 'ciec' && (
              <>
                <Controller
                  name="RFC"
                  control={control}
                  rules={{
                    required: 'Es un campo requerido.',
                    pattern: {
                      value: /^[A-ZÑ0-9]+$/,
                      message: 'Invalid RFC'
                    },
                    minLength: {
                      value: 12,
                      message: 'RFC debe tener al menos 12 caracteres'
                    }
                  }}
                  render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                    <InputField
                      label="Ingresa tu RFC:"
                      autoUpperCase
                      maxLength={13}
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...restFieldProps}
                    />
                  )}
                />

                <Controller
                  name="password"
                  control={control}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                    <InputField
                      label="Ingresa la contraseña con la que ingresas al SAT:"
                      type="password"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...restFieldProps}
                    />
                  )}
                />
              </>
            )}
            {type === 'efirma' && (
              <>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                    <InputField
                      label="Ingresa la contraseña con la que ingresas al SAT:"
                      type="password"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...restFieldProps}
                    />
                  )}
                />
                <Controller
                  name="certificate"
                  control={control}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field: { onChange, name }, fieldState: { error } }) => (
                    <InputUpload
                      label="Certificado (.cer)"
                      id="certificate"
                      accept=".cer"
                      name={name}
                      onChange={(e) => {
                        onChange(e.target.files?.[0]);
                      }}
                      errorMessages={error && error.message ? [error.message] : []}
                    >
                      Subir archivo
                    </InputUpload>
                  )}
                />
                <Controller
                  name="privateKey"
                  control={control}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field: { onChange, name }, fieldState: { error } }) => (
                    <InputUpload
                      label="Llave privada (.key)"
                      name={name}
                      accept=".key"
                      id="private-key"
                      onChange={(e) => {
                        onChange(e.target.files?.[0]);
                      }}
                      errorMessages={error && error.message ? [error.message] : []}
                    >
                      Subir archivo
                    </InputUpload>
                  )}
                />
              </>
            )}
          </ContactData>
          <Card.Row>
            <Controller
              name="economicActivity"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  label="Principal actividad económica:"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={optionsBusinessName}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>

          {economicActivity && economicActivitiesMapping[economicActivity] && (
            <Card.Row>
              <Controller
                name="specificEconomicActivity"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Es un campo requerido.' }}
                render={({ field, fieldState, ...rest }) => (
                  <InputField
                    label="Selecciona tu actividad específica:"
                    placeholder="Selecciona una opción"
                    isDropdown
                    options={economicActivitiesMapping[economicActivity].map(({ name }) => ({
                      value: name,
                      label: name
                    }))}
                    onChangeInternalValue={field.onChange}
                    errorMessages={
                      fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                    }
                    {...rest}
                  />
                )}
              />
            </Card.Row>
          )}
          <Card.Row>
            <Controller
              name="serialNumber"
              control={control}
              rules={{
                minLength: {
                  value: 20,
                  message: 'El número de serie debe tener 20 caracteres.'
                },
                required: 'Es un campo requerido.',
                pattern: {
                  value: /^[A-ZÑ0-9]+$/,
                  message: 'Número de serie inválido.'
                }
              }}
              render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                <InputField
                  label="Ingresa el No. de serie dentro del comprobante de generación del certificado digital de firma electrónica:"
                  placeholder="No. de serie"
                  regex={/^[A-ZÑ0-9]+$/}
                  onChangeInternalValue={onChange}
                  autoUpperCase
                  maxLength={20}
                  errorMessages={error && error.message ? [error.message] : []}
                  wrapLabel
                  {...restFieldProps}
                />
              )}
            />
          </Card.Row>
          <Card.Row itemPosition="left">
            <div>
              <Checkbox
                label="¿Una persona diferente a la empresa que representas aportará recursos a esta cuenta sin ser el titular?"
                {...register('resourceProvider')}
                disabled={realOwnerChecked}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowInfoModal(true);
                }}
              >
                Más información
              </a>
            </div>
          </Card.Row>

          <Card.Row itemPosition="left">
            <div>
              <Checkbox
                label="¿Una persona diferente a la empresa que representas obtendrá los beneficios de la cuenta y es el verdadero dueño de los recursos?"
                {...register('realOwner')}
                disabled={resourceProviderChecked}
              />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setShowInfoModal(true);
                }}
              >
                Más información
              </a>
            </div>
          </Card.Row>
        </FormSection>
        <ConsultCredit>
          <h2>Autorización para consulta en sociedades de información crediticia</h2>
          <div className="checkbox">
            <Checkbox
              label="Manifiesto que la empresa que represento actúa por cuenta propia"
              {...register('actOnTheirOwn', { required: 'Es un campo requerido para continuar.' })}
              errorMessages={
                errors.actOnTheirOwn && errors.actOnTheirOwn.message
                  ? [errors.actOnTheirOwn.message]
                  : []
              }
            />
            <div className="checkbox">
              <Checkbox
                label="Acepto los Términos y Condiciones, Aviso de Privacidad y Cláusula de medios electrónicos tales como NIP"
                {...register('termsAndPrivacy', { required: true })}
                errorMessages={
                  errors.termsAndPrivacy && [
                    'Por favor acepta los términos y condiciones para continuar.'
                  ]
                }
              />
            </div>
            <div className="checkbox">
              <Checkbox
                label="
                Autorizo expresamente a Sustainable Tech ESG, S.A.P.I de C.V.
                Institución de Financiamiento Colectivo (FinSphera), para que lleve a cabo Investigaciones sobre mi comportamiento Crediticio en las
                Sociedades de Información Crediticia (SIC) que estime conveniente.
                Conozco la naturaleza y alcance de la Información que se solicitará, del uso que se le dará y que se podrán realizar consultas periódicas de mi historial crediticio. Consiento que esta autorización tenga una vigencia de 3 años contados a partir de hoy, y en su caso mientras mantengamos relación jurídica. Acepto que este documento quede bajo propiedad de Sustainable Tech ESG, S.A.P.I de C.V. Institución de Financiamiento
                Colectivo (FinSphera) para efectos de control y cumplimiento del articulo 28 de la LRSIC."
                {...register('authorizationConsultCredit', { required: true })}
                errorMessages={
                  errors.authorizationConsultCredit && [
                    'Esta autorización es requerida para continuar.'
                  ]
                }
              />
            </div>
            <div>
              <Checkbox
                label="Estoy solicitando crédito con otra institución financiera"
                {...register('anotherCredit')}
                errorMessages={
                  errors.anotherCredit && errors.anotherCredit.message
                    ? [errors.anotherCredit.message]
                    : []
                }
              />
            </div>
          </div>
          <p>
            Cláusula para utilizar medios electrónicos de autenticación. Las partes acuerdan que el
            Cliente podrá expresar su consentimiento respecto a los presentes{' '}
            <b>términos y condiciones</b>, así como tener acceso a los servicios ofrecidos por{' '}
            <b>
              Sustainable Tech ESG, S.A.P.I de C.V. Institución de Financiamiento Colectivo
              (FinSphera)
            </b>{' '}
            mediante medios electrónicos tales como NIP.
          </p>
          {showInput ? (
            <div>
              <Controller
                name="finspheraPassword"
                control={control}
                rules={{
                  minLength: {
                    value: 5,
                    message: 'El código debe tener 5 caracteres'
                  },
                  required: 'Código requerido.'
                }}
                render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                  <InputField
                    label="Ingreso de NIP que enviamos a tu correo"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    wrapLabel
                    {...restFieldProps}
                  />
                )}
              />
            </div>
          ) : null}
        </ConsultCredit>
      </Card>
      <Container>
        <Button size="small" onClick={closeFlow}>
          Terminar después
        </Button>
        {showInput ? (
          <Button size="small" type="submit" onClick={handleSubmit(handleValidateCode)}>
            Siguiente
          </Button>
        ) : (
          <Button size="small" type="submit" onClick={handleSubmit(onSubmit)}>
            Aceptar
          </Button>
        )}
      </Container>
    </PersonalInformationContainer>
  );
}
