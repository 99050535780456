import styled from 'styled-components/macro';

import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  neutralBlueGray
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const ModalCreditUnsuccess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.showCreditUnsuccess ? 'flex' : 'none')};

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  animation: ${(props) => (props.showCreditUnsuccess ? fadeIn : fadeOut)} 0.5s linear;
  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
  > h2 {
    font-size: ${uIFontSize.xLarge};
    text-align: center;
    color: ${brandBlack};
    > span {
      color: ${greenSp};
      font-size: ${uIFontSize.xLarge};
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  > p {
    width: 100%;
    text-align: center;
    font-size: ${uIFontSize.medium};
  }
  > button {
    margin-top: 2%;
  }
  > h3 {
    font-size: medium;
    font-weight: bold;
    margin-top: 2%;
    text-align: center;
  }
`;

export const ButtonsContainerOne = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
  border: none;
  background-color: ${whiteSphera};
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5% 0;
  > div {
    width: 90%;
    display: flex;
    justify-content: space-between;
    h3 {
      width: 60%;
      font-size: ${uIFontSize.large};
      color: ${grayMediumSphera};
      text-align: left;
      font-weight: bold;
      margin: 1% 0;
    }
    > p {
      width: 35%;
      color: ${greenSp};
      text-align: left;
      font-size: ${uIFontSize.large};
      font-weight: bold;
      margin: 1% 0;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: ${uIFontSize.xLarge};
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: ${uIFontSize.h3};
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    > a {
      color: ${greenSp};
      font-size: ${uIFontSize.h3};
      font-weight: bold;
    }
  }
`;

export const Headers = styled.section`
  padding-bottom: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #b7bcc7;
  > div {
    width: 90%;
  }

  > figure {
    width: 30px;
    height: 30px;
    cursor: pointer;

    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
  }
  @media (max-width: ${breakpoints.tablet}) {
    padding-bottom: 5%;
    > figure {
      width: 25px;
      height: 25px;
    }
  }
`;
