import { forwardRef, ForwardRefExoticComponent, RefObject } from 'react';
import { CardContainer, CardFormContainer, CardRowContainer } from './card.styles';
import { CardComponentProps, CardRowProps, CardProps } from './card.interface';

const Card = forwardRef<HTMLElement, CardComponentProps>((props, ref) => {
  const { children, isForm, ...restProps } = props;

  return isForm ? (
    <CardFormContainer ref={ref as RefObject<HTMLFormElement>} {...restProps}>
      {children}
    </CardFormContainer>
  ) : (
    <CardContainer ref={ref as RefObject<HTMLDivElement>} {...restProps}>
      {children}
    </CardContainer>
  );
}) as CardComponentProps & ForwardRefExoticComponent<CardProps>;

const CardRow = forwardRef<HTMLDivElement, CardRowProps>((props, ref) => {
  const {
    children,
    className,
    itemPosition = 'center',
    oneLine = false,
    direction = 'row'
  } = props;

  const combinedClassNames = ['card-row', className].filter(Boolean).join(' ');

  return (
    <CardRowContainer
      ref={ref}
      className={combinedClassNames}
      itemPosition={itemPosition}
      oneLine={oneLine}
      direction={direction}
      {...props}
    >
      {children}
    </CardRowContainer>
  );
});

Card.displayName = 'Card';
CardRow.displayName = 'CardRow';

Card.Row = CardRow;

export default Card;
