import React from 'react';

import { ResetPasswordContainer, ResetPasswordInfo } from './resetPassword.styles';

export default function ResetPassword() {
  return (
    <ResetPasswordContainer>
      <ResetPasswordInfo>
        <div>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success.png"
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Revisa tu correo!</h2>
          <p>Enviamos un email a micorreo@empresa.mx para restablecer tu contraseña.</p>
        </div>
      </ResetPasswordInfo>
    </ResetPasswordContainer>
  );
}
