import { ChangeEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { DashboardDocuments, DocumentsInfoContainer } from './businessDocuments.styles';
import { ModalDocumentsSucess } from 'src/components/shared/modals/documentsUploaded/documentsUploaded.styles';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { url } from 'src/environments';
import Modal from 'src/utilities/UILibrary/Feedback/Modal/Modal';
import { RootState } from 'src/store/store';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { UploadButton } from 'src/utilities/UILibrary/Forms/UploadButton/UploadButton';
import { getApplicantFiles } from 'src/graphql/customized_queries';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { API } from 'aws-amplify';

type UploadedFiles = {
  constitutiveAct: File | null;
  actInscription: File | null;
  addressFile: File | null;
  legalRep: File | null;
  idFile: File[];
  statement: File | null;
};

interface GetApplicantFilesResponse {
  data: {
    getFile: {
      areBusinessDocumentsUpload: boolean;
    };
  };
}

export default function BusinessDocuments() {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const [uploadStatus, setUploadStatus] = useState('pending');
  const [filesValidator, setFilesValidator] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFiles>({
    constitutiveAct: null,
    actInscription: null,
    addressFile: null,
    idFile: [],
    legalRep: null,
    statement: null
  });
  const [showModal, setShowModal] = useState(false);
  const [legalRepresentativeUploaded, setLegalRepresentativeUploaded] = useState(false);
  const [legallyBoundUploaded, setLegallyBoundUploaded] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [inputsDisabled, setInputsDisabled] = useState(true);
  const [internalErrors, setInternalErrors] = useState({
    legalRepresentativeName: false
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await API.graphql({
        query: getApplicantFiles,
        variables: { id: user.id }
      });

      if ('data' in response) {
        const files = response.data?.getFile?.areBusinessDocumentsUpload;
        setFilesValidator(files);
      } else {
        // Handle the case where there is no data property
        console.error('No data received', response);
      }
    };
    fetchUserData();
  }, []);

  //FIle to base64
  const fileToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFile = async (file: File | null) => {
    if (!file) return;
    try {
      const base64String = await fileToBase64(file);
      return base64String;
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };

  const sendToServer = async () => {
    setUploadStatus('uploading');

    const fileKeys = Object.keys(uploadedFiles);

    const input = {
      id: user.id,
      files: [] as { base64File: unknown; folder: string; fileName: string }[]
    };

    for (const key of fileKeys) {
      if (key === 'idFile') {
        let idFileCounter = 0;
        for (const file of uploadedFiles[key]) {
          const base64File = await handleFile(file);
          let fileName;
          if (idFileCounter === 0) {
            fileName = `${key}_legally_bound.pdf`;
          } else {
            fileName = `${key}_${idFileCounter}.pdf`;
          }
          input.files.push({
            base64File,
            folder: 'businessDocuments',
            fileName: fileName
          });
          idFileCounter++;
        }
      } else {
        const base64File = await handleFile(
          uploadedFiles[key as keyof Omit<UploadedFiles, 'idFile'>]
        );
        input.files.push({
          base64File,
          folder: 'businessDocuments',
          fileName: `${key}.pdf`
        });
      }
    }

    try {
      setLocalLoading(true);
      const upload = await axios.post(`${url}/admin/documents/upload-multiple`, input);
      const responseBody = JSON.parse(upload.data.body);
      if (responseBody.result === true) {
        setUploadStatus('completed');
        setShowModal(true);
      } else {
        setUploadStatus('failed');
      }
      setLocalLoading(false);
    } catch (error) {
      console.error(error);
      setUploadStatus('failed');
      setLocalLoading(false);
    }
  };

  const handleUploadLegalRepresentatives = (e: ChangeEvent<HTMLInputElement>) => {
    setUploadedFiles({
      ...uploadedFiles,
      idFile: e.target.files
        ? [...uploadedFiles.idFile, ...Array.from(e.target.files)]
        : uploadedFiles.idFile
    });
  };

  return (
    <DocumentsInfoContainer>
      <ComposedModal isOpen={showModal} onClose={() => setShowModal(false)} type="success" />
      <ModalDocumentsSucess />
      {filesValidator ? (
        <>
          <h1>Ya has cargado tus archivos de forma exitosa. ¿Deseas volver a realizar la carga?</h1>
          <Button size="small" onClick={() => setInputsDisabled(false)}>
            Volver a cargar
          </Button>
        </>
      ) : (
        <h2>
          En esta sección podrás cargar los documentos necesarios para que puedas crear tus campañas
        </h2>
      )}
      <DashboardDocuments>
        <div>
          <p>Acta Constitutiva </p>
          <InputUpload
            label="Acta constitutiva"
            id="constitutiveAct"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => {
              setUploadedFiles({
                ...uploadedFiles,
                constitutiveAct: e.target.files?.[0] || null
              });
            }}
          >
            Cargar archivo
          </InputUpload>
        </div>
        <div>
          <p>Inscripción del Acta Constitutiva</p>
          <InputUpload
            label="Inscripción del Acta Constitutiva"
            id="actInscription"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => {
              setUploadedFiles({
                ...uploadedFiles,
                actInscription: e.target.files?.[0] || null
              });
            }}
          >
            Cargar archivo
          </InputUpload>
        </div>
        <div>
          <p>Antigüedad no mayor a 3 meses</p>
          <InputUpload
            label="Comprobante de domicilio"
            id="addressFile"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => {
              setUploadedFiles({
                ...uploadedFiles,
                addressFile: e.target.files?.[0] || null
              });
            }}
          >
            Cargar archivo
          </InputUpload>
        </div>
        <div>
          <p>
            Cuando los Poderes de los Representantes legales no estén en el Acta Constitutiva debes
            cargar el documento o documentos que lo contenga o contengan con su correspondiente
            inscripción en el Registro Público de la Propiedad y Comercio. También debes cargar aquí
            la Credencial de Elector o Pasaporte de cada uno de los Representantes Legales de la
            empresa.
          </p>
          <InputUpload
            label="Poderes del representante o representantes"
            id="legalRep"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => {
              setUploadedFiles({
                ...uploadedFiles,
                legalRep: e.target.files?.[0] || null
              });
            }}
          >
            Cargar archivo
          </InputUpload>
        </div>
        <div>
          <p>
            Identificación personal de cada uno de los representantes legales. En caso de ser
            extranjero proporciónanos tu pasaporte o algún documento válidos de identificación
            personal oficial emitida por autoridades federales de tu país de origen.
          </p>
          <InputUpload
            id="idFile"
            label="Identificaciones"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => handleUploadLegalRepresentatives(e)}
          >
            Cargar archivo
          </InputUpload>
        </div>
        <div>
          <p>
            Declaración firmada por el representante legal que señale quiénes son sus Propietarios
            Reales
          </p>
          <InputUpload
            label="Declaración"
            id="statement"
            size="small"
            accept=".pdf"
            disabled={inputsDisabled}
            onChange={(e) => {
              setUploadedFiles({
                ...uploadedFiles,
                statement: e.target.files?.[0] || null
              });
            }}
          >
            Cargar archivo
          </InputUpload>
        </div>
      </DashboardDocuments>
      <div className="submit-button">
        <Checkbox
          label="Marca esta casilla si has subido todos los representantes legales en Identificación personal"
          onChange={(e) => setLegalRepresentativeUploaded(e.target.checked)}
          disabled={uploadedFiles.idFile.length === 0}
        />
        <Button
          onClick={() => {
            if (uploadStatus === 'completed') {
              setUploadedFiles({
                constitutiveAct: null,
                actInscription: null,
                addressFile: null,
                legalRep: null,
                idFile: [],
                statement: null
              });
              setUploadStatus('pending');
            } else {
              sendToServer();
            }
          }}
          isSlim
          isFullWidth
          disabled={
            uploadStatus === 'uploading' ||
            !Object.values(uploadedFiles).every((value) => value) ||
            !legalRepresentativeUploaded
          }
          isLoading={localLoading}
        >
          {uploadStatus === 'completed' ? 'Actualizar archivos' : 'Subir todos'}
        </Button>
      </div>
    </DocumentsInfoContainer>
  );
}
