import { forwardRef, useState, useEffect } from 'react';
import { BoxAmountProps } from './types';
import { BoxAmoutContainer } from './styles';

export const BoxAmount = forwardRef<HTMLDivElement, BoxAmountProps>((props, ref) => {
  const { amount = 0, isFormated = true, ...rest } = props;
  const [animationClass, setAnimationClass] = useState('');
  const displayAmount = isFormated ? amount.toLocaleString() : amount;

  useEffect(() => {
    setAnimationClass('animate');
    const timer = setTimeout(() => {
      setAnimationClass('');
    }, 500);
    return () => clearTimeout(timer);
  }, [displayAmount]);

  return (
    <BoxAmoutContainer ref={ref} amount={amount} {...rest}>
      <p className={animationClass}>{displayAmount}</p>
    </BoxAmoutContainer>
  );
});

BoxAmount.displayName = 'BoxAmount';
