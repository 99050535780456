import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  createEachBeneficiaryAPI,
  createFinspheraInvestmentAPI,
  createInvestmentAPI,
  fetchAddressApplicantDataAPI,
  fetchAddressInformationAPI,
  fetchBankApplicantDataAPI,
  fetchBankInvestorDataAPI,
  fetchCurrentSessionAPI,
  fetchFilesInformationAPI,
  fetchMifielInformationAPI,
  fetchObligadoSolidarioApplicantAPI,
  fetchPersonalInformationAPI,
  fetchPersonalInformationApplicantAPI
} from 'src/middlewares/API/Profile/profile';
import { ProfileState, Beneficiary, Investment } from './profile.interface';

// Async Thunks Profile Investor
export const fetchPersonalInformation = createAsyncThunk(
  'profile/fetchPersonalInformation',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: string) => {
    try {
      const response = await fetchPersonalInformationAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

// Async Thunks Profile Investor
export const fetchMifielInformation = createAsyncThunk(
  'profile/fetchMifielInformation',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input) => {
    try {
      const response = await fetchMifielInformationAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

// Async Thunks Profile Investor
export const fetchAddressInformation = createAsyncThunk(
  'profile/fetchAddressInformation',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (address: any, thunkAPI) => {
    try {
      const response = await fetchAddressInformationAPI(address, thunkAPI);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

export const fetchBeneficiariesInformation = createAsyncThunk(
  'profile/fetchBeneficiariesInformation',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetchPersonalInformationAPI(userId);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

export const fetchFilesInformation = createAsyncThunk(
  'profile/fetchFilesInformation',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (userId: any, { rejectWithValue }) => {
    try {
      const response = await fetchFilesInformationAPI(userId);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

// Async Thunks Address Applicant
export const fetchAddressApplicantData = createAsyncThunk(
  'profile/fetchAddressApplicantData',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any) => {
    try {
      const response = await fetchAddressApplicantDataAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

export const fetchPersonalInformationApplicant = createAsyncThunk(
  'profile/fetchPersonalInformationApplicant',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: string) => {
    try {
      const response = await fetchPersonalInformationApplicantAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

export const fetchObligadoSolidarioApplicant = createAsyncThunk(
  'fetchObligadoSolidarioApplicant',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: string) => {
    try {
      const response = await fetchObligadoSolidarioApplicantAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

export const fetchCurrentSession = createAsyncThunk(
  'profile/fetchCurrentSession',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: string) => {
    try {
      const response = await fetchCurrentSessionAPI();
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return error.message || 'Unknown error';
    }
  }
);

//CREATE BENEFICIARY
export const createEachBeneficiary = createAsyncThunk(
  'beneficiaries/createBeneficiary',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createEachBeneficiaryAPI(input);

      if (response.data && response.data.createBeneficiary) {
        return response.data.createBeneficiary as Beneficiary;
      }

      throw new Error('Failed to create beneficiary');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//CREATE INVESTMENT
export const createInvestment = createAsyncThunk(
  'investment/createInvestment',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createInvestmentAPI(input);
      if (response.data && response.data.createInvestment) {
        return response.data.createInvestment as Investment;
      }
      throw new Error('Failed to create investment');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//CREATE INVESTMENT FINSPHERA
export const createFinspheraInvestment = createAsyncThunk(
  'investment/createFinspheraInvestment',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createFinspheraInvestmentAPI(input);
      if (response.data && response.data.createInvestment) {
        return response.data.createInvestment as Investment;
      }
      throw new Error('Failed to create investment');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//GET BANK APPLICANT DATA
export const fetchBankApplicantData = createAsyncThunk(
  'profile/fetchBankApplicantData',
  async (input, { rejectWithValue }) => {
    try {
      const response = await fetchBankApplicantDataAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//GET BANK INVESTOR DATA
export const fetchBankInvestorData = createAsyncThunk(
  'profile/fetchBankInvestorData',
  async (input, { rejectWithValue }) => {
    try {
      const response = await fetchBankInvestorDataAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

// GET CAMPAIGN INVESTOR DATA
export const fetchCampaignInformation = createAsyncThunk(
  'profile/etchCampaignInformation',
  async (input, { rejectWithValue }) => {
    try {
      const response = await fetchBankInvestorDataAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

// Slice
export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    personalInformation: {},
    addressInformation: [],
    bankInformation: {},
    campaignInformation: {},
    filesInformation: {},
    investmentInformation: [],
    mifielInformation: {},
    solidaryInformation: {},
    beneficiariesInformation: [] as Beneficiary[],
    beneficiary: {},
    beneficieries: [],
    status: 'idle',
    error: {}
  } as unknown as ProfileState,
  reducers: {
    addBeneficiary: (state, action) => {
      state.beneficiariesInformation.push(action.payload);
    },
    deleteBeneficiary: (state, action) => {
      state.beneficiariesInformation = state.beneficiariesInformation.filter(
        (beneficiary) => beneficiary.id !== action.payload
      );
    },
    addInvestment: (state, action) => {
      state.investmentInformation.push(action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonalInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPersonalInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.personalInformation = action.payload;
      })
      .addCase(fetchPersonalInformation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchPersonalInformationApplicant.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPersonalInformationApplicant.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.personalInformation = action.payload;
      })
      .addCase(fetchPersonalInformationApplicant.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchBankApplicantData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBankApplicantData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.bankInformation = action.payload;
      })
      .addCase(fetchBankApplicantData.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchBankInvestorData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBankInvestorData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bankInformation = action.payload;
      })
      .addCase(fetchBankInvestorData.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchAddressInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddressInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addressInformation = action.payload;
      })
      .addCase(fetchAddressInformation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchCampaignInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCampaignInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addressInformation = action.payload;
      })
      .addCase(fetchCampaignInformation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchAddressApplicantData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddressApplicantData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.addressInformation = action.payload;
      })
      .addCase(fetchAddressApplicantData.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchFilesInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFilesInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.filesInformation = action.payload;
      })
      .addCase(fetchFilesInformation.rejected, (state, action) => {
        state.status = 'failed';
        // state.error = { message: action.error.message || 'Unknown error' };
        // state.error = action.error.message;
      })
      .addCase(fetchMifielInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMifielInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        state.mifielInformation = action.payload;
      })
      .addCase(fetchMifielInformation.rejected, (state, action) => {
        state.status = 'failed';
        // state.error = { message: action.error.message || 'Unknown error' };
        // state.error = action.error.message;
      })
      .addCase(fetchBeneficiariesInformation.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBeneficiariesInformation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.beneficiariesInformation = action.payload;
      })
      .addCase(fetchBeneficiariesInformation.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(fetchObligadoSolidarioApplicant.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchObligadoSolidarioApplicant.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.solidaryInformation = action.payload;
      })
      .addCase(fetchObligadoSolidarioApplicant.rejected, (state, action) => {
        state.status = 'failed';
      })
      .addCase(createEachBeneficiary.fulfilled, (state, action) => {
        state.beneficiariesInformation.push(action.payload);
      })
      .addCase(createInvestment.fulfilled, (state, action) => {
        state.investmentInformation.push(action.payload);
      });
  }
});

export const { addBeneficiary, deleteBeneficiary, addInvestment } = profileSlice.actions;

const selectSelf = (state: RootState) => state;

export default profileSlice.reducer;
