import React, { useState, useEffect } from 'react';

import {
  ModalDetailInvestment,
  DetailInvestmentInfo,
  DetailInvestmentCost,
  DetailInvestmentReturn,
  DetailInvestmentButtons
} from './detailInvestment.styles';

export default function DetailInvestment(props) {
  const [detailInvestment, setDetailInvestment] = useState(props.showModalDetailInvestment);
  const [available, setAvailable] = useState(false);

  useEffect(() => {
    setDetailInvestment(props.showModalDetailInvestment);
  }, [props.showModalDetailInvestment]);

  const closeModal = () => setDetailInvestment(false);

  return (
    <ModalDetailInvestment showModalDetailInvestment={detailInvestment}>
      <div>
        <div>
          <h3>Detalle de tu inversión</h3>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </div>
        <DetailInvestmentInfo>
          <div>
            <h3>Tasa</h3>
            <h3>Monto de inversión:</h3>
            <h3>Monto a transferir:</h3>
          </div>
          <div>
            <p>15.0%</p>
            <p>$1,000</p>
            <p>$1,000</p>
          </div>
        </DetailInvestmentInfo>
        <DetailInvestmentCost>
          <h2>Costo de la inversión:</h2>
          <div>
            <div>
              <div>
                <h3>Comisión FinSphera:</h3>
                <p>$0</p>
              </div>
              <p>Cobrando al inicio de la inversión.</p>
            </div>
            <div>
              <div>
                <h3>Cobro por cobranza:</h3>
                <p>$6.81</p>
              </div>
              <p>Se descontará de los intereses una vez liquidado el crédito.</p>
            </div>
          </div>
        </DetailInvestmentCost>
        <DetailInvestmentReturn>
          <h2>Costo de la inversión:</h2>
          <div>
            <div>
              <p>Plazo estimado de retorno de la inversión:</p>
              <p>88 días</p>
            </div>
            <div>
              <p>Monto bruto:</p>
              <p>$1,036.67</p>
            </div>
            <div>
              <p>Utilidad neta al fin del crédito:</p>
              <p>$29.86</p>
            </div>
          </div>
        </DetailInvestmentReturn>
        <DetailInvestmentButtons>
          <button>Revisar términos de inversión</button>
          <button>Cancelar</button>
        </DetailInvestmentButtons>
      </div>
    </ModalDetailInvestment>
  );
}
