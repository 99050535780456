import styled from 'styled-components/macro';
import {
  grayLightSphera,
  whiteSphera,
  blackSphera,
  greenSp,
  grayMediumSphera
} from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { device } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ModalSignupSuccess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showSuccessModal ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1001;
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    justify-content: center;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3% 7% 3% 5%;
    > h2 {
      font-size: ${uIFontSize.large};
      color: ${blackSphera};
      > span {
        color: ${greenSp};
        font-size: ${uIFontSize.large};
      }
    }

    > p:nth-child(2),
    > p:nth-child(3) {
      color: ${blackSphera};
      font-size: ${uIFontSize.small};
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      > span {
        color: ${greenSp};
        font-weight: bold;
        font-size: ${uIFontSize.small};
      }
    }

    > input {
      font-size: 1.2vw;
      text-align: center;
      letter-spacing: 2vw;
      color: ${blackSphera};
      width: 60%;
      height: 40px;
      margin: 2.5% auto 2.5% auto;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }

    > button {
      color: ${whiteSphera};
      font-size: 1.3vw;
      font-weight: bold;
      width: 40%;
      margin: 1% 25% 5% 25%;
      padding: 1.5% 8%;
      border: none;
      border-radius: 5px;
      background-color: ${(props) => (props.disabled ? 'grey' : 'black')};
      cursor: pointer;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.1);
      }
    }
    > h3 {
      color: ${grayMediumSphera};
      font-size: 1.3vw;
    }
    > a {
      color: ${greenSp};
      font-weight: bold;
      font-size: 1.3vw;
      margin-left: 2%;
    }

    > p:last-child {
      font-size: 1.2vw;
      text-align: center;
      color: rgb(255, 0, 0);

      a {
        color: ${greenSp};
        font-size: 1.2vw;
        font-weight: bold;
        text-decoration: none;
      }
    }
    .input-wrapper {
      justify-content: center;
      margin: 2% 0 0 10%;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h2 {
        > span {
        }
      }

      > p:nth-child(2),
      > p:nth-child(3) {
      }

      > input:nth-child(4) {
      }

      > button:nth-child(5) {
        &:hover {
        }
      }

      > p:last-child {
        a {
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > h2 {
        font-size: 7vw;
        text-align: center;

        > span {
        }
      }

      > p:nth-child(2),
      > p:nth-child(3) {
        font-size: 4.5vw;
      }

      > input:nth-child(4) {
        font-size: 4.2vw;
        width: 100%;
      }

      > button:nth-child(5) {
        font-size: 4.3vw;
        width: 100%;
        padding: 3% 8%;
        margin: auto auto 5% auto;

        &:hover {
        }
      }

      > p:last-child {
        font-size: 4.2vw;

        a {
          font-size: 4.2vw;
        }
      }
    }
  }
`;
