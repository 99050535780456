import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setLoadingState,
  updateInvestorInformation,
  updateOnboardingBlockingStatus
} from 'src/store/User/user.slice';
import { PersonalInformationContainer } from './biometrics.styles';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import economicActivitiesGeneral from 'src/constants/economicActivitiesGeneral';
import economicActivitiesMapping from 'src/constants/economicActivitiesMapping';
import { FormValues } from './biometrics.types';
import { occupations } from 'src/constants/ocupations';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { setAppError } from 'src/store/app/app.slice';
import options from 'src/constants/simafOptionsInvestor';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { getInvestor_Onboarding } from 'src/graphql/customized_queries';
import { GetInvestorQuery } from 'src/graphql/API';
import { API } from 'aws-amplify';
import { renderShowInfoModal } from 'src/components/shared/componentFactory/renderShowInfoModal';
import { sendPLDEmail, updateResourcesProvider } from 'src/requests';
import { CountrySelector } from 'src/utilities/UILibrary';
import generateUUID from 'src/functions/uuid';
import { blockedCountriesInSpanish } from 'src/constants/blockedCountries';
import {
  simafStates,
  simafCountriesWithoutNullValues
} from 'src/constants/countries/simafStatesAndCountriesCatalog';
import Resources from 'src/components/shared/modals/resourcesProvider/resourcesProvider';

const stateInput = {
  labelToSelect: 'Selecciona tu estado de nacimiento:',
  labelToWrite: 'Escribe tu estado de nacimiento:',
  placeholderToSelect: 'Selecciona una opción',
  placeholderToWrite: 'Introduce tu estado de residencia'
};

export default function Biometrics() {
  const [resourcesModal, setResourcesModal] = useState(false);
  const [fullName, setFullName] = useState<string | null>();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const user = useSelector((state: RootState) => state.user.currentUser);
  const { onboarding } = user;
  const navigate = useNavigate();
  const resourcesId = generateUUID();
  const dispatch = useAppThunkDispatch();
  const {
    control,
    watch,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormValues>();
  const { nextStep } = useManageSteps();
  const isPEP = options[5];

  const resourceProviderChecked = watch('resourceProvider');
  const realOwnerChecked = watch('realOwner');
  const birthCountryNormalized = watch('birthCountry')
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  const ocupationOptions = occupations.map((occupation) => ({
    value: occupation.name,
    label: occupation.name
  }));
  const isPEPOptions = isPEP.map((isPep) => ({
    value: isPep.code,
    label: isPep.name
  }));

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = (await API.graphql({
          query: getInvestor_Onboarding,
          variables: { id: user.id }
        })) as { data: GetInvestorQuery };
        setFullName(data?.getInvestor?.personalInformation?.fullName);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id, fullName]);

  const economicActivity = watch('economicActivity');
  const politicallyExposedValue = watch('politicallyExposed');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const isBlockedCountry = [data.residenceCountry, data.nationality, data.birthCountry].some(
      (country) => blockedCountriesInSpanish.includes(country)
    );

    const countryLookup = new Map(
      simafCountriesWithoutNullValues.map((country) => [country.name_es, country.simaf_code])
    );
    const stateLookup = new Map(
      simafStates.map((state) => [
        state.federal_entity_description,
        state.federal_entity_simaf_code
      ])
    );

    const countryOfResidenceSimafId = countryLookup.get(data.residenceCountry);
    const birthCountrySimafId = countryLookup.get(data.birthCountry);
    const nationalitySimafId = countryLookup.get(data.nationality);
    const stateSimafId = stateLookup.get(data.state || '');

    const isResourceProvider = data.resourceProvider;
    const isRealOwner = data.realOwner;
    const input = {
      type: 'investor_fisica_onboarding_primerPaso',
      input: {
        id: user.id,
        personalInformation: {
          RFC: data.RFC,
          generalActivity: data.economicActivity,
          specificActivity: data.specificEconomicActivity,
          occupation: data.occupation,
          isPEP: data.politicallyExposed,
          pepDate: data.pepDate || '',
          pepName: data.pepName || '',
          pepCharge: data.pepCharge || '',
          actOnTheirOwn: data.actOnTheirOwn,
          countryOfResidence: data.residenceCountry,
          nationality: data.nationality,
          countryOfBirth: data.birthCountry,
          stateOfBirth: data.state,
          simaf_countryOfResidenceId: countryOfResidenceSimafId?.toString() || '',
          simaf_nationalityId: nationalitySimafId?.toString() || '',
          simaf_countryOfBirthId: birthCountrySimafId?.toString() || '',
          simaf_stateOfBirthId: stateSimafId?.toString() || data.state
        }
      }
    };
    try {
      dispatch(setLoadingState(true));
      await dispatch(updateInvestorInformation(input)).unwrap();

      if (isBlockedCountry) {
        await dispatch(
          updateOnboardingBlockingStatus({
            type: 'investor',
            input: {
              id: user.id,
              onboarding: {
                isBlocked: true,
                reasonOfBlocking: 'countryBlocked',
                currentStep: onboarding.currentStep
              }
            }
          })
        );

        return navigate('/pagina-validacion');
      }

      if (isResourceProvider || isRealOwner) {
        await updateResourcesProvider('investor', user.id);
        await sendPLDEmail(user.id, 'investor');
        return setResourcesModal(true);
      }

      await nextStep();
      navigate('/inversionista/onboarding-risks');
    } catch {
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const optionsBusinessName = economicActivitiesGeneral.map((activity) => ({
    value: activity,
    label: activity
  }));

  const statesOptions = simafStates.map((state) => ({
    value: state.federal_entity_description,
    label: state.federal_entity_description
  }));

  return (
    <PersonalInformationContainer className="onboarding-view-content">
      {renderShowInfoModal(showInfoModal, () => setShowInfoModal(false))}
      <Resources
        resourcesModal={resourcesModal}
        setResourcesModal={setResourcesModal}
        resourcesId={resourcesId}
      />
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_personalInformation_banner.png`}
        imgAlt="Finsphera icono información personal"
        title="Datos personales y biométricos"
        className="onboarding-banner"
      />
      <Card className="onboarding-card" isSlim isForm>
        <Card.Row>
          <h3>Ingresa la siguiente información:</h3>
        </Card.Row>

        <Card.Row>
          <CountrySelector
            label="Selecciona tu nacionalidad:"
            errorMessages={
              errors.nationality && errors.nationality.message ? [errors.nationality.message] : []
            }
            placeholder="Selecciona una opción"
            {...register('nationality', { required: 'Es un campo requerido.' })}
          />
        </Card.Row>

        <Card.Row>
          <CountrySelector
            label="Selecciona tu país de residencia:"
            errorMessages={
              errors.residenceCountry && errors.residenceCountry.message
                ? [errors.residenceCountry.message]
                : []
            }
            placeholder="Selecciona una opción"
            {...register('residenceCountry', { required: 'Es un campo requerido.' })}
          />
        </Card.Row>

        <Card.Row>
          <CountrySelector
            label="Selecciona tu país de nacimiento:"
            errorMessages={
              errors.birthCountry && errors.birthCountry.message
                ? [errors.birthCountry.message]
                : []
            }
            placeholder="Selecciona una opción"
            {...register('birthCountry', { required: 'Es un campo requerido.' })}
          />
        </Card.Row>

        <Card.Row>
          <Controller
            name="state"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputField
                label={
                  birthCountryNormalized === 'mexico'
                    ? stateInput.labelToSelect
                    : stateInput.labelToWrite
                }
                placeholder={
                  birthCountryNormalized === 'mexico'
                    ? stateInput.placeholderToSelect
                    : stateInput.placeholderToWrite
                }
                isDropdown={birthCountryNormalized === 'mexico'}
                options={statesOptions}
                onChangeInternalValue={onChange}
                errorMessages={error && error.message ? [error.message] : []}
                {...rest}
              />
            )}
          />
        </Card.Row>

        <Card.Row>
          <h3>Ahora necesitamos tu RFC e información acerca de tus actividades económicas:</h3>
        </Card.Row>

        <Card.Row>
          <Controller
            name="RFC"
            control={control}
            rules={{
              minLength: {
                value: 13,
                message: 'RFC debe tener al menos 13 caracteres.'
              },
              required: 'Es un campo requerido.',
              pattern: {
                value: /^[A-ZÑ0-9]+$/,
                message: 'RFC inválido.'
              }
            }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputField
                label="Ingresa tu RFC:"
                placeholder="RFC"
                maxLength={13}
                autoUpperCase
                onChangeInternalValue={onChange}
                errorMessages={error && error.message ? [error.message] : []}
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="economicActivity"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Principal actividad económica:"
                placeholder="Selecciona una opción"
                isDropdown
                options={optionsBusinessName}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>

        {economicActivity && economicActivitiesMapping[economicActivity] && (
          <Card.Row>
            <Controller
              name="specificEconomicActivity"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  label="Selecciona tu actividad específica:"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={economicActivitiesMapping[economicActivity].map(({ name }) => ({
                    value: name,
                    label: name
                  }))}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>
        )}
        <Card.Row>
          <Controller
            name="occupation"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Ocupación:"
                placeholder="Selecciona una opción"
                isDropdown
                options={ocupationOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>

        <Card.Row>
          <Controller
            name="politicallyExposed"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                wrapLabel
                label="¿Tú o alguno de tus familiares, hasta el segundo grado, desempeña o ha desempeñado durante el último año, funciones públicas en un país extranjero o en territorio nacional, en puestos ejecutivos en empresas gubernamentales, estatales, federales o municipales o en partidos políticos?"
                placeholder="Selecciona una opción"
                isDropdown
                options={isPEPOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>

        {(politicallyExposedValue === '2' || politicallyExposedValue === '3') && (
          <>
            <Card.Row>
              <Controller
                name="pepName"
                control={control}
                rules={{
                  minLength: {
                    value: 5,
                    message: 'El nombre debe tener al menos 5 caracteres'
                  },
                  required: 'Es un campo requerido.'
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Nombre de la persona que se desempeñó como PEP:"
                    placeholder="Nombre"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="pepCharge"
                control={control}
                rules={{
                  minLength: {
                    value: 5,
                    message: 'El cargo debe tener al menos 5 caracteres'
                  },
                  required: 'Es un campo requerido.'
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Ingresa el cargo desempeñado:"
                    placeholder="Descripción"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="pepDate"
                control={control}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    type="date"
                    label="Fecha de funciones en el cargo (sólo si ya finalizó)"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
          </>
        )}
        <Card.Row itemPosition="left">
          <div>
            <Checkbox
              label="¿Una persona diferente a ti aportará recursos a esta cuenta sin ser el titular?"
              {...register('resourceProvider')}
              disabled={realOwnerChecked}
            />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowInfoModal(true);
              }}
            >
              Más información
            </a>
          </div>
        </Card.Row>

        <Card.Row itemPosition="left">
          <div>
            <Checkbox
              label="¿Una persona diferente a ti obtendrá los beneficios de la cuenta y es el verdadero dueño de los recursos?"
              {...register('realOwner')}
              disabled={resourceProviderChecked}
            />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowInfoModal(true);
              }}
            >
              Más información
            </a>
          </div>
        </Card.Row>

        <Card.Row itemPosition="left">
          <Checkbox
            label="Manifiesto que actúo por cuenta propia"
            {...register('actOnTheirOwn', { required: 'Es un campo requerido para continuar.' })}
            errorMessages={
              errors.actOnTheirOwn && errors.actOnTheirOwn.message
                ? [errors.actOnTheirOwn.message]
                : []
            }
          />
        </Card.Row>
      </Card>
      <FormActionButtons submitAction={handleSubmit(onSubmit)} />
    </PersonalInformationContainer>
  );
}
