import axios from 'axios';
import { url } from 'src/environments';

const addUserToGroup = async (username: string, groupname: 'applicant' | 'investor') => {
  const user = { username, groupname };

  try {
    const data = await axios.post(`${url}/admin/add-user-to-group`, user);
    return data;
  } catch (error) {
    console.error('ERROR Add User To Group:', error);
    return 'ERROR';
  }
};

export default addUserToGroup;
