import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonalInformationContainer, Banner, Options, Option } from './myProfile.styles';
import GeneralInfo from './generalInfo/generalInfo';
import Address from './address/address';
import ContractsInfo from './contractsInfo/contractInfo';
import BankInfo from './bankInformation/bankInfo';
import axios from 'axios';
import { url } from 'src/environments';
import {
  fetchAddressApplicantData,
  fetchBankApplicantData,
  fetchFilesInformation,
  fetchPersonalInformationApplicant
} from 'src/store/Profile/profile.slice';
import WithdrawCredit from './withdrawCredit/withdrawCredit';
import BusinessDocuments from './BusinessDocuments/BusinessDocuments';
import AccountStateApplicant from './accountState/AccountState';
import CreateJointlyObligated from './jointlyObligated/jointlyObligated';

export default function ApplicantProfile() {
  const [selectedOption, setSelectedOption] = useState('generalInfo');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const bankInformation = useSelector((state) => state.profile.bankInformation);
  const addressInformation = useSelector((state) => state.profile.addressInformation);
  const filesInformation = useSelector((state) => state.profile.filesInformation);
  const userId = personalInformation?.data?.getApplicant?.userId;
  const [getdata, setGetdata] = useState(null);
  const [obligadoInformation, setObligadoInformation] = useState([]);
  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformationApplicant(user.id));
      dispatch(fetchBankApplicantData(user.id));
      dispatch(fetchAddressApplicantData(user.id));
      dispatch(fetchFilesInformation(user.id));
    }
  }, [user.id, dispatch]);
  const body = {
    type: 'getAllObligadoSolidario',
    applicantId: user.id
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const request = await axios.post(`${url}/admin/queries/get-all`, body);
        const parsedBody = JSON.parse(request.data.body);
        const response = parsedBody.result;
        setGetdata(response);
        setObligadoInformation(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [user.id, url]);

  const options = [
    { key: 'generalInfo', label: 'Datos de la PyME' },
    { key: 'address', label: 'Dirección PyME' },
    { key: 'jointlyObligated', label: 'Obligado Solidario' },
    { key: 'contractsInfo', label: 'Contratos' },
    { key: 'businessDocuments', label: 'Mis documentos' },
    { key: 'bankInfo', label: 'Datos bancarios' },
    { key: 'withdrawCredit', label: 'Campañas' },
    { key: 'accountState', label: 'Estados de Cuenta' }
  ];

  return (
    <PersonalInformationContainer>
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_dash_my_profile_banner.png`}
            alt="Finsphera icono"
          />
        </figure>
        <h2>Mi perfil</h2>
        <p>
          En tu perfil podrás completar tus datos como solicitante en FinSphera y así realizar
          acciones en la plataforma.
        </p>
      </Banner>
      <Options>
        {options.map((option) => (
          <Option
            key={option.key}
            isSelected={selectedOption === option.key}
            onClick={() => setSelectedOption(option.key)}
          >
            {option.label}
          </Option>
        ))}
      </Options>
      {selectedOption === 'generalInfo' && (
        <GeneralInfo personalInformation={personalInformation} />
      )}
      {selectedOption === 'address' && <Address addressInformation={addressInformation} />}
      {selectedOption === 'jointlyObligated' && (
        <CreateJointlyObligated
          obligadoInfo={obligadoInformation}
          personalInformation={personalInformation}
        />
      )}
      {selectedOption === 'contractsInfo' && (
        <ContractsInfo personalInformation={personalInformation} />
      )}
      {selectedOption === 'bankInfo' && personalInformation && (
        <BankInfo bankInformation={bankInformation} personalInformation={personalInformation} />
      )}
      {selectedOption === 'businessDocuments' && (
        <BusinessDocuments filesInformation={filesInformation} />
      )}
      {selectedOption === 'withdrawCredit' && (
        <WithdrawCredit
          personalInformation={personalInformation}
          bankInformation={bankInformation}
          obligadoInfo={obligadoInformation}
        />
      )}
      {selectedOption === 'accountState' && (
        <AccountStateApplicant
          createdAt={personalInformation.data.getApplicant.createdAt}
          userId={personalInformation.data.getApplicant.userId}
        />
      )}
    </PersonalInformationContainer>
  );
}
