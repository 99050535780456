import styled from 'styled-components/macro';
import { blackSphera, greenSp, supportOliveGreen } from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const AboutUsContainer = styled.section``;

export const AboutUsVideo = styled.section`
  display: flex;
  padding-bottom: 5%;
  width: 100%;
  > div {
    width: 80%;
    margin: 0 10%;
    > p {
      font-size: ${uIFontSize.xLarge};
      text-align: left;
      margin-top: 2%;
      > span {
        color: ${supportOliveGreen};
        font-weight: bold;
        font-size: ${uIFontSize.xLarge};
      }
    }
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    > div {
      width: 90%;
      margin: 0 5%;
      > p {
        font-size: ${uIFontSize.xxxLarge};
        > span {
          font-size: ${uIFontSize.xxxLarge};
        }
      }
    }
  }

  @media (min-width: 2561px) {
    > div {
      width: 90%;
      margin: 0 5%;
      > p {
        font-size: ${uIFontSize.xxxLarge};
        > span {
          font-size: ${uIFontSize.xxxLarge};
        }
      }
    }
  }

  @media (min-width: 1440px) and (max-width: 1900px) {
    > div {
      width: 90%;
      margin: 0 5%;
      > p {
        font-size: ${uIFontSize.xxLarge};
        > span {
          font-size: ${uIFontSize.xxLarge};
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    padding-bottom: 5%;
    > div {
      width: 96%;
      margin: 0 2%;
      > p {
        font-size: ${uIFontSize.large};
        text-align: left;
        > span {
          color: ${supportOliveGreen};
          font-weight: bold;
          font-size: ${uIFontSize.large};
        }
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    div {
      p {
        font-size: ${uIFontSize.large};
        > span {
          font-size: ${uIFontSize.large};
        }
      }
    }
  }
`;

export const FintechSection = styled.section`
  display: flex;
  > div {
    width: 50%;
    padding: 2.5% 2.5% 2.5% 5%;
    background-color: ${blackSphera};

    > h3 {
      margin-bottom: 2.5%;
      color: white;
      font-size: ${uIFontSize.xxxLarge};

      > span {
        color: ${greenSp};
        font-size: ${uIFontSize.xxxLarge};
      }
    }

    > p {
      margin-bottom: 2.5%;
      width: 95%;
      color: white;
      font-size: ${uIFontSize.large};
    }

    > p:nth-child(3) {
      margin-bottom: 2.5%;
      width: 95%;
      color: white;
      font-size: ${uIFontSize.large};

      > span {
        font-weight: bold;
        font-size: ${uIFontSize.large};
      }
    }
  }

  > figure {
    width: 50%;
    height: 400px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 2561px) {
    > div {
      > h3 {
        font-size: 3rem;

        > span {
          font-size: 3rem;
        }
      }

      > p {
        font-size: ${uIFontSize.xxLarge};
      }

      > p:nth-child(3) {
        font-size: ${uIFontSize.xxLarge};

        > span {
          font-size: ${uIFontSize.xxLarge};
        }
      }
    }
    > figure {
      height: 505px;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      padding: 6% 4% 4% 5%;

      > h3 {
        margin-bottom: 5%;
        font-size: 4vw;

        > span {
        }
      }
      > p:nth-child(2) {
        margin-bottom: 5%;
      }

      > p:nth-child(3) {
        width: 98%;

        > span {
        }
      }
    }
    > figure {
      width: 50%;
      height: auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      padding: 2.5% 2.5% 2.5% 5%;

      > h3 {
        margin-bottom: 5%;
        font-size: 9vw;

        > span {
        }
      }
      > p:nth-child(2) {
        margin-bottom: 4%;
        width: 100%;
        font-size: 4.2vw;
      }
      > p:nth-child(3) {
        margin-bottom: 4%;
        width: 100%;
        font-size: 4.2vw;
      }
    }
    > figure {
      width: 100%;
      height: auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const ImpactSection = styled.section`
  display: flex;
  flex-direction: row-reverse;

  > div {
    width: 50%;
    padding: 2.5% 2.5% 2.5% 5%;
    background-color: ${greenSp};

    > h3 {
      margin-bottom: 2.5%;
      color: #576176;
      font-size: ${uIFontSize.xxxLarge};
      color: ${blackSphera};
      > span {
        font-size: ${uIFontSize.xxxLarge};
      }
    }

    > p {
      margin-bottom: 2.5%;
      width: 95%;
      color: ${blackSphera};
      font-size: ${uIFontSize.large};
    }
  }

  > figure {
    width: 50%;
    height: 415px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 2561px) {
    > div {
      > h3 {
        font-size: 3rem;

        > span {
          font-size: 3rem;
        }
      }

      > p {
        font-size: ${uIFontSize.xxLarge};
      }
    }
    > figure {
      height: 480px;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      padding: 6% 4% 4% 5%;

      > h3 {
        margin-bottom: 5%;
        font-size: 4vw;
        > span {
        }
      }

      > p:nth-child(2) {
        width: 98%;
      }
    }

    > figure {
      width: 50%;
      height: auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    > div {
      width: 100%;
      padding: 2.5% 2.5% 2.5% 5%;

      > h3 {
        margin-bottom: 5%;
        color: ${blackSphera};
        font-size: 9vw;

        > span {
        }
      }

      > p:nth-child(2) {
        margin-bottom: 5%;
        width: 100%;
        color: ${blackSphera};
        font-size: 4.2vw;
      }
    }

    > figure {
      width: 100%;
      height: auto;
      > img {
      }
    }
  }
`;

export const OurValueOfferTitle = styled.section`
  > h2 {
    padding: 2% 0 3% 2%;
    text-align: center;
    font-size: 3.5vw;
    color: ${blackSphera};
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;

    > h2 {
      font-size: 7vw;
    }
  }
`;

export const PymesInvestorsSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    display: flex;
  }
`;

export const Pymes = styled.div`
  margin: 0 5%;
  width: 90%;
  color: ${blackSphera};

  > p {
    width: 70%;
    margin: 2% 0 5% 2%;
    font-size: 1.2vw;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      width: 77%;
      font-size: 1.4vw;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 2% 0;
    > p {
      width: 90%;
      margin: 2% 0 0% 5%;
      font-size: 3.5vw;
    }
  }
`;

export const PymesTitle = styled.div`
  display: flex;
  flex-direction: row;
  > h2 {
    width: 50%;
    font-size: 1.5vw;
  }
  > h3 {
    width: 25%;
    font-size: 1.5vw;
  }

  > figure {
    width: 50px;
    height: auto;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      width: 50%;
      font-size: 2vw;
    }
    > figure {
      width: 150px;
      height: auto;
      > img {
        width: 80%;
        height: auto;
      }
    }
    > h3 {
      width: 25%;
      font-size: 2vw;
    }
  }

  @media (max-width: 600px) {
    margin: 5%;
    > h2 {
      font-size: 3.5vw;
    }
    > figure {
      width: 120px;
      height: auto;
      > img {
        width: 80%;
        height: auto;
      }
    }
    > h3 {
      width: 25%;
      font-size: 3.5vw;
    }
  }
`;

export const PymesInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > div {
    margin-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1% 0;
    background-color: #f8f8f9;
    > p {
      width: 50%;
    }
    > h4 {
      width: 25%;
      font-weight: normal;
    }
    > h3 {
      color: ${greenSp};
    }
  }
  > div:nth-child(2) {
    background-color: white;
  }
  > p {
    text-align: left;
  }

  @media (min-width: 2561px) {
    > div {
      > p {
        font-size: ${uIFontSize.xxLarge};
      }
      > h4 {
        font-size: ${uIFontSize.xxLarge};
      }
      > h3 {
        font-size: ${uIFontSize.xxLarge};
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > p {
        font-size: ${uIFontSize.small};
      }
      > h4 {
        font-size: ${uIFontSize.small};
      }
      > h3 {
        font-size: ${uIFontSize.small};
      }
    }

    > div:nth-child(2) {
      > p {
        font-size: ${uIFontSize.small};
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      > p {
        font-size: 3.5vw;
        padding-right: 5%;
      }
      > h4 {
        font-size: 3.5vw;
      }
      > h3 {
        font-size: 3.5vw;
      }
    }
  }
`;

export const Investors = styled.div`
  color: #576176;
  margin: 0 5%;
  width: 90%;
  color: ${blackSphera};

  > p {
    width: 70%;
    margin: 2% 0 5% 2%;
    font-size: 1.2vw;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      width: 77%;
      font-size: 1.4vw;
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 2% 0;
    > p {
      width: 90%;
      margin: 2% 0 0% 5%;
      font-size: 3.5vw;
    }
  }
`;

export const InvestorsTitle = styled.div`
  display: flex;
  flex-direction: row;
  > h2 {
    width: 50%;
    font-size: 1.5vw;
  }
  > h3 {
    width: 25%;
    font-size: 1.5vw;
  }

  > figure {
    width: 50px;
    height: auto;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      width: 50%;
      font-size: 2vw;
    }
    > figure {
      width: 150px;
      height: auto;
      > img {
        width: 80%;
        height: auto;
      }
    }
    > h3 {
      width: 25%;
      font-size: 2vw;
    }
  }

  @media (max-width: 600px) {
    margin: 5%;
    > h2 {
      font-size: 3.5vw;
    }
    > figure {
      width: 120px;
      height: auto;
      > img {
        width: 80%;
        height: auto;
      }
    }
    > h3 {
      width: 25%;
      font-size: 3.5vw;
    }
  }
`;

export const InvestorsInfo = styled.div`
  display: flex;
  > div {
    margin-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 1% 0;
    background-color: #f8f8f9;
    > p {
      width: 50%;
    }
    > h4 {
      width: 25%;
      font-weight: normal;
    }
    > h3 {
      color: ${greenSp};
    }
  }
  > div:nth-child(2) {
    background-color: white;
  }

  @media (min-width: 2561px) {
    > div {
      > p {
        font-size: ${uIFontSize.xxLarge};
      }
      > h4 {
        font-size: ${uIFontSize.xxLarge};
      }
      > h3 {
        font-size: ${uIFontSize.xxLarge};
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > p {
        font-size: ${uIFontSize.small};
      }
      > h4 {
        font-size: ${uIFontSize.small};
      }
      > h3 {
        font-size: ${uIFontSize.small};
      }
    }
    > div:nth-child(2) {
      > p {
        font-size: ${uIFontSize.small};
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    > div {
      margin-left: 5%;
      height: 90px;
      > p {
        font-size: 3.5vw;
        padding-right: 5%;
      }
    }
  }
`;

export const OurPartners = styled.section`
  background-color: #e5e5e5;
  padding: 2% 5%;

  > h3 {
    text-align: center;
    color: ${blackSphera};
    font-size: 3vw;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3 {
    }
  }

  @media (max-width: 600px) {
    > h3 {
      font-size: 7vw;
    }
  }
`;

export const OurPartnersCardCont = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding-left: 8%;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
  }
`;

export const OurPartnersCard = styled.div`
  width: 25%;
  background-color: white;
  margin-right: 8%;
  margin-top: 5%;
  padding-bottom: 2.5%;
  -webkit-box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);

  > p {
    color: #576176;
    font-size: 1.2vw;
    width: 90%;
    margin: auto;
    padding-top: 0%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    height: 350px;

    > p {
      font-size: 1.3vw;
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    height: 350px;

    > p {
      font-size: 3.5vw;
      width: 90%;
    }
  }
`;
export const OurPartnerFigureAfinco = styled.figure`
  width: 100%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;

export const OurPartnerFigureAii = styled.figure`
  width: 60%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 65%;

    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;

export const OurPartnerFigureMef = styled.figure`
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;
export const OurPartnerFigureBid = styled.figure`
  width: 100%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;
export const OurPartnerFigureCcmx = styled.figure`
  width: 90%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 75%;

    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;
export const OurPartnerFigurePactoGlobal = styled.figure`
  width: 90%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 68%;

    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;

export const OurPartnerFigureBiofin = styled.figure`
  width: 90%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;

export const OurPartnerFigureFintech = styled.figure`
  width: 90%;
  margin: auto;
  padding: 10%;

  > img {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
    }
  }

  @media (max-width: 600px) {
    > img {
    }
  }
`;

export const DevelopmentGoals = styled.section`
  display: flex;
  margin-top: 5%;
  padding: 2%;
  -webkit-box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-top: 5%;
    padding: 2%;
  }
`;

export const DevelopmentGoalsImage = styled.div`
  width: 20%;

  > a {
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > a {
    }
  }

  @media (max-width: 600px) {
    width: 60%;
    margin: auto;

    > a {
    }
  }
`;

export const DevelopmentGoalsLogoONU = styled.figure`
  display: flex;
  justify-content: center;
  margin: 5% 0 8% 0;

  > img {
    width: 70%;
    height: 70%;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    margin: 5% 0 8% 0;

    > img {
      width: 60%;
      height: 60%;
    }
  }

  @media (max-width: 600px) {
    margin: 5% 0 8% 0;

    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const DevelopmentGoalsLogos589 = styled.figure`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 8%;

  > img {
    object-fit: cover;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
      width: 30%;
      height: 30%;
    }
  }

  @media (max-width: 600px) {
  }
`;

export const DevelopmentGoalsLogos131415 = styled.figure`
  display: flex;
  justify-content: space-evenly;

  > img {
    object-fit: cover;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
      width: 30%;
      height: 30%;
    }
  }

  @media (max-width: 600px) {
  }
`;

export const DevelopmentGoalsInfo = styled.div`
  width: 80%;
  padding-top: 2.5%;

  > p {
    padding: 5% 5% 0 5%;
    width: 100%;
    font-size: 1.2vw;
    color: #576176;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    padding-top: 2%;

    > p {
      padding: 1%;
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    > p {
      padding: 3%;
      width: 100%;
      font-size: 4.2vw;
    }
  }
`;

export const Suma = styled.section`
  background-color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5%;
  padding: 2%;
  -webkit-box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 5px 10px 5px rgba(0, 0, 0, 0.05);

  > a {
    width: 20%;
    height: 100%;

    > figure {
      > img {
        width: 80%;
        height: 20%;
      }
    }
  }

  > div {
    width: 80%;

    > p {
      width: 100%;
      font-size: 1.2vw;
      color: #576176;
      line-height: 25px;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > a {
      > figure {
        > img {
        }
      }
    }

    > div {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    padding: 2%;

    > a {
      width: 60%;
      > figure {
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
    > div {
      width: 90%;

      > p {
        width: 100%;
        font-size: 4.2vw;
        line-height: 25px;
      }
    }
  }
`;
