import axios from 'axios';
import { url } from 'src/environments';

type TypeOfUser = 'investor' | 'applicant';

export type GeolocationType = {
  date: string;
  latitude: string;
  longitude: string;
  accuracy: string;
};

export const updateGeolocation = async (
  id: string,
  typeOfUser: TypeOfUser,
  geolocation: GeolocationType
) => {
  const input = {
    id,
    typeOfUser,
    geolocation: {
      date: geolocation.date,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      accuracy: geolocation.accuracy
    }
  };
  const response = await axios.post(`${url}/admin/dynamo/update`, {
    type: 'update_array',
    input
  });
  return response;
};
