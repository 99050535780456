import { HomeContainer } from './home.styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { CalculatorSection } from 'src/components/features/landing/home/CalculatorSection/CalculatorSection';
import { InsightsSection } from 'src/components/features/landing/home/InsightsSection/InsightsSection';
import { InvestmentShowcaseSection } from 'src/components/features/landing/home/InvestmentShowcaseSection/InvestmentShowcase';
import { CreditShowcaseSection } from 'src/components/features/landing/home/CreditShowcaseSection/CreditShowcase';
import { OurPartnersSection } from 'src/components/features/landing/home/OurPartnersSection/OurPartners';

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn } = useSelector((state: RootState) => state.user);
  const isSessionExpired = location.search.includes('sessionExpired=true');
  const [showSessionExpiredModal, setShowSessionExpiredModal] = useState(
    isSessionExpired && !isSignedIn
  );

  useEffect(() => {
    const isSessionExpired = location.search.includes('sessionExpired=true');
    setShowSessionExpiredModal(isSessionExpired && !isSignedIn);
  }, [location, isSignedIn]);

  const handleModalClose = () => {
    navigate('/');
    setShowSessionExpiredModal(false);
  };

  return (
    <HomeContainer>
      <ComposedModal
        messageTitle="Tu sesión expiró"
        message="Pulsa el botón para iniciar sesión nuevamente."
        type="warning"
        isOpen={showSessionExpiredModal}
        onClose={handleModalClose}
        onOverlayClose={true}
        buttons={[
          { label: 'Iniciar sesión', onClick: () => navigate('/iniciar-sesion') },
          {
            label: 'Cancelar',
            onClick: handleModalClose,
            color: 'secondary'
          }
        ]}
      />
      <CalculatorSection />
      <InsightsSection />
      <InvestmentShowcaseSection />
      <CreditShowcaseSection />
      <OurPartnersSection />
    </HomeContainer>
  );
};
