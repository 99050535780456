import { FC } from 'react';
import { NavMenuListProps } from './navMenuList.interface';
import { NavMenuListWrapper } from './navMenuList.styles';

export const NavMenuList: FC<NavMenuListProps> = (props) => {
  const { callback, showMenuList, renderItems } = props;

  return (
    <NavMenuListWrapper
      className={`${showMenuList ? 'is-active' : ''}`}
      onClick={callback}
      showMenuList={showMenuList}
      renderItems={renderItems}
      callback={callback}
    >
      <ul className="list-item">{renderItems()}</ul>
    </NavMenuListWrapper>
  );
};
