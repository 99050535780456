import styled from 'styled-components/macro';
import {
  whiteSphera,
  blackSphera,
  brandBlack,
  greenSp,
  graySphera,
  grayPlaceholder
} from 'src/utilities/UIStyles/variables/colors';

export const DashboardInvestmentContainer = styled.section`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  > h3 {
    width: 80%;
    margin: 2% 10%;
    color: ${greenSp};
    text-align: center;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 80%;
  margin: 2% 10%;
  > h3 {
    width: 90%;
    margin: 2% 5%;
    color: ${brandBlack};
    text-align: center;
  }
  > button {
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid ${grayPlaceholder};
    color: ${graySphera};
    text-align: center;
    padding: 0.5%;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  padding: 5% 0 2.5% 10%;

  > figure {
    width: 85px;
    height: 85px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    color: ${blackSphera};
    font-size: 2.5vw;
    width: 30%;
  }

  > p {
    color: ${blackSphera};
    font-size: 1.2vw;
    width: 40%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure {
      > img {
      }
    }

    > h2 {
    }

    > p {
    }
  }

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
    padding: 25% 0 2.5% 0%;

    > figure {
      > img {
      }
    }

    > h2 {
      font-size: 6vw;
      width: 100%;
      margin-right: 0;
      margin-bottom: 5%;
      text-align: center;
    }

    > p {
      text-align: center;
      font-size: 4vw;
      width: 90%;
      margin-bottom: 5%;
    }
  }
`;

export const DashboardInvestmentInfo = styled.div`
  width: 90%;
  margin: 1.5% auto 2.5% auto;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1%;

    > h3 {
      font-size: 1.3vw;
      color: ${blackSphera};
      width: 30%;
      padding-bottom: 1%;
      border-bottom: 1px solid ${blackSphera};
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    padding-top: 1%;
    padding-bottom: 2%;

    > p {
      width: 30%;
      font-size: 1.3vw;
      color: ${blackSphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }
    }

    > div:nth-child(2) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 2.5%;

      > h3 {
        font-size: 4vw;
        text-align: center;
      }
    }

    > div:nth-child(2) {
      padding-top: 2.5%;
      padding-bottom: 5%;

      > p {
        font-size: 4vw;
        text-align: center;
      }
    }
  }
`;

export const DashboardInvestmentTable = styled.table`
  width: 90%;
  margin: 5% auto;
  border: 0px;
  border-collapse: collapse;
  > tbody {
    > tr {
      > th {
        font-size: 1.2vw;
        color: ${whiteSphera};
        vertical-align: text-top;
        text-align: left;
        padding: 1% 1.5% 1% 0.5%;
        background-color: ${blackSphera};
      }
    }
    > tr {
      > td {
        color: ${brandBlack};
        font-size: 1vw;
        text-align: left;
        padding: 1%;
        > button {
          font-size: 1.2vw;
        }
        > h2 {
          width: 100%;
          text-align: center;
        }
      }
    }
    > tr:nth-child(2n) {
      background-color: #f0f5ff;
    }

    > tr:nth-child(3n) {
      background-color: #e1e8f6;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > tbody {
      > tr {
        > th {
          font-size: 1.5vw;
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > tbody {
      > tr {
        > th {
          font-size: 2.5vw;
        }

        > td {
          font-size: 3vw;
          > h2 {
            font-size: 3vw;
          }
        }

        > td:last-child {
          > button {
            font-size: 3vw;
          }
        }
      }
    }
  }
`;

export const DashboardInvestmentButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 2.5% auto;

  > a:nth-child(1) {
    font-size: 1.2vw;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    color: ${blackSphera};
    width: 25%;
    padding: 1%;
    border: 1px solid ${blackSphera};
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
  }

  > a:nth-child(2) {
    font-size: 1.2vw;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    color: ${whiteSphera};
    width: 25%;
    padding: 1%;
    border: none;
    border-radius: 5px;
    background-color: ${blackSphera};
    cursor: pointer;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > a:nth-child(1) {
    }

    > a:nth-child(2) {
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 10% auto;

    > a:nth-child(1) {
      font-size: 4.5vw;
      width: 45%;
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
      width: 45%;
    }
  }
`;
