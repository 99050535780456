import { useState, useEffect } from 'react';
import {
  fetchPersonalInformation,
  fetchAddressInformation,
  fetchBeneficiariesInformation,
  addBeneficiary,
  fetchMifielInformation
} from 'src/store/Profile/profile.slice';
import { PersonalInformationContainer, Banner, Options, Option } from './myProfile.styles';
import GeneralInfo from './generalInfo/generalInfo';
import Address from './address/address';
import Beneficiaries from './beneficiaries/beneficiaries';
import ProfessionalInfo from './professionalInfo/professionalInfo';
import { useDispatch, useSelector } from 'react-redux';
import ContractsInfo from 'src/views/investor/Dashboard/myProfile/contractsInfo/contractInfo';
import AccountState from './accountState/AccountState';
import { PlatformBanner } from 'src/utilities/UILibrary';

export default function MyProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const addressInformation = useSelector((state) => state.profile.addressInformation);
  const beneficiariesInformation = useSelector((state) => state.profile.beneficiariesInformation);

  const [generalInfo, setGeneralInfo] = useState(true);
  const [address, setAddress] = useState(false);
  const [professionalInfo, setProfessionalInfo] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState(false);
  const [contracts, setContracts] = useState(false);
  const [accountState, setAccountState] = useState(false);

  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
      dispatch(fetchAddressInformation(user.id));
      dispatch(fetchBeneficiariesInformation(user.id));
      dispatch(fetchMifielInformation(user.id));
    }
  }, [user.id, dispatch]);

  const onAddBeneficiary = (beneficiary) => {
    dispatch(addBeneficiary(beneficiary));
  };

  const showGeneralInfo = () => {
    setGeneralInfo(true);
    setAddress(false);
    setProfessionalInfo(false);
    setBeneficiaries(false);
    setContracts(false);
    setAccountState(false);
  };

  const showAddress = () => {
    setGeneralInfo(false);
    setAddress(true);
    setProfessionalInfo(false);
    setBeneficiaries(false);
    setContracts(false);
    setAccountState(false);
  };

  const showProfessionalInfo = () => {
    setGeneralInfo(false);
    setAddress(false);
    setProfessionalInfo(true);
    setBeneficiaries(false);
    setContracts(false);
    setAccountState(false);
  };

  const showBeneficiaries = () => {
    setGeneralInfo(false);
    setAddress(false);
    setProfessionalInfo(false);
    setBeneficiaries(true);
    setContracts(false);
    setAccountState(false);
  };

  const showContracts = () => {
    setGeneralInfo(false);
    setAddress(false);
    setProfessionalInfo(false);
    setBeneficiaries(false);
    setContracts(true);
    setAccountState(false);
  };

  const showAccountState = () => {
    setGeneralInfo(false);
    setAddress(false);
    setProfessionalInfo(false);
    setBeneficiaries(false);
    setContracts(false);
    setAccountState(true);
  };

  return (
    <PersonalInformationContainer>
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_dash_my_profile_banner.png`}
        imgAlt="Finsphera ícono perfil"
        title="Mi perfil"
        description="En tu perfil podrás completar tus datos como inversionista FinSphera y así realizar
        acciones en la plataforma."
        className="onboarding-banner"
      />
      <Options>
        <Option isSelected={generalInfo} onClick={showGeneralInfo}>
          Información General
        </Option>
        <Option isSelected={address} onClick={showAddress}>
          Dirección
        </Option>
        <Option isSelected={professionalInfo} onClick={showProfessionalInfo}>
          Información Profesional
        </Option>
        {user.typeOfPerson === 'FISICA' && (
          <Option isSelected={beneficiaries} onClick={showBeneficiaries}>
            Beneficiarios
          </Option>
        )}
        <Option isSelected={contracts} onClick={showContracts}>
          Contratos
        </Option>
        <Option isSelected={accountState} onClick={showAccountState}>
          Estados de Cuenta
        </Option>
      </Options>
      {generalInfo && <GeneralInfo personalInformation={personalInformation} />}
      {address && (
        <Address
          addressInformation={addressInformation}
          personalInformation={personalInformation}
        />
      )}
      {professionalInfo && <ProfessionalInfo personalInformation={personalInformation} />}
      {user.typeOfPerson === 'FISICA' && beneficiaries && (
        <Beneficiaries
          beneficiariesInformation={beneficiariesInformation}
          onRefreshData={() => dispatch(fetchBeneficiariesInformation(user.id))}
          addBeneficiary={onAddBeneficiary}
        />
      )}
      {contracts && <ContractsInfo personalInformation={personalInformation} />}
      {accountState && (
        <AccountState
          createdAt={personalInformation?.data?.getInvestor?.createdAt}
          userId={personalInformation?.data.getInvestor?.userId}
        />
      )}
    </PersonalInformationContainer>
  );
}
