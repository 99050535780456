import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  satwsInfo,
  setLoadingState,
  updateInvestorCompany,
  updateOnboardingBlockingStatus
} from 'src/store/User/user.slice';
import { PersonalInformationContainer } from './biometrics.styles';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { FormValues } from './biometrics.types';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { fileToBase64 } from 'src/functions/fileToBase64';
import { setAppError } from 'src/store/app/app.slice';
import axios from 'axios';
import options from 'src/constants/simafOptionsInvestor';
import { url } from 'src/environments';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import { getInvestor_Onboarding } from 'src/graphql/customized_queries';
import { GetInvestorQuery } from 'src/graphql/API';
import { API } from 'aws-amplify';
import { renderShowInfoModal } from 'src/components/shared/componentFactory/renderShowInfoModal';
import { sendPLDEmail, updateResourcesProvider } from 'src/requests';
import { CountrySelector } from 'src/utilities/UILibrary';
import { blockedCountriesInSpanish } from 'src/constants/blockedCountries';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import economicActivitiesGeneral from 'src/constants/economicActivitiesGeneral';
import economicActivitiesMapping from 'src/constants/economicActivitiesMapping';
import Resources from 'src/components/shared/modals/resourcesProvider/resourcesProvider';
import generateUUID from 'src/functions/uuid';

export default function BiometricsMoral() {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const onboarding = user.onboarding;
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  //SATWS
  const [type, setType] = useState<string>();
  const isPEP = options[5];
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    register
  } = useForm<FormValues>();

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [resourcesModal, setResourcesModal] = useState(false);
  const [fullName, setFullName] = useState<string | null>();
  const { nextStep } = useManageSteps();
  const isPEPOptions = isPEP.map((isPep) => ({
    value: isPep.code,
    label: isPep.name
  }));
  const resourcesId = generateUUID();

  //Fetch para verificar si Metamap ya se ejecutó o no para no volverse a ejecutar
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = (await API.graphql({
          query: getInvestor_Onboarding,
          variables: { id: user.id }
        })) as { data: GetInvestorQuery };
        setFullName(data?.getInvestor?.personalInformation?.fullName);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id, fullName]);
  const resourceProviderChecked = watch('resourceProvider');
  const realOwnerChecked = watch('realOwner');

  //FIle to base64
  const handleFile = async (file: File) => {
    if (!file) return;

    try {
      const base64String = await fileToBase64(file);
      return base64String;
      // Aquí puedes hacer uso del base64String, por ejemplo, enviarlo a un servidor
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };

  const selectOptions = [
    { value: 'ciec', label: 'CIEC' },
    { value: 'efirma', label: 'e.firma' }
  ];

  const economicActivity = watch('economicActivity');
  const optionsBusinessName = economicActivitiesGeneral.map((activity, index) => ({
    value: activity,
    label: activity,
    key: index
  }));
  const politicallyExposedValue = watch('politicallyExposed');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const isBlockedCountry = blockedCountriesInSpanish.includes(data.residenceCountry);

    const isResourceProviderOrRealOwner = data.resourceProvider || data.realOwner;
    const { certificate, privateKey } = data;
    const certificateBase64 = await handleFile(certificate);
    const privateKeyBase64 = await handleFile(privateKey);
    //Esto se manda a satws
    let body = {};
    if (type === 'ciec') {
      body = {
        type: type,
        rfc: data.RFC,
        password: data.password,
        id: user.id,
        typeOfUser: 'investor'
      };
    }

    if (type === 'efirma') {
      body = {
        type: type,
        password: data.password,
        id: user.id,
        typeOfUser: 'investor',
        certificate: certificateBase64,
        privateKey: privateKeyBase64,
        rfc: data.RFC
      };
    }
    const input = {
      type: 'investor_moral_onboarding_primerPaso',
      input: {
        id: user.id,
        personalInformation: {
          RFC: data.RFCper,
          isPEP: data.politicallyExposed,
          pepDate: data.pepDate || null,
          pepName: data.pepName || null,
          pepCharge: data.pepCharge || null,
          actOnTheirOwn: data.actOnTheirOwn,
          countryOfResidence: data.residenceCountry
        },
        companyInformation: {
          generalActivity: data.economicActivity,
          specificActivity: data.specificEconomicActivity,
          numeroDeSerie: data.serialNumber
        }
      }
    };
    try {
      dispatch(setLoadingState(true));
      await dispatch(satwsInfo(body));
      await dispatch(updateInvestorCompany(input)).unwrap();
      await axios.get(`${url}/admin/address-parts-company?id=${user.id}`);

      if (isBlockedCountry) {
        await dispatch(
          updateOnboardingBlockingStatus({
            type: 'investor',
            input: {
              id: user.id,
              onboarding: {
                isBlocked: true,
                reasonOfBlocking: 'countryBlocked',
                currentStep: onboarding.currentStep
              }
            }
          })
        );

        return navigate('/pagina-validacion');
      }

      if (isResourceProviderOrRealOwner) {
        await updateResourcesProvider('investor', user.id);
        await sendPLDEmail(user.id, 'investor');
        return setResourcesModal(true);
      }

      await nextStep();
      navigate('/inversionista/onboarding-risks');
    } catch {
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const handleType = (value: string | number | readonly string[]) => setType(value.toString());

  return (
    <PersonalInformationContainer className="onboarding-view-content">
      {renderShowInfoModal(showInfoModal, () => setShowInfoModal(false))}
      <Resources
        resourcesModal={resourcesModal}
        setResourcesModal={setResourcesModal}
        resourcesId={resourcesId}
      />
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_personalInformation_banner.png`}
        imgAlt="Finsphera icono información personal"
        title="Datos personales y biométricos"
        description="Vamos a completar tu registro como inversionista, para ello necesitamos que nos proporciones la siguiente información:"
        className="onboarding-banner"
      />
      <Card className="onboarding-card" isForm isSlim>
        <Card.Row>
          <h3>Datos del apoderado legal</h3>
        </Card.Row>

        <Card.Row>
          <CountrySelector
            label="Selecciona tu país de residencia:"
            errorMessages={
              errors.residenceCountry && errors.residenceCountry.message
                ? [errors.residenceCountry.message]
                : []
            }
            placeholder="Selecciona una opción"
            {...register('residenceCountry', { required: 'Es un campo requerido.' })}
          />
        </Card.Row>

        <Card.Row>
          <Controller
            name="RFCper"
            control={control}
            rules={{
              minLength: {
                value: 13,
                message: 'RFC debe tener al menos 13 caracteres.'
              },
              required: 'Es un campo requerido.',
              pattern: {
                value: /^[A-ZÑ0-9]+$/,
                message: 'RFC inválido.'
              }
            }}
            render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
              <InputField
                maxLength={13}
                label="Ingresa el RFC:"
                placeholder="RFC"
                autoUpperCase
                onChangeInternalValue={onChange}
                errorMessages={error && error.message ? [error.message] : []}
                {...restFieldProps}
              />
            )}
          />
        </Card.Row>

        <Card.Row>
          <h3>Datos compañía o empresa</h3>
        </Card.Row>
        <Card.Row>
          <p>
            A continuación proporciónanos la CIEC o e.firma de la compañía para seguir con tu
            registro. Después de llenar la información da click en Siguiente.
          </p>
        </Card.Row>
        <Card.Row>
          <Controller
            name="type"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => {
              const handleCombinedChange = (value: string | number | readonly string[]) => {
                handleType(value);
                onChange(value);
              };

              return (
                <InputField
                  isDropdown
                  onChangeInternalValue={handleCombinedChange}
                  options={selectOptions}
                  wrapLabel
                  placeholder="Selecciona una opción"
                  errorMessages={error && error.message ? [error.message] : []}
                  {...restFieldProps}
                />
              );
            }}
          />
        </Card.Row>
        {type === 'ciec' && (
          <>
            <Card.Row>
              <Controller
                name="RFC"
                control={control}
                rules={{
                  required: 'Es un campo requerido.',
                  pattern: {
                    value: /^[A-ZÑ0-9]+$/,
                    message: 'Invalid RFC'
                  },
                  minLength: {
                    value: 12,
                    message: 'RFC debe tener al menos 12 caracteres'
                  }
                }}
                render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                  <InputField
                    label="Ingresa tu RFC:"
                    autoUpperCase
                    maxLength={13}
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...restFieldProps}
                  />
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="password"
                control={control}
                rules={{ required: 'Es un campo requerido.' }}
                render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
                  <InputField
                    label="Ingresa tu contraseña:"
                    type="password"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...restFieldProps}
                  />
                )}
              />
            </Card.Row>
          </>
        )}
        {type === 'efirma' && (
          <>
            <Card.Row>
              <Controller
                name="certificate"
                control={control}
                rules={{ required: 'Es un campo requerido.' }}
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <InputUpload
                    label="Certificado (.cer)"
                    id="certificate"
                    accept=".cer"
                    name={name}
                    onChange={(e) => {
                      onChange(e.target.files?.[0]);
                    }}
                    errorMessages={error && error.message ? [error.message] : []}
                  >
                    Subir archivo
                  </InputUpload>
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="privateKey"
                control={control}
                rules={{ required: 'Es un campo requerido.' }}
                render={({ field: { onChange, name }, fieldState: { error } }) => (
                  <InputUpload
                    label="Llave privada (.key)"
                    name={name}
                    accept=".key"
                    id="private-key"
                    onChange={(e) => {
                      onChange(e.target.files?.[0]);
                    }}
                    errorMessages={error && error.message ? [error.message] : []}
                  >
                    Subir archivo
                  </InputUpload>
                )}
              />
            </Card.Row>
          </>
        )}
        <Card.Row>
          <Controller
            name="economicActivity"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Principal actividad económica:"
                placeholder="Selecciona una opción"
                isDropdown
                options={optionsBusinessName}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>

        {economicActivity && economicActivitiesMapping[economicActivity] && (
          <Card.Row>
            <Controller
              name="specificEconomicActivity"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  label="Selecciona tu actividad específica:"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={economicActivitiesMapping[economicActivity].map(({ name }) => ({
                    value: name,
                    label: name
                  }))}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>
        )}
        <Card.Row>
          <Controller
            name="serialNumber"
            control={control}
            rules={{
              minLength: {
                value: 20,
                message: 'El número de serie debe tener 20 caracteres.'
              },
              required: 'Es un campo requerido.',
              pattern: {
                value: /^[A-ZÑ0-9]+$/,
                message: 'Número de serie inválido.'
              }
            }}
            render={({ field: { onChange, ...restFieldProps }, fieldState: { error } }) => (
              <InputField
                label="Ingresa el No. de serie dentro del comprobante de generación del certificado digital de firma electrónica:"
                placeholder="No. de serie"
                regex={/^[A-ZÑ0-9]+$/}
                onChangeInternalValue={onChange}
                autoUpperCase
                maxLength={20}
                errorMessages={error && error.message ? [error.message] : []}
                wrapLabel
                {...restFieldProps}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="politicallyExposed"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                wrapLabel
                label="¿Tú o alguno de tus familiares, hasta el segundo grado, desempeña o ha desempeñado durante el último año, funciones públicas en un país extranjero o en territorio nacional, en puestos ejecutivos en empresas gubernamentales, estatales, federales o municipales o en partidos políticos?"
                placeholder="Selecciona una opción"
                isDropdown
                options={isPEPOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>

        {(politicallyExposedValue === '2' || politicallyExposedValue === '3') && (
          <>
            <Card.Row>
              <Controller
                name="pepName"
                control={control}
                rules={{
                  minLength: {
                    value: 5,
                    message: 'El nombre debe tener al menos 5 caracteres'
                  },
                  required: 'Es un campo requerido.'
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Nombre de la persona que se desempeñó como PEP:"
                    placeholder="Nombre"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="pepCharge"
                control={control}
                rules={{
                  minLength: {
                    value: 5,
                    message: 'El cargo debe tener al menos 5 caracteres'
                  },
                  required: 'Es un campo requerido.'
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Ingresa el cargo desempeñado:"
                    placeholder="Descripción"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
            <Card.Row>
              <Controller
                name="pepDate"
                control={control}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    type="date"
                    label="Fecha de término de función (si aplica)"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
          </>
        )}

        <Card.Row itemPosition="left">
          <div>
            <Checkbox
              label="Selecciona esta casilla si una persona diferente a la empresa que representas aportará recursos a esta cuenta sin ser el titular"
              {...register('resourceProvider')}
              disabled={realOwnerChecked}
            />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowInfoModal(true);
              }}
            >
              Más información
            </a>
          </div>
        </Card.Row>

        <Card.Row itemPosition="left">
          <div>
            <Checkbox
              label="Selecciona esta casilla si una persona diferente a la empresa que representas obtendrá los beneficios de la cuenta y es el verdadero dueño de los recursos"
              {...register('realOwner')}
              disabled={resourceProviderChecked}
            />
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowInfoModal(true);
              }}
            >
              Más información
            </a>
          </div>
        </Card.Row>

        <Card.Row itemPosition="left">
          <Checkbox
            label="Manifiesto que la empresa que represento actúa por cuenta propia"
            {...register('actOnTheirOwn', { required: 'Es un campo requerido para continuar.' })}
            errorMessages={
              errors.actOnTheirOwn && errors.actOnTheirOwn.message
                ? [errors.actOnTheirOwn.message]
                : []
            }
          />
        </Card.Row>

        <Card.Row itemPosition="left">
          <Checkbox
            label="Acepto los Términos y Condiciones y el Aviso de Privacidad."
            {...register('termsAndPrivacy', { required: true })}
            errorMessages={
              errors.termsAndPrivacy && [
                'Por favor acepta los términos y condiciones para continuar.'
              ]
            }
          />
        </Card.Row>
      </Card>
      <FormActionButtons submitAction={handleSubmit(onSubmit)} />
    </PersonalInformationContainer>
  );
}
