import styled from 'styled-components/macro';

import { whiteSphera, graySphera } from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalErrorUploadingInput = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalErrorUploadingInput ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalErrorUploadingInput ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 45%;
    padding: 2.5%;
    background-color: ${whiteSphera};
    border-radius: 5px;

    > figure:nth-child(1) {
      width: 25px;
      height: 25px;
      margin-left: 96%;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(2) {
      width: 120px;
      height: 120px;
      margin: 0 auto 2.5% auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > h2:nth-child(3) {
      color: ${graySphera};
      font-size: 2vw;
      text-align: center;
      margin: 2.5% auto 0 auto;
    }

    > p:nth-child(4) {
      color: ${graySphera};
      font-size: 1.2vw;
      text-align: center;
      width: 70%;
      margin: 2.5% auto 5% auto;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > figure:nth-child(1) {
        > img {
        }
      }

      > figure:nth-child(2) {
        > img {
        }
      }

      > h2:nth-child(3) {
      }

      > p:nth-child(4) {
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding: 5%;

      > figure:nth-child(1) {
        margin-left: 93%;

        > img {
        }
      }

      > figure:nth-child(2) {
        width: 80px;
        height: 80px;
        margin: 0 auto 5% auto;

        > img {
        }
      }

      > h2:nth-child(3) {
        font-size: 5vw;
        margin: 5% auto 0 auto;
      }

      > p:nth-child(4) {
        font-size: 4.5vw;
        width: 100%;
        margin: 5% auto 5% auto;
      }
    }
  }
`;
