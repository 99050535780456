import { useState, useCallback } from 'react';
import { GeolocationType } from 'src/requests';

export type GeolocationPositionType = {
  accuracy: string;
  latitude: string;
  longitude: string;
  date: string;
};

export const useGeolocation = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const getUserLocation = useCallback(async () => {
    if (!navigator.geolocation) {
      setError('Not supported');
    }

    setLoading(true);

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        })
      );

      setLoading(false);
      const currentDate = new Date().toISOString();

      return {
        accuracy: (position.coords.accuracy ?? 0).toString(),
        latitude: position.coords.latitude.toString(),
        longitude: position.coords.longitude.toString(),
        date: currentDate
      } as GeolocationType;
    } catch (err) {
      const error = err as GeolocationPositionError;
      setError(`Error (${error.code}): ${error.message}`);
      setLoading(false);
    }
  }, []);

  return { getUserLocation, error, loading };
};
