import { ThreatContainer, TextContainer } from './threat.styles';
import { Banner } from 'src/utilities/UILibrary';
import { Link } from 'react-router-dom';

export const ThreatValidation = () => {
  return (
    <ThreatContainer>
      <Banner>
        <h1>Validación de información</h1>{' '}
      </Banner>
      <TextContainer>
        <h2>
          El equipo de Finsphera está realizando una validación de los datos que nos proporcionaste.
        </h2>
        <p>
          Si tienes alguna duda contáctanos en:{' '}
          <Link to="mailto:atencionaclientes@finsphera.com">atencionaclientes@finsphera.com</Link>
        </p>
      </TextContainer>
    </ThreatContainer>
  );
};
