import styled from 'styled-components';
import { ApplicantCalculatorProps } from './applicantCalculator.types';
import { blackSphera, graySphera, grayLightSphera } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const ApplicantCalculatorContainer = styled(Card)<ApplicantCalculatorProps>`
  display: flex;
  max-width: 100%;
  margin-bottom: 2rem;
  gap: 4rem;

  h3 {
    color: ${blackSphera};
    font-size: ${uIFontSize.h3};
  }

  p {
    color: ${blackSphera};
    font-size: ${uIFontSize.paragraph};
  }

  input {
    font-size: ${uIFontSize.paragraph};
    color: ${blackSphera};
    max-width: 70%;
    height: 35px;
    margin-right: 2%;
    padding-left: 2.5%;
    border: 1px solid ${grayLightSphera};
    border-radius: 5px;
    accent-color: ${blackSphera};

    &::placeholder {
      color: ${blackSphera};
    }
  }

  .bold-text {
    font-weight: bold;
  }

  .error-message {
    color: red;
    font-size: ${uIFontSize.paragraph};
  }

  .calc-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 300px;

    > div {
      display: flex;
      justify-content: space-between;
    }

    .calc-form__input {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .calc-results {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    .calc-results--body {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;
