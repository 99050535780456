import { forwardRef } from 'react';
import { ProgressWheelContainer } from './styles';
import { ProgressWheelProps } from './types';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';

export const ProgressWheel = forwardRef<HTMLDivElement, ProgressWheelProps>((props, ref) => {
  const { percentage, ...restProps } = props;

  const strokeColor =
    percentage === 0
      ? colors.black.gray
      : percentage === 100
      ? colors.green.default
      : colors.orange.secondary;

  const strokeDashoffset = percentage === 0 ? 99 : 100 - percentage;

  return (
    <ProgressWheelContainer ref={ref} percentage={percentage} {...restProps}>
      <p className="value">{percentage}%</p>
      <svg>
        {/* Mask */}
        <circle
          cx="50%"
          cy="50%"
          r="32"
          pathLength="100"
          stroke="rgba(0, 0, 0, 0.05)"
          strokeWidth="10"
        />
        {/* Progress */}
        <circle
          cx="50%"
          cy="50%"
          r="32"
          className="progress"
          pathLength="100"
          stroke={strokeColor}
          strokeWidth="10"
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </ProgressWheelContainer>
  );
});

ProgressWheel.displayName = 'ProgressWheel';
