import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';

export const useIdleLogout = async (logoutFunction: () => void) => {
  const { groupName } = useSelector((state: RootState) => state.user.currentUser);
  const timeout = groupName === 'applicant' ? 600000 : 300000;

  let timer: NodeJS.Timeout | null = null;
  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(logoutFunction, timeout);
  };

  useEffect(() => {
    const events = ['mousemove', 'keydown', 'touchstart', 'scroll'];

    const resetTimerForEvents = () => {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
      resetTimer();
    };

    resetTimerForEvents();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [logoutFunction, timeout, resetTimer]);
};
