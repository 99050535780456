import { useLocation, useNavigate } from 'react-router-dom';

// This hook is used to redirect the user to the correct path after login but taking account the query params
export const useRedirectPath = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const redirectParam = queryParams.get('redirect');

  const redirectTo = (defaultPath: string) => {
    if (queryParams.has('redirect')) {
      let redirectPath = '';
      try {
        const redirectUrl = new URL(redirectParam || '', window.location.origin);
        if (redirectUrl.origin === window.location.origin) {
          redirectPath = redirectUrl.pathname;
        }
        return navigate(redirectPath);
      } catch (error) {
        console.error('Invalid redirect parameter:', error);
      }
    }

    return navigate(defaultPath);
  };

  return redirectTo;
};
