import { forwardRef, useMemo } from 'react';
import { MilestoneProgressProps } from './types';
import { MilestoneProgressContainer } from './styles';

const TOTAL_MILESTONES = 16;
const MILESTONES_POSITIONS = [1, 4, 7, 10, 13, 16];
const MILESTONES_IN_DAYS = 0.16;

export const MilestoneProgress = forwardRef<HTMLDivElement, MilestoneProgressProps>(
  (props, ref) => {
    const { percentage, ...restProps } = props;
    const milestones = useMemo(
      () =>
        Array(TOTAL_MILESTONES)
          .fill(0)
          .map((_, index) => {
            const condition = MILESTONES_POSITIONS.includes(index + 1);
            const days: { [key: number]: number } = {
              1: 0,
              4: 30,
              7: 60,
              10: 90,
              13: 120,
              16: 150
            };
            const actualDots = percentage * MILESTONES_IN_DAYS;

            return (
              <div className="container" key={index}>
                <div
                  className={
                    (condition ? 'day-milestone' : 'milestone') +
                    (actualDots >= index && percentage > 0 ? ' active' : '')
                  }
                />
                {condition ? <p>{days[index + 1]}D</p> : <p className="hidden">0</p>}
              </div>
            );
          }),
      [percentage]
    );

    return (
      <MilestoneProgressContainer ref={ref} percentage={percentage} {...restProps}>
        <div className="milestone-progress-parent">
          <div className="progress-bar" />
          {milestones}
        </div>
      </MilestoneProgressContainer>
    );
  }
);

MilestoneProgress.displayName = 'MilestoneProgress';
