import { API, graphqlOperation } from 'aws-amplify';
import { updateCampaign, deleteCampaign } from 'src/graphql/mutations';
import { getCampaign } from 'src/graphql/queries';
import { CreateCampaignDash, CreateObligadoSolidarioCust } from 'src/graphql/customized_mutations';
import axios from 'axios';
import { listCampaigns_Dash } from 'src/graphql/customized_queries';
import { url } from 'src/environments';

//Sección para creación de campañas
//Ejecución de endpoint para extracción de facturas
export const campaignInvoicesAPI = async (input) => {
  try {
    const response = await axios.post(`${url}/admin/satws/update-invoices`, input, {
      timeout: 30000
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Ejecución de endpoint para validar facturas UUID
export const validateInvoicesAPI = async (input) => {
  try {
    const response = await axios.post(`${url}/admin/satws/validate-uuid`, input);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Ejecución de endpoint para validar facturas que ya fueron utilizadas
export const usedInvoicesAPI = async (input) => {
  try {
    const response = await axios.post(`${url}/admin/satws/used-invoices`, input);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Ejecución de endpoint para validar facturas que ya fueron utilizadas
export const totalInvoicesAPI = async (input) => {
  try {
    const response = await axios.post(`${url}/admin/satws/invoices-total-amount `, input);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Ejecución de endpoint para guardar facturas en dynamo
export const saveInvoicesAPI = async (input) => {
  try {
    const response = await axios.post(`${url}/admin/satws/invoices-to-dynamodb`, input);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Ejecución creación de campaña en db después de validación de facturas
export const createNewCampaignAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: CreateCampaignDash,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
//////////////////

//Se crea tabla del Obligado Solidario desde el Onboarding para que Metamap pueda validar con el id
export const createObligadoSolidarioAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: CreateObligadoSolidarioCust,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const getSpecificCampaign = async (id) => {
  const rawResponse = await API.graphql(graphqlOperation(getCampaign, { id }));
  return rawResponse.data.getCampaign;
};

export const updateExistentCampaign = async (input) => {
  const rawResponse = await API.graphql(graphqlOperation(updateCampaign, { input }));
  return rawResponse.data.updateCampaign;
};

export const getlistCampaigns = async (id) => {
  const rawResponse = await API.graphql(graphqlOperation(listCampaigns_Dash, { id }));
  return rawResponse.data.listCampaigns;
};

export const deleteSpecificCampaign = async (input) => {
  const rawResponse = await API.graphql(graphqlOperation(deleteCampaign, { input }));
  return rawResponse.data.deleteCampaign;
};
