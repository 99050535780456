import styled from 'styled-components/macro';
import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayDarkSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const Contracts = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > div {
    display: flex;
    margin-bottom: 2.5%;
    > div {
      width: 33%;
      > p {
        color: ${brandBlack};
        font-size: ${uIFontSize.small};
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
  }
`;

export const ContractNull = styled.div`
  width: 100%;
  margin: 2%;
  text-align: center;
  > h1 {
    width: 100%;
    font-size: 1.6vw;
    color: ${greenSp};
  }
`;

export const FilterButtons = styled.div`
  width: 60%;
  margin: 2% 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 5%;
  }
`;

export const ContractSection = styled.div`
  width: 60%;
  margin: 2% 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 5%;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  display: flex;
  justify-content: center;
  border-collapse: collapse;
  background-color: ${whiteSphera};
  > h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 5%;
  }
  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: ${uIFontSize.medium};
    > a {
      color: ${grayDarkSphera};
      font-weight: bold;
    }
  }
  th {
    background-color: ${brandBlack};
    color: ${whiteSphera};
  }
  tr:nth-child(even) {
    background-color: #f0f5ff;
  }
  tr:nth-child(odd) {
    background-color: #e1e8f6;
  }
  button {
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
`;
