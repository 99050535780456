import styled from 'styled-components/macro';

import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  brandYellowgreen,
  grayPlaceholder,
  grayLightSphera,
  graySphera,
  neutralBlueGray
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ModalCampaignSuccess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalCampaignSuccess ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 20;
  animation: ${(props) => (props.showModalCampaignSuccess ? fadeIn : fadeOut)} 0.5s linear;

  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;

    > h3 {
      font-size: ${uIFontSize.h3};
      color: ${grayMediumSphera};
    }
    > h2 {
      font-size: ${uIFontSize.title};
      text-align: center;
      color: ${brandBlack};
      > span {
        color: ${greenSp};
        font-size: ${uIFontSize.title};
      }
    }
    > p {
      color: ${brandBlack};
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > a {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
        padding: 3% 8%;

        &:hover {
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const InfoContainer = styled.section`
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  p {
    width: 45%;
    color: ${brandBlack};
    font-size: ${uIFontSize.paragraph};
    margin-bottom: 5px;
  }
`;

export const ConfirmButton = styled.button`
  color: ${brandBlack};
  font-size: ${uIFontSize.paragraph};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandYellowgreen};
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const CloseButton = styled.button`
  color: ${whiteSphera};
  font-size: ${uIFontSize.paragraph};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandBlack};
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0;
  h3 {
    width: 30%;
    font-size: ${uIFontSize.paragraph};
    color: ${grayMediumSphera};
    margin-bottom: 5px;
    text-align: center;
    font-weight: 100;
  }
  > p {
    color: ${greenSp};
    text-align: center;
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
  }
`;

export const ComplementaryInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 70%;
  margin: 2% 0;
  padding: 2%;
  border: 1px solid ${brandBlack};
  border-radius: 10px;

  table {
    width: 100%;
    border-collapse: collapse;

    thead {
      background-color: ${brandBlack};
      color: ${whiteSphera};
    }

    th,
    td {
      padding: 8px;
      font-size: ${uIFontSize.paragraph};
      text-align: left;
      border-bottom: 1px solid ${brandBlack};
    }

    td {
      color: ${brandBlack};
    }
  }
`;

export const CampaignCode = styled.div`
  width: 90%;
  margin: 2.5% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  > p {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${greenSp};
    margin-bottom: 5%;
  }
  > input {
    font-size: ${uIFontSize.paragraph};
    color: ${grayPlaceholder};
    width: 80%;
    height: 35px;
    margin-bottom: 2.5%;
    padding-left: 2.5%;
    border: 1px solid ${grayLightSphera};
    border-radius: 5px;
  }
  > select {
    font-size: ${uIFontSize.paragraph};
    color: ${grayPlaceholder};
    width: 80%;
    height: 35px;
    margin-bottom: 2.5%;
    padding-left: 2.5%;
    border: 1px solid ${grayLightSphera};
    border-radius: 5px;
  }

  > h3 {
    font-size: ${uIFontSize.paragraph};
    color: ${graySphera};
    margin-bottom: 2.5%;
    text-align: center;
  }

  > h2 {
    font-size: ${uIFontSize.paragraph};
    color: ${greenSp};
    margin-bottom: 2.5%;
    text-align: center;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    > h3 {
      font-size: ${uIFontSize.paragraph};
      color: ${graySphera};
      margin-bottom: 2.5%;
    }
    > input {
      font-size: ${uIFontSize.paragraph};
      color: ${grayPlaceholder};
      width: 10%;
      height: 30px;
      margin-bottom: 2.5%;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: ${uIFontSize.subTitle};
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: ${uIFontSize.paragraph};
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    > a {
      color: ${greenSp};
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
    }
  }
`;
