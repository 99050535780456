import { useViewMobile } from 'src/hooks/useViewMobile';
import { CreditShowcaseSectionContainer } from './creditShowcaseSection.styles';
import {
  Card,
  Button,
  BoxAmount,
  MilestoneProgress,
  ProgressWheel
} from 'src/utilities/UILibrary2024/components';
import { SkeletonLine } from 'src/utilities/UILibrary2024/components';
import { IconCheckListOnRevision } from 'src/assets/icons/various';
import { CheckMark } from 'src/assets/icons/interfaceIcons';
import { useState } from 'react';

// todo: the h3 font-weigh 900 is not taking into account, the same in investmentShowcase version
// todo: improve the readibility by componentizing the more elements, the style file is getting too big too

export const CreditShowcaseSection = () => {
  const isMobile = useViewMobile();
  const [hoverStates, setHoverStates] = useState({ card2: false, card3: false });
  const cardMobileWidth = '19rem';

  const handleHover = (card: 'card2' | 'card3', isHovered: boolean) => {
    setHoverStates((prev) => ({ ...prev, [card]: isHovered }));
  };

  return (
    <CreditShowcaseSectionContainer>
      <div className="credit-showcase--cards-section">
        <div className="fade-detail" />

        <div className="card1">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '222rem'}
            aspectRatio="44 / 45"
            isFluid
          >
            <h3 className="card--header">SOLICITUD EN LÍNEA</h3>
            <div className="card1--body">
              <div className="card1--section">
                <div className="card--skeleton-section">
                  <SkeletonLine />
                  <SkeletonLine />
                  <div className="card--review-icon">
                    <IconCheckListOnRevision />
                  </div>
                </div>
                <Button className="card--button" isSlim={true} variant="gray">
                  Aceptar
                </Button>
              </div>
              <div className="card1--section">
                <div className="card--skeleton-section">
                  <SkeletonLine />
                  <SkeletonLine />
                  <div className="card--checkmark-icon">
                    <CheckMark />
                    <p>APROBADO</p>
                  </div>
                </div>
                <Button className="card--button" isSlim={true} variant="gray">
                  Aceptar
                </Button>
              </div>
            </div>
          </Card>
        </div>

        <div className="card2">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '32.5rem'}
            aspectRatio="52 / 33"
            isFluid
            onMouseEnter={() => handleHover('card2', true)}
            onMouseLeave={() => handleHover('card2', false)}
          >
            <div className="card2--body">
              <div className="card2--campaign-section">
                <h3 className="card--header">CREAR CAMPAÑA</h3>
                <div className="card2--skeleton-section">
                  <SkeletonLine />
                  <SkeletonLine />
                </div>
                <BoxAmount amount={hoverStates.card2 ? 1500000 : 5000} />
              </div>

              <Card
                className="card2--card-section"
                height="188px"
                width="170px"
                isFluid
                paddingY={22}
              >
                <CheckMark />
                <p>REGISTRO EXITOSO</p>
              </Card>
            </div>
            <div className="card2--footer">
              <MilestoneProgress percentage={hoverStates.card2 ? 70 : 0} />
            </div>
          </Card>
        </div>
        <div className="card3">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '30.625rem'}
            aspectRatio="7 / 3"
            isFluid
            paddingY={24}
            onMouseEnter={() => handleHover('card3', true)}
            onMouseLeave={() => handleHover('card3', false)}
          >
            <h3 className="card--header">CAMPAÑAS REGISTRADAS</h3>
            <div className="card3--body">
              <div className="card3--section">
                <div className="left">
                  <p className="campaign-name">Campaña 1</p>
                  <p className="company-name">Empresa A</p>
                  <div className="card3--skeleton-section">
                    <SkeletonLine />
                    <SkeletonLine />
                  </div>
                </div>
                <div className="right">
                  <ProgressWheel percentage={hoverStates.card3 ? 100 : 5} />
                  <p>Financiamiento</p>
                </div>
              </div>
              <div className="card3--section">
                <div className="left">
                  <p className="campaign-name">Campaña 2</p>
                  <p className="company-name">Empresa A</p>
                  <div className="card3--skeleton-section">
                    <SkeletonLine />
                    <SkeletonLine />
                  </div>
                </div>
                <div className="right">
                  <ProgressWheel percentage={hoverStates.card3 ? 45 : 0} />
                  <p>Financiamiento</p>
                </div>
              </div>
            </div>
            <div className="card3--footer">
              <p className={`${hoverStates.card3 ? '' : 'hidden'}`}>
                FELICIDADES RECIBES TÚ CRÉDITO
              </p>
            </div>
          </Card>
        </div>
      </div>
      <div className="description">
        <h1>Crédito</h1>
        <p>
          Accede a crédito empresarial a corto plazo <span />a tasas atractivas, sin complicaciones
        </p>
        <div className="description--rates">
          <p className="info">financiamiento hasta</p>
          <p className="orange-rate">14 millones</p>
          <p className="orange-info">de MX pesos</p>
          <Button variant="orange">Financiamiento</Button>
        </div>
      </div>
    </CreditShowcaseSectionContainer>
  );
};
