import { API, graphqlOperation } from 'aws-amplify';
import {
  CreateResourceProvider,
  updateInvestor_OnboardingStatus,
  updateApplicant_OnboardingStatus
} from 'src/graphql/customized_mutations';
import {
  MutateCreateResourceProviderType,
  UpdateOnboardingBlockingStatusRequestResponse
} from 'src/types/requests/graphql';
import { typeOfUser } from 'src/types/investorAndApplicant';
import { UpdateOnboardingBlockingInputProps } from 'src/store/User/user.interface';

export const mutateCreateResourceProvider = async (input: MutateCreateResourceProviderType) => {
  const response = await API.graphql(graphqlOperation(CreateResourceProvider, { input }));
  return response;
};

export const updateOnboardingBlockingStatusRequest = async (
  type: typeOfUser,
  input: UpdateOnboardingBlockingInputProps
) => {
  const inputWithComplete = {
    ...input,
    onboarding: {
      ...input.onboarding,
      currentStep: input.onboarding.currentStep + 1,
      complete: false
    }
  };

  const mutation =
    type === 'investor' ? updateInvestor_OnboardingStatus : updateApplicant_OnboardingStatus;
  const response = await API.graphql(graphqlOperation(mutation, { input: inputWithComplete }));

  const data = response as { data: UpdateOnboardingBlockingStatusRequestResponse };
  return type === 'investor' ? data.data.updateInvestor : data.data.updateApplicant;
};
