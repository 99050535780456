import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import styled from 'styled-components/macro';

export const PersonalInformationContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  background-color: rgb(245, 245, 245);

  .date-input-container {
    display: flex;
    align-items: start;
    width: 100%;
    flex-direction: column;
    gap: 0.3rem;

    input {
      height: 2rem;
      border: 1px solid black;
      width: 100%;
      border-radius: 5px;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .error-message {
      color: red;
    }

    @media (min-width: ${breakpoints.tablet}) {
      input {
        height: 2.5rem;
      }
    }
  }
`;
