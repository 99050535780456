import { ModalBodyProps, ModalHeaderProps, ModalProps } from './modal.interface';
import styled, { keyframes, css } from 'styled-components/macro';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

type ModalStyleProps = {
  displayElement?: boolean;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ModalContainer = styled.div<ModalProps & ModalStyleProps>`
  width: 100%;
  min-width: 16.875rem;
  display: ${({ displayElement }) => (displayElement ? 'flex' : 'none')};
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem;
  animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s linear forwards;

  .card {
    z-index: 10002;
    position: relative;
    top: -2rem;
    align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
    text-align: ${({ isCentered }) => (isCentered ? 'center' : 'initial')};
    padding-top: 2rem;
    padding-bottom: 2rem;
    animation: ${({ isOpen }) => (isOpen ? fadeIn : fadeOut)} 0.3s linear forwards;
  }

  .overlay {
    background-color: ${({ isOverlay }) => (isOverlay ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .close-button {
    width: 1.5rem;
    aspect-ratio: 1;
    position: absolute;
    top: 1rem;
    right: 1rem;
    border-radius: 50%;
    border: none;
    background-color: black;
    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      left: 11px;
      content: ' ';
      height: 15px;
      top: 4.5px;
      width: 1.5px;
      background-color: white;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .card {
      max-width: 43rem;
    }

    .close-button {
      width: 2rem;

      &:before,
      &:after {
        height: 20px;
        left: 15px;
      }
    }
  }
`;

const commonStyles = css`
  margin-bottom: 1.5rem;

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 2rem;
  }
`;

export const ModalHeaderContainer = styled.div<ModalHeaderProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'initial')};

  * {
    font-size: ${uIFontSize.xLarge};
  }
  ${commonStyles}

  @media (min-width: ${breakpoints.desktop}) {
    * {
      font-size: ${uIFontSize.xxLarge};
    }
  }
`;

export const ModalBodyContainer = styled.div<ModalBodyProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ isCentered }) => (isCentered ? 'center' : 'flex-start')};
  text-align: ${({ isCentered }) => (isCentered ? 'center' : 'initial')};

  ${commonStyles}
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
