import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { CardProps } from './types';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';

const DEFAULT_WIDTH = '24.25rem';
const DEFAULT_HEIGHT = '32rem';

const sizes = ({
  width = DEFAULT_WIDTH,
  height = DEFAULT_HEIGHT,
  isHorizontal = false,
  aspectRatio = 'unset',
  isFluid = false
}: CardProps): FlattenSimpleInterpolation => {
  const [widthValue, heightValue] = isHorizontal ? [height, width] : [width, height];

  const aspectRatios = {
    default: '97 / 128',
    slim: '325 / 482',
    custom: aspectRatio
  };

  let selectedAspectRatio =
    aspectRatios[aspectRatio as keyof typeof aspectRatios] || aspectRatios['custom'];

  // Invert the aspect ratio if isHorizontal is true
  if (isHorizontal && selectedAspectRatio !== 'unset') {
    const [numerator, denominator] = selectedAspectRatio.split(' / ');
    selectedAspectRatio = `${denominator} / ${numerator}`;
  }

  return css`
    ${isFluid
      ? css`
          height: 100%;
          width: 100%;
          max-width: ${widthValue};
          max-height: ${heightValue};
        `
      : css`
          min-height: ${heightValue};
          min-width: ${widthValue};
        `}

    ${selectedAspectRatio &&
    css`
      aspect-ratio: ${selectedAspectRatio};
    `}
    ${selectedAspectRatio !== 'unset' &&
    css`
      min-height: unset;
      max-height: unset;
      height: fit-content;
    `}
  `;
};

export const CardContainer = styled.div<CardProps>`
  display: flex;
  padding: ${({ paddingX = 32, paddingY = 32, isSlim }) =>
    isSlim ? '16px' : `${paddingY}px ${paddingX}px`};
  background-color: ${colors.background.white};
  border-radius: 24px;
  box-shadow: inset -3px -3px 4px #97979738, 0px 6px 24px #00000029;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  ${sizes}
  ${({ isHoverable }) =>
    isHoverable &&
    css`
      transition: box-shadow 0.3s ease-in-out;
      &:hover {
        box-shadow: inset -3px -3px 8px #97979758, 0px 12px 48px #00000069;
      }
    `}
`;
