import axios from 'axios';
import { config } from 'src/environments';
import { typeOfUser } from 'src/types/investorAndApplicant';

export const sendPLDEmail = async (id: string, typeOfUser: typeOfUser) => {
  const input = {
    id,
    typeOfUser
  };

  const response = await axios.post(`${config.url}/pld/send-email/officer`, input);

  return response;
};

export const blockOnboarding = async (id: string, userType: typeOfUser, reasonOfBlock: string) => {
  const payload = {
    type: 'block_onboarding',
    input: {
      id,
      isBlocked: true,
      reasonOfBlocking: reasonOfBlock,
      userType
    }
  };

  const response = await axios.post(`/block-onboarding`, payload);

  return response;
};

export const unblockOnboarding = async (id: string, userType: typeOfUser) => {
  const payload = {
    type: 'block_onboarding',
    input: {
      id,
      isBlocked: false,
      reasonOfBlocking: '',
      userType
    }
  };

  const response = await axios.post(`/block-onboarding`, payload);

  return response;
};

export const updateResourcesProvider = async (userType: typeOfUser, userId: string) => {
  const payload = {
    type: 'update_isResourcesProvider',
    input: {
      id: userId,
      typeOfUser: userType,
      isResourcesProvider: 'SI_APLICA'
    }
  };

  await axios.post(`${config.url}/admin/dynamo/update`, payload);
};

const axiosInstanceWithoutInterceptors = axios.create();

export const updateMetamapDummyData = async (userId: string, userType: typeOfUser) => {
  const payload = {
    metadata: {
      id: userId,
      typeOfUser: userType
    }
  };

  await axiosInstanceWithoutInterceptors.post(`${config.url}/admin/test/mati`, payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};
