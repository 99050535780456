import { useState, useEffect } from 'react';
import { API, Auth } from 'aws-amplify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  evidenceRisks,
  updateInvestorRisk,
  createGeneralContract,
  literaxRisks,
  setLoadingState,
  setLoadingMessage
} from 'src/store/User/user.slice';
import { useSelector } from 'react-redux';
import { PersonalInformationContainer } from './onboardingRisks.styles';
import getCurrentDate from 'src/functions/getCurrentDate';
import { getInvestor_Onboarding } from 'src/graphql/customized_queries';
import { PlatformBanner, Card } from 'src/utilities/UILibrary';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { url } from 'src/environments';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { OptionToggle } from 'src/components/shared/OptionToggle/OptionToggle';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { setAppError } from 'src/store/app/app.slice';
import { GetInvestorQuery } from 'src/graphql/API';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';

const loadingMessage =
  'Estimado usuario, estamos procesando tu información, esto puede tardar unos minutos. Por favor, no cierres esta ventana.';

type FormValues = {
  loss: string;
  liquidity: string;
  information: string;
  performance: string;
  notApproved: string;
  notAdvice: string;
  investment: string;
  disclosedInformation: string;
  password: string;
};

export default function Risks() {
  const [fullName, setFullName] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();
  const [taxId, setTaxId] = useState<string | null>();
  const [dateOfApplication] = useState(getCurrentDate());
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const { nextStep } = useManageSteps();
  const { register, handleSubmit, control } = useForm<FormValues>();
  const [password, setPassword] = useState('');

  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const user = useSelector((state: RootState) => state.user.currentUser);
  const currentDate = getCurrentDate();
  const [date, time] = currentDate.split('T');
  const [year, month, day] = date.split('-');
  const [hour, minute] = time.split(':');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = (await API.graphql({
          query: getInvestor_Onboarding,
          variables: { id: user.id }
        })) as { data: GetInvestorQuery };

        setFullName(data.getInvestor?.personalInformation?.fullName);
        setEmail(data.getInvestor?.personalInformation?.email);
        setTaxId(data.getInvestor?.personalInformation?.RFC);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id]);

  const ipv4 = user.ipAddress ? user?.ipAddress[user?.ipAddress?.length - 1].IPv4 : '';

  const onInitialSubmit = (data: FormValues) => {
    const allYes = Object.values(data).every((value) => value === 'yes');
    if (!allYes) {
      dispatch(setAppError({ errorCode: 'Debes aceptar todos los riesgos para continuar' }));
      dispatch(setLoadingState(false));
      return;
    }
    setShowPasswordInput(true);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(setLoadingState(true));
    dispatch(setLoadingMessage(loadingMessage));
    const timeStamp = Date.now();

    //Endpoints que generan el sello de tiempo con la data de Metamap
    const literaxBody = {
      id: user.id,
      typeOfUser: 'investor',
      //Timestamp sólo aplica para el endpoint de literax/emision/mati-files
      timestamp: Date.now().toString()
    };
    //Endpoints de riesgos
    const updateInput = {
      investorId: user.id,
      loss: data.loss === 'yes' ? true : false,
      liquidity: data.liquidity === 'yes' ? true : false,
      information: data.information === 'yes' ? true : false,
      performance: data.performance === 'yes' ? true : false,
      notApproved: data.notApproved === 'yes' ? true : false,
      notAdvice: data.notAdvice === 'yes' ? true : false,
      investment: data.investment === 'yes' ? true : false,
      disclosedInformation: data.disclosedInformation === 'yes' ? true : false,
      createdAt: dateOfApplication,
      updatedAt: dateOfApplication
    };
    const inputPdf = {
      id: user.id,
      loss: true,
      liquidity: true,
      information: true,
      performance: true,
      noApproval: true,
      noAdvice: true,
      investment: true,
      disclosedInformation: true,
      fullName: fullName,
      createdAt: dateOfApplication,
      ip: ipv4,
      timestamp: timeStamp,
      hostname: 'finsphera.com.mx'
    };
    const generalContract = {
      id: user.id
    };
    const body = {
      id: user.id,
      typeOfUser: 'investor',
      object: `${user.id}/contracts/risk_${timeStamp}.pdf`
    };
    const riskSigned = {
      type: 'update_isContractSigned',
      input: {
        typeOfUser: 'investor',
        id: user.id,
        key: 'isRisksSigned'
      }
    };

    try {
      //validación de contraseña ingresada
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.signIn(currentUser.username, data.password);
    } catch (error) {
      console.error(error);
      dispatch(setAppError({ errorCode: 'Contraseña incorrecta' }));
      dispatch(setLoadingState(false));
      return;
    }

    try {
      await axios.post(`${url}/admin/dynamo/update`, riskSigned);
      await axios.post(`${url}/admin/literax/all-info-mati`, literaxBody);
      await axios.post(`${url}/admin/mati/send-files-to-aws`, literaxBody);
      await axios.post(`${url}/admin/literax/emision/mati-files`, literaxBody);
      //endpoint para segmentar la dirección que viene desde Metamap
      await axios.get(`${url}/admin/address-parts?id=${user.id}_p`);

      //Dispatch que crea la tabla risk en Dynamo
      await dispatch(updateInvestorRisk(updateInput));
      //Dispatch para crear el pdf del contrato general que se guarda en s3 y después es consumido por el endpoint de MiFiel(Contrato riesgos)
      await dispatch(evidenceRisks(inputPdf));
      //Dispatch para ejecutar Literax
      await dispatch(literaxRisks(body));
      //Dispatch para crear el pdf del contrato general que se guarda en s3 y después es consumido por el endpoint de MiFiel(Contrato General)
      await dispatch(createGeneralContract(generalContract));
      await nextStep();
      navigate('/inversionista/onboarding-perfil-uso');
    } catch (error) {
      console.error(error);
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <PersonalInformationContainer className="onboarding-view-content">
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_personalInformation5_banner.png`}
        imgAlt="Finsphera icono información personal"
        title="Riesgos"
        className="onboarding-banner"
      />
      <Card className="onboarding-card" isSlim isForm>
        <Card.Row oneLine>
          <h3>1. Información de riesgos</h3>
          <OptionToggle options={['Si', 'No']} />
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Riesgo de pérdida.-</strong> ¿Comprende que estas inversiones son riesgosas y
            que puede perder todo el dinero invertido?
          </p>
          <div className="radio-group">
            <input type="radio" value={'yes'} {...register('loss')} disabled={showPasswordInput} />
            <input type="radio" value="no" {...register('loss')} disabled={showPasswordInput} />
          </div>
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Riesgo de liquidez.-</strong> ¿Comprende que es posible que no podrá deshacerse
            anticipadamente de su inversión?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('liquidity')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('liquidity')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Riesgo de información.-</strong> ¿Comprende que es posible que la información
            que Finsphera proporcione sobre la oferta y el desempeño sucesivo de los Solicitantes de
            financiamiento, sea limitada?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('information')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('information')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Riesgo de rendimiento.-</strong> ¿Comprende que es posible que no obtenga
            ingreso o rendimiento alguno, tales como dividendos o intereses, por estas inversiones?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('performance')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('performance')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
      </Card>

      <Card className="onboarding-card" isSlim isForm>
        <Card.Row className="card-row-risks" oneLine>
          <h3>2. Aprobación y asesoría</h3>
          <OptionToggle options={['Si', 'No']} />
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Sin aprobación.-</strong> ¿Comprende que las ofertas de inversión publicadas no
            han sido revisadas o aprobadas en forma alguna por la Comisión Nacional Bancaria y de
            Valores u otra autoridad?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('notApproved')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('notApproved')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>

        <Card.Row oneLine>
          <p>
            <strong>Sin asesoría.-</strong> ¿Comprende que no recibirá asesoría sobre si las
            inversiones son adecuadas para usted?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('notAdvice')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('notAdvice')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
      </Card>

      <Card className="onboarding-card" isSlim isForm>
        <Card.Row className="card-row-risks" oneLine>
          <h3>3. Acerca de la inversión</h3>
          <OptionToggle options={['Si', 'No']} />
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Riesgo de inversión.-</strong> ¿Ha leído este formulario y comprende los riesgos
            de llevar a cabo estas inversiones?
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('investment')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('investment')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
        <Card.Row oneLine>
          <p>
            <strong>Información divulgada.-</strong> Antes de invertir, deberá leer cuidadosamente
            la información divulgada por cada uno de los Solicitantes de financiamiento en los que
            usted considere realizar la inversión. Si no ha leído o no comprende dicha información,
            usted no debería invertir.
          </p>
          <div className="radio-group">
            <input
              type="radio"
              value="yes"
              {...register('disclosedInformation')}
              disabled={showPasswordInput}
            />
            <input
              type="radio"
              value="no"
              {...register('disclosedInformation')}
              disabled={showPasswordInput}
            />
          </div>
        </Card.Row>
      </Card>

      <Card className="onboarding-card" isSlim isForm>
        <Card.Row oneLine>
          <div>
            <p>Nombre completo:</p>
            <p>
              <strong>{fullName}</strong>
            </p>
          </div>
          <div>
            <p>Fecha de aplicación del cuestionario:</p>
            <p>
              <strong>{new Date(dateOfApplication).toLocaleDateString()}</strong>
            </p>
          </div>
        </Card.Row>
        {showPasswordInput && (
          <div>
            <p>
              <span>Firma electrónica.-</span> Al ingresar la contraseña de autenticación en la
              plataforma de Finsphera y dar click en el botón &quot;ACEPTO RIESGOS&quot;, reconozco
              que estoy firmando este formulario electrónicamente, produciendo los mismos efectos
              que las leyes otorgan a la firma autógrafa.
            </p>
            <Card.Row>
              <Controller
                name="password"
                control={control}
                defaultValue={undefined}
                rules={{ required: 'Es un campo requerido.' }}
                render={({ field, fieldState, ...rest }) => (
                  <InputField
                    label="Ingresa la contraseña con la que inicias sesión en Finsphera:"
                    value={password}
                    onChangeInternalValue={field.onChange}
                    placeholder="Contraseña Finsphera"
                    required
                    type="password"
                  />
                )}
              />
            </Card.Row>
          </div>
        )}
      </Card>
      <FormActionButtons
        submitButtonText="Acepto riesgos"
        submitAction={handleSubmit(showPasswordInput ? onSubmit : onInitialSubmit)}
      />
    </PersonalInformationContainer>
  );
}
