import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalEditPhone, Success, Figure, ActionSection } from './updatePhone.styles';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { environment, url } from 'src/environments';
import { useNavigate } from 'react-router-dom';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { setLoadingState } from 'src/store/User/user.slice';
import { errorHandler } from 'src/middlewares/Auth/index.utilities';
import { isByPass, config } from 'src/environments';

export default function UpdatePhoneModal({ showModal, setShowModal }) {
  const [step, setStep] = useState(1);
  const [referenceId, setReferenceId] = useState('');
  const [mobilephone, setMobilephone] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [showFinishedModal, setShowFinishedModal] = useState(false);
  const dispatch = useDispatch();
  //Para validar teléfono MFA
  const user = useSelector((state) => state.user.currentUser);
  const { nextStep } = useManageSteps();
  const navigate = useNavigate();

  const closeModal = () => {
    setShowModal(false);
  };

  const handleValidateCode = async (event) => {
    event.preventDefault();
    const mobileP = mobilephone.split(' ')[0] + mobilephone.split(' ')[1];
    const body = {
      id: user.id,
      otp: verificationCode,
      referenceId: referenceId,
      typeOfUser: 'applicant'
    };

    dispatch(setLoadingState(true));
    const response = await axios.post(
      `${url}/${environment}/admin/mobile/${mobileP}/validate-otp`,
      body
    );
    const parsedBody = JSON.parse(response.data.body);
    const codeResponse = parsedBody.isVerified;
    if (codeResponse === true) {
      dispatch(setLoadingState(false));
      await nextStep();
      setShowFinishedModal(true);
    } else {
      dispatch(setLoadingState(false));
      errorHandler(dispatch, { code: 'incorrectPhoneCode' });
      console.error('Incorrect verification code.');
    }
  };
  const handleGetCode = async (event) => {
    event.preventDefault();
    const mobileP = mobilephone.split(' ')[0] + mobilephone.split(' ')[1];
    const input = {
      id: user.id,
      typeOfUser: 'applicant'
    };
    const trully = {
      id: user.id,
      typeOfUser: 'applicant',
      phone: mobileP
    };

    let label = 'No Threat';
    if (!isByPass && config.env === 'prod') {
      const trullyTwo = await axios.post(
        `${url}/${environment}/trully/enrich-data/predict/second-step`,
        trully
      );
      const body = JSON.parse(trullyTwo.data.body);
      label = body.label;
    }

    if (label === 'No Threat') {
      //Ejecuta lambda para enviar código de verificación
      const phone = await axios.post(
        `${url}/${environment}/admin/mobile/${mobileP}/get-otp`,
        input
      );
      const parsedBody = JSON.parse(phone.data.body);
      const referenceId = parsedBody.ReferenceId;
      setReferenceId(referenceId);
      setStep(2);
    }
    navigate('/pagina-validacion');
  };

  const handleVerifyCode = async (event) => {
    event.preventDefault();
    const mobileP = mobilephone.split(' ')[0] + mobilephone.split(' ')[1];
    const body = {
      id: user.id,
      otp: verificationCode,
      referenceId: referenceId,
      typeOfUser: 'investor'
    };
    const response = await axios.post(
      ` ${url}/${environment}/admin/mobile/${mobileP}/validate-otp`,
      body
    );
    const parsedBody = JSON.parse(response.data.body);
    const codeResponse = parsedBody.isVerified;
  };
  return (
    <ModalEditPhone showModal={showModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu teléfono se ha editado de forma exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <ActionSection>
            <p>
              {step === 1
                ? 'Estás a punto de actualizar el teléfono celular con el que validaremos tus operaciones. Ingresa los 10 dígitos:'
                : 'Ingresa el código de verificación que enviamos a tu número celular'}
            </p>
            <form onSubmit={step === 1 ? handleGetCode : handleValidateCode}>
              <InputField
                isDialCode
                value={mobilephone}
                onChangeInternalValue={(value) => setMobilephone(value)}
                placeholder="Número de teléfono"
                minLength={10}
                required
              />
              {step === 2 && (
                <>
                  <InputField
                    value={verificationCode}
                    regex={/^\d{1,6}$/}
                    restrictRegex
                    errorRegexMessage="El código de verificación debe ser de 6 dígitos"
                    onChangeInternalValue={(value) => setVerificationCode(value)}
                    type="number"
                    placeholder="Código de verificación"
                    required
                  />
                  <Button onClick={handleVerifyCode} isFullWidth size="small" type="submit">
                    Validar código de verificación
                  </Button>
                </>
              )}
              {step === 1 && (
                <Button onClick={handleVerifyCode} isFullWidth size="small" type="submit">
                  Enviar código de verificación
                </Button>
              )}
            </form>
          </ActionSection>
        </div>
      )}
    </ModalEditPhone>
  );
}
