import { AvisoContainer, Logo } from './Privacy.styles';
import { Link } from 'react-router-dom';

export default function Privacy() {
  return (
    <>
      <Logo>
        <Link to="/">
          {/* <img src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_nav_finsphera.png" alt="logotipo finsphera" /> */}
        </Link>
      </Logo>
      <AvisoContainer>
        <h1>AVISO DE PRIVACIDAD FINSPHERA</h1>
        <p>
          De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares, en lo sucesivo (“LFPDPPP”), declara SUSTAINABLE TECH ESG, S.A.P.I. DE C.V.
          INSTITUCION DE FINANCIAMIENTO COLECTIVO (“FINSPHERA”), ser una empresa legalmente
          constituida de conformidad con las leyes mexicanas, con domicilio ubicado en calle
          Montecito 38 Of. 16 Piso 28, Colonia Nápoles, C.P.03810, Alcaldía de Benito Juárez, Ciudad
          de México para cualquier notificación para efectos del presente, y es la responsable del
          tratamiento de sus (“Datos Personales”), por lo que a través de este Aviso de Privacidad
          hace de su conocimiento que la información de sus clientes (“Usted”), es tratada de forma
          estrictamente confidencial apegándose en todo momento a las recomendaciones que hace el
          Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos
          Personales (INAI). Al aceptar este Aviso de Privacidad, se entiende que Usted ha revisado,
          leído y aceptado los términos del mismo, por lo que otorga su consentimiento para el
          tratamiento de sus Datos Personales, por parte de FINSPHERA, conforme lo aquí establecido.
          Dicho consentimiento podrá ser revocado en cualquier momento sin que se le atribuyan
          efectos retroactivos, mediante notificación expresa y por escrito en la que manifieste que
          revoca dicho consentimiento de conformidad con el procedimiento que se describe más
          adelante.
        </p>
        <h2>DEFINICIONES</h2>
        <p>
          Para efectos del presente Aviso de Privacidad se tendrá por definición, en la totalidad
          del documento, a efecto de brindar claridad y exactitud al significado de las siguientes
          palabras, con independencia de ser escritas en singular o en plural:
        </p>
        <br />
        <ul>
          <li>
            <span> DATOS PERSONALES:</span> Cualquier información concerniente a una persona física
            identificada o identificable. Se considera que una persona es identificable cuando su
            identidad pueda determinarse directa o indirectamente a través de cualquier información.
          </li>
          <li>
            <span> AVISO DE PRIVACIDAD:</span> El presente documento en toda su extensión.
          </li>
          <li>
            <span> DATOS PERSONALES SENSIBLES:</span> Aquellos datos personales que afecten a la
            esfera más íntima de su titular o cuya utilización indebida pudiere dar origen a
            discriminación o conlleve un riesgo grave.
          </li>
          <li>
            <span> DERECHOS ARCO:</span>Derechos de Acceso, Rectificación, Cancelación y Oposición.
          </li>
          <li>
            <span> ENCARGADO:</span> La persona física o moral que, sola o conjuntamente con otras,
            trata Datos Personales por cuenta del Responsable.
          </li>
          <li>
            <span> FINSPHERA:</span> Persona moral constituida bajo la denominación “SUSTAINABLE
            TECH ESG, S.A.P.I. DE C.V. INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO”, quién para los
            efectos del presente Aviso de Privacidad, es el responsable del tratamiento, uso y
            protección de sus Datos Personales.
          </li>
          <li>
            <span> PLATAFORMA:</span> A la interfaz o aplicación móvil, denominada “FinSphera” o la
            página web www.finsphera.com que permite hacer uso de productos y servicios ofrecidos
            por FINSPHERA, de la cual es titular FINSPHERA.
          </li>
          <li>
            <span> TITULAR:</span> Significa la persona física o funcionarios, empleados,
            representantes legales, accionistas, comisionistas o cualquier persona física, a quien
            corresponden los Datos Personales ingresados en la Plataforma. El presente documento se
            encuentra dirigido al Titular, por lo que podrá ser referido en el documento como
            “Usted”.
          </li>
          <li>
            <span> RESPONSABLE:</span> El responsable del Tratamiento de los Datos Personales es la
            persona moral denominada SUSTAINABLE TECH ESG, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN
            DE CAPITAL VARIABLE, INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO (`FINSPHERA`).
          </li>
          <li>
            <span> INAI:</span> Instituto Nacional de Transparencia, Acceso a la Información y
            Protección de Datos Personales.
          </li>
          <li>
            <span> CONDUSEF:</span> Comisión Nacional para la Protección y la Defensa de los
            Usuarios de Servicios Financieros.
          </li>
        </ul>
        <br />
        <h3>1. Finalidades del tratamiento de sus Datos Personales</h3>
        <ul>
          <span>FINSPHERA </span> realiza el tratamiento de sus Datos Personales, con las siguientes
          finalidades a efecto de atender su solicitud del producto o servicio y formalizar su
          contratación, de acuerdo con la Ley para Regular las Instituciones de Tecnología
          Financiera(“Ley Fintech”):
          <li>
            El cumplimiento de la relación contractual derivada de la solicitud y/o contratación de
            productos o servicios;
          </li>
          <li>
            Verificar, confirmar y validar su identidad, información confidencial y la veracidad de
            la información que proporcione como propia;
          </li>
          <li>
            Administrar, operar y dar seguimiento a los servicios y productos que solicita o
            contrata con <span>FINSPHERA</span>;
          </li>
          <li>
            Creación de canales de comunicación entre Usted y <span>FINSPHERA</span>;
          </li>
          <li>
            Cumplimento con lo requerido por cualquier autoridad federal, estatal o municipal de
            conformidad con las disposiciones legales vigentes, incluyendo la relativa a Prevención
            de Operaciones con Recursos de Procedencia Ilícita;
          </li>
          <li>Gestiones de mantenimiento de las Operaciones</li>
        </ul>
        <br />
        <h3>2. Datos Personales que podrán ser recabados</h3>
        <p>
          Para llevar a cabo las finalidades descritas en el presente Aviso de Privacidad podrán ser
          requeridos los siguientes Datos Personales: Nombre completo, Identificación Oficial,
          Registro Federal de Contribuyentes (RFC), Clave única de registro de población (CURP),
          género, país y fecha de nacimiento, nacionalidad, domicilio particular, correo
          electrónico, teléfono celular, ocupación y datos generales de sus beneficiarios. Se
          informa que <span>FINSPHERA</span> ecabará Datos Personales sensibles: datos de ubicación
          geográfica, para el uso de los servicios y/o productos de conformidad con la regulación
          aplicable y datos financieros.
        </p>
        <br />
        <h3>3. Transferencia de Datos Personales</h3>
        <p>
          <span>FINSPHERA</span> declara, que sus Datos Personales podrán ser compartidos con otra
          empresa, organización o persona distinta a esta empresa, por lo que al aceptar este Aviso
          de Privacidad, se entiende que Usted ha revisado, leído y aceptado los términos y
          condiciones del mismo, por lo que otorga su consentimiento para el tratamiento de sus
          Datos Personales, por parte de <span>FINSPHERA</span>, conforme lo aquí establecido. Dicho
          consentimiento se considerará expreso, si no se da ninguno de los supuestos establecidos
          en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares y únicamente podrá ser revocado con efectos exclusivamente hacia el futuro,
          mediante notificación expresa y por escrito en la que manifieste que revoca dicho
          consentimiento. Otorga su consentimiento expreso para que sus datos sean transferidos a
          terceros nacionales o extranjeros con la finalidad de verificar y confirmar su identidad,
          así como la autenticidad de la información, como se establece en el primer párrafo de este
          numeral.
        </p>
        <br />
        <h3>4. Políticas de seguridad de la información</h3>
        <p>
          Su información personal será resguardada bajo estricta confidencialidad, y para prevenir
          razonablemente el uso o divulgación indebida de la misma, <span>FINSPHERA</span> toma en
          todo momento medidas razonables, incluyendo medidas de seguridad administrativas, técnicas
          y físicas de conformidad con la normatividad aplicable para proteger su información
          personal de pérdida, robo, sustracción, mal uso, o acceso, alteración, destrucción y
          revelación no autorizada. En particular, <span>FINSPHERA</span> cuenta con un oficial de
          privacidad, una política de privacidad, cursos de capacitación a nuestros empleados,
          acceso restringido a información personal sólo a usuarios autorizados, un inventario de
          Datos Personales (debidamente clasificados por categoría de datos) y de los sistemas de
          tratamiento, análisis de riesgo y cláusulas contractuales. En este sentido, Usted
          comprende y reconoce que <span>FINSPHERA</span> le ha informado que nunca enviará correos
          electrónicos buscando información de detalles como nombres de usuario y contraseñas, así
          como tampoco de números de sus tarjetas de crédito y su personal nunca solicitará sus
          contraseñas. Podrá solicitar información sobre las transacciones realizadas en sus
          servicios cuando existan dudas o aclaraciones. Sin embargo, esta información se solicitará
          a través de la Plataforma, la Aplicación u otro medio de comunicación que{' '}
          <span>FINSPHERA</span> señale previamente que pondrá a su disposición para tal efecto. En
          caso de que ocurra una vulneración de seguridad en cualquier fase del tratamiento de sus
          Datos Personales, que afecte de forma significativa sus derechos patrimoniales o morales,
          el responsable le comunicará dicha situación de forma inmediata, para que pueda tomar las
          medidas necesarias que correspondan para la defensa de sus derechos.
        </p>
        <br />
        <h3>5. Medios para el ejercicio de Derechos ARCO</h3>
        <p>
          Todos sus Datos Personales son tratados de acuerdo con la legislación aplicable y vigente
          en el país, por ello le informamos que Usted tiene en todo momento los derechos de Acceso,
          Rectificación, Cancelación u Oposición (en lo subsecuente “ARCO”) al tratamiento que le
          brindan a sus Datos Personales; derecho que podrá hacer valer a través del teléfono 55
          6146 9604 y/o correo electrónico protecciondatospersonales@finsphera.com. FINSPHERA
          posterior a ser acreditada la personalidad del promovente, comunicará al titular, en un
          plazo máximo de 20 (veinte) días, contados desde la fecha en que se recibió la solicitud
          de acceso, rectificación, cancelación u oposición, la determinación adoptada, a efecto de
          que, si resulta procedente, se haga efectiva la misma dentro de los quince días siguientes
          a la fecha en que se comunica la respuesta. A través de estos canales Usted podrá
          actualizar sus datos y especificar el medio por el cual desea recibir información y/o
          eliminar su registro de nuestra base de datos cuando considere que sus datos no han sido
          utilizados de manera correcta, ya que, en caso de no contar con esta especificación de su
          parte, FINSPHERA establecerá libremente el canal que considere pertinente para enviarle
          información. Por último, FINSPHERA, se compromete a la adopción de políticas de
          privacidad, de protección de Datos Personales y de seguridad de la información exigibles
          al interior de su organización, así como a implementar medidas de seguridad de carácter
          tecnológico, físico y administrativo. FINSPHERA brinda mecanismos alternos al ejercicio de
          Derechos ARCO para que los Titulares limiten el uso y divulgación de sus Datos Personales.
          En este sentido, hace del conocimiento del Titular que, para evitar recibir publicidad,
          puede inscribirse en el REUS que es el Registro Público de Usuarios de la Condusef, en el
          vínculo https://webapps.condusef.gob.mx/reus/app/registro.jsp o vía telefónica al número
          01800 999 8080. En adición, en caso de no realizar el registro en el REUS, puede solicitar
          a FINSPHERA su inscripción al listado de exclusión, con el objeto de no recibir
          información de los productos y servicios. Si es procedente, se registrará al Titular en el
          listado de FINSHERA y se proporcionará constancia de lo anterior.
        </p>
        <br />
        <h3>6. Revocación</h3>
        <p>
          El Titular podrá revocar su consentimiento, así como oponerse al tratamiento de sus datos
          personales para las finalidades de la relación jurídica que dio origen a la contratación
          de un producto o servicio, incluyendo las finalidades secundarias como marketing y
          publicidad. La revocación del consentimiento se llevará a cabo de acuerdo con el siguiente
          procedimiento:
        </p>
        <ul>
          <p>
            a) Envío de un correo electrónico a la dirección protecciondatospersonales@finsphera.com
            con el asunto «Revocación del Consentimiento».
          </p>
          <p>b) En el correo electrónico se deberá señalar:</p>
          <li>El nombre completo del Titular.</li>
          <li>Domiciliio</li>
          <li>Correo electrónico.</li>
          <li>El motivo de su solicitud.</li>
          <li>Los argumentos que sustenten su solicitud o petición.</li>
          <li>Copia del documento oficial que acredite su identidad.</li>
          <li>
            Descripción clara y precisa respecto de los Datos Personales y finalidades de sobre las
            cuales desea revocar el consentimiento
          </li>
        </ul>
        <p>
          En todos los casos la revocación del consentimiento no tendrá efectos retroactivos, es
          decir no afectará la legalidad del procesamiento basado en su consentimiento antes de su
          retiro, el Titular podrá solicitar la misma al correo electrónico;{' '}
          <Link to="mailto:protecciondatospersonales@finsphera.com">
            protecciondatospersonales@finsphera.com
          </Link>
          , para obtener el formato respectivo. En un plazo de 45 (cuarenta y cinco) días contados a
          partir de la recepción de dicho formato, los datos del Titular serán excluidos, previa
          acreditación de su identidad, la cual procederá sin necesidad de cumplir con requisito
          adicional alguno.
        </p>
        <br />
        <h3>7. Modificaciones del Aviso de Privacidad</h3>
        <p>
          Este Aviso de Privacidad podrá ser modificado por <span>FINSPHERA</span>, dichas
          modificaciones serán oportunamente informadas a través de correo electrónico, teléfono, o
          cualquier otro medio de comunicación que <span>FINSPHERA</span> determine para tal efecto.
        </p>
        <br />
        <h3>8. Modificaciones del Aviso de Privacidad</h3>
        <p>
          Tratándose de <span>Datos Personales Sensibles</span> en los cuales no figuren
          obligaciones derivadas de una relación jurídica (Contratos, Convenios, Términos y
          Condiciones, o cualquier otra), FINSPHERA recabará el consentimiento expreso del Titular a
          través de un formato proporcionado al correo electrónico personal, desde el momento en que
          tenga conocimiento del hecho. Asimismo, se hará constar la aceptación por escrito y la
          firma del Titular, que a su vez deberá coincidir con una Identificación Oficial vigente,
          el cual será remitido al correo;{' '}
          <Link to="mailto:protecciondatospersonales@finsphera.com">
            protecciondatospersonales@finsphera.com
          </Link>
          , en un plazo que no podrá exceder de 5 días hábiles, conforme a lo establecido en el
          artículo 9 de la Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares.
          <span>FINSPHERA</span> no podrán crear bases de datos que contengan datos personales
          sensibles, sin que se justifique la creación de estas para finalidades legítimas,
          concretas y acordes con las actividades o fines explícitos que persigue.
        </p>
        <br />
        <h3>9. Uso de Cookies y otras tecnologías en página web</h3>
        <p>
          El Titular reconoce que la Página Web de FINSPHERA utiliza cookies, web beacons y otras
          tecnologías lo anterior permite brindarle un mejor servicio y experiencia navegar en la
          Página Web. Los datos personales que obtenemos de estas tecnologías de rastreo son los
          siguientes: horario de navegación, tiempo de navegación en nuestra Página Web, secciones
          consultadas, y páginas de Internet accedidas previo a la nuestra, ubicación, proveedor de
          servicios de internet, y tipo de conexión, únicamente para conocimiento y elaboración de
          estudios de FINSPHERA. Las cookies pueden desactivarse o deshabilitarse siguiendo los
          procedimientos del navegador de internet que utiliza.
        </p>
      </AvisoContainer>
    </>
  );
}
