import axios from 'axios';
import { url } from 'src/environments';

export type TypeOfUser = 'investor' | 'applicant';

export type IpType = {
  IPv4: string;
  date: string;
};

export const updateIpAddress = async (id: string, typeOfUser: TypeOfUser, ip: IpType) => {
  const input = {
    id,
    typeOfUser,
    ipAddress: {
      IPv4: ip.IPv4,
      date: ip.date
    }
  };
  const response = await axios.post(`${url}/admin/dynamo/update`, {
    type: 'update_array',
    input
  });
  return response;
};
