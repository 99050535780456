import styled from 'styled-components/macro';

import { whiteSphera, blackSphera, greenSp } from 'src/utilities/UIStyles/variables/colors';

export const InvestDetailContainer = styled.section`
  padding-bottom: 5%;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    padding-bottom: 10%;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${whiteSphera};
  padding: 10% 0 2.5% 10%;

  > figure {
    width: 85px;
    height: 85px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: auto;
    }
  }

  > h2 {
    color: ${blackSphera};
    font-size: 3vw;
    width: 20%;
    margin-right: 10%;
  }

  > p {
    color: ${blackSphera};
    font-size: 1.5vw;
    width: 45%;

    > span {
      color: ${greenSp};
      font-weight: bold;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure {
      > img {
      }
    }

    > h2 {
    }

    > p {
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
    padding: 25% 0 2.5% 0%;

    > figure {
      margin-right: 0;
      margin-bottom: 5%;

      > img {
      }
    }

    > h2 {
      font-size: 6vw;
      width: 100%;
      margin-right: 0;
      margin-bottom: 2%;
      text-align: center;
    }

    > p {
      text-align: center;
      font-size: 4vw;
      width: 90%;
      margin-bottom: 5%;
    }
  }
`;

export const InvestInfo = styled.section`
  margin-top: 2.5%;

  > h2 {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-left: 5%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: 4.2vw;
    }
  }
`;

export const InvestDetailInfo = styled.div`
  width: 90%;
  margin: 1.5% auto 2.5% auto;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1%;

    > h3 {
      font-size: 1.3vw;
      color: ${blackSphera};
      width: 30%;
      padding-bottom: 1%;
      border-bottom: 1px solid ${blackSphera};
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    padding-top: 1%;
    padding-bottom: 2%;

    > p {
      width: 30%;
      font-size: 1.3vw;
      color: ${blackSphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }
    }

    > div:nth-child(2) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 2.5%;

      > h3 {
        font-size: 4vw;
        text-align: center;
      }
    }

    > div:nth-child(2) {
      padding-top: 2.5%;
      padding-bottom: 5%;

      > p {
        font-size: 4vw;
        text-align: center;
      }
    }
  }
`;

export const InvestDetailCost = styled.div`
  margin-left: 5%;

  > div:nth-child(1) {
    width: 80%;
    margin-bottom: 2.5%;

    > h3 {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 2%;
    }

    > div {
      display: flex;
      justify-content: space-between;

      > p {
        font-size: 1.2vw;
        color: ${blackSphera};
      }
    }

    > hr {
      margin: 2% 0;
      border: 0.5px solid #576176;
    }
  }

  > div:nth-child(2) {
    width: 80%;

    > h3 {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 2%;
    }

    > div {
      display: flex;
      justify-content: space-between;

      > p {
        font-size: 1.2vw;
        color: ${blackSphera};
      }
    }

    > hr {
      margin: 0.5% 0;
      border: 0.5px solid #576176;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }

      > div {
        > p {
        }
      }

      > hr {
      }
    }

    > div:nth-child(2) {
      > h3 {
      }

      > div {
        > p {
        }
      }

      > hr {
      }
    }
  }

  @media (max-width: 600px) {
    margin-left: 5%;

    > div:nth-child(1) {
      width: 90%;
      margin-bottom: 5%;

      > h3 {
        font-size: 4.2vw;
        margin-bottom: 2%;
      }

      > div {
        > p {
          font-size: 3.5vw;
        }
      }

      > hr {
        margin: 2.5% 0;
      }
    }

    > div:nth-child(2) {
      width: 90%;

      > h3 {
        font-size: 4.2vw;
        margin-bottom: 2%;
      }

      > div {
        > p {
          font-size: 3.5vw;
        }
      }

      > hr {
        margin: 1% 0;
      }
    }
  }
`;
