import {
  onboardingMapApplicantRoutes,
  onboardingMapInvestorMoralRoutes,
  onboardingMapInvestorPhysicalRoutes
} from 'src/constants/onboardingMapRoutes';
import { getUserFlow } from './getFlowType';

const flowTypeToRoutesMap = {
  physicalInvestor: onboardingMapInvestorPhysicalRoutes,
  moralInvestor: onboardingMapInvestorMoralRoutes,
  moralApplicant: onboardingMapApplicantRoutes
};

export const getCurrentOnboardingStep = (user: unknown): number => {
  const flowType = getUserFlow(user);
  const currentUrl = window.location.pathname;

  const actualStep =
    flowTypeToRoutesMap[flowType].findIndex((route) => route.path === currentUrl) + 1;

  return actualStep;
};
