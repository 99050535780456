import { InputField } from 'src/utilities/UILibrary';
import { WaitlistModalWrapper } from './waitlist.styles';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { ErrorMessage } from 'src/utilities/UILibrary/Forms/InputField/ErrorMessage';
import { postWaitlist } from 'src/requests';
import { useAppThunkDispatch } from 'src/store/store';
import { setLoadingState } from 'src/store/User/user.slice';
import { setAppError } from 'src/store/app/app.slice';
import { parseError } from 'src/functions/parseError';

type WaitlistModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccessfulSubmit: () => void;
  onFailedSubmit: () => void;
};

export type FormValues = {
  fullName: string;
  email: string;
  phone: string;
  operationType: string;
};

export const WaitlistModal = ({
  isOpen,
  onClose,
  onSuccessfulSubmit,
  onFailedSubmit
}: WaitlistModalProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    reset
  } = useForm<FormValues>();
  const dispatch = useAppThunkDispatch();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      dispatch(setLoadingState(true));
      await postWaitlist(data);
      onSuccessfulSubmit();
    } catch (error) {
      const errorFriendly = parseError(error);
      dispatch(setAppError({ errorCode: errorFriendly, severity: 'error' }));
      onFailedSubmit();
    } finally {
      dispatch(setLoadingState(false));
      reset();
    }
  };

  const waitListFormBody = (
    <form className="wait-list-form-body">
      <Controller
        name="fullName"
        control={control}
        rules={{
          required: 'Este campo es obligatorio.',
          pattern: {
            value: /^[^\s]+(\s[^\s]+){2,}$/,
            message: 'Ingresa al menos tu nombre y dos apellidos.'
          }
        }}
        render={({ field: { onChange, value, ...rest } }) => (
          <InputField
            label="Ingresa tu nombre completo"
            placeholder="Nombre"
            onChangeInternalValue={onChange}
            errorMessages={
              errors.fullName && errors.fullName.message ? [errors.fullName.message] : []
            }
            {...rest}
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          required: 'Este campo es obligatorio.',
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: 'Ingresa un correo electrónico válido.'
          }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <InputField
            label="Ingresa tu correo electrónico"
            type="email"
            placeholder="Correo electrónico"
            onChangeInternalValue={onChange}
            errorMessages={errors.email && errors.email.message ? [errors.email.message] : []}
            {...rest}
          />
        )}
      />
      <Controller
        name="phone"
        control={control}
        rules={{
          required: 'Este campo es obligatorio.',
          minLength: {
            value: 14,
            message: 'Ingresa al menos 10 números.'
          }
        }}
        render={({ field: { onChange, ...rest } }) => (
          <InputField
            label="Ingresa tu teléfono"
            isDialCode
            placeholder="Teléfono"
            maxLength={10}
            onChangeInternalValue={onChange}
            blockDialCodeCountry
            errorMessages={errors.phone && errors.phone.message ? [errors.phone.message] : []}
            {...rest}
          />
        )}
      />
      <div className="radio-container">
        <p>Selecciona el tipo de operación que realizarás con nosotros:</p>
        <div className="radio-group">
          <label>
            <input
              type="radio"
              value="investor"
              {...register('operationType', { required: 'Este campo es obligatorio.' })}
            />
            Invertir
          </label>
          <label>
            <input
              type="radio"
              value="applicant"
              {...register('operationType', { required: 'Este campo es obligatorio.' })}
            />
            Solicitar crédito
          </label>
        </div>
        <ErrorMessage
          errorMessages={
            errors.operationType && errors.operationType.message
              ? [errors.operationType.message]
              : []
          }
        />
      </div>
    </form>
  );

  return (
    <WaitlistModalWrapper
      messageTitle="Únete a la lista de espera para formar parte de la comunidad Finsphera"
      type="none"
      isOpen={isOpen}
      messageChildren={waitListFormBody}
      onClose={onClose}
      buttons={[
        {
          label: 'Confirmar',
          onClick: handleSubmit(onSubmit),
          isFullWidth: true
        }
      ]}
    />
  );
};
