import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { url } from 'src/environments';
import {
  Banner,
  EsgInformationForm,
  EsgInformation,
  EsgInformationTitle,
  EsgFormContainer
} from './goalsDocuments.styles';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { ButtonsContainer } from '../equityForm.styles';
import { setAppError } from 'src/store/app/app.slice';

export default function GoalsDocuments() {
  const user = useSelector((state) => state.user.currentUser);
  const [uploadStatus, setUploadStatus] = useState('pending');
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState({
    sustainablePolicy: '',
    odsCertfication: '',
    sustainableAnualReport: '',
    otherDocument: ''
  });

  //FIle to base64
  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFile = async (file) => {
    if (!file) return;
    try {
      const base64String = await fileToBase64(file);
      return base64String;
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };

  const sendToServer = async () => {
    setLocalLoading(true);
    setUploadStatus('uploading');
    const fileKeys = Object.keys(uploadedFiles);
    const input = {
      id: user.id,
      files: []
    };
    for (const key of fileKeys) {
      if (!uploadedFiles[key]) {
        continue;
      }
      if (key === 'idFile') {
        let idFileCounter = 0;
        for (const file of uploadedFiles[key]) {
          const base64File = await handleFile(file);
          let fileName;
          if (idFileCounter === 0) {
            fileName = `${key}_esgDocuments.pdf`;
          } else {
            fileName = `${key}_${idFileCounter}.pdf`;
          }
          input.files.push({
            base64File,
            folder: 'esgDocuments',
            fileName: fileName
          });
          idFileCounter++;
        }
      } else {
        const base64File = await handleFile(uploadedFiles[key]);
        input.files.push({
          base64File,
          folder: 'esgDocuments',
          fileName: `${key}.pdf`
        });
      }
    }
    try {
      const upload = await axios.post(`${url}/admin/documents/upload-multiple`, input);
      const responseBody = JSON.parse(upload.data.body);
      if (responseBody.result === true) {
        navigate('/solicitante/dashboard/esg-equidad');
      } else {
        setLocalLoading(false);
        setAppError({
          errorCode: 'Ups, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.'
        });
      }
    } catch (error) {
      console.error(error);
      setAppError({
        errorCode: 'Ups, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.'
      });
      setLocalLoading(false);
    }
  };

  const handleReturn = () => {
    navigate('/solicitante/dashboard/esg');
  };
  return (
    <EsgFormContainer>
      <Banner>
        <h2>
          Análisis de
          <p> impacto Social y Ambiental</p>
          de la PyME solicitante de crédito.
        </h2>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image+41.png`}
            alt="Finsphera ícono"
          />
        </figure>
      </Banner>
      <EsgInformation>
        <EsgInformationTitle>
          <h1>Documentos de comprobación</h1>
          <h3>
            Captura alguno(s) de los siguientes documentos en caso de que la PyME cuente con ellos:
          </h3>
        </EsgInformationTitle>
        <EsgInformationForm>
          <Card className="card-container" isSlim isForm>
            <Card.Row>
              <InputUpload
                label="Su Política de Sustentabilidad, también conocida como: Política de Impacto Ambiental, Social y de Buen Gobierno Corporativo:"
                id="sustainablePolicy"
                size="small"
                accept=".pdf"
                wrapLabel={true}
                onChange={(e) => {
                  setUploadedFiles({
                    ...uploadedFiles,
                    sustainablePolicy: e.target.files?.[0] || null
                  });
                }}
              >
                Cargar archivo
              </InputUpload>
              <InputUpload
                label="Certificaciones de instituciones y organismos externos relacionados con el cumplimiento de los Objetivos de Desarrollo Sostenible de la ONU:"
                id="odsCertfication"
                size="small"
                accept=".pdf"
                wrapLabel={true}
                onChange={(e) => {
                  setUploadedFiles({
                    ...uploadedFiles,
                    odsCertfication: e.target.files?.[0] || null
                  });
                }}
              >
                Cargar archivo
              </InputUpload>
              <InputUpload
                label="Reporte Anual de Sustentabilidad: documento informativo para comunicar el desempeño dentro de este rubro"
                id="sustainableAnualReport"
                size="small"
                accept=".pdf"
                wrapLabel={true}
                onChange={(e) => {
                  setUploadedFiles({
                    ...uploadedFiles,
                    sustainableAnualReport: e.target.files?.[0] || null
                  });
                }}
              >
                Cargar archivo
              </InputUpload>
              <InputUpload
                label="Otro documento que compruebe que la PyME realiza actividades que promueven el cumplimiento de los Objetivos de Desarrollo Sostenible de la ONU:"
                id="otherDocument"
                size="small"
                wrapLabel={true}
                accept=".pdf"
                onChange={(e) => {
                  setUploadedFiles({
                    ...uploadedFiles,
                    otherDocument: e.target.files?.[0] || null
                  });
                }}
              >
                Cargar archivo
              </InputUpload>
            </Card.Row>
          </Card>
        </EsgInformationForm>
      </EsgInformation>
      <ButtonsContainer>
        <Button size="small" onClick={handleReturn}>
          Regresar
        </Button>
        <Button isLoading={localLoading} size="small" onClick={sendToServer}>
          {uploadStatus === 'completed' ? 'Actualizar archivos' : 'Subir archivos'}
        </Button>
      </ButtonsContainer>
    </EsgFormContainer>
  );
}
