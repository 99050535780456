const productRedeco = [
  {
    name: 'Comunicarse fuera del horario comprendido entre las 8:00 a las 21:00 horas, de acuerdo a los husos horarios del domicilio del Deudor',
    code: '0878'
  },
  {
    name: 'Dirigirse de manera no educada e irrespetuosa',
    code: '0888'
  },
  {
    name: 'Enviar o presentar documentos que aparenten ser escritos judiciales u ostentarse como representantes de algún órgano jurisdiccional o autoridad',
    code: '0884'
  },
  {
    name: 'Evidenciar al Deudor públicamente, a través de redes sociales, medios de difusión masiva, listas negras, cartelones o anuncios, entre otros; así como establecer registros especiales, con lo cual se haga del conocimiento del público la negativa de pago del Deudor',
    code: '0885'
  },
  {
    name: 'Realizar gestiones de cobro, negociación o reestructuración a personas que no son el Usuario, cliente y/o socio Deudor',
    code: '1146'
  },
  {
    name: 'Indicar una forma distinta de pago a la señalada por la Entidad Financiera, y/o solicitar que el pago del adeudo se realice de manera directa al Despacho de Cobranza',
    code: '0877'
  },
  {
    name: 'Omitir entregar al Deudor los documentos que contengan el acuerdo de pago, negociación o reestructuración del crédito, préstamo o financiamiento, que permita identificar los montos, pagos, intereses, requisitos, términos y condiciones del acuerdo',
    code: '0880'
  },
  {
    name: 'Omitir indicar el nombre de las personas responsables de la Cobranza',
    code: '0872'
  },
  {
    name: 'Omitir indicar el nombre, denominación o razón social, domicilio y/o número telefónico del Despacho de Cobranza',
    code: '0871'
  },
  {
    name: 'Omitir mencionar el crédito, préstamo, financiamiento, tipo de producto, operación y/o contrato motivo de la deuda en que se basa la acción de Cobranza',
    code: '0874'
  },
  {
    name: 'Omitir mencionar el domicilio, Correo Electrónico y/o número telefónico de la unidad administrativa de la Entidad Financiera encargada de recibir las Quejas por malas prácticas de Cobranza',
    code: '0876'
  },
  {
    name: 'Omitir mencionar el monto del adeudo, fecha de cálculo y/o términos y condiciones para liquidar el adeudo',
    code: '0875'
  },
  {
    name: 'Omitir mencionar el nombre de la Entidad Financiera que otorgó el crédito, préstamo o financiamiento',
    code: '0873'
  },
  {
    name: 'Omitir mencionar que puede presentar una Queja a través del REDECO por malas prácticas de Cobranza',
    code: '1353'
  },
  {
    name: 'Realizar Cobranza de un Crédito ya pagado',
    code: '0563'
  },
  {
    name: 'Realizar gestiones de cobro, negociación o reestructuración con menores de edad o adultos mayores, salvo que en el último supuesto se trate de los Deudores',
    code: '1351'
  },
  {
    name: 'Realizar gestiones de cobro, negociación o reestructuración en el domicilio, teléfono y/o Correo Electrónico distinto al proporcionado por el Deudor, obligado solidario o aval',
    code: '0887'
  },
  {
    name: 'Realizar gestiones de cobro utilizando teléfonos no registrados por la Entidad Financiera en el REDECO',
    code: '1354'
  },
  {
    name: 'Realizar la Cobranza mediante el uso de grabaciones de forma no personalizada',
    code: '1352'
  },
  {
    name: 'Utilizar números de teléfono que aparezcan en el identificador de llamadas como "confidencial", "oculto", "privado" o con cualquier otra expresión o denominación semejante que imposibilite su identificación',
    code: '0882'
  },
  {
    name: 'Utilizar un nombre o denominación que se asemeja a una institución pública',
    code: '0881'
  }
];

export default productRedeco;
