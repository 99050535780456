import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalEdit, Success, Figure, ActionSection } from './updateMarketing.styles';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { environment, url } from 'src/environments';
import { errorHandler } from 'src/middlewares/Auth/index.utilities';

export default function UpdateMarketingModal({
  marketingModal,
  setMarketingModal,
  typeOfUser,
  marketing
}) {
  const [successText, setSuccessText] = useState();
  const user = useSelector((state) => state.user.currentUser);

  const closeModal = () => {
    setMarketingModal(false);
  };

  const sendRequest = async () => {
    const newMarketingValue = marketing === '1' ? '0' : '1';
    const updateMarketing = {
      type: 'update_isInMarketing',
      input: {
        id: user.id,
        typeOfUser: typeOfUser,
        isInMarketing: newMarketingValue
      }
    };
    try {
      const request = await axios.post(`${url}/admin/dynamo/update`, updateMarketing);
      setSuccessText(true);
      return request;
    } catch (error) {
      setSuccessText(false);
      console.error(error);
    }
  };

  return (
    <ModalEdit marketingModal={marketingModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu solicitud se ha registrado de forma exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <ActionSection>
            <h3>
              Estás a punto de actualizar tu configuración para recibir publicidad de Finsphera vía
              correo electrónico
            </h3>
            <Button type="submit" onClick={sendRequest}>
              {marketing === '1' ? 'Cancelar publicidad' : 'Habilitar publicidad'}
            </Button>
            <p>Recuerda que puedes modificar esta opción desde tu cuenta cuando lo desees.</p>
          </ActionSection>
        </div>
      )}
    </ModalEdit>
  );
}
