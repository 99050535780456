import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { navBarValues } from '../Navbar/navbar.styles';
import { LayoutWrapperProps } from './LayoutWrapper';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';

const textStyle = ({ isNewDesign }: LayoutWrapperProps): FlattenSimpleInterpolation => {
  switch (isNewDesign) {
    case true:
      return css`
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        a {
          color: ${colors.text.black};
          font-family: Roboto;
        }

        p,
        a {
          font-weight: 400;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: 700;
        }
      `;
    default:
      return css``;
  }
};

export const LayoutWrapperContainer = styled.div<LayoutWrapperProps>`
  ${textStyle}

  .main-wrapper {
    padding-top: ${({ isNewDesign }) => (isNewDesign ? '100px' : navBarValues.height)};
    max-width: 2561px;
    margin: 0 auto;
  }

  .spiner-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: white;
  }
`;
