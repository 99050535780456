/* eslint-disable no-console */
// @ts-nocheck TODO: remove when typed, this file was a javascript file
import { API, Auth, graphqlOperation } from 'aws-amplify';
import axios from 'axios';
import {
  createApplicant_signup,
  createInvestor_signup,
  updateInvestor_login,
  updateApplicant_login,
  updateApplicant_Onboarding,
  createRiskOnboarding,
  updateApplicant_webPage
} from 'src/graphql/customized_mutations';
import { getCreditApproved } from 'src/graphql/customized_queries';
import { isByPass, url, config } from 'src/environments';

export const createNewInvestor = async ({
  id,
  typeOfPerson,
  email,
  password,
  isInMarketing,
  ip,
  geolocation,
  date
}) => {
  const input = {
    id,
    typeOfPerson,
    createdAt: date,
    updatedAt: date,
    isInMarketing: isInMarketing,
    ipAddress: {
      IPv4: ip.IPv4,
      country_code: ip.country_code,
      country_name: ip.country_name,
      city: ip.city,
      state: ip.state,
      date: date
    },
    geolocation: {
      accuracy: geolocation.accuracy,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      date: geolocation.date
    },
    personalInformation: {
      email
    }
  };
  try {
    await Auth.signIn(email, password);
    await Auth.currentSession();
    try {
      await API.graphql(graphqlOperation(createInvestor_signup, { input }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  } catch (err) {
    console.error(2, err);
    throw err;
  }
};

export const createNewApplicant = async ({
  id,
  email,
  password,
  isInMarketing,
  geolocation,
  ip,
  date
}) => {
  const input = {
    id,
    createdAt: date,
    updatedAt: date,
    isInMarketing: isInMarketing,
    ipAddress: {
      IPv4: ip.IPv4,
      country_code: ip.country_code,
      country_name: ip.country_name,
      city: ip.city,
      state: ip.state,
      date: date
    },

    geolocation: {
      accuracy: geolocation.accuracy,
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      date: geolocation.date
    },
    personalInformation: {
      email
    }
  };
  try {
    await Auth.signIn(email, password);
    try {
      await API.graphql(graphqlOperation(createApplicant_signup, { input }));
    } catch (err) {
      console.error(err);
      throw err;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Updates the IP address everytime the applicant signsIn
export const udpateApplicantAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: updateApplicant_login,
      variables: {
        input
      }
    });
    return data.updateApplicant;
  } catch (error) {
    console.error(error);
  }
};
//Updates the IP address everytime the investor signsIn
export const udpateInvestorAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: updateInvestor_login,
      variables: {
        input
      }
    });
    return data.updateInvestor;
  } catch (error) {
    console.error(error);
  }
};

//Función para id incremental interno
export const internalIdAPI = async ({ id, typeOfUser }) => {
  try {
    const data = { id, typeOfUser };
    const createId = await axios.post(`${url}/admin/get-incremental-id`, data);
    return createId.data;
  } catch (error) {
    console.error(error);
    throw new Error(error.errorMessage || 'Unknown error');
  }
};

//Función para Trully validación inicial
export const trullyFirstStepAPI = async ({ id, typeOfUser }) => {
  try {
    if (isByPass === true || config.env === 'dev') return 'No Threat';

    const data = { id, typeOfUser };
    const validateData = await axios.post(`${url}/trully/enrich-data/predict/first-step`, data);
    const body = JSON.parse(validateData.data.body);
    const label = body.label;
    return label;
  } catch (error) {
    console.error(error);
    throw new Error(error.errorMessage || 'Unknown error');
  }
};

//Onboarding updates
export const udpateApplicantOnb = async (input) => {
  try {
    const data = await API.graphql({
      query: updateApplicant_Onboarding,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

//Onboarding updates
export const updateInvestorCompanyAPI = async (input) => {
  const { data } = await axios.post(`${url}/admin/dynamo/update`, input);
  return data;
};

//Onboarding updates : Investor update biometrics physical
export const updateInvestorInfoAPI = async (input) => {
  const { data } = await axios.post(`${url}/admin/dynamo/update`, input);
  return data.updateInvestor;
};

//
export const udpateInvestorRisk = async (input) => {
  try {
    const data = await API.graphql({
      query: createRiskOnboarding,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

//Query para obtener el reultado del cálculo de crédito de SATWS desde la DB
export const getCreditApprovedAPI = async (body) => {
  try {
    const response = await API.graphql(
      graphqlOperation(getCreditApproved, { applicantId: body.id })
    );
    return response.data.getCreditAnalysis;
  } catch (error) {
    console.error({ error });
    throw error;
  }
};

//Cálculo de crédito con SATWS
export const SatwsOnboardingAPI = async (body) => {
  try {
    const acidTest = await axios.post(
      `${url}/satws/credit-analysis/liquidity/${body.rfc}/acid-test`,
      { id: body.id }
    );
    const paymentAbility = await axios.post(
      `${url}/satws/credit-analysis/liquidity/${body.rfc}/immediate-payment-ability`,
      { id: body.id }
    );
    const liquidityRadio = await axios.post(
      `${url}/satws/credit-analysis/liquidity/${body.rfc}/liquidity-ratio`,
      { id: body.id }
    );
    const businessAge = await axios.post(
      `${url}/satws/credit-analysis/market-and-product/${body.rfc}/business-age`,
      { id: body.id }
    );
    const capitalRegime = await axios.post(
      `${url}/satws/credit-analysis/market-and-product/${body.rfc}/capital-regime`,
      { id: body.id }
    );
    const federalEntity = await axios.post(
      `${url}/satws/credit-analysis/market-and-product/${body.rfc}/federal-entity`,
      { id: body.id }
    );
    const mainActivty = await axios.post(
      `${url}/satws/credit-analysis/market-and-product/${body.rfc}/main-activity`,
      { id: body.id }
    );
    const taxPayer = await axios.post(
      `${url}/satws/credit-analysis/market-and-product/${body.rfc}/status-in-taxpayer-registry`,
      { id: body.id }
    );
    const growthSales = await axios.post(
      `${url}/satws/credit-analysis/profitability/${body.rfc}/compound-annual-growth-in-sales`,
      { id: body.id }
    );
    const growthUtility = await axios.post(
      `${url}/satws/credit-analysis/profitability/${body.rfc}/compound-annual-growth-in-utility`,
      { id: body.id }
    );
    const profitability = await axios.post(
      `${url}/satws/credit-analysis/profitability/${body.rfc}/profitability`,
      { id: body.id }
    );
    const growthClients = await axios.post(
      `${url}/satws/credit-analysis/solvency/${body.rfc}/annual-growth-in-clients`,
      { id: body.id }
    );
    const corporateClients = await axios.post(
      `${url}/satws/credit-analysis/solvency/${body.rfc}/major-corporate-clients`,
      { id: body.id }
    );
    const taxRegime = await axios.post(
      `${url}/satws/credit-analysis/solvency/${body.rfc}/tax-regime`,
      { id: body.id }
    );
    const roa = await axios.post(`${url}/satws/credit-analysis/solvency/${body.rfc}/roa`, {
      id: body.id
    });
    const roe = await axios.post(`${url}/satws/credit-analysis/solvency/${body.rfc}/roe`, {
      id: body.id
    });
    const salesConcentration = await axios.post(
      `${url}/satws/credit-analysis/solvency/${body.rfc}/sales-concentration`,
      { id: body.id }
    );
    const taxCheck = await axios.post(
      `${url}/satws/credit-analysis/solvency/${body.rfc}/tax-compliance-check`,
      { id: body.id }
    );
    const satwsOnboarding = await axios.post(`${url}/admin/credit-analysis/satws/onboarding`, {
      id: body.id
    });
    const financialIndicators = await axios.get(`${url}/admin/finantial-indicators?id=${body.id}`);

    return [
      {
        financialIndicators: financialIndicators.data,
        acidTest: acidTest.data,
        paymentAbility: paymentAbility.data,
        liquidityRadio: liquidityRadio.data,
        businessAge: businessAge.data,
        capitalRegime: capitalRegime.data,
        federalEntity: federalEntity.data,
        mainActivity: mainActivty.data,
        taxPayer: taxPayer.data,
        growthSales: growthSales.data,
        growthUtility: growthUtility.data,
        profitability: profitability.data,
        growthClients: growthClients.data,
        corporateClients: corporateClients.data,
        taxRegime: taxRegime.data,
        roa: roa.data,
        roe: roe.data,
        salesConcentration: salesConcentration.data,
        taxCheck: taxCheck.data,
        satwsOnboarding: satwsOnboarding.data
        // simafIds: simafIds.data
      }
    ];
  } catch (error) {
    console.error(error);
    throw new Error(error.errorMessage || 'Unknown error');
  }
};

//Campaign webSite update Applicant
export const udpateApplicantCampaign = async (input) => {
  try {
    const data = await API.graphql({
      query: updateApplicant_webPage,
      variables: {
        input
      }
    });
    console.error(data);
    return data;
  } catch (error) {
    console.error(error);
  }
};
