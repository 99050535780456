import {
  ModalCreditSuccess,
  ContentContainer,
  ButtonsContainer,
  ConfirmButton,
  MainInfo,
  Success,
  Headers
} from './creditWithdrawSuccess.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { url } from 'src/environments';
import { useSelector } from 'react-redux';
import { getCreditInfo } from 'src/graphql/customized_queries';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
export default function CreditWithdrawModal({
  showModalCreditSuccess,
  closeModal,
  campaignData,
  personalInformation,
  bankInformation
}) {
  //Doble autenticación
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [tasaInteresAdicionalPorcentaje, setTasaInteresAdicionalPorcentaje] = useState('');
  const [tiie, setTiie] = useState(null);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  //JALAR TIIE e InterestRate para recalculo de intereses
  useEffect(() => {
    axios
      .get(`${url}/admin/get-tiie`)
      .then((s) => {
        const parsedData = JSON.parse(s.data.body);
        setTiie(Number(parsedData.TIIE));
      })
      .catch((error) => error);
  }, []);
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getCreditInfo,
          variables: { applicantId: user.id }
        });
        setTasaInteresAdicionalPorcentaje(Number(data.getCreditAnalysis.interestRateApplicant));
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  const transfer =
    campaignData?.collectedAmount -
    ((0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount +
      (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount * 0.16);
  const handleSendEmail = () => {
    const input = {
      id: user.id,
      typeOfUser: 'applicant',
      typeMessage: 'withdrawMoneyCampaign',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const onConfirm = async () => {
    setLocalLoading(true);
    const accountList = bankInformation?.data?.getApplicant?.banks?.items;
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: 'applicant'
    };
    const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
    const parsedBody = JSON.parse(validationCode.data.body);
    if (!parsedBody.isVerified) {
      alert('Validación de código fallida');
      return;
    }
    const updateCampaign = {
      type: 'update_campaign_money_related',
      input: {
        campaignId: campaignData.id,
        isMoneySent: 1,
        montoTransferencia:
          campaignData?.collectedAmount - campaignData?.comisionCreditoPesosConIVA,
        interesCreditoPesos:
          ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
          campaignData?.plazoCredito *
          campaignData?.collectedAmount,
        IVAinteresCreditoPesos:
          ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
          campaignData?.plazoCredito *
          campaignData?.collectedAmount *
          0.16,
        interesesCreditoPesosConIVA:
          ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
            campaignData?.plazoCredito *
            campaignData?.collectedAmount +
          ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
            campaignData?.plazoCredito *
            campaignData?.collectedAmount *
            0.16,
        comisionCreditoPesosFinal:
          (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount,
        IVAcomisionCreditoPesosFinal:
          (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount * 0.16,
        comisionCreditoPesosConIVAFinal:
          (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount +
          (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount * 0.16,
        inversionFinspheraCampaniaPesos: 0.01 * campaignData?.collectedAmount
      }
    };
    const campaign = campaignData?.id;
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/release-of-resources`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: {
        campaignId: campaign
      }
    };
    try {
      const response = await axios(config);
      if (response.status === 201) {
        const updateResponse = await axios.post(`${url}/admin/dynamo/update`, updateCampaign);
        setLocalLoading(false);
        setSuccessText(true);
      } else {
        console.error('First request did not return the expected response');
        setLocalLoading(false);
      }
    } catch (error) {
      setLocalLoading(false);
      console.error(error);
    }
  };

  return (
    <ModalCreditSuccess showModalCreditSuccess={showModalCreditSuccess}>
      {!successText ? (
        <ContentContainer>
          <Headers>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </Headers>
          <h2>
            ¡Estás apunto de confirmar la transferencia del crédito a tu cuenta con{' '}
            <span>FINSPHERA!</span>
          </h2>
          <MainInfo>
            <div>
              <h3>Nombre de campaña:</h3>
              <p>{campaignData?.nombreCampania ? campaignData?.nombreCampania : '-'} </p>
            </div>
            <div>
              <h3>Monto recolectado:</h3>
              <p>
                {formatCurrency(campaignData?.collectedAmount)
                  ? formatCurrency(campaignData?.collectedAmount)
                  : '-'}
              </p>
            </div>
            <div>
              <h3>Comisión + IVA del monto recolectado:</h3>
              <p>
                {formatCurrency(
                  (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount +
                    (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount * 0.16
                )}
              </p>
            </div>
            <div>
              <h3>Monto a transferir:</h3>
              <p>{formatCurrency(transfer)}</p>
            </div>
            <div>
              <h3>Plazo del crédito:</h3>
              <p>{campaignData?.plazoCredito ? campaignData?.plazoCredito : '-'} días</p>
            </div>
          </MainInfo>
          {showInput ? (
            <ButtonsContainer>
              <p>Ingresa el código que enviamos a tu correo:</p>
              <input
                type="text"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <Button isLoading={localLoading} size="small" onClick={onConfirm}>
                Confirmar código
              </Button>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              <ConfirmButton size="small" onClick={handleSendEmail}>
                Aceptar
              </ConfirmButton>
            </ButtonsContainer>
          )}
        </ContentContainer>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono modal exitoso"
            />
          </figure>
          <h2>¡Tu transferencia se ha realizado de forma exitosa!</h2>
          <p>
            Puedes verificar tu fecha próxima de pago o liquidar tu crédito en la sección de
            Campañas/ Ver Pagos.
          </p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalCreditSuccess>
  );
}
