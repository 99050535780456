import { ProfessionalInfoContainer, ProfesionalSection } from './professionalInfo.styles';

export default function ProfessionalInfo({ personalInformation }) {
  let professionalInfo;
  const data = personalInformation?.data?.getInvestor;

  switch (data.typeOfPerson) {
    case 'MORAL':
      professionalInfo = (
        <>
          <div>
            <h3>Nombre de compañía:</h3>
            <p>
              {data.companyInformation?.companyName?.legalName
                ? data.companyInformation?.companyName?.legalName
                : '-'}
            </p>
          </div>
          <div>
            <h3>RFC:</h3>
            <p>{data.companyInformation?.RFC ? data.companyInformation?.RFC : '-'}</p>
          </div>
          <div>
            <h3>Denominación:</h3>
            <p>
              {data.companyInformation?.denomination ? data.companyInformation?.denomination : '-'}
            </p>
          </div>
          <div>
            <h3>Actividad general:</h3>
            <p>
              {data.companyInformation?.generalActivity
                ? data.companyInformation?.generalActivity
                : '-'}
            </p>
          </div>
          <div>
            <h3>Actividad específica:</h3>
            <p>
              {data.companyInformation?.specificActivity
                ? data.companyInformation?.specificActivity
                : '-'}
            </p>
          </div>
        </>
      );
      break;
    case 'FISICA':
      professionalInfo = (
        <>
          <div>
            <h3>Actividad general:</h3>
            <p>
              {data.personalInformation?.generalActivity
                ? data.personalInformation?.generalActivity
                : '-'}
            </p>
          </div>
          <div>
            <h3>Actividad específica:</h3>
            <p>
              {data.personalInformation?.specificActivity
                ? data.personalInformation?.specificActivity
                : '-'}
            </p>
          </div>
        </>
      );
      break;
    default:
      professionalInfo = <p>Invalid typeOfPerson</p>;
  }
  return (
    <ProfessionalInfoContainer>
      <ProfesionalSection>{professionalInfo}</ProfesionalSection>
    </ProfessionalInfoContainer>
  );
}
