import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRegisterCLABE, RegisterCLABEForm, Success } from './registerCLABE.styles';
import banks from 'src/constants/banks';
import { createApplicantBankAccount } from 'src/store/User/user.slice';
import getCurrentDate from 'src/functions/getCurrentDate';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { url } from 'src/environments';
import createNumber from 'src/functions/createNumber';

export default function RegisterCLABE({
  showModalRegisterCLABE,
  closeModal,
  personalInformation,
  bankData
}) {
  const [code, setCode] = useState('');
  const [step, setStep] = useState(2);
  const [successText, setSuccessText] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  // const [legalName, setLegalName] = useState('');
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const [data, setData] = useState({
    identifier: '',
    registerCLABE: ''
  });
  //Para validar teléfono MFA
  const [mobile] = useState(
    personalInformation?.data?.getApplicant?.personalInformation?.phone?.mobile
  );
  const [referenceId, setReferenceId] = useState('');
  const [verificationCode] = useState('');
  //Campos para stp validación
  const [beneficiaryName] = useState(
    personalInformation?.data?.getApplicant?.personalInformation?.fullName
  );
  const [beneficiaryRFC] = useState(
    personalInformation?.data?.getApplicant?.companyInformation?.RFC
  );
  const legalName =
    personalInformation?.data?.getApplicant?.companyInformation?.companyName?.legalName;

  useEffect(() => {
    if (showModalRegisterCLABE) {
      setCode('');
      setStep(1);
      setSuccessText(false);
      setSelectedBank(null);
      setData({
        identifier: '',
        registerCLABE: ''
      });
      setCheckboxes({
        isMainAccount: bankData.length === 0 ? true : false
      });
    }
  }, [showModalRegisterCLABE, bankData]);

  const [checkboxes, setCheckboxes] = useState({
    isMainAccount: false
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const handleBankChange = (event) => {
    const selectedBank = banks.find((bank) => bank.name === event.target.value);
    setSelectedBank(event.target.value);
    if (selectedBank) {
      setSelectedBankCode(selectedBank.code);
    }
  };

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };
  //Función para enviar el código MFA al celular previamente validado
  const handleAccount = async (event) => {
    event.preventDefault();
    const input = {
      id: user.id,
      typeOfUser: 'applicant'
    };
    const phone = await axios.post(`${url}/admin/mobile/${mobile}/get-otp`, input);
    const parsedBody = JSON.parse(phone.data.body);
    const referenceId = parsedBody.ReferenceId;
    setReferenceId(referenceId);
    setStep(2);
  };
  const finspheraAccount = process.env.REACT_APP_STPACCOUNT_FINSPHERA;
  const clienteOrdenante = process.env.REACT_APP_STP_ACUSECEP_FINSPHERA;
  //Función para validar el código de MFA, para crear la cuenta bancaria en base de datos y para validar con stp
  const handleVerifyCode = async (event) => {
    event.preventDefault();
    setLocalLoading(true);
    let idKey = user.groupName === 'investor' ? 'investorId' : 'applicantId';
    const createInput = {
      [idKey]: user.id,
      identifier: data.identifier,
      CLABE: data.registerCLABE,
      bankName: selectedBank,
      bankCode: String(selectedBankCode),
      isMainAccount: checkboxes.isMainAccount,
      isMFA: true,
      createdAt: getCurrentDate(),
      updatedAt: getCurrentDate()
    };
    const body = {
      id: user.id,
      otp: verificationCode,
      referenceId: referenceId,
      typeOfUser: 'applicant'
    };
    //Input STP : Los comentarios se quedan para que en prod dejemos de hardcodear los valores con el código comentado
    const stp = {
      clienteId: user.id,
      tipoCliente: 'applicant',
      conceptoPago: 'AcuseCep',
      cuentaBeneficiario: data.registerCLABE,
      institucionContraparte: '90646',
      monto: '0.01',
      nombreBeneficiario: 'ND',
      referenciaNumerica: createNumber(),
      nombreOrdenante: 'SUSTAINABLE',
      rfcCurpBeneficiario: beneficiaryRFC,
      rfcCurpOrdenante: 'STE210701CY8',
      cuentaOrdenante: String(finspheraAccount),
      clienteOrdenanteId: String(clienteOrdenante)
    };
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registra-orden`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: stp
    };
    try {
      // Validate OTP
      const response = await axios.post(`${url}/admin/mobile/${mobile}/validate-otp`, body);
      const parsedBody = JSON.parse(response.data.body);
      const codeResponse = parsedBody.isVerified;
      if (codeResponse === true) {
        // Si la validación OTP es correcta, se ejecuta stp
        const stpResp = await axios(config);
        const stpId = stpResp.data?.resultado?.id;
        const isStpSuccess = typeof stpId === 'number' && stpId.toString().length > 6;
        if (isStpSuccess) {
          dispatch(createApplicantBankAccount(createInput));
          setSuccessText(true);
        } else {
          console.error('Error:', stpResp);
          setLocalLoading(false);
        }
      } else {
        console.error('OTP validation failed:', parsedBody);
        setLocalLoading(false);
      }
    } catch (error) {
      console.error('catch error:', error);
      setLocalLoading(false);
    }
  };

  return (
    <ModalRegisterCLABE showModalRegisterCLABE={showModalRegisterCLABE}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="ícono de operación exitosa"
            />
          </figure>
          <h2>Tu cuenta ha sido dada de alta de forma exitosa</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <div>
            <h1>Dar de alta una nueva CLABE</h1>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </div>
          <RegisterCLABEForm>
            <h3>Asegúrate de dar de alta la CLABE interbancaria de tu compañía:</h3>
            <h2>{legalName}</h2>
            <h3>Nombre (opcional)</h3>
            <input type="text" name="identifier" onChange={handleInputChange} maxLength={18} />
            <h3>Nº de CLABE interbancaria</h3>
            <input type="text" name="registerCLABE" onChange={handleInputChange} maxLength={18} />
            <p>La CLABE debe de contener 18 caracteres.</p>
            <h3>Selecciona el banco</h3>
            <select name="banks" onChange={handleBankChange}>
              <option value={undefined}>Seleccionar una opción</option>
              {banks.map((eAG, i) => (
                <option key={i} value={eAG.name}>
                  {eAG.name}
                </option>
              ))}
            </select>
            {step === 2 && (
              <>
                <h3>Ingresa el código de verificación</h3>
                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
              </>
            )}
          </RegisterCLABEForm>
          <div>
            <Button onClick={closeModal}>Cerrar</Button>
            {step === 1 && (
              <Button isLoading={localLoading} onClick={handleAccount}>
                Confirmar alta de CLABE
              </Button>
            )}
            {step === 2 && (
              <Button isLoading={localLoading} onClick={handleVerifyCode}>
                Confirmar código
              </Button>
            )}
          </div>
        </div>
      )}
    </ModalRegisterCLABE>
  );
}
