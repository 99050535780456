import styled from 'styled-components/macro';

import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  brandYellowgreen,
  neutralBlueGray,
  grayPlaceholder
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalCreditSuccess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.showModalCreditSuccess ? 'flex' : 'none')};

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  animation: ${(props) => (props.showModalCreditSuccess ? fadeIn : fadeOut)} 0.5s linear;

  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;

    > h3 {
      font-size: 1.5vw;
      color: ${grayMediumSphera};
    }
    > p {
      color: ${brandBlack};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > a {
        font-size: 4.3vw;
        width: 100%;
        padding: 3% 8%;

        &:hover {
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
  > h2 {
    font-size: 2.5vw;
    text-align: center;
    color: ${brandBlack};
    > span {
      color: ${greenSp};
      font-size: 2.5vw;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      font-size: 3vw;
      > span {
        font-size: 3vw;
      }
    }
  }
  @media (max-width: 600px) {
    > h2 {
      font-size: 5vw;
      > span {
        font-size: 5vw;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  > p {
    width: 100%;
    text-align: center;
  }
  > input {
    width: 50%;
    margin: 2% 25%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid ${grayPlaceholder};
    font-size: 1.2vw;
    text-align: center;
    letter-spacing: 2vw;
    color: ${grayPlaceholder};
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      font-size: 1.5vw;
    }
  }
  @media (max-width: 600px) {
    > p {
      font-size: 2.8vw;
    }
  }
`;

export const ConfirmButton = styled.button`
  color: ${brandBlack};
  font-size: 1.2vw;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0 30%;
  padding: 2% 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandYellowgreen};
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 1.5vw;
  }
  @media (max-width: 600px) {
    font-size: 3.5vw;
  }
`;

// export const CloseButton = styled.button`
//   color: ${whiteSphera};
//   font-size: 1.2vw;
//   font-weight: bold;
//   text-align: center;
//   text-decoration: none;
//   width: 40%;
//   margin: 2% 30%;
//   padding: 1%;
//   border: none;
//   border-radius: 5px;
//   background-color: ${brandBlack};
//   cursor: pointer;
//   transition: all 0.5s;
//   &:hover {
//     transform: scale(1.1);
//   }
// `;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5% 0;
  > div {
    width: 60%;
    display: flex;
    justify-content: space-between;
    h3 {
      width: 50%;
      font-size: 1.4vw;
      color: ${grayMediumSphera};
      text-align: center;
      font-weight: 100;
      margin: 1% 0;
    }
    > p {
      width: 50%;
      color: ${greenSp};
      text-align: center;
      font-size: 1.4vw;
      font-weight: bold;
      margin: 1% 0;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > div {
      > h3 {
        font-size: 3.2vw;
      }
      > p {
        font-size: 3.2vw;
      }
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: 1.6vw;
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: 1.4vw;
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    > a {
      color: ${greenSp};
      font-size: 1.4vw;
      font-weight: bold;
    }
  }
`;

export const Headers = styled.section`
  padding-bottom: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #b7bcc7;
  > div {
    width: 90%;
  }

  > figure {
    width: 30px;
    height: 30px;
    cursor: pointer;

    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    padding-bottom: 5%;
    > figure {
      width: 25px;
      height: 25px;
    }
  }
`;
