import { FC, useEffect } from 'react';
import { ApplicantCalculatorProps } from './applicantCalculator.types';
import { ApplicantCalculatorContainer } from './applicantCalculator.styles';
import { useForm } from 'react-hook-form';
import { IVA, informativeAnnualInterestRate, PYMEAnnualComission } from 'src/constants/values';

export const ApplicantCalculator: FC<ApplicantCalculatorProps> = () => {
  const {
    register,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm();

  const amountRequested = Number(watch('requestedAmount'));
  const termInDays = watch('term');
  const maxAmount = 12000000;
  const minAmount = 50000;
  const maxTerm = 120;
  const minTerm = 30;

  useEffect(() => {
    if (amountRequested > maxAmount) {
      setValue('requestedAmount', maxAmount);
    }

    if (termInDays > maxTerm) {
      setValue('term', maxTerm);
    }

    if (amountRequested < minAmount || termInDays < minTerm) {
      setError('requestedAmount', {
        type: 'manual',
        message: 'El monto mínimo es de $50,000.00 y el plazo mínimo es de 30 días.'
      });
    } else {
      clearErrors('requestedAmount');
    }

    if (isNaN(amountRequested)) {
      setValue('requestedAmount', minAmount);
    }
  }, [amountRequested, termInDays, setValue]);

  const calculateInterestIncludingVAT = (
    informativeAnnualInterestRate: number,
    termInDays: number,
    amountRequested: number,
    IVA: number
  ) => {
    return (informativeAnnualInterestRate / 360) * termInDays * amountRequested * (1 + IVA);
  };

  const calculateCommissionIncludingVAT = (
    PYMEAnnualComission: number,
    termInDays: number,
    amountRequested: number,
    IVA: number
  ) => {
    return (PYMEAnnualComission / 360) * termInDays * amountRequested * (1 + IVA);
  };

  const formatMoney = (money: number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Number(money));
  };

  const shouldDisplayResults =
    amountRequested >= minAmount && termInDays >= minTerm && Object.keys(errors).length === 0;

  const interestValue = calculateInterestIncludingVAT(
    informativeAnnualInterestRate,
    termInDays,
    amountRequested,
    IVA
  );
  const commissionValue = calculateCommissionIncludingVAT(
    PYMEAnnualComission,
    termInDays,
    amountRequested,
    IVA
  );
  const totalAmountToPayValue = interestValue + commissionValue + amountRequested;

  const interestIncludingVAT = formatMoney(interestValue);
  const commissionIncludingVAT = formatMoney(commissionValue);
  const totalAmountToPay = formatMoney(totalAmountToPayValue);

  return (
    <ApplicantCalculatorContainer>
      <div className="calc-form">
        <p>
          Tu nivel dependerá de la evaluación de tu empresa. Cada Nivel cuenta con una comisión por
          apertura y tasa de interés.
        </p>
        <div>
          <p className="bold-text">Tasa informativa anual</p>
          <p>{informativeAnnualInterestRate * 100}%</p>
        </div>
        <div className="calc-form__input">
          <p className="bold-text">Monto solicitado</p>
          <div>
            <input
              {...register('requestedAmount')}
              placeholder="$"
              type="number"
              min={50000}
              step={1000}
              defaultValue={50000}
            />
            <p>
              $
              {new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              }).format(amountRequested)}
            </p>
          </div>
        </div>
        <div className="calc-form__input">
          <p className="bold-text">Elige un plazo</p>
          <div>
            <input
              {...register('term')}
              type="number"
              min={minTerm}
              placeholder="Días"
              defaultValue={minTerm}
            />
            <p>{termInDays ? `${termInDays} días` : '0 días'}</p>
          </div>
        </div>
        {/* Error message */}
        <div>
          {errors.requestedAmount && typeof errors.requestedAmount.message === 'string' ? (
            <p className="error-message">{errors.requestedAmount.message}</p>
          ) : null}
        </div>
      </div>

      <div className="calc-results">
        <div className="calc-results--header">
          <p>Aquí podrás ver con claridad las condiciones de tu crédito.</p>
        </div>
        <div className="calc-results--body">
          <h3>Descripción</h3>
          <h3>Cantidad</h3>

          <p className="bold-text">Interés (incluye IVA) a pagar al final del crédito.</p>
          {shouldDisplayResults ? <p>{'$' + interestIncludingVAT}</p> : <p>$0.00</p>}

          <p className="bold-text">Comisión (incluye IVA) pagadera al inicio del crédito.</p>
          {shouldDisplayResults ? <p>{'$' + commissionIncludingVAT}</p> : <p>$0.00</p>}

          <p className="bold-text">Cantidad a Pagar al vencimiento del crédito.</p>
          {shouldDisplayResults ? <p>${totalAmountToPay}</p> : <p>$0.00</p>}
        </div>
        <div className="calc-results--footer"></div>
      </div>
    </ApplicantCalculatorContainer>
  );
};
