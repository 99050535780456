import styled from 'styled-components/macro';
import {
  brandBlack,
  grayLightSphera,
  graySphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const CreditInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);
  padding: 2.5%;
  > h2 {
    width: 60%;
    margin: 2% 20%;
    color: ${graySphera};
  }
  > p {
    width: 90%;
    margin: 2% 5%;
    color: ${brandBlack};
    font-size: 1.2vw;
    text-align: center;
    font-weight: bold;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
  }
`;

export const Banner = styled.div`
  background-color: ${whiteSphera};
  padding: 2.5% 0;
  > div {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    margin: auto;
    padding-left: 12%;
    > figure {
      width: 60px;
      height: 60px;
      margin-right: 5%;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > h2 {
      color: ${brandBlack};
      font-size: 2vw;
      width: 30%;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > figure {
        > img {
        }
      }
      > h2 {
      }
    }
  }

  @media (max-width: 600px) {
    padding: 2.5% 0 2.5% 2.55%;
    > div {
      justify-content: center;
      flex-wrap: wrap;
      > figure {
        width: 120px;
        height: 120px;
        margin-right: 5%;
        > img {
        }
      }
      > h2 {
        font-size: 5vw;
        width: 100%;
        margin-right: 0;
        margin-bottom: 5%;
        text-align: center;
      }
    }
  }
`;

export const FormContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  margin: 2.5% 10%;
  padding: 0;
  /* background-color: orange; */
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    width: 95%;
    margin: 5% auto;
    padding: 5%;
  }
`;

export const ComplaintSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2% 30% 2% 30%;
  width: 40%;
  > div {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    > p {
      width: 100%;
    }
    > h4 {
      width: 100%;
    }
  }
  > Button {
    width: 60%;
    margin: 2% 20%;
    background-color: ${greenSp};
    font-size: ${uIFontSize.medium};
  }
`;

export const FormData = styled.div`
  width: 30%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 2%;
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const FormDataTitle = styled.h2`
  font-size: 1.5vw;
  color: ${greenSp};
  margin-bottom: 2%;
  border-bottom: 1px solid ${grayLightSphera};
  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 1.5vw;
  }

  @media (max-width: 600px) {
    font-size: 3vw;
    margin-bottom: 5%;
  }
`;

export const FormEditable = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2% 20%;
  width: 60%;
  > div {
    display: flex;
    flex-wrap: wrap;
    > p {
      width: 100%;
    }
  }
`;

export const FormInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2% 20%;
  width: 60%;
  > p {
    width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
`;
export const FormDate = styled.div`
  display: flex;
  margin: 2% 20%;
  width: 60%;
  > div {
    flex-direction: column;
    width: 50%;
    justify-content: space-between;
    align-content: center;
    text-align: center;
  }
`;

export const StyledButton = styled.button`
  background-color: ${(props) => (props.disabled ? grayLightSphera : brandBlack)};
  font-size: 1.2vw;
  color: ${whiteSphera};
  margin-left: 80%;
  border: none;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.disabled ? grayLightSphera : brandBlack)};
  }
`;
