import { forwardRef, useState, ChangeEvent } from 'react';
import { InputTextAreaProps } from './inputTextArea.types';
import { InputTextAreaContainer } from './inputTextArea.styles';
import { ErrorMessage } from '../InputField/ErrorMessage';

export const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>((props, ref) => {
  const {
    value,
    id,
    label,
    regex,
    errorMessages = [],
    errortype,
    wrapLabel,
    onChange,
    ...rest
  } = props;

  const [errorRegex, setErrorRegex] = useState(false);
  const inputId = id || `input-${Math.random().toString(36).substr(2, 9)}`;

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (regex && !regex.test(e.target.value)) {
      setErrorRegex(true);
    } else {
      setErrorRegex(false);
      onChange && onChange(e.target.value);
    }
  };

  return (
    <InputTextAreaContainer wrapLabel={wrapLabel}>
      {label ? <label htmlFor={inputId}>{label}</label> : null}
      <textarea value={value} onChange={handleChange} id={inputId} {...rest} ref={ref} />
      {(errorRegex || errorMessages.length > 0) && (
        <ErrorMessage errorType={errortype} errorMessages={errorMessages} />
      )}
    </InputTextAreaContainer>
  );
});

InputTextArea.displayName = 'InputTextArea';
