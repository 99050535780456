import styled from 'styled-components/macro';
import {
  supportLemonGreen,
  brandBlack,
  supportOliveGreen,
  supportDarkGreen,
  greenSp,
  grayDarkSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const AvisoContainer = styled.div`
  margin: 5% 0;
  border-radius: 5px;
  opacity: 0.8;
  padding: 1% 3%;
  & > p {
    margin: 0 3%;
    color: black;
    font-size: ${uIFontSize.paragraph};
    & > a {
      color: ${supportOliveGreen};
      font-weight: bold;
      text-decoration: underline;
      font-size: ${uIFontSize.paragraph};
    }
  }
  & > h2 {
    color: ${supportLemonGreen};
    text-align: center;
    font-weight: bold;
    font-size: ${uIFontSize.subTitle};
    margin: 3% 0;
  }
  & > h1 {
    color: ${greenSp};
    text-align: center;
    font-weight: bold;
    margin: 0 3%;
    padding: 2% 0;
    font-size: ${uIFontSize.title};
  }
  & > h3 {
    color: ${supportDarkGreen};
    margin: 0 3% 2% 3%;
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
  }
  & > ul {
    padding: 2% 5%;
    & > p {
      color: ${grayDarkSphera};
      padding: 0;
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
      margin-bottom: 2%;
    }
    & > li {
      margin: 2% 0;
      & > span {
        color: ${brandBlack};
        text-align: center;
        font-weight: bolder;
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  width: 100%;
  margin: 1% 0 0 80%;
`;
