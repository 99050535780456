import styled from 'styled-components';
import { ProgressBarProps } from './types';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ProgressBarContainer = styled.div<ProgressBarProps>`
  background: ${colors.white.pearl};
  box-shadow: inset 0px 3px 6px ${colors.black.gray}70;
  border-radius: 5px;
  position: relative;
  height: ${({ size }) => (size === 'small' ? '0.875rem' : '2.25rem')};
  width: 100%;

  .actual-progress {
    display: flex;
    background: ${({ actualPercentage }) =>
      actualPercentage !== 0 ? colors.green.default : colors.black.gray};
    border-radius: 5px;
    height: 100%;
    width: ${({ actualPercentage }) => (actualPercentage !== 0 ? `${actualPercentage}%` : '2%')};
    transition: width 1s;
    justify-content: end;
    align-items: center;

    p {
      font-size: ${({ size }) =>
        size === 'small' ? `${uIFontSize.xSmall}` : `${uIFontSize.large}`};
      color: ${colors.text.white};
      margin-right: 8px;
      z-index: 2;
    }
  }
`;
