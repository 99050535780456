import styled from 'styled-components/macro';

import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  grayPlaceholder,
  grayLightSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const CreateBeneficiaryModal = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalCreateBeneficiary ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  animation: ${(props) => (props.showModalCreateBeneficiary ? fadeIn : fadeOut)} 0.5s linear;
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
  > h2 {
    font-size: ${uIFontSize.xxLarge};
    text-align: center;
    color: ${brandBlack};
    > span {
      color: ${greenSp};
      font-size: ${uIFontSize.xxLarge};
    }
  }
  > p {
    font-size: ${uIFontSize.paragraph};
    width: 90%;
    margin: 1% 5%;
    text-align: center;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;

export const InfoContainer = styled.section`
  margin: 2% 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5% 0;
  > div {
    width: 45%;
    h3 {
      font-size: ${uIFontSize.paragraph};
      color: ${grayMediumSphera};
      margin-bottom: 5px;
      text-align: center;
      font-weight: 100;
    }
    > input {
      font-size: ${uIFontSize.paragraph};
      width: 100%;
    }
    > select {
      color: ${grayPlaceholder};
      font-size: ${uIFontSize.paragraph};
      width: 100%;
      height: 40px;
      padding-left: 1%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  > button {
    background-color: ${greenSp};
    color: ${brandBlack};
    font-weight: bold;
  }
`;
