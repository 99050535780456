import { forwardRef } from 'react';
import { ButtonProps } from './button.interface';
import { ButtonContainer } from './button.styles';
import { Loading } from './button.styles';
import { Spinner } from 'src/components/shared/loaders/Spinner/Spinner';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, isLoading, loadingText, color, ...rest } = props;

  return (
    <ButtonContainer ref={ref} isLoading={isLoading} color={color} {...rest}>
      {isLoading ? (
        <Loading>
          <Spinner height="60%" dotSize="3px" /> {loadingText && <p>{loadingText}</p>}
        </Loading>
      ) : (
        children
      )}
    </ButtonContainer>
  );
});

Button.displayName = 'Button';
