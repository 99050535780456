import styled from 'styled-components/macro';

import {
  brandBlack,
  whiteSphera,
  neutralBlue,
  neutralBlueGray,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const AddressContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > button {
    background-color: ${neutralBlue};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 90%;
    color: ${brandBlack};
    font-size: ${uIFontSize.medium};
  }
  @media (max-width: ${breakpoints.tablet}) {
    > button {
      font-size: ${uIFontSize.small};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 3%;
  > button {
    background-color: ${greenSp};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 5%;
    color: ${brandBlack};
    font-size: ${uIFontSize.medium};
  }
  @media (max-width: ${breakpoints.tablet}) {
    > button {
      font-size: ${uIFontSize.small};
    }
  }
`;

export const InfoSection = styled.div`
  display: flex;
  margin: 2.5%;
  width: 100%;
  padding-bottom: 1%;
  border-bottom: 1px solid ${greenSp};
  > div {
    width: 33%;

    > h3 {
      color: ${neutralBlueGray};
      font-size: ${uIFontSize.medium};
    }
    > p {
      color: ${neutralBlueGray};
      font-size: ${uIFontSize.medium};
      padding: 1%;
    }
    > input {
      border-radius: 5px;
      width: 80%;
      border-style: none;
      border: 1px solid ${neutralBlue};
      padding: 1% 0;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    > div {
      > h3 {
        font-size: ${uIFontSize.small};
      }
      > p {
        font-size: ${uIFontSize.small};
      }
    }
  }
`;
