/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createResourcesProvider = /* GraphQL */ `
  mutation CreateResourcesProvider(
    $input: CreateResourcesProviderInput!
    $condition: ModelResourcesProviderConditionInput
  ) {
    createResourcesProvider(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateResourcesProvider = /* GraphQL */ `
  mutation UpdateResourcesProvider(
    $input: UpdateResourcesProviderInput!
    $condition: ModelResourcesProviderConditionInput
  ) {
    updateResourcesProvider(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteResourcesProvider = /* GraphQL */ `
  mutation DeleteResourcesProvider(
    $input: DeleteResourcesProviderInput!
    $condition: ModelResourcesProviderConditionInput
  ) {
    deleteResourcesProvider(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createAnonymousMailbox = /* GraphQL */ `
  mutation CreateAnonymousMailbox(
    $input: CreateAnonymousMailboxInput!
    $condition: ModelAnonymousMailboxConditionInput
  ) {
    createAnonymousMailbox(input: $input, condition: $condition) {
      id
      userType
      description
      issueDate
      anyoneKnows
      hasEscaled
      email
      fullName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAnonymousMailbox = /* GraphQL */ `
  mutation UpdateAnonymousMailbox(
    $input: UpdateAnonymousMailboxInput!
    $condition: ModelAnonymousMailboxConditionInput
  ) {
    updateAnonymousMailbox(input: $input, condition: $condition) {
      id
      userType
      description
      issueDate
      anyoneKnows
      hasEscaled
      email
      fullName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAnonymousMailbox = /* GraphQL */ `
  mutation DeleteAnonymousMailbox(
    $input: DeleteAnonymousMailboxInput!
    $condition: ModelAnonymousMailboxConditionInput
  ) {
    deleteAnonymousMailbox(input: $input, condition: $condition) {
      id
      userType
      description
      issueDate
      anyoneKnows
      hasEscaled
      email
      fullName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBeneficiary = /* GraphQL */ `
  mutation CreateBeneficiary(
    $input: CreateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    createBeneficiary(input: $input, condition: $condition) {
      id
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      percentage
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBeneficiariesId
      owner
    }
  }
`;
export const updateBeneficiary = /* GraphQL */ `
  mutation UpdateBeneficiary(
    $input: UpdateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    updateBeneficiary(input: $input, condition: $condition) {
      id
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      percentage
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBeneficiariesId
      owner
    }
  }
`;
export const deleteBeneficiary = /* GraphQL */ `
  mutation DeleteBeneficiary(
    $input: DeleteBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    deleteBeneficiary(input: $input, condition: $condition) {
      id
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      percentage
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBeneficiariesId
      owner
    }
  }
`;
export const createInvestor = /* GraphQL */ `
  mutation CreateInvestor($input: CreateInvestorInput!, $condition: ModelInvestorConditionInput) {
    createInvestor(input: $input, condition: $condition) {
      id
      userId
      typeOfPerson
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBeneficiariesId
          owner
        }
        nextToken
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      risk {
        investorId
        loss
        liquidity
        information
        performance
        notApproved
        notAdvice
        investment
        disclosedInformation
        isSigned
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      investments {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      createdAt
      updatedAt
      isInNewsletter
      isInMarketing
      isPLDApproved
      isSIMAFSended
      economicActivities {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      isRisksSigned
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateInvestor = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      userId
      typeOfPerson
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBeneficiariesId
          owner
        }
        nextToken
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      risk {
        investorId
        loss
        liquidity
        information
        performance
        notApproved
        notAdvice
        investment
        disclosedInformation
        isSigned
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      investments {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      createdAt
      updatedAt
      isInNewsletter
      isInMarketing
      isPLDApproved
      isSIMAFSended
      economicActivities {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      isRisksSigned
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteInvestor = /* GraphQL */ `
  mutation DeleteInvestor($input: DeleteInvestorInput!, $condition: ModelInvestorConditionInput) {
    deleteInvestor(input: $input, condition: $condition) {
      id
      userId
      typeOfPerson
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBeneficiariesId
          owner
        }
        nextToken
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      risk {
        investorId
        loss
        liquidity
        information
        performance
        notApproved
        notAdvice
        investment
        disclosedInformation
        isSigned
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      investments {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      createdAt
      updatedAt
      isInNewsletter
      isInMarketing
      isPLDApproved
      isSIMAFSended
      economicActivities {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      isRisksSigned
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createObligadoSolidario = /* GraphQL */ `
  mutation CreateObligadoSolidario(
    $input: CreateObligadoSolidarioInput!
    $condition: ModelObligadoSolidarioConditionInput
  ) {
    createObligadoSolidario(input: $input, condition: $condition) {
      id
      applicantId
      firstName
      lastNameM
      lastNameP
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      percentage
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      applicantObligadoSolidarioId
      owner
    }
  }
`;
export const updateObligadoSolidario = /* GraphQL */ `
  mutation UpdateObligadoSolidario(
    $input: UpdateObligadoSolidarioInput!
    $condition: ModelObligadoSolidarioConditionInput
  ) {
    updateObligadoSolidario(input: $input, condition: $condition) {
      id
      applicantId
      firstName
      lastNameM
      lastNameP
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      percentage
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      applicantObligadoSolidarioId
      owner
    }
  }
`;
export const deleteObligadoSolidario = /* GraphQL */ `
  mutation DeleteObligadoSolidario(
    $input: DeleteObligadoSolidarioInput!
    $condition: ModelObligadoSolidarioConditionInput
  ) {
    deleteObligadoSolidario(input: $input, condition: $condition) {
      id
      applicantId
      firstName
      lastNameM
      lastNameP
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      percentage
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      applicantObligadoSolidarioId
      owner
    }
  }
`;
export const createInvestment = /* GraphQL */ `
  mutation CreateInvestment(
    $input: CreateInvestmentInput!
    $condition: ModelInvestmentConditionInput
  ) {
    createInvestment(input: $input, condition: $condition) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      status
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      porcentajeGananciaInteres
      porcentajeGananciaInteresFinal
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;
export const updateInvestment = /* GraphQL */ `
  mutation UpdateInvestment(
    $input: UpdateInvestmentInput!
    $condition: ModelInvestmentConditionInput
  ) {
    updateInvestment(input: $input, condition: $condition) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      status
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      porcentajeGananciaInteres
      porcentajeGananciaInteresFinal
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;
export const deleteInvestment = /* GraphQL */ `
  mutation DeleteInvestment(
    $input: DeleteInvestmentInput!
    $condition: ModelInvestmentConditionInput
  ) {
    deleteInvestment(input: $input, condition: $condition) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      status
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      porcentajeGananciaInteres
      porcentajeGananciaInteresFinal
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;
export const createApplicant = /* GraphQL */ `
  mutation CreateApplicant(
    $input: CreateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    createApplicant(input: $input, condition: $condition) {
      id
      userId
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      interestRateFinsphera
      defaultInterestRateInvestor
      interestRateInvestor
      score
      paymentScore
      delayDays
      isApproved
      isBureauConsultAuthorized
      reasonOfRejection
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      isInNewsletter
      isPLDApproved
      isInMarketing
      isSIMAFSended
      requestedAmount
      termLoan
      loanDestination
      createdAt
      updatedAt
      creditBureau {
        applicantId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      creditAnalysis {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      economicActivity {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      campaigns {
        items {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      distributionChannel
      industryActivity
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      obligadoSolidario {
        items {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        nextToken
      }
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateApplicant = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      userId
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      interestRateFinsphera
      defaultInterestRateInvestor
      interestRateInvestor
      score
      paymentScore
      delayDays
      isApproved
      isBureauConsultAuthorized
      reasonOfRejection
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      isInNewsletter
      isPLDApproved
      isInMarketing
      isSIMAFSended
      requestedAmount
      termLoan
      loanDestination
      createdAt
      updatedAt
      creditBureau {
        applicantId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      creditAnalysis {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      economicActivity {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      campaigns {
        items {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      distributionChannel
      industryActivity
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      obligadoSolidario {
        items {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        nextToken
      }
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteApplicant = /* GraphQL */ `
  mutation DeleteApplicant(
    $input: DeleteApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    deleteApplicant(input: $input, condition: $condition) {
      id
      userId
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      interestRateFinsphera
      defaultInterestRateInvestor
      interestRateInvestor
      score
      paymentScore
      delayDays
      isApproved
      isBureauConsultAuthorized
      reasonOfRejection
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      isInNewsletter
      isPLDApproved
      isInMarketing
      isSIMAFSended
      requestedAmount
      termLoan
      loanDestination
      createdAt
      updatedAt
      creditBureau {
        applicantId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      creditAnalysis {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      economicActivity {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      campaigns {
        items {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      distributionChannel
      industryActivity
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      obligadoSolidario {
        items {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        nextToken
      }
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createBank = /* GraphQL */ `
  mutation CreateBank($input: CreateBankInput!, $condition: ModelBankConditionInput) {
    createBank(input: $input, condition: $condition) {
      CLABE
      applicantId
      investorId
      isMFA
      identifier
      isMainAccount
      messageId
      bankName
      bankCode
      status
      numberOfCard
      account
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBanksId
      applicantBanksId
      owner
    }
  }
`;
export const updateBank = /* GraphQL */ `
  mutation UpdateBank($input: UpdateBankInput!, $condition: ModelBankConditionInput) {
    updateBank(input: $input, condition: $condition) {
      CLABE
      applicantId
      investorId
      isMFA
      identifier
      isMainAccount
      messageId
      bankName
      bankCode
      status
      numberOfCard
      account
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBanksId
      applicantBanksId
      owner
    }
  }
`;
export const deleteBank = /* GraphQL */ `
  mutation DeleteBank($input: DeleteBankInput!, $condition: ModelBankConditionInput) {
    deleteBank(input: $input, condition: $condition) {
      CLABE
      applicantId
      investorId
      isMFA
      identifier
      isMainAccount
      messageId
      bankName
      bankCode
      status
      numberOfCard
      account
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBanksId
      applicantBanksId
      owner
    }
  }
`;
export const createAdmin = /* GraphQL */ `
  mutation CreateAdmin($input: CreateAdminInput!, $condition: ModelAdminConditionInput) {
    createAdmin(input: $input, condition: $condition) {
      id
      email
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAdmin = /* GraphQL */ `
  mutation UpdateAdmin($input: UpdateAdminInput!, $condition: ModelAdminConditionInput) {
    updateAdmin(input: $input, condition: $condition) {
      id
      email
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAdmin = /* GraphQL */ `
  mutation DeleteAdmin($input: DeleteAdminInput!, $condition: ModelAdminConditionInput) {
    deleteAdmin(input: $input, condition: $condition) {
      id
      email
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!, $condition: ModelCampaignConditionInput) {
    createCampaign(input: $input, condition: $condition) {
      id
      applicantId
      nombreCampania
      montoSolicitado
      valueOfInvoices
      collectedAmount
      isActive
      isExpired
      isSuccessful
      isPromissoryNoteSigned
      dateStartCampaign
      dateEndCampaign
      signLimitDate
      signDate
      plazoCredito
      paymentDate
      areInvoicesUsed
      isMoneySent
      termCampaing
      status
      destinoCredito
      fuentePago
      investment {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      TIIE
      tasaInteresAdicionalPorcentaje
      tasaCreditoPorcentaje
      interesCreditoPesos
      IVAinteresCreditoPesos
      interesesCreditoPesosConIVA
      interesMoratorioPesos
      IVAinteresMoratorio
      interesesMoratorioPesosConIVA
      comisionCreditoPesos
      IVAcomisionCreditoPesos
      comisionCreditoPesosConIVA
      inversionFinspheraCampaniaPorcentaje
      inversionFinspheraCampaniaPesos
      tasaInteresMoratorioPorcentaje
      montoTransferencia
      plazoCreditoFinal
      paymentDateFinal
      interesCreditoPesosFinal
      IVAinteresCreditoPesosFinal
      interesesCreditoPesosConIVAFinal
      comisionCreditoPesosFinal
      IVAcomisionCreditoPesosFinal
      comisionCreditoPesosConIVAFinal
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      applicantCampaignsId
      owner
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!, $condition: ModelCampaignConditionInput) {
    updateCampaign(input: $input, condition: $condition) {
      id
      applicantId
      nombreCampania
      montoSolicitado
      valueOfInvoices
      collectedAmount
      isActive
      isExpired
      isSuccessful
      isPromissoryNoteSigned
      dateStartCampaign
      dateEndCampaign
      signLimitDate
      signDate
      plazoCredito
      paymentDate
      areInvoicesUsed
      isMoneySent
      termCampaing
      status
      destinoCredito
      fuentePago
      investment {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      TIIE
      tasaInteresAdicionalPorcentaje
      tasaCreditoPorcentaje
      interesCreditoPesos
      IVAinteresCreditoPesos
      interesesCreditoPesosConIVA
      interesMoratorioPesos
      IVAinteresMoratorio
      interesesMoratorioPesosConIVA
      comisionCreditoPesos
      IVAcomisionCreditoPesos
      comisionCreditoPesosConIVA
      inversionFinspheraCampaniaPorcentaje
      inversionFinspheraCampaniaPesos
      tasaInteresMoratorioPorcentaje
      montoTransferencia
      plazoCreditoFinal
      paymentDateFinal
      interesCreditoPesosFinal
      IVAinteresCreditoPesosFinal
      interesesCreditoPesosConIVAFinal
      comisionCreditoPesosFinal
      IVAcomisionCreditoPesosFinal
      comisionCreditoPesosConIVAFinal
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      applicantCampaignsId
      owner
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($input: DeleteCampaignInput!, $condition: ModelCampaignConditionInput) {
    deleteCampaign(input: $input, condition: $condition) {
      id
      applicantId
      nombreCampania
      montoSolicitado
      valueOfInvoices
      collectedAmount
      isActive
      isExpired
      isSuccessful
      isPromissoryNoteSigned
      dateStartCampaign
      dateEndCampaign
      signLimitDate
      signDate
      plazoCredito
      paymentDate
      areInvoicesUsed
      isMoneySent
      termCampaing
      status
      destinoCredito
      fuentePago
      investment {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      TIIE
      tasaInteresAdicionalPorcentaje
      tasaCreditoPorcentaje
      interesCreditoPesos
      IVAinteresCreditoPesos
      interesesCreditoPesosConIVA
      interesMoratorioPesos
      IVAinteresMoratorio
      interesesMoratorioPesosConIVA
      comisionCreditoPesos
      IVAcomisionCreditoPesos
      comisionCreditoPesosConIVA
      inversionFinspheraCampaniaPorcentaje
      inversionFinspheraCampaniaPesos
      tasaInteresMoratorioPorcentaje
      montoTransferencia
      plazoCreditoFinal
      paymentDateFinal
      interesCreditoPesosFinal
      IVAinteresCreditoPesosFinal
      interesesCreditoPesosConIVAFinal
      comisionCreditoPesosFinal
      IVAcomisionCreditoPesosFinal
      comisionCreditoPesosConIVAFinal
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      applicantCampaignsId
      owner
    }
  }
`;
export const createEfisysCampaign = /* GraphQL */ `
  mutation CreateEfisysCampaign(
    $input: CreateEfisysCampaignInput!
    $condition: ModelEfisysCampaignConditionInput
  ) {
    createEfisysCampaign(input: $input, condition: $condition) {
      creditRequestNumber
      applicantId
      campaignId
      creditRequest {
        test
      }
      investmentRequest {
        test
      }
      cancelInvestment {
        test
      }
      creditNumber
      status
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      deletedAt
      owner
    }
  }
`;
export const updateEfisysCampaign = /* GraphQL */ `
  mutation UpdateEfisysCampaign(
    $input: UpdateEfisysCampaignInput!
    $condition: ModelEfisysCampaignConditionInput
  ) {
    updateEfisysCampaign(input: $input, condition: $condition) {
      creditRequestNumber
      applicantId
      campaignId
      creditRequest {
        test
      }
      investmentRequest {
        test
      }
      cancelInvestment {
        test
      }
      creditNumber
      status
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      deletedAt
      owner
    }
  }
`;
export const deleteEfisysCampaign = /* GraphQL */ `
  mutation DeleteEfisysCampaign(
    $input: DeleteEfisysCampaignInput!
    $condition: ModelEfisysCampaignConditionInput
  ) {
    deleteEfisysCampaign(input: $input, condition: $condition) {
      creditRequestNumber
      applicantId
      campaignId
      creditRequest {
        test
      }
      investmentRequest {
        test
      }
      cancelInvestment {
        test
      }
      creditNumber
      status
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      deletedAt
      owner
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice($input: CreateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
    createInvoice(input: $input, condition: $condition) {
      id
      applicantId
      campaignId
      comprobante {
        lugarExpedicion
        metodoPago
        tipoDeComprobante
        total
        moneda
        subTotal
        condicionesDePago
        noCertificado
        formaPago
        sello
        fecha
        folio
        version
        emisor {
          RFC
          nombre
          regimenFiscal
        }
        receptor {
          RFC
          nombre
          usoCFDI
        }
        conceptos {
          concepto {
            claveProdServ
            cantidad
            claveUnidad
            unidad
            descripcion
            valorUnitario
            importe
          }
        }
        impuestos {
          totalImpuestosTrasladados
          impuestoTraslado {
            impuesto
            tipoFactor
            tasaOCuota
            importe
          }
        }
        complemento {
          timbreFiscalDigital {
            version
            UUID
            fechaTimbrado
            RFCProvCertif
            selloCFD
            noCertificadoSAT
            selloSAT
            folioFiscal
          }
        }
      }
      number
      name
      type
      isVerified
      isApproved
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      isAvailable
      applicantInvoicesId
      owner
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice($input: UpdateInvoiceInput!, $condition: ModelInvoiceConditionInput) {
    updateInvoice(input: $input, condition: $condition) {
      id
      applicantId
      campaignId
      comprobante {
        lugarExpedicion
        metodoPago
        tipoDeComprobante
        total
        moneda
        subTotal
        condicionesDePago
        noCertificado
        formaPago
        sello
        fecha
        folio
        version
        emisor {
          RFC
          nombre
          regimenFiscal
        }
        receptor {
          RFC
          nombre
          usoCFDI
        }
        conceptos {
          concepto {
            claveProdServ
            cantidad
            claveUnidad
            unidad
            descripcion
            valorUnitario
            importe
          }
        }
        impuestos {
          totalImpuestosTrasladados
          impuestoTraslado {
            impuesto
            tipoFactor
            tasaOCuota
            importe
          }
        }
        complemento {
          timbreFiscalDigital {
            version
            UUID
            fechaTimbrado
            RFCProvCertif
            selloCFD
            noCertificadoSAT
            selloSAT
            folioFiscal
          }
        }
      }
      number
      name
      type
      isVerified
      isApproved
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      isAvailable
      applicantInvoicesId
      owner
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice($input: DeleteInvoiceInput!, $condition: ModelInvoiceConditionInput) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      applicantId
      campaignId
      comprobante {
        lugarExpedicion
        metodoPago
        tipoDeComprobante
        total
        moneda
        subTotal
        condicionesDePago
        noCertificado
        formaPago
        sello
        fecha
        folio
        version
        emisor {
          RFC
          nombre
          regimenFiscal
        }
        receptor {
          RFC
          nombre
          usoCFDI
        }
        conceptos {
          concepto {
            claveProdServ
            cantidad
            claveUnidad
            unidad
            descripcion
            valorUnitario
            importe
          }
        }
        impuestos {
          totalImpuestosTrasladados
          impuestoTraslado {
            impuesto
            tipoFactor
            tasaOCuota
            importe
          }
        }
        complemento {
          timbreFiscalDigital {
            version
            UUID
            fechaTimbrado
            RFCProvCertif
            selloCFD
            noCertificadoSAT
            selloSAT
            folioFiscal
          }
        }
      }
      number
      name
      type
      isVerified
      isApproved
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      isAvailable
      applicantInvoicesId
      owner
    }
  }
`;
export const createEconomicActivity = /* GraphQL */ `
  mutation CreateEconomicActivity(
    $input: CreateEconomicActivityInput!
    $condition: ModelEconomicActivityConditionInput
  ) {
    createEconomicActivity(input: $input, condition: $condition) {
      id
      economicActivity
      specificActivity
      importToMexico
      exportFromMexico
      useCoinedMetals
      relationsWithCountries
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEconomicActivity = /* GraphQL */ `
  mutation UpdateEconomicActivity(
    $input: UpdateEconomicActivityInput!
    $condition: ModelEconomicActivityConditionInput
  ) {
    updateEconomicActivity(input: $input, condition: $condition) {
      id
      economicActivity
      specificActivity
      importToMexico
      exportFromMexico
      useCoinedMetals
      relationsWithCountries
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEconomicActivity = /* GraphQL */ `
  mutation DeleteEconomicActivity(
    $input: DeleteEconomicActivityInput!
    $condition: ModelEconomicActivityConditionInput
  ) {
    deleteEconomicActivity(input: $input, condition: $condition) {
      id
      economicActivity
      specificActivity
      importToMexico
      exportFromMexico
      useCoinedMetals
      relationsWithCountries
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCreditAnalysis = /* GraphQL */ `
  mutation CreateCreditAnalysis(
    $input: CreateCreditAnalysisInput!
    $condition: ModelCreditAnalysisConditionInput
  ) {
    createCreditAnalysis(input: $input, condition: $condition) {
      applicantId
      profitability {
        profitabilityValue
        profitabilityPoints
        compoundAnnualGrowthInSalesValue
        compoundAnnualGrowthInSalesPoints
        compoundAnnualGrowthInNetEarningsValue
        compoundAnnualGrowthInNetEarningsPoints
        rating
        isApproved
      }
      liquidity {
        creditLine
        creditBalance
        borrowingCapacityValue
        borrowingCapacityPoints
        liquidityRatioValue
        liquidityRatioPoints
        acidTestValue
        acidTestPoints
        immediatePaymentAbilityValue
        immediatePaymentAbilityPoints
        maximumPaymentTermValue
        maximumPaymentTermPoints
        rating
        isApproved
      }
      solvency {
        taxComplianceChecksValue
        taxComplianceChecksPoints
        taxRegimeValue
        taxRegimePoints
        allClients
        majorCorporateClients
        majorCorporateClientsValue
        majorCorporateClientsPoints
        annualGrowthInClientsValue
        annualGrowthInClientsPoints
        salesConcentrationValue
        salesConcentrationPoints
        ROEValue
        ROEPoints
        ROAValue
        ROAPoints
        rating
        isApproved
      }
      creditAccess {
        financialInstitutionGrantsCreditValue
        financialInstitutionGrantsCreditPoints
        creditBureauRatingValue
        creditBureauRatingPoints
        paymentHistoryValue
        paymentHistoryPoints
        numberOfCreditGrantorsValue
        numberOfCreditGrantorsPoints
        inquiriesInCreditBureauValue
        inquiriesInCreditBureauPoints
        rating
        isApproved
      }
      marketAndProduct {
        dayOfBirth
        businessAgeValue
        businessAgePoints
        mainActivityValue
        mainActivityPoints
        statusInTaxpayerRegistryValue
        statusInTaxpayerRegistryPoints
        capitalRegimeValue
        capitalRegimePoints
        federalEntityValue
        federalEntityPoints
        rating
        isApproved
      }
      corporateGovernance {
        experienceOfMainShareholderValue
        experienceOfMainShareholderPoints
        independetConselorsValue
        independetConselorsPoints
        independenceDirectorGeneralValue
        independenceDirectorGeneralPoints
        numberOfCommitteesValue
        numberOfCommitteesPoints
        rating
        isApproved
      }
      genderEquality {
        numberFemaleShareholdersValue
        numberFemaleShareholdersPoints
        numberFemaleDirectorsOnBoardValue
        numberFemaleDirectorsOnBoardPoints
        isCEOFemaleValue
        isCEOFemalePoints
        numberFemaleWithManagerialPositionValue
        numberFemaleWithManagerialPositionPoints
        numberFemaleEmployeesValue
        numberFemaleEmployeesPoints
        rating
        isApproved
      }
      environmentalAndSocialImpact {
        finPobreza {
          erradicarPobrezaExtrema {
            value
            description
          }
          reduccionPobrezaRelativa {
            value
            description
          }
          implantacionProteccionSocial {
            value
            description
          }
          garantiaAccesoServicioBasico {
            value
            description
          }
          resilienciaDesastresAmbientales {
            value
            description
          }
          creacionMarcosNormativos {
            value
            description
          }
          isApproved
        }
        hambreCero {
          finHambre {
            value
            description
          }
          finMalnutricion {
            value
            description
          }
          duplicarProductividadIngresosPequeniaEscala {
            value
            description
          }
          practicasAgricolasSosteniblesResilientes {
            value
            description
          }
          mantenimientoDiversidadGeneticaSemilla {
            value
            description
          }
          aumentoInversionAgricola {
            value
            description
          }
          estabilidadMercadosAgropecuarios {
            value
            description
          }
          controlVolatilidadPreciosAlimentos {
            value
            description
          }
          isApproved
        }
        saludBienestar {
          reduccionTasaMortalidadMaterna {
            value
            description
          }
          eliminarMortalidadInfantil {
            value
            description
          }
          finEnfermedadesTransmisibles {
            value
            description
          }
          reduccionEnfermedadesNoTransmisiblesYSaludMental {
            value
            description
          }
          prevencionTratamientoAbusosDrogasAlcohol {
            value
            description
          }
          controlTabaco {
            value
            description
          }
          apoyoIDVacunasMedicamentosEsenciales {
            value
            description
          }
          aumentoFinanciacionSistemaSanitario {
            value
            description
          }
          refuerzoGestionRiesgosSanitarios {
            value
            description
          }
          isApproved
        }
        educacionCalidad {
          asegurarCalidadPrimariaSecundaria {
            value
            description
          }
          asegurarAccesoCalidadEducacionPreescolar {
            value
            description
          }
          asegurarAccesoIgualitarioFormacionSuperior {
            value
            description
          }
          aumentoCompetenciasAccederEmpleo {
            value
            description
          }
          eliminacionDisparidadGenero {
            value
            description
          }
          asegurarAlfabetizacion {
            value
            description
          }
          fomentarEducacionGlobal {
            value
            description
          }
          mejoraInstalacionesEducativas {
            value
            description
          }
          aumentoBecas {
            value
            description
          }
          mejorarDocentes {
            value
            description
          }
          isApproved
        }
        igualdadGenero {
          eliminarViolenciaGenero {
            value
            description
          }
          eliminarMatrimonioInfantil {
            value
            description
          }
          reconocerTrabajoDomestico {
            value
            description
          }
          asegurarParticipacionMujer {
            value
            description
          }
          asegurarAccesoSaludSexual {
            value
            description
          }
          asegurarIgualdadDerechos {
            value
            description
          }
          mejorarUsoTecnologia {
            value
            description
          }
          aprobarPoliticasIgualdad {
            value
            description
          }
          isApproved
        }
        aguaLimpia {
          accesoAguaPotable {
            value
            description
          }
          accesoSanamiento {
            value
            description
          }
          mejorarCalidadAgua {
            value
            description
          }
          usoEficienteRecursosHidricos {
            value
            description
          }
          gestionRecursosHidricos {
            value
            description
          }
          proteccionEcosistemas {
            value
            description
          }
          creacionCapacidadGestion {
            value
            description
          }
          apoyarComunidadesLocales {
            value
            description
          }
          isApproved
        }
        energiaAsequible {
          accesoUniversalEnergia {
            value
            description
          }
          aumentoEnergiasRenovables {
            value
            description
          }
          duplicarEficienciaEnergetica {
            value
            description
          }
          aumentoInvestigacionInversion {
            value
            description
          }
          ampliarInfraestructuraYTecnologia {
            value
            description
          }
          isApproved
        }
        trabajoDecente {
          mantenimientoCrecimientoEconomico {
            value
            description
          }
          elevarProductividad {
            value
            description
          }
          fomentoPequeniaMedianaEmpresa {
            value
            description
          }
          mejorarProduccionYConsumo {
            value
            description
          }
          lograrPlenoEmpleo {
            value
            description
          }
          reduccionJovenesSinTrabajo {
            value
            description
          }
          erradicarEsclavitud {
            value
            description
          }
          proteccionDerechosLaborales {
            value
            description
          }
          promocionTurismo {
            value
            description
          }
          fortalecimientoInstitucionesFinancieras {
            value
            description
          }
          aumentoAyudaComercio {
            value
            description
          }
          desarrolloEmpleoJuvenil {
            value
            description
          }
          isApproved
        }
        industriaInnovacion {
          desarrolloInfraestructura {
            value
            description
          }
          promocionIndustriaInclusiva {
            value
            description
          }
          aumentoAccesoPymesServiciosFinancieros {
            value
            description
          }
          modernizacionInfraestructura {
            value
            description
          }
          aumentoInvestigacionCientifica {
            value
            description
          }
          apoyoInfraestructuraSostenible {
            value
            description
          }
          desarrolloTecnologia {
            value
            description
          }
          aumentoAccesoTecnologias {
            value
            description
          }
          isApproved
        }
        reduccionDesigualdades {
          crecimientosIngresos {
            value
            description
          }
          promocionInclusion {
            value
            description
          }
          igualdadOportinidades {
            value
            description
          }
          adopcionPoliticasFiscales {
            value
            description
          }
          mejorarRegulacionMercados {
            value
            description
          }
          participacionPaisesDesarrollo {
            value
            description
          }
          facilitarMigracion {
            value
            description
          }
          aplicacionTratoEspecial {
            value
            description
          }
          fomentarCorrientesFinancieras {
            value
            description
          }
          reduccionCostesRemesas {
            value
            description
          }
          isApproved
        }
        ciudadesComunidades {
          accesoVivienda {
            value
            description
          }
          accesoTransportePublico {
            value
            description
          }
          aumentoUrbanizacion {
            value
            description
          }
          proteccionPatrimonioCultural {
            value
            description
          }
          reduccionNumeroMuertes {
            value
            description
          }
          reduccionImpactoAmbiental {
            value
            description
          }
          accesoZonasVerdes {
            value
            description
          }
          apoyoZonasUrbanas {
            value
            description
          }
          reduccionRiesgos {
            value
            description
          }
          apoyoConstruccionEdificosSostenibles {
            value
            description
          }
          isApproved
        }
        produccionConsumo {
          aplicacionMarcoConsumo {
            value
            description
          }
          usoEficienteRecursosNaturales {
            value
            description
          }
          reduccionDesperdicioAlimentos {
            value
            description
          }
          gestionDeshechos {
            value
            description
          }
          prevencionReduccionReciclado {
            value
            description
          }
          adopcionPracticasSostenibles {
            value
            description
          }
          adquisicionPublicasSostenibles {
            value
            description
          }
          asegurarEducacionDesarrolloSostenible {
            value
            description
          }
          fortalecimientoCienciaTecnologia {
            value
            description
          }
          turismoSostenible {
            value
            description
          }
          regularSubsidios {
            value
            description
          }
          isApproved
        }
        accionClima {
          fortalecerResilencia {
            value
            description
          }
          incorporarCambioClimaticoPoliticas {
            value
            description
          }
          mejoraEducacion {
            value
            description
          }
          movilizacionRecursosEconomicos {
            value
            description
          }
          gestionCambioClimatico {
            value
            description
          }
          isApproved
        }
        vidaSubmarina {
          prevencionContaminacionMarina {
            value
            description
          }
          gestionEcosistemasMarinosCosteros {
            value
            description
          }
          minimizarAcidificacionOceanos {
            value
            description
          }
          regularExplotacionPesqueraSostenible {
            value
            description
          }
          conservarZonasCosterasYMarinas {
            value
            description
          }
          combatirPescaIlegal {
            value
            description
          }
          aumentoBeneficiosEconomicosPescaSostenible {
            value
            description
          }
          apoyoInvestigacionMarina {
            value
            description
          }
          fomentoPescaPequeniaEscala {
            value
            description
          }
          aplicacionConvencionNNUU {
            value
            description
          }
          isApproved
        }
        vidaEcosistema {
          conservacionEcosistemas {
            value
            description
          }
          gestionSostenibleBosques {
            value
            description
          }
          luchaContraDesertificacion {
            value
            description
          }
          conservarEcosistemasMontaniosos {
            value
            description
          }
          degradacionPerdidaBiodiversidad {
            value
            description
          }
          usoAdecuadoRecursosGeneticos {
            value
            description
          }
          combatirCazaFurtiva {
            value
            description
          }
          prevencionEspeciesInvasoras {
            value
            description
          }
          integracionPlanesMedioAmbiente {
            value
            description
          }
          movilizacionAumentoRecursosFinancieros {
            value
            description
          }
          aumentoRecursosGestionForestal {
            value
            description
          }
          luchaCazaFurtiva {
            value
            description
          }
          isApproved
        }
        pazJusticiaInstituciones {
          reduccionFormasViolencia {
            value
            description
          }
          erradicacionMaltrato {
            value
            description
          }
          promocionEstadoDerecho {
            value
            description
          }
          reduccionCorrientesFinancieras {
            value
            description
          }
          reduccionCorrupcion {
            value
            description
          }
          creacionInstitucionesEficaces {
            value
            description
          }
          fomentoParticipacionCiudadana {
            value
            description
          }
          fortalecerPaisesEnDesarrollo {
            value
            description
          }
          proporcionIdentidadJuridica {
            value
            description
          }
          accesoInformacion {
            value
            description
          }
          fortalecimientoInstituciones {
            value
            description
          }
          promocionAplicacionLeyes {
            value
            description
          }
          isApproved
        }
        alianzaOds {
          movilizacionRecursosDomesticos {
            value
            description
          }
          cumplirAyudaDesarrollo {
            value
            description
          }
          movilizarRecursosFinancieros {
            value
            description
          }
          reestructurarDeuda {
            value
            description
          }
          promocionInversion {
            value
            description
          }
          mejorarTraspasoTecnologia {
            value
            description
          }
          promoverTecnologiaEcologicas {
            value
            description
          }
          crearBancoTecnologia {
            value
            description
          }
          refuerzoCapacidades {
            value
            description
          }
          promocionComercio {
            value
            description
          }
          aumentoExportaciones {
            value
            description
          }
          accesoMercados {
            value
            description
          }
          aumentoEstabilidad {
            value
            description
          }
          mejoraCoherencia {
            value
            description
          }
          respetoSoberania {
            value
            description
          }
          mejorarAlianzaMundial {
            value
            description
          }
          fomentoAlianzas {
            value
            description
          }
          creacionCapacidadEstadistica {
            value
            description
          }
          promoverIndicadores {
            value
            description
          }
          isApproved
        }
        rating
        isApproved
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      isApproved
      rating
      interestRateApplicant
      hasAnotherCredit
      owner
    }
  }
`;
export const updateCreditAnalysis = /* GraphQL */ `
  mutation UpdateCreditAnalysis(
    $input: UpdateCreditAnalysisInput!
    $condition: ModelCreditAnalysisConditionInput
  ) {
    updateCreditAnalysis(input: $input, condition: $condition) {
      applicantId
      profitability {
        profitabilityValue
        profitabilityPoints
        compoundAnnualGrowthInSalesValue
        compoundAnnualGrowthInSalesPoints
        compoundAnnualGrowthInNetEarningsValue
        compoundAnnualGrowthInNetEarningsPoints
        rating
        isApproved
      }
      liquidity {
        creditLine
        creditBalance
        borrowingCapacityValue
        borrowingCapacityPoints
        liquidityRatioValue
        liquidityRatioPoints
        acidTestValue
        acidTestPoints
        immediatePaymentAbilityValue
        immediatePaymentAbilityPoints
        maximumPaymentTermValue
        maximumPaymentTermPoints
        rating
        isApproved
      }
      solvency {
        taxComplianceChecksValue
        taxComplianceChecksPoints
        taxRegimeValue
        taxRegimePoints
        allClients
        majorCorporateClients
        majorCorporateClientsValue
        majorCorporateClientsPoints
        annualGrowthInClientsValue
        annualGrowthInClientsPoints
        salesConcentrationValue
        salesConcentrationPoints
        ROEValue
        ROEPoints
        ROAValue
        ROAPoints
        rating
        isApproved
      }
      creditAccess {
        financialInstitutionGrantsCreditValue
        financialInstitutionGrantsCreditPoints
        creditBureauRatingValue
        creditBureauRatingPoints
        paymentHistoryValue
        paymentHistoryPoints
        numberOfCreditGrantorsValue
        numberOfCreditGrantorsPoints
        inquiriesInCreditBureauValue
        inquiriesInCreditBureauPoints
        rating
        isApproved
      }
      marketAndProduct {
        dayOfBirth
        businessAgeValue
        businessAgePoints
        mainActivityValue
        mainActivityPoints
        statusInTaxpayerRegistryValue
        statusInTaxpayerRegistryPoints
        capitalRegimeValue
        capitalRegimePoints
        federalEntityValue
        federalEntityPoints
        rating
        isApproved
      }
      corporateGovernance {
        experienceOfMainShareholderValue
        experienceOfMainShareholderPoints
        independetConselorsValue
        independetConselorsPoints
        independenceDirectorGeneralValue
        independenceDirectorGeneralPoints
        numberOfCommitteesValue
        numberOfCommitteesPoints
        rating
        isApproved
      }
      genderEquality {
        numberFemaleShareholdersValue
        numberFemaleShareholdersPoints
        numberFemaleDirectorsOnBoardValue
        numberFemaleDirectorsOnBoardPoints
        isCEOFemaleValue
        isCEOFemalePoints
        numberFemaleWithManagerialPositionValue
        numberFemaleWithManagerialPositionPoints
        numberFemaleEmployeesValue
        numberFemaleEmployeesPoints
        rating
        isApproved
      }
      environmentalAndSocialImpact {
        finPobreza {
          erradicarPobrezaExtrema {
            value
            description
          }
          reduccionPobrezaRelativa {
            value
            description
          }
          implantacionProteccionSocial {
            value
            description
          }
          garantiaAccesoServicioBasico {
            value
            description
          }
          resilienciaDesastresAmbientales {
            value
            description
          }
          creacionMarcosNormativos {
            value
            description
          }
          isApproved
        }
        hambreCero {
          finHambre {
            value
            description
          }
          finMalnutricion {
            value
            description
          }
          duplicarProductividadIngresosPequeniaEscala {
            value
            description
          }
          practicasAgricolasSosteniblesResilientes {
            value
            description
          }
          mantenimientoDiversidadGeneticaSemilla {
            value
            description
          }
          aumentoInversionAgricola {
            value
            description
          }
          estabilidadMercadosAgropecuarios {
            value
            description
          }
          controlVolatilidadPreciosAlimentos {
            value
            description
          }
          isApproved
        }
        saludBienestar {
          reduccionTasaMortalidadMaterna {
            value
            description
          }
          eliminarMortalidadInfantil {
            value
            description
          }
          finEnfermedadesTransmisibles {
            value
            description
          }
          reduccionEnfermedadesNoTransmisiblesYSaludMental {
            value
            description
          }
          prevencionTratamientoAbusosDrogasAlcohol {
            value
            description
          }
          controlTabaco {
            value
            description
          }
          apoyoIDVacunasMedicamentosEsenciales {
            value
            description
          }
          aumentoFinanciacionSistemaSanitario {
            value
            description
          }
          refuerzoGestionRiesgosSanitarios {
            value
            description
          }
          isApproved
        }
        educacionCalidad {
          asegurarCalidadPrimariaSecundaria {
            value
            description
          }
          asegurarAccesoCalidadEducacionPreescolar {
            value
            description
          }
          asegurarAccesoIgualitarioFormacionSuperior {
            value
            description
          }
          aumentoCompetenciasAccederEmpleo {
            value
            description
          }
          eliminacionDisparidadGenero {
            value
            description
          }
          asegurarAlfabetizacion {
            value
            description
          }
          fomentarEducacionGlobal {
            value
            description
          }
          mejoraInstalacionesEducativas {
            value
            description
          }
          aumentoBecas {
            value
            description
          }
          mejorarDocentes {
            value
            description
          }
          isApproved
        }
        igualdadGenero {
          eliminarViolenciaGenero {
            value
            description
          }
          eliminarMatrimonioInfantil {
            value
            description
          }
          reconocerTrabajoDomestico {
            value
            description
          }
          asegurarParticipacionMujer {
            value
            description
          }
          asegurarAccesoSaludSexual {
            value
            description
          }
          asegurarIgualdadDerechos {
            value
            description
          }
          mejorarUsoTecnologia {
            value
            description
          }
          aprobarPoliticasIgualdad {
            value
            description
          }
          isApproved
        }
        aguaLimpia {
          accesoAguaPotable {
            value
            description
          }
          accesoSanamiento {
            value
            description
          }
          mejorarCalidadAgua {
            value
            description
          }
          usoEficienteRecursosHidricos {
            value
            description
          }
          gestionRecursosHidricos {
            value
            description
          }
          proteccionEcosistemas {
            value
            description
          }
          creacionCapacidadGestion {
            value
            description
          }
          apoyarComunidadesLocales {
            value
            description
          }
          isApproved
        }
        energiaAsequible {
          accesoUniversalEnergia {
            value
            description
          }
          aumentoEnergiasRenovables {
            value
            description
          }
          duplicarEficienciaEnergetica {
            value
            description
          }
          aumentoInvestigacionInversion {
            value
            description
          }
          ampliarInfraestructuraYTecnologia {
            value
            description
          }
          isApproved
        }
        trabajoDecente {
          mantenimientoCrecimientoEconomico {
            value
            description
          }
          elevarProductividad {
            value
            description
          }
          fomentoPequeniaMedianaEmpresa {
            value
            description
          }
          mejorarProduccionYConsumo {
            value
            description
          }
          lograrPlenoEmpleo {
            value
            description
          }
          reduccionJovenesSinTrabajo {
            value
            description
          }
          erradicarEsclavitud {
            value
            description
          }
          proteccionDerechosLaborales {
            value
            description
          }
          promocionTurismo {
            value
            description
          }
          fortalecimientoInstitucionesFinancieras {
            value
            description
          }
          aumentoAyudaComercio {
            value
            description
          }
          desarrolloEmpleoJuvenil {
            value
            description
          }
          isApproved
        }
        industriaInnovacion {
          desarrolloInfraestructura {
            value
            description
          }
          promocionIndustriaInclusiva {
            value
            description
          }
          aumentoAccesoPymesServiciosFinancieros {
            value
            description
          }
          modernizacionInfraestructura {
            value
            description
          }
          aumentoInvestigacionCientifica {
            value
            description
          }
          apoyoInfraestructuraSostenible {
            value
            description
          }
          desarrolloTecnologia {
            value
            description
          }
          aumentoAccesoTecnologias {
            value
            description
          }
          isApproved
        }
        reduccionDesigualdades {
          crecimientosIngresos {
            value
            description
          }
          promocionInclusion {
            value
            description
          }
          igualdadOportinidades {
            value
            description
          }
          adopcionPoliticasFiscales {
            value
            description
          }
          mejorarRegulacionMercados {
            value
            description
          }
          participacionPaisesDesarrollo {
            value
            description
          }
          facilitarMigracion {
            value
            description
          }
          aplicacionTratoEspecial {
            value
            description
          }
          fomentarCorrientesFinancieras {
            value
            description
          }
          reduccionCostesRemesas {
            value
            description
          }
          isApproved
        }
        ciudadesComunidades {
          accesoVivienda {
            value
            description
          }
          accesoTransportePublico {
            value
            description
          }
          aumentoUrbanizacion {
            value
            description
          }
          proteccionPatrimonioCultural {
            value
            description
          }
          reduccionNumeroMuertes {
            value
            description
          }
          reduccionImpactoAmbiental {
            value
            description
          }
          accesoZonasVerdes {
            value
            description
          }
          apoyoZonasUrbanas {
            value
            description
          }
          reduccionRiesgos {
            value
            description
          }
          apoyoConstruccionEdificosSostenibles {
            value
            description
          }
          isApproved
        }
        produccionConsumo {
          aplicacionMarcoConsumo {
            value
            description
          }
          usoEficienteRecursosNaturales {
            value
            description
          }
          reduccionDesperdicioAlimentos {
            value
            description
          }
          gestionDeshechos {
            value
            description
          }
          prevencionReduccionReciclado {
            value
            description
          }
          adopcionPracticasSostenibles {
            value
            description
          }
          adquisicionPublicasSostenibles {
            value
            description
          }
          asegurarEducacionDesarrolloSostenible {
            value
            description
          }
          fortalecimientoCienciaTecnologia {
            value
            description
          }
          turismoSostenible {
            value
            description
          }
          regularSubsidios {
            value
            description
          }
          isApproved
        }
        accionClima {
          fortalecerResilencia {
            value
            description
          }
          incorporarCambioClimaticoPoliticas {
            value
            description
          }
          mejoraEducacion {
            value
            description
          }
          movilizacionRecursosEconomicos {
            value
            description
          }
          gestionCambioClimatico {
            value
            description
          }
          isApproved
        }
        vidaSubmarina {
          prevencionContaminacionMarina {
            value
            description
          }
          gestionEcosistemasMarinosCosteros {
            value
            description
          }
          minimizarAcidificacionOceanos {
            value
            description
          }
          regularExplotacionPesqueraSostenible {
            value
            description
          }
          conservarZonasCosterasYMarinas {
            value
            description
          }
          combatirPescaIlegal {
            value
            description
          }
          aumentoBeneficiosEconomicosPescaSostenible {
            value
            description
          }
          apoyoInvestigacionMarina {
            value
            description
          }
          fomentoPescaPequeniaEscala {
            value
            description
          }
          aplicacionConvencionNNUU {
            value
            description
          }
          isApproved
        }
        vidaEcosistema {
          conservacionEcosistemas {
            value
            description
          }
          gestionSostenibleBosques {
            value
            description
          }
          luchaContraDesertificacion {
            value
            description
          }
          conservarEcosistemasMontaniosos {
            value
            description
          }
          degradacionPerdidaBiodiversidad {
            value
            description
          }
          usoAdecuadoRecursosGeneticos {
            value
            description
          }
          combatirCazaFurtiva {
            value
            description
          }
          prevencionEspeciesInvasoras {
            value
            description
          }
          integracionPlanesMedioAmbiente {
            value
            description
          }
          movilizacionAumentoRecursosFinancieros {
            value
            description
          }
          aumentoRecursosGestionForestal {
            value
            description
          }
          luchaCazaFurtiva {
            value
            description
          }
          isApproved
        }
        pazJusticiaInstituciones {
          reduccionFormasViolencia {
            value
            description
          }
          erradicacionMaltrato {
            value
            description
          }
          promocionEstadoDerecho {
            value
            description
          }
          reduccionCorrientesFinancieras {
            value
            description
          }
          reduccionCorrupcion {
            value
            description
          }
          creacionInstitucionesEficaces {
            value
            description
          }
          fomentoParticipacionCiudadana {
            value
            description
          }
          fortalecerPaisesEnDesarrollo {
            value
            description
          }
          proporcionIdentidadJuridica {
            value
            description
          }
          accesoInformacion {
            value
            description
          }
          fortalecimientoInstituciones {
            value
            description
          }
          promocionAplicacionLeyes {
            value
            description
          }
          isApproved
        }
        alianzaOds {
          movilizacionRecursosDomesticos {
            value
            description
          }
          cumplirAyudaDesarrollo {
            value
            description
          }
          movilizarRecursosFinancieros {
            value
            description
          }
          reestructurarDeuda {
            value
            description
          }
          promocionInversion {
            value
            description
          }
          mejorarTraspasoTecnologia {
            value
            description
          }
          promoverTecnologiaEcologicas {
            value
            description
          }
          crearBancoTecnologia {
            value
            description
          }
          refuerzoCapacidades {
            value
            description
          }
          promocionComercio {
            value
            description
          }
          aumentoExportaciones {
            value
            description
          }
          accesoMercados {
            value
            description
          }
          aumentoEstabilidad {
            value
            description
          }
          mejoraCoherencia {
            value
            description
          }
          respetoSoberania {
            value
            description
          }
          mejorarAlianzaMundial {
            value
            description
          }
          fomentoAlianzas {
            value
            description
          }
          creacionCapacidadEstadistica {
            value
            description
          }
          promoverIndicadores {
            value
            description
          }
          isApproved
        }
        rating
        isApproved
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      isApproved
      rating
      interestRateApplicant
      hasAnotherCredit
      owner
    }
  }
`;
export const deleteCreditAnalysis = /* GraphQL */ `
  mutation DeleteCreditAnalysis(
    $input: DeleteCreditAnalysisInput!
    $condition: ModelCreditAnalysisConditionInput
  ) {
    deleteCreditAnalysis(input: $input, condition: $condition) {
      applicantId
      profitability {
        profitabilityValue
        profitabilityPoints
        compoundAnnualGrowthInSalesValue
        compoundAnnualGrowthInSalesPoints
        compoundAnnualGrowthInNetEarningsValue
        compoundAnnualGrowthInNetEarningsPoints
        rating
        isApproved
      }
      liquidity {
        creditLine
        creditBalance
        borrowingCapacityValue
        borrowingCapacityPoints
        liquidityRatioValue
        liquidityRatioPoints
        acidTestValue
        acidTestPoints
        immediatePaymentAbilityValue
        immediatePaymentAbilityPoints
        maximumPaymentTermValue
        maximumPaymentTermPoints
        rating
        isApproved
      }
      solvency {
        taxComplianceChecksValue
        taxComplianceChecksPoints
        taxRegimeValue
        taxRegimePoints
        allClients
        majorCorporateClients
        majorCorporateClientsValue
        majorCorporateClientsPoints
        annualGrowthInClientsValue
        annualGrowthInClientsPoints
        salesConcentrationValue
        salesConcentrationPoints
        ROEValue
        ROEPoints
        ROAValue
        ROAPoints
        rating
        isApproved
      }
      creditAccess {
        financialInstitutionGrantsCreditValue
        financialInstitutionGrantsCreditPoints
        creditBureauRatingValue
        creditBureauRatingPoints
        paymentHistoryValue
        paymentHistoryPoints
        numberOfCreditGrantorsValue
        numberOfCreditGrantorsPoints
        inquiriesInCreditBureauValue
        inquiriesInCreditBureauPoints
        rating
        isApproved
      }
      marketAndProduct {
        dayOfBirth
        businessAgeValue
        businessAgePoints
        mainActivityValue
        mainActivityPoints
        statusInTaxpayerRegistryValue
        statusInTaxpayerRegistryPoints
        capitalRegimeValue
        capitalRegimePoints
        federalEntityValue
        federalEntityPoints
        rating
        isApproved
      }
      corporateGovernance {
        experienceOfMainShareholderValue
        experienceOfMainShareholderPoints
        independetConselorsValue
        independetConselorsPoints
        independenceDirectorGeneralValue
        independenceDirectorGeneralPoints
        numberOfCommitteesValue
        numberOfCommitteesPoints
        rating
        isApproved
      }
      genderEquality {
        numberFemaleShareholdersValue
        numberFemaleShareholdersPoints
        numberFemaleDirectorsOnBoardValue
        numberFemaleDirectorsOnBoardPoints
        isCEOFemaleValue
        isCEOFemalePoints
        numberFemaleWithManagerialPositionValue
        numberFemaleWithManagerialPositionPoints
        numberFemaleEmployeesValue
        numberFemaleEmployeesPoints
        rating
        isApproved
      }
      environmentalAndSocialImpact {
        finPobreza {
          erradicarPobrezaExtrema {
            value
            description
          }
          reduccionPobrezaRelativa {
            value
            description
          }
          implantacionProteccionSocial {
            value
            description
          }
          garantiaAccesoServicioBasico {
            value
            description
          }
          resilienciaDesastresAmbientales {
            value
            description
          }
          creacionMarcosNormativos {
            value
            description
          }
          isApproved
        }
        hambreCero {
          finHambre {
            value
            description
          }
          finMalnutricion {
            value
            description
          }
          duplicarProductividadIngresosPequeniaEscala {
            value
            description
          }
          practicasAgricolasSosteniblesResilientes {
            value
            description
          }
          mantenimientoDiversidadGeneticaSemilla {
            value
            description
          }
          aumentoInversionAgricola {
            value
            description
          }
          estabilidadMercadosAgropecuarios {
            value
            description
          }
          controlVolatilidadPreciosAlimentos {
            value
            description
          }
          isApproved
        }
        saludBienestar {
          reduccionTasaMortalidadMaterna {
            value
            description
          }
          eliminarMortalidadInfantil {
            value
            description
          }
          finEnfermedadesTransmisibles {
            value
            description
          }
          reduccionEnfermedadesNoTransmisiblesYSaludMental {
            value
            description
          }
          prevencionTratamientoAbusosDrogasAlcohol {
            value
            description
          }
          controlTabaco {
            value
            description
          }
          apoyoIDVacunasMedicamentosEsenciales {
            value
            description
          }
          aumentoFinanciacionSistemaSanitario {
            value
            description
          }
          refuerzoGestionRiesgosSanitarios {
            value
            description
          }
          isApproved
        }
        educacionCalidad {
          asegurarCalidadPrimariaSecundaria {
            value
            description
          }
          asegurarAccesoCalidadEducacionPreescolar {
            value
            description
          }
          asegurarAccesoIgualitarioFormacionSuperior {
            value
            description
          }
          aumentoCompetenciasAccederEmpleo {
            value
            description
          }
          eliminacionDisparidadGenero {
            value
            description
          }
          asegurarAlfabetizacion {
            value
            description
          }
          fomentarEducacionGlobal {
            value
            description
          }
          mejoraInstalacionesEducativas {
            value
            description
          }
          aumentoBecas {
            value
            description
          }
          mejorarDocentes {
            value
            description
          }
          isApproved
        }
        igualdadGenero {
          eliminarViolenciaGenero {
            value
            description
          }
          eliminarMatrimonioInfantil {
            value
            description
          }
          reconocerTrabajoDomestico {
            value
            description
          }
          asegurarParticipacionMujer {
            value
            description
          }
          asegurarAccesoSaludSexual {
            value
            description
          }
          asegurarIgualdadDerechos {
            value
            description
          }
          mejorarUsoTecnologia {
            value
            description
          }
          aprobarPoliticasIgualdad {
            value
            description
          }
          isApproved
        }
        aguaLimpia {
          accesoAguaPotable {
            value
            description
          }
          accesoSanamiento {
            value
            description
          }
          mejorarCalidadAgua {
            value
            description
          }
          usoEficienteRecursosHidricos {
            value
            description
          }
          gestionRecursosHidricos {
            value
            description
          }
          proteccionEcosistemas {
            value
            description
          }
          creacionCapacidadGestion {
            value
            description
          }
          apoyarComunidadesLocales {
            value
            description
          }
          isApproved
        }
        energiaAsequible {
          accesoUniversalEnergia {
            value
            description
          }
          aumentoEnergiasRenovables {
            value
            description
          }
          duplicarEficienciaEnergetica {
            value
            description
          }
          aumentoInvestigacionInversion {
            value
            description
          }
          ampliarInfraestructuraYTecnologia {
            value
            description
          }
          isApproved
        }
        trabajoDecente {
          mantenimientoCrecimientoEconomico {
            value
            description
          }
          elevarProductividad {
            value
            description
          }
          fomentoPequeniaMedianaEmpresa {
            value
            description
          }
          mejorarProduccionYConsumo {
            value
            description
          }
          lograrPlenoEmpleo {
            value
            description
          }
          reduccionJovenesSinTrabajo {
            value
            description
          }
          erradicarEsclavitud {
            value
            description
          }
          proteccionDerechosLaborales {
            value
            description
          }
          promocionTurismo {
            value
            description
          }
          fortalecimientoInstitucionesFinancieras {
            value
            description
          }
          aumentoAyudaComercio {
            value
            description
          }
          desarrolloEmpleoJuvenil {
            value
            description
          }
          isApproved
        }
        industriaInnovacion {
          desarrolloInfraestructura {
            value
            description
          }
          promocionIndustriaInclusiva {
            value
            description
          }
          aumentoAccesoPymesServiciosFinancieros {
            value
            description
          }
          modernizacionInfraestructura {
            value
            description
          }
          aumentoInvestigacionCientifica {
            value
            description
          }
          apoyoInfraestructuraSostenible {
            value
            description
          }
          desarrolloTecnologia {
            value
            description
          }
          aumentoAccesoTecnologias {
            value
            description
          }
          isApproved
        }
        reduccionDesigualdades {
          crecimientosIngresos {
            value
            description
          }
          promocionInclusion {
            value
            description
          }
          igualdadOportinidades {
            value
            description
          }
          adopcionPoliticasFiscales {
            value
            description
          }
          mejorarRegulacionMercados {
            value
            description
          }
          participacionPaisesDesarrollo {
            value
            description
          }
          facilitarMigracion {
            value
            description
          }
          aplicacionTratoEspecial {
            value
            description
          }
          fomentarCorrientesFinancieras {
            value
            description
          }
          reduccionCostesRemesas {
            value
            description
          }
          isApproved
        }
        ciudadesComunidades {
          accesoVivienda {
            value
            description
          }
          accesoTransportePublico {
            value
            description
          }
          aumentoUrbanizacion {
            value
            description
          }
          proteccionPatrimonioCultural {
            value
            description
          }
          reduccionNumeroMuertes {
            value
            description
          }
          reduccionImpactoAmbiental {
            value
            description
          }
          accesoZonasVerdes {
            value
            description
          }
          apoyoZonasUrbanas {
            value
            description
          }
          reduccionRiesgos {
            value
            description
          }
          apoyoConstruccionEdificosSostenibles {
            value
            description
          }
          isApproved
        }
        produccionConsumo {
          aplicacionMarcoConsumo {
            value
            description
          }
          usoEficienteRecursosNaturales {
            value
            description
          }
          reduccionDesperdicioAlimentos {
            value
            description
          }
          gestionDeshechos {
            value
            description
          }
          prevencionReduccionReciclado {
            value
            description
          }
          adopcionPracticasSostenibles {
            value
            description
          }
          adquisicionPublicasSostenibles {
            value
            description
          }
          asegurarEducacionDesarrolloSostenible {
            value
            description
          }
          fortalecimientoCienciaTecnologia {
            value
            description
          }
          turismoSostenible {
            value
            description
          }
          regularSubsidios {
            value
            description
          }
          isApproved
        }
        accionClima {
          fortalecerResilencia {
            value
            description
          }
          incorporarCambioClimaticoPoliticas {
            value
            description
          }
          mejoraEducacion {
            value
            description
          }
          movilizacionRecursosEconomicos {
            value
            description
          }
          gestionCambioClimatico {
            value
            description
          }
          isApproved
        }
        vidaSubmarina {
          prevencionContaminacionMarina {
            value
            description
          }
          gestionEcosistemasMarinosCosteros {
            value
            description
          }
          minimizarAcidificacionOceanos {
            value
            description
          }
          regularExplotacionPesqueraSostenible {
            value
            description
          }
          conservarZonasCosterasYMarinas {
            value
            description
          }
          combatirPescaIlegal {
            value
            description
          }
          aumentoBeneficiosEconomicosPescaSostenible {
            value
            description
          }
          apoyoInvestigacionMarina {
            value
            description
          }
          fomentoPescaPequeniaEscala {
            value
            description
          }
          aplicacionConvencionNNUU {
            value
            description
          }
          isApproved
        }
        vidaEcosistema {
          conservacionEcosistemas {
            value
            description
          }
          gestionSostenibleBosques {
            value
            description
          }
          luchaContraDesertificacion {
            value
            description
          }
          conservarEcosistemasMontaniosos {
            value
            description
          }
          degradacionPerdidaBiodiversidad {
            value
            description
          }
          usoAdecuadoRecursosGeneticos {
            value
            description
          }
          combatirCazaFurtiva {
            value
            description
          }
          prevencionEspeciesInvasoras {
            value
            description
          }
          integracionPlanesMedioAmbiente {
            value
            description
          }
          movilizacionAumentoRecursosFinancieros {
            value
            description
          }
          aumentoRecursosGestionForestal {
            value
            description
          }
          luchaCazaFurtiva {
            value
            description
          }
          isApproved
        }
        pazJusticiaInstituciones {
          reduccionFormasViolencia {
            value
            description
          }
          erradicacionMaltrato {
            value
            description
          }
          promocionEstadoDerecho {
            value
            description
          }
          reduccionCorrientesFinancieras {
            value
            description
          }
          reduccionCorrupcion {
            value
            description
          }
          creacionInstitucionesEficaces {
            value
            description
          }
          fomentoParticipacionCiudadana {
            value
            description
          }
          fortalecerPaisesEnDesarrollo {
            value
            description
          }
          proporcionIdentidadJuridica {
            value
            description
          }
          accesoInformacion {
            value
            description
          }
          fortalecimientoInstituciones {
            value
            description
          }
          promocionAplicacionLeyes {
            value
            description
          }
          isApproved
        }
        alianzaOds {
          movilizacionRecursosDomesticos {
            value
            description
          }
          cumplirAyudaDesarrollo {
            value
            description
          }
          movilizarRecursosFinancieros {
            value
            description
          }
          reestructurarDeuda {
            value
            description
          }
          promocionInversion {
            value
            description
          }
          mejorarTraspasoTecnologia {
            value
            description
          }
          promoverTecnologiaEcologicas {
            value
            description
          }
          crearBancoTecnologia {
            value
            description
          }
          refuerzoCapacidades {
            value
            description
          }
          promocionComercio {
            value
            description
          }
          aumentoExportaciones {
            value
            description
          }
          accesoMercados {
            value
            description
          }
          aumentoEstabilidad {
            value
            description
          }
          mejoraCoherencia {
            value
            description
          }
          respetoSoberania {
            value
            description
          }
          mejorarAlianzaMundial {
            value
            description
          }
          fomentoAlianzas {
            value
            description
          }
          creacionCapacidadEstadistica {
            value
            description
          }
          promoverIndicadores {
            value
            description
          }
          isApproved
        }
        rating
        isApproved
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      isApproved
      rating
      interestRateApplicant
      hasAnotherCredit
      owner
    }
  }
`;
export const createCreditBureau = /* GraphQL */ `
  mutation CreateCreditBureau(
    $input: CreateCreditBureauInput!
    $condition: ModelCreditBureauConditionInput
  ) {
    createCreditBureau(input: $input, condition: $condition) {
      applicantId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCreditBureau = /* GraphQL */ `
  mutation UpdateCreditBureau(
    $input: UpdateCreditBureauInput!
    $condition: ModelCreditBureauConditionInput
  ) {
    updateCreditBureau(input: $input, condition: $condition) {
      applicantId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCreditBureau = /* GraphQL */ `
  mutation DeleteCreditBureau(
    $input: DeleteCreditBureauInput!
    $condition: ModelCreditBureauConditionInput
  ) {
    deleteCreditBureau(input: $input, condition: $condition) {
      applicantId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress($input: CreateAddressInput!, $condition: ModelAddressConditionInput) {
    createAddress(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fullAddress
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      streetType
      streetReferences
      statusRaw
      numInt
      numExt
      typeOfAddress
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      type
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      investorAddressId
      applicantAddressId
      owner
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress($input: UpdateAddressInput!, $condition: ModelAddressConditionInput) {
    updateAddress(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fullAddress
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      streetType
      streetReferences
      statusRaw
      numInt
      numExt
      typeOfAddress
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      type
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      investorAddressId
      applicantAddressId
      owner
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress($input: DeleteAddressInput!, $condition: ModelAddressConditionInput) {
    deleteAddress(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fullAddress
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      streetType
      streetReferences
      statusRaw
      numInt
      numExt
      typeOfAddress
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      type
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      investorAddressId
      applicantAddressId
      owner
    }
  }
`;
export const createRisk = /* GraphQL */ `
  mutation CreateRisk($input: CreateRiskInput!, $condition: ModelRiskConditionInput) {
    createRisk(input: $input, condition: $condition) {
      investorId
      loss
      liquidity
      information
      performance
      notApproved
      notAdvice
      investment
      disclosedInformation
      isSigned
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateRisk = /* GraphQL */ `
  mutation UpdateRisk($input: UpdateRiskInput!, $condition: ModelRiskConditionInput) {
    updateRisk(input: $input, condition: $condition) {
      investorId
      loss
      liquidity
      information
      performance
      notApproved
      notAdvice
      investment
      disclosedInformation
      isSigned
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteRisk = /* GraphQL */ `
  mutation DeleteRisk($input: DeleteRiskInput!, $condition: ModelRiskConditionInput) {
    deleteRisk(input: $input, condition: $condition) {
      investorId
      loss
      liquidity
      information
      performance
      notApproved
      notAdvice
      investment
      disclosedInformation
      isSigned
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile($input: CreateFileInput!, $condition: ModelFileConditionInput) {
    createFile(input: $input, condition: $condition) {
      id
      address
      FEA
      INE {
        s3Front
        s3Back
        matiFrontUrl
        matiBackUrl
        emmissionDate
        expirationDate
        documentNumber
        ocrNumber
        documentType
        issueCountry
        simafContryCode
        ne
      }
      taxID
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      areBusinessDocumentsUpload
      businessDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      odsDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      owner
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile($input: UpdateFileInput!, $condition: ModelFileConditionInput) {
    updateFile(input: $input, condition: $condition) {
      id
      address
      FEA
      INE {
        s3Front
        s3Back
        matiFrontUrl
        matiBackUrl
        emmissionDate
        expirationDate
        documentNumber
        ocrNumber
        documentType
        issueCountry
        simafContryCode
        ne
      }
      taxID
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      areBusinessDocumentsUpload
      businessDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      odsDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      owner
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile($input: DeleteFileInput!, $condition: ModelFileConditionInput) {
    deleteFile(input: $input, condition: $condition) {
      id
      address
      FEA
      INE {
        s3Front
        s3Back
        matiFrontUrl
        matiBackUrl
        emmissionDate
        expirationDate
        documentNumber
        ocrNumber
        documentType
        issueCountry
        simafContryCode
        ne
      }
      taxID
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      areBusinessDocumentsUpload
      businessDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      odsDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      owner
    }
  }
`;
export const createSTP = /* GraphQL */ `
  mutation CreateSTP($input: CreateSTPInput!, $condition: ModelSTPConditionInput) {
    createSTP(input: $input, condition: $condition) {
      id
      CLABE
      cambioEstado {
        id
        empresa
        folioOrigen
        estado
        causaDevolucion
        tsLiquidacion
        createdAt
        updatedAt
      }
      collection {
        id
        abono
        fechaOperacion
        institucionOrdenante
        institucionBeneficiaria
        claveRastreo
        monto
        nombreOrdenante
        tipoCuentaOrdenante
        cuentaOrdenante
        rfcCurpOrdenante
        nombreBeneficiario
        tipoCuentaBeneficiario
        cuentaBeneficiario
        nombreBeneficiario2
        tipoCuentaBeneficiario2
        cuentaBeneficiario2
        rfcCurpBeneficiario
        conceptoPago
        referenciaNumerica
        empresa
        tipoPago
        tsLiquidacion
        folioCodi
        createdAt
        updatedAt
      }
      registraOrden {
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        firma
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        registraOrdenId
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        ordenPago
        fechaOperacion
        folioOrigen
      }
      recursosDisponibles
      compromisoInversion
      status
      inversionesVigentes
      saldoTotal
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSTP = /* GraphQL */ `
  mutation UpdateSTP($input: UpdateSTPInput!, $condition: ModelSTPConditionInput) {
    updateSTP(input: $input, condition: $condition) {
      id
      CLABE
      cambioEstado {
        id
        empresa
        folioOrigen
        estado
        causaDevolucion
        tsLiquidacion
        createdAt
        updatedAt
      }
      collection {
        id
        abono
        fechaOperacion
        institucionOrdenante
        institucionBeneficiaria
        claveRastreo
        monto
        nombreOrdenante
        tipoCuentaOrdenante
        cuentaOrdenante
        rfcCurpOrdenante
        nombreBeneficiario
        tipoCuentaBeneficiario
        cuentaBeneficiario
        nombreBeneficiario2
        tipoCuentaBeneficiario2
        cuentaBeneficiario2
        rfcCurpBeneficiario
        conceptoPago
        referenciaNumerica
        empresa
        tipoPago
        tsLiquidacion
        folioCodi
        createdAt
        updatedAt
      }
      registraOrden {
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        firma
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        registraOrdenId
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        ordenPago
        fechaOperacion
        folioOrigen
      }
      recursosDisponibles
      compromisoInversion
      status
      inversionesVigentes
      saldoTotal
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSTP = /* GraphQL */ `
  mutation DeleteSTP($input: DeleteSTPInput!, $condition: ModelSTPConditionInput) {
    deleteSTP(input: $input, condition: $condition) {
      id
      CLABE
      cambioEstado {
        id
        empresa
        folioOrigen
        estado
        causaDevolucion
        tsLiquidacion
        createdAt
        updatedAt
      }
      collection {
        id
        abono
        fechaOperacion
        institucionOrdenante
        institucionBeneficiaria
        claveRastreo
        monto
        nombreOrdenante
        tipoCuentaOrdenante
        cuentaOrdenante
        rfcCurpOrdenante
        nombreBeneficiario
        tipoCuentaBeneficiario
        cuentaBeneficiario
        nombreBeneficiario2
        tipoCuentaBeneficiario2
        cuentaBeneficiario2
        rfcCurpBeneficiario
        conceptoPago
        referenciaNumerica
        empresa
        tipoPago
        tsLiquidacion
        folioCodi
        createdAt
        updatedAt
      }
      registraOrden {
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        firma
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        registraOrdenId
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        ordenPago
        fechaOperacion
        folioOrigen
      }
      recursosDisponibles
      compromisoInversion
      status
      inversionesVigentes
      saldoTotal
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSTPNotification = /* GraphQL */ `
  mutation CreateSTPNotification(
    $input: CreateSTPNotificationInput!
    $condition: ModelSTPNotificationConditionInput
  ) {
    createSTPNotification(input: $input, condition: $condition) {
      registraOrdenId
      STPId
      STP {
        items {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSTPNotification = /* GraphQL */ `
  mutation UpdateSTPNotification(
    $input: UpdateSTPNotificationInput!
    $condition: ModelSTPNotificationConditionInput
  ) {
    updateSTPNotification(input: $input, condition: $condition) {
      registraOrdenId
      STPId
      STP {
        items {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSTPNotification = /* GraphQL */ `
  mutation DeleteSTPNotification(
    $input: DeleteSTPNotificationInput!
    $condition: ModelSTPNotificationConditionInput
  ) {
    deleteSTPNotification(input: $input, condition: $condition) {
      registraOrdenId
      STPId
      STP {
        items {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLiterax = /* GraphQL */ `
  mutation CreateLiterax($input: CreateLiteraxInput!, $condition: ModelLiteraxConditionInput) {
    createLiterax(input: $input, condition: $condition) {
      idExterno
      applicantId
      investorId
      estado
      descripcion
      constancia
      serie
      timeStamp
      huellaDigital
      politicaPSC
      firmaPSC
      archivoConstancia
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorLiteraxsId
      applicantLiteraxsId
      owner
    }
  }
`;
export const updateLiterax = /* GraphQL */ `
  mutation UpdateLiterax($input: UpdateLiteraxInput!, $condition: ModelLiteraxConditionInput) {
    updateLiterax(input: $input, condition: $condition) {
      idExterno
      applicantId
      investorId
      estado
      descripcion
      constancia
      serie
      timeStamp
      huellaDigital
      politicaPSC
      firmaPSC
      archivoConstancia
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorLiteraxsId
      applicantLiteraxsId
      owner
    }
  }
`;
export const deleteLiterax = /* GraphQL */ `
  mutation DeleteLiterax($input: DeleteLiteraxInput!, $condition: ModelLiteraxConditionInput) {
    deleteLiterax(input: $input, condition: $condition) {
      idExterno
      applicantId
      investorId
      estado
      descripcion
      constancia
      serie
      timeStamp
      huellaDigital
      politicaPSC
      firmaPSC
      archivoConstancia
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorLiteraxsId
      applicantLiteraxsId
      owner
    }
  }
`;
export const createMifiel = /* GraphQL */ `
  mutation CreateMifiel($input: CreateMifielInput!, $condition: ModelMifielConditionInput) {
    createMifiel(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      campaignId
      type
      documentId
      send_mail
      signed_hash
      name
      signed_by_all
      signed
      signed_at
      created_at
      burned_at
      status {
        status
      }
      external_id
      remind_every
      expires_at
      days_to_expire
      created_by
      state
      original_hash
      manual_close
      encrypted
      allow_business
      file_file_name
      archived
      krs
      owner {
        id
        email
        name
        is_company
      }
      creator {
        id
        email
        name
      }
      callback_url
      sign_callback_url
      already_signed {
        mifiel_already_signed
      }
      has_not_signed {
        mifiel_has_not_signed
      }
      permissions {
        unknown_1
        unknown_2
        unknown_3
      }
      file
      file_download
      file_signed
      file_signed_download
      file_zipped
      file_xml
      signers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      viewers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      signatures {
        id
        name
        email
        signed
        signed_at
        certificate_number
        tax_id
        signature
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      investorMifielsId
      applicantMifielsId
    }
  }
`;
export const updateMifiel = /* GraphQL */ `
  mutation UpdateMifiel($input: UpdateMifielInput!, $condition: ModelMifielConditionInput) {
    updateMifiel(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      campaignId
      type
      documentId
      send_mail
      signed_hash
      name
      signed_by_all
      signed
      signed_at
      created_at
      burned_at
      status {
        status
      }
      external_id
      remind_every
      expires_at
      days_to_expire
      created_by
      state
      original_hash
      manual_close
      encrypted
      allow_business
      file_file_name
      archived
      krs
      owner {
        id
        email
        name
        is_company
      }
      creator {
        id
        email
        name
      }
      callback_url
      sign_callback_url
      already_signed {
        mifiel_already_signed
      }
      has_not_signed {
        mifiel_has_not_signed
      }
      permissions {
        unknown_1
        unknown_2
        unknown_3
      }
      file
      file_download
      file_signed
      file_signed_download
      file_zipped
      file_xml
      signers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      viewers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      signatures {
        id
        name
        email
        signed
        signed_at
        certificate_number
        tax_id
        signature
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      investorMifielsId
      applicantMifielsId
    }
  }
`;
export const deleteMifiel = /* GraphQL */ `
  mutation DeleteMifiel($input: DeleteMifielInput!, $condition: ModelMifielConditionInput) {
    deleteMifiel(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      campaignId
      type
      documentId
      send_mail
      signed_hash
      name
      signed_by_all
      signed
      signed_at
      created_at
      burned_at
      status {
        status
      }
      external_id
      remind_every
      expires_at
      days_to_expire
      created_by
      state
      original_hash
      manual_close
      encrypted
      allow_business
      file_file_name
      archived
      krs
      owner {
        id
        email
        name
        is_company
      }
      creator {
        id
        email
        name
      }
      callback_url
      sign_callback_url
      already_signed {
        mifiel_already_signed
      }
      has_not_signed {
        mifiel_has_not_signed
      }
      permissions {
        unknown_1
        unknown_2
        unknown_3
      }
      file
      file_download
      file_signed
      file_signed_download
      file_zipped
      file_xml
      signers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      viewers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      signatures {
        id
        name
        email
        signed
        signed_at
        certificate_number
        tax_id
        signature
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      investorMifielsId
      applicantMifielsId
    }
  }
`;
export const createCirculoDeCredito = /* GraphQL */ `
  mutation CreateCirculoDeCredito(
    $input: CreateCirculoDeCreditoInput!
    $condition: ModelCirculoDeCreditoConditionInput
  ) {
    createCirculoDeCredito(input: $input, condition: $condition) {
      applicantId
      folioConsulta
      folioOtorgante
      claveRetorno
      fechaConsulta
      persona {
        RFC
        nombre
        nacionalidad
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
      }
      clavesBanxico {
        claveBanxico1
        claveBanxico2
        claveBanxico3
        claveBanxico4
        claveBanxico5
        claveBanxico6
        claveBanxico7
        claveBanxico8
        claveBanxico9
        claveBanxico10
      }
      calificacionCartera {
        calificacion
        nombreOtorgante
      }
      clavePrevenciones
      consultasInstitucionales {
        comerciales {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        financieras {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        consultas {
          fechaConsulta
          nombreOtorgante
        }
      }
      declarativas
      accionistas {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        porcentaje
      }
      avales {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        cantidad
      }
      credito {
        cuentasFinancieras {
          RFC
          contrato
          nombreOtorgante
          saldoInicial
          saldoTotal
          moneda
          fechaApertura
          plazo
          tipoCambio
          clavesObservacion
          tipoCredito
          vigente
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          fechaCierre
          pagoEfectivo
          quita
          dacionPago
          quebrantoCastigo
          historia
          atrasoMayor
          registroImpugnado
        }
        cuentasComerciales {
          RFC
          nombreOtorgante
          moneda
          vigente
          saldoTotal
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          historia
          atrasoMayor
        }
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCirculoDeCredito = /* GraphQL */ `
  mutation UpdateCirculoDeCredito(
    $input: UpdateCirculoDeCreditoInput!
    $condition: ModelCirculoDeCreditoConditionInput
  ) {
    updateCirculoDeCredito(input: $input, condition: $condition) {
      applicantId
      folioConsulta
      folioOtorgante
      claveRetorno
      fechaConsulta
      persona {
        RFC
        nombre
        nacionalidad
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
      }
      clavesBanxico {
        claveBanxico1
        claveBanxico2
        claveBanxico3
        claveBanxico4
        claveBanxico5
        claveBanxico6
        claveBanxico7
        claveBanxico8
        claveBanxico9
        claveBanxico10
      }
      calificacionCartera {
        calificacion
        nombreOtorgante
      }
      clavePrevenciones
      consultasInstitucionales {
        comerciales {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        financieras {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        consultas {
          fechaConsulta
          nombreOtorgante
        }
      }
      declarativas
      accionistas {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        porcentaje
      }
      avales {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        cantidad
      }
      credito {
        cuentasFinancieras {
          RFC
          contrato
          nombreOtorgante
          saldoInicial
          saldoTotal
          moneda
          fechaApertura
          plazo
          tipoCambio
          clavesObservacion
          tipoCredito
          vigente
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          fechaCierre
          pagoEfectivo
          quita
          dacionPago
          quebrantoCastigo
          historia
          atrasoMayor
          registroImpugnado
        }
        cuentasComerciales {
          RFC
          nombreOtorgante
          moneda
          vigente
          saldoTotal
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          historia
          atrasoMayor
        }
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCirculoDeCredito = /* GraphQL */ `
  mutation DeleteCirculoDeCredito(
    $input: DeleteCirculoDeCreditoInput!
    $condition: ModelCirculoDeCreditoConditionInput
  ) {
    deleteCirculoDeCredito(input: $input, condition: $condition) {
      applicantId
      folioConsulta
      folioOtorgante
      claveRetorno
      fechaConsulta
      persona {
        RFC
        nombre
        nacionalidad
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
      }
      clavesBanxico {
        claveBanxico1
        claveBanxico2
        claveBanxico3
        claveBanxico4
        claveBanxico5
        claveBanxico6
        claveBanxico7
        claveBanxico8
        claveBanxico9
        claveBanxico10
      }
      calificacionCartera {
        calificacion
        nombreOtorgante
      }
      clavePrevenciones
      consultasInstitucionales {
        comerciales {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        financieras {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        consultas {
          fechaConsulta
          nombreOtorgante
        }
      }
      declarativas
      accionistas {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        porcentaje
      }
      avales {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        cantidad
      }
      credito {
        cuentasFinancieras {
          RFC
          contrato
          nombreOtorgante
          saldoInicial
          saldoTotal
          moneda
          fechaApertura
          plazo
          tipoCambio
          clavesObservacion
          tipoCredito
          vigente
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          fechaCierre
          pagoEfectivo
          quita
          dacionPago
          quebrantoCastigo
          historia
          atrasoMayor
          registroImpugnado
        }
        cuentasComerciales {
          RFC
          nombreOtorgante
          moneda
          vigente
          saldoTotal
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          historia
          atrasoMayor
        }
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSTPRegistraOrden = /* GraphQL */ `
  mutation CreateSTPRegistraOrden(
    $input: CreateSTPRegistraOrdenInput!
    $condition: ModelSTPRegistraOrdenConditionInput
  ) {
    createSTPRegistraOrden(input: $input, condition: $condition) {
      registraOrdenId
      applicantId
      investorId
      registraOrden
      ordenPago
      claveRastreo
      conceptoPago
      cuentaBeneficiario
      cuentaOrdenante
      empresa
      fechaOperacion
      folioOrigen
      institucionContraparte
      institucionOperante
      monto
      nombreBeneficiario
      nombreOrdenante
      referenciaNumerica
      rfcCurpBeneficiario
      rfcCurpOrdenante
      tipoCuentaBeneficiario
      tipoCuentaOrdenante
      tipoPago
      createdAt
      updatedAt
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const updateSTPRegistraOrden = /* GraphQL */ `
  mutation UpdateSTPRegistraOrden(
    $input: UpdateSTPRegistraOrdenInput!
    $condition: ModelSTPRegistraOrdenConditionInput
  ) {
    updateSTPRegistraOrden(input: $input, condition: $condition) {
      registraOrdenId
      applicantId
      investorId
      registraOrden
      ordenPago
      claveRastreo
      conceptoPago
      cuentaBeneficiario
      cuentaOrdenante
      empresa
      fechaOperacion
      folioOrigen
      institucionContraparte
      institucionOperante
      monto
      nombreBeneficiario
      nombreOrdenante
      referenciaNumerica
      rfcCurpBeneficiario
      rfcCurpOrdenante
      tipoCuentaBeneficiario
      tipoCuentaOrdenante
      tipoPago
      createdAt
      updatedAt
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const deleteSTPRegistraOrden = /* GraphQL */ `
  mutation DeleteSTPRegistraOrden(
    $input: DeleteSTPRegistraOrdenInput!
    $condition: ModelSTPRegistraOrdenConditionInput
  ) {
    deleteSTPRegistraOrden(input: $input, condition: $condition) {
      registraOrdenId
      applicantId
      investorId
      registraOrden
      ordenPago
      claveRastreo
      conceptoPago
      cuentaBeneficiario
      cuentaOrdenante
      empresa
      fechaOperacion
      folioOrigen
      institucionContraparte
      institucionOperante
      monto
      nombreBeneficiario
      nombreOrdenante
      referenciaNumerica
      rfcCurpBeneficiario
      rfcCurpOrdenante
      tipoCuentaBeneficiario
      tipoCuentaOrdenante
      tipoPago
      createdAt
      updatedAt
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const createSimafPerfil = /* GraphQL */ `
  mutation CreateSimafPerfil(
    $input: CreateSimafPerfilInput!
    $condition: ModelSimafPerfilConditionInput
  ) {
    createSimafPerfil(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      simaf_cuentaId
      perfilDeclarado {
        frecuencia
        monto
      }
      perfilOperado {
        frecuencia
        monto
      }
      moneda
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      simaf_cuenta {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSimafPerfil = /* GraphQL */ `
  mutation UpdateSimafPerfil(
    $input: UpdateSimafPerfilInput!
    $condition: ModelSimafPerfilConditionInput
  ) {
    updateSimafPerfil(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      simaf_cuentaId
      perfilDeclarado {
        frecuencia
        monto
      }
      perfilOperado {
        frecuencia
        monto
      }
      moneda
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      simaf_cuenta {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSimafPerfil = /* GraphQL */ `
  mutation DeleteSimafPerfil(
    $input: DeleteSimafPerfilInput!
    $condition: ModelSimafPerfilConditionInput
  ) {
    deleteSimafPerfil(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      simaf_cuentaId
      perfilDeclarado {
        frecuencia
        monto
      }
      perfilOperado {
        frecuencia
        monto
      }
      moneda
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      simaf_cuenta {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSimafCuenta = /* GraphQL */ `
  mutation CreateSimafCuenta(
    $input: CreateSimafCuentaInput!
    $condition: ModelSimafCuentaConditionInput
  ) {
    createSimafCuenta(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_asociada_institucion
      institucion_financiera
      nacionalidad_cuenta_asociada
      nivel_cuenta
      numero_cuenta_proyecto
      regimen
      saldo
      tipo_cuenta
      tipo_financiamiento
      tipo_producto
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSimafCuenta = /* GraphQL */ `
  mutation UpdateSimafCuenta(
    $input: UpdateSimafCuentaInput!
    $condition: ModelSimafCuentaConditionInput
  ) {
    updateSimafCuenta(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_asociada_institucion
      institucion_financiera
      nacionalidad_cuenta_asociada
      nivel_cuenta
      numero_cuenta_proyecto
      regimen
      saldo
      tipo_cuenta
      tipo_financiamiento
      tipo_producto
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSimafCuenta = /* GraphQL */ `
  mutation DeleteSimafCuenta(
    $input: DeleteSimafCuentaInput!
    $condition: ModelSimafCuentaConditionInput
  ) {
    deleteSimafCuenta(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_asociada_institucion
      institucion_financiera
      nacionalidad_cuenta_asociada
      nivel_cuenta
      numero_cuenta_proyecto
      regimen
      saldo
      tipo_cuenta
      tipo_financiamiento
      tipo_producto
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSimafOperacion = /* GraphQL */ `
  mutation CreateSimafOperacion(
    $input: CreateSimafOperacionInput!
    $condition: ModelSimafOperacionConditionInput
  ) {
    createSimafOperacion(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_destino
      cuenta_origen
      geolocalizacion
      instrumento_monetario
      moneda
      timestamp
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSimafOperacion = /* GraphQL */ `
  mutation UpdateSimafOperacion(
    $input: UpdateSimafOperacionInput!
    $condition: ModelSimafOperacionConditionInput
  ) {
    updateSimafOperacion(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_destino
      cuenta_origen
      geolocalizacion
      instrumento_monetario
      moneda
      timestamp
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSimafOperacion = /* GraphQL */ `
  mutation DeleteSimafOperacion(
    $input: DeleteSimafOperacionInput!
    $condition: ModelSimafOperacionConditionInput
  ) {
    deleteSimafOperacion(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      cuenta_destino
      cuenta_origen
      geolocalizacion
      instrumento_monetario
      moneda
      timestamp
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createSimafCuestionario = /* GraphQL */ `
  mutation CreateSimafCuestionario(
    $input: CreateSimafCuestionarioInput!
    $condition: ModelSimafCuestionarioConditionInput
  ) {
    createSimafCuestionario(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      origen_recursos
      destino_recursos
      proposito
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateSimafCuestionario = /* GraphQL */ `
  mutation UpdateSimafCuestionario(
    $input: UpdateSimafCuestionarioInput!
    $condition: ModelSimafCuestionarioConditionInput
  ) {
    updateSimafCuestionario(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      origen_recursos
      destino_recursos
      proposito
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteSimafCuestionario = /* GraphQL */ `
  mutation DeleteSimafCuestionario(
    $input: DeleteSimafCuestionarioInput!
    $condition: ModelSimafCuestionarioConditionInput
  ) {
    deleteSimafCuestionario(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      origen_recursos
      destino_recursos
      proposito
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTrully = /* GraphQL */ `
  mutation CreateTrully($input: CreateTrullyInput!, $condition: ModelTrullyConditionInput) {
    createTrully(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fraud
      label
      raw_data {
        document_ocr {
          document_id
          document_ocr {
            bbox_face
            detected
            doc_type
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          image_quality {
            quality_score
          }
        }
        enrichment {
          curp {
            age
            curp
            date_of_birth
            gender
            government_name
            government_valid
            is_mexican
            state_iso
            state_of_birth
          }
          external_fraud_service {
            carrier
            city
            country
            dialing_code
            do_not_call
            formatted
            fraud_score
            leaked
            line_type
            local_format
            mcc
            message
            mnc
            name
            prepaid
            recent_abuse
            region
            risky
            sms_domain
            sms_email
            spammer
            success
            timezone
            valid
            VOIP
            zip_code
          }
          ip {
            ip
          }
          phone_number {
            fraud_report
            phone
            active
            active_status
          }
          whatsapp {
            is_valid
            on_whatsapp
          }
        }
        face_comparison {
          match
        }
        face_recognition {
          face_attributes {
            beard
            emotion
            eyeglasses
            gender
            maximum_age
            minimum_age
            mustache
            smile
            sunglasses
          }
          face_id
          inquiry_date
          match
          confidence
          first_seen
          fraud_detail
          last_seen
          match_fraud_flag
          times_seen_last_month
        }
        identity_fraud_score {
          probability
        }
        listed_weeks {
          employment_summary {
            age_first_job
            average_working_days
            discounted_weeks
            employed_last_3_years
            imss_active
            issste_weeks
            listed_weeks
            longest_job_period
            max_base_salary
            min_base_salary
            nss
            projected_monthly_salary
            reinstated_weeks
            shortest_job_period
            total_active_jobs
            total_employers
            total_jobs
          }
          salary_summary {
            active_job
            avg_base_salary
            contribution_base_salary
            contribution_base_salary_duration
            discharge_date
            employer_id
            employer_name
            employer_state
            job_duration_days
            max_base_salary
            min_base_salary
            registration_date
            salary_difference
            salary_movements
          }
        }
      }
      reason
      request_id
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTrully = /* GraphQL */ `
  mutation UpdateTrully($input: UpdateTrullyInput!, $condition: ModelTrullyConditionInput) {
    updateTrully(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fraud
      label
      raw_data {
        document_ocr {
          document_id
          document_ocr {
            bbox_face
            detected
            doc_type
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          image_quality {
            quality_score
          }
        }
        enrichment {
          curp {
            age
            curp
            date_of_birth
            gender
            government_name
            government_valid
            is_mexican
            state_iso
            state_of_birth
          }
          external_fraud_service {
            carrier
            city
            country
            dialing_code
            do_not_call
            formatted
            fraud_score
            leaked
            line_type
            local_format
            mcc
            message
            mnc
            name
            prepaid
            recent_abuse
            region
            risky
            sms_domain
            sms_email
            spammer
            success
            timezone
            valid
            VOIP
            zip_code
          }
          ip {
            ip
          }
          phone_number {
            fraud_report
            phone
            active
            active_status
          }
          whatsapp {
            is_valid
            on_whatsapp
          }
        }
        face_comparison {
          match
        }
        face_recognition {
          face_attributes {
            beard
            emotion
            eyeglasses
            gender
            maximum_age
            minimum_age
            mustache
            smile
            sunglasses
          }
          face_id
          inquiry_date
          match
          confidence
          first_seen
          fraud_detail
          last_seen
          match_fraud_flag
          times_seen_last_month
        }
        identity_fraud_score {
          probability
        }
        listed_weeks {
          employment_summary {
            age_first_job
            average_working_days
            discounted_weeks
            employed_last_3_years
            imss_active
            issste_weeks
            listed_weeks
            longest_job_period
            max_base_salary
            min_base_salary
            nss
            projected_monthly_salary
            reinstated_weeks
            shortest_job_period
            total_active_jobs
            total_employers
            total_jobs
          }
          salary_summary {
            active_job
            avg_base_salary
            contribution_base_salary
            contribution_base_salary_duration
            discharge_date
            employer_id
            employer_name
            employer_state
            job_duration_days
            max_base_salary
            min_base_salary
            registration_date
            salary_difference
            salary_movements
          }
        }
      }
      reason
      request_id
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTrully = /* GraphQL */ `
  mutation DeleteTrully($input: DeleteTrullyInput!, $condition: ModelTrullyConditionInput) {
    deleteTrully(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      fraud
      label
      raw_data {
        document_ocr {
          document_id
          document_ocr {
            bbox_face
            detected
            doc_type
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          image_quality {
            quality_score
          }
        }
        enrichment {
          curp {
            age
            curp
            date_of_birth
            gender
            government_name
            government_valid
            is_mexican
            state_iso
            state_of_birth
          }
          external_fraud_service {
            carrier
            city
            country
            dialing_code
            do_not_call
            formatted
            fraud_score
            leaked
            line_type
            local_format
            mcc
            message
            mnc
            name
            prepaid
            recent_abuse
            region
            risky
            sms_domain
            sms_email
            spammer
            success
            timezone
            valid
            VOIP
            zip_code
          }
          ip {
            ip
          }
          phone_number {
            fraud_report
            phone
            active
            active_status
          }
          whatsapp {
            is_valid
            on_whatsapp
          }
        }
        face_comparison {
          match
        }
        face_recognition {
          face_attributes {
            beard
            emotion
            eyeglasses
            gender
            maximum_age
            minimum_age
            mustache
            smile
            sunglasses
          }
          face_id
          inquiry_date
          match
          confidence
          first_seen
          fraud_detail
          last_seen
          match_fraud_flag
          times_seen_last_month
        }
        identity_fraud_score {
          probability
        }
        listed_weeks {
          employment_summary {
            age_first_job
            average_working_days
            discounted_weeks
            employed_last_3_years
            imss_active
            issste_weeks
            listed_weeks
            longest_job_period
            max_base_salary
            min_base_salary
            nss
            projected_monthly_salary
            reinstated_weeks
            shortest_job_period
            total_active_jobs
            total_employers
            total_jobs
          }
          salary_summary {
            active_job
            avg_base_salary
            contribution_base_salary
            contribution_base_salary_duration
            discharge_date
            employer_id
            employer_name
            employer_state
            job_duration_days
            max_base_salary
            min_base_salary
            registration_date
            salary_difference
            salary_movements
          }
        }
      }
      reason
      request_id
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
