// filtered country list according to simaf catalog filtering the countries that are not in the catalog
export const countryListFiltered = [
  {
    name_en: 'Mexico',
    name_es: 'México',
    dial_code: '+52',
    code_2: 'MX',
    code_3: 'MEX',
    tld: '.mx',
    flag_emoji: '🇲🇽',
    continent_en: 'North America'
  },
  {
    name_en: 'United States',
    name_es: 'Estados Unidos',
    dial_code: '+1',
    code_2: 'US',
    code_3: 'USA',
    tld: '.us',
    flag_emoji: '🇺🇸',
    continent_en: 'North America'
  },
  {
    name_en: 'Anguilla',
    name_es: 'Anguilla',
    dial_code: '+1264',
    code_2: 'AI',
    code_3: 'AIA',
    tld: '.ai',
    flag_emoji: '🇦🇮',
    continent_en: 'North America'
  },
  {
    name_en: 'Antigua and Barbuda',
    name_es: 'Antigua y Barbuda',
    dial_code: '+1268',
    code_2: 'AG',
    code_3: 'ATG',
    tld: '.ag',
    flag_emoji: '🇦🇬',
    continent_en: 'North America'
  },
  {
    name_en: 'Argentina',
    name_es: 'Argentina',
    dial_code: '+54',
    code_2: 'AR',
    code_3: 'ARG',
    tld: '.ar',
    flag_emoji: '🇦🇷',
    continent_en: 'South America'
  },
  {
    name_en: 'Aruba',
    name_es: 'Aruba',
    dial_code: '+297',
    code_2: 'AW',
    code_3: 'ABW',
    tld: '.aw',
    flag_emoji: '🇦🇼',
    continent_en: 'North America'
  },
  {
    name_en: 'Bahamas',
    name_es: 'Bahamas',
    dial_code: '+1242',
    code_2: 'BS',
    code_3: 'BHS',
    tld: '.bs',
    flag_emoji: '🇧🇸',
    continent_en: 'North America'
  },
  {
    name_en: 'Barbados',
    name_es: 'Barbados',
    dial_code: '+1246',
    code_2: 'BB',
    code_3: 'BRB',
    tld: '.bb',
    flag_emoji: '🇧🇧',
    continent_en: 'North America'
  },
  {
    name_en: 'Belize',
    name_es: 'Belice',
    dial_code: '+501',
    code_2: 'BZ',
    code_3: 'BLZ',
    tld: '.bz',
    flag_emoji: '🇧🇿',
    continent_en: 'North America'
  },
  {
    name_en: 'Bermuda',
    name_es: 'Bermudas',
    dial_code: '+1441',
    code_2: 'BM',
    code_3: 'BMU',
    tld: '.bm',
    flag_emoji: '🇧🇲',
    continent_en: 'North America'
  },
  {
    name_en: 'Bolivia',
    name_es: 'Bolivia',
    dial_code: '+591',
    code_2: 'BO',
    code_3: 'BOL',
    tld: '.bo',
    flag_emoji: '🇧🇴',
    continent_en: 'South America'
  },
  {
    name_en: 'Brazil',
    name_es: 'Brasil',
    dial_code: '+55',
    code_2: 'BR',
    code_3: 'BRA',
    tld: '.br',
    flag_emoji: '🇧🇷',
    continent_en: 'South America'
  },
  {
    name_en: 'Canada',
    name_es: 'Canadá',
    dial_code: '+1',
    code_2: 'CA',
    code_3: 'CAN',
    tld: '.ca',
    flag_emoji: '🇨🇦',
    continent_en: 'North America'
  },
  {
    name_en: 'Cayman Islands',
    name_es: 'Islas Caimán',
    dial_code: '+1345',
    code_2: 'KY',
    code_3: 'CYM',
    tld: '.ky',
    flag_emoji: '🇰🇾',
    continent_en: 'North America'
  },
  {
    name_en: 'Chile',
    name_es: 'Chile',
    dial_code: '+56',
    code_2: 'CL',
    code_3: 'CHL',
    tld: '.cl',
    flag_emoji: '🇨🇱',
    continent_en: 'South America'
  },
  {
    name_en: 'Colombia',
    name_es: 'Colombia',
    dial_code: '+57',
    code_2: 'CO',
    code_3: 'COL',
    tld: '.co',
    flag_emoji: '🇨🇴',
    continent_en: 'South America'
  },
  {
    name_en: 'Costa Rica',
    name_es: 'Costa Rica',
    dial_code: '+506',
    code_2: 'CR',
    code_3: 'CRI',
    tld: '.cr',
    flag_emoji: '🇨🇷',
    continent_en: 'North America'
  },
  {
    name_en: 'Cuba',
    name_es: 'Cuba',
    dial_code: '+53',
    code_2: 'CU',
    code_3: 'CUB',
    tld: '.cu',
    flag_emoji: '🇨🇺',
    continent_en: 'North America'
  },
  {
    name_en: 'Curaçao',
    name_es: 'Curazao',
    dial_code: '+599',
    code_2: 'CW',
    code_3: 'CUW',
    tld: '.cw',
    flag_emoji: '🇨🇼',
    continent_en: 'North America'
  },
  {
    name_en: 'Dominica',
    name_es: 'Dominica',
    dial_code: '+1767',
    code_2: 'DM',
    code_3: 'DMA',
    tld: '.dm',
    flag_emoji: '🇩🇲',
    continent_en: 'North America'
  },
  {
    name_en: 'Dominican Republic',
    name_es: 'República Dominicana',
    dial_code: '+1849',
    code_2: 'DO',
    code_3: 'DOM',
    tld: '.do',
    flag_emoji: '🇩🇴',
    continent_en: 'North America'
  },
  {
    name_en: 'Ecuador',
    name_es: 'Ecuador',
    dial_code: '+593',
    code_2: 'EC',
    code_3: 'ECU',
    tld: '.ec',
    flag_emoji: '🇪🇨',
    continent_en: 'South America'
  },
  {
    name_en: 'El Salvador',
    name_es: 'El Salvador',
    dial_code: '+503',
    code_2: 'SV',
    code_3: 'SLV',
    tld: '.sv',
    flag_emoji: '🇸🇻',
    continent_en: 'North America'
  },
  {
    name_en: 'Falkland Islands (Malvinas)',
    name_es: 'Islas Malvinas',
    dial_code: '+500',
    code_2: 'FK',
    code_3: 'FLK',
    tld: '.fk',
    flag_emoji: '🇫🇰',
    continent_en: 'South America'
  },
  {
    name_en: 'French Guiana',
    name_es: 'Guayana Francesa',
    dial_code: '+594',
    code_2: 'GF',
    code_3: 'GUF',
    tld: '.gf',
    flag_emoji: '🇬🇫',
    continent_en: 'South America'
  },
  {
    name_en: 'Greenland',
    name_es: 'Groenlandia',
    dial_code: '+299',
    code_2: 'GL',
    code_3: 'GRL',
    tld: '.gl',
    flag_emoji: '🇬🇱',
    continent_en: 'North America'
  },
  {
    name_en: 'Grenada',
    name_es: 'Granada',
    dial_code: '+1473',
    code_2: 'GD',
    code_3: 'GRD',
    tld: '.gd',
    flag_emoji: '🇬🇩',
    continent_en: 'North America'
  },
  {
    name_en: 'Guadeloupe',
    name_es: 'Guadalupe',
    dial_code: '+590',
    code_2: 'GP',
    code_3: 'GLP',
    tld: '.gp',
    flag_emoji: '🇬🇵',
    continent_en: 'North America'
  },
  {
    name_en: 'Guatemala',
    name_es: 'Guatemala',
    dial_code: '+502',
    code_2: 'GT',
    code_3: 'GTM',
    tld: '.gt',
    flag_emoji: '🇬🇹',
    continent_en: 'North America'
  },
  {
    name_en: 'Guyana',
    name_es: 'Guyana',
    dial_code: '+592',
    code_2: 'GY',
    code_3: 'GUY',
    tld: '.gy',
    flag_emoji: '🇬🇾',
    continent_en: 'South America'
  },
  {
    name_en: 'Haiti',
    name_es: 'Haití',
    dial_code: '+509',
    code_2: 'HT',
    code_3: 'HTI',
    tld: '.ht',
    flag_emoji: '🇭🇹',
    continent_en: 'North America'
  },
  {
    name_en: 'Honduras',
    name_es: 'Honduras',
    dial_code: '+504',
    code_2: 'HN',
    code_3: 'HND',
    tld: '.hn',
    flag_emoji: '🇭🇳',
    continent_en: 'North America'
  },
  {
    name_en: 'Jamaica',
    name_es: 'Jamaica',
    dial_code: '+1 876',
    code_2: 'JM',
    code_3: 'JAM',
    tld: '.jm',
    flag_emoji: '🇯🇲',
    continent_en: 'North America'
  },
  {
    name_en: 'Montserrat',
    name_es: 'Montserrat',
    dial_code: '+1664',
    code_2: 'MS',
    code_3: 'MSR',
    tld: '.ms',
    flag_emoji: '🇲🇸',
    continent_en: 'North America'
  },
  {
    name_en: 'Nicaragua',
    name_es: 'Nicaragua',
    dial_code: '+505',
    code_2: 'NI',
    code_3: 'NIC',
    tld: '.ni',
    flag_emoji: '🇳🇮',
    continent_en: 'North America'
  },
  {
    name_en: 'Panama',
    name_es: 'Panamá',
    dial_code: '+507',
    code_2: 'PA',
    code_3: 'PAN',
    tld: '.pa',
    flag_emoji: '🇵🇦',
    continent_en: 'North America'
  },
  {
    name_en: 'Paraguay',
    name_es: 'Paraguay',
    dial_code: '+595',
    code_2: 'PY',
    code_3: 'PRY',
    tld: '.py',
    flag_emoji: '🇵🇾',
    continent_en: 'South America'
  },
  {
    name_en: 'Peru',
    name_es: 'Perú',
    dial_code: '+51',
    code_2: 'PE',
    code_3: 'PER',
    tld: '.pe',
    flag_emoji: '🇵🇪',
    continent_en: 'South America'
  },
  {
    name_en: 'Puerto Rico',
    name_es: 'Puerto Rico',
    dial_code: '+1939',
    code_2: 'PR',
    code_3: 'PRI',
    tld: '.pr',
    flag_emoji: '🇵🇷',
    continent_en: 'North America'
  },
  {
    name_en: 'Saint Kitts and Nevis',
    name_es: 'San Cristóbal y Nieves',
    dial_code: '+1869',
    code_2: 'KN',
    code_3: 'KNA',
    tld: '.kn',
    flag_emoji: '🇰🇳',
    continent_en: 'North America'
  },
  {
    name_en: 'Saint Lucia',
    name_es: 'Santa Lucía',
    dial_code: '+1758',
    code_2: 'LC',
    code_3: 'LCA',
    tld: '.lc',
    flag_emoji: '🇱🇨',
    continent_en: 'North America'
  },
  {
    name_en: 'Saint Martin',
    name_es: 'San Martín',
    dial_code: '+590',
    code_2: 'MF',
    code_3: 'MAF',
    tld: '.mf',
    flag_emoji: '🇲🇫',
    continent_en: 'North America'
  },
  {
    name_en: 'Saint Pierre and Miquelon',
    name_es: 'San Pedro y Miquelón',
    dial_code: '+508',
    code_2: 'PM',
    code_3: 'SPM',
    tld: '.pm',
    flag_emoji: '🇵🇲',
    continent_en: 'North America'
  },
  {
    name_en: 'Saint Vincent and the Grenadines',
    name_es: 'San Vicente y las Granadinas',
    dial_code: '+1784',
    code_2: 'VC',
    code_3: 'VCT',
    tld: '.vc',
    flag_emoji: '🇻🇨',
    continent_en: 'North America'
  },
  {
    name_en: 'Suriname',
    name_es: 'Surinam',
    dial_code: '+597',
    code_2: 'SR',
    code_3: 'SUR',
    tld: '.sr',
    flag_emoji: '🇸🇷',
    continent_en: 'South America'
  },
  {
    name_en: 'Trinidad and Tobago',
    name_es: 'Trinidad y Tobago',
    dial_code: '+1868',
    code_2: 'TT',
    code_3: 'TTO',
    tld: '.tt',
    flag_emoji: '🇹🇹',
    continent_en: 'North America'
  },
  {
    name_en: 'Turks and Caicos Islands',
    name_es: 'Islas Turcas y Caicos',
    dial_code: '+1649',
    code_2: 'TC',
    code_3: 'TCA',
    tld: '.tc',
    flag_emoji: '🇹🇨',
    continent_en: 'North America'
  },
  {
    name_en: 'Uruguay',
    name_es: 'Uruguay',
    dial_code: '+598',
    code_2: 'UY',
    code_3: 'URY',
    tld: '.uy',
    flag_emoji: '🇺🇾',
    continent_en: 'South America'
  },
  {
    name_en: 'Venezuela',
    name_es: 'Venezuela',
    dial_code: '+58',
    code_2: 'VE',
    code_3: 'VEN',
    tld: '.ve',
    flag_emoji: '🇻🇪',
    continent_en: 'South America'
  },
  {
    name_en: 'Virgin Islands, British',
    name_es: 'Islas Vírgenes Británicas',
    dial_code: '+1284',
    code_2: 'VG',
    code_3: 'VGB',
    tld: '.vg',
    flag_emoji: '🇻🇬',
    continent_en: 'North America'
  },
  {
    name_en: 'Virgin Islands, U.S.',
    name_es: 'Islas Vírgenes de los Estados Unidos',
    dial_code: '+1340',
    code_2: 'VI',
    code_3: 'VIR',
    tld: '.vi',
    flag_emoji: '🇻🇮',
    continent_en: 'North America'
  },
  {
    name_en: 'Afghanistan',
    name_es: 'Afganistán',
    dial_code: '+93',
    code_2: 'AF',
    code_3: 'AFG',
    tld: '.af',
    flag_emoji: '🇦🇫',
    continent_en: 'Africa'
  },
  {
    name_en: 'Albania',
    name_es: 'Albania',
    dial_code: '+355',
    code_2: 'AL',
    code_3: 'ALB',
    tld: '.al',
    flag_emoji: '🇦🇱',
    continent_en: 'Europe'
  },
  {
    name_en: 'Algeria',
    name_es: 'Argelia',
    dial_code: '+213',
    code_2: 'DZ',
    code_3: 'DZA',
    tld: '.dz',
    flag_emoji: '🇩🇿',
    continent_en: 'Africa'
  },
  {
    name_en: 'American Samoa',
    name_es: 'Samoa Americana',
    dial_code: '+1684',
    code_2: 'AS',
    code_3: 'ASM',
    tld: '.as',
    flag_emoji: '🇦🇸',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Andorra',
    name_es: 'Andorra',
    dial_code: '+376',
    code_2: 'AD',
    code_3: 'AND',
    tld: '.ad',
    flag_emoji: '🇦🇩',
    continent_en: 'Europe'
  },
  {
    name_en: 'Angola',
    name_es: 'Angola',
    dial_code: '+244',
    code_2: 'AO',
    code_3: 'AGO',
    tld: '.ao',
    flag_emoji: '🇦🇴',
    continent_en: 'Africa'
  },
  {
    name_en: 'Armenia',
    name_es: 'Armenia',
    dial_code: '+374',
    code_2: 'AM',
    code_3: 'ARM',
    tld: '.am',
    flag_emoji: '🇦🇲',
    continent_en: 'Asia'
  },
  {
    name_en: 'Australia',
    name_es: 'Australia',
    dial_code: '+61',
    code_2: 'AU',
    code_3: 'AUS',
    tld: '.au',
    flag_emoji: '🇦🇺',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Austria',
    name_es: 'Austria',
    dial_code: '+43',
    code_2: 'AT',
    code_3: 'AUT',
    tld: '.at',
    flag_emoji: '🇦🇹',
    continent_en: 'Europe'
  },
  {
    name_en: 'Azerbaijan',
    name_es: 'Azerbaiyán',
    dial_code: '+994',
    code_2: 'AZ',
    code_3: 'AZE',
    tld: '.az',
    flag_emoji: '🇦🇿',
    continent_en: 'Asia'
  },
  {
    name_en: 'Bahrain',
    name_es: 'Baréin',
    dial_code: '+973',
    code_2: 'BH',
    code_3: 'BHR',
    tld: '.bh',
    flag_emoji: '🇧🇭',
    continent_en: 'Asia'
  },
  {
    name_en: 'Bangladesh',
    name_es: 'Bangladés',
    dial_code: '+880',
    code_2: 'BD',
    code_3: 'BGD',
    tld: '.bd',
    flag_emoji: '🇧🇩',
    continent_en: 'Asia'
  },
  {
    name_en: 'Belarus',
    name_es: 'Bielorrusia',
    dial_code: '+375',
    code_2: 'BY',
    code_3: 'BLR',
    tld: '.by',
    flag_emoji: '🇧🇾',
    continent_en: 'Europe'
  },
  {
    name_en: 'Belgium',
    name_es: 'Bélgica',
    dial_code: '+32',
    code_2: 'BE',
    code_3: 'BEL',
    tld: '.be',
    flag_emoji: '🇧🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Benin',
    name_es: 'Benin',
    dial_code: '+229',
    code_2: 'BJ',
    code_3: 'BEN',
    tld: '.bj',
    flag_emoji: '🇧🇯',
    continent_en: 'Africa'
  },
  {
    name_en: 'Bhutan',
    name_es: 'Bután',
    dial_code: '+975',
    code_2: 'BT',
    code_3: 'BTN',
    tld: '.bt',
    flag_emoji: '🇧🇹',
    continent_en: 'Asia'
  },
  {
    name_en: 'Bosnia and Herzegovina',
    name_es: 'Bosnia y Herzegovina',
    dial_code: '+387',
    code_2: 'BA',
    code_3: 'BIH',
    tld: '.ba',
    flag_emoji: '🇧🇦',
    continent_en: 'Europe'
  },
  {
    name_en: 'Botswana',
    name_es: 'Botsuana',
    dial_code: '+267',
    code_2: 'BW',
    code_3: 'BWA',
    tld: '.bw',
    flag_emoji: '🇧🇼',
    continent_en: 'Africa'
  },
  {
    name_en: 'British Indian Ocean Territory',
    name_es: 'Territorio Británico del Océano Índico',
    dial_code: '+246',
    code_2: 'IO',
    code_3: 'IOT',
    tld: '.io',
    flag_emoji: '🇮🇴',
    continent_en: 'Asia'
  },
  {
    name_en: 'Brunei Darussalam',
    name_es: 'Brunéi',
    dial_code: '+673',
    code_2: 'BN',
    code_3: 'BRN',
    tld: '.bn',
    flag_emoji: '🇧🇳',
    continent_en: 'Asia'
  },
  {
    name_en: 'Bulgaria',
    name_es: 'Bulgaria',
    dial_code: '+359',
    code_2: 'BG',
    code_3: 'BGR',
    tld: '.bg',
    flag_emoji: '🇧🇬',
    continent_en: 'Europe'
  },
  {
    name_en: 'Burkina Faso',
    name_es: 'Burkina Faso',
    dial_code: '+226',
    code_2: 'BF',
    code_3: 'BFA',
    tld: '.bf',
    flag_emoji: '🇧🇫',
    continent_en: 'Africa'
  },
  {
    name_en: 'Burundi',
    name_es: 'Burundi',
    dial_code: '+257',
    code_2: 'BI',
    code_3: 'BDI',
    tld: '.bi',
    flag_emoji: '🇧🇮',
    continent_en: 'Africa'
  },
  {
    name_en: 'Cambodia',
    name_es: 'Camboya',
    dial_code: '+855',
    code_2: 'KH',
    code_3: 'KHM',
    tld: '.kh',
    flag_emoji: '🇰🇭',
    continent_en: 'Asia'
  },
  {
    name_en: 'Cameroon',
    name_es: 'Camerún',
    dial_code: '+237',
    code_2: 'CM',
    code_3: 'CMR',
    tld: '.cm',
    flag_emoji: '🇨🇲',
    continent_en: 'Africa'
  },
  {
    name_en: 'Cape Verde',
    name_es: 'Cabo Verde',
    dial_code: '+238',
    code_2: 'CV',
    code_3: 'CPV',
    tld: '.cv',
    flag_emoji: '🇨🇻',
    continent_en: 'Africa'
  },
  {
    name_en: 'Central African Republic',
    name_es: 'República Centroafricana',
    dial_code: '+236',
    code_2: 'CF',
    code_3: 'CAF',
    tld: '.cf',
    flag_emoji: '🇨🇫',
    continent_en: 'Africa'
  },
  {
    name_en: 'Chad',
    name_es: 'Chad',
    dial_code: '+235',
    code_2: 'TD',
    code_3: 'TCD',
    tld: '.td',
    flag_emoji: '🇹🇩',
    continent_en: 'Africa'
  },
  {
    name_en: 'China',
    name_es: 'China',
    dial_code: '+86',
    code_2: 'CN',
    code_3: 'CHN',
    tld: '.cn',
    flag_emoji: '🇨🇳',
    continent_en: 'Asia'
  },
  {
    name_en: 'Comoros',
    name_es: 'Comoras',
    dial_code: '+269',
    code_2: 'KM',
    code_3: 'COM',
    tld: '.km',
    flag_emoji: '🇰🇲',
    continent_en: 'Africa'
  },
  {
    name_en: 'Congo',
    name_es: 'Congo',
    dial_code: '+242',
    code_2: 'CG',
    code_3: 'COG',
    tld: '.cg',
    flag_emoji: '🇨🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Cook Islands',
    name_es: 'Islas Cook',
    dial_code: '+682',
    code_2: 'CK',
    code_3: 'COK',
    tld: '.ck',
    flag_emoji: '🇨🇰',
    continent_en: 'Oceania'
  },
  {
    name_en: "Côte d'Ivoire",
    name_es: 'Costa de Marfil',
    dial_code: '+225',
    code_2: 'CI',
    code_3: 'CIV',
    tld: '.ci',
    flag_emoji: '🇨🇮',
    continent_en: 'Africa'
  },
  {
    name_en: 'Croatia',
    name_es: 'Croacia',
    dial_code: '+385',
    code_2: 'HR',
    code_3: 'HRV',
    tld: '.hr',
    flag_emoji: '🇭🇷',
    continent_en: 'Europe'
  },
  {
    name_en: 'Cyprus',
    name_es: 'Chipre',
    dial_code: '+357',
    code_2: 'CY',
    code_3: 'CYP',
    tld: '.cy',
    flag_emoji: '🇨🇾',
    continent_en: 'Europe'
  },
  {
    name_en: 'Czechia',
    name_es: 'Chequia',
    dial_code: '+420',
    code_2: 'CZ',
    code_3: 'CZE',
    tld: '.cz',
    flag_emoji: '🇨🇿',
    continent_en: 'Europe'
  },
  {
    name_en: 'Denmark',
    name_es: 'Dinamarca',
    dial_code: '+45',
    code_2: 'DK',
    code_3: 'DNK',
    tld: '.dk',
    flag_emoji: '🇩🇰',
    continent_en: 'Europe'
  },
  {
    name_en: 'Djibouti',
    name_es: 'Yibuti',
    dial_code: '+253',
    code_2: 'DJ',
    code_3: 'DJI',
    tld: '.dj',
    flag_emoji: '🇩🇯',
    continent_en: 'Africa'
  },
  {
    name_en: 'Egypt',
    name_es: 'Egipto',
    dial_code: '+20',
    code_2: 'EG',
    code_3: 'EGY',
    tld: '.eg',
    flag_emoji: '🇪🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Equatorial Guinea',
    name_es: 'Guinea Ecuatorial',
    dial_code: '+240',
    code_2: 'GQ',
    code_3: 'GNQ',
    tld: '.gq',
    flag_emoji: '🇬🇶',
    continent_en: 'Africa'
  },
  {
    name_en: 'Eritrea',
    name_es: 'Eritrea',
    dial_code: '+291',
    code_2: 'ER',
    code_3: 'ERI',
    tld: '.er',
    flag_emoji: '🇪🇷',
    continent_en: 'Africa'
  },
  {
    name_en: 'Estonia',
    name_es: 'Estonia',
    dial_code: '+372',
    code_2: 'EE',
    code_3: 'EST',
    tld: '.ee',
    flag_emoji: '🇪🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Eswatini',
    name_es: 'Suazilandia',
    dial_code: '+268',
    code_2: 'SZ',
    code_3: 'SWZ',
    tld: '.sz',
    flag_emoji: '🇸🇿',
    continent_en: 'Africa'
  },
  {
    name_en: 'Ethiopia',
    name_es: 'Etiopía',
    dial_code: '+251',
    code_2: 'ET',
    code_3: 'ETH',
    tld: '.et',
    flag_emoji: '🇪🇹',
    continent_en: 'Africa'
  },
  {
    name_en: 'Faroe Islands',
    name_es: 'Islas Feroe',
    dial_code: '+298',
    code_2: 'FO',
    code_3: 'FRO',
    tld: '.fo',
    flag_emoji: '🇫🇴',
    continent_en: 'Europe'
  },
  {
    name_en: 'Fiji',
    name_es: 'Fiyi',
    dial_code: '+679',
    code_2: 'FJ',
    code_3: 'FJI',
    tld: '.fj',
    flag_emoji: '🇫🇯',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Finland',
    name_es: 'Finlandia',
    dial_code: '+358',
    code_2: 'FI',
    code_3: 'FIN',
    tld: '.fi',
    flag_emoji: '🇫🇮',
    continent_en: 'Europe'
  },
  {
    name_en: 'France',
    name_es: 'Francia',
    dial_code: '+33',
    code_2: 'FR',
    code_3: 'FRA',
    tld: '.fr',
    flag_emoji: '🇫🇷',
    continent_en: 'Europe'
  },
  {
    name_en: 'Gabon',
    name_es: 'Gabón',
    dial_code: '+241',
    code_2: 'GA',
    code_3: 'GAB',
    tld: '.ga',
    flag_emoji: '🇬🇦',
    continent_en: 'Africa'
  },
  {
    name_en: 'Gambia',
    name_es: 'Gambia',
    dial_code: '+220',
    code_2: 'GM',
    code_3: 'GMB',
    tld: '.gm',
    flag_emoji: '🇬🇲',
    continent_en: 'Africa'
  },
  {
    name_en: 'Georgia',
    name_es: 'Georgia',
    dial_code: '+995',
    code_2: 'GE',
    code_3: 'GEO',
    tld: '.ge',
    flag_emoji: '🇬🇪',
    continent_en: 'Asia'
  },
  {
    name_en: 'Germany',
    name_es: 'Alemania',
    dial_code: '+49',
    code_2: 'DE',
    code_3: 'DEU',
    tld: '.de',
    flag_emoji: '🇩🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Ghana',
    name_es: 'Ghana',
    dial_code: '+233',
    code_2: 'GH',
    code_3: 'GHA',
    tld: '.gh',
    flag_emoji: '🇬🇭',
    continent_en: 'Africa'
  },
  {
    name_en: 'Gibraltar',
    name_es: 'Gibraltar',
    dial_code: '+350',
    code_2: 'GI',
    code_3: 'GIB',
    tld: '.gi',
    flag_emoji: '🇬🇮',
    continent_en: 'Europe'
  },
  {
    name_en: 'Greece',
    name_es: 'Grecia',
    dial_code: '+30',
    code_2: 'GR',
    code_3: 'GRC',
    tld: '.gr',
    flag_emoji: '🇬🇷',
    continent_en: 'Europe'
  },
  {
    name_en: 'Guam',
    name_es: 'Guam',
    dial_code: '+1671',
    code_2: 'GU',
    code_3: 'GUM',
    tld: '.gu',
    flag_emoji: '🇬🇺',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Guinea',
    name_es: 'Guinea',
    dial_code: '+224',
    code_2: 'GN',
    code_3: 'GIN',
    tld: '.gn',
    flag_emoji: '🇬🇳',
    continent_en: 'Africa'
  },
  {
    name_en: 'Guinea-Bissau',
    name_es: 'Guinea-Bissau',
    dial_code: '+245',
    code_2: 'GW',
    code_3: 'GNB',
    tld: '.gw',
    flag_emoji: '🇬🇼',
    continent_en: 'Africa'
  },
  {
    name_en: 'Hungary',
    name_es: 'Hungría',
    dial_code: '+36',
    code_2: 'HU',
    code_3: 'HUN',
    tld: '.hu',
    flag_emoji: '🇭🇺',
    continent_en: 'Europe'
  },
  {
    name_en: 'Iceland',
    name_es: 'Islandia',
    dial_code: '+354',
    code_2: 'IS',
    code_3: 'ISL',
    tld: '.is',
    flag_emoji: '🇮🇸',
    continent_en: 'Europe'
  },
  {
    name_en: 'India',
    name_es: 'India',
    dial_code: '+91',
    code_2: 'IN',
    code_3: 'IND',
    tld: '.in',
    flag_emoji: '🇮🇳',
    continent_en: 'Asia'
  },
  {
    name_en: 'Indonesia',
    name_es: 'Indonesia',
    dial_code: '+62',
    code_2: 'ID',
    code_3: 'IDN',
    tld: '.id',
    flag_emoji: '🇮🇩',
    continent_en: 'Asia'
  },
  {
    name_en: 'Iran',
    name_es: 'Irán',
    dial_code: '+98',
    code_2: 'IR',
    code_3: 'IRN',
    tld: '.ir',
    flag_emoji: '🇮🇷',
    continent_en: 'Asia'
  },
  {
    name_en: 'Iraq',
    name_es: 'Irak',
    dial_code: '+964',
    code_2: 'IQ',
    code_3: 'IRQ',
    tld: '.iq',
    flag_emoji: '🇮🇶',
    continent_en: 'Asia'
  },
  {
    name_en: 'Ireland',
    name_es: 'Irlanda',
    dial_code: '+353',
    code_2: 'IE',
    code_3: 'IRL',
    tld: '.ie',
    flag_emoji: '🇮🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Isle of Man',
    name_es: 'Isla de Man',
    dial_code: '+44',
    code_2: 'IM',
    code_3: 'IMN',
    tld: '.im',
    flag_emoji: '🇮🇲',
    continent_en: 'Europe'
  },
  {
    name_en: 'Israel',
    name_es: 'Israel',
    dial_code: '+972',
    code_2: 'IL',
    code_3: 'ISR',
    tld: '.il',
    flag_emoji: '🇮🇱',
    continent_en: 'Asia'
  },
  {
    name_en: 'Italy',
    name_es: 'Italia',
    dial_code: '+39',
    code_2: 'IT',
    code_3: 'ITA',
    tld: '.it',
    flag_emoji: '🇮🇹',
    continent_en: 'Europe'
  },
  {
    name_en: 'Japan',
    name_es: 'Japón',
    dial_code: '+81',
    code_2: 'JP',
    code_3: 'JPN',
    tld: '.jp',
    flag_emoji: '🇯🇵',
    continent_en: 'Asia'
  },
  {
    name_en: 'Jordan',
    name_es: 'Jordania',
    dial_code: '+962',
    code_2: 'JO',
    code_3: 'JOR',
    tld: '.jo',
    flag_emoji: '🇯🇴',
    continent_en: 'Asia'
  },
  {
    name_en: 'Kazakhstan',
    name_es: 'Kazajistán',
    dial_code: '+7 7',
    code_2: 'KZ',
    code_3: 'KAZ',
    tld: '.kz',
    flag_emoji: '🇰🇿',
    continent_en: 'Asia'
  },
  {
    name_en: 'Kenya',
    name_es: 'Kenia',
    dial_code: '+254',
    code_2: 'KE',
    code_3: 'KEN',
    tld: '.ke',
    flag_emoji: '🇰🇪',
    continent_en: 'Africa'
  },
  {
    name_en: 'Kiribati',
    name_es: 'Kiribati',
    dial_code: '+686',
    code_2: 'KI',
    code_3: 'KIR',
    tld: '.ki',
    flag_emoji: '🇰🇮',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Kuwait',
    name_es: 'Kuwait',
    dial_code: '+965',
    code_2: 'KW',
    code_3: 'KWT',
    tld: '.kw',
    flag_emoji: '🇰🇼',
    continent_en: 'Asia'
  },
  {
    name_en: 'Kyrgyzstan',
    name_es: 'Kirguistán',
    dial_code: '+996',
    code_2: 'KG',
    code_3: 'KGZ',
    tld: '.kg',
    flag_emoji: '🇰🇬',
    continent_en: 'Asia'
  },
  {
    name_en: 'Laos',
    name_es: 'Laos',
    dial_code: '+856',
    code_2: 'LA',
    code_3: 'LAO',
    tld: '.la',
    flag_emoji: '🇱🇦',
    continent_en: 'Asia'
  },
  {
    name_en: 'Latvia',
    name_es: 'Letonia',
    dial_code: '+371',
    code_2: 'LV',
    code_3: 'LVA',
    tld: '.lv',
    flag_emoji: '🇱🇻',
    continent_en: 'Europe'
  },
  {
    name_en: 'Lebanon',
    name_es: 'Líbano',
    dial_code: '+961',
    code_2: 'LB',
    code_3: 'LBN',
    tld: '.lb',
    flag_emoji: '🇱🇧',
    continent_en: 'Asia'
  },
  {
    name_en: 'Lesotho',
    name_es: 'Lesoto',
    dial_code: '+266',
    code_2: 'LS',
    code_3: 'LSO',
    tld: '.ls',
    flag_emoji: '🇱🇸',
    continent_en: 'Africa'
  },
  {
    name_en: 'Liberia',
    name_es: 'Liberia',
    dial_code: '+231',
    code_2: 'LR',
    code_3: 'LBR',
    tld: '.lr',
    flag_emoji: '🇱🇷',
    continent_en: 'Africa'
  },
  {
    name_en: 'Libya',
    name_es: 'Libia',
    dial_code: '+218',
    code_2: 'LY',
    code_3: 'LBY',
    tld: '.ly',
    flag_emoji: '🇱🇾',
    continent_en: 'Africa'
  },
  {
    name_en: 'Liechtenstein',
    name_es: 'Liechtenstein',
    dial_code: '+423',
    code_2: 'LI',
    code_3: 'LIE',
    tld: '.li',
    flag_emoji: '🇱🇮',
    continent_en: 'Europe'
  },
  {
    name_en: 'Lithuania',
    name_es: 'Lituania',
    dial_code: '+370',
    code_2: 'LT',
    code_3: 'LTU',
    tld: '.lt',
    flag_emoji: '🇱🇹',
    continent_en: 'Europe'
  },
  {
    name_en: 'Luxembourg',
    name_es: 'Luxemburgo',
    dial_code: '+352',
    code_2: 'LU',
    code_3: 'LUX',
    tld: '.lu',
    flag_emoji: '🇱🇺',
    continent_en: 'Europe'
  },
  {
    name_en: 'Madagascar',
    name_es: 'Madagascar',
    dial_code: '+261',
    code_2: 'MG',
    code_3: 'MDG',
    tld: '.mg',
    flag_emoji: '🇲🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Malawi',
    name_es: 'Malawi',
    dial_code: '+265',
    code_2: 'MW',
    code_3: 'MWI',
    tld: '.mw',
    flag_emoji: '🇲🇼',
    continent_en: 'Africa'
  },
  {
    name_en: 'Malaysia',
    name_es: 'Malasia',
    dial_code: '+60',
    code_2: 'MY',
    code_3: 'MYS',
    tld: '.my',
    flag_emoji: '🇲🇾',
    continent_en: 'Asia'
  },
  {
    name_en: 'Maldives',
    name_es: 'Maldivas',
    dial_code: '+960',
    code_2: 'MV',
    code_3: 'MDV',
    tld: '.mv',
    flag_emoji: '🇲🇻',
    continent_en: 'Asia'
  },
  {
    name_en: 'Mali',
    name_es: 'Mali',
    dial_code: '+223',
    code_2: 'ML',
    code_3: 'MLI',
    tld: '.ml',
    flag_emoji: '🇲🇱',
    continent_en: 'Africa'
  },
  {
    name_en: 'Malta',
    name_es: 'Malta',
    dial_code: '+356',
    code_2: 'MT',
    code_3: 'MLT',
    tld: '.mt',
    flag_emoji: '🇲🇹',
    continent_en: 'Europe'
  },
  {
    name_en: 'Marshall Islands',
    name_es: 'Islas Marshall',
    dial_code: '+692',
    code_2: 'MH',
    code_3: 'MHL',
    tld: '.mh',
    flag_emoji: '🇲🇭',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Mauritania',
    name_es: 'Mauritania',
    dial_code: '+222',
    code_2: 'MR',
    code_3: 'MRT',
    tld: '.mr',
    flag_emoji: '🇲🇷',
    continent_en: 'Africa'
  },
  {
    name_en: 'Mauritius',
    name_es: 'Mauricio',
    dial_code: '+230',
    code_2: 'MU',
    code_3: 'MUS',
    tld: '.mu',
    flag_emoji: '🇲🇺',
    continent_en: 'Africa'
  },
  {
    name_en: 'Micronesia',
    name_es: 'Micronesia',
    dial_code: '+691',
    code_2: 'FM',
    code_3: 'FSM',
    tld: '.fm',
    flag_emoji: '🇫🇲',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Moldova',
    name_es: 'Moldavia',
    dial_code: '+373',
    code_2: 'MD',
    code_3: 'MDA',
    tld: '.md',
    flag_emoji: '🇲🇩',
    continent_en: 'Europe'
  },
  {
    name_en: 'Monaco',
    name_es: 'Mónaco',
    dial_code: '+377',
    code_2: 'MC',
    code_3: 'MCO',
    tld: '.mc',
    flag_emoji: '🇲🇨',
    continent_en: 'Europe'
  },
  {
    name_en: 'Mongolia',
    name_es: 'Mongolia',
    dial_code: '+976',
    code_2: 'MN',
    code_3: 'MNG',
    tld: '.mn',
    flag_emoji: '🇲🇳',
    continent_en: 'Asia'
  },
  {
    name_en: 'Montenegro',
    name_es: 'Montenegro',
    dial_code: '+382',
    code_2: 'ME',
    code_3: 'MNE',
    tld: '.me',
    flag_emoji: '🇲🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Morocco',
    name_es: 'Marruecos',
    dial_code: '+212',
    code_2: 'MA',
    code_3: 'MAR',
    tld: '.ma',
    flag_emoji: '🇲🇦',
    continent_en: 'Africa'
  },
  {
    name_en: 'Mozambique',
    name_es: 'Mozambique',
    dial_code: '+258',
    code_2: 'MZ',
    code_3: 'MOZ',
    tld: '.mz',
    flag_emoji: '🇲🇿',
    continent_en: 'Africa'
  },
  {
    name_en: 'Myanmar',
    name_es: 'Myanmar',
    dial_code: '+95',
    code_2: 'MM',
    code_3: 'MMR',
    tld: '.mm',
    flag_emoji: '🇲🇲',
    continent_en: 'Asia'
  },
  {
    name_en: 'Namibia',
    name_es: 'Namibia',
    dial_code: '+264',
    code_2: 'NA',
    code_3: 'NAM',
    tld: '.na',
    flag_emoji: '🇳🇦',
    continent_en: 'Africa'
  },
  {
    name_en: 'Nauru',
    name_es: 'Nauru',
    dial_code: '+674',
    code_2: 'NR',
    code_3: 'NRU',
    tld: '.nr',
    flag_emoji: '🇳🇷',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Nepal',
    name_es: 'Nepal',
    dial_code: '+977',
    code_2: 'NP',
    code_3: 'NPL',
    tld: '.np',
    flag_emoji: '🇳🇵',
    continent_en: 'Asia'
  },
  {
    name_en: 'Netherlands',
    name_es: 'Países Bajos',
    dial_code: '+31',
    code_2: 'NL',
    code_3: 'NLD',
    tld: '.nl',
    flag_emoji: '🇳🇱',
    continent_en: 'Europe'
  },
  {
    name_en: 'New Zealand',
    name_es: 'Nueva Zelanda',
    dial_code: '+64',
    code_2: 'NZ',
    code_3: 'NZL',
    tld: '.nz',
    flag_emoji: '🇳🇿',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Niger',
    name_es: 'Níger',
    dial_code: '+227',
    code_2: 'NE',
    code_3: 'NER',
    tld: '.ne',
    flag_emoji: '🇳🇪',
    continent_en: 'Africa'
  },
  {
    name_en: 'Nigeria',
    name_es: 'Nigeria',
    dial_code: '+234',
    code_2: 'NG',
    code_3: 'NGA',
    tld: '.ng',
    flag_emoji: '🇳🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Niue',
    name_es: 'Niue',
    dial_code: '+683',
    code_2: 'NU',
    code_3: 'NIU',
    tld: '.nu',
    flag_emoji: '🇳🇺',
    continent_en: 'Oceania'
  },
  {
    name_en: 'North Korea',
    name_es: 'Corea del Norte',
    dial_code: '+850',
    code_2: 'KP',
    code_3: 'PRK',
    tld: '.kp',
    flag_emoji: '🇰🇵',
    continent_en: 'Asia'
  },
  {
    name_en: 'North Macedonia',
    name_es: 'Macedonia del Norte',
    dial_code: '+389',
    code_2: 'MK',
    code_3: 'MKD',
    tld: '.mk',
    flag_emoji: '🇲🇰',
    continent_en: 'Europe'
  },
  {
    name_en: 'Northern Mariana Islands',
    name_es: 'Islas Marianas del Norte',
    dial_code: '+1670',
    code_2: 'MP',
    code_3: 'MNP',
    tld: '.mp',
    flag_emoji: '🇲🇵',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Norway',
    name_es: 'Noruega',
    dial_code: '+47',
    code_2: 'NO',
    code_3: 'NOR',
    tld: '.no',
    flag_emoji: '🇳🇴',
    continent_en: 'Europe'
  },
  {
    name_en: 'Oman',
    name_es: 'Omán',
    dial_code: '+968',
    code_2: 'OM',
    code_3: 'OMN',
    tld: '.om',
    flag_emoji: '🇴🇲',
    continent_en: 'Asia'
  },
  {
    name_en: 'Pakistan',
    name_es: 'Pakistán',
    dial_code: '+92',
    code_2: 'PK',
    code_3: 'PAK',
    tld: '.pk',
    flag_emoji: '🇵🇰',
    continent_en: 'Asia'
  },
  {
    name_en: 'Palau',
    name_es: 'Palaos',
    dial_code: '+680',
    code_2: 'PW',
    code_3: 'PLW',
    tld: '.pw',
    flag_emoji: '🇵🇼',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Palestine',
    name_es: 'Palestina',
    dial_code: '+970',
    code_2: 'PS',
    code_3: 'PSE',
    tld: '.ps',
    flag_emoji: '🇵🇸',
    continent_en: 'Asia'
  },
  {
    name_en: 'Papua New Guinea',
    name_es: 'Papúa Nueva Guinea',
    dial_code: '+675',
    code_2: 'PG',
    code_3: 'PNG',
    tld: '.pg',
    flag_emoji: '🇵🇬',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Philippines',
    name_es: 'Filipinas',
    dial_code: '+63',
    code_2: 'PH',
    code_3: 'PHL',
    tld: '.ph',
    flag_emoji: '🇵🇭',
    continent_en: 'Asia'
  },
  {
    name_en: 'Pitcairn',
    name_es: 'Islas Pitcairn',
    dial_code: '+64',
    code_2: 'PN',
    code_3: 'PCN',
    tld: '.pn',
    flag_emoji: '🇵🇳',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Poland',
    name_es: 'Polonia',
    dial_code: '+48',
    code_2: 'PL',
    code_3: 'POL',
    tld: '.pl',
    flag_emoji: '🇵🇱',
    continent_en: 'Europe'
  },
  {
    name_en: 'Portugal',
    name_es: 'Portugal',
    dial_code: '+351',
    code_2: 'PT',
    code_3: 'PRT',
    tld: '.pt',
    flag_emoji: '🇵🇹',
    continent_en: 'Europe'
  },
  {
    name_en: 'Qatar',
    name_es: 'Catar',
    dial_code: '+974',
    code_2: 'QA',
    code_3: 'QAT',
    tld: '.qa',
    flag_emoji: '🇶🇦',
    continent_en: 'Asia'
  },
  {
    name_en: 'Romania',
    name_es: 'Rumania',
    dial_code: '+40',
    code_2: 'RO',
    code_3: 'ROU',
    tld: '.ro',
    flag_emoji: '🇷🇴',
    continent_en: 'Europe'
  },
  {
    name_en: 'Russia',
    name_es: 'Rusia',
    dial_code: '+7',
    code_2: 'RU',
    code_3: 'RUS',
    tld: '.ru',
    flag_emoji: '🇷🇺',
    continent_en: 'Europe'
  },
  {
    name_en: 'Rwanda',
    name_es: 'Ruanda',
    dial_code: '+250',
    code_2: 'RW',
    code_3: 'RWA',
    tld: '.rw',
    flag_emoji: '🇷🇼',
    continent_en: 'Africa'
  },
  {
    name_en: 'Saint Helena',
    name_es: 'Santa Elena',
    dial_code: '+290',
    code_2: 'SH',
    code_3: 'SHN',
    tld: '.sh',
    flag_emoji: '🇸🇭',
    continent_en: 'Africa'
  },
  {
    name_en: 'Samoa',
    name_es: 'Samoa',
    dial_code: '+685',
    code_2: 'WS',
    code_3: 'WSM',
    tld: '.ws',
    flag_emoji: '🇼🇸',
    continent_en: 'Oceania'
  },
  {
    name_en: 'San Marino',
    name_es: 'San Marino',
    dial_code: '+378',
    code_2: 'SM',
    code_3: 'SMR',
    tld: '.sm',
    flag_emoji: '🇸🇲',
    continent_en: 'Europe'
  },
  {
    name_en: 'Sao Tome and Principe',
    name_es: 'Santo Tomé y Príncipe',
    dial_code: '+239',
    code_2: 'ST',
    code_3: 'STP',
    tld: '.st',
    flag_emoji: '🇸🇹',
    continent_en: 'Africa'
  },
  {
    name_en: 'Saudi Arabia',
    name_es: 'Arabia Saudita',
    dial_code: '+966',
    code_2: 'SA',
    code_3: 'SAU',
    tld: '.sa',
    flag_emoji: '🇸🇦',
    continent_en: 'Asia'
  },
  {
    name_en: 'Senegal',
    name_es: 'Senegal',
    dial_code: '+221',
    code_2: 'SN',
    code_3: 'SEN',
    tld: '.sn',
    flag_emoji: '🇸🇳',
    continent_en: 'Africa'
  },
  {
    name_en: 'Serbia',
    name_es: 'Serbia',
    dial_code: '+381',
    code_2: 'RS',
    code_3: 'SRB',
    tld: '.rs',
    flag_emoji: '🇷🇸',
    continent_en: 'Europe'
  },
  {
    name_en: 'Seychelles',
    name_es: 'Seychelles',
    dial_code: '+248',
    code_2: 'SC',
    code_3: 'SYC',
    tld: '.sc',
    flag_emoji: '🇸🇨',
    continent_en: 'Africa'
  },
  {
    name_en: 'Sierra Leone',
    name_es: 'Sierra Leona',
    dial_code: '+232',
    code_2: 'SL',
    code_3: 'SLE',
    tld: '.sl',
    flag_emoji: '🇸🇱',
    continent_en: 'Africa'
  },
  {
    name_en: 'Singapore',
    name_es: 'Singapur',
    dial_code: '+65',
    code_2: 'SG',
    code_3: 'SGP',
    tld: '.sg',
    flag_emoji: '🇸🇬',
    continent_en: 'Asia'
  },
  {
    name_en: 'Slovakia',
    name_es: 'Eslovaquia',
    dial_code: '+421',
    code_2: 'SK',
    code_3: 'SVK',
    tld: '.sk',
    flag_emoji: '🇸🇰',
    continent_en: 'Europe'
  },
  {
    name_en: 'Slovenia',
    name_es: 'Eslovenia',
    dial_code: '+386',
    code_2: 'SI',
    code_3: 'SVN',
    tld: '.si',
    flag_emoji: '🇸🇮',
    continent_en: 'Europe'
  },
  {
    name_en: 'Solomon Islands',
    name_es: 'Islas Salomón',
    dial_code: '+677',
    code_2: 'SB',
    code_3: 'SLB',
    tld: '.sb',
    flag_emoji: '🇸🇧',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Somalia',
    name_es: 'Somalia',
    dial_code: '+252',
    code_2: 'SO',
    code_3: 'SOM',
    tld: '.so',
    flag_emoji: '🇸🇴',
    continent_en: 'Africa'
  },
  {
    name_en: 'South Africa',
    name_es: 'Sudáfrica',
    dial_code: '+27',
    code_2: 'ZA',
    code_3: 'ZAF',
    tld: '.za',
    flag_emoji: '🇿🇦',
    continent_en: 'Africa'
  },
  {
    name_en: 'South Georgia and the South Sandwich Islands',
    name_es: 'Islas Georgias del Sur y Sandwich del Sur',
    dial_code: '+500',
    code_2: 'GS',
    code_3: 'SGS',
    tld: '.gs',
    flag_emoji: '🇬🇸',
    continent_en: 'Antarctica'
  },
  {
    name_en: 'South Korea',
    name_es: 'Corea del Sur',
    dial_code: '+82',
    code_2: 'KR',
    code_3: 'KOR',
    tld: '.kr',
    flag_emoji: '🇰🇷',
    continent_en: 'Asia'
  },
  {
    name_en: 'South Sudan',
    name_es: 'Sudán del Sur',
    dial_code: '+211',
    code_2: 'SS',
    code_3: 'SSD',
    tld: '.ss',
    flag_emoji: '🇸🇸',
    continent_en: 'Africa'
  },
  {
    name_en: 'Spain',
    name_es: 'España',
    dial_code: '+34',
    code_2: 'ES',
    code_3: 'ESP',
    tld: '.es',
    flag_emoji: '🇪🇸',
    continent_en: 'Europe'
  },
  {
    name_en: 'Sri Lanka',
    name_es: 'Sri Lanka',
    dial_code: '+94',
    code_2: 'LK',
    code_3: 'LKA',
    tld: '.lk',
    flag_emoji: '🇱🇰',
    continent_en: 'Asia'
  },
  {
    name_en: 'Sudan',
    name_es: 'Sudán',
    dial_code: '+249',
    code_2: 'SD',
    code_3: 'SDN',
    tld: '.sd',
    flag_emoji: '🇸🇩',
    continent_en: 'Africa'
  },
  {
    name_en: 'Sweden',
    name_es: 'Suecia',
    dial_code: '+46',
    code_2: 'SE',
    code_3: 'SWE',
    tld: '.se',
    flag_emoji: '🇸🇪',
    continent_en: 'Europe'
  },
  {
    name_en: 'Switzerland',
    name_es: 'Suiza',
    dial_code: '+41',
    code_2: 'CH',
    code_3: 'CHE',
    tld: '.ch',
    flag_emoji: '🇨🇭',
    continent_en: 'Europe'
  },
  {
    name_en: 'Syria',
    name_es: 'Siria',
    dial_code: '+963',
    code_2: 'SY',
    code_3: 'SYR',
    tld: '.sy',
    flag_emoji: '🇸🇾',
    continent_en: 'Asia'
  },
  {
    name_en: 'Taiwan',
    name_es: 'Taiwán',
    dial_code: '+886',
    code_2: 'TW',
    code_3: 'TWN',
    tld: '.tw',
    flag_emoji: '🇹🇼',
    continent_en: 'Asia'
  },
  {
    name_en: 'Tajikistan',
    name_es: 'Tayikistán',
    dial_code: '+992',
    code_2: 'TJ',
    code_3: 'TJK',
    tld: '.tj',
    flag_emoji: '🇹🇯',
    continent_en: 'Asia'
  },
  {
    name_en: 'Tanzania',
    name_es: 'Tanzania',
    dial_code: '+255',
    code_2: 'TZ',
    code_3: 'TZA',
    tld: '.tz',
    flag_emoji: '🇹🇿',
    continent_en: 'Africa'
  },
  {
    name_en: 'Thailand',
    name_es: 'Tailandia',
    dial_code: '+66',
    code_2: 'TH',
    code_3: 'THA',
    tld: '.th',
    flag_emoji: '🇹🇭',
    continent_en: 'Asia'
  },
  {
    name_en: 'Togo',
    name_es: 'Togo',
    dial_code: '+228',
    code_2: 'TG',
    code_3: 'TGO',
    tld: '.tg',
    flag_emoji: '🇹🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Tokelau',
    name_es: 'Tokelau',
    dial_code: '+690',
    code_2: 'TK',
    code_3: 'TKL',
    tld: '.tk',
    flag_emoji: '🇹🇰',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Tonga',
    name_es: 'Tonga',
    dial_code: '+676',
    code_2: 'TO',
    code_3: 'TON',
    tld: '.to',
    flag_emoji: '🇹🇴',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Tristan da Cunha',
    name_es: 'Tristán de Acuña',
    dial_code: '+290',
    code_2: 'TA',
    code_3: 'SHN',
    tld: '.ta',
    flag_emoji: '🇹🇦',
    continent_en: 'Africa'
  },
  {
    name_en: 'Tunisia',
    name_es: 'Túnez',
    dial_code: '+216',
    code_2: 'TN',
    code_3: 'TUN',
    tld: '.tn',
    flag_emoji: '🇹🇳',
    continent_en: 'Africa'
  },
  {
    name_en: 'Türkiye',
    name_es: 'Turquía',
    dial_code: '+90',
    code_2: 'TR',
    code_3: 'TUR',
    tld: '.tr',
    flag_emoji: '🇹🇷',
    continent_en: 'Asia'
  },
  {
    name_en: 'Turkmenistan',
    name_es: 'Turkmenistán',
    dial_code: '+993',
    code_2: 'TM',
    code_3: 'TKM',
    tld: '.tm',
    flag_emoji: '🇹🇲',
    continent_en: 'Asia'
  },
  {
    name_en: 'Tuvalu',
    name_es: 'Tuvalu',
    dial_code: '+688',
    code_2: 'TV',
    code_3: 'TUV',
    tld: '.tv',
    flag_emoji: '🇹🇻',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Uganda',
    name_es: 'Uganda',
    dial_code: '+256',
    code_2: 'UG',
    code_3: 'UGA',
    tld: '.ug',
    flag_emoji: '🇺🇬',
    continent_en: 'Africa'
  },
  {
    name_en: 'Ukraine',
    name_es: 'Ucrania',
    dial_code: '+380',
    code_2: 'UA',
    code_3: 'UKR',
    tld: '.ua',
    flag_emoji: '🇺🇦',
    continent_en: 'Europe'
  },
  {
    name_en: 'United Arab Emirates',
    name_es: 'Emiratos Árabes Unidos',
    dial_code: '+971',
    code_2: 'AE',
    code_3: 'ARE',
    tld: '.ae',
    flag_emoji: '🇦🇪',
    continent_en: 'Asia'
  },
  {
    name_en: 'United Kingdom',
    name_es: 'Reino Unido',
    dial_code: '+44',
    code_2: 'GB',
    code_3: 'GBR',
    tld: '.uk',
    flag_emoji: '🇬🇧',
    continent_en: 'Europe'
  },
  {
    name_en: 'Uzbekistan',
    name_es: 'Uzbekistán',
    dial_code: '+998',
    code_2: 'UZ',
    code_3: 'UZB',
    tld: '.uz',
    flag_emoji: '🇺🇿',
    continent_en: 'Asia'
  },
  {
    name_en: 'Vanuatu',
    name_es: 'Vanuatu',
    dial_code: '+678',
    code_2: 'VU',
    code_3: 'VUT',
    tld: '.vu',
    flag_emoji: '🇻🇺',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Vatican City State',
    name_es: 'Ciudad del Vaticano',
    dial_code: '+379',
    code_2: 'VA',
    code_3: 'VAT',
    tld: '.va',
    flag_emoji: '🇻🇦',
    continent_en: 'Europe'
  },
  {
    name_en: 'Vietnam',
    name_es: 'Vietnam',
    dial_code: '+84',
    code_2: 'VN',
    code_3: 'VNM',
    tld: '.vn',
    flag_emoji: '🇻🇳',
    continent_en: 'Asia'
  },
  {
    name_en: 'Wallis and Futuna',
    name_es: 'Wallis y Futuna',
    dial_code: '+681',
    code_2: 'WF',
    code_3: 'WLF',
    tld: '.wf',
    flag_emoji: '🇼🇫',
    continent_en: 'Oceania'
  },
  {
    name_en: 'Western Sahara',
    name_es: 'Sahara Occidental',
    dial_code: '+212',
    code_2: 'EH',
    code_3: 'ESH',
    tld: '.eh',
    flag_emoji: '🇪🇭',
    continent_en: 'Africa'
  },
  {
    name_en: 'Yemen',
    name_es: 'Yemen',
    dial_code: '+967',
    code_2: 'YE',
    code_3: 'YEM',
    tld: '.ye',
    flag_emoji: '🇾🇪',
    continent_en: 'Asia'
  },
  {
    name_en: 'Zambia',
    name_es: 'Zambia',
    dial_code: '+260',
    code_2: 'ZM',
    code_3: 'ZMB',
    tld: '.zm',
    flag_emoji: '🇿🇲',
    continent_en: 'Africa'
  },
  {
    name_en: 'Zimbabwe',
    name_es: 'Zimbabue',
    dial_code: '+263',
    code_2: 'ZW',
    code_3: 'ZWE',
    tld: '.zw',
    flag_emoji: '🇿🇼',
    continent_en: 'Africa'
  }
];
