import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ModalSuccessDetailInvestment,
  DetailInvestmentInfo,
  DetailInvestmentAdvices
} from './successDetailInvestment.styles';

export default function SuccessDetailInvestment(props) {
  const [successDetailInvestment, setSuccessDetailInvestment] = useState(
    props.showModalSuccessDetailInvestment
  );

  useEffect(() => {
    setSuccessDetailInvestment(props.showModalSuccessDetailInvestment);
  }, [props.showModalSuccessDetailInvestment]);

  const closeModal = () => setSuccessDetailInvestment(false);

  return (
    <ModalSuccessDetailInvestment showModalSuccessDetailInvestment={successDetailInvestment}>
      <div>
        <div>
          <h3>Detalle de tu inversión</h3>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </div>
        <DetailInvestmentAdvices>
          <div>
            <figure>
              <img
                src="https://finsphera-resources.s3.amazonaws.com/iconoSuccessBig.png"
                alt="icono de éxito"
              />
            </figure>
            <p>
              Hemos recibido con éxito los detalles de tu inversión. Haremos el retiro de $1,000 de
              tu Cuenta Virtual para esta inversión.
            </p>
          </div>
          <div>
            <p>
              Una vez realizada la inversion, te enviaremos a tu correo electronico la confirmación.
              Puedes contactarnos en nuestro correo:{' '}
              <Link to="/">atencionaclientes@finsphera.com.mx</Link> o por WhatsApp al{' '}
              <Link to="/">55 6146 9604</Link>.
            </p>
          </div>
        </DetailInvestmentAdvices>
        <DetailInvestmentInfo>
          <h2>Detalles de la inversión:</h2>
          <div>
            <div>
              <h3>Amortización</h3>
              <h3>Capital</h3>
              <h3>Interés</h3>
              <h3>Costo cobranza</h3>
              <h3>Monto a recibir</h3>
              <h3>Fecha vencimiento</h3>
            </div>
            <div>
              <p>15.0%</p>
              <p>$1,000</p>
              <p>$36.67</p>
              <p>$6.81</p>
              <p>$1,029.86</p>
              <p>18/05/2021</p>
            </div>
          </div>
          <Link to="/inversionista/dashboard/detalle-de-compañia">Cerrar</Link>
        </DetailInvestmentInfo>
      </div>
    </ModalSuccessDetailInvestment>
  );
}
