import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { ModalRegisterCLABE, RegisterCLABEForm, Success } from './registerCLABE.styles';
import banks from 'src/constants/banks';
import { createBankAccount } from 'src/store/User/user.slice';
import getCurrentDate from 'src/functions/getCurrentDate';
import axios from 'axios';
import { getInvestor_Profile } from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { url } from 'src/environments';
import createNumber from 'src/functions/createNumber';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';

export default function RegisterCLABE({ showModalRegisterCLABE, closeModal }) {
  const [code, setCode] = useState('');
  const [step, setStep] = useState(1);
  const [successText, setSuccessText] = useState(false);
  const [selectedBank, setSelectedBank] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [selectedBankCode, setSelectedBankCode] = useState('');
  const [data, setData] = useState({
    identifier: '',
    registerCLABE: ''
  });
  const companyName = useSelector(
    (state) =>
      state.profile.personalInformation?.data?.getInvestor?.companyInformation?.companyName
        ?.legalName
  );
  //Para validar teléfono MFA
  const [mobileInfo, setMobileInfo] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [verificationCode] = useState('');
  //Campos para stp validación
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [beneficiaryRFC, setBeneficiaryRFC] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getInvestor_Profile,
          variables: { id: user.id }
          // variables: { id: user[idKey] }
        });
        setUserInfo(data);
        setMobileInfo(data.getInvestor.personalInformation.phone.mobile);
        if (data.getInvestor.typeOfPerson === 'FISICA') {
          setBeneficiaryName(data.getInvestor.personalInformation.fullName);
          setBeneficiaryRFC(data.getInvestor.personalInformation.RFC);
        } else {
          setBeneficiaryName(data.getInvestor.companyInformation.companyName);
          setBeneficiaryRFC(data.getInvestor.companyInformation.RFC);
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, []);

  useEffect(() => {
    if (showModalRegisterCLABE) {
      setCode('');
      setStep(1);
      setSuccessText(false);
      setSelectedBank(null);
      setData({
        identifier: '',
        registerCLABE: ''
      });
      setCheckboxes({
        isMainAccount: false
      });
    }
  }, [showModalRegisterCLABE]);

  const [checkboxes, setCheckboxes] = useState({
    isMainAccount: false
  });
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const isMoral = user.typeOfPerson === 'MORAL';
  const handleBankChange = (event) => {
    const selectedBank = banks.find((bank) => bank.name === event.target.value);
    setSelectedBank(event.target.value);
    if (selectedBank) {
      setSelectedBankCode(selectedBank.code);
    }
  };
  const handleChecked = (e) => {
    setCheckboxes({
      ...checkboxes,
      [e.target.name]: e.target.checked
    });
  };
  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value
    });
  };

  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  //Función para enviar el código MFA al celular previamente validado
  const handleAccount = async (event) => {
    event.preventDefault();
    //Función para enviar código al celular
    const mobile = mobileInfo;
    const input = {
      id: user.id,
      typeOfUser: 'investor'
    };

    const phone = await axios.post(`${url}/admin/mobile/${mobile}/get-otp`, input);
    const parsedBody = JSON.parse(phone.data.body);
    const referenceId = parsedBody.ReferenceId;
    setReferenceId(referenceId);
    setStep(2);
  };
  const finspheraAccount = process.env.REACT_APP_STPACCOUNT_FINSPHERA;
  const clienteOrdenante = process.env.REACT_APP_STP_ACUSECEP_FINSPHERA;
  //Función para validar el código de MFA, para crear la cuenta bancaria en base de datos y para validar con stp
  const handleVerifyCode = async (event) => {
    event.preventDefault();
    setLocalLoading(true);
    let idKey = user.groupName === 'investor' ? 'investorId' : 'applicantId';
    const createInput = {
      [idKey]: user.id,
      identifier: data.identifier,
      CLABE: data.registerCLABE,
      bankName: selectedBank,
      bankCode: selectedBankCode,
      isMainAccount: checkboxes.isMainAccount,
      isMFA: true,
      createdAt: getCurrentDate(),
      updatedAt: getCurrentDate()
    };
    const mobile = mobileInfo;
    const body = {
      id: user.id,
      otp: verificationCode,
      referenceId: referenceId,
      typeOfUser: 'investor'
    };
    const stp = {
      clienteId: user.id,
      tipoCliente: 'investor',
      conceptoPago: 'AcuseCep',
      //cuentaBeneficiario: '646180132700000002',
      cuentaBeneficiario: data.registerCLABE,
      institucionContraparte: '90646',
      //institucionContraparte: String(selectedBankCode),
      monto: '0.01',
      nombreBeneficiario: 'ND',
      //nombreBeneficiario: beneficiaryName,
      referenciaNumerica: createNumber(),
      nombreOrdenante: 'SUSTAINABLE',
      rfcCurpBeneficiario: beneficiaryRFC,
      rfcCurpOrdenante: 'STE210701CY8',
      cuentaOrdenante: String(finspheraAccount),
      clienteOrdenanteId: String(clienteOrdenante)
    };
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registra-orden`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: stp
    };
    try {
      // Validate OTP
      const response = await axios.post(`${url}/admin/mobile/${mobile}/validate-otp`, body);
      const parsedBody = JSON.parse(response.data.body);
      const codeResponse = parsedBody.isVerified;

      if (codeResponse === true) {
        // If OTP validation is successful, proceed to make the STP request
        const stpResp = await axios(config);
        const stpId = stpResp.data?.id;
        const isStpSuccess = typeof stpId === 'number' && stpId.toString().length > 6;
        if (isStpSuccess) {
          dispatch(createBankAccount(createInput));
          setLocalLoading(false);
          setSuccessText(true);
        } else {
          console.error('Error:', stpResp);
          setLocalLoading(false);
        }
      } else {
        console.error('OTP validation failed:', parsedBody);
        setLocalLoading(false);
      }
    } catch (error) {
      console.error('catch error:', error);
      setLocalLoading(false);
    }
  };

  return (
    <ModalRegisterCLABE showModalRegisterCLABE={showModalRegisterCLABE}>
      {!successText ? (
        <div>
          <div>
            <h3>Dar de alta una nueva CLABE</h3>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </div>
          <RegisterCLABEForm>
            {isMoral ? (
              <h3>
                Asegurate de dar de alta la CLABE interbancaria de tu compañía{' '}
                <strong>{companyName}</strong>:
              </h3>
            ) : (
              <></>
            )}
            <h3>Nombre o descripción</h3>
            <input type="text" name="identifier" onChange={handleInputChange} />
            <h3>Nº de CLABE interbancaria</h3>
            <input type="text" name="registerCLABE" onChange={handleInputChange} maxLength={18} />
            <p>La CLABE debe de contener 18 caracteres.</p>
            <h3>Selecciona el banco</h3>
            <select name="banks" onChange={handleBankChange}>
              <option value={undefined}>Seleccionar una opción</option>
              {banks.map((eAG, i) => (
                <option key={i} value={eAG.name}>
                  {eAG.name}
                </option>
              ))}
            </select>
            {!isMoral ? (
              <div>
                <h3>Quiero que esta cuenta sea mi cuenta principal</h3>
                <input
                  type="checkbox"
                  name="isMainAccount"
                  onChange={handleChecked}
                  id="isMainAccount"
                />
              </div>
            ) : null}

            {step === 2 && (
              <>
                <h3>Ingresa el código de verificación</h3>
                <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />
              </>
            )}
          </RegisterCLABEForm>
          <div>
            <Button size="small" onClick={closeModal}>
              Cerrar
            </Button>
            {step === 1 && (
              <Button size="small" onClick={handleAccount}>
                Confirmar alta de CLABE
              </Button>
            )}
            {step === 2 && (
              <Button size="small" isLoading={localLoading} onClick={handleVerifyCode}>
                Confirmar código
              </Button>
            )}
          </div>
        </div>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="ícono de operación exitosa"
            />
          </figure>
          <h2>Tu cuenta ha sido dada de alta de forma exitosa</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalRegisterCLABE>
  );
}
