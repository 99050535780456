import styled from 'styled-components/macro';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import {
  brandBlack,
  brandYellowgreen,
  grayDarkSphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const EsgFormContainer = styled.div`
  > Button {
    width: 25%;
    margin: 0 0 5% 70%;
  }
`;

export const EsgInformationForm = styled.div`
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5%;

    > p {
      color: ${brandBlack};
      font-size: 1.2vw;
      width: 68%;

      > span {
        font-weight: bold;
      }
    }

    > div {
      display: flex;
      > input {
        font-size: 1.2vw;
        width: 70px;
        height: 40px;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > p {
        > span {
        }
      }

      > div {
        > input {
        }
      }
    }
  }
  @media (max-width: 600px) {
    > div {
      > p {
        text-align: justify;
        font-size: 4vw;
        width: 68%;
      }
      > div {
        margin-right: 8%;

        > input {
          font-size: 4.2vw;
          width: 40px;
          height: 30px;
        }
      }
    }
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: ${whiteSphera};
  padding: 0 4%;
  > figure {
    width: 50%;
    height: 350px;
    > img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    color: ${brandBlack};
    font-size: 2.5vw;
    width: 40%;
    margin: 2% 10% 2% 0;
    > p {
      color: ${greenSp};
      font-size: 2.5vw;
      width: 50%;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure {
      > img {
      }
    }
    > h2 {
      font-size: 4vw;
      width: 50%;
      > p {
        font-size: 4vw;
        width: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
    padding: 2.5% 0 2.5% 0%;
    > figure {
      > img {
      }
    }
    > h2 {
      font-size: 6vw;
      width: 100%;
      margin-right: 0;
      margin-bottom: 5%;
      text-align: center;
      > p {
        text-align: center;
        font-size: 6vw;
        width: 100%;
      }
    }
  }
`;

export const EsgInformation = styled.div`
  padding: 0 4% 2% 4%;
  background-color: ${whiteSphera};
  border-radius: 10px;
  > h4 {
    color: ${grayDarkSphera};
    font-size: 1.2vw;
    text-align: left;
    width: 80%;
    margin: 0 0 2% 10%;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h4 {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    > h4 {
      font-size: 2.5vw;
    }
  }
`;

export const EsgInformationTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2%;
  padding: 0 5% 1% 5%;
  background-color: ${brandYellowgreen};
  > h1 {
    margin: 2% 0;
    color: ${brandBlack};
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 2vw;
  }
  > h3 {
    padding: 0 12% 2% 12%;
    color: ${brandBlack};
    font-size: 1.2vw;
    text-align: center;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h1 {
      font-size: 2.5vw;
    }
    > h3 {
      font-size: 1.8vw;
    }
  }

  @media (max-width: 600px) {
    > h1 {
      font-size: 4.2vw;
    }
    > h3 {
      font-size: 3.5vw;
    }
  }
`;
