import { useState, useEffect } from 'react';
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { url } from 'src/environments';
import { useNavigate } from 'react-router-dom';
import ContractsSign from 'src/components/shared/modals/contractsSign/contractsSign';
import { getMifiel_Profile } from 'src/graphql/customized_queries';
import {
  Contracts,
  ContractNull,
  StyledTable,
  FilterButtons,
  ContractSection
} from './contractInfo.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export default function ContractsInfo({ personalInformation }) {
  const [dataMifiel, setDataMifiel] = useState([]);
  const [filter, setFilter] = useState('all');
  const user = useSelector((state) => state.user.currentUser);
  const [contracts, setContracts] = useState([]);
  const statusRisk = personalInformation?.data?.getApplicant?.isContractSigned;
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserData = async () => {
      if (user.id) {
        try {
          const { data } = await API.graphql({
            query: getMifiel_Profile,
            variables: { applicantId: user.id }
          });
          setDataMifiel(data?.mifielsByApplicantId?.items);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchUserData();
  }, [user.id]);

  const fetchUrl = async () => {
    try {
      const responses = await Promise.all([
        axios.post(`${url}/admin/documents/get-presignedurl`, {
          type: 'credit',
          Key: `${user.id}/contracts/`
        })
      ]);
      const contractsData = responses.map((response) => {
        const preSignedURL = JSON.parse(response.data.body);
        return {
          url: preSignedURL,
          name: 'Contrato de crédito',
          isSigned: statusRisk
        };
      });
      setContracts(contractsData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  function formatDateToMMDDYY(date) {
    const d = new Date(date);
    const year = d.getFullYear().toString();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const hasMifielData = dataMifiel.some((item) => item.signers && item.signatures);
  const combinedData =
    dataMifiel?.reduce((acc, mifielItem) => {
      const campaign = personalInformation.data.getApplicant.campaigns.items.find(
        (campaignItem) => campaignItem.id === mifielItem.campaignId
      );
      if (!campaign) {
        return acc;
      }
      const mifielSignersData = mifielItem.signers.map((signer) => {
        const signature = mifielItem.signatures.find((s) => s.id === signer.id) || {};
        return {
          campaignId: mifielItem.campaignId,
          campaignName: campaign.nombreCampania,
          collectedAmount: campaign.collectedAmount,
          createdAt: mifielItem.createdAt,
          widgetId: signer.widget_id,
          signerName: signature.name,
          signed: signature.signed,
          rfc: signature.tax_id
        };
      });
      return [...acc, ...mifielSignersData];
    }, []) || [];
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };
  const filteredData = combinedData.filter((item) => {
    if (filter === 'signed') return item.signed;
    if (filter === 'unsigned') return !item.signed;
    return true; // For 'all' filter
  });

  const formatMoney = (money) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Number(money));
  };

  const handleSign = (widgetId) => {
    navigate(`/solicitante/dashboard/miFiel/${widgetId}`);
  };
  return (
    <Contracts>
      <ContractsSign showModal={showModal} setShowModal={setShowModal} />
      <FilterButtons>
        <h2>Pagarés:</h2>
        <Button size="small" onClick={() => handleFilterChange('all')}>
          Todos
        </Button>
        <Button size="small" onClick={() => handleFilterChange('signed')}>
          Firmados
        </Button>
        <Button size="small" onClick={() => handleFilterChange('unsigned')}>
          No Firmados
        </Button>
      </FilterButtons>
      {hasMifielData && combinedData.length > 0 ? (
        <StyledTable>
          <table>
            <thead>
              <tr>
                <th>Campaña</th>
                <th>Monto recolectado</th>
                <th>Fecha de creación</th>
                <th>Nombre Firmante</th>
                <th>RFC</th>
                <th>Firmado</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((item, index) => (
                <tr key={index}>
                  <td>{item.campaignName || '-'}</td>
                  <td>$ {formatMoney(item.collectedAmount || '-')}</td>
                  <td>{formatDateToMMDDYY(item.createdAt)}</td>
                  <td>{item.signerName}</td>
                  <td>{item.rfc}</td>
                  <td>{item.signed ? 'Sí' : 'No'}</td>
                  <td>
                    <Button
                      size="small"
                      isFullWidth
                      disabled={item.signed}
                      onClick={() => {
                        handleSign(item.widgetId);
                      }}
                    >
                      {item.signed ? 'Firmado' : 'Firmar'}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledTable>
      ) : (
        <ContractNull>
          <h1>Aún no tienes pagarés</h1>
        </ContractNull>
      )}
      <ContractSection>
        <h2>Contratos:</h2>
        <StyledTable>
          <table>
            <thead>
              <tr>
                <th>Contrato</th>
                <th>Documento</th>
                <th>Firmado</th>
                <th>Firmar</th>
              </tr>
            </thead>
            <tbody>
              {contracts.map((contract, index) => (
                <tr key={index}>
                  <td>{contract.name}</td>
                  <td>
                    <a href={contract.url} target="_blank" rel="noopener noreferrer">
                      Ver documento
                    </a>
                  </td>
                  <td>{contract.isSigned ? 'Sí' : 'No'}</td>
                  <td>
                    {' '}
                    {!contract.isSigned && (
                      <Button size="small" onClick={() => setShowModal(true)}>
                        Firmar
                      </Button>
                    )}
                    {contract.isSigned && '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </StyledTable>
      </ContractSection>
    </Contracts>
  );
}
