import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Success, Figure, ActionSection, ModalResources } from './resourcesProvider.styles';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { url } from 'src/environments';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Card } from 'src/utilities/UILibrary';
import { Link, useNavigate } from 'react-router-dom';
import { setAppError } from 'src/store/app/app.slice';
import axios from 'axios';
import { mutateCreateResourceProvider } from 'src/requests';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { FormValues, ResourcesProviderProps } from './resourcesProvider.types';
import { updateOnboardingBlockingStatus } from 'src/store/User/user.slice';

const Resources: React.FC<ResourcesProviderProps> = ({
  resourcesModal,
  setResourcesModal,
  resourcesId
}) => {
  const props = { setResourcesModal, resourcesId };
  const [successText, setSuccessText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.currentUser);
  const onboarding = user.onboarding;
  const userType = user.groupName;
  const dispatch = useAppThunkDispatch();
  const { control, handleSubmit } = useForm<FormValues>();

  const closeModal = async () => {
    setResourcesModal(false);
    await dispatch(
      updateOnboardingBlockingStatus({
        type: 'investor',
        input: {
          id: user.id,
          onboarding: {
            isBlocked: true,
            reasonOfBlocking: 'resourceProviderOrRealOwner',
            currentStep: onboarding.currentStep
          }
        }
      })
    );

    return navigate('/pagina-validacion');
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setLocalLoading(true);
    let resources;
    if (userType === 'investor') {
      resources = {
        id: resourcesId,
        investorId: user.id,
        personalInformation: {
          email: data.email
        }
      };
    } else {
      resources = {
        id: resourcesId,
        applicantId: user.id,
        personalInformation: {
          email: data.email
        }
      };
    }
    const body = {
      id: resourcesId,
      typeOfUser: 'resourcesProvider'
    };
    try {
      await mutateCreateResourceProvider(resources);
      const response = await axios.post(`${url}/admin/email/user/mati-direct-link`, body);
      const parsedBody = JSON.parse(response.data.body);
      if (parsedBody.result) {
        setSuccessText(true);
      } else {
        setAppError({ errorCode: 'Error al enviar url al proveedor de recursos' });
      }
    } catch (error) {
      setAppError({ errorCode: 'Error al recibir respuesta' });
    } finally {
      setLocalLoading(false);
    }
  };
  return (
    <ModalResources resourcesModal={resourcesModal} {...props}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="ícono de correo electrónico"
            />
          </figure>
          <h2>
            ¡Tu solicitud ha sido enviada de forma exitosa! En breve nos estaremos comunicando
            contigo
          </h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <ActionSection>
            <Card isForm onSubmit={handleSubmit(onSubmit)}>
              <Figure>
                <div></div>
                <figure onClick={closeModal}>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                    alt="ícono para cerrar la ventana"
                  />
                </figure>
              </Figure>
              <h3>
                Haz seleccionado que una persona diferente a la empresa que representas será el
                proveedor de recursos o propietario real, por lo para continuar con tu proceso, te
                solicitaremos el correo electrónico de dicha persona para enviarle una liga con la
                que podrá registrar sus datos biométricos:
              </h3>
              <Card.Row>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={undefined}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field, fieldState, ...rest }) => (
                    <InputField
                      onChangeInternalValue={field.onChange}
                      placeholder="Correo electrónico"
                      minLength={10}
                      required
                    />
                  )}
                />
              </Card.Row>
              <Card.Row>
                <h4>
                  Adicional a ello deberá enviarnos los siguientes documentos al correo electrónico:{' '}
                  <Link to="mailto:atencionaclientes@finsphera.com">
                    {' '}
                    atencionaclientes@finsphera.com
                  </Link>{' '}
                </h4>
                <ul>
                  <li> Acta constitutiva</li>
                  <li> Poder del representante</li>
                  <li> Constancia de FIEL</li>
                  <li> RFC</li>
                  <li> Comprobante de domicilio</li>
                </ul>
              </Card.Row>
              <Card.Row>
                <p>
                  Recuerda que mientras más rápido nos proporciones la información solicitada, más
                  rápido podrás continuar con tu proceso de registro.
                </p>
              </Card.Row>
              <Button
                onClick={handleSubmit(onSubmit)}
                isLoading={localLoading}
                isFullWidth
                size="small"
                type="submit"
              >
                Aceptar
              </Button>
            </Card>
          </ActionSection>
        </div>
      )}
    </ModalResources>
  );
};

export default Resources;
