import styled from 'styled-components/macro';

import {
  whiteSphera,
  grayDarkSphera,
  blackSphera,
  greenSp,
  brandBlack
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const InvestSimulatorContainer = styled.section`
  width: 45%;
  margin: 0 2.5%;
  padding: 1% 2%;
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    padding-top: 5%;
    width: 90%;
    margin: 0 5%;
  }
`;

export const SimulatorInput = styled.div`
  width: 100%;
  margin: 2% 0;
  padding: 2% 0;
  background-color: ${blackSphera};
  > h3 {
    font-size: ${uIFontSize.large};
    color: ${whiteSphera};
    text-align: center;
    margin-bottom: 3%;
  }
  > div {
    > input {
      color: ${brandBlack};
      font-size: ${uIFontSize.medium};
      width: 40%;
      margin: 0 30%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${brandBlack};
      border-radius: 5px;
      text-align: center;
    }
    > div {
      display: flex;
      justify-content: space-between;
      padding: 2.5% 5%;
      > h4 {
        font-size: ${uIFontSize.medium};
        color: ${whiteSphera};
      }
      > p {
        font-size: ${uIFontSize.medium};
        color: ${whiteSphera};
      }
    }

    > hr {
      border: 1px solid ${whiteSphera};
    }

    > div:last-child {
      background-color: ${greenSp};

      > h4 {
        color: ${blackSphera};
      }

      > p {
        color: ${blackSphera};
      }
    }
  }

  > div:nth-child(3) {
    margin-top: 5%;
    margin-bottom: 2.5%;

    > p {
      font-size: 1.5vw;
      font-weight: bold;
      color: ${whiteSphera};
      text-align: center;
    }
  }

  > button:nth-child(4) {
    color: ${blackSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    width: 40%;
    padding: 1% 0;
    border: none;
    border-radius: 5px;
    background-color: ${whiteSphera};
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-bottom: 10%;

    > div:nth-child(3) {
      margin-top: 5%;
      margin-bottom: 10%;
    }

    > button:nth-child(4) {
      font-size: 4.5vw;
      width: 100%;
      margin-left: 0;
      padding-top: 4%;
      padding-bottom: 4%;

      &:hover {
      }
    }
  }
`;

export const SimulatorResult = styled.div`
  width: 100%;
  margin: 2% 0;
  padding: 2% 0;
  background-color: ${blackSphera};
  > h3 {
    font-size: ${uIFontSize.large};
    color: ${whiteSphera};
    text-align: center;
    margin-bottom: 5%;
  }

  > div:nth-child(2) {
    > div {
      display: flex;
      justify-content: space-between;
      padding: 2.5% 5%;

      > h4 {
        font-size: ${uIFontSize.medium};
        font-weight: normal;
        color: ${whiteSphera};
      }

      > p {
        font-size: ${uIFontSize.medium};
        font-weight: bold;
        color: ${whiteSphera};
      }
    }

    > hr {
      border: 0.5px solid ${whiteSphera};
    }
  }

  > div:nth-child(3) {
    margin-top: 5%;
    margin-bottom: 10%;

    > p {
      font-size: ${uIFontSize.medium};
      font-weight: bold;
      color: ${whiteSphera};
      text-align: center;
    }
  }
  > button {
    background-color: ${whiteSphera};
    color: ${grayDarkSphera};
    width: 40%;
    margin-left: 30%;
  }

  @media (max-width: 600px) {
    width: 100%;
    > div:nth-child(3) {
      margin-top: 5%;
      margin-bottom: 10%;

      > button {
        width: 80%;
        padding: 2% 0;
        margin: 0 10%;
      }
    }
  }
`;

export const Investmentexceeded = styled.div`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  > p {
    color: ${whiteSphera};
    padding: 0 3%;
    font-weight: bold;
    text-align: center;
    font-size: ${uIFontSize.medium};
  }
  > button {
    border: 1px solid ${greenSp};
    margin: 2% 0;
  }
`;
