import { NavbarContainer } from './styles';
import { forwardRef, useEffect, useState } from 'react';
import { NavbarProps } from './types';
import { Link } from 'react-router-dom';
import { LogoBlack } from 'src/assets/icons/brand';
import { useViewMobile } from 'src/hooks/useViewMobile';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { Button } from 'src/utilities/UILibrary2024/components';
import { Squash as Hamburger } from 'hamburger-react';
import { NavMenuList } from './NavMenuList';

// Todo: add correct links
const buttonString = {
  isSigned: 'Dashboard',
  isNotSigned: 'Iniciar sesión'
};

export const Navbar = forwardRef<HTMLElement, NavbarProps>((props, ref) => {
  const { isSigned, dynamicHide = false, ...restProps } = props;
  const isMobile = useViewMobile(parseInt(breakpoints.tablet));
  const [showMenuList, setShowMenuList] = useState(false);
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window === 'undefined') return;

    const shouldShow = window.scrollY <= lastScrollY;
    setShow(shouldShow);
    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && dynamicHide) {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'auto';
      setShowMenuList(false);
    }
  }, [isMobile]);

  const onChangeMenuList = () => {
    document.body.style.overflow = showMenuList ? 'auto' : 'hidden';
    return setShowMenuList(!showMenuList);
  };

  const NonSignedInNavItems = () => (
    <>
      <li>
        <Link to="/inversionista/registrate">Invierte</Link>
      </li>
      <li>
        <Link to="/solicitante/registrate">Solicita crédito</Link>
      </li>
      <li>
        <Link to="/quienes-somos">¿Quiénes somos?</Link>
      </li>
    </>
  );

  const renderNavItems = () => NonSignedInNavItems();

  return (
    <NavbarContainer show={show} ref={ref} {...restProps}>
      <div className="navbar-left">
        <Link to={'/'}>
          <LogoBlack className={'logo'} />
        </Link>
      </div>
      <div className="navbar-right">
        {!isMobile ? <ul>{renderNavItems()}</ul> : null}
        <Button size={isMobile ? 'small' : 'medium'} variant="border-orange">
          {isSigned ? buttonString.isSigned : buttonString.isNotSigned}
        </Button>
        {isMobile ? (
          <>
            <Hamburger toggled={showMenuList} toggle={onChangeMenuList} distance="sm" size={24} />
            <NavMenuList
              showMenuList={showMenuList}
              callback={onChangeMenuList}
              renderItems={renderNavItems}
            />{' '}
          </>
        ) : null}
      </div>
    </NavbarContainer>
  );
});

Navbar.displayName = 'Navbar';
