import { Link } from 'react-router-dom';
import { FooterContainer, LogoCont, LastPartFooter, MediaCont } from './footer.styles';
import { environment } from 'src/environments';
import { signInSelector } from 'src/store/User/user.slice';
import { useSelector } from 'react-redux';

const Footer = () => {
  const isSignedIn = useSelector(signInSelector);

  return (
    <FooterContainer>
      <h2>Legal</h2>
      <p>
        En términos de la Ley para Regular las Instituciones de Tecnología Financiera (la “LRITF”),
        se hace saber que: Sustaintable Tech ESG, S.AP.I. de C.V. (“FinSphera”) es una sociedad
        autorizada para organizarse y operar como Institución de Financiamiento Colectivo de Deuda
        por la Comisión Nacional Bancaria y de Valores, por lo que se encuentra autorizada, regulada
        y supervisada por las Autoridades Financieras; ni el Gobierno Federal ni las entidades de la
        Administración Pública Paraestatal podrán: (i) responsabilizarse o garantizar los recursos
        que los Usuarios de FinSphera inviertan a través de nuestra plataforma; y (ii) asumir alguna
        responsabilidad por las obligaciones contraídas por FinSphera o por algún Usuario de
        FinSphera frente a otro, en virtud de la celebración de las Operaciones. Para efectos del
        presente párrafo, se entenderá por Autoridades Financieras, Usuarios y Operaciones, los
        términos así definidos por la Ley para Regular las Instituciones de Tecnología Financiera.
        En términos de la Ley de Protección y Defensa al Usuario de Servicios Financieros, se hace
        saber que: (i) el programa de educación financiera de Finsphera se encuentra publicado en{' '}
        <Link to="/">www.finshpera.com</Link>; y (ii) la Unidad Especializada de Atención a Usuarios
        de FinSphera se encuentra ubicada en Montecito 38 Piso 16 Oficina 28, Colonia Nápoles,
        Alcaldía de Benito Juárez, Código Postal 03810, Ciudad de México con teléfono 56 3959 3368,
        correo electrónico
        <Link to="mailto:atencionaclientes@finsphera.com"> atencionaclientes@finsphera.com</Link>,
        página en internet <Link to="/">www.finsphera.com</Link>.<span> Titular de la Unidad:</span>{' '}
        Javier Olivera Hernández. <span>Horario de atención: </span>
        Lunes a Viernes de 9 am a 2 pm y de 3 pm a 5 pm. El Usuario podrá ponerse en contacto con la
        Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros (la
        “CONDUSEF”) mediante el sitio web www.condusef.gob.mx, teléfono (55) 5340 0999 o 800 999
        8080, o por correo electrónico: asesoria@condusef.gob.mx.
      </p>
      <p>
        Puedes consultar los Términos y Condiciones de FinSphera en
        <Link to="/terminos-y-condiciones">
          {' '}
          https://www.finsphera.com/terminos-y-condiciones
        </Link>{' '}
        y nuestro Aviso de Privacidad en:{' '}
        <Link to="/aviso-de-privacidad">https://www.finsphera.com/aviso-de-privacidad</Link>. Todas
        las operaciones son en Pesos Mexicanos y los servicios prestados solo están disponibles en
        México. Todos los derechos reservados®.
      </p>
      {/* Anonymous and compliance Links */}
      {environment === 'dev' ? (
        <>
          <p>
            <Link
              to="https://www.gob.mx/cnbv"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              CNBV (Comisión Nacional Bancaria y de Valores)
            </Link>
          </p>
          <p>
            <Link to="/buzon-anonimo" className="underline">
              Buzón de Denuncias - Buzón Anónimo
            </Link>
          </p>
          <p>
            <Link to={isSignedIn ? '/quejas-cobranza' : '/iniciar-sesion'}>
              ¿Quieres generar una queja de los despachos de cobranza de FinSphera? Da click aquí
            </Link>
          </p>
        </>
      ) : null}
      <LastPartFooter>
        <h3>Síguenos en:</h3>
        <MediaCont>
          <figure>
            <Link
              to="https://www.facebook.com/FinSphera-101778232177736"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_facebook.png"
                alt="FinSphera Facebook"
              />
            </Link>
          </figure>
          <figure>
            <Link to="https://twitter.com/FinSphera" target="_blank" rel="noreferrer">
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_twitter.png"
                alt="FinSphera Twitter"
              />
            </Link>
          </figure>
          <figure>
            <Link to="https://www.linkedin.com/company/finsphera" target="_blank" rel="noreferrer">
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_linkedin.png"
                alt="FinSphera LinkedIn"
              />
            </Link>
          </figure>
          <figure>
            <Link to="https://www.instagram.com/finsphera/" target="_blank" rel="noreferrer">
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_instagram.png"
                alt="FinSphera Instagram"
              />
            </Link>
          </figure>
          <figure>
            <Link
              to="https://www.youtube.com/channel/UCqBUEbrVmyKit-rCM3akrPQ"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_youtube.png"
                alt="FinSphera Youtube"
              />
            </Link>
          </figure>
          <figure>
            <Link
              to="https://open.spotify.com/user/316pjhuquaww2o33flzrhowjkdoa?si=F-pAWyxCQWa8i48liPRdVw&utm_source=whatsapp"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_spotify.png"
                alt="FinSphera Youtube"
              />
            </Link>
          </figure>
        </MediaCont>
        <LogoCont>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_footer_logo_finsphera.png"
            alt="logotipo FinSphera"
          />
        </LogoCont>
      </LastPartFooter>
    </FooterContainer>
  );
};

export default Footer;
