import styled from 'styled-components';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

export const WaitlistModalWrapper = styled(ComposedModal)`
  .wait-list-form-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: initial;

    .radio-container {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 0.5rem;
    }

    .radio-group {
      display: flex;
      flex-direction: row;
      gap: 1rem;

      input {
        margin-right: 0.5rem;
      }
    }
  }
`;
