import { useSelector } from 'react-redux';
import {
  AccountContainer,
  AccountInfo,
  CodeSection,
  Entries,
  FiltersSection,
  FirstAction,
  FirstValidation,
  StyledTable
} from './AccountState.style';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useState, useEffect, SetStateAction } from 'react';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import axios from 'axios';
import { RootState } from 'src/store/store';
import { url } from 'src/environments';
import getCurrentDate from 'src/functions/getCurrentDate';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { AccountStateApplicantProps, FilterData } from './AccountState.types';

export default function AccountState({ userId, createdAt }: AccountStateApplicantProps) {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const [data, setData] = useState<FilterData[]>([]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState<string>('');
  const today = getCurrentDate().split('T')[0];
  const [localLoading, setLocalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [period, setPeriod] = useState<string>('0');
  const [periodOptions, setPeriodOptions] = useState<{ value: string; label: string }[]>([]);
  const [modalType, setModalType] = useState<'error' | 'success' | 'warning'>('success');
  const [isAccountStateButtonDisabled, setIsAccountStateButtonDisabled] = useState(true);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [totals, setTotals] = useState({ abono: 0, cargo: 0 });

  const handleActionClick = (action: SetStateAction<string>) => {
    setSelectedAction(action);
    setStartDate('');
    setEndDate('');
    setPeriod('');
    setOtp('');
    setShowInput(false);
    setLocalLoading(false);
    setErrorType('');
  };
  const calculateAccountAgeInMonths = (createdAt: string): number => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const yearsDifference = currentDate.getFullYear() - createdDate.getFullYear();
    const monthsDifference = currentDate.getMonth() - createdDate.getMonth();
    const totalDifferenceInMonths = yearsDifference * 12 + monthsDifference;
    if (currentDate.getDate() < createdDate.getDate()) {
      return totalDifferenceInMonths - 1;
    }
    return totalDifferenceInMonths;
  };
  const generatePeriodOptions = (): { value: string; label: string }[] => {
    const options: { value: string; label: string }[] = [];
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    for (let i = 0; i < 6; i++) {
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const formattedMonth = month < 10 ? `0${month}` : month;
      const optionValue = `${formattedMonth}_${year}`;
      options.unshift({ value: optionValue, label: optionValue });
      date.setMonth(date.getMonth() - 1);
    }
    return options.reverse();
  };
  useEffect(() => {
    const accountAgeInMonths = calculateAccountAgeInMonths(createdAt);
    if (accountAgeInMonths >= 1) {
      setPeriodOptions(generatePeriodOptions());
      setIsAccountStateButtonDisabled(false);
    } else {
      setIsAccountStateButtonDisabled(true);
    }
  }, [createdAt]);
  function formatCurrency(availableAmount: number) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(availableAmount);
  }
  const isConfirmButtonEnabled = () => {
    if (selectedAction === 'movimientos') {
      return startDate && endDate;
    } else if (selectedAction === 'estadoDeCuenta') {
      return period.length > 0;
    }
    return false;
  };

  const modalErrorMessages: { [key: string]: string } = {
    incorrectCode: 'El código que ingresaste es incorrecto, por favor inténtalo de nuevo'
  };
  useEffect(() => {
    if (errorType === 'incorrectCode') {
      setShowModal(true);
      setShowInput(false);
      setModalType('error');
    }
  }, [errorType]);

  const handleSendEmail = () => {
    const input = {
      id: user.id,
      typeOfUser: 'investor',
      typeMessage: 'downloadBankStatement',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const handleOtp = async () => {
    setLocalLoading(true);
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: 'investor'
    };
    const base = process.env.REACT_APP_ACCOUNTING;
    const constructedURL = `${base}api/view-by-ledger-account/edo-cta?start=${startDate}&end=${endDate}&userId=${userId}`;
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: constructedURL,
      headers: {
        apiKey: process.env.REACT_APP_APIKEY_ACCOUNT_STATE
      }
    };
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        setErrorType('incorrectCode');
        setModalType('error');
        setShowModal(true);
      } else {
        setIsAuthenticated(true);
        setShowInput(false);
      }
      if (selectedAction === 'movimientos') {
        axios
          .request(config)
          .then((response) => {
            setData(response.data);
            setLocalLoading(false);
          })
          .catch((error) => {
            console.error(error);
            setLocalLoading(false);
          });
      } else if (selectedAction === 'estadoDeCuenta') {
        try {
          const email = {
            id: user.id,
            typeOfUser: 'investor',
            type: 'estadoDeCuenta',
            filename: `${period}.pdf`
          };
          const response = await axios.post(`${url}/admin/email/attach-email`, email);
          const preSignedURL = JSON.parse(response.data.body);
          if (preSignedURL === true) {
            setModalType('success');
            setShowModal(true);
            setLocalLoading(false);
          } else {
            setModalType('error');
            setShowModal(true);
            setLocalLoading(false);
          }
        } catch (err) {
          console.error(err);
          setModalType('error');
        }
      }
    } catch (error) {
      console.error(error);
      setModalType('error');
      setLocalLoading(false);
      setShowModal(true);
    }
  };

  useEffect(() => {
    const filteredData = data.filter(
      (item) =>
        item.descripcion &&
        item.descripcion.includes('Suma') &&
        (!activeFilter || (item.nombreCuenta && item.nombreCuenta.includes(activeFilter)))
    );
    const totals = filteredData.reduce(
      (acc, item) => {
        acc.abono += item.abono || 0;
        acc.cargo += item.cargo || 0;
        return acc;
      },
      { abono: 0, cargo: 0 }
    );
    setTotals(totals);
  }, [data, activeFilter]);

  return (
    <AccountContainer>
      <ComposedModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setErrorType('');
          handleActionClick('estadoDeCuenta');
        }}
        message={modalErrorMessages[errorType]}
        type={modalType}
      />
      <Card className="card-container" isForm>
        <h2>
          En esta sección puedes obtener tus movimientos dentro de periodo de tiempo específico:
        </h2>
        <FirstAction>
          <Button type="button" color="green" onClick={() => handleActionClick('movimientos')}>
            Ver movimientos
          </Button>
          <Button
            type="button"
            color="green"
            disabled={isAccountStateButtonDisabled}
            onClick={() => handleActionClick('estadoDeCuenta')}
          >
            Ver estado de cuenta
          </Button>
        </FirstAction>
        {selectedAction === 'movimientos' && (
          <>
            <Card.Row>
              <p>Selecciona la fecha de inicio</p>
              <InputField
                onChangeInternalValue={(value) => setStartDate(value as string)}
                value={startDate}
                placeholder="..."
                type="date"
                min="2023-01-01"
                max={today}
              />
            </Card.Row>
            <Card.Row>
              <p>Selecciona la fecha final</p>
              <InputField
                onChangeInternalValue={(value) => setEndDate(value as string)}
                value={endDate}
                placeholder="..."
                type="date"
                max={today}
              />
            </Card.Row>
          </>
        )}
        {selectedAction === 'estadoDeCuenta' && (
          <Card.Row>
            <p>Selecciona el periodo</p>
            <InputField
              placeholder="Seleccionar..."
              isDropdown={true}
              options={periodOptions}
              value={period}
              onChangeInternalValue={(newValue) => {
                setPeriod(newValue as string);
              }}
            />
          </Card.Row>
        )}
      </Card>
      <CodeSection>
        <p>Por tu seguridad, siempre requeriremos un doble factor de autenticación</p>
        {showInput ? (
          <FirstValidation>
            <p>Ingresa el código que enviamos a tu correo:</p>
            <InputField
              type="text"
              value={otp}
              onChangeInternalValue={(value) => setOtp(value as string)}
            />
            <Button isLoading={localLoading} size="small" onClick={handleOtp}>
              Confirmar código
            </Button>
          </FirstValidation>
        ) : (
          <Button
            isLoading={localLoading}
            size="small"
            disabled={!isConfirmButtonEnabled()}
            onClick={handleSendEmail}
          >
            Confirmar
          </Button>
        )}
      </CodeSection>
      {isAuthenticated && (
        <>
          {selectedAction === 'movimientos' && (
            <>
              <FiltersSection>
                <h2>Filtros:</h2>
                <div>
                  <Button
                    size="small"
                    className={activeFilter === 'Compromisos de Inversión' ? 'active-filter' : ''}
                    onClick={() => setActiveFilter('Compromisos de Inversión')}
                  >
                    Compromisos de inversión
                  </Button>
                  <Button
                    size="small"
                    className={
                      activeFilter === 'Depósitos de inversionistas' ? 'active-filter' : ''
                    }
                    onClick={() => setActiveFilter('Depósitos de inversionistas')}
                  >
                    Recursos disponibles
                  </Button>
                  <Button
                    size="small"
                    className={activeFilter === 'Inversiones Vigentes' ? 'active-filter' : ''}
                    onClick={() => setActiveFilter('Inversiones Vigentes')}
                  >
                    Inversiones vigentes
                  </Button>
                  <Button size="small" onClick={() => setActiveFilter(null)}>
                    Ver todo
                  </Button>
                </div>
              </FiltersSection>
              <AccountInfo>
                {activeFilter && (
                  <Entries>
                    <h3>Totales para {activeFilter}:</h3>
                    <p>Depósitos totales (Abono): {formatCurrency(totals.abono)}</p>
                    <p>Cargos totales (Cargo): {formatCurrency(totals.cargo)}</p>
                  </Entries>
                )}
                {data.length > 0 && (
                  <StyledTable>
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Número de operación</th>
                        <th>Comentarios</th>
                        <th>Descripción</th>
                        <th>Entradas</th>
                        <th>Salidas</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data
                        .filter(
                          (item) =>
                            (!activeFilter ||
                              (item.nombreCuenta && item.nombreCuenta.includes(activeFilter))) &&
                            (!item.descripcion || !item.descripcion.includes('Suma'))
                        )
                        .map((item) => (
                          <tr key={item.registroContable || item.numeroCuenta}>
                            <td>{item.fecha ? new Date(item.fecha).toLocaleDateString() : '-'}</td>
                            <td>{item.registroContable ?? '-'}</td>
                            <td>{item.comentario ?? '-'}</td>
                            <td>{item.descripcion}</td>
                            <td>{formatCurrency(item.abono) ?? '-'}</td>
                            <td>{formatCurrency(item.cargo) ?? '-'}</td>
                          </tr>
                        ))}
                    </tbody>
                  </StyledTable>
                )}
              </AccountInfo>
            </>
          )}
        </>
      )}
    </AccountContainer>
  );
}
