import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  DashboardAccountCLABEContainer,
  Banner,
  DashboardAccountCLABEInfo,
  DashboardAccountCLABEButton,
  DashboardAccountCLABETable
} from './accountCLABE.styles';
import { API } from 'aws-amplify';
import ModalInfoVirtualAccount from 'src/components/shared/modals/infoVirtualAccount/infoVirtualAccount';
import ModalRegisterCLABE from 'src/components/shared/modals/registerCLABE/registerCLABE';
import ModalDeleteRegisterCLABE from 'src/components/shared/modals/deleteRegisterCLABE/deleteRegisterCLABE';
import { getInvestorBankAccount, getInvestor_Profile } from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';

export default function DashboardAccountCLABE() {
  const dispatch = useDispatch();
  const [infoVirtualAccount] = useState(false);
  const [currentCLABE, setCurrentCLABE] = useState('');
  const [registerCLABE, setRegisterCLABE] = useState(false);
  const [deleteCLABE, setDeleteCLABE] = useState(false);
  const [bankData, setBankData] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [, setPersonalInfo] = useState({});
  const user = useSelector((state) => state.user.currentUser);
  const isMoral = user.typeOfPerson === 'MORAL';
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const PldApproved = personalInformation?.data?.getInvestor?.isPLDApproved;

  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getInvestorBankAccount,
          variables: { id: user.id }
        });
        setBankData(data.getInvestor.banks.items);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id]);
  //Data filtrada para las cuentas borradas. Usar este useEffect cuando se actualice el esquema:
  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const { data } = await API.graphql({
  //         query: getInvestorBankAccount,
  //         variables: { id: user.id }
  //       });
  //       const filteredBankData = data.getInvestor.banks.items.filter(
  //         (bank) => bank.isDeleted === null || bank.isDeleted === 0
  //       );
  //       setBankData(filteredBankData);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   if (user.id) {
  //     fetchUserData();
  //   }
  // }, [user.id]);
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };
  const handleCheckboxChange = (index) => {
    setBankData((oldBankData) =>
      oldBankData.map((bank, idx) => {
        if (idx === index) {
          return {
            ...bank,
            isMainAccount: !bank.isMainAccount
          };
        } else if (bank.isMainAccount) {
          return {
            ...bank,
            isMainAccount: false
          };
        }
        return bank;
      })
    );
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getInvestor_Profile,
          variables: { id: user.id }
        });
        setPersonalInfo(data.getInvestor.personalInformation);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id]);

  const setStatus = (status) => {
    switch (status) {
      case 'inProgress':
        return 'En progreso';
      case 'rejected':
        return 'Rechazada';
      case 'approved':
        return 'Aprobada';
      default:
        return status;
    }
  };
  const openModal = () => {
    setRegisterCLABE(true);
  };
  const closeModal = () => {
    setRegisterCLABE(false);
    window.location.reload();
  };
  const openDeleteModal = (clabeNumber) => {
    setCurrentCLABE(clabeNumber);
    setDeleteCLABE(true);
  };
  const closeDeleteModal = () => {
    setDeleteCLABE(false);
  };

  return (
    <DashboardAccountCLABEContainer>
      <ModalInfoVirtualAccount showModalInfoVirtualAccount={infoVirtualAccount} />
      <ModalRegisterCLABE
        showModalRegisterCLABE={registerCLABE}
        closeModal={closeModal}
        bankData={bankData}
      />
      <ModalDeleteRegisterCLABE
        showModalDeleteCLABE={deleteCLABE}
        closeModal={closeDeleteModal}
        clabeNumber={currentCLABE}
      />
      <Banner>
        <h2>CLABES interbancarias</h2>
        <p>
          Aqui podrás encontrar toda la información de tus CLABES dadas de altas, así como su
          estatus.
        </p>
      </Banner>
      <DashboardAccountCLABEInfo>
        <div>
          <h3>Total de CLABES dadas de alta</h3>
          <h3>Total de CLABES activas</h3>
        </div>
        <div></div>
        <div>
          <p>{bankData.length}</p>
          <p>{bankData.filter((bank) => bank.status.toLowerCase() === 'activa').length}</p>
        </div>
      </DashboardAccountCLABEInfo>
      {PldApproved === '0' && (
        <h3>
          Estimado usuario, aún no puedes dar de alta tu cuenta bancaria, nuestro equipo está
          validando la información proporcionada. En un plazo no mayor a 24hrs. recibirás noticias
          por parte del equipo de Finsphera.
        </h3>
      )}
      <DashboardAccountCLABEButton>
        <Button onClick={openModal} disabled={PldApproved === '0'}>
          Dar de alta una nueva CLABE
        </Button>
      </DashboardAccountCLABEButton>
      <DashboardAccountCLABETable>
        <tbody>
          <tr>
            <th>Nombre</th>
            <th>Banco</th>
            <th>CLABE</th>
            <th>Estatus de Cuenta</th>
            <th>
              Cuenta principal{' '}
              <button onClick={handleEditClick}>{isEditable ? 'Aceptar' : 'Editar'}</button>
            </th>
            <th>Eliminar cuenta</th>
          </tr>
          {bankData.map((bank, index) => (
            <tr key={index}>
              <td>{bank.identifier}</td>
              <td>{bank.bankName}</td>
              <td>{bank.CLABE}</td>
              <td>{setStatus(bank.status)}</td>
              <td>
                {isEditable ? (
                  <input
                    type="checkbox"
                    checked={bank.isMainAccount}
                    onChange={() => handleCheckboxChange(index)}
                  />
                ) : bank.isMainAccount ? (
                  'Sí'
                ) : (
                  'No'
                )}
              </td>
              <td>
                {' '}
                <Button size="small" onClick={() => openDeleteModal(bank.CLABE)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </DashboardAccountCLABETable>
    </DashboardAccountCLABEContainer>
  );
}
