import { createSlice, createDraftSafeSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store/store';
import { ErrorAppObject, UserState } from './app.interface';

const initialState: UserState = {
  isSignedIn: false,
  isLoading: false,
  formStep: 1,
  forms: [],
  error: {
    errorCode: '',
    isError: false,
    severity: ''
  }
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
    setAppError: (
      state,
      action: PayloadAction<{ errorCode?: string; severity?: 'error' | 'warning' | 'critical' }>
    ) => {
      const { errorCode, severity } = action.payload;

      const payload: ErrorAppObject = {
        errorCode: errorCode || '',
        isError: true,
        severity: severity || 'error'
      };

      state.error = payload;
    },
    removeAppError: (state) => {
      state.error = {
        isError: false,
        severity: '',
        errorCode: state.error.errorCode
      };
    }
  }
});

export const { setIsSignedIn, setAppError, removeAppError } = appSlice.actions;
export default appSlice.reducer;

const selectSelf = (state: RootState) => state;

export const isSignedInSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.app.isSignedIn
);

export const errorAppSelector = createDraftSafeSelector(selectSelf, (state) => state.app.error);
