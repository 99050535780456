import styled from 'styled-components/macro';
import { whiteSphera } from '../../../utilities/UIStyles/variables/colors';

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 100vw;
  padding-top: 47px;
  background-color: ${whiteSphera};
  padding: 0 12.5%;
  text-align: center;
`;
