import { useState, useEffect } from 'react';
import {
  ProfessionalInfoContainer,
  DashboardAccountCLABEButton,
  DashboardAccountCLABETable
} from './bankInfo.styles';
import { useSelector } from 'react-redux';
import ModalRegisterCLABE from 'src/components/shared/modals/registerCLABEApplicant/registerCLABE';
import { ModalDeleteRegisterCLABE } from 'src/components/shared/modals/deleteRegisterCLABE/deleteRegisterCLABE.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { API } from 'aws-amplify';
import { getApplicantFiles } from 'src/graphql/customized_queries';

export default function BankInfo({ bankInformation, personalInformation }) {
  const [registerCLABE, setRegisterCLABE] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const [bankData, setBankData] = useState([]);
  const [filesValidator, setFilesValidator] = useState(null);
  const [deleteCLABE, setDeleteCLABE] = useState(false);
  const [currentCLABE, setCurrentCLABE] = useState('');
  const [isEditable, setIsEditable] = useState(false);
  const [, setRefreshKey] = useState(0);
  const setStatus = (status) => {
    switch (status) {
      case 'inProgress':
        return 'En progreso';
      case 'rejected':
        return 'Rechazada';
      case 'approved':
        return 'Aprobada';
      default:
        return status;
    }
  };

  const PldApproved = personalInformation?.data?.getApplicant?.isPLDApproved;

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await API.graphql({
        query: getApplicantFiles,
        variables: { id: user.id }
      });

      if ('data' in response) {
        const files = response.data?.getFile?.areBusinessDocumentsUpload;
        setFilesValidator(files);
      } else {
        // Handle no data property
        console.error('No data received', response);
      }
    };
    fetchUserData();
  }, []);
  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };

  const handleCheckboxChange = (index) => {
    setBankData((oldBankData) =>
      oldBankData.map((bank, idx) => {
        if (idx === index) {
          return {
            ...bank,
            isMainAccount: !bank.isMainAccount
          };
        } else if (bank.isMainAccount) {
          return {
            ...bank,
            isMainAccount: false
          };
        }
        return bank;
      })
    );
  };
  useEffect(() => {
    if (
      bankInformation &&
      bankInformation?.data?.getApplicant &&
      bankInformation?.data?.getApplicant?.banks
    ) {
      setBankData(bankInformation.data.getApplicant.banks.items);
    }
  }, [bankInformation]);
  const closeModal = () => {
    setRegisterCLABE(false);
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const openModal = () => {
    setRegisterCLABE(true);
  };
  const openDeleteModal = (clabeNumber) => {
    setCurrentCLABE(clabeNumber);
    setDeleteCLABE(true);
  };
  const closeDeleteModal = () => {
    setDeleteCLABE(false);
  };
  return (
    <ProfessionalInfoContainer>
      <ModalRegisterCLABE
        showModalRegisterCLABE={registerCLABE}
        closeModal={closeModal}
        bankData={bankData}
        personalInformation={personalInformation}
      />
      <ModalDeleteRegisterCLABE
        showModalDeleteCLABE={deleteCLABE}
        closeModal={closeDeleteModal}
        clabeNumber={currentCLABE}
      />
      {PldApproved === '0' && filesValidator === false ? (
        <h3>
          Estimado usuario, las áreas internas de Finsphera están validando tu información. En un
          plazo no mayor a 24hrs. recibirás noticias para empezar a solicitar crédito a través de
          tus campañas. Mientras tanto puedes ir a tu apartado de Mis Documentos para cargar la
          información solicitada cuanto antes.
        </h3>
      ) : PldApproved === '0' ? (
        <h3>
          Estimado usuario, aún no puedes dar de alta tu cuenta bancaria, nuestro equipo está
          validando la información proporcionada. En un plazo no mayor a 24hrs. recibirás noticias
          por parte del equipo de Finsphera.
        </h3>
      ) : filesValidator === false ? (
        <h3>
          Estimado usuario, aún no has subido los documentos de la compañía. Recuerda que mientras
          no nos proporciones esta información, no podrás dar de alta cuentas ni crear campaña.
          ¡Hazlo cuanto antes, ve al apartado de Mis Documentos!
        </h3>
      ) : null}
      <DashboardAccountCLABEButton>
        <Button onClick={openModal} disabled={!filesValidator || PldApproved === '0'}>
          Dar de alta CLABE de la compañía
        </Button>
      </DashboardAccountCLABEButton>
      <DashboardAccountCLABETable>
        <tbody>
          <tr>
            <th>Nombre</th>
            <th>Banco</th>
            <th>CLABE</th>
            <th>Cuenta Activa</th>
            <th>
              Cuenta principal{' '}
              <button onClick={handleEditClick}>{isEditable ? 'Aceptar' : 'Editar'}</button>
            </th>
            <th>Eliminar cuenta</th>
          </tr>
          {bankData.map((bank, index) => (
            <tr key={index}>
              <td>{bank.identifier}</td>
              <td>{bank.bankName}</td>
              <td>{bank.CLABE}</td>
              <td>{setStatus(bank.status)}</td>
              <td>
                {isEditable ? (
                  <input
                    type="checkbox"
                    checked={bank.isMainAccount}
                    onChange={() => handleCheckboxChange(index)}
                  />
                ) : bank.isMainAccount ? (
                  'Sí'
                ) : (
                  'No'
                )}
              </td>
              <td>
                {' '}
                <Button isFullWidth size="small" onClick={() => openDeleteModal(bank.CLABE)}>
                  Eliminar
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </DashboardAccountCLABETable>
    </ProfessionalInfoContainer>
  );
}
