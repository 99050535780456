import styled from 'styled-components/macro';

import {
  blueSphera,
  greenSp,
  grayPlaceholder,
  blackSphera,
  grayLightSphera,
  grayDarkSphera,
  yellowSphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const CompanyDetailContainer = styled.section`
  padding-top: 10%;
  background-color: rgb(245, 245, 245);

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    padding-top: 25%;
  }
`;

export const CompanyCardsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2.5% 5%;
  background-color: ${whiteSphera};

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const CompanyCardInvest = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  background-color: ${whiteSphera};
  border: 1px solid ${blackSphera};
  border-radius: 5px;

  > figure:nth-child(1) {
    width: 30%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > div:nth-child(2) {
    width: 33%;

    > h2 {
      font-size: 2.5vw;
      color: ${blackSphera};
      margin-bottom: 1%;
    }

    > p:nth-child(2) {
      font-size: 1.2vw;
      color: ${blackSphera};
      margin-bottom: 1%;
    }

    > p:nth-child(3) {
      font-size: 2vw;
      font-weight: bold;
      color: ${blackSphera};
    }
  }

  > div:nth-child(3) {
    width: 33%;

    > div:nth-child(1) {
      > p {
        font-size: 1.2vw;
        font-weight: bold;
        color: ${blackSphera};
        margin-bottom: 2.5%;
      }

      > div {
        width: 100%;
        height: 30px;
        border-radius: 20px;
        background-color: ${blackSphera};

        > div {
          width: 23%;
          height: 100%;
          border-radius: 20px;
          background-color: ${greenSp};
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: center;
      padding-top: 5%;
      padding-bottom: 5%;
      border-top: 1px solid ${grayPlaceholder};

      > h3 {
        font-size: 1.2vw;
        color: ${blackSphera};
        text-align: center;
      }

      > div {
        display: flex;
        justify-content: space-between;
        gap: 5%;
        margin-left: 2.5%;

        > a {
          > figure {
            width: 30px;
            height: 30px;

            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    width: 100%;

    > figure:nth-child(1) {
      > img {
      }
    }

    > div:nth-child(2) {
      > h2:nth-child(1) {
        font-size: 4.2vw;
      }

      > p:nth-child(2) {
        font-size: 4.5vw;
      }

      > p:nth-child(3) {
        font-size: 4.2vw;
      }

      > p:nth-child(4) {
        font-size: 4.2vw;
      }
    }

    > div:nth-child(3) {
      height: 20px;

      > div {
      }
    }

    > div:nth-child(4) {
      > h3 {
        font-size: 4.5vw;
      }

      > div {
        > figure {
          > a {
            > img {
            }
          }
        }
      }
    }
  }
`;

export const CompanyCardDetails = styled.div`
  width: 40%;
  padding-top: 5.5%;
  padding-left: 2.5%;
  border: 1px solid ${blackSphera};

  > ul {
    list-style: none;
    margin-bottom: 8%;

    > li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 1.2vw;
      color: ${blackSphera};
      margin-bottom: 1%;

      > figure {
        width: 18px;
        height: 18px;
        margin-left: 2.5%;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  > div {
    > h2 {
      color: ${blackSphera};
      font-size: 1.2vw;
      width: 95%;
      margin-bottom: 2.5%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid ${grayPlaceholder};
    }

    > p {
      color: ${blackSphera};
      font-size: 1.2vw;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > ul {
      > li {
        > figure {
          > img {
          }
        }
      }
    }

    > div {
      > h2 {
      }

      > p {
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    > ul {
      > li {
        font-size: 4.2vw;

        > figure {
          > img {
          }
        }
      }
    }

    > div {
      > h2 {
        font-size: 4.2vw;
      }

      > p {
        font-size: 4.2vw;
      }
    }
  }
`;

export const CompanyCardSimulator = styled.div`
  width: 45%;
  padding: 5% 2% 5% 2.5%;
  background-color: ${blackSphera};

  > h3:nth-child(1) {
    font-size: 1.5vw;
    color: ${whiteSphera};
    text-align: center;
    margin-bottom: 5%;
  }

  > div:nth-child(2) {
    > div {
      display: flex;
      justify-content: space-between;
      padding: 2.5% 1%;
      border-bottom: 1px solid ${grayLightSphera};

      > h4 {
        font-size: 1.2vw;
        font-weight: normal;
        color: ${whiteSphera};
      }

      > p {
        font-size: 1.2vw;
        font-weight: bold;
        color: ${whiteSphera};
      }
    }
  }

  > div:nth-child(3) {
    margin: 10% 0 2.5% 0;

    > p {
      font-size: 1.3vw;
      font-weight: bold;
      color: ${whiteSphera};
      text-align: center;
    }
  }

  > button:nth-child(4) {
    color: ${grayDarkSphera};
    font-weight: bold;
    font-size: 1.3vw;
    text-align: center;
    width: 80%;
    padding: 1% 0;
    margin-left: 10%;
    border: none;
    border-radius: 5px;
    background-color: ${whiteSphera};
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > div:nth-child(2) {
      > div {
        > h4 {
        }

        > p {
        }
      }
    }

    > div:nth-child(3) {
      > p {
      }
    }

    > button:nth-child(4) {
      &:hover {
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 5%;

    > h3:nth-child(1) {
      font-size: 4.5vw;
    }

    > div:nth-child(2) {
      > div {
        > h4 {
          font-size: 4vw;
        }

        > p {
          font-size: 4vw;
        }
      }
    }

    > div:nth-child(3) {
      margin: 5% 0;

      > p {
        font-size: 5vw;
        text-align: center;
      }
    }

    > button:nth-child(4) {
      font-size: 4.5vw;
      width: 100%;
      padding-top: 2%;
      padding-bottom: 2%;
      margin-left: 0;

      &:hover {
      }
    }
  }
`;

export const CompanyCreditDescription = styled.div`
  padding: 2.5% 0;
  width: 90%;
  margin-left: 5%;
  border-bottom: 2px solid ${blackSphera};

  > div {
    > h3 {
      font-size: 1.2vw;
      color: ${blackSphera};
      margin-bottom: 1%;
    }

    > p {
      font-size: 1.2vw;
      color: ${blackSphera};
      line-height: 25px;
      width: 70%;
    }

    > ul {
      margin-top: 1%;
      margin-left: 2.5%;

      > li {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        color: ${blackSphera};
        font-size: 1.2vw;
        margin-bottom: 0.2%;

        > span {
          font-weight: bold;
        }

        > p {
          font-size: 1.2vw;

          > span {
            font-weight: bold;
          }
        }

        > figure {
          width: 15px;
          height: 15px;
          margin-left: 1%;
          cursor: pointer;

          > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }

      > p {
        line-height: 20px;
      }
    }

    > div:nth-child(2) {
      > h3:nth-child(1) {
      }

      > p:nth-child(2) {
        line-height: 20px;
      }

      > p:nth-child(3) {
        line-height: 20px;
      }

      > ul:nth-child(4) {
        > li {
          > span {
          }

          > p {
            > span {
            }
          }

          > figure {
            > img {
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 2.5% 0;
    width: 90%;

    > div:nth-child(1) {
      > h3 {
        font-size: 4.2vw;
      }

      > p {
        font-size: 4.2vw;
        width: 90%;
      }
    }

    > div:nth-child(2) {
      > h3:nth-child(1) {
        font-size: 4.2vw;
        margin-top: 5%;
      }

      > p:nth-child(2) {
        font-size: 4.2vw;
        width: 90%;
      }

      > p:nth-child(3) {
        font-size: 4.2vw;
        width: 90%;
        margin-top: 1%;
      }

      > ul:nth-child(4) {
        margin-left: 5%;

        > li {
          font-size: 4.2vw;
          margin-bottom: 0.2%;

          > span {
          }

          > p {
            font-size: 4.2vw;

            > span {
            }
          }

          > figure {
            > img {
            }
          }
        }
      }
    }
  }
`;

export const CompanyInformation = styled.div`
  padding: 2.5% 0;
  border-bottom: 2px solid ${blackSphera};
  width: 90%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > ul:nth-child(2) {
    margin-top: 1%;
    margin-left: 2.5%;
    list-style: none;

    > li {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 0.2%;

      > span {
        font-weight: bold;
      }

      > a {
        color: ${blackSphera};
        text-decoration: none;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > ul:nth-child(2) {
      > li {
        > span {
        }

        > a {
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin-left: 5%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > ul:nth-child(2) {
      margin-left: 5%;

      > li {
        font-size: 4.2vw;

        > span {
        }

        > a {
        }
      }
    }
  }
`;

export const CompanyCreditRights = styled.div`
  padding: 2.5% 0;
  border-bottom: 2px solid ${blackSphera};
  width: 90%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > p:nth-child(2) {
    color: ${blackSphera};
    font-size: 1.2vw;

    > span {
      font-weight: bold;
    }
  }

  > ul:nth-child(3) {
    margin-top: 1%;
    margin-bottom: 2.5%;
    margin-left: 2.5%;
    list-style: none;

    > li {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 0.2%;

      > span {
        font-weight: bold;
      }

      > a {
        color: ${blackSphera};
        text-decoration: none;
      }
    }
  }

  > div:nth-child(4) {
    > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      background-color: ${blackSphera};
      padding: 2.5%;

      > h3 {
        font-size: 1.2vw;
        color: ${whiteSphera};
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      padding: 2.5%;

      > p {
        font-size: 1.2vw;
        color: ${blackSphera};
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > p:nth-child(2) {
      > span {
      }
    }

    > ul:nth-child(3) {
      > li {
        > span {
        }

        > a {
        }
      }
    }

    > div:nth-child(4) {
      > div:nth-child(1) {
        > h3 {
        }
      }

      > div:nth-child(2) {
        > p {
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > p:nth-child(2) {
      font-size: 4.2vw;

      > span {
      }
    }

    > ul:nth-child(3) {
      margin-bottom: 5%;
      margin-left: 5%;

      > li {
        font-size: 4.2vw;

        > span {
        }

        > a {
        }
      }
    }

    > div:nth-child(4) {
      > div:nth-child(1) {
        > h3 {
          font-size: 4.2vw;
        }
      }

      > div:nth-child(2) {
        > p {
          font-size: 4.2vw;
        }
      }
    }
  }
`;

export const CompanyAmortization = styled.div`
  padding: 2.5% 0;
  border-bottom: 1px solid ${grayPlaceholder};
  width: 65%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > p:nth-child(2) {
    color: ${blackSphera};
    font-size: 1.2vw;

    > span {
      font-weight: bold;
    }
  }

  > ul:nth-child(3) {
    margin-top: 1%;
    margin-left: 2.5%;
    list-style: none;

    > li {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 0.2%;

      > span {
        font-weight: bold;
      }

      > a {
        color: ${blackSphera};
        text-decoration: none;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > p:nth-child(2) {
      > span {
      }
    }

    > ul:nth-child(3) {
      > li {
        > span {
        }

        > a {
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > p:nth-child(2) {
      font-size: 4.2vw;

      > span {
      }
    }

    > ul:nth-child(3) {
      margin-left: 5%;

      > li {
        font-size: 4.2vw;

        > span {
        }

        > a {
        }
      }
    }
  }
`;

export const CompanyFinancialIndicators = styled.div`
  padding: 2.5% 0;
  border-bottom: 1px solid ${grayPlaceholder};
  width: 65%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > ul:nth-child(2) {
    margin-top: 1%;
    margin-left: 2.5%;
    list-style: none;

    > li {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-bottom: 0.2%;

      > span {
        font-weight: bold;
      }

      > a {
        color: ${blackSphera};
        text-decoration: none;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > ul:nth-child(2) {
      > li {
        > span {
        }

        > a {
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > ul:nth-child(2) {
      margin-left: 5%;

      > li {
        color: ${blackSphera};
        font-size: 4.2vw;

        > span {
        }

        > a {
        }
      }
    }
  }
`;

export const CompanyRiskFactor = styled.div`
  padding: 2.5% 0;
  border-bottom: 1px solid ${grayPlaceholder};
  width: 85%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > p:nth-child(2) {
    color: ${blackSphera};
    font-size: 1.2vw;
    line-height: 25px;
    margin-left: 3%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > p:nth-child(2) {
      line-height: 20px;
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > p:nth-child(2) {
      font-size: 4.2vw;
      line-height: 20px;
    }
  }
`;

export const CompanyAdditionalInfo = styled.div`
  padding: 2.5% 0;
  border-bottom: 1px solid ${grayPlaceholder};
  width: 85%;
  margin-left: 5%;

  > div:nth-child(1) {
    > h3:nth-child(1) {
      font-size: 1.2vw;
      color: ${blackSphera};
      margin-bottom: 1%;
    }

    > p:nth-child(2) {
      color: ${blackSphera};
      font-size: 1.2vw;
    }
  }

  > div:nth-child(2) {
    > h3:nth-child(1) {
      font-size: 1.2vw;
      color: ${blackSphera};
      margin-top: 2.5%;
      margin-bottom: 1%;
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      width: 55%;

      > select {
        color: ${grayPlaceholder};
        font-size: 1.2vw;
        width: 45%;
        height: 48px;
        padding-left: 1%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }

      > button {
        color: ${whiteSphera};
        font-weight: bold;
        font-size: 1.2vw;
        text-align: center;
        width: 25%;
        padding-top: 1%;
        padding-bottom: 1%;
        border: none;
        border-radius: 5px;
        background-color: ${blackSphera};
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3:nth-child(1) {
      }

      > p:nth-child(2) {
      }
    }

    > div:nth-child(2) {
      > h3:nth-child(1) {
      }

      > div:nth-child(2) {
        > select {
        }

        > button {
          &:hover {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    > div:nth-child(1) {
      > h3:nth-child(1) {
        font-size: 4.2vw;
      }

      > p:nth-child(2) {
        font-size: 4.2vw;
      }
    }

    > div:nth-child(2) {
      > h3:nth-child(1) {
        font-size: 4.2vw;
        margin-top: 5%;
      }

      > div:nth-child(2) {
        flex-direction: column;
        width: 90%;

        > select {
          font-size: 4.2vw;
          width: 100%;
          height: 40px;
        }

        > button {
          font-size: 4.2vw;
          width: 100%;
          margin-top: 5%;
          padding-top: 3%;
          padding-bottom: 3%;

          &:hover {
          }
        }
      }
    }
  }
`;

export const CompanyFollowInvest = styled.div`
  padding: 2.5% 0;
  width: 85%;
  margin-left: 5%;

  > h3:nth-child(1) {
    font-size: 1.2vw;
    color: ${blackSphera};
    margin-bottom: 1%;
  }

  > p:nth-child(2) {
    color: ${blackSphera};
    font-size: 1.2vw;
    margin-bottom: 2.5%;

    > a {
      color: ${greenSp};
      font-weight: bold;
      text-decoration: none;
    }
  }

  > a {
    color: ${blackSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    text-decoration: none;
    padding: 1% 5%;
    border: 1px solid ${blackSphera};
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h3:nth-child(1) {
    }

    > p:nth-child(2) {
      > a {
      }
    }

    > a {
      &:hover {
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > h3:nth-child(1) {
      font-size: 4.2vw;
    }

    > p:nth-child(2) {
      font-size: 4.2vw;
      width: 90%;
      margin-bottom: 5%;

      > a {
      }
    }

    > a {
      font-size: 4.2vw;
      width: 100%;
      padding: 3%;

      &:hover {
      }
    }
  }
`;
