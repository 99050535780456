import styled from 'styled-components/macro';

import { blueSphera, graySphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalDocumentsSucess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalInvoiceSuccess ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  animation: ${(props) => (props.showModalInvoiceSuccess ? fadeIn : fadeOut)} 0.5s linear;

  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;

    > h2 {
      font-size: 2.5vw;
      text-align: center;
      color: ${graySphera};

      > span {
        color: #f8ad03;
      }
    }

    > a {
      color: ${whiteSphera};
      font-size: 2vw;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      width: 50%;
      margin: 5% auto;
      padding: 2%;
      border: none;
      border-radius: 5px;
      background-color: ${blueSphera};
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h2 {
        > span {
        }
      }

      > a {
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > h2 {
        font-size: 7vw;
        text-align: center;

        > span {
        }
      }

      > a {
        font-size: 4.3vw;
        width: 100%;
        padding: 3% 8%;

        &:hover {
        }
      }
    }
  }
`;
