import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { url } from 'src/environments';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  ButtonContainer,
  IdentityQuestion,
  JointlyContainer,
  StyledTable,
  TermsPolicies
} from './jointlyObligated.style';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { useForm, Controller } from 'react-hook-form';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import options from 'src/constants/simafOptionsInvestor';
import { setAppError } from 'src/store/app/app.slice';
import generateUUID from 'src/functions/uuid';
import { API } from 'aws-amplify';
import { getApplicantFiles } from 'src/graphql/customized_queries';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

export default function CreateJointlyObligated({ obligadoInfo, personalInformation }) {
  const user = useSelector((state) => state.user.currentUser);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sameAsRep, setSameAsRep] = useState(null);
  const [isLegalRep, setIsLegalRep] = useState(false);
  const [filesValidator, setFilesValidator] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsLegalRep, setShowInputsLegalRep] = useState(false);
  const [idObligado, setIdObligado] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPEP = options[5];
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const emailWatched = watch('email');
  const isPEPOptions = isPEP.map((isPep) => ({
    value: isPep.code,
    label: isPep.name
  }));

  const legalRepData = personalInformation?.data?.getApplicant?.personalInformation;
  const PldApproved = personalInformation?.data?.getApplicant?.isPLDApproved;
  //Validar esto:
  const validationId = personalInformation?.data?.getApplicant?.id;

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await API.graphql({
        query: getApplicantFiles,
        variables: { id: user.id }
      });
      if ('data' in response) {
        const files = response.data?.getFile?.areBusinessDocumentsUpload;
        setFilesValidator(files);
      } else {
        console.error('No data received', response);
      }
    };
    fetchUserData();
  }, []);

  const politicallyExposedValue = watch('politicallyExposed');

  const createNewObligado = () => {
    const newUuid = generateUUID();
    setIdObligado(newUuid);
    //This checks if legalRepData.id matches any id in obligadoInfo array
    const isLegalRepAlreadyObligado = obligadoInfo.some((info) => info.id === validationId);
    if (isLegalRepAlreadyObligado) {
      setShowInputs(true);
      setIsLegalRep(false);
    } else {
      setIsLegalRep(true);
    }
  };

  //Function that sends the new data to dynamo
  const onSubmit = async (data) => {
    setLocalLoading(true);
    try {
      if (showInputsLegalRep) {
        const obligado = {
          id: user.id
        };
        await axios.post(`${url}/admin/mutations/create/obligado-solidario-is-applicant`, obligado);
      } else if (showInputs) {
        const metamap = {
          id: idObligado,
          typeOfUser: 'obligadoSolidario'
        };
        const obligado = {
          id: idObligado,
          applicantId: user.id,
          personalInformation: {
            email: data.email,
            RFC: data.RFC,
            isPep: data.politicallyExposed,
            pepDate: data.pepDate,
            pepName: data.pepName,
            pepCharge: data.pepCharge
          }
        };
        await axios.post(`${url}/admin/mutations/create/obligado-solidario`, obligado);
        await axios.post(`${url}/admin/email/user/mati-direct-link`, metamap);
      }
      setLocalLoading(false);
      setShowModal(true);
    } catch (error) {
      setLocalLoading(false);
      dispatch(setAppError());
    }
  };

  const generateModalMessage = (email) => {
    if (showInputsLegalRep) {
      return (
        <>
          <h3>El obligado solidario ha sido dado de alta exitosamente</h3>
          <p>
            En el proceso de creación de campañas, podrás seleccionarlo. Recuerda que una vez
            alcanzada la meta de financiamiento, deberá ingresar a la plataforma y firmar los
            pagarés.
          </p>
        </>
      );
    } else {
      return (
        <>
          <h3>
            Hemos generado un enlace que le llegará al obligado solidario al correo electrónico
            registrado: {email}
          </h3>
          <p>
            Al dar click en el enlace, el obligado deberá realizar su registro de biométricos y
            subir su identificación oficial (INE). Una vez realizado este proceso, Finsphera
            validará su información. ¡Recuerda que mientras más rápido realice este proceso, estarás
            recibiendo una notificación vía correo de que el alta ha sido exitosa!.
          </p>
        </>
      );
    }
  };

  const handleFinish = async () => {
    handleCancel();
    setShowModal(false);
    navigate('/solicitante/dashboard/mi-perfil');
  };

  const handleRadioChange = (value) => {
    setSameAsRep(value);
    if (value === 'yes') {
      setShowInputsLegalRep(true);
    } else {
      setShowInputs(true);
    }
  };

  const handleCancel = (value) => {
    setSameAsRep(null);
    setShowInputsLegalRep(false);
    setShowInputs(false);
    setIsLegalRep(false);
  };

  return (
    <JointlyContainer>
      <ComposedModal
        messageTitle="La solicitud ha sido generada exitosamente"
        messageChildren={generateModalMessage(emailWatched)}
        type="success"
        isOpen={showModal}
        onClose={() => handleFinish()}
        buttons={[
          {
            label: 'Continuar',
            onClick: () => handleFinish(),
            color: 'green'
          }
        ]}
        isCloseButton={false}
        onOverlayClose={false}
      />
      <StyledTable>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>RFC</th>
              <th>Email</th>
              <th>Es PEP</th>
              <th>Fecha de creación</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(obligadoInfo) && obligadoInfo.length > 0 ? (
              obligadoInfo
                .filter((info) => info.isPLDApproved === '1')
                .map((info) => (
                  <tr key={info.id}>
                    <td>
                      {info?.personalInformation?.fullName
                        ? info?.personalInformation?.fullName
                        : '-'}
                    </td>
                    <td>{info?.personalInformation?.RFC ? info?.personalInformation?.RFC : '-'}</td>
                    <td>
                      {info?.personalInformation?.email ? info?.personalInformation?.email : '-'}
                    </td>
                    <td>{info?.personalInformation?.isPep === '1' ? 'Sí' : 'No'}</td>
                    <td>
                      {info?.createdAt ? new Date(info.createdAt).toISOString().split('T')[0] : '-'}
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan="5">Aún no tienes obligados solidarios dados de alta.</td>
              </tr>
            )}
          </tbody>
        </table>
      </StyledTable>
      <ButtonContainer>
        <Button
          size="medium"
          onClick={createNewObligado}
          disabled={!filesValidator || PldApproved === '0'}
        >
          Nuevo Obligado Solidario
        </Button>
      </ButtonContainer>
      {isLegalRep && (
        <IdentityQuestion>
          <p>
            ¿El obligado solidario que vas a dar de alta será la misma persona que el representante
            legal?
          </p>
          <div>
            <label>
              <input
                type="radio"
                value="yes"
                checked={sameAsRep === 'yes'}
                onChange={() => handleRadioChange('yes')}
                disabled={showInputsLegalRep || showInputs}
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                value="no"
                checked={sameAsRep === 'no'}
                onChange={() => handleRadioChange('no')}
                disabled={showInputsLegalRep || showInputs}
              />
              No
            </label>
          </div>
        </IdentityQuestion>
      )}
      {showInputsLegalRep && (
        <IdentityQuestion>
          <p>Los datos del Obligado Solidario que daremos de alta son:</p>
          <div>
            <h3>Nombre completo:</h3>
            <p>{legalRepData.fullName}</p>
          </div>
          <div>
            <h3>Correo electrónico:</h3>
            <p>{legalRepData.email}</p>
          </div>
          <div>
            <h3>RFC:</h3>
            <p>{legalRepData.RFC}</p>
          </div>
          <ButtonContainer>
            <Button size="small" onClick={() => handleCancel()}>
              Cancelar
            </Button>
            <Button isLoading={localLoading} size="small" onClick={handleSubmit(onSubmit)}>
              Confirmar
            </Button>
          </ButtonContainer>
        </IdentityQuestion>
      )}
      {showInputs && (
        <IdentityQuestion>
          <Controller
            name="RFC"
            control={control}
            rules={{
              minLength: {
                value: 13,
                message: 'RFC debe tener al menos 13 caracteres.'
              },
              required: 'Es un campo requerido.',
              pattern: {
                value: /^[A-ZÑ0-9]+$/,
                message: 'RFC inválido.'
              }
            }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputField
                label="Ingresa el RFC del obligado solidario:"
                placeholder="AABB1234567P8"
                maxLength={13}
                autoUpperCase
                onChangeInternalValue={onChange}
                errorMessages={error && error.message ? [error.message] : []}
                {...rest}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Ingresa el correo electrónico del obligado solidario:"
                placeholder="email@gmail.com"
                maxLength={30}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
          <Card.Row>
            <Controller
              name="politicallyExposed"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  wrapLabel
                  label="¿El Obligado o alguno de sus familiares hasta el segundo grado, desempeña o ha desempeñado durante el último año, funciones públicas en un país extranjero o en territorio nacional, en puestos ejecutivos en empresas gubernamentales, estatales, federales o municipales o en partidos políticos?"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={isPEPOptions}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>
          <Card.Row>
            {(politicallyExposedValue === '2' || politicallyExposedValue === '3') && (
              <div>
                <Controller
                  name="pepName"
                  control={control}
                  rules={{
                    minLength: {
                      value: 5,
                      message: 'El nombre debe tener al menos 5 caracteres'
                    },
                    required: 'Es un campo requerido.'
                  }}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputField
                      label="Nombre de la persona que se desempeñó como PEP:"
                      placeholder="Nombre"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name="pepCharge"
                  control={control}
                  rules={{
                    minLength: {
                      value: 5,
                      message: 'El cargo debe tener al menos 5 caracteres'
                    },
                    required: 'Es un campo requerido.'
                  }}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputField
                      label="Ingresa el cargo desempeñado:"
                      placeholder="Descripción"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name="pepDate"
                  control={control}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputField
                      type="date"
                      label="Fecha de término de función (si aplica)"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
              </div>
            )}
          </Card.Row>
          <TermsPolicies>
            <input
              type="checkbox"
              name="acceptTerms"
              onChange={() => setAcceptTerms((acceptTerms) => !acceptTerms)}
            />
            <p>
              He leído y estoy de acuerdo en que mi información sea utilizada para los fines que a
              Finsphera convenga descritos en los{' '}
              <Link to="/terminos-y-condiciones" target="_blank" rel="noopener noreferrer">
                términos y condiciones
              </Link>{' '}
              y{' '}
              <Link to="/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">
                políticas de privacidad del sitio
              </Link>
              .
            </p>
          </TermsPolicies>
          <ButtonContainer>
            <Button size="small" onClick={() => handleCancel()}>
              Cancelar
            </Button>
            <Button
              isLoading={localLoading}
              disabled={!acceptTerms}
              size="small"
              onClick={handleSubmit(onSubmit)}
            >
              Confirmar
            </Button>
          </ButtonContainer>
        </IdentityQuestion>
      )}
    </JointlyContainer>
  );
}
