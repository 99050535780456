import {
  ModalCampaignCancel,
  ContentContainer,
  CloseButton,
  MainInfo
} from './campaignCanceled.styles';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function CancelCampaignModal({ showCancelCampaign, setCampaignCancel }) {
  const user = useSelector((state) => state.user.currentUser);
  return (
    <ModalCampaignCancel showCancelCampaign={showCancelCampaign}>
      <ContentContainer>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_error.png`}
            alt="Ícono de aviso"
          />
        </figure>
        <h2>
          Estás apunto de cancelar tu <span>CAMPAÑA:</span>
        </h2>
        <MainInfo>
          <p>
            Para que podamos cumplir con tu solicitud, será necesario que contactes con nuestro
            equipo al correo:
          </p>
          <Link to="mailto:atencionaclientes@finsphera.com">atencionaclientes@finsphera.com</Link>
          <p>
            Agregando la razón de la cancelación y el correo con el que estás registrado en
            Finsphera: {user.email}
          </p>
        </MainInfo>
        <CloseButton onClick={() => setCampaignCancel(false)}>Cerrar</CloseButton>
      </ContentContainer>
    </ModalCampaignCancel>
  );
}
