import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { loadingSelector } from 'src/store/User/user.slice';
import { RootState } from 'src/store/store';
import { isByPass } from 'src/environments';

type User = 'investor' | 'applicant';

type Person = 'MORAL' | 'FISICA' | undefined;

export type AllowedUser = { user: User; person: Person };

export type ProtectedRouteProps = {
  children: ReactNode;
  redirectPath?: string;
  redirectPathIfNotAllowed?: string;
  isReturnPath?: boolean;
  invertedProtected?: boolean; // The isSignedIn logic will be inverted
  allowedUsers?: AllowedUser[];
  condition?: boolean;
};

export const ProtectedRoute = ({
  children,
  redirectPath = '/iniciar-sesion',
  redirectPathIfNotAllowed = '/not-found',
  isReturnPath = true,
  invertedProtected = false,
  allowedUsers,
  condition
}: ProtectedRouteProps) => {
  const isLoading = useSelector(loadingSelector);
  const [isLoaded, setIsLoaded] = useState(false);

  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn);
  const { typeOfPerson: typeOfPerson, groupName } = useSelector(
    (state: RootState) => state.user.currentUser
  );
  const location = useLocation();
  const encodedPathname = encodeURIComponent(location.pathname);

  useEffect(() => {
    if (!isLoading && !isLoaded) {
      setIsLoaded(true);
    }
  }, [isLoaded, isLoading]);

  if (isByPass) return children as JSX.Element;

  if (!isLoaded) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          widows: '100vw',
          backgroundColor: 'white'
        }}
      />
    );
  }

  const composedRedirectPath = `${redirectPath}?redirect=${encodedPathname}`;

  if (condition) {
    return <Navigate to={redirectPathIfNotAllowed} replace />;
  }

  if ((isSignedIn && invertedProtected) || (!isSignedIn && !invertedProtected)) {
    return <Navigate to={isReturnPath ? composedRedirectPath : redirectPath} replace />;
  }

  if (
    allowedUsers &&
    !allowedUsers.some((u) => u.user === groupName && u.person === typeOfPerson)
  ) {
    return <Navigate to={redirectPathIfNotAllowed} replace />;
  }

  return children as JSX.Element;
};
