import { useEffect, useState } from 'react';
import {
  Banner,
  EsgInformation,
  EsgInformationTitle,
  EsgFormContainer,
  RadioSection,
  ButtonsContainer
} from './equityForm.styles';
import axios from 'axios';
import { url } from 'src/environments';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import options from 'src/constants/esgEquity';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { Controller, useForm } from 'react-hook-form';
import { setAppError } from 'src/store/app/app.slice';

export default function Equity() {
  const [showModal, setShowModal] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('pending');
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const [uploadedFiles, setUploadedFiles] = useState({
    equityPolicy: '',
    anualReport: '',
    certifications: '',
    other: ''
  });
  const user = useSelector((state) => state.user.currentUser);
  const handleReturn = () => {
    navigate('/solicitante/dashboard/esg-documentos');
  };

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  //FIle to base64
  const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader?.result?.split(',')[1];
          resolve(base64String);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleFile = async (file) => {
    if (!file) return;
    try {
      const base64String = await fileToBase64(file);
      return base64String;
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };

  //Constants esgEquity
  const [womenDirectorsOnBoard, womenDirectors, femaleShareholders, womenInCompany] = options;
  const womenDirectorsOnBoardOptions = womenDirectorsOnBoard.map((directorBoard) => ({
    value: directorBoard,
    label: directorBoard
  }));
  const womenDirectorsOptions = womenDirectors.map((director) => ({
    value: director,
    label: director
  }));
  const femaleShareholdersOptions = femaleShareholders.map((shareholders) => ({
    value: shareholders,
    label: shareholders
  }));
  const womenInCompanyOptions = womenInCompany.map((company) => ({
    value: company,
    label: company
  }));

  const onSubmit = (data) => {
    sendData(data);
  };

  //Send to server function
  const sendData = async (data) => {
    setUploadStatus('uploading');
    const fileKeys = Object.keys(uploadedFiles);
    const input = {
      id: user.id,
      files: []
    };
    for (const key of fileKeys) {
      if (!uploadedFiles[key]) {
        continue;
      }
      if (key === 'idFile') {
        let idFileCounter = 0;
        for (const file of uploadedFiles[key]) {
          const base64File = await handleFile(file);
          let fileName;
          if (idFileCounter === 0) {
            fileName = `${key}.pdf`;
          } else {
            fileName = `${key}_${idFileCounter}.pdf`;
          }
          input.files.push({
            base64File,
            folder: 'esgDocuments',
            fileName: fileName
          });
          idFileCounter++;
        }
      } else {
        const base64File = await handleFile(uploadedFiles[key]);
        input.files.push({
          base64File,
          folder: 'esgDocuments',
          fileName: `${key}.pdf`
        });
      }
    }
    const payload = {
      id: user.id,
      numberFemaleShareholdersValue: data.femaleShareholders || '',
      isCEOFemaleValue: data.femaleCEO === 'true' ? true : false,
      numberFemaleWithManagerialPositionValue: data.womenDirectors || '',
      numberFemaleDirectorsOnBoardValue: data.womenDirectorsOnBoard || '',
      numberFemaleEmployeesValue: data.womenInCompany || ''
    };
    try {
      setLocalLoading(true);
      const upload = await axios.post(`${url}/admin/documents/upload-multiple`, input);
      const responseBody = JSON.parse(upload.data.body);
      if (responseBody.result === true) {
        const equity = await axios.post(`${url}/admin/credit-analysis/gender-equity/all`, payload);
        const genderResponse = JSON.parse(equity.data.body);
        if (genderResponse.return === true) {
          setShowModal(true);
        } else {
          setAppError({
            errorCode: 'Ups, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.'
          });
        }
      } else {
        setAppError({
          errorCode: 'Ups, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.'
        });
        setLocalLoading(false);
      }
    } catch (error) {
      console.error(error);
      setAppError({
        errorCode: 'Ups, ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.'
      });
      setLocalLoading(false);
    }
  };

  return (
    <EsgFormContainer>
      <ComposedModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          navigate('/solicitante/dashboard/mi-perfil');
        }}
        messageTitle="Proceso finalizado"
        message="Has concluido el formulario exitosamente"
        type="success"
      />
      <Banner>
        <h2>
          Análisis de impacto Social y Ambiental de la PyME solicitante de crédito:
          <p> Inclusión y equidad de género.</p>
        </h2>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image+41.png`}
            alt="Finsphera ícono"
          />
        </figure>
      </Banner>
      <EsgInformation>
        <EsgInformationTitle>
          <h1>Objetivo</h1>
          <h3>
            En FinSphera buscamos fomentar un ambiente laboral más equitativo e igualitario en la
            economía mexicana. Es por ello que medimos este tema en todas las Pymes solicitantes de
            crédito. Por favor contesta las siguientes preguntas:.
          </h3>
        </EsgInformationTitle>
        <Card className="card-container" isSlim isForm>
          <Card.Row>
            <Controller
              name="womenDirectorsOnBoard"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="¿Cuál es el porcentaje de accionistas mujeres del total de accionistas de la PyME?"
                  placeholder="Selecciona una opción:"
                  isDropdown
                  wrapLabel={true}
                  options={womenDirectorsOnBoardOptions}
                  onChangeInternalValue={onChange}
                />
              )}
            />
            <Controller
              name="womenDirectors"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="Porcentaje de mujeres consejeras independientes del total de consejos independientes"
                  placeholder="Selecciona una opción:"
                  isDropdown
                  wrapLabel={true}
                  options={womenDirectorsOptions}
                  onChangeInternalValue={onChange}
                />
              )}
            />

            <Controller
              name="femaleShareholders"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="¿Qué porcentaje de directivos reportando al director general son mujeres?"
                  placeholder="Selecciona una opción:"
                  isDropdown
                  options={femaleShareholdersOptions}
                  wrapLabel={true}
                  onChangeInternalValue={onChange}
                />
              )}
            />
            <Controller
              name="womenInCompany"
              control={control}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="¿Qué porcentaje del total de empleados en la PyME son mujeres?"
                  placeholder="Selecciona una opción:"
                  isDropdown
                  options={womenInCompanyOptions}
                  wrapLabel={true}
                  onChangeInternalValue={onChange}
                />
              )}
            />
          </Card.Row>
          <RadioSection>
            <p>¿El director general de la PyME es mujer?:</p>
            <div>
              <p>Sí</p>
              <input type="radio" {...register('femaleCEO')} value={true} />
            </div>
            <div>
              <p>No</p>
              <input type="radio" {...register('femaleCEO')} value={false} />
            </div>
          </RadioSection>
        </Card>
        <EsgInformationTitle>
          <h1>Documentos de comprobación</h1>
          <h3>
            Captura alguno(s) de los siguientes documentos en caso de que la PyME cuente con ellos:
          </h3>
        </EsgInformationTitle>
        <Card className="card-container" isForm>
          <Card.Row>
            <InputUpload
              label="Política de Equidad de Género de la Pyme:"
              id="equityPolicy"
              size="small"
              accept=".pdf"
              onChange={(e) => {
                setUploadedFiles({
                  ...uploadedFiles,
                  equityPolicy: e.target.files?.[0] || null
                });
              }}
            >
              Cargar archivo
            </InputUpload>
            <InputUpload
              label="Reporte Anual de Sustentabilidad:"
              id="anualReport"
              size="small"
              accept=".pdf"
              onChange={(e) => {
                setUploadedFiles({
                  ...uploadedFiles,
                  anualReport: e.target.files?.[0] || null
                });
              }}
            >
              Cargar archivo
            </InputUpload>
            <InputUpload
              label="Certificaciones de instituciones y organismos externos relacionados con la equidad
                  de género:"
              id="certifications"
              size="small"
              accept=".pdf"
              wrapLabel={true}
              onChange={(e) => {
                setUploadedFiles({
                  ...uploadedFiles,
                  certifications: e.target.files?.[0] || null
                });
              }}
            >
              Cargar archivo
            </InputUpload>
            <InputUpload
              label="Otro documento que compruebe que la Pyme realiza actividades que promueven
                  la equidad de género:"
              id="other"
              size="small"
              accept=".pdf"
              wrapLabel={true}
              onChange={(e) => {
                setUploadedFiles({
                  ...uploadedFiles,
                  other: e.target.files?.[0] || null
                });
              }}
            >
              Cargar archivo
            </InputUpload>
          </Card.Row>
        </Card>
      </EsgInformation>
      <ButtonsContainer>
        <Button isSlim onClick={handleReturn}>
          Regresar
        </Button>
        <Button onClick={handleSubmit(onSubmit)} isSlim isLoading={localLoading}>
          {uploadStatus === 'Completado' ? 'Actualizar archivos' : 'Continuar'}
        </Button>
      </ButtonsContainer>
    </EsgFormContainer>
  );
}
