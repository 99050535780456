import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  CampaignInvoicesContainer,
  ButtonContainer,
  InvoiceSection,
  CreateCampaign,
  FormDataTitle,
  NavigationSection,
  RequestedSection
} from './addInvoices.styles';
import createId from 'src/functions/createId';
import { useForm } from 'react-hook-form';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { usedInvoices, validateInvoicesThunk } from 'src/store/Campaigns/campaign.slice';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { url } from 'src/environments';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { PlatformBanner } from 'src/utilities/UILibrary';

const defaultInvoice = () => ({
  id: createId(),
  xml: null,
  pdf: null,
  hasXML: false,
  hasPDF: false,
  isValid: true
});
const MAX_INVOICES = 8;

export default function AddInvoices() {
  const [showModal, setShowModal] = useState(false);
  const [invoices, setInvoices] = useState([defaultInvoice()]);
  const [reset, setReset] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [amountError, setAmountError] = useState();
  const [fileNamesFromAPI, setFileNamesFromAPI] = useState([]);
  const [hasValidated, setHasValidated] = useState(false);
  const [fileSelected, setFileSelected] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [uploadStatus, setUploadStatus] = useState('pending');
  const calculatedCampaignData = useSelector((state) => state.campaign.calculatedCampaignData);
  const collectedAmount = useSelector((state) => state.campaign.collectedAmount);
  const [usedInvoiceFileName, setUsedInvoiceFileName] = useState(null);
  function formatAsMexicanPesos(amount) {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(amount);
  }
  ///
  const uploadedFileNames = invoices
    .map((_, index) => [`invoice_${index + 1}.xml`, `invoice_${index + 1}.pdf`])
    .flat();
  const validFileNames = uploadedFileNames.filter((name) => fileNamesFromAPI.includes(name));
  const invalidFileNames = uploadedFileNames.filter((name) => !fileNamesFromAPI.includes(name));

  //RequestAmount and Invoices total value
  const requestedAmount = calculatedCampaignData?.montoSolicitado;
  const generatedId = calculatedCampaignData?.campaignId;
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const hours = String(today.getHours()).padStart(2, '0');
    const minutes = String(today.getMinutes()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}`;
  }
  const {
    formState: { errors },
    watch,
    register
  } = useForm();
  const addInvoice = () => {
    if (invoices.length < MAX_INVOICES) {
      setInvoices((prev) => [...prev, defaultInvoice()]);
    }
  };
  const isChecked = watch('invoicesCrowdfunding');
  const isButtonDisabled =
    !invoices.some((invoice) => invoice.hasXML && invoice.hasPDF) || !isChecked;
  const removeInvoice = () => {
    setInvoices((prev) => {
      const updated = [...prev];
      updated.pop();
      return updated;
    });
  };
  const handleBack = () => {
    navigate(`/solicitante/dashboard/crear-campaña`);
  };
  const modalErrorMessages = {
    usedInvoice: `La factura ${usedInvoiceFileName} ya está siendo utilizada en otra campaña, por favor agrega otra`,
    validateInvoicesError: 'Las facturas que subiste no se encuentran en el SAT.',
    amountError: `El monto total de las facturas es: ${amountError}. Solicita un monto menor o carga facturas que den un monto mayor o igual al monto del crédito.`,
    generic: 'Ocurrió un error'
  };

  const buttonShouldBeDisabled = () => {
    if (isButtonDisabled) return true;
  };

  const handleFileChange = (type, index) => {
    return async (event) => {
      const file = event.target.files[0];
      if (file) {
        try {
          const base64String = await fileToBase64(file);
          setInvoices((prevInvoices) => {
            const newInvoices = [...prevInvoices];
            if (type === 'xml') {
              newInvoices[index].hasXML = true;
              newInvoices[index].xml = base64String;
            } else if (type === 'pdf') {
              newInvoices[index].hasPDF = true;
              newInvoices[index].pdf = base64String;
            }
            return newInvoices;
          });
          setFileSelected(true);
          setReset(false);
        } catch (error) {
          console.error('Error converting file:', error);
        }
      }
    };
  };
  useEffect(() => {
    if (showModal) {
      // Additional logic
    }
  }, [showModal]);

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
        setFileSelected(true);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  //Upload invoices to s3
  const uploadDocuments = async () => {
    const filesArray = invoices
      .map((invoice, index) => [
        {
          base64File: invoice.xml,
          folder: `invoices/${getCurrentDate()}`,
          fileName: `invoice_${index + 1}.xml`
        },
        {
          base64File: invoice.pdf,
          folder: `invoices/${getCurrentDate()}`,
          fileName: `invoice_${index + 1}.pdf`
        }
      ])
      .flat();
    const input = {
      id: calculatedCampaignData.applicantId,
      files: filesArray
    };
    setUploadStatus('uploading');
    await axios.post(`${url}/admin/documents/upload-multiple`, input);
  };

  //Invoices validation
  const validateInvoices = async () => {
    try {
      const validationResponse = await dispatch(validateInvoicesThunk(user.id));
      let body = validationResponse.payload.validateInvoicesResponse.data.body;
      if (typeof body === 'string') {
        body = JSON.parse(body);
      }
      if (body.notExist && body.notExist.length > 0) {
        setErrorType('validateInvoicesError');
        setShowModal(true);
        return { isValid: false };
      } else if (body && Array.isArray(body.exist)) {
        const invoicesData = body.exist.map((invoice) => ({
          satwsId: invoice.satwsId,
          uuid: invoice.uuid,
          fileName: invoice.fileNameXML
        }));
        return { isValid: true, invoicesData };
      }
    } catch (error) {
      console.error('Error validating invoices:', error);
      // Handle error
      return { isValid: false };
    }
  };

  const checkUsedInvoices = async (invoicesData) => {
    try {
      const postData = {
        id: user.id,
        invoices: invoicesData,
        requestedAmount: parseFloat(requestedAmount)
      };
      const usedInvoicesResponse = await dispatch(usedInvoices(postData));
      const response = unwrapResult(usedInvoicesResponse);
      // Handle the response for used invoices
      if (response && response.used) {
        const usedInvoiceFileNames = response.usedInvoices.map((invoice) => invoice.fileName);
        setUsedInvoiceFileName(usedInvoiceFileNames);
        setErrorType('usedInvoice');
        setShowModal(true);
        return { canProceed: false, usedInvoiceFileNames };
      } else if (!response.canProceed) {
        setErrorType('amountError');
        const totalAmountFormatted = formatAsMexicanPesos(response.totalAmount);
        setAmountError(totalAmountFormatted);
        setShowModal(true);
        return { canProceed: false };
      } else {
        return { canProceed: true };
      }
    } catch (error) {
      console.error('Error checking used invoices:', error);
    }
  };

  //Buttons logic
  const handleNext = async () => {
    setLocalLoading(true);
    let isSuccess = true;
    try {
      await uploadDocuments();
      const validationResponse = await validateInvoices();
      if (!validationResponse.isValid) {
        setShowModal('validateInvoicesError');
        isSuccess = false;
        setShowModal(true);
        return;
      }
      const invoiceCheckResponse = await checkUsedInvoices(validationResponse.invoicesData);
      if (!invoiceCheckResponse.canProceed) {
        isSuccess = false;
        return;
      }
    } catch (error) {
      console.error('Error unwrapping result:', error);
      isSuccess = false;
      setShowModal(true);
      return;
    } finally {
      setLocalLoading(false);
      if (isSuccess) {
        navigate('/solicitante/dashboard/obligado-solidario');
      }
    }
  };

  return (
    <CreateCampaign>
      <ComposedModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setErrorType(null);
        }}
        message={modalErrorMessages[errorType] || modalErrorMessages.generic}
        type="error"
      />
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_companyInformation_banner.png`}
        imgAlt="Icono de información personal"
        title="Crear campaña"
        className="onboarding-banner"
      />
      <CampaignInvoicesContainer>
        <FormDataTitle>Carga de facturas: </FormDataTitle>
        <p>
          Para crear campañas es necesario que nos proporciones facturas que avalen tu capacidad de
          pago para el crédito que estás solicitando con Finsphera.
        </p>
        <ButtonContainer>
          <Button size="small" onClick={addInvoice}>
            Agregar factura
          </Button>
          <Button size="small" onClick={removeInvoice}>
            Eliminar factura
          </Button>
        </ButtonContainer>
        {invoices.map((invoice, index) => {
          const fileNameXml = `invoice_${index + 1}.xml`;
          const fileNamePdf = `invoice_${index + 1}.pdf`;
          const isXmlValid = validFileNames.includes(fileNameXml);
          const isPdfValid = validFileNames.includes(fileNamePdf);
          return (
            <InvoiceSection key={invoice.id}>
              <div>
                <p>Archivo XML del CFDI</p>
                <InputUpload
                  id={`cfdi-xml-${index}`}
                  size="small"
                  accept=".xml"
                  onChange={handleFileChange('xml', index)}
                  value={reset ? '' : undefined}
                  disabled={isXmlValid}
                >
                  Cargar archivo
                </InputUpload>
                {hasValidated && invalidFileNames.includes(fileNameXml) && (
                  <span>Cargar de nuevo esta factura</span>
                )}
              </div>
              <div>
                <p>Archivo PDF del CFDI</p>
                <InputUpload
                  id={`cfdi-pdf-${index}`}
                  size="small"
                  accept=".pdf"
                  onChange={handleFileChange('pdf', index)}
                  value={reset ? '' : undefined}
                  disabled={isPdfValid}
                >
                  Cargar archivo
                </InputUpload>
                {hasValidated && invalidFileNames.includes(fileNamePdf) && (
                  <span>Cargar de nuevo esta factura</span>
                )}
              </div>
            </InvoiceSection>
          );
        })}
        <Checkbox
          label="Como representante legal de la PyMe, confirmo que las facturas que estoy subiendo en este apartado de Finsphera, no están siendo ofertadas en otra plataforma de Crowdfunding"
          {...register('invoicesCrowdfunding', {
            required: 'Es un campo requerido para continuar.'
          })}
          errorMessages={
            errors.invoicesCrowdfunding && errors.invoicesCrowdfunding.message
              ? [errors.invoicesCrowdfunding.message]
              : []
          }
        />
        {requestedAmount > 0 && collectedAmount > 0 && requestedAmount > collectedAmount ? (
          <RequestedSection>
            <p>
              El monto solicitado no puede exceder al monto total de las facturas. Por favor
              verifica el monto solicitado y el valor de las facturas que has subido.
            </p>
            <Button onClick={() => navigate(`/solicitante/dashboard/crear-campaña`)}>
              Volver y corregir
            </Button>
          </RequestedSection>
        ) : null}
      </CampaignInvoicesContainer>
      <NavigationSection>
        <Button onClick={handleBack}>Regresar</Button>
        <Button onClick={handleNext} isLoading={localLoading} disabled={buttonShouldBeDisabled()}>
          {uploadStatus === 'completed' ? 'Siguiente' : 'Siguiente'}
        </Button>
      </NavigationSection>
    </CreateCampaign>
  );
}
