import { forwardRef } from 'react';
import { ProgressBarContainer } from './styles';
import { ProgressBarProps } from './types';

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>((props, ref) => {
  const { actualPercentage = 0, animated = true, size = 'medium', ...rest } = props;

  return (
    <ProgressBarContainer
      ref={ref}
      actualPercentage={actualPercentage}
      animated={animated}
      size={size}
      {...rest}
    >
      <div className="actual-progress">
        {actualPercentage > 9 ? <p>{actualPercentage}%</p> : null}
      </div>
    </ProgressBarContainer>
  );
});

ProgressBar.displayName = 'ProgressBar';
