import styled from 'styled-components/macro';

import {
  greenSp,
  whiteSphera,
  grayPlaceholder,
  blackSphera,
  neutralBlueGray,
  grayDarkSphera
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ModalCompanyDetailInvestPay = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalCompanyDetailInvestPay ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalCompanyDetailInvestPay ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 70%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid #b7bcc7;

      > h3 {
        font-size: ${uIFontSize.h3};
        color: ${blackSphera};
        width: 90%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > div:nth-child(1) {
        > h3 {
          font-size: ${uIFontSize.h3};
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;

      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;

        > h3 {
          font-size: ${uIFontSize.h3};
        }
      }
    }
  }
`;

export const DetailInvestPay = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1% auto 2.5% auto;

  > div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > button:nth-child(1) {
        font-size: ${uIFontSize.paragraph};
      }

      > button:nth-child(2) {
        font-size: ${uIFontSize.paragraph};
      }
    }

    > div:nth-child(2) {
      > div:nth-child(1),
      > div:nth-child(2) {
        width: 50%;
        > h3 {
          font-size: ${uIFontSize.h3};
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 5% 5% 0 5%;

    > div:nth-child(1) {
      flex-direction: column;

      > button:nth-child(1) {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
        margin-bottom: 5%;
      }

      > button:nth-child(2) {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
      }
    }

    > div:nth-child(2) {
      flex-direction: column;

      > div:nth-child(1),
      > div:nth-child(2) {
        width: 100%;
        margin-top: 5%;

        > h3 {
          font-size: ${uIFontSize.h3};
        }

        > ul {
          > li {
            font-size: ${uIFontSize.paragraph};
          }
        }
      }
    }

    > div:nth-child(3) {
      > p {
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const GeneralInformation = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5%;
  > div {
    width: 40%;

    > h3 {
      color: ${blackSphera};
      font-size: ${uIFontSize.h3};
      margin-bottom: 5%;
    }

    > ul {
      list-style: none;

      > li {
        color: ${blackSphera};
        font-size: ${uIFontSize.paragraph};
        margin-bottom: 1%;

        > span {
          color: ${greenSp};
          font-weight: bold;
          font-size: ${uIFontSize.paragraph};
        }
      }
    }
  }
`;

export const Recordatory = styled.div`
  > p {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${neutralBlueGray};
  }
`;

export const Status = styled.div`
  margin: 2% 0;
  > h3 {
    font-size: ${uIFontSize.xxLarge};
    font-weight: bold;
    color: ${neutralBlueGray};
  }
  > p {
    font-size: ${uIFontSize.xLarge};
    font-weight: bold;
    color: ${neutralBlueGray};
    text-align: center;
  }
`;

export const ExtraInfo = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: left;
  > div {
    width: 50%;
    > p {
      font-size: ${uIFontSize.large};
      font-weight: bold;
      color: ${neutralBlueGray};
    }
  }
`;

export const AccountSection = styled.section`
  background: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
`;

export const AccountHeader = styled.div`
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
`;

export const AccountInfo = styled.div`
  margin-bottom: 10px;
  width: 50%;
  > ul {
    > li {
      > p {
        font-weight: bold;
        font-size: ${uIFontSize.large};
      }
    }
  }
`;

export const AccountDetails = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  align-items: left;
  > div {
    > h3 {
      background-color: ${grayDarkSphera};
      color: ${whiteSphera};
      font-size: ${uIFontSize.large};
    }
    border: 1px solid ${grayPlaceholder};
    p {
      margin: 0;
      font-size: ${uIFontSize.xLarge};
      text-align: center;
    }
  }
`;
