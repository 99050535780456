import getCurrentDate from './getCurrentDate';

//Function to store the invoices
export const transformPostData = (data, campaignId) => {
  return {
    id: data.id,
    campaignId: campaignId,
    invoices: data.invoices.map((invoice) => ({
      satwsId: invoice.satwsId,
      Key: `/invoices/${getCurrentDate()}/${invoice.fileName}`
    }))
  };
};

export default getCurrentDate;
