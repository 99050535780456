import styled from 'styled-components/macro';
import { InputTextAreaProps } from './inputTextArea.types';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

type InputTextAreaContainerProps = Pick<InputTextAreaProps, 'wrapLabel'>;

export const InputTextAreaContainer = styled.div<InputTextAreaContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 9.375rem;
  gap: 0.3rem;

  label {
    white-space: ${(p) => (p.wrapLabel ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  textarea {
    padding-left: 0.5rem;
    font-size: ${uIFontSize.paragraph};
  }
`;
