import styled from 'styled-components/macro';
import {
  blackSphera,
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  brandYellowgreen
} from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalCampaignCancel = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${(props) => (props.showCancelCampaign ? fadeIn : fadeOut)} 0.5s linear forwards;
  visibility: ${(props) => (props.showCancelCampaign ? 'visible' : 'hidden')};

  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;

    > h3 {
      font-size: 1.5vw;
      color: ${grayMediumSphera};
    }
    > h2 {
      font-size: 2.5vw;
      text-align: center;
      color: ${brandBlack};
      > span {
        color: ${greenSp};
        font-size: 2.5vw;
      }
    }
    > p {
      color: ${brandBlack};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h2 {
        > span {
          font-size: 2.5vw;
        }
      }

      > a {
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > h2 {
        font-size: 7vw;
        text-align: center;

        > span {
          font-size: 6.5vw;
        }
      }

      > a {
        font-size: 4.3vw;
        width: 100%;
        padding: 3% 8%;

        &:hover {
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  border-radius: 5px;
  padding: 3%;
  > figure {
    display: flex;
    justify-content: center;
    > img {
      align-self: center;
    }
  }
`;

export const CloseButton = styled.button`
  color: ${whiteSphera};
  font-size: 1.2vw;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 5% 30%;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandBlack};
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5% 0;
  p {
    width: 100%;
    font-size: 1.4vw;
    color: ${grayMediumSphera};
    margin-bottom: 5px;
    text-align: center;
    font-weight: 100;
  }
  > a {
    color: ${greenSp};
    text-align: center;
    font-size: 1.4vw;
    font-weight: bold;
  }
  @media (max-width: 600px) {
    > p {
      font-size: 3vw;
    }
    > a {
      font-size: 3vw;
    }
  }
`;
