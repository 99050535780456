import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { url } from 'src/environments';
import axios from 'axios';
import {
  ButtonsContainer,
  CampaignCode,
  CloseButton,
  ConfirmButton,
  ModalDeleteRegisterCLABE,
  ContentContainer,
  RegisterCLABEAdvice
} from './deleteRegisterCLABE.styles';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

export default function DeleteRegisterCLABE({
  closeModal,
  showModalDeleteCLABE,
  clabeNumber,
  onConfirm
}) {
  const [messageId, setMessageId] = useState();
  const [localLoading, setLocalLoading] = useState(false);
  const [type, setType] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  useEffect(() => {
    if (user.groupName === 'investor') {
      setType('investor');
    } else {
      setType('applicant');
    }
  }, [user.groupName]);

  const handleSendEmail = async (event) => {
    const input = {
      id: user.id,
      typeOfUser: type,
      typeMessage: 'deleteCLABE',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };
  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setLocalLoading(true);
    const input = {
      id: user.id,
      messageId: messageId,
      code: verificationCode,
      timestamp: Date.now(),
      typeOfUser: type
    };
    const body = {
      type: 'delete_user_CLABE',
      input: {
        CLABE: clabeNumber
      }
    };
    try {
      const validationCodeResponse = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCodeResponse.data.body);
      if (!parsedBody.isVerified) {
        alert('Validación de código fallida');
        setLocalLoading(false);
        return;
      }
      await axios.post(`${url}/admin/dynamo/update`, body);
      // onConfirm();
      setSuccessText(true);
    } catch (error) {
      console.error(error);
    }
  };

  const onClose = () => {
    closeModal();
    setShowInput(false);
  };
  const acceptButton = () => {
    closeModal();
    navigate('/inversionista/dashboard/cuenta-clabe');
  };

  return (
    <ModalDeleteRegisterCLABE showModalDeleteCLABE={showModalDeleteCLABE}>
      {successText ? (
        <ContentContainer>
          <RegisterCLABEAdvice>
            <div>
              <figure>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
                  alt=""
                />
              </figure>
              <p>Tu cuenta bancaria ha sido dado de baja exitosamente.</p>
              <CloseButton onClick={acceptButton}>Aceptar</CloseButton>
            </div>
          </RegisterCLABEAdvice>
        </ContentContainer>
      ) : (
        <ContentContainer>
          <RegisterCLABEAdvice>
            <div>
              <figure>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_warning.png`}
                  alt=""
                />
              </figure>
              <h2>Estás a punto de eliminar tu cuenta bancaria:</h2>
              <p>{clabeNumber}</p>
            </div>
          </RegisterCLABEAdvice>
          {showInput ? (
            <>
              <CampaignCode>
                <h3>Ingresa el código de verificación</h3>
                <>
                  <InputField
                    value={verificationCode}
                    maxLength={6}
                    onChangeInternalValue={(value) => setVerificationCode(value)}
                    type="number"
                    placeholder="Código de verificación"
                  />
                </>
              </CampaignCode>
              <ButtonsContainer>
                <CloseButton onClick={onClose}>Cancelar</CloseButton>
                <Button isLoading={localLoading} onClick={handleOtpSubmit}>
                  Confirmar código
                </Button>
              </ButtonsContainer>
            </>
          ) : (
            <ButtonsContainer>
              <CloseButton onClick={closeModal}>Cancelar</CloseButton>
              <ConfirmButton onClick={handleSendEmail}>Aceptar</ConfirmButton>
            </ButtonsContainer>
          )}
        </ContentContainer>
      )}
    </ModalDeleteRegisterCLABE>
  );
}
