import styled from 'styled-components/macro';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { spacing } from 'src/utilities/UILibrary2024/stylesTokens';

export const HomeContainer = styled.div`
  background-color: ${colors.background.app};
  padding: 0 ${spacing.appHorizontalPadding.landingPage.mobile};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 ${spacing.appHorizontalPadding.landingPage.tablet};
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 ${spacing.appHorizontalPadding.landingPage.desktop};
  }
`;
