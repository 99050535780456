import styled from 'styled-components/macro';

import {
  blueSphera,
  graySphera,
  whiteSphera,
  grayLightSphera,
  greenSp,
  brandBlack,
  neutralBlueGray,
  grayPlaceholder
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalWithdrawVirtualAccount = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalWithdrawVirtualAccount ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalWithdrawVirtualAccount ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 50%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 1%;

    > div:nth-child(1) {
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > div:nth-child(1) {
        > h3 {
          font-size: 2vw;
        }

        > figure {
          > img {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
`;

export const Headers = styled.section`
  padding: 2.5% 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${grayPlaceholder};
  align-items: center;
  width: 100%;
  > h3 {
    font-size: 1.8vw;
    margin-left: 5%;
  }

  > figure {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-right: 2%;

    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 600px) {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 5%;
    > h3 {
      font-size: 4.2vw;
    }
  }
`;

export const DetailWithdraw = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5%;
  width: 90%;
  margin: 1% auto 2.5% auto;

  > h3 {
    color: ${graySphera};
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    margin: 2% 0;
    > p {
      font-size: 2vw;
      color: ${graySphera};
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.5%;
    margin-bottom: 2.5%;

    > p {
      font-size: 1.2vw;
      width: 45%;
      color: ${graySphera};
    }

    > input {
      font-size: 1.2vw;
      color: ${graySphera};
      width: 60%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
    > select {
      font-size: 1.2vw;
      color: ${graySphera};
      width: 60%;
      height: 35px;
      padding-left: 2.5%;
      border-radius: 5px;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      font-size: 1.5vw;
    }
    > div {
      > p {
        font-size: 1.5vw;
        width: 45%;
      }

      > input {
        font-size: 1.5vw;
      }
      > select {
        font-size: 1.5vw;
      }
    }
  }

  @media (max-width: 600px) {
    > h3 {
      font-size: 3.5vw;
      > p {
        font-size: 3.5vw;
        color: ${graySphera};
      }
    }
    > div {
      > p {
        font-size: 2.5vw;
      }
      > input {
        font-size: 2.5vw;
      }
      > select {
        font-size: 2.5vw;
      }
    }
  }
`;

export const ButtonClose = styled.div`
  background-color: ${brandBlack};
  color: ${whiteSphera};
  font-weight: bold;
  font-size: 1.2vw;
  text-align: center;
  width: 45%;
  padding: 2% 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-content: center;
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    padding: 3.5% 0;
    font-size: 2.5vw;
  }
`;

export const ButtonConfirm = styled.div`
  background-color: ${greenSp};
  color: ${whiteSphera};
  font-weight: bold;
  font-size: 1.2vw;
  text-align: center;
  width: 45%;
  padding: 2% 0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  align-content: center;
  @media (min-width: 600px) and (max-width: 1024px) {
    /* padding: 2% 0;
    font-size: 1.2vw; */
  }
  @media (max-width: 600px) {
    padding: 3.5% 0;
    font-size: 2.5vw;
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: 1.6vw;
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: 1.4vw;
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    > a {
      color: ${greenSp};
      font-size: 1.4vw;
      font-weight: bold;
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin: 2% 5%;
  background-color: ${whiteSphera};
  > p {
    font-size: 1.4vw;
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    width: 100%;
    text-align: center;
    font-weight: bold;
  }
  > input {
    color: ${grayPlaceholder};
    font-size: 1.6vw;
    width: 60%;
    text-align: center;
    border-radius: 5px;
    border: 1px solid ${grayPlaceholder};
    margin: 2% 10%;
    letter-spacing: 2vw;
  }
`;
