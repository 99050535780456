import styled, { keyframes } from 'styled-components/macro';
import { uIFontSize } from '../../../UIStyles/variables/typography';
import { breakpoints } from '../../../UIStyles/variables/breakpoints';
import { error, warning } from '../../../UIStyles/variables/colors';
import { InputProps } from './inputField.interface';

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const InputContainer = styled.div<InputProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 9.375rem;
  gap: 0.3rem;

  label {
    white-space: ${(p) => (p.wrapLabel ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  input,
  select {
    width: 100%;
    height: 2rem;
    border: ${(props) => (props.variant === 'flushed' ? 'none' : '1px solid black')};
    border-bottom: 1px solid black;
    border-radius: ${(props) => (props.variant === 'flushed' ? '0' : '5px')};
    z-index: 10;
    font-size: ${uIFontSize.paragraph};

    &:focus {
      outline: ${(props) => (props.variant === 'flushed' ? 'none' : '1px solid black')};
      border-bottom: ${(props) =>
        props.variant === 'flushed' ? '2px solid black' : '1px solid black'};
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  input {
    padding-left: ${(props: InputProps) => (props.isDialCode === true ? '6rem' : '0.5rem')};
  }

  select {
    background-color: transparent;
    padding: 0 0.2rem;
  }

  select:invalid {
    color: gray;
  }

  .is-error {
    border-color: ${(props: InputProps) => (props.errortype === 'error' ? error : warning)};
  }

  .input-wrapper {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .svg-eye {
      position: absolute;
      right: 1rem;
      width: 1.25rem;
      z-index: 10;
      cursor: pointer;
      user-select: none;
    }
  }

  .dial-code-container {
    position: absolute;
    left: 0.5rem;
    display: flex;
    align-items: center;
    max-width: 5rem;

    .dial-code-select {
      position: relative;
      width: initial;
      max-width: 3.125rem;
      height: 100%;
      font-size: 1.5rem;
      z-index: 100;
      border: none;
    }

    .dial-code-select:focus {
      outline: none;
    }

    .dial-code {
      position: relative;
      z-index: 100;
      color: gray;
      user-select: none;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    input,
    select {
      height: 2.5rem;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .input-wrapper {
      .svg-eye {
        width: 1.5rem;
      }
    }
  }
`;

export const ErrorContainer = styled.span`
  animation: ${slideDown} 0.3s ease-out;

  ul {
    list-style-position: inside;
    color: ${(props: InputProps) => (props.errortype === 'error' ? error : warning)};

    li {
      font-size: ${uIFontSize.small};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .error-container {
      ul li {
        font-size: 0.875rem;
      }
    }
  }
`;
