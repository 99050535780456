import styled from 'styled-components';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { FooterProps } from './types';

export const FooterContainer = styled.footer<FooterProps>`
  color: #fff;
  background-color: ${colors.background.footer};
  padding: 1rem;
  font-size: ${uIFontSize.small};
  max-width: 100vw;
  font-weight: 100;

  h4 {
    color: ${colors.text.headerYellow};
  }

  .footer-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    grid-auto-rows: auto;

    a {
      color: ${colors.text.default};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    .heading {
      font-size: ${uIFontSize.subTitle};
      margin-bottom: 0.625rem;
    }

    .list {
      list-style: none;
      padding: 0;
    }

    .list-item {
      margin-bottom: 0.3125rem;
    }

    .social-media-list {
      display: flex;
      gap: 1rem;
    }

    .svg-socialmedia {
      width: 2.25rem;
      height: 2.25rem;
    }

    .social-media-section {
      grid-column: span 2;
    }

    .compliance-section {
      ul {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
      }

      img {
        height: 50px;
      }
    }
  }

  .footer-legal {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #fff;
    margin-top: 3.25rem;
    padding-top: 1.25rem;

    .image-container {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      margin-bottom: 1rem;

      img {
        width: 200px;
      }
    }

    a {
      color: ${colors.text.link};
      text-decoration: none;
    }

    p {
      color: ${colors.text.white};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 2.5rem 4.25rem 4.25rem;

    .footer-content {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
      grid-auto-rows: auto;
      padding: 0;

      .heading {
        font-size: ${uIFontSize.title};
        margin-bottom: 1.25rem;
      }

      .section h4 {
        margin-top: 0;
      }

      .list-item {
        margin-bottom: 0.625rem;
      }
    }

    .footer-legal {
      padding: 0;
      flex-direction: row;
      gap: 5.625rem;

      .image-container {
        display: flex;
        align-items: center;
        min-width: 14.375rem;

        img {
          width: 240px;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 4.5rem 6.25rem 6.25rem;

    .footer-content {
      padding: 0 5rem;
    }

    .footer-legal {
      padding: 3.125rem 5rem 0;
    }
  }
`;
