import styled from 'styled-components/macro';
import { blackSphera, greenSp, neutralBlueGray } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const SignupContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
`;

export const SignupInfo = styled.div`
  width: 50%;
  padding: 2% 0 0 5%;
  > form {
    > button {
      background-color: ${greenSp};
      color: ${blackSphera};
      padding: 1% 3%;
      margin: 2%;
      border-style: none;
      border-radius: 5px;
      width: 45%;
      font-size: ${uIFontSize.medium};
      cursor: pointer;
      font-weight: bold;
    }
    > p {
      margin-bottom: 5%;
      color: ${neutralBlueGray};
      font-size: ${uIFontSize.large};
    }
  }
  > h2 {
    color: ${blackSphera};
    font-size: ${uIFontSize.xxLarge};
    line-height: 50px;
    width: 82%;
    margin-bottom: 20%;
    > span {
      color: ${greenSp};
      font-size: ${uIFontSize.xxLarge};
    }
  }

  @media (min-width: 2561px) {
    width: 50%;
    > h2 {
      font-size: 3rem;
      > span {
        font-size: 3rem;
      }
    }
    > form {
      > button {
        font-size: ${uIFontSize.large};
        cursor: pointer;
        font-weight: bold;
        padding: 1% 3%;
      }
      > p {
        font-size: ${uIFontSize.xxLarge};
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1900px) {
    width: 40%;
    padding: 5% 0 0 5%;
    > h2 {
      line-height: 30px;
      width: 100%;
      font-size: ${uIFontSize.xxxLarge};
      > span {
        font-size: ${uIFontSize.xxxLarge};
      }
    }
    > form {
      > button {
        padding: 2% 0;
        width: 45%;
        font-size: ${uIFontSize.medium};
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 40%;
    > h2 {
      line-height: 30px;
      font-size: ${uIFontSize.xxLarge};
    }
    > form {
      > button {
        padding: 2% 0;
        width: 45%;
        font-size: ${uIFontSize.medium};
      }
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    padding: 5% 0 5% 5%;
    > h2 {
      font-size: ${uIFontSize.xxLarge};
      line-height: 40px;
      width: 95%;
      margin-bottom: 2.5%;
    }
    > form {
      > button {
        background-color: ${greenSp};
        color: ${blackSphera};
        padding: 3%;
        margin: 2%;
        border-style: none;
        border-radius: 5px;
        width: 45%;
        font-size: ${uIFontSize.medium};
      }
    }
  }
`;

export const SignupForm = styled.div`
  display: flex;
  width: 60%;
  height: 60%;
  > img {
    width: 90%;
    height: auto;
    padding: 2% 0 5% 0;
  }

  @media (min-width: 2561px) {
    width: 50%;
    > img {
      width: auto;
      padding: 2% 0;
      margin: 0 0 0 10%;
    }
  }

  @media (min-width: 1024px) and (max-width: 1900px) {
    > img {
      padding: 0 0 5% 0;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > img {
      width: 90%;
      height: auto;
      margin: 0 0 0 10%;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    > img {
      width: 100%;
      height: auto;
      margin: 0;
    }
  }
`;
