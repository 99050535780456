import { ProgressWheelProps } from './types';
import styled from 'styled-components';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ProgressWheelContainer = styled.div<ProgressWheelProps>`
  position: relative;
  aspect-ratio: 1 / 1;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
    position: relative;
  }

  circle {
    fill: none;
    stroke-linecap: round;
  }

  .path-mask {
    stroke-linecap: round;
    transform-origin: center center;
  }

  .progress {
    stroke-linecap: round;
    stroke-dasharray: 100;
    transform-origin: center center;
    transform: rotate(-90deg);
    transition: stroke-dashoffset 0.3s ease-out;
  }

  .value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${colors.text.description};
    font-size: ${uIFontSize.small};
  }
`;
