import styled from 'styled-components';
import { blackSphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const OptionToggleContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  background-color: ${blackSphera};
  max-height: 2rem;

  p {
    color: ${whiteSphera};
    font-size: ${uIFontSize.paragraph};
  }

  span {
    width: 1px;
    height: 1.2rem;
    background-color: ${whiteSphera};
  }
`;
