import { CollectionComplaintsContainer } from './collectionComplaints.styles';
import { Card, Button, InputField, PlatformBanner, InputTextArea } from 'src/utilities/UILibrary';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { setAppError } from 'src/store/app/app.slice';
import { setLoadingState, userSelector } from 'src/store/User/user.slice';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from 'src/environments';
import productRedeco from 'src/constants/redeco';

export type FormValues = {
  name: string;
  clientID: string;
  isPORI: string;
  complaintReason: string;
};

export const CollectionComplaints = () => {
  const { handleSubmit, control } = useForm<FormValues>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const [showInfoModal, setShowInfoModal] = useState(false);
  const subproductName = productRedeco.map((product) => ({
    value: product.code,
    label: product.name
  }));

  const isPORI = [
    { value: 'SI', label: 'Sí' },
    { value: 'NO', label: 'No' }
  ];

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      //Validar si el usuario existe en dynamo
      //Después se construye este body para API
      const body = {
        id: user.id,
        typeOfUser: 'investor',
        QuejasFecRecepcion: '01/03/2024',
        product: '029713671557',
        CausasId: data.complaintReason,
        QuejasPORI: data.isPORI,
        createdAt: '2024-03-01T01:01:01.000Z',
        updatedAt: '2024-03-01T01:01:01.000Z'
      };
      dispatch(setLoadingState(true));
      const result = await axios.post(`${url}/redeco/despacho-cobranza-quejas`, body);
      if (result.status === 200 && result.data === 'true') {
        setIsSuccessModalOpen(true);
      } else {
        dispatch(setAppError({}));
      }
    } catch (error) {
      console.error(error);
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <CollectionComplaintsContainer>
      <ComposedModal
        type="success"
        isOpen={isSuccessModalOpen}
        onClose={() => navigate('/')}
        message="¡Gracias por tu reporte! Tu contribución es valiosa y nos ayuda a mejorar. Revisaremos tu reporte lo más pronto posible."
      />
      <ComposedModal
        type="warning"
        isOpen={showInfoModal}
        onClose={() => setShowInfoModal(false)}
        messageTitle="¿Dónde encontrar tu ID de usuario?"
        message="Tu ID de usuario se encuentra en la sección Mi Perfil en tu dashboard, al cual podrás acceder si eres un usuario solicitante de crédito. Si tienes alguna duda, por favor comunícate con nuestro equipo de soporte."
      />
      <PlatformBanner
        title="Tu voz, nuestra prioridad"
        description="Entendemos la importancia de cada queja y la tratamos con la mayor seriedad. Este formulario es tu canal directo para reportar incidencias con nuestras actividades de cobranza, garantizando la confidencialidad de tu información y comprometiéndonos a una mejora continua."
        className="landing-banner"
      />

      <Card
        isForm
        isSlim
        className="landing-card anonymous-mailbox-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card.Row>
          <h2>Datos Generales</h2>
        </Card.Row>
        <Card.Row itemPosition="left" direction="column">
          <p>
            <b>1. Nombre</b>
          </p>
          <Controller
            name="name"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Nombre completo:"
                onChangeInternalValue={field.onChange}
                placeholder="Tu nombre completo aquí"
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row itemPosition="left" direction="column">
          <p>
            <b>2. ¿Esta queja está relacionada con un robo de identidad?</b>
          </p>
          <Controller
            name="isPORI"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <>
                <InputField
                  label="Por favor, selecciona una opción*:"
                  isDropdown
                  options={isPORI}
                  onChangeInternalValue={field.onChange}
                  placeholder="..."
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              </>
            )}
          />
        </Card.Row>
        <Card.Row itemPosition="left" direction="column">
          <p>
            <b>3. Razón de la queja</b>
          </p>
          <Controller
            name="complaintReason"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Por favor, selecciona una opción*:"
                isDropdown
                options={subproductName}
                onChangeInternalValue={field.onChange}
                placeholder="..."
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row itemPosition="left">
          <Button type="submit" isSlim isFullWidth>
            Enviar Reporte
          </Button>
        </Card.Row>
      </Card>
    </CollectionComplaintsContainer>
  );
};
