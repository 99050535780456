import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import styled from 'styled-components/macro';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const ConfirmationAccountWrapper = styled.div`
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;

  .card-container {
    margin-top: 1.5rem;
    ${spaceFlowContainer()}
  }

  .radio-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: start;
    width: 100%;
  }

  .radio-group {
    display: flex;
    width: 100%;
    justify-content: start;
    gap: 2rem;

    > div {
      display: flex;
      gap: 0.5rem;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .card-container {
      margin-top: 2rem;
    }

    button {
      font-size: ${uIFontSize.medium};
    }
  }
`;
