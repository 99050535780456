import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { environment } from 'src/environments';
import Home from 'src/views/landingPage/Home/Home';
import AboutUs from 'src/views/landingPage/AboutUs/AboutUs';
import ContractsAndLegal from '../views/landingPage/ContractsAndLegal/ContractsAndLegal';
import ShareholderAndCommunity from '../views/landingPage/ShareholderAndCommunity/ShareholderAndCommunity';
import Login from 'src/views/landingPage/Login/Login';
import { ForgotPassword } from '../components/shared/password/ForgotPassword/ForgotPassword';
import ResetPassword from '../components/shared/password/resetPassword/resetPassword';
import Terms from '../components/Layout/Terms/Terms';
import Privacy from '../components/Layout/Privacy/Privacy';
import { LayoutWrapper } from '../components/Layout/LayoutWrapper/LayoutWrapper';
import { OnboardingApplicantRoutes } from 'src/routes/applicant/OnboardingApplicantRoutes';
import { DashboardApplicantRoutes } from 'src/routes/applicant/DashboardApplicantRoutes';
import { OnboardingInvestorRoutes } from 'src/routes/investor/OnboardingInvestorRoutes';
import SignupInvestor from 'src/views/landingPage/SignupInvestor/SignupInvestor';
import { DashboardInvestorRoutes } from 'src/routes/investor/DashboardInvestorRoutes';
import SignupApplicant from 'src/views/landingPage/SignupApplicant/SignupApplicant';
import MainSignup from 'src/components/shared/Signup/mainSignup';
import { ApplicantHomeLogin } from 'src/views/landingPage/ApplicantHomeLogin/ApplicantHomeLogin';
import { NotFound } from 'src/views/shared/NotFound/NotFound';
import { TestView } from 'src/views/TestView/TestView';
import { ProtectedRoute } from './ProtectedRoute';
import { ConfirmationAccount } from 'src/views/landingPage/ConfirmationAccount/ConfirmationAccount';
import { ThreatValidation } from 'src/views/landingPage/ThreatsTrully/Threat';
import { AnonymousMailbox } from 'src/views/landingPage/AnonymousMailbox/AnonymousMailbox';
import { CollectionComplaints } from 'src/views/landingPage/CollectionComplaints/CollectionComplaints';
import { LandingPageRoutes } from 'src/routes/new/';

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            environment !== 'prod' && window.location.pathname.startsWith('/beta') ? (
              <LayoutWrapper isNewDesign />
            ) : (
              <LayoutWrapper />
            )
          }
        >
          {/* Landing */}
          {environment === 'dev' ? <Route path="/beta/*" element={<LandingPageRoutes />} /> : null}
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<AboutUs />} />
          <Route path="/contratos-y-legal" element={<ContractsAndLegal />} />
          <Route path="/relacion-con-accionistas-comunidad" element={<ShareholderAndCommunity />} />
          <Route path="/terminos-y-condiciones" element={<Terms />} />
          <Route path="/aviso-de-privacidad" element={<Privacy />} />
          {/* Desactivated routes */}
          {/* <Route path="/media" element={<Media />} />
          <Route path="/eventos-webinars" element={<EventsWebinars />} />
          <Route path="/biblioteca" element={<Library />} />
          <Route path="/nuestros-servicios" element={<Library />} />
          <Route path="/eventos-webinars-temporadas" element={<EventsWebinarsSeasons />} /> */}
          {environment === 'dev' ? (
            <>
              <Route path="/buzon-anonimo" element={<AnonymousMailbox />} />
              <Route path="/quejas-cobranza" element={<CollectionComplaints />} />
              <Route path="/solicitante/inicio" element={<ApplicantHomeLogin />} />
              {/* Auth */}
              <Route
                path="/iniciar-sesion"
                element={
                  <ProtectedRoute redirectPath="/" isReturnPath={false} invertedProtected>
                    <Login />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inversionista/registrate"
                element={
                  <ProtectedRoute redirectPath="/" isReturnPath={false} invertedProtected>
                    <SignupInvestor />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/solicitante/registrate"
                element={
                  <ProtectedRoute redirectPath="/" isReturnPath={false} invertedProtected>
                    <SignupApplicant />
                  </ProtectedRoute>
                }
              />
              <Route path="/:usuario/reestablecer-contraseña" element={<ForgotPassword />} />
              <Route path="/reestablecer-contraseña" element={<ResetPassword />} />
              <Route path="/pagina-validacion" element={<ThreatValidation />} />
              <Route path="/registro" element={<MainSignup />} />
              <Route path="/confirmar-cuenta" element={<ConfirmationAccount />} />
              {/* <Route path="/recuperar-cuenta" element={<RecoverAccount />} /> Pending to developing */}
              {/* Onboarding and Dashboard */}
              {OnboardingApplicantRoutes}
              {DashboardApplicantRoutes}
              {OnboardingInvestorRoutes}
              {DashboardInvestorRoutes}
              {/* Testing, please don't production */}
              <Route path="/test" element={<TestView />} />
            </>
          ) : null}

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
