/* eslint-disable @typescript-eslint/no-empty-function */
import { FormEvent, ChangeEvent, useState } from 'react';
import { SignupContainer, SignupInfo, SignupForm, UserType } from './signupInvestor.styles';
import { openSucessModal, setLoadingState, signUpInvestor } from 'src/store/User/user.slice';
import { errorHandler } from 'src/middlewares/Auth/index.utilities';
import { useAppThunkDispatch } from 'src/store/store';

//External components
import ModalSignupSuccess from '../../../components/shared/modals/signupSuccessInvestor/signupSuccess';
import ModalError from '../../../components/shared/modals/Error/Error';
import PasswordValid from 'src/components/shared/PasswordValidator/PasswordValid';
import { Link } from 'react-router-dom';
import { ErrorObject } from 'src/store/User/user.interface';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

const Signup: React.FC = () => {
  const dispatch = useAppThunkDispatch();

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [selectedOption, setSelectedtOption] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [type, setType] = useState('password');

  const handleChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedtOption(e.target.value);
  };

  const onSignUpRequest = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      errorHandler(dispatch, { code: 'passwordsNotEqual' });
      return;
    }
    if (!acceptTerms) {
      errorHandler(dispatch, { code: 'acceptTerms' });
      return;
    }
    if (!selectedOption) {
      errorHandler(dispatch, { code: 'allDataRequired' });
      return;
    }
    if (!email || !password || !confirmPassword) {
      errorHandler(dispatch, { code: 'allDataRequired' });
      return;
    }

    dispatch(setLoadingState(true));

    try {
      dispatch(setLoadingState(true));
      await dispatch(
        signUpInvestor({
          username: email,
          password: password,
          typeOfPerson: selectedOption
        })
      ).unwrap();
      dispatch(openSucessModal());
      dispatch(setLoadingState(false));
    } catch (error: unknown) {
      if (isErrorObject(error)) {
        errorHandler(dispatch, error);
      } else {
        console.error(error);
      }
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  function isErrorObject(error: unknown): error is ErrorObject {
    return typeof error === 'object' && error !== null && 'code' in error;
  }

  return (
    <SignupContainer>
      <ModalError onReset={() => {}} />
      <ModalSignupSuccess />
      <SignupInfo>
        <h2>
          ¡Invierte prestando tu dinero a las mejores Pymes de México y recibe, por fin,{' '}
          <span>la tasa de interés completa!</span>
        </h2>
        <p>
          Sin intermediarios, gracias a la economía colaborativa <span>¡Así de fácil!</span>
        </p>
      </SignupInfo>
      <SignupForm>
        <form onSubmit={onSignUpRequest}>
          <h3>Regístrate | Inversionista</h3>
          <input
            type="email"
            name="email"
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div>
            <input
              type={type}
              name="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
            />
            {type === 'password' ? (
              <figure onClick={() => setType('text')}>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconoUnlockPassword.png`}
                  alt="icono mostrar contraseña"
                />
              </figure>
            ) : (
              <figure onClick={() => setType('password')}>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Eye-Open.png`}
                  alt="icono mostrar contraseña"
                />
              </figure>
            )}
          </div>
          <div>
            <input
              type={type}
              name="confirmPassword"
              placeholder="Confirmar contraseña"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <PasswordValid
            password={password}
            onChangePassword={(value) => setIsPasswordValid(value)}
          />
          <UserType>
            <div>
              <input
                type="radio"
                name="typeOfPerson"
                checked={selectedOption === 'FISICA'}
                onChange={handleChangeValue}
                id="fisica"
                value="FISICA"
              />
              <label htmlFor="fisica">Persona física</label>
            </div>
            <div>
              <input
                type="radio"
                name="typeOfPerson"
                checked={selectedOption === 'MORAL'}
                onChange={handleChangeValue}
                id="moral"
                value="MORAL"
              />
              <label htmlFor="moral">Persona moral</label>
            </div>
          </UserType>
          <div>
            <input
              type="checkbox"
              name="acceptTerms"
              onChange={() => setAcceptTerms((acceptTerms) => !acceptTerms)}
            />
            <p>
              He leído y estoy de acuerdo con los{' '}
              <Link to="/terminos-y-condiciones">términos y condiciones</Link> y{' '}
              <Link to="/aviso-de-privacidad">políticas de privacidad del sitio</Link>.
            </p>
          </div>
          <Button isSlim type="submit" disabled={!isPasswordValid}>
            Regístrate
          </Button>
          <p>¿Ya tienes tu cuenta en FinSphera?</p>
          <Link to="/iniciar-sesion">Inicia sesión ahora</Link>
        </form>
      </SignupForm>
    </SignupContainer>
  );
};
export default Signup;
