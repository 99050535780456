import { useState } from 'react';
import goals from 'src/constants/esgGoals';
import { useSelector } from 'react-redux';
import {
  Banner,
  EsgInformationForm,
  EsgInformation,
  EsgInformationTitle,
  EsgFormContainer
} from './esgForm.styles';
import axios from 'axios';
import { url } from 'src/environments';
import Goal from './goal/goal';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

export default function Equity() {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [localLoading, setLocalLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user.currentUser);

  const handleSelectedOptions = (goalId, optionId, targetChecked, description) => {
    setData((prevData) => {
      const currentGoalData = prevData[goalId] || {};
      currentGoalData[optionId] = {
        value: targetChecked,
        description: description
      };
      return {
        ...prevData,
        [goalId]: currentGoalData
      };
    });
  };

  const handleContinue = async () => {
    setLocalLoading(true);
    const payload = {
      id: user.id,
      EnvironmentalAndSocialImpact: data
    };
    try {
      const resp = await axios.post(
        `${url}/admin/credit-analysis/environmental-and-social-impact/ods`,
        payload
      );

      if (resp.status === 200) {
        const responseBody = JSON.parse(resp.data.body);
        if (responseBody.result) {
          navigate('/solicitante/dashboard/esg-documentos');
        } else {
          console.error('Response result is not true:', responseBody);
          setShowModal(true);
        }
      } else {
        console.error('Unexpected status code:', resp.status);
        setLocalLoading(false);
        setShowModal(true);
      }
    } catch (error) {
      console.error('Request failed:', error);
      setLocalLoading(false);
      setShowModal(true);
    }
  };

  return (
    <EsgFormContainer>
      <Banner>
        <h2>
          Análisis de <p>Inclusión y equidad de género.</p> de la PyME solicitante de crédito
        </h2>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image+41.png`}
            alt="Finsphera ícono"
          />
        </figure>
      </Banner>
      <ComposedModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        messageTitle="Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo más tarde."
        type="error"
      />
      <EsgInformation>
        <EsgInformationTitle>
          <h1>Objetivo</h1>
          <h3>
            En nuestra búsqueda por construir un modelo de Inversiones con Impacto Social y
            Medioambiental, procuramos que cada solicitante de crédito logre cumplir con algunos de
            los objetivos de desarrollo sostenible impulsados por la Organización de las Naciones
            Unidas (ONU).
          </h3>
        </EsgInformationTitle>
        <h4>
          Señala todas las metas en las que la PyME está realizando actividades que promuevan o
          faciliten su cumplimiento. Puedes seleccionar múltiples opciones, dependiendo de tu
          compromiso con las metas ESG.
        </h4>
        <EsgInformationForm>
          <div>
            {goals.map((goal) => (
              <Goal
                key={goal.id}
                title={goal.title}
                options={goal.options}
                goalId={goal.id}
                data={data}
                setData={setData}
                handleSelectedOptions={handleSelectedOptions}
              />
            ))}
          </div>
        </EsgInformationForm>
      </EsgInformation>
      <Button isLoading={localLoading} onClick={handleContinue}>
        Guardar y continuar
      </Button>
    </EsgFormContainer>
  );
}
