import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalEditPhone, RegisterCLABEForm, Success, Figure } from './editPhone.styles';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { url } from 'src/environments';

export default function EditPhone({
  showModalTelephone,
  setShowModalTelephone,
  referenceId,
  mobilephone
}) {
  const [code, setCode] = useState('');
  const [step, setStep] = useState(2);
  const [successText, setSuccessText] = useState(false);
  //Para validar teléfono MFA
  const [verificationCode, setVerificationCode] = useState('');
  const user = useSelector((state) => state.user.currentUser);

  const closeModal = () => {
    setShowModalTelephone(false);
  };

  //Función para validar el código de MFA
  const handleVerifyCode = async (event) => {
    event.preventDefault();
    const mobileP = mobilephone.split(' ')[0] + mobilephone.split(' ')[1];
    const body = {
      id: user.id,
      otp: verificationCode,
      referenceId: referenceId,
      typeOfUser: 'investor'
    };
    const response = await axios.post(` ${url}/admin/mobile/${mobileP}/validate-otp`, body);
    const parsedBody = JSON.parse(response.data.body);
    const codeResponse = parsedBody.isVerified;
  };
  return (
    <ModalEditPhone showModalTelephone={showModalTelephone} closeModal={closeModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu teléfono se ha editado de forma exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <RegisterCLABEForm>
            <h3>Ingresa el código de verificación</h3>
            <>
              <InputField
                value={verificationCode}
                maxLength={6}
                onChangeInternalValue={(value) => setVerificationCode(value)}
                type="number"
                placeholder="Código de verificación"
              />
            </>
          </RegisterCLABEForm>
          <div>
            <Button onClick={handleVerifyCode} isFullWidth size="small" type="submit">
              Validar código de verificación
            </Button>
            <Button onClick={closeModal}>Cerrar</Button>
          </div>
        </div>
      )}
    </ModalEditPhone>
  );
}
