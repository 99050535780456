import styled from 'styled-components/macro';

import {
  blueSphera,
  grayLightSphera,
  grayPlaceholder,
  graySphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalDetailInvestmentB = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalDetailInvestmentB ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalDetailInvestmentB ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 60%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid #b7bcc7;

      > h3 {
        font-size: 1.5vw;
        color: ${graySphera};
        width: 90%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      width: 80%;

      > div:nth-child(1) {
        > h3 {
        }

        > figure {
          > img {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
      margin-left: -5%;

      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;

        > h3 {
          font-size: 4.5vw;
        }

        > figure {
          > img {
          }
        }
      }
    }
  }
`;

export const DetailInvestmentBInfo = styled.div`
  width: 90%;
  margin: 2.5% auto 2.5% auto;
  border: none;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    background-color: ${blueSphera};

    > h3 {
      font-size: 1.2vw;
      font-weight: normal;
      color: ${whiteSphera};
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    background-color: #f0f5ff;

    > p {
      font-size: 1.2vw;
      font-weight: normal;
      color: ${graySphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }
    }

    > div:nth-child(2) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    margin: 5% auto 5% auto;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 5%;

      > h3 {
        font-size: 3.2vw;
      }
    }

    > div:nth-child(2) {
      padding-top: 5%;
      padding-bottom: 5%;

      > p {
        font-size: 3.2vw;
      }
    }
  }
`;

export const DetailInvestmentBForm = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;

  > h2 {
    color: ${graySphera};
    font-size: 1.5vw;
  }

  > div {
    margin-top: 2.5%;
    margin-left: 2.5%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 1%;
      border-bottom: 2px solid ${blueSphera};

      > input {
        margin-right: 2.5%;
      }

      > p {
        color: ${graySphera};
        font-size: 1.2vw;
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: flex-start;
      margin-top: 1%;
      padding-bottom: 1%;

      > input {
        margin-top: 1%;
        margin-right: 2.5%;
      }

      > p {
        color: ${graySphera};
        font-size: 1.2vw;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }

    > div {
      > div:nth-child(1) {
        > div {
          > h3 {
          }

          > p {
          }
        }

        > p {
        }
      }

      > div:nth-child(2) {
        > div {
          > h3 {
          }

          > p {
          }
        }

        > p {
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin: 1% auto 5% auto;

    > h2 {
      font-size: 4vw;
    }

    > div {
      margin-top: 5%;
      margin-left: 5%;

      > div:nth-child(1) {
        > div {
          > h3 {
            font-size: 3.8vw;
          }

          > p {
            font-size: 3.8vw;
          }
        }

        > p {
          font-size: 3.6vw;
        }
      }

      > div:nth-child(2) {
        margin-top: 5%;

        > div {
          > h3 {
            font-size: 3.8vw;
          }

          > p {
            font-size: 3.8vw;
          }
        }

        > p {
          font-size: 3.6vw;
        }
      }
    }
  }
`;

export const DetailInvestmentBGetCode = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;

  > h2 {
    color: ${graySphera};
    font-size: 1.5vw;
    margin-bottom: 2.5%;
  }

  > div {
    display: flex;
    justify-content: space-between;
    width: 70%;

    > select,
    > input {
      color: ${grayPlaceholder};
      font-size: 1.2vw;
      width: 60%;
      height: 50px;
      margin-top: 2%;
      margin-bottom: 2%;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
      background-color: transparent;
    }

    > button {
      color: ${whiteSphera};
      font-weight: bold;
      font-size: 1.2vw;
      text-align: center;
      width: 25%;
      height: 50px;
      margin: auto;
      border: none;
      border-radius: 5px;
      background-color: ${blueSphera};
      cursor: pointer;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }

    > div {
      > select,
      > input {
      }

      > button {
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 1% auto 2.5% auto;

    > h2 {
      font-size: 4vw;
      margin-bottom: 5%;
    }

    > div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > select,
      > input {
        font-size: 3.8vw;
        width: 65%;
      }

      > button {
        font-size: 3.8vw;
        width: 30%;
      }
    }
  }
`;

export const DetailInvestmentAdvices = styled.div`
  width: 90%;
  margin: auto;

  > p {
    color: ${graySphera};
    font-size: 1.2vw;
    font-style: ${(props) => (props.isCodeProvided ? 'normal' : 'italic')};
    width: ${(props) => (props.isCodeProvided ? '55%' : '100%')};
    margin-top: 2.5%;
    margin-bottom: 2%;

    > button {
      font-size: 1.2vw;
      font-weight: bold;
      color: ${blueSphera};
      border: none;
      background-color: transparent;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: auto;

    > p {
      font-size: 3.5vw;
      width: 100%;
      margin-top: 5%;

      > button {
        font-size: 3.5vw;
      }
    }
  }
`;
