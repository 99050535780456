type Route = {
  path: string;
};

type MapRouteObject = Route[];

export const onboardingMapInvestorPhysicalRoutes: MapRouteObject = [
  {
    path: '/inversionista/identificacion'
  },
  {
    path: '/inversionista/onboarding-data-f'
  },
  {
    path: '/inversionista/onboarding-risks'
  },
  {
    path: '/inversionista/onboarding-perfil-uso'
  },
  {
    path: '/inversionista/onboarding-phone'
  }
];

export const onboardingMapInvestorMoralRoutes: MapRouteObject = [
  {
    path: '/inversionista/identificacion'
  },
  {
    path: '/inversionista/onboarding-data-m'
  },
  {
    path: '/inversionista/onboarding-risks'
  },
  {
    path: '/inversionista/onboarding-perfil-uso'
  },
  {
    path: '/inversionista/onboarding-phone'
  }
];

export const onboardingMapApplicantRoutes: MapRouteObject = [
  {
    path: '/solicitante/identificacion'
  },
  {
    path: '/solicitante/onboarding-data'
  },
  {
    path: '/solicitante/onboarding-creditInfo'
  },
  {
    path: '/solicitante/onboarding-perfil-uso'
  },
  {
    path: '/solicitante/onboarding-phone'
  }
];

export const flowTypeToRoutesMap = {
  physicalInvestor: onboardingMapInvestorPhysicalRoutes,
  moralInvestor: onboardingMapInvestorMoralRoutes,
  moralApplicant: onboardingMapApplicantRoutes
};
