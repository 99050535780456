import { useState } from 'react';
import { InvestmentShowcaseSectionContainer } from './investmentShowcaseSection.styles';
import {
  Card,
  Button,
  InvestmentCampaignDemo,
  BoxAmount,
  ProgressBar
} from 'src/utilities/UILibrary2024/components';
import { useViewMobile } from 'src/hooks/useViewMobile';

export const InvestmentShowcaseSection = () => {
  const [card2Hovered, setCard2Hovered] = useState(false);
  const isMobile = useViewMobile();

  const cardMobileWidth = '19rem';

  const handleHover = (type: 'card2' | 'card3') => {
    if (type === 'card2') {
      setCard2Hovered(true);
    }
  };

  return (
    <InvestmentShowcaseSectionContainer className="investment-showcase">
      <div className="description">
        <h1>Inversión</h1>
        <p>
          Aumenta tu capital con inversiones <span />
          en empresas de alto rendimiento
        </p>
        <div className="description--rates">
          <p className="info">
            rendimiento <br />
            hasta
          </p>
          <p className="rate">15%</p>
          <p className="info">anual</p>
          <Button variant="green">Invierte</Button>
        </div>
      </div>
      <div className="investment-showcase__cards-section">
        <div className="fade-detail" />
        <div className="card1">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '22.5rem'}
            aspectRatio="19 / 22"
            isFluid
          >
            <div className="card--header">
              <h3>ELIGE LA EMPRESA A INVERTIR</h3>
            </div>
            <InvestmentCampaignDemo
              mobileView={isMobile}
              campaignName="Campaña Uno"
              percentageToAnimate={65}
            />
            <InvestmentCampaignDemo
              mobileView={isMobile}
              campaignName="Campaña Dos"
              percentageToAnimate={35}
            />
            <InvestmentCampaignDemo mobileView={isMobile} campaignName="Campaña Tres" />
          </Card>
        </div>
        <div className="card2">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '23.75rem'}
            aspectRatio="38 / 35"
            isFluid
            onMouseEnter={() => handleHover('card2')}
            onMouseLeave={() => setCard2Hovered(false)}
          >
            <div className="card--header">
              <h3>MONTO A INVERTIR</h3>
              <p>En campaña 2</p>
            </div>
            <BoxAmount className="box-amount" amount={card2Hovered ? 500000 : 5000} />
            <ProgressBar actualPercentage={card2Hovered ? 55 : 35} />
            <Button className="card--button" isSlim={true}>
              Aceptar
            </Button>
          </Card>
        </div>
        <div className="card3">
          <Card
            className="card"
            width={isMobile ? cardMobileWidth : '35.875rem'}
            aspectRatio={isMobile ? undefined : '16 / 9'}
            isFluid
          >
            <div className="card--header">
              <h3>RENDIMIENTOS</h3>
            </div>
          </Card>
        </div>
      </div>
    </InvestmentShowcaseSectionContainer>
  );
};
