import { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ModalWarning } from './warning.styles';
import { deleteBeneficiary } from 'src/graphql/customized_mutations';

export default function WarningModal({ showModal, onClose, beneficiaryId, onDelete }) {
  const handleAccept = async () => {
    const input = {
      id: beneficiaryId
    };
    try {
      await API.graphql(graphqlOperation(deleteBeneficiary, { input }));
      onClose();
      onDelete(beneficiaryId);
    } catch (error) {
      console.error('Error deleting beneficiary:', error);
    }
  };
  return (
    <ModalWarning warningModal={showModal}>
      <div>
        <figure onClick={onClose}>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
            alt="icono para cerrar la ventana"
          />
        </figure>
        <figure>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
            alt="icono de aviso"
          />
        </figure>
        <h2>Estás a punto de eliminar este benficiario</h2>
        <p>¿Deseas continuar?</p>
        <button onClick={handleAccept}>Aceptar</button>
      </div>
    </ModalWarning>
  );
}
