const goals = [
  {
    id: 'finPobreza',
    title: 'Objetivo 1. Fin de la pobreza: Erradicar la pobreza extrema para todas las personas.',
    options: [
      {
        id: 'erradicarPobrezaExtrema',
        label: 'Meta FinPobreza.1',
        value: false,
        description: 'Erradicación de la pobreza extrema'
      },
      {
        id: 'reduccionPobrezaRelativa',
        label: 'Meta FinPobreza.2',
        value: false,
        description: 'Reducción de la pobreza relativa en todas sus dimensiones.'
      },
      {
        id: 'implantacionProteccionSocial',
        label: 'Meta FinPobreza.3',
        value: false,
        description: 'Implantación de sistemas de protección social.'
      },
      {
        id: 'garantiaAccesoServicioBasico',
        label: 'Meta FinPobreza.4',
        value: false,
        description: 'Garantía de acceso a servicios básicos y recursos financieros.'
      },
      {
        id: 'resilienciaDesastresAmbientales',
        label: 'Meta FinPobreza.5',
        value: false,
        description: 'Resiliencia a desastres ambientales, económicos y sociales.'
      },
      {
        id: 'creacionMarcosNormativos',
        label: 'Meta FinPobreza.6',
        value: false,
        description: 'Creación de marcos normativos para erradicar la pobreza.'
      }
    ]
  },
  {
    id: 'hambreCero',
    title:
      'Objetivo 2. Hambre cero: Poner fin al hambre y asegurar el acceso de todas las personas, a una alimentación sana, nutritiva y suficiente durante todo el año.',
    options: [
      {
        id: 'finHambre',
        label: 'Meta HambreCero.1',
        value: false,
        description: 'Poner fin al hambre'
      },
      {
        id: 'finMalnutricion',
        label: 'Meta HambreCero.2',
        value: false,
        description: 'Poner fin a todas las formas de malnutrición.'
      },
      {
        id: 'duplicarProductividadIngresosPequeniaEscala',
        label: 'Meta HambreCero.3',
        value: false,
        description: 'Duplicación de productividad e ingresos agrícolas a pequeña escala.'
      },
      {
        id: 'practicasAgricolasSosteniblesResilientes',
        label: 'Meta HambreCero.4',
        value: false,
        description: 'Prácticas agrícolas sostenibles y resilientes.'
      },
      {
        id: 'mantenimientoDiversidadGeneticaSemilla',
        label: 'Meta HambreCero.5',
        value: false,
        description: 'Mantenimiento de la diversidad genética de semillas.'
      },
      {
        id: 'aumentoInversionAgricola',
        label: 'Meta HambreCero.6',
        value: false,
        description: 'Aumento de inversiones en agricultura.'
      },
      {
        id: 'estabilidadMercadosAgropecuarios',
        label: 'Meta HambreCero.7',
        value: false,
        description: 'Estabilidad de mercados agropecuarios mundiales.'
      },
      {
        id: 'controlVolatilidadPreciosAlimentos',
        label: 'Meta HambreCero.8',
        value: false,
        description: 'Control de la volatibilidad de precios de los alimentos.'
      }
    ]
  },
  {
    id: 'saludBienestar',
    title:
      'Objetivo 3. Salud y bienestar: Lograr la cobertura sanitaria universal, el acceso a servicios de salud esenciales de calidad y el acceso a medicamentos y vacunas seguras, eficaces, asequibles y de calidad para todos.',
    options: [
      {
        id: 'reduccionTasaMortalidadMaterna',
        label: 'Meta SaludBienestar.1',
        value: false,
        description: 'Reducción de la tasa de mortalidad materna.'
      },
      {
        id: 'eliminarMortalidadInfantil',
        label: 'Meta SaludBienestar.2',
        value: false,
        description: 'Eliminar la mortalidad infantil.'
      },
      {
        id: 'finEnfermedadesTransmisibles',
        label: 'Meta SaludBienestar.3',
        value: false,
        description: 'Poner fin a las enfermedades transmisibles.'
      },
      {
        id: 'reduccionEnfermedadesNoTransmisiblesYSaludMental',
        label: 'Meta SaludBienestar.4',
        value: false,
        description: 'Reducción de las enfermedades no transmisibles y salud mental.'
      },
      {
        id: 'prevencionTratamientoAbusosDrogasAlcohol',
        label: 'Meta SaludBienestar.5',
        value: false,
        description: 'Prevención y tratamiento de abusos de drogas y alcohol.'
      },
      {
        id: 'controlTabaco',
        label: 'Meta SaludBienestar.6',
        value: false,
        description: 'Control del tabaco.'
      },
      {
        id: 'apoyoIDVacunasMedicamentosEsenciales',
        label: 'Meta SaludBienestar.7',
        value: false,
        description: 'Apoyo a la I+D de vacunas y medicamentos esenciales.'
      },
      {
        id: 'aumentoFinanciacionSistemaSanitario',
        label: 'Meta SaludBienestar.8',
        value: false,
        description: 'Aumento de la financiación del sistema sanitario.'
      },
      {
        id: 'refuerzoGestionRiesgosSanitarios',
        label: 'Meta SaludBienestar.9',
        value: false,
        description: 'Refuerzo en la gestión de riesgos sanitarios.'
      }
    ]
  },
  {
    id: 'educacionCalidad',
    title:
      'Objetivo 4. Educación de calidad: Asegurar el acceso igualitario de todos los hombres y las mujeres a una formación técnica, profesional y superior de calidad.',
    options: [
      {
        id: 'asegurarCalidadPrimariaSecundaria',
        label: 'Meta EducacionCalidad.1',
        value: false,
        description: 'Asegurar la calidad de la educación primaria y secundaria.'
      },
      {
        id: 'asegurarAccesoCalidadEducacionPreescolar',
        label: 'Meta EducacionCalidad.2',
        value: false,
        description: 'Asegurar el acceso y calidad de la educación preescolar.'
      },
      {
        id: 'asegurarAccesoIgualitarioFormacionSuperior',
        label: 'Meta EducacionCalidad.3',
        value: false,
        description: 'Asegurar el acceso igualitario a la formación superior.'
      },
      {
        id: 'aumentoCompetenciasAccederEmpleo',
        label: 'Meta EducacionCalidad.4',
        value: false,
        description: 'Aumento de las competencias para acceder al empleo.'
      },
      {
        id: 'eliminacionDisparidadGenero',
        label: 'Meta EducacionCalidad.5',
        value: false,
        description: 'Eliminación de la disparidad de género y colectivos vulnerables.'
      },
      {
        id: 'asegurarAlfabetizacion',
        label: 'Meta EducacionCalidad.6',
        value: false,
        description: 'Asegurar la alfabetización y conocimiento de aritmética.'
      },
      {
        id: 'fomentarEducacionGlobal',
        label: 'Meta EducacionCalidad.7',
        value: false,
        description: 'Fomentar la educación global para el desarrollo sostenible.'
      },
      {
        id: 'mejoraInstalacionesEducativas',
        label: 'Meta EducacionCalidad.8',
        value: false,
        description: 'Mejora de instalaciones educativas inclusivas y seguras.'
      },
      {
        id: 'aumentoBecas',
        label: 'Meta EducacionCalidad.9',
        value: false,
        description: 'Aumento de becas para enseñanza superior.'
      },
      {
        id: 'mejorarDocentes',
        label: 'Meta EducacionCalidad.10',
        value: false,
        description: 'Mejorar la cualificación de docentes.'
      }
    ]
  },
  {
    id: 'igualdadGenero',
    title:
      'Objetivo 5. Igualdad de género: Poner fin a todas las formas de discriminación contra las mujeres y niñas.',
    options: [
      {
        id: 'eliminarViolenciaGenero',
        label: 'Meta IgualdadGenero.1',
        value: false,
        description: 'Eliminar todas las formas de violencia de género.'
      },
      {
        id: 'eliminarMatrimonioInfantil',
        label: 'Meta IgualdadGenero.2',
        value: false,
        description: 'Eliminar el matrimonio infantil y mutilación genital femenina.'
      },
      {
        id: 'reconocerTrabajoDomestico',
        label: 'Meta IgualdadGenero.3',
        value: false,
        description: 'Reconocer el trabajo de cuidados y doméstico.'
      },
      {
        id: 'asegurarParticipacionMujer',
        label: 'Meta IgualdadGenero.4',
        value: false,
        description: 'Asegurar la participación plena de la mujer e igualdad oportunidades.'
      },
      {
        id: 'asegurarAccesoSaludSexual',
        label: 'Meta IgualdadGenero.5',
        value: false,
        description: 'Asegurar el acceso a la salud sexual, reproductiva y derechos reproductivos.'
      },
      {
        id: 'asegurarIgualdadDerechos',
        label: 'Meta IgualdadGenero.6',
        value: false,
        description: 'Asegurar la igualdad de derechos a los recursos económicos.'
      },
      {
        id: 'mejorarUsoTecnologia',
        label: 'Meta IgualdadGenero.7',
        value: false,
        description:
          'Mejorar el uso de tecnología y de las tecnologías de la información y comunicación.'
      },
      {
        id: 'aprobarPoliticasIgualdad',
        label: 'Meta IgualdadGenero.8',
        value: false,
        description: 'Aprobar políticas y leyes para la igualdad y el empoderamiento.'
      }
    ]
  },
  {
    id: 'aguaLimpia',
    title:
      'Objetivo 6. Agua limpia y saneamiento: Acceso a agua limpia y saneamiento de manera asequible para todos.',
    options: [
      {
        id: 'accesoAguaPotable',
        label: 'Meta AguaLimpia.1',
        value: false,
        description: 'Lograr el acceso a agua potable.'
      },
      {
        id: 'accesoSanamiento',
        label: 'Meta AguaLimpia.2',
        value: false,
        description: 'Lograr el acceso a servicios de saneamiento e higiene.'
      },
      {
        id: 'mejorarCalidadAgua',
        label: 'Meta AguaLimpia.3',
        value: false,
        description: 'Mejorar la calidad del agua, reducir la contaminación y aguas residuales.'
      },
      {
        id: 'usoEficienteRecursosHidricos',
        label: 'Meta AguaLimpia.4',
        value: false,
        description: 'Aumentar el uso eficiente de recursos hídricos (extracción de agua dulce).'
      },
      {
        id: 'gestionRecursosHidricos',
        label: 'Meta AguaLimpia.5',
        value: false,
        description: 'Implementar la gestión integral de recursos hídricos.'
      },
      {
        id: 'proteccionEcosistemas',
        label: 'Meta AguaLimpia.6',
        value: false,
        description: 'Protección de los ecosistemas relacionados con agua.'
      },
      {
        id: 'creacionCapacidadGestion',
        label: 'Meta AguaLimpia.7',
        value: false,
        description: 'Fomentar la creación de capacidades de gestión.'
      },
      {
        id: 'apoyarComunidadesLocales',
        label: 'Meta AguaLimpia.8',
        value: false,
        description: 'Apoyar la participación de las comunidades locales.'
      }
    ]
  },
  {
    id: 'energiaAsequible',
    title:
      'Objetivo 7. Energía asequible y no contaminante: Garantizar el acceso universal a servicios energéticos asequibles, fiables y modernos de fuentes de energía renovables.',
    options: [
      {
        id: 'accesoUniversalEnergia',
        label: 'Meta EnergiaAsequible.1',
        value: false,
        description: 'Garantizar el acceso universal a energía.'
      },
      {
        id: 'aumentoEnergiasRenovables',
        label: 'Meta EnergiaAsequible.2',
        value: false,
        description: 'Aumento de las energías renovables.'
      },
      {
        id: 'duplicarEficienciaEnergetica',
        label: 'Meta EnergiaAsequible.3',
        value: false,
        description: 'Duplicar la tasa de eficiencia energética.'
      },
      {
        id: 'aumentoInvestigacionInversion',
        label: 'Meta EnergiaAsequible.4',
        value: false,
        description: 'Aumento de la investigación e inversión en energías limpias.'
      },
      {
        id: 'ampliarInfraestructuraYTecnologia',
        label: 'Meta EnergiaAsequible.5',
        value: false,
        description: 'Ampliar la infraestructura y tecnología en países en desarrollo.'
      }
    ]
  },
  {
    id: 'trabajoDecente',
    title:
      'Objetivo 8. Trabajo Decente y Crecimiento Económico. Lograr empleo pleno y productivo y un trabajo decente para todos los hombres y mujeres.',
    options: [
      {
        id: 'mantenimientoCrecimientoEconomico',
        label: 'Meta TrabajoDecente.1',
        value: false,
        description: 'Mantenimiento del crecimiento económico.'
      },
      {
        id: 'elevarProductividad',
        label: 'Meta TrabajoDecente.2',
        value: false,
        description:
          'Elevar la productividad a través de la diversificación, tecnología e innovación.'
      },
      {
        id: 'fomentoPequeniaMedianaEmpresa',
        label: 'Meta TrabajoDecente.3',
        value: false,
        description: 'Fomento de pequeña y mediana empresa.'
      },
      {
        id: 'mejorarProduccionYConsumo',
        label: 'Meta TrabajoDecente.4',
        value: false,
        description: 'Mejora de la producción y consumo eficiente y respetuoso.'
      },
      {
        id: 'lograrPlenoEmpleo',
        label: 'Meta TrabajoDecente.5',
        value: false,
        description: 'Lograr el pleno empleo y trabajo decente.'
      },
      {
        id: 'reduccionJovenesSinTrabajo',
        label: 'Meta TrabajoDecente.6',
        value: false,
        description: 'Reducción de los jóvenes sin trabajo ni estudios.'
      },
      {
        id: 'erradicarEsclavitud',
        label: 'Meta TrabajoDecente.7',
        value: false,
        description: 'Erradicación de la esclavitud, trata y trabajo infantil.'
      },
      {
        id: 'proteccionDerechosLaborales',
        label: 'Meta TrabajoDecente.8',
        value: false,
        description: 'Protección de los derechos laborales y trabajo seguro.'
      },
      {
        id: 'promocionTurismo',
        label: 'Meta TrabajoDecente.9',
        value: false,
        description: 'Promoción del turismo sostenible.'
      },
      {
        id: 'fortalecimientoInstitucionesFinancieras',
        label: 'Meta TrabajoDecente.10',
        value: false,
        description: 'Fortalecimiento de la capacidad de las instituciones financieras.'
      },
      {
        id: 'aumentoAyudaComercio',
        label: 'Meta TrabajoDecente.11',
        value: false,
        description: 'Aumento de la ayuda para el comercio en países en desarrollo.'
      },
      {
        id: 'desarrolloEmpleoJuvenil',
        label: 'Meta TrabajoDecente.12',
        value: false,
        description: 'Desarrollo de la estrategia mundial para empleo juvenil.'
      }
    ]
  },
  {
    id: 'industriaInnovacion',
    title:
      'Objetivo 9.	Industria, innovación e infraestructura: Desarrollar infraestructuras fiables, sostenibles, resilientes y de calidad, para apoyar el desarrollo económico y el bienestar humano, haciendo especial hincapié en el acceso asequible y equitativo para todos.',
    options: [
      {
        id: 'desarrolloInfraestructura',
        label: 'Meta IndustriaInnovacion.1',
        value: false,
        description: 'Desarrollo de infraestructura sostenible.'
      },
      {
        id: 'promocionIndustriaInclusiva',
        label: 'Meta IndustriaInnovacion.2',
        value: false,
        description: 'Promoción de industria inclusiva y sostenible.'
      },
      {
        id: 'aumentoAccesoPymesServiciosFinancieros',
        label: 'Meta IndustriaInnovacion.3',
        value: false,
        description: 'Aumento del acceso a Pymes a servicios financieros y cadenas de valor.'
      },
      {
        id: 'modernizacionInfraestructura',
        label: 'Meta IndustriaInnovacion.4',
        value: false,
        description: 'Modernización de la infraestructura, tecnología limpia.'
      },
      {
        id: 'aumentoInvestigacionCientifica',
        label: 'Meta IndustriaInnovacion.5',
        value: false,
        description: 'Aumento de la investigación científica, capacidad tecnológica.'
      },
      {
        id: 'apoyoInfraestructuraSostenible',
        label: 'Meta IndustriaInnovacion.6',
        value: false,
        description: 'Apoyo a infraestructuras sostenibles y resilientes.'
      },
      {
        id: 'desarrolloTecnologia',
        label: 'Meta IndustriaInnovacion.7',
        value: false,
        description: 'Desarrollo de la tecnología, investigación e innovación.'
      },
      {
        id: 'aumentoAccesoTecnologias',
        label: 'Meta IndustriaInnovacion.8',
        value: false,
        description:
          'Aumento del acceso a las Tecnologías de Información y Comunicación e Internet.'
      }
    ]
  },
  {
    id: 'reduccionDesigualdades',
    title:
      'Objetivo 10.	Reducción de las desigualdades: Empoderar a las personas de bajos ingresos y promover la inclusión económica de todos y todas, independientemente de su género, raza o etnia.',
    options: [
      {
        id: 'crecimientosIngresos',
        label: 'Meta ReduccionDesigualdades.1',
        value: false,
        description: 'Crecimiento de ingresos del 40% población pobre.'
      },
      {
        id: 'promocionInclusion',
        label: 'Meta ReduccionDesigualdades.2',
        value: false,
        description: 'Promoción de la inclusión social, económica y política.'
      },
      {
        id: 'igualdadOportinidades',
        label: 'Meta ReduccionDesigualdades.3',
        value: false,
        description: 'Garantizar la igualdad de oportunidades.'
      },
      {
        id: 'adopcionPoliticasFiscales',
        label: 'Meta ReduccionDesigualdades.4',
        value: false,
        description: 'Adopción de políticas fiscales, salariales y de protección social.'
      },
      {
        id: 'mejorarRegulacionMercados',
        label: 'Meta ReduccionDesigualdades.5',
        value: false,
        description: 'Mejorar de la regulación de los mercados financieros mundiales.'
      },
      {
        id: 'participacionPaisesDesarrollo',
        label: 'Meta ReduccionDesigualdades.6',
        value: false,
        description: 'Participación de países en desarrollo en Organizaciones Internacionales.'
      },
      {
        id: 'facilitarMigracion',
        label: 'Meta ReduccionDesigualdades.7',
        value: false,
        description: 'Facilitar la migración y políticas migratorias ordenadas.'
      },
      {
        id: 'aplicacionTratoEspecial',
        label: 'Meta ReduccionDesigualdades.8',
        value: false,
        description: 'Aplicación del principio del trato especial y diferenciado.'
      },
      {
        id: 'fomentarCorrientesFinancieras',
        label: 'Meta ReduccionDesigualdades.9',
        value: false,
        description: 'Fomento de corrientes financieras para países en desarrollo.'
      },
      {
        id: 'reduccionCostesRemesas',
        label: 'Meta ReduccionDesigualdades.10',
        value: false,
        description: 'Reducción de costes de remesas.'
      }
    ]
  },
  {
    id: 'ciudadesComunidades',
    title:
      'Objetivo 11. Ciudades y comunidades sostenibles: Asegurar el acceso de todas las personas a viviendas, sistemas de transporte y servicios básicos adecuados, seguros y asequibles y mejorar los barrios marginales.',
    options: [
      {
        id: 'accesoVivienda',
        label: 'Meta CiudadesComunidades.1',
        value: false,
        description: 'Asegurar el acceso a la vivienda.'
      },
      {
        id: 'accesoTransportePublico',
        label: 'Meta CiudadesComunidades.2',
        value: false,
        description: 'Proporcionar el acceso a transporte público.'
      },
      {
        id: 'aumentoUrbanizacion',
        label: 'Meta CiudadesComunidades.3',
        value: false,
        description: 'Aumento de la urbanización inclusiva y sostenible.'
      },
      {
        id: 'proteccionPatrimonioCultural',
        label: 'Meta CiudadesComunidades.4',
        value: false,
        description: 'Protección del patrimonio cultural y natural.'
      },
      {
        id: 'reduccionNumeroMuertes',
        label: 'Meta CiudadesComunidades.5',
        value: false,
        description: 'Reducción del número de muertes por desastres y reducción de vulnerabilidad.'
      },
      {
        id: 'reduccionImpactoAmbiental',
        label: 'Meta CiudadesComunidades.6',
        value: false,
        description: 'Reducción del impacto ambiental en ciudades.'
      },
      {
        id: 'accesoZonasVerdes',
        label: 'Meta CiudadesComunidades.7',
        value: false,
        description: 'Proporcionar el acceso a zonas verdes y espacios públicos seguros.'
      },
      {
        id: 'apoyoZonasUrbanas',
        label: 'Meta CiudadesComunidades.8',
        value: false,
        description: 'Apoyo a vínculos zonas urbanas, periurbanas y rurales.'
      },
      {
        id: 'reduccionRiesgos',
        label: 'Meta CiudadesComunidades.9',
        value: false,
        description: 'Aumento de la reducción de riesgos de desastres en ciudades.'
      },
      {
        id: 'apoyoConstruccionEdificosSostenibles',
        label: 'Meta CiudadesComunidades.10',
        value: false,
        description: 'Apoyo a la construcción de edificios sostenibles y resilientes.'
      }
    ]
  },
  {
    id: 'produccionConsumo',
    title:
      'Objetivo 12. Producción y consumo responsables: Reducir la huella ecológica de las personas mediante un cambio en los métodos de producción y consumo de bienes y recursos.',
    options: [
      {
        id: 'aplicacionMarcoConsumo',
        label: 'Meta ProduccionConsumo.1',
        value: false,
        description: 'Aplicación del marco de consumo y producción sostenibles.'
      },
      {
        id: 'usoEficienteRecursosNaturales',
        label: 'Meta ProduccionConsumo.2',
        value: false,
        description: 'Lograr el uso eficiente de recursos naturales.'
      },
      {
        id: 'reduccionDesperdicioAlimentos',
        label: 'Meta ProduccionConsumo.3',
        value: false,
        description: 'Reducción del desperdicio de alimentos.'
      },
      {
        id: 'gestionDeshechos',
        label: 'Meta ProduccionConsumo.4',
        value: false,
        description: 'Gestión de deshechos y productos químicos.'
      },
      {
        id: 'prevencionReduccionReciclado',
        label: 'Meta ProduccionConsumo.5',
        value: false,
        description: 'Prevención, reducción, reciclado y reutilización de desechos.'
      },
      {
        id: 'adopcionPracticasSostenibles',
        label: 'Meta ProduccionConsumo.6',
        value: false,
        description: 'Adopción de prácticas sostenibles en empresas.'
      },
      {
        id: 'adquisicionPublicasSostenibles',
        label: 'Meta ProduccionConsumo.7',
        value: false,
        description: 'Adquisiciones públicas sostenibles.'
      },
      {
        id: 'asegurarEducacionDesarrolloSostenible',
        label: 'Meta ProduccionConsumo.8',
        value: false,
        description: 'Asegurar la educación para el Desarrollo Sostenible.'
      },
      {
        id: 'fortalecimientoCienciaTecnologia',
        label: 'Meta ProduccionConsumo.9',
        value: false,
        description: 'Fortalecimiento de ciencia y tecnología para sostenibilidad.'
      },
      {
        id: 'turismoSostenible',
        label: 'Meta ProduccionConsumo.10',
        value: false,
        description: 'Lograr turismo sostenible.'
      },
      {
        id: 'regularSubsidios',
        label: 'Meta ProduccionConsumo.11',
        value: false,
        description: 'Regulación de subsidios a combustibles fósiles.'
      }
    ]
  },
  {
    id: 'accionClima',
    title:
      'Objetivo 13. Acción por el clima: Adopción de medidas concretas de reducción del cambio climático.',
    options: [
      {
        id: 'fortalecerResilencia',
        label: 'Meta AccionClima.1',
        value: false,
        description: 'Fortalecimiento de la resiliencia y adaptación.'
      },
      {
        id: 'incorporarCambioClimaticoPoliticas',
        label: 'Meta AccionClima.2',
        value: false,
        description:
          'Incorporación del cambio climático en políticas, estrategias y planes nacionales.'
      },
      {
        id: 'mejoraEducacion',
        label: 'Meta AccionClima.3',
        value: false,
        description: 'Mejora de la educación y sensibilización medioambiental.'
      },
      {
        id: 'movilizacionRecursosEconomicos',
        label: 'Meta AccionClima.4',
        value: false,
        description: ' Movilización de recursos económicos.'
      },
      {
        id: 'gestionCambioClimatico',
        label: 'Meta AccionClima.5',
        value: false,
        description: 'Gestión del cambio climático en los países menos avanzados.'
      }
    ]
  },
  {
    id: 'vidaSubmarina',
    title: 'Objetivo 14. Vida Submarina',
    options: [
      {
        id: 'prevencionContaminacionMarina',
        label: 'Meta VidaSubmarina.1',
        value: false,
        description: 'Prevención y reducción de la contaminación marina.'
      },
      {
        id: 'gestionEcosistemasMarinosCosteros',
        label: 'Meta VidaSubmarina.2',
        value: false,
        description: 'Gestión ecosistemas marinos y costeros.'
      },
      {
        id: 'minimizarAcidificacionOceanos',
        label: 'Meta VidaSubmarina.3',
        value: false,
        description: 'Minimización de la acidificación de océanos.'
      },
      {
        id: 'regularExplotacionPesqueraSostenible',
        label: 'Meta VidaSubmarina.4',
        value: false,
        description: 'Regulación de la explotación pesquera sostenible.'
      },
      {
        id: 'conservarZonasCosterasYMarinas',
        label: 'Meta VidaSubmarina.5',
        value: false,
        description: 'Conservación zonas costeras y marinas.'
      },
      {
        id: 'combatirPescaIlegal',
        label: 'Meta VidaSubmarina.6',
        value: false,
        description: 'Combatir la pesca ilegal y excesiva.'
      },
      {
        id: 'aumentoBeneficiosEconomicosPescaSostenible',
        label: 'Meta VidaSubmarina.7',
        value: false,
        description: 'Aumento de los beneficios económicos de la pesca sostenible.'
      },
      {
        id: 'apoyoInvestigacionMarina',
        label: 'Meta VidaSubmarina.8',
        value: false,
        description: 'Apoyo a la investigación y tecnología marina.'
      },
      {
        id: 'fomentoPescaPequeniaEscala',
        label: 'Meta VidaSubmarina.9',
        value: false,
        description: 'Fomento de la pesca a pequeña escala y artesanal.'
      },
      {
        id: 'aplicacionConvencionNNUU',
        label: 'Meta VidaSubmarina.10',
        value: false,
        description: 'Aplicación Convención de Naciones Unidas sobre el Derecho del Mar.'
      }
    ]
  },
  {
    id: 'vidaEcosistema',
    title:
      'Objetivo 15.	Vida y ecosistemas terrestres: Conservar, restablecer y usar de manera sostenible los ecosistemas terrestres y los ecosistemas interiores de agua dulce.',
    options: [
      {
        id: 'conservacionEcosistemas',
        label: 'Meta VidaEcosistemas.1',
        value: false,
        description: 'Asegurar la conservación y uso sostenibles de los ecosistemas.'
      },
      {
        id: 'gestionSostenibleBosques',
        label: 'Meta VidaEcosistemas.2',
        value: false,
        description: 'Gestión sostenibles de bosques.'
      },
      {
        id: 'luchaContraDesertificacion',
        label: 'Meta VidaEcosistemas.3',
        value: false,
        description: 'Lucha contra la desertificación.'
      },
      {
        id: 'conservarEcosistemasMontaniosos',
        label: 'Meta VidaEcosistemas.4',
        value: false,
        description: 'Asegurar la conservación ecosistemas montañosos.'
      },
      {
        id: 'degradacionPerdidaBiodiversidad',
        label: 'Meta VidaEcosistemas.5',
        value: false,
        description: 'Medidas contra la degradación y pérdida de biodiversidad.'
      },
      {
        id: 'usoAdecuadoRecursosGeneticos',
        label: 'Meta VidaEcosistemas.6',
        value: false,
        description: 'Acceso y uso adecuado de los recursos genéticos.'
      },
      {
        id: 'combatirCazaFurtiva',
        label: 'Meta VidaEcosistemas.7',
        value: false,
        description: 'Combatir la caza furtiva de especies protegidas.'
      },
      {
        id: 'prevencionEspeciesInvasoras',
        label: 'Meta VidaEcosistemas.8',
        value: false,
        description: 'Prevención de especies invasoras.'
      },
      {
        id: 'integracionPlanesMedioAmbiente',
        label: 'Meta VidaEcosistemas.9',
        value: false,
        description: 'Integración de planes sensibles a medioambiente.'
      },
      {
        id: 'movilizacionAumentoRecursosFinancieros',
        label: 'Meta VidaEcosistemas.10',
        value: false,
        description: 'Movilización y aumento de los recursos financieros.'
      },
      {
        id: 'aumentoRecursosGestionForestal',
        label: 'Meta VidaEcosistemas.11',
        value: false,
        description: 'Aumento de recursos para gestión forestal.'
      },
      {
        id: 'luchaCazaFurtiva',
        label: 'Meta VidaEcosistemas.12',
        value: false,
        description: 'Apoyar la lucha contra caza furtiva.'
      }
    ]
  },
  {
    id: 'pazJusticiaInstituciones',
    title:
      'Objetivo 16.	Paz, justicia e instituciones sólidas: Promover el estado de derecho y garantizar la igualdad de acceso a la justicia para todos.',
    options: [
      {
        id: 'reduccionFormasViolencia',
        label: 'Meta PazJusticiaInstituciones.1',
        value: false,
        description: 'Reducción de todas las formas de violencia.'
      },
      {
        id: 'erradicacionMaltrato',
        label: 'Meta PazJusticiaInstituciones.2',
        value: false,
        description: 'Erradicación del maltrato, trata y explotación infantil.'
      },
      {
        id: 'promocionEstadoDerecho',
        label: 'Meta PazJusticiaInstituciones.3',
        value: false,
        description: 'Promoción del estado de derecho, acceso a justicia.'
      },
      {
        id: 'reduccionCorrientesFinancieras',
        label: 'Meta PazJusticiaInstituciones.4',
        value: false,
        description: 'Reducción de las corrientes financieras y de armas ilícitas.'
      },
      {
        id: 'reduccionCorrupcion',
        label: 'Meta PazJusticiaInstituciones.5',
        value: false,
        description: 'Reducción de la corrupción y soborno.'
      },
      {
        id: 'creacionInstitucionesEficaces',
        label: 'Meta PazJusticiaInstituciones.6',
        value: false,
        description: 'Creación de instituciones eficaces y transparentes.'
      },
      {
        id: 'fomentoParticipacionCiudadana',
        label: 'Meta PazJusticiaInstituciones.7',
        value: false,
        description: 'Fomento de la participación ciudadana.'
      },
      {
        id: 'fortalecerPaisesEnDesarrollo',
        label: 'Meta PazJusticiaInstituciones.8',
        value: false,
        description:
          'Fortalecimiento de la participación de países en desarrollo en organismos internacionales.'
      },
      {
        id: 'proporcionIdentidadJuridica',
        label: 'Meta PazJusticiaInstituciones.9',
        value: false,
        description: 'Proporción de identidad jurídica y registro de nacimientos.'
      },
      {
        id: 'accesoInformacion',
        label: 'Meta PazJusticiaInstituciones.10',
        value: false,
        description: 'Acceso a información y libertades fundamentales.'
      },
      {
        id: 'fortalecimientoInstituciones',
        label: 'Meta PazJusticiaInstituciones.11',
        value: false,
        description: 'Fortalecimiento de instituciones en prevención de la violencia.'
      },
      {
        id: 'promocionAplicacionLeyes',
        label: 'Meta PazJusticiaInstituciones.12',
        value: false,
        description: 'Promoción y aplicación de leyes y políticas (Derechos Humanos).'
      }
    ]
  },
  {
    id: 'alianzaODS',
    title:
      'Objetivo 17.	Alianzas para lograr los ODS: Promover las alianzas y la cooperación para lograr el cumplimiento de los ODS.',
    options: [
      {
        id: 'movilizacionRecursosDomesticos',
        label: 'Meta AlianzasODS1',
        value: false,
        description: 'Movilización de recursos domésticos para recaudación fiscal.'
      },
      {
        id: 'cumplirAyudaDesarrollo',
        label: 'Meta AlianzasODS2',
        value: false,
        description: 'Cumplimiento del 0,7% de Ayuda Oficial al Desarrollo.'
      },
      {
        id: 'movilizarRecursosFinancieros',
        label: 'Meta AlianzasODS3',
        value: false,
        description: 'Movilización de recursos financieros adicionales.'
      },
      {
        id: 'reestructurarDeuda',
        label: 'Meta AlianzasODS4',
        value: false,
        description: 'Reestructuración de la deuda.'
      },
      {
        id: 'promocionInversion',
        label: 'Meta AlianzasODS5',
        value: false,
        description: 'Promoción de inversiones en países menos adelantados.'
      },
      {
        id: 'mejorarTraspasoTecnologia',
        label: 'Meta AlianzasODS6',
        value: false,
        description: 'Mejora del traspaso de tecnología.'
      },
      {
        id: 'promoverTecnologiaEcologicas',
        label: 'Meta AlianzasODS7',
        value: false,
        description: 'Promoción de tecnologías ecológicamente racionales.'
      },
      {
        id: 'crearBancoTecnologia',
        label: 'Meta AlianzasODS8',
        value: false,
        description: 'Creación de banco de tecnología.'
      },
      {
        id: 'refuerzoCapacidades',
        label: 'Meta AlianzasODS9',
        value: false,
        description: 'Refuerzo de capacidades de implementación ODS.'
      },
      {
        id: 'promocionComercio',
        label: 'Meta AlianzasODS10',
        value: false,
        description: 'Promoción de comercio multilateral universal.'
      },
      {
        id: 'aumentoExportaciones',
        label: 'Meta AlianzasODS11',
        value: false,
        description: 'Aumento de las exportaciones de países en desarrollo.'
      },
      {
        id: 'accesoMercados',
        label: 'Meta AlianzasODS12',
        value: false,
        description: 'Acceso a mercado para países en desarrollo.'
      },
      {
        id: 'aumentoEstabilidad',
        label: 'Meta AlianzasODS13',
        value: false,
        description: 'Aumento de la estabilidad macroeconómica mundial.'
      },
      {
        id: 'mejoraCoherencia',
        label: 'Meta AlianzasODS14',
        value: false,
        description: 'Mejora de la coherencia de políticas.'
      },
      {
        id: 'respetoSoberania',
        label: 'Meta AlianzasODS15',
        value: false,
        description: 'Respeto a la soberanía nacional.'
      },
      {
        id: 'mejorarAlianzaMundial',
        label: 'Meta AlianzasODS16',
        value: false,
        description: 'Mejorar la alianza mundial para el desarrollo sostenible.'
      },
      {
        id: 'fomentoAlianzas',
        label: 'Meta AlianzasODS17',
        value: false,
        description: 'Fomento de alianzas público-privadas.'
      },
      {
        id: 'creacionCapacidadEstadistica',
        label: 'Meta AlianzasODS18',
        value: false,
        description: 'Creación de capacidad estadística.'
      },
      {
        id: 'promoverIndicadores',
        label: 'Meta AlianzasODS19',
        value: false,
        description: 'Promoción de indicadores que vayan más allá del Producto Interno Bruto.'
      }
    ]
  }
];

export default goals;
