import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  DashboardHomeContainer,
  DashboardHomeMain,
  DashboardHomeInvestmentOpportunity,
  Welcome,
  Campaigns,
  DashboardWelcomeInfo,
  DashboardWelcomeDepositWithdrawButtons
} from './home.styles';
import Sponsors from 'src/components/Layout/sponsors/sponsors';
import {
  getApplicantFiles,
  getApplicantPLD,
  getCreditApproved
} from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import WithdrawVirtualAccount from 'src/components/shared/modals/withdrawVirtualAccount/withdrawVirtualAccount';
import ModalInfoVirtualAccount from 'src/components/shared/modals/infoVirtualAccount/infoVirtualAccount';

export default function DashboardHome() {
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const [infoVirtualAccount, setInfoVirtualAccount] = useState(false);
  const [detailInvestPay, setDetailInvestPay] = useState(false);
  const [simafValid, setSimafValid] = useState(null);
  const [esgFilled, setEsgFilled] = useState(null);
  const [filesValidator, setFilesValidator] = useState(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.currentUser);
  const stp = personalInformation?.data?.getApplicant?.STP;
  const amount = stp?.recursosDisponibles;
  const personalInformationApplicant = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant
  );

  //Banner
  const onboardingFinished = useSelector((state) => state.user.onboardingFinished);
  const currentStep = useSelector((state) => state.user.currentStep);
  const userId = user.id;
  const handleBannerClick = () => {
    let route;
    switch (currentStep) {
      case 1:
        route = navigate('/solicitante/onboarding-data');
        break;
      case 2:
        route = navigate('/solicitante/onboarding-creditInfo');
        break;
      case 3:
        route = navigate('/solicitante/onboarding-analysis');
        break;
      case 4:
        route = navigate('/solicitante/onboarding-phone');
        break;
      default:
        route = navigate('/solicitante/onboarding-data');
    }
  };
  const handleDeposit = () => {
    setInfoVirtualAccount(true);
  };
  const handleRetirement = () => {
    setDetailInvestPay(true);
  };
  const handleButtonClick = () => {
    navigate('/solicitante/dashboard/esg');
  };
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  useEffect(() => {
    setInfoVirtualAccount(infoVirtualAccount);
  }, [infoVirtualAccount]);

  useEffect(() => {
    setDetailInvestPay(detailInvestPay);
  }, [detailInvestPay]);

  const campaigns = personalInformation?.data?.getApplicant?.campaigns;
  const activeCampaigns = campaigns?.items?.filter((campaign) => campaign.isActive === '1');
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getApplicantFiles,
          variables: { id: userId }
        });
        const files = data?.getFile?.areBusinessDocumentsUpload;
        setFilesValidator(files);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data } = await API.graphql({
          query: getApplicantPLD,
          variables: { id: userId }
        });
        const simaf = data.getApplicant.isPLDApproved;
        setSimafValid(simaf);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data } = await API.graphql({
          query: getCreditApproved,
          variables: { applicantId: userId }
        });
        const esg = data?.getCreditAnalysis?.environmentalAndSocialImpact;
        setEsgFilled(esg);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  return (
    <DashboardHomeContainer>
      <WithdrawVirtualAccount
        showModalWithdrawVirtualAccount={detailInvestPay}
        setModal={setDetailInvestPay}
        amount={amount}
        personalInformationApplicant={personalInformationApplicant}
      />
      <ModalInfoVirtualAccount
        showModalInfoVirtualAccount={infoVirtualAccount}
        setModal={setInfoVirtualAccount}
        amount={amount}
      />
      <DashboardHomeMain>
        <Welcome>
          <div>
            <h2>¡Bienvenido a FinSphera!</h2>
            <h3>La opción más fácil para solictar créditos</h3>
          </div>
          <h4>
            ¡Ya eres parte de la comunidad de Finsphera y estás listo para impactar positivamente a
            nuestra Comunidad y Medio Ambiente!.
          </h4>
        </Welcome>
      </DashboardHomeMain>
      <Campaigns>
        {filesValidator !== null &&
          simafValid !== null &&
          (filesValidator === false || simafValid === '0' ? (
            <div>
              <h4>
                Entra a:{' '}
                <Link to="/solicitante/dashboard/mi-perfil">Mi Perfil / Mis documentos</Link> y
                captura la información que se te pide. Al terminar, en un lapso no mayor a 24hrs.
                recibirás un correo con la notificación de que tu cuenta está lista para comenzar a
                tomar crédito.
              </h4>
            </div>
          ) : (
            <div>
              <h3>Solicita inversión a través de Finsphera</h3>
              <Link to="/solicitante/dashboard/campañas">Ir a campañas</Link>
              <p>
                Recuerda que al ser un cliente cumplido, puedes subir tu nivel de crédito y obtener
                mejores tasas de interés.
              </p>
            </div>
          ))}
      </Campaigns>
      <DashboardWelcomeInfo>
        <div>
          <h3>Saldo disponible</h3>
          <h3>Campañas activas</h3>
        </div>
        <div></div>
        <div>
          <p>{stp?.recursosDisponibles ? formatCurrency(stp?.recursosDisponibles) : '0.00'}</p>
          <p>{activeCampaigns?.length ? activeCampaigns?.length : '0'}</p>
        </div>
      </DashboardWelcomeInfo>
      <DashboardWelcomeDepositWithdrawButtons>
        <button onClick={handleDeposit}>Depositar a Cuenta Virtual</button>
        <button onClick={handleRetirement}>Retiros Cuenta Virtual</button>
      </DashboardWelcomeDepositWithdrawButtons>
      <Sponsors />
      <DashboardHomeInvestmentOpportunity>
        <div>
          <h2>Environmental, Social, and Governance (Ambiental, Social y Gobernanza)</h2>
          <p>
            ESG es una herramienta valiosa para evaluar y guiar las prácticas empresariales hacia un
            camino más sostenible y ético.
          </p>
          {esgFilled === null ? (
            <Button onClick={handleButtonClick} disabled={esgFilled !== null}>
              Responde nuestro cuestionario ESG
            </Button>
          ) : (
            <h3>Ya has completado el cuestionario ESG</h3>
          )}
        </div>
        <div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/ESG_2.png`}
              alt="IMAGEN DE REFERENCIA ESG"
            />
          </figure>
        </div>
      </DashboardHomeInvestmentOpportunity>
    </DashboardHomeContainer>
  );
}
