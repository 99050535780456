import { useState } from 'react';
import {
  Success,
  Figure,
  ActionSection,
  ModalExperienced,
  ButtonsSection
} from './experiencedInvestor.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import getCurrentDate from 'src/functions/getCurrentDate';
import { Card } from 'src/utilities/UILibrary';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Auth } from 'aws-amplify';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { ExperiencedInvestorProps } from './experiencedInvestor.types';

const ExperiencedInvestor: React.FC<ExperiencedInvestorProps> = (props) => {
  const { setExperiencedModal, experiencedModal, personal, investments } = props;
  const [successText, setSuccessText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [password, setPassword] = useState('');
  const [type, setType] = useState('password');
  const [passwordError, setPasswordError] = useState('');
  const [showInput, setShowInput] = useState(false);
  const date = getCurrentDate().split('T')[0];
  const closeModal = () => {
    setExperiencedModal(false);
  };
  const creationDateInvestor = useSelector(
    (state: RootState) => state.profile.personalInformation?.data?.getInvestor?.createdAt
  );
  const hasOneYearPassed = (creationDate: string): boolean => {
    const creation = new Date(creationDate);
    const today = new Date();
    const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
    return creation <= oneYearAgo;
  };
  const calculateTotalInvestment = (
    investments: { status: string; compromisoInversion: number }[]
  ): number => {
    return investments
      .filter((investment) => investment.status === 'pagado')
      .reduce((acc, current) => acc + current.compromisoInversion, 0);
  };
  const totalInvestment = calculateTotalInvestment(investments);
  const isInvestmentEligible = totalInvestment > 4187195;
  const isEligible = hasOneYearPassed(creationDateInvestor) && isInvestmentEligible;

  const handleSubmitStart = () => {
    setCurrentStep(2);
  };
  const handleSubmitAccept = () => {
    setShowInput(true);
  };
  const handleSubmitSign = async () => {
    setLocalLoading(true);
    setPasswordError('');
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.signIn(currentUser.username, password);
      setSuccessText(true);
    } catch (error: unknown) {
      if ((error as Error).message === 'Incorrect username or password.') {
        setPasswordError('La contraseña es incorrecta. Inténtalo de nuevo.');
      } else {
        console.error(error);
      }
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <ModalExperienced {...props}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="ícono de correo electrónico"
            />
          </figure>
          <h2>
            ¡Tu solicitud ha sido generada de forma exitosa! En breve nos estaremos comunicando
            contigo.
          </h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          {currentStep === 1 && (
            <ActionSection>
              <Card isForm>
                <Figure>
                  <div></div>
                  <figure onClick={closeModal}>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                      alt="ícono para cerrar la ventana"
                    />
                  </figure>
                </Figure>
                <p>
                  Un inversionista experimentado es aquel que debido a su alto compromiso con el
                  desarrollo de PyMes mexicanas a través de nuestra plataforma, desea seguir
                  contribuyendo dentro de esta comunidad y generar mayor impacto. Para ser
                  inversionista experimentado debes tener al menos 12 meses de experiencia o haber
                  invertido 550,000 UDI’s.
                </p>
                <ButtonsSection>
                  <Button onClick={closeModal} isLoading={localLoading} isFullWidth size="small">
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleSubmitStart}
                    isLoading={localLoading}
                    isFullWidth
                    size="small"
                    disabled={!isEligible}
                  >
                    Comenzar solicitud
                  </Button>
                </ButtonsSection>
              </Card>
            </ActionSection>
          )}
          {currentStep === 2 && (
            <ActionSection>
              <Card isForm>
                <Figure>
                  <div></div>
                  <figure onClick={closeModal}>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                      alt="ícono para cerrar la ventana"
                    />
                  </figure>
                </Figure>
                <Card.Row>
                  <h2>
                    FORMATO DE MANIFESTACIÓN RESPECTO DEL CUMPLIMIENTO DE LOS REQUISITOS PARA SER
                    CONSIDERADO COMO INVERSIONISTA EXPERIMENTADO
                  </h2>
                </Card.Row>
                <Card.Row>
                  <p>
                    Yo <span>{personal?.fullName} </span> declaro que es mi interés ser considerado
                    como inversionista experimentado en términos del artículo 2, fracción X, inciso
                    d) de las Disposiciones de carácter general aplicables a las instituciones de
                    tecnología financiera, y que estoy consciente de que actuar con esa calidad,
                    además de los riesgos propios derivados de ser inversionista en una institución
                    de financiamiento colectivo, implica poder realizar compromisos de inversión que
                    superen los porcentajes establecidos en dichas disposiciones y, por lo tanto,
                    podría estar concentrando mi inversión, lo cual incrementa el riesgo de
                    perderla. Entiendo como compromiso de inversión lo señalado en el artículo 2,
                    fracción I de esas mismas disposiciones. Habiendo reconocido lo anterior,
                    declaro que he realizado operaciones, según se define en la Ley para Regular las
                    Instituciones de Tecnología Financiera, en instituciones de financiamiento
                    colectivo con el carácter de inversionista, cuyo importe agregado es superior al
                    equivalente en moneda nacional a 550,000 UDI’s, y que cuento con al menos 12
                    meses de experiencia realizándolas.
                  </p>
                </Card.Row>
                <Card.Row>
                  <p>
                    Entiendo que <span>Finsphera (SUSTAINABLE TECH ESG, S.A.P.I. DE C.V.) </span>{' '}
                    requerirá información y documentación necesarias, a fin de verificar que me
                    encuentro en el supuesto anterior; y que de no proporcionarla, no podré ser
                    considerado como inversionista experimentado para los efectos del artículo 50,
                    fracción V de las referidas disposiciones.
                  </p>
                </Card.Row>
                <div>
                  <p>Nombre completo:</p>
                  <h4>{personal?.fullName}</h4>
                </div>
                <div>
                  <p>Fecha de firma:</p>
                  <h4>{date}</h4>
                </div>
                {showInput && (
                  <>
                    <Card.Row>
                      <InputField
                        type={type}
                        placeholder="Contraseña actual"
                        label="Ingresa la contraseña con la que inicias sesión:"
                        value={password}
                        onChangeInternalValue={(value) => setPassword(String(value))}
                        required
                      />
                      {passwordError && <h4 style={{ color: 'red' }}>{passwordError}</h4>}
                    </Card.Row>
                    <ButtonsSection>
                      <Button onClick={() => setCurrentStep(1)} isFullWidth size="small">
                        Regresar
                      </Button>
                      <Button
                        onClick={handleSubmitSign}
                        isLoading={localLoading}
                        isFullWidth
                        size="small"
                      >
                        Firmar
                      </Button>
                    </ButtonsSection>
                  </>
                )}
                {!showInput && (
                  <ButtonsSection>
                    <Button onClick={() => setCurrentStep(1)} isFullWidth size="small">
                      Regresar
                    </Button>
                    <Button onClick={handleSubmitAccept} isFullWidth size="small">
                      Aceptar
                    </Button>
                  </ButtonsSection>
                )}
              </Card>
            </ActionSection>
          )}
        </div>
      )}
    </ModalExperienced>
  );
};

export default ExperiencedInvestor;
