import styled from 'styled-components/macro';
import { blackSphera, supportLemonGreen } from 'src/utilities/UIStyles/variables/colors';

export const OurShareholdersAndOurCommunityContainer = styled.section``;

export const ShareholdersCommunityTitle = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > h2 {
    width: 40%;
    font-size: 3vw;
    color: ${blackSphera};
    margin: 10% 10% 2% 8%;
  }
  > img {
    width: 50%;
    height: auto;
    background-size: cover;
    top: 0;
    right: 0;
    position: absolute;
    border-bottom-left-radius: 50%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }
  }
  @media (max-width: 600px) {
    > h2 {
      font-size: 4vw;
      margin: 25% 10% 2% 5%;
    }

    > img {
      margin-top: 15%;
    }
  }
`;

export const Directory = styled.section`
  background-color: #f8f8f9;
  > h2 {
    padding: 3% 0 3% 0;
    text-align: left;
    font-size: 3vw;
    color: ${supportLemonGreen};
    margin-left: 8%;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      font-size: 2.5vw;
    }
  }
  @media (max-width: 600px) {
    > h2 {
      font-size: 4.5vw;
    }
  }
`;

export const DirectoryInfo = styled.section`
  display: flex;
  width: 100%;
  > div {
    margin: 0 2.5%;
    width: 45%;
    > div {
      width: 80%;
      margin: 0 auto 5% auto;
      > p {
        font-size: 1.2vw;
        margin: 2% 0;
        color: ${blackSphera};
        font-weight: bold;
      }
      > div {
        > div {
          display: flex;
          align-items: center;
          > figure {
            width: 40px;
            height: 40px;
            margin-right: 1%;
            > img {
              width: 90%;
              height: 90%;
              object-fit: cover;
            }
          }
          > p {
            font-size: 1.2vw;
            color: ${blackSphera};
            margin-bottom: 1%;
          }
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > div {
        > div {
          > div {
            > p {
              font-size: 1.8vw;
            }
          }
        }
        > p {
          font-size: 1.5vw;
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 80%;
      > div {
        > div {
          > div {
            > p {
              font-size: 2.6vw;
            }
          }
        }
        > p {
          font-size: 2.6vw;
        }
      }
    }
  }
`;
