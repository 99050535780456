import styled from 'styled-components/macro';

import {
  blackSphera,
  grayLightSphera,
  grayPlaceholder,
  graySphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const PersonalInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);
  padding-top: 5%;
  padding-bottom: 2.5%;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    padding-top: 25%;
    padding-bottom: 2.5%;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${whiteSphera};
  padding: 2.5% 0 2.5% 15%;

  > figure {
    width: 85px;
    height: 85px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    color: ${blackSphera};
    font-size: 2.5vw;
    margin-right: 5%;
  }

  > p {
    color: ${graySphera};
    font-size: 1.5vw;
    width: 42%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure {
      > img {
      }
    }

    > h2 {
    }

    > p {
    }
  }

  @media (max-width: 600px) {
    padding: 2.5% 0 2.5% 2.55%;
    justify-content: center;
    flex-wrap: wrap;

    > figure {
      width: 65px;
      height: 65px;

      > img {
      }
    }

    > h2 {
      font-size: 4vw;
      width: 70%;
    }

    > p {
      text-align: justify;
      font-size: 4vw;
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
    }
  }
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 2.5% auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  .card-container {
    width: 80%;
    margin: 0 10%;
    padding: 5% 10%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    width: 95%;
    margin: 5% auto;
    padding: 5%;
  }
`;

export const FormDataEconomicForm = styled.div`
  display: flex;

  > div:nth-child(1) {
    width: 45%;

    > p {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-top: 2.5%;
      margin-bottom: 1%;
    }

    > select {
      color: ${grayPlaceholder};
      font-size: 1.2vw;
      width: 100%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }

  > div:nth-child(2) {
    width: 45%;
    margin-left: 10%;

    > p {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-top: 2.5%;
      margin-bottom: 1%;
    }

    > select {
      color: ${grayPlaceholder};
      font-size: 1.2vw;
      width: 100%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 1% 10%;

  > button {
    font-size: 1.2vw;
    font-weight: bold;
    color: ${whiteSphera};
    padding: 1% 6%;
    border: none;
    border-radius: 5px;
    background-color: ${blackSphera};
    cursor: pointer;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > a:nth-child(1) {
    }

    > button:nth-child(2) {
    }
  }

  @media (max-width: 600px) {
    > a:nth-child(1) {
      font-size: 4.9vw;
      margin-left: 2%;
      padding: 4% 2%;
      width: 45%;
      cursor: pointer;
    }

    > button:nth-child(2) {
      font-size: 4.9vw;
      width: 48%;
      margin-left: 2.5%;
      padding: 4% 2%;
    }
  }
`;
