import styled from 'styled-components/macro';

import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayMediumSphera,
  brandYellowgreen,
  neutralBlueGray,
  grayPlaceholder,
  graySphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalCreditSuccess = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.showModalCreditSuccess ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  animation: ${(props) => (props.showModalCreditSuccess ? fadeIn : fadeOut)} 0.5s linear;
  z-index: 10000;
  > div {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    padding: 3%;

    > h3 {
      font-size: ${uIFontSize.h3};
      color: ${grayMediumSphera};
    }
    > p {
      color: ${brandBlack};
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 95%;

      > a {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
        padding: 3% 8%;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
  > h2 {
    font-size: ${uIFontSize.subTitle};
    text-align: center;
    color: ${brandBlack};
    > span {
      color: ${greenSp};
      font-size: ${uIFontSize.subTitle};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      font-size: ${uIFontSize.subTitle};
      > span {
        font-size: ${uIFontSize.subTitle};
      }
    }
  }
  @media (max-width: 600px) {
    > h2 {
      font-size: ${uIFontSize.subTitle};
      > span {
        font-size: ${uIFontSize.subTitle};
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
  > p {
    width: 100%;
    text-align: center;
  }
  > input {
    width: 50%;
    margin: 2% 25%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid ${grayPlaceholder};
    font-size: ç;
    text-align: center;
    letter-spacing: 2vw;
    color: ${grayPlaceholder};
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      font-size: ${uIFontSize.paragraph};
    }
  }
  @media (max-width: 600px) {
    > p {
      font-size: ${uIFontSize.paragraph};
    }
  }
`;

export const ConfirmButton = styled.button`
  color: ${brandBlack};
  font-size: ${uIFontSize.paragraph};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0 30%;
  padding: 2% 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandYellowgreen};
  cursor: pointer;
  transition: all 0.5s;
  &:hover {
    transform: scale(1.1);
  }
  &:disabled {
    background-color: ${grayPlaceholder};
    color: ${whiteSphera};
    cursor: not-allowed;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: ${uIFontSize.paragraph};
  }
  @media (max-width: 600px) {
    font-size: ${uIFontSize.paragraph};
  }
`;

export const MainInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 5% 0;
  > div {
    width: 80%;
    display: flex;
    justify-content: space-between;
    h3 {
      width: 50%;
      font-size: ${uIFontSize.h3};
      color: ${grayMediumSphera};
      text-align: left;
      font-weight: 100;
      margin: 1% 0;
    }
    > p {
      width: 50%;
      color: ${grayMediumSphera};
      text-align: right;
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      margin: 1% 0;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > div {
      width: 90%;
      > h3 {
        font-size: ${uIFontSize.paragraph};
      }
      > p {
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const ComplementaryInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 2% 0;
  > div {
    width: 80%;
    display: flex;
    margin: 0 10%;
    justify-content: space-between;
    h3 {
      width: 80%;
      font-size: ${uIFontSize.h3};
      color: ${grayMediumSphera};
      text-align: left;
      font-weight: 100;
      margin: 1% 0;
    }
    > p {
      width: 50%;
      color: ${grayMediumSphera};
      text-align: right;
      font-size: ${uIFontSize.paragraph};
      margin: 1% 0;
    }
  }
  @media (max-width: 600px) {
    > div {
      > h3 {
        font-size: ${uIFontSize.h3};
      }
      > p {
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: ${uIFontSize.subTitle};
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: ${uIFontSize.paragraph};
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    text-align: center;
    > a {
      color: ${greenSp};
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
    }
  }
`;

export const Headers = styled.section`
  padding-bottom: 1%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #b7bcc7;
  > div {
    width: 90%;
  }
  > figure {
    width: 30px;
    height: 30px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 600px) {
    padding-bottom: 5%;
    > figure {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Totals = styled.section`
  width: 80%;
  display: flex;
  margin: 0 10%;
  justify-content: space-evenly;
  border-bottom: 1px solid ${graySphera};
  h3 {
    width: 80%;
    font-size: ${uIFontSize.paragraph};
    color: ${grayMediumSphera};
    text-align: left;
    font-weight: bold;
    margin: 1% 0;
  }
  h2 {
    width: 80%;
    font-size: ${uIFontSize.large};
    color: ${brandBlack};
    text-align: left;
    font-weight: bold;
    margin: 1% 0;
  }
  > p {
    width: 50%;
    color: ${greenSp};
    text-align: right;
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    margin: 1% 0;
  }
`;

export const IntermediateInfo = styled.div`
  width: 70%;
  display: flex;
  margin: 0 15%;
  justify-content: space-between;
  > div {
    > p {
      text-align: center;
    }
  }
`;
