import { forwardRef, useState, useEffect, useRef, ChangeEvent } from 'react';
import { UploadButtonContainer } from './uploadButton.styles';
import { UploadButtonProps } from './uploadButton.types';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export const UploadButton = forwardRef<HTMLDivElement, UploadButtonProps>((props, ref) => {
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const {
    children,
    isFullWidth,
    size = 'medium',
    isSlim = true,
    isLoading,
    loadingText = 'Cargando...',
    onChange,
    name,
    id,
    successMessage = 'Archivo cargado',
    accept,
    disabled,
    ...rest
  } = props;
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      onChange(event, event.target.files[0]);
      setIsFileUploaded(true);
    } else {
      onChange(event, undefined);
    }
  };

  return (
    <UploadButtonContainer ref={ref} onChange={onChange} {...rest}>
      <input
        id={id}
        name={name}
        ref={fileInputRef}
        type="file"
        onChange={handleFileChange}
        accept={accept}
      />
      <Button
        type="button"
        isFullWidth={isFullWidth}
        size={size}
        isSlim={isSlim}
        onClick={handleClick}
        isLoading={isLoading}
        loadingText={loadingText}
        disabled={disabled ?? isFileUploaded}
      >
        {isFileUploaded ? successMessage : children}
      </Button>
    </UploadButtonContainer>
  );
});

UploadButton.displayName = 'UploadButton';
