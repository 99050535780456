import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {
  DetailContainer,
  CardLogo,
  MainDetail,
  InvestDetail,
  CampaignInfo,
  CardStatus,
  GeneralInfo,
  InterestInfo,
  CompanyInfo,
  CompanyContainer,
  CompanyAdd
} from './opportunitiesDetail.styles';
import { useDispatch, useSelector } from 'react-redux';
import InvestSimulator from '../../../../components/features/investor/dashboard/investSimulator/investSimulator';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';
import { API } from 'aws-amplify';
import { getCreditApproved } from 'src/graphql/customized_queries';

const OpportunitiesDetail = (props) => {
  const defaultImage = `${process.env.REACT_APP_BUCKET_FINSPHERA}/image.png`;
  const location = useLocation();
  const item = location.state?.detail || null;
  const dataCompany = item?.applicant?.companyInformation;
  const [profileImage, setProfileImage] = useState('');
  const [esgData, setEsgData] = useState();
  const applicantId = item?.applicant?.id;
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  useEffect(() => {
    fetchImage().then(setProfileImage);
  }, []);
  const fetchImage = async () => {
    const body = {
      Key: `${user.id}/media/profileImage.jpg`
    };
    try {
      setProfileImage(`${user.id}/media/profileImage.jpg`);
    } catch (err) {
      console.error(err);
      return defaultImage;
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await API.graphql({
          query: getCreditApproved,
          variables: { applicantId: applicantId }
        });
        setEsgData(data.data?.getCreditAnalysis, 'datadata');
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  const percentageFinanced =
    item?.collectedAmount >= 0
      ? Math.round((item?.collectedAmount / item?.montoSolicitado) * 100)
      : 0;

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return (
    <DetailContainer>
      <MainDetail>
        <CardLogo>
          <img src={profileImage} alt="CompanyLogo" />
        </CardLogo>
        <InvestDetail>
          <div>
            <h2>
              {dataCompany?.companyName?.legalName ? dataCompany?.companyName?.legalName : '-'}
            </h2>
            <p>Monto a financiar</p>
            <h2>{formatCurrency(item?.montoSolicitado ? item?.montoSolicitado : '-')} MXN</h2>
          </div>
          <div>
            <CardStatus percentage={percentageFinanced}>
              <p>Porcentaje financiado: {percentageFinanced} %</p>
              <div>
                <div></div>
              </div>
            </CardStatus>
            <div>
              <div></div>
            </div>
          </div>
        </InvestDetail>
        <Link to="/inversionista/dashboard/oportunidades">Regresar</Link>
      </MainDetail>
      <CampaignInfo>
        <GeneralInfo>
          <div>
            <h3>Nombre de la campaña: </h3>
            <p>{item?.nombreCampania ? item.nombreCampania : '-'}</p>
          </div>
          <div>
            <h3>Destino del crédito: </h3>
            <p>{item?.destinoCredito ? item?.destinoCredito : '-'}</p>
          </div>
          <div>
            <h3>Plazo en días: </h3>
            <p>{item?.plazoCredito ? item?.plazoCredito : '-'}</p>
          </div>
          <div>
            <h3>Fuente de ingresos para pago: </h3>
            <p>{item?.fuentePago ? item?.fuentePago : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Monto invertido hasta ahora:</h3>
            <p>{item?.collectedAmount === -1 ? '0.00' : formatCurrency(item?.collectedAmount)}</p>
          </div>
          <div>
            {' '}
            <h3>Valor de las facturas:</h3>
            <p>{item?.valueOfInvoices === -1 ? '0.00' : formatCurrency(item?.valueOfInvoices)}</p>
          </div>
        </GeneralInfo>
        <InterestInfo>
          <h3>Tasa de interés adicional: </h3>
          <p>{Number(item?.tasaInteresAdicionalPorcentaje * 100).toFixed(2)} %</p>
          <h3>Tasa de interés del crédito: </h3>
          <p>{Number(item?.tasaCreditoPorcentaje * 100).toFixed(2)} %</p>
          <h3>Interes del crédito en pesos: </h3>
          <p>{formatCurrency(item?.interesCreditoPesos)}</p>
          <h3>IVA del interés del crédito en pesos: </h3>
          <p>{formatCurrency(item?.IVAinteresCreditoPesos)}</p>
          <h3>Interés del crédito en pesos + IVA: </h3>
          <p>{formatCurrency(item?.interesesCreditoPesosConIVA)}</p>
        </InterestInfo>
        <InterestInfo>
          <h3>Tasa de interés moratoria: </h3>
          <p>{Number(item?.tasaInteresMoratorioPorcentaje * 100).toFixed(2)} %</p>
          <h3>Comisión del crédito:</h3>
          <p>{formatCurrency(item?.comisionCreditoPesos)}</p>
          <h3>IVA de la comisión del crédito: </h3>
          <p>{formatCurrency(item?.IVAcomisionCreditoPesos)}</p>
          <h3>Comisión del crédito + IVA: </h3>
          <p>{formatCurrency(item?.comisionCreditoPesosConIVA)}</p>
          <h3>Monto inversión de Finsphera en campaña: </h3>
          <p>{formatCurrency(item?.inversionFinspheraCampaniaPesos)}</p>
        </InterestInfo>
      </CampaignInfo>
      <InvestSimulator />
      <CompanyContainer>
        <h2>Información de la compañía</h2>
        <CompanyInfo>
          <div>
            <h3>Actividad económica:</h3>
            <p>{dataCompany?.generalActivity ? dataCompany?.generalActivity : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Actividad específica:</h3>
            <p>{dataCompany?.specificActivity ? dataCompany?.specificActivity : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Sector de negocio:</h3>
            <p>{dataCompany?.businessSector ? dataCompany?.businessSector : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Correo de contacto:</h3>
            <p>{dataCompany?.email ? dataCompany?.email : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Inicio de operaciones:</h3>
            <p>
              {dataCompany?.startedOperationsAt
                ? formatDate(dataCompany?.startedOperationsAt)
                : '-'}
            </p>
          </div>
          <div>
            {' '}
            <h3>Empleados:</h3>
            <p>{dataCompany?.employees ? dataCompany?.employees : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Tipo de sociedad:</h3>
            <p>{dataCompany?.societyType ? dataCompany?.societyType : '-'}</p>
          </div>
        </CompanyInfo>
        <CompanyAdd>
          {esgData?.environmentalAndSocialImpact?.isApproved === true ? (
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/ESG_2.png`}
              alt="Imagen de referencia ESG"
            />
          ) : null}
          <div>
            <h3>Valores de impacto social y ambiental:</h3>
            {esgData?.environmentalAndSocialImpact?.isApproved === true ? 'CUMPLE' : 'NO APLICA'}
          </div>
          <div>
            {' '}
            <h3>Calificación del análisis de crédito con Finsphera:</h3>
            <p>{esgData?.rating ? esgData?.rating.toFixed(2) : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>Rating en equidad de género:</h3>
            <p>
              {esgData?.genderEquality.rating ? esgData?.genderEquality.rating.toFixed(2) : '-'}
            </p>
          </div>
          <div>
            {' '}
            <h3>Rating en gobierno corporativo:</h3>
            <p>
              {esgData?.corporateGovernance.rating
                ? esgData?.corporateGovernance.rating.toFixed(2)
                : '-'}
            </p>
          </div>
          <div>
            {' '}
            <h3>Página web:</h3>
            <p>{dataCompany?.webSite ? dataCompany?.webSite : '-'}</p>
          </div>
          <div>
            {' '}
            <h3>País:</h3>
            <p>{dataCompany?.country ? dataCompany?.country : '-'}</p>
          </div>
        </CompanyAdd>
      </CompanyContainer>
    </DetailContainer>
  );
};

export default OpportunitiesDetail;
