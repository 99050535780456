import styled from 'styled-components/macro';
import {
  blackSphera,
  brandBlack,
  neutralBlueGray,
  supportLemonGreen,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const PersonalInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);
  padding: 0 0 2.5% 0;
  .card-container {
    ${spaceFlowContainer()}
  }

  .modal-body {
    dd {
      margin-bottom: 1rem;
    }

    dd:last-child {
      margin-bottom: 0;
    }
  }

  .full-width {
    width: 100%;
  }
`;

export const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${spaceFlowContainer(true)}
  background-color: ${whiteSphera};
  padding: 2.5% 0;
  & > figure {
    width: 70px;
    height: 70px;
    margin-right: 2%;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  & > h1 {
    color: ${blackSphera};
    width: 25%;
  }
  & > p {
    color: ${blackSphera};
    font-size: ${uIFontSize.paragraph};
    width: 35%;
    margin-left: 8%;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 8% 0 2% 0;
  }
  @media (max-width: 600px) {
    padding: 15% 0 5% 0;
    justify-content: center;
    flex-wrap: wrap;
    & > figure {
      width: 80px;
      height: 80px;
      margin-right: 5%;
      & > img {
        width: 100%;
        height: 100%;
      }
    }
    > h1 {
      text-align: center;
      width: 100%;
      margin-right: 0;
      margin-bottom: 5%;
    }
    > p {
      text-align: center;
      width: 90%;
      margin: 0;
    }
  }
`;

export const FormSection = styled.div`
  & > h2 {
    color: ${neutralBlueGray};
    margin: 2.5% 0;
    padding-bottom: 1.5%;
    border-bottom: 2px solid ${neutralBlueGray};
  }
`;

export const ContactData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  margin-bottom: 2%;
`;

export const ConsultCredit = styled.div`
  padding: 2%;
  border-bottom: 1px solid ${neutralBlueGray};
  & > h2 {
    color: ${supportLemonGreen};
    margin-top: 5%;
  }
  .input-wrapper label {
    color: ${brandBlack};
  }

  .checkbox > .checkbox {
    margin-top: 1rem;
  }

  & > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 3% 10% 1rem 0;
    & > input {
      margin-right: 1%;
      margin-top: 0.5%;
      width: 20px;
      accent-color: ${supportLemonGreen};
    }
    > figure {
      width: 30px;
      height: 29px;
      margin-right: 2.5%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (max-width: 600px) {
    & > div {
      margin: 2.5% 0 5% 0;
      & > input {
        margin: 1% 1% 0 0;
      }
      & > label {
        margin-right: 5%;
      }
    }
  }
`;

export const BiometricsSection = styled.div`
  width: 100%;
  margin: 5% 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    color: ${blackSphera};
    margin-top: 2.5%;
    margin-bottom: 5%;
    width: 100%;
  }
  @media (max-width: 600px) {
    width: 90%;
    margin: 5% 5%;
  }
`;
export const Container = styled.div`
  ${spaceFlowContainer()}
  display: flex;
  justify-content: space-around;
  text-align: center;
`;
