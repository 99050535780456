export const simafStates = [
  {
    federal_entity_simaf_code: 1,
    federal_entity_description: 'Aguascalientes'
  },
  {
    federal_entity_simaf_code: 2,
    federal_entity_description: 'Baja California'
  },
  {
    federal_entity_simaf_code: 3,
    federal_entity_description: 'Baja California Sur'
  },
  {
    federal_entity_simaf_code: 4,
    federal_entity_description: 'Campeche'
  },
  {
    federal_entity_simaf_code: 5,
    federal_entity_description: 'Coahuila de Zaragoza'
  },
  {
    federal_entity_simaf_code: 6,
    federal_entity_description: 'Colima'
  },
  {
    federal_entity_simaf_code: 7,
    federal_entity_description: 'Chiapas'
  },
  {
    federal_entity_simaf_code: 8,
    federal_entity_description: 'Chihuahua'
  },
  {
    federal_entity_simaf_code: 9,
    federal_entity_description: 'Ciudad de Mexico'
  },
  {
    federal_entity_simaf_code: 10,
    federal_entity_description: 'Durango'
  },
  {
    federal_entity_simaf_code: 11,
    federal_entity_description: 'Guanajuato'
  },
  {
    federal_entity_simaf_code: 12,
    federal_entity_description: 'Guerrero'
  },
  {
    federal_entity_simaf_code: 13,
    federal_entity_description: 'Hidalgo'
  },
  {
    federal_entity_simaf_code: 14,
    federal_entity_description: 'Jalisco'
  },
  {
    federal_entity_simaf_code: 15,
    federal_entity_description: 'Mexico'
  },
  {
    federal_entity_simaf_code: 16,
    federal_entity_description: 'Michoacan de Ocampo'
  },
  {
    federal_entity_simaf_code: 17,
    federal_entity_description: 'Morelos'
  },
  {
    federal_entity_simaf_code: 18,
    federal_entity_description: 'Nayarit'
  },
  {
    federal_entity_simaf_code: 19,
    federal_entity_description: 'Nuevo Leon'
  },
  {
    federal_entity_simaf_code: 20,
    federal_entity_description: 'Oaxaca'
  },
  {
    federal_entity_simaf_code: 21,
    federal_entity_description: 'Puebla'
  },
  {
    federal_entity_simaf_code: 22,
    federal_entity_description: 'Queretaro'
  },
  {
    federal_entity_simaf_code: 23,
    federal_entity_description: 'Quintana Roo'
  },
  {
    federal_entity_simaf_code: 24,
    federal_entity_description: 'San Luis Potosi'
  },
  {
    federal_entity_simaf_code: 25,
    federal_entity_description: 'Sinaloa'
  },
  {
    federal_entity_simaf_code: 26,
    federal_entity_description: 'Sonora'
  },
  {
    federal_entity_simaf_code: 27,
    federal_entity_description: 'Tabasco'
  },
  {
    federal_entity_simaf_code: 28,
    federal_entity_description: 'Tamaulipas'
  },
  {
    federal_entity_simaf_code: 29,
    federal_entity_description: 'Tlaxcala'
  },
  {
    federal_entity_simaf_code: 30,
    federal_entity_description: 'Veracruz de Ignacio de la Llave'
  },
  {
    federal_entity_simaf_code: 31,
    federal_entity_description: 'Yucatan'
  },
  {
    federal_entity_simaf_code: 32,
    federal_entity_description: 'Zacatecas'
  }
];

export const simafCountriesWithoutNullValues = [
  {
    name_en: 'Mexico',
    name_es: 'México',
    flag_emoji: '🇲🇽',
    continent_en: 'North America',
    simaf_code: 260
  },
  {
    name_en: 'United States',
    name_es: 'Estados Unidos',
    flag_emoji: '🇺🇸',
    continent_en: 'North America',
    simaf_code: 221
  },
  {
    name_en: 'Anguilla',
    name_es: 'Anguilla',
    flag_emoji: '🇦🇮',
    continent_en: 'North America',
    simaf_code: 201
  },
  {
    name_en: 'Antigua and Barbuda',
    name_es: 'Antigua y Barbuda',
    flag_emoji: '🇦🇬',
    continent_en: 'North America',
    simaf_code: 202
  },
  {
    name_en: 'Argentina',
    name_es: 'Argentina',
    flag_emoji: '🇦🇷',
    continent_en: 'South America',
    simaf_code: 204
  },
  {
    name_en: 'Aruba',
    name_es: 'Aruba',
    flag_emoji: '🇦🇼',
    continent_en: 'North America',
    simaf_code: 205
  },
  {
    name_en: 'Bahamas',
    name_es: 'Bahamas',
    flag_emoji: '🇧🇸',
    continent_en: 'North America',
    simaf_code: 206
  },
  {
    name_en: 'Barbados',
    name_es: 'Barbados',
    flag_emoji: '🇧🇧',
    continent_en: 'North America',
    simaf_code: 207
  },
  {
    name_en: 'Belize',
    name_es: 'Belice',
    flag_emoji: '🇧🇿',
    continent_en: 'North America',
    simaf_code: 208
  },
  {
    name_en: 'Bermuda',
    name_es: 'Bermudas',
    flag_emoji: '🇧🇲',
    continent_en: 'North America',
    simaf_code: 209
  },
  {
    name_en: 'Bolivia',
    name_es: 'Bolivia',
    flag_emoji: '🇧🇴',
    continent_en: 'South America',
    simaf_code: 210
  },
  {
    name_en: 'Brazil',
    name_es: 'Brasil',
    flag_emoji: '🇧🇷',
    continent_en: 'South America',
    simaf_code: 211
  },
  {
    name_en: 'Canada',
    name_es: 'Canadá',
    flag_emoji: '🇨🇦',
    continent_en: 'North America',
    simaf_code: 213
  },
  {
    name_en: 'Cayman Islands',
    name_es: 'Islas Caimán',
    flag_emoji: '🇰🇾',
    continent_en: 'North America',
    simaf_code: 212
  },
  {
    name_en: 'Chile',
    name_es: 'Chile',
    flag_emoji: '🇨🇱',
    continent_en: 'South America',
    simaf_code: 217
  },
  {
    name_en: 'Colombia',
    name_es: 'Colombia',
    flag_emoji: '🇨🇴',
    continent_en: 'South America',
    simaf_code: 214
  },
  {
    name_en: 'Costa Rica',
    name_es: 'Costa Rica',
    flag_emoji: '🇨🇷',
    continent_en: 'North America',
    simaf_code: 215
  },
  {
    name_en: 'Cuba',
    name_es: 'Cuba',
    flag_emoji: '🇨🇺',
    continent_en: 'North America',
    simaf_code: 216
  },
  {
    name_en: 'Curaçao',
    name_es: 'Curazao',
    flag_emoji: '🇨🇼',
    continent_en: 'North America',
    simaf_code: 251
  },
  {
    name_en: 'Dominica',
    name_es: 'Dominica',
    flag_emoji: '🇩🇲',
    continent_en: 'North America',
    simaf_code: 218
  },
  {
    name_en: 'Dominican Republic',
    name_es: 'República Dominicana',
    flag_emoji: '🇩🇴',
    continent_en: 'North America',
    simaf_code: 239
  },
  {
    name_en: 'Ecuador',
    name_es: 'Ecuador',
    flag_emoji: '🇪🇨',
    continent_en: 'South America',
    simaf_code: 219
  },
  {
    name_en: 'El Salvador',
    name_es: 'El Salvador',
    flag_emoji: '🇸🇻',
    continent_en: 'North America',
    simaf_code: 220
  },
  {
    name_en: 'Falkland Islands (Malvinas)',
    name_es: 'Islas Malvinas',
    flag_emoji: '🇫🇰',
    continent_en: 'South America',
    simaf_code: 231
  },
  {
    name_en: 'French Guiana',
    name_es: 'Guayana Francesa',
    flag_emoji: '🇬🇫',
    continent_en: 'South America',
    simaf_code: 227
  },
  {
    name_en: 'Greenland',
    name_es: 'Groenlandia',
    flag_emoji: '🇬🇱',
    continent_en: 'North America',
    simaf_code: 223
  },
  {
    name_en: 'Grenada',
    name_es: 'Granada',
    flag_emoji: '🇬🇩',
    continent_en: 'North America',
    simaf_code: 222
  },
  {
    name_en: 'Guadeloupe',
    name_es: 'Guadalupe',
    flag_emoji: '🇬🇵',
    continent_en: 'North America',
    simaf_code: 224
  },
  {
    name_en: 'Guatemala',
    name_es: 'Guatemala',
    flag_emoji: '🇬🇹',
    continent_en: 'North America',
    simaf_code: 225
  },
  {
    name_en: 'Guyana',
    name_es: 'Guyana',
    flag_emoji: '🇬🇾',
    continent_en: 'South America',
    simaf_code: 226
  },
  {
    name_en: 'Haiti',
    name_es: 'Haití',
    flag_emoji: '🇭🇹',
    continent_en: 'North America',
    simaf_code: 228
  },
  {
    name_en: 'Honduras',
    name_es: 'Honduras',
    flag_emoji: '🇭🇳',
    continent_en: 'North America',
    simaf_code: 229
  },
  {
    name_en: 'Jamaica',
    name_es: 'Jamaica',
    flag_emoji: '🇯🇲',
    continent_en: 'North America',
    simaf_code: 230
  },
  {
    name_en: 'Montserrat',
    name_es: 'Montserrat',
    flag_emoji: '🇲🇸',
    continent_en: 'North America',
    simaf_code: 233
  },
  {
    name_en: 'Nicaragua',
    name_es: 'Nicaragua',
    flag_emoji: '🇳🇮',
    continent_en: 'North America',
    simaf_code: 234
  },
  {
    name_en: 'Panama',
    name_es: 'Panamá',
    flag_emoji: '🇵🇦',
    continent_en: 'North America',
    simaf_code: 235
  },
  {
    name_en: 'Paraguay',
    name_es: 'Paraguay',
    flag_emoji: '🇵🇾',
    continent_en: 'South America',
    simaf_code: 236
  },
  {
    name_en: 'Peru',
    name_es: 'Perú',
    flag_emoji: '🇵🇪',
    continent_en: 'South America',
    simaf_code: 237
  },
  {
    name_en: 'Puerto Rico',
    name_es: 'Puerto Rico',
    flag_emoji: '🇵🇷',
    continent_en: 'North America',
    simaf_code: 238
  },
  {
    name_en: 'Saint Kitts and Nevis',
    name_es: 'San Cristóbal y Nieves',
    flag_emoji: '🇰🇳',
    continent_en: 'North America',
    simaf_code: 240
  },
  {
    name_en: 'Saint Lucia',
    name_es: 'Santa Lucía',
    flag_emoji: '🇱🇨',
    continent_en: 'North America',
    simaf_code: 243
  },
  {
    name_en: 'Saint Martin',
    name_es: 'San Martín',
    flag_emoji: '🇲🇫',
    continent_en: 'North America',
    simaf_code: 252
  },
  {
    name_en: 'Saint Pierre and Miquelon',
    name_es: 'San Pedro y Miquelón',
    flag_emoji: '🇵🇲',
    continent_en: 'North America',
    simaf_code: 145
  },
  {
    name_en: 'Saint Vincent and the Grenadines',
    name_es: 'San Vicente y las Granadinas',
    flag_emoji: '🇻🇨',
    continent_en: 'North America',
    simaf_code: 242
  },
  {
    name_en: 'Suriname',
    name_es: 'Surinam',
    flag_emoji: '🇸🇷',
    continent_en: 'South America',
    simaf_code: 244
  },
  {
    name_en: 'Trinidad and Tobago',
    name_es: 'Trinidad y Tobago',
    flag_emoji: '🇹🇹',
    continent_en: 'North America',
    simaf_code: 245
  },
  {
    name_en: 'Turks and Caicos Islands',
    name_es: 'Islas Turcas y Caicos',
    flag_emoji: '🇹🇨',
    continent_en: 'North America',
    simaf_code: 246
  },
  {
    name_en: 'Uruguay',
    name_es: 'Uruguay',
    flag_emoji: '🇺🇾',
    continent_en: 'South America',
    simaf_code: 247
  },
  {
    name_en: 'Venezuela',
    name_es: 'Venezuela',
    flag_emoji: '🇻🇪',
    continent_en: 'South America',
    simaf_code: 250
  },
  {
    name_en: 'Virgin Islands, British',
    name_es: 'Islas Vírgenes Británicas',
    flag_emoji: '🇻🇬',
    continent_en: 'North America',
    simaf_code: 249
  },
  {
    name_en: 'Virgin Islands, U.S.',
    name_es: 'Islas Vírgenes de los Estados Unidos',
    flag_emoji: '🇻🇮',
    continent_en: 'North America',
    simaf_code: 248
  },
  {
    name_en: 'Afghanistan',
    name_es: 'Afganistán',
    flag_emoji: '🇦🇫',
    continent_en: 'Africa',
    simaf_code: 301
  },
  {
    name_en: 'Albania',
    name_es: 'Albania',
    flag_emoji: '🇦🇱',
    continent_en: 'Europe',
    simaf_code: 401
  },
  {
    name_en: 'Algeria',
    name_es: 'Argelia',
    flag_emoji: '🇩🇿',
    continent_en: 'Africa',
    simaf_code: 102
  },
  {
    name_en: 'American Samoa',
    name_es: 'Samoa Americana',
    flag_emoji: '🇦🇸',
    continent_en: 'Oceania',
    simaf_code: 528
  },
  {
    name_en: 'Andorra',
    name_es: 'Andorra',
    flag_emoji: '🇦🇩',
    continent_en: 'Europe',
    simaf_code: 403
  },
  {
    name_en: 'Angola',
    name_es: 'Angola',
    flag_emoji: '🇦🇴',
    continent_en: 'Africa',
    simaf_code: 101
  },
  {
    name_en: 'Armenia',
    name_es: 'Armenia',
    flag_emoji: '🇦🇲',
    continent_en: 'Asia',
    simaf_code: 303
  },
  {
    name_en: 'Australia',
    name_es: 'Australia',
    flag_emoji: '🇦🇺',
    continent_en: 'Oceania',
    simaf_code: 501
  },
  {
    name_en: 'Austria',
    name_es: 'Austria',
    flag_emoji: '🇦🇹',
    continent_en: 'Europe',
    simaf_code: 405
  },
  {
    name_en: 'Azerbaijan',
    name_es: 'Azerbaiyán',
    flag_emoji: '🇦🇿',
    continent_en: 'Asia',
    simaf_code: 304
  },
  {
    name_en: 'Bahrain',
    name_es: 'Baréin',
    flag_emoji: '🇧🇭',
    continent_en: 'Asia',
    simaf_code: 305
  },
  {
    name_en: 'Bangladesh',
    name_es: 'Bangladés',
    flag_emoji: '🇧🇩',
    continent_en: 'Asia',
    simaf_code: 306
  },
  {
    name_en: 'Belarus',
    name_es: 'Bielorrusia',
    flag_emoji: '🇧🇾',
    continent_en: 'Europe',
    simaf_code: 407
  },
  {
    name_en: 'Belgium',
    name_es: 'Bélgica',
    flag_emoji: '🇧🇪',
    continent_en: 'Europe',
    simaf_code: 408
  },
  {
    name_en: 'Benin',
    name_es: 'Benin',
    flag_emoji: '🇧🇯',
    continent_en: 'Africa',
    simaf_code: 104
  },
  {
    name_en: 'Bhutan',
    name_es: 'Bután',
    flag_emoji: '🇧🇹',
    continent_en: 'Asia',
    simaf_code: 307
  },
  {
    name_en: 'Bosnia and Herzegovina',
    name_es: 'Bosnia y Herzegovina',
    flag_emoji: '🇧🇦',
    continent_en: 'Europe',
    simaf_code: 409
  },
  {
    name_en: 'Botswana',
    name_es: 'Botsuana',
    flag_emoji: '🇧🇼',
    continent_en: 'Africa',
    simaf_code: 105
  },
  {
    name_en: 'British Indian Ocean Territory',
    name_es: 'Territorio Británico del Océano Índico',
    flag_emoji: '🇮🇴',
    continent_en: 'Asia',
    simaf_code: 325
  },
  {
    name_en: 'Brunei Darussalam',
    name_es: 'Brunéi',
    flag_emoji: '🇧🇳',
    continent_en: 'Asia',
    simaf_code: 308
  },
  {
    name_en: 'Bulgaria',
    name_es: 'Bulgaria',
    flag_emoji: '🇧🇬',
    continent_en: 'Europe',
    simaf_code: 410
  },
  {
    name_en: 'Burkina Faso',
    name_es: 'Burkina Faso',
    flag_emoji: '🇧🇫',
    continent_en: 'Africa',
    simaf_code: 106
  },
  {
    name_en: 'Burundi',
    name_es: 'Burundi',
    flag_emoji: '🇧🇮',
    continent_en: 'Africa',
    simaf_code: 107
  },
  {
    name_en: 'Cambodia',
    name_es: 'Camboya',
    flag_emoji: '🇰🇭',
    continent_en: 'Asia',
    simaf_code: 309
  },
  {
    name_en: 'Cameroon',
    name_es: 'Camerún',
    flag_emoji: '🇨🇲',
    continent_en: 'Africa',
    simaf_code: 109
  },
  {
    name_en: 'Cape Verde',
    name_es: 'Cabo Verde',
    flag_emoji: '🇨🇻',
    continent_en: 'Africa',
    simaf_code: 108
  },
  {
    name_en: 'Central African Republic',
    name_es: 'República Centroafricana',
    flag_emoji: '🇨🇫',
    continent_en: 'Africa',
    simaf_code: 143
  },
  {
    name_en: 'Chad',
    name_es: 'Chad',
    flag_emoji: '🇹🇩',
    continent_en: 'Africa',
    simaf_code: 115
  },
  {
    name_en: 'China',
    name_es: 'China',
    flag_emoji: '🇨🇳',
    continent_en: 'Asia',
    simaf_code: 315
  },
  {
    name_en: 'Comoros',
    name_es: 'Comoras',
    flag_emoji: '🇰🇲',
    continent_en: 'Africa',
    simaf_code: 152
  },
  {
    name_en: 'Congo',
    name_es: 'Congo',
    flag_emoji: '🇨🇬',
    continent_en: 'Africa',
    simaf_code: 113
  },
  {
    name_en: 'Cook Islands',
    name_es: 'Islas Cook',
    flag_emoji: '🇨🇰',
    continent_en: 'Oceania',
    simaf_code: 503
  },
  {
    name_en: "Côte d'Ivoire",
    name_es: 'Costa de Marfil',
    flag_emoji: '🇨🇮',
    continent_en: 'Africa',
    simaf_code: 114
  },
  {
    name_en: 'Croatia',
    name_es: 'Croacia',
    flag_emoji: '🇭🇷',
    continent_en: 'Europe',
    simaf_code: 411
  },
  {
    name_en: 'Cyprus',
    name_es: 'Chipre',
    flag_emoji: '🇨🇾',
    continent_en: 'Europe',
    simaf_code: 318
  },
  {
    name_en: 'Czechia',
    name_es: 'Chequia',
    flag_emoji: '🇨🇿',
    continent_en: 'Europe',
    simaf_code: 440
  },
  {
    name_en: 'Denmark',
    name_es: 'Dinamarca',
    flag_emoji: '🇩🇰',
    continent_en: 'Europe',
    simaf_code: 412
  },
  {
    name_en: 'Djibouti',
    name_es: 'Yibuti',
    flag_emoji: '🇩🇯',
    continent_en: 'Africa',
    simaf_code: 116
  },
  {
    name_en: 'Egypt',
    name_es: 'Egipto',
    flag_emoji: '🇪🇬',
    continent_en: 'Africa',
    simaf_code: 117
  },
  {
    name_en: 'Equatorial Guinea',
    name_es: 'Guinea Ecuatorial',
    flag_emoji: '🇬🇶',
    continent_en: 'Africa',
    simaf_code: 125
  },
  {
    name_en: 'Eritrea',
    name_es: 'Eritrea',
    flag_emoji: '🇪🇷',
    continent_en: 'Africa',
    simaf_code: 118
  },
  {
    name_en: 'Estonia',
    name_es: 'Estonia',
    flag_emoji: '🇪🇪',
    continent_en: 'Europe',
    simaf_code: 416
  },
  {
    name_en: 'Eswatini',
    name_es: 'Suazilandia',
    flag_emoji: '🇸🇿',
    continent_en: 'Africa',
    simaf_code: 160
  },
  {
    name_en: 'Ethiopia',
    name_es: 'Etiopía',
    flag_emoji: '🇪🇹',
    continent_en: 'Africa',
    simaf_code: 119
  },
  {
    name_en: 'Faroe Islands',
    name_es: 'Islas Feroe',
    flag_emoji: '🇫🇴',
    continent_en: 'Europe',
    simaf_code: 417
  },
  {
    name_en: 'Fiji',
    name_es: 'Fiyi',
    flag_emoji: '🇫🇯',
    continent_en: 'Oceania',
    simaf_code: 535
  },
  {
    name_en: 'Finland',
    name_es: 'Finlandia',
    flag_emoji: '🇫🇮',
    continent_en: 'Europe',
    simaf_code: 418
  },
  {
    name_en: 'France',
    name_es: 'Francia',
    flag_emoji: '🇫🇷',
    continent_en: 'Europe',
    simaf_code: 419
  },
  {
    name_en: 'Gabon',
    name_es: 'Gabón',
    flag_emoji: '🇬🇦',
    continent_en: 'Africa',
    simaf_code: 120
  },
  {
    name_en: 'Gambia',
    name_es: 'Gambia',
    flag_emoji: '🇬🇲',
    continent_en: 'Africa',
    simaf_code: 121
  },
  {
    name_en: 'Georgia',
    name_es: 'Georgia',
    flag_emoji: '🇬🇪',
    continent_en: 'Asia',
    simaf_code: 323
  },
  {
    name_en: 'Germany',
    name_es: 'Alemania',
    flag_emoji: '🇩🇪',
    continent_en: 'Europe',
    simaf_code: 402
  },
  {
    name_en: 'Ghana',
    name_es: 'Ghana',
    flag_emoji: '🇬🇭',
    continent_en: 'Africa',
    simaf_code: 122
  },
  {
    name_en: 'Gibraltar',
    name_es: 'Gibraltar',
    flag_emoji: '🇬🇮',
    continent_en: 'Europe',
    simaf_code: 420
  },
  {
    name_en: 'Greece',
    name_es: 'Grecia',
    flag_emoji: '🇬🇷',
    continent_en: 'Europe',
    simaf_code: 421
  },
  {
    name_en: 'Guam',
    name_es: 'Guam',
    flag_emoji: '🇬🇺',
    continent_en: 'Oceania',
    simaf_code: 505
  },
  {
    name_en: 'Guinea',
    name_es: 'Guinea',
    flag_emoji: '🇬🇳',
    continent_en: 'Africa',
    simaf_code: 123
  },
  {
    name_en: 'Guinea-Bissau',
    name_es: 'Guinea-Bissau',
    flag_emoji: '🇬🇼',
    continent_en: 'Africa',
    simaf_code: 124
  },
  {
    name_en: 'Hungary',
    name_es: 'Hungría',
    flag_emoji: '🇭🇺',
    continent_en: 'Europe',
    simaf_code: 422
  },
  {
    name_en: 'Iceland',
    name_es: 'Islandia',
    flag_emoji: '🇮🇸',
    continent_en: 'Europe',
    simaf_code: 424
  },
  {
    name_en: 'India',
    name_es: 'India',
    flag_emoji: '🇮🇳',
    continent_en: 'Asia',
    simaf_code: 325
  },
  {
    name_en: 'Indonesia',
    name_es: 'Indonesia',
    flag_emoji: '🇮🇩',
    continent_en: 'Asia',
    simaf_code: 326
  },
  {
    name_en: 'Iran',
    name_es: 'Irán',
    flag_emoji: '🇮🇷',
    continent_en: 'Asia',
    simaf_code: 327
  },
  {
    name_en: 'Iraq',
    name_es: 'Irak',
    flag_emoji: '🇮🇶',
    continent_en: 'Asia',
    simaf_code: 328
  },
  {
    name_en: 'Ireland',
    name_es: 'Irlanda',
    flag_emoji: '🇮🇪',
    continent_en: 'Europe',
    simaf_code: 423
  },
  {
    name_en: 'Isle of Man',
    name_es: 'Isla de Man',
    flag_emoji: '🇮🇲',
    continent_en: 'Europe',
    simaf_code: 241
  },
  {
    name_en: 'Israel',
    name_es: 'Israel',
    flag_emoji: '🇮🇱',
    continent_en: 'Asia',
    simaf_code: 329
  },
  {
    name_en: 'Italy',
    name_es: 'Italia',
    flag_emoji: '🇮🇹',
    continent_en: 'Europe',
    simaf_code: 425
  },
  {
    name_en: 'Japan',
    name_es: 'Japón',
    flag_emoji: '🇯🇵',
    continent_en: 'Asia',
    simaf_code: 330
  },
  {
    name_en: 'Jordan',
    name_es: 'Jordania',
    flag_emoji: '🇯🇴',
    continent_en: 'Asia',
    simaf_code: 331
  },
  {
    name_en: 'Kazakhstan',
    name_es: 'Kazajistán',
    flag_emoji: '🇰🇿',
    continent_en: 'Asia',
    simaf_code: 332
  },
  {
    name_en: 'Kenya',
    name_es: 'Kenia',
    flag_emoji: '🇰🇪',
    continent_en: 'Africa',
    simaf_code: 126
  },
  {
    name_en: 'Kiribati',
    name_es: 'Kiribati',
    flag_emoji: '🇰🇮',
    continent_en: 'Oceania',
    simaf_code: 507
  },
  {
    name_en: 'Kuwait',
    name_es: 'Kuwait',
    flag_emoji: '🇰🇼',
    continent_en: 'Asia',
    simaf_code: 334
  },
  {
    name_en: 'Kyrgyzstan',
    name_es: 'Kirguistán',
    flag_emoji: '🇰🇬',
    continent_en: 'Asia',
    simaf_code: 333
  },
  {
    name_en: 'Laos',
    name_es: 'Laos',
    flag_emoji: '🇱🇦',
    continent_en: 'Asia',
    simaf_code: 345
  },
  {
    name_en: 'Latvia',
    name_es: 'Letonia',
    flag_emoji: '🇱🇻',
    continent_en: 'Europe',
    simaf_code: 426
  },
  {
    name_en: 'Lebanon',
    name_es: 'Líbano',
    flag_emoji: '🇱🇧',
    continent_en: 'Asia',
    simaf_code: 335
  },
  {
    name_en: 'Lesotho',
    name_es: 'Lesoto',
    flag_emoji: '🇱🇸',
    continent_en: 'Africa',
    simaf_code: 127
  },
  {
    name_en: 'Liberia',
    name_es: 'Liberia',
    flag_emoji: '🇱🇷',
    continent_en: 'Africa',
    simaf_code: 128
  },
  {
    name_en: 'Libya',
    name_es: 'Libia',
    flag_emoji: '🇱🇾',
    continent_en: 'Africa',
    simaf_code: 129
  },
  {
    name_en: 'Liechtenstein',
    name_es: 'Liechtenstein',
    flag_emoji: '🇱🇮',
    continent_en: 'Europe',
    simaf_code: 427
  },
  {
    name_en: 'Lithuania',
    name_es: 'Lituania',
    flag_emoji: '🇱🇹',
    continent_en: 'Europe',
    simaf_code: 428
  },
  {
    name_en: 'Luxembourg',
    name_es: 'Luxemburgo',
    flag_emoji: '🇱🇺',
    continent_en: 'Europe',
    simaf_code: 429
  },
  {
    name_en: 'Madagascar',
    name_es: 'Madagascar',
    flag_emoji: '🇲🇬',
    continent_en: 'Africa',
    simaf_code: 130
  },
  {
    name_en: 'Malawi',
    name_es: 'Malawi',
    flag_emoji: '🇲🇼',
    continent_en: 'Africa',
    simaf_code: 133
  },
  {
    name_en: 'Malaysia',
    name_es: 'Malasia',
    flag_emoji: '🇲🇾',
    continent_en: 'Asia',
    simaf_code: 337
  },
  {
    name_en: 'Maldives',
    name_es: 'Maldivas',
    flag_emoji: '🇲🇻',
    continent_en: 'Asia',
    simaf_code: 338
  },
  {
    name_en: 'Mali',
    name_es: 'Mali',
    flag_emoji: '🇲🇱',
    continent_en: 'Africa',
    simaf_code: 134
  },
  {
    name_en: 'Malta',
    name_es: 'Malta',
    flag_emoji: '🇲🇹',
    continent_en: 'Europe',
    simaf_code: 431
  },
  {
    name_en: 'Marshall Islands',
    name_es: 'Islas Marshall',
    flag_emoji: '🇲🇭',
    continent_en: 'Oceania',
    simaf_code: 513
  },
  {
    name_en: 'Mauritania',
    name_es: 'Mauritania',
    flag_emoji: '🇲🇷',
    continent_en: 'Africa',
    simaf_code: 137
  },
  {
    name_en: 'Mauritius',
    name_es: 'Mauricio',
    flag_emoji: '🇲🇺',
    continent_en: 'Africa',
    simaf_code: 136
  },
  {
    name_en: 'Micronesia',
    name_es: 'Micronesia',
    flag_emoji: '🇫🇲',
    continent_en: 'Oceania',
    simaf_code: 514
  },
  {
    name_en: 'Moldova',
    name_es: 'Moldavia',
    flag_emoji: '🇲🇩',
    continent_en: 'Europe',
    simaf_code: 433
  },
  {
    name_en: 'Monaco',
    name_es: 'Mónaco',
    flag_emoji: '🇲🇨',
    continent_en: 'Europe',
    simaf_code: 434
  },
  {
    name_en: 'Mongolia',
    name_es: 'Mongolia',
    flag_emoji: '🇲🇳',
    continent_en: 'Asia',
    simaf_code: 339
  },
  {
    name_en: 'Montenegro',
    name_es: 'Montenegro',
    flag_emoji: '🇲🇪',
    continent_en: 'Europe',
    simaf_code: 453
  },
  {
    name_en: 'Morocco',
    name_es: 'Marruecos',
    flag_emoji: '🇲🇦',
    continent_en: 'Africa',
    simaf_code: 135
  },
  {
    name_en: 'Mozambique',
    name_es: 'Mozambique',
    flag_emoji: '🇲🇿',
    continent_en: 'Africa',
    simaf_code: 139
  },
  {
    name_en: 'Myanmar',
    name_es: 'Myanmar',
    flag_emoji: '🇲🇲',
    continent_en: 'Asia',
    simaf_code: 340
  },
  {
    name_en: 'Namibia',
    name_es: 'Namibia',
    flag_emoji: '🇳🇦',
    continent_en: 'Africa',
    simaf_code: 140
  },
  {
    name_en: 'Nauru',
    name_es: 'Nauru',
    flag_emoji: '🇳🇷',
    continent_en: 'Oceania',
    simaf_code: 516
  },
  {
    name_en: 'Nepal',
    name_es: 'Nepal',
    flag_emoji: '🇳🇵',
    continent_en: 'Asia',
    simaf_code: 341
  },
  {
    name_en: 'Netherlands',
    name_es: 'Países Bajos',
    flag_emoji: '🇳🇱',
    continent_en: 'Europe',
    simaf_code: 436
  },
  {
    name_en: 'New Zealand',
    name_es: 'Nueva Zelanda',
    flag_emoji: '🇳🇿',
    continent_en: 'Oceania',
    simaf_code: 520
  },
  {
    name_en: 'Niger',
    name_es: 'Níger',
    flag_emoji: '🇳🇪',
    continent_en: 'Africa',
    simaf_code: 141
  },
  {
    name_en: 'Nigeria',
    name_es: 'Nigeria',
    flag_emoji: '🇳🇬',
    continent_en: 'Africa',
    simaf_code: 142
  },
  {
    name_en: 'Niue',
    name_es: 'Niue',
    flag_emoji: '🇳🇺',
    continent_en: 'Oceania',
    simaf_code: 517
  },
  {
    name_en: 'North Korea',
    name_es: 'Corea del Norte',
    flag_emoji: '🇰🇵',
    continent_en: 'Asia',
    simaf_code: 312
  },
  {
    name_en: 'North Macedonia',
    name_es: 'Macedonia del Norte',
    flag_emoji: '🇲🇰',
    continent_en: 'Europe',
    simaf_code: 430
  },
  {
    name_en: 'Northern Mariana Islands',
    name_es: 'Islas Marianas del Norte',
    flag_emoji: '🇲🇵',
    continent_en: 'Oceania',
    simaf_code: 512
  },
  {
    name_en: 'Norway',
    name_es: 'Noruega',
    flag_emoji: '🇳🇴',
    continent_en: 'Europe',
    simaf_code: 435
  },
  {
    name_en: 'Oman',
    name_es: 'Omán',
    flag_emoji: '🇴🇲',
    continent_en: 'Asia',
    simaf_code: 342
  },
  {
    name_en: 'Pakistan',
    name_es: 'Pakistán',
    flag_emoji: '🇵🇰',
    continent_en: 'Asia',
    simaf_code: 343
  },
  {
    name_en: 'Palau',
    name_es: 'Palaos',
    flag_emoji: '🇵🇼',
    continent_en: 'Oceania',
    simaf_code: 356
  },
  {
    name_en: 'Palestine',
    name_es: 'Palestina',
    flag_emoji: '🇵🇸',
    continent_en: 'Asia',
    simaf_code: 358
  },
  {
    name_en: 'Papua New Guinea',
    name_es: 'Papúa Nueva Guinea',
    flag_emoji: '🇵🇬',
    continent_en: 'Oceania',
    simaf_code: 523
  },
  {
    name_en: 'Philippines',
    name_es: 'Filipinas',
    flag_emoji: '🇵🇭',
    continent_en: 'Asia',
    simaf_code: 322
  },
  {
    name_en: 'Pitcairn',
    name_es: 'Islas Pitcairn',
    flag_emoji: '🇵🇳',
    continent_en: 'Oceania',
    simaf_code: 524
  },
  {
    name_en: 'Poland',
    name_es: 'Polonia',
    flag_emoji: '🇵🇱',
    continent_en: 'Europe',
    simaf_code: 437
  },
  {
    name_en: 'Portugal',
    name_es: 'Portugal',
    flag_emoji: '🇵🇹',
    continent_en: 'Europe',
    simaf_code: 438
  },
  {
    name_en: 'Qatar',
    name_es: 'Catar',
    flag_emoji: '🇶🇦',
    continent_en: 'Asia',
    simaf_code: 344
  },
  {
    name_en: 'Romania',
    name_es: 'Rumania',
    flag_emoji: '🇷🇴',
    continent_en: 'Europe',
    simaf_code: 441
  },
  {
    name_en: 'Russia',
    name_es: 'Rusia',
    flag_emoji: '🇷🇺',
    continent_en: 'Europe',
    simaf_code: 442
  },
  {
    name_en: 'Rwanda',
    name_es: 'Ruanda',
    flag_emoji: '🇷🇼',
    continent_en: 'Africa',
    simaf_code: 112
  },
  {
    name_en: 'Saint Helena',
    name_es: 'Santa Elena',
    flag_emoji: '🇸🇭',
    continent_en: 'Africa',
    simaf_code: 147
  },
  {
    name_en: 'Samoa',
    name_es: 'Samoa',
    flag_emoji: '🇼🇸',
    continent_en: 'Oceania',
    simaf_code: 527
  },
  {
    name_en: 'San Marino',
    name_es: 'San Marino',
    flag_emoji: '🇸🇲',
    continent_en: 'Europe',
    simaf_code: 443
  },
  {
    name_en: 'Sao Tome and Principe',
    name_es: 'Santo Tomé y Príncipe',
    flag_emoji: '🇸🇹',
    continent_en: 'Africa',
    simaf_code: 148
  },
  {
    name_en: 'Saudi Arabia',
    name_es: 'Arabia Saudita',
    flag_emoji: '🇸🇦',
    continent_en: 'Asia',
    simaf_code: 302
  },
  {
    name_en: 'Senegal',
    name_es: 'Senegal',
    flag_emoji: '🇸🇳',
    continent_en: 'Africa',
    simaf_code: 149
  },
  {
    name_en: 'Serbia',
    name_es: 'Serbia',
    flag_emoji: '🇷🇸',
    continent_en: 'Europe',
    simaf_code: 452
  },
  {
    name_en: 'Seychelles',
    name_es: 'Seychelles',
    flag_emoji: '🇸🇨',
    continent_en: 'Africa',
    simaf_code: 150
  },
  {
    name_en: 'Sierra Leone',
    name_es: 'Sierra Leona',
    flag_emoji: '🇸🇱',
    continent_en: 'Africa',
    simaf_code: 151
  },
  {
    name_en: 'Singapore',
    name_es: 'Singapur',
    flag_emoji: '🇸🇬',
    continent_en: 'Asia',
    simaf_code: 346
  },
  {
    name_en: 'Slovakia',
    name_es: 'Eslovaquia',
    flag_emoji: '🇸🇰',
    continent_en: 'Europe',
    simaf_code: 413
  },
  {
    name_en: 'Slovenia',
    name_es: 'Eslovenia',
    flag_emoji: '🇸🇮',
    continent_en: 'Europe',
    simaf_code: 414
  },
  {
    name_en: 'Solomon Islands',
    name_es: 'Islas Salomón',
    flag_emoji: '🇸🇧',
    continent_en: 'Oceania',
    simaf_code: 526
  },
  {
    name_en: 'Somalia',
    name_es: 'Somalia',
    flag_emoji: '🇸🇴',
    continent_en: 'Africa',
    simaf_code: 153
  },
  {
    name_en: 'South Africa',
    name_es: 'Sudáfrica',
    flag_emoji: '🇿🇦',
    continent_en: 'Africa',
    simaf_code: 154
  },
  {
    name_en: 'South Georgia and the South Sandwich Islands',
    name_es: 'Islas Georgias del Sur y Sandwich del Sur',
    flag_emoji: '🇬🇸',
    continent_en: 'Antarctica',
    simaf_code: 323
  },
  {
    name_en: 'South Korea',
    name_es: 'Corea del Sur',
    flag_emoji: '🇰🇷',
    continent_en: 'Asia',
    simaf_code: 313
  },
  {
    name_en: 'South Sudan',
    name_es: 'Sudán del Sur',
    flag_emoji: '🇸🇸',
    continent_en: 'Africa',
    simaf_code: 172
  },
  {
    name_en: 'Spain',
    name_es: 'España',
    flag_emoji: '🇪🇸',
    continent_en: 'Europe',
    simaf_code: 415
  },
  {
    name_en: 'Sri Lanka',
    name_es: 'Sri Lanka',
    flag_emoji: '🇱🇰',
    continent_en: 'Asia',
    simaf_code: 348
  },
  {
    name_en: 'Sudan',
    name_es: 'Sudán',
    flag_emoji: '🇸🇩',
    continent_en: 'Africa',
    simaf_code: 171
  },
  {
    name_en: 'Sweden',
    name_es: 'Suecia',
    flag_emoji: '🇸🇪',
    continent_en: 'Europe',
    simaf_code: 445
  },
  {
    name_en: 'Switzerland',
    name_es: 'Suiza',
    flag_emoji: '🇨🇭',
    continent_en: 'Europe',
    simaf_code: 446
  },
  {
    name_en: 'Syria',
    name_es: 'Siria',
    flag_emoji: '🇸🇾',
    continent_en: 'Asia',
    simaf_code: 347
  },
  {
    name_en: 'Taiwan',
    name_es: 'Taiwán',
    flag_emoji: '🇹🇼',
    continent_en: 'Asia',
    simaf_code: 316
  },
  {
    name_en: 'Tajikistan',
    name_es: 'Tayikistán',
    flag_emoji: '🇹🇯',
    continent_en: 'Asia',
    simaf_code: 350
  },
  {
    name_en: 'Tanzania',
    name_es: 'Tanzania',
    flag_emoji: '🇹🇿',
    continent_en: 'Africa',
    simaf_code: 161
  },
  {
    name_en: 'Thailand',
    name_es: 'Tailandia',
    flag_emoji: '🇹🇭',
    continent_en: 'Asia',
    simaf_code: 349
  },
  {
    name_en: 'Togo',
    name_es: 'Togo',
    flag_emoji: '🇹🇬',
    continent_en: 'Africa',
    simaf_code: 162
  },
  {
    name_en: 'Tokelau',
    name_es: 'Tokelau',
    flag_emoji: '🇹🇰',
    continent_en: 'Oceania',
    simaf_code: 530
  },
  {
    name_en: 'Tonga',
    name_es: 'Tonga',
    flag_emoji: '🇹🇴',
    continent_en: 'Oceania',
    simaf_code: 531
  },
  {
    name_en: 'Tristan da Cunha',
    name_es: 'Tristán de Acuña',
    flag_emoji: '🇹🇦',
    continent_en: 'Africa',
    simaf_code: 357
  },
  {
    name_en: 'Tunisia',
    name_es: 'Túnez',
    flag_emoji: '🇹🇳',
    continent_en: 'Africa',
    simaf_code: 164
  },
  {
    name_en: 'Türkiye',
    name_es: 'Turquía',
    flag_emoji: '🇹🇷',
    continent_en: 'Asia',
    simaf_code: 352
  },
  {
    name_en: 'Turkmenistan',
    name_es: 'Turkmenistán',
    flag_emoji: '🇹🇲',
    continent_en: 'Asia',
    simaf_code: 351
  },
  {
    name_en: 'Tuvalu',
    name_es: 'Tuvalu',
    flag_emoji: '🇹🇻',
    continent_en: 'Oceania',
    simaf_code: 532
  },
  {
    name_en: 'Uganda',
    name_es: 'Uganda',
    flag_emoji: '🇺🇬',
    continent_en: 'Africa',
    simaf_code: 165
  },
  {
    name_en: 'Ukraine',
    name_es: 'Ucrania',
    flag_emoji: '🇺🇦',
    continent_en: 'Europe',
    simaf_code: 447
  },
  {
    name_en: 'United Arab Emirates',
    name_es: 'Emiratos Árabes Unidos',
    flag_emoji: '🇦🇪',
    continent_en: 'Asia',
    simaf_code: 321
  },
  {
    name_en: 'United Kingdom',
    name_es: 'Reino Unido',
    flag_emoji: '🇬🇧',
    continent_en: 'Europe',
    simaf_code: 439
  },
  {
    name_en: 'Uzbekistan',
    name_es: 'Uzbekistán',
    flag_emoji: '🇺🇿',
    continent_en: 'Asia',
    simaf_code: 353
  },
  {
    name_en: 'Vanuatu',
    name_es: 'Vanuatu',
    flag_emoji: '🇻🇺',
    continent_en: 'Oceania',
    simaf_code: 533
  },
  {
    name_en: 'Vatican City State',
    name_es: 'Ciudad del Vaticano',
    flag_emoji: '🇻🇦',
    continent_en: 'Europe',
    simaf_code: 444
  },
  {
    name_en: 'Vietnam',
    name_es: 'Vietnam',
    flag_emoji: '🇻🇳',
    continent_en: 'Asia',
    simaf_code: 354
  },
  {
    name_en: 'Wallis and Futuna',
    name_es: 'Wallis y Futuna',
    flag_emoji: '🇼🇫',
    continent_en: 'Oceania',
    simaf_code: 534
  },
  {
    name_en: 'Western Sahara',
    name_es: 'Sahara Occidental',
    flag_emoji: '🇪🇭',
    continent_en: 'Africa',
    simaf_code: 170
  },
  {
    name_en: 'Yemen',
    name_es: 'Yemen',
    flag_emoji: '🇾🇪',
    continent_en: 'Asia',
    simaf_code: 355
  },
  {
    name_en: 'Zambia',
    name_es: 'Zambia',
    flag_emoji: '🇿🇲',
    continent_en: 'Africa',
    simaf_code: 167
  },
  {
    name_en: 'Zimbabwe',
    name_es: 'Zimbabue',
    flag_emoji: '🇿🇼',
    continent_en: 'Africa',
    simaf_code: 168
  }
];
