import { setAppError } from 'src/store/app/app.slice';
import { AppDispatch } from '../../store/store';
import { openErrorModal } from 'src/store/User/user.slice';

interface ErrorObject {
  code: string;
  message?: string;
}

type ErrorHandlerFunction = (
  dispatch: AppDispatch,
  error: ErrorObject,
  // eslint-disable-next-line @typescript-eslint/ban-types
  aditionalFunction?: Function | null,
  dataNeeded?: unknown
) => Promise<void> | void;

// NOTE: This is a legacy errorHandler, the new one is using a straitghforward approach through the setAppError action.
export const errorHandler: ErrorHandlerFunction = async (
  dispatch,
  error,
  aditionalFunction = null,
  dataNeeded = null
) => {
  const getErrorTypeFromError = (error: ErrorObject) => {
    switch (error.code) {
      //Signup section
      case 'passwordsNotEqual':
        return {
          type: 'passwordsNotEqual',
          passwordsNotEqual: true
        };
      case 'acceptTerms':
        return {
          type: 'acceptTerms',
          acceptTerms: true
        };
      case 'allDataRequired':
        return {
          type: 'allDataRequired',
          allDataRequired: true
        };
      case 'incorrectPhoneCode':
        return {
          type: 'incorrectPhoneCode',
          incorrectPhoneCode: true
        };
      // User has not confirmed code.
      case 'UserNotConfirmedException':
        if (typeof aditionalFunction === 'function') {
          aditionalFunction(dataNeeded);
        }
        return {
          type: 'codeConfirmation',
          userNotConfirmed: true
        };
      case 'An account with the given email already exists.':
        return {
          type: 'UsernameExistsException',
          usernameExists: true
        };
      //CHECKBOX DOESN'T CHECKED
      case 'checkboxNotChecked':
        return {
          type: 'checkboxNotChecked',
          checkboxNotChecked: true
        };
      // User is not registered yet.
      case 'UserNotFoundException':
        return {
          type: 'userDoesNotExists',
          userDoesNotExists: true
        };
      // User password expired
      case 'PasswordResetRequiredException':
        return {
          type: 'passwordResetRequired',
          passwordResetRequired: true
        };
      // Credentials
      case 'NotAuthorizedException':
        switch (error.message) {
          case 'User is disabled.':
            return {
              type: 'disabledUser',
              disabledUser: true
            };
          default:
            return false;
        }
      // TOO MANY ATTEMPTS
      case 'LimitExceededException':
        return {
          type: 'tooManyAttempts',
          tooManyAttempts: true
        };
      //USER ALREADY EXISTS
      case 'UserAlreadyExists':
        return {
          type: 'userAlreadyexists',
          userAlreadyexists: true
        };
      // Code provided is not correct
      case 'CodeMismatchException':
        return {
          type: 'codeMismatch',
          codeMismatch: true
        };
      // Confirmation code is empty
      case 'Confirmation code cannot be empty':
        return {
          type: 'emptyCode',
          emptyCode: true
        };
      // User is already confirmed
      case 'User is already confirmed.':
        return {
          type: 'userNotConfirmed',
          userNotConfirmed: true
        };
      // User is already confirmed
      case 'wrongConfirmPass':
        return {
          type: 'wrongConfirmPass',
          wrongConfirmPass: true
        };
      // User is already confirmed
      case 'Invalid verification code provided, please try again.':
        return {
          type: 'codeIncorrect',
          codeIncorrect: true
        };
      case 'onboardingError':
        return {
          type: 'onboardingError',
          onboardingError: true
        };
      default:
        return false;
    }
  };

  if (getErrorTypeFromError(error) === false) {
    dispatch(
      setAppError({
        errorCode: error.message || 'Generic error',
        severity: 'error'
      })
    );
    return;
  }

  const errorTypeData = getErrorTypeFromError(error);
  const action = openErrorModal(errorTypeData);
  if (typeof action === 'function') {
    // (returns a promise)
    try {
      await dispatch(action);
    } catch (err) {
      console.error('Error in errorHandler:', err);
    }
  } else {
    // plain object action
    dispatch(action);
  }
};
