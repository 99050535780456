import { FooterContainer } from './styles';
import {
  LinkedinLogo,
  YoutubeLogo,
  XLogo,
  SpotifyLogo,
  FacebookLogo,
  InstagramLogo
} from 'src/assets/icons/socialMedia';
import { buro, cnbv, condusef } from 'src/assets/icons/compliance';
import logoFinsphera from 'src/assets/icons/brand/logoWhite.svg';
import { Link } from 'react-router-dom';
import { forwardRef } from 'react';
import { FooterProps } from './types';

// TODO: Define the Links direction and check if there is a valid route
// TODO: Add the correct new Finsphera Logo when the UI team provides it

export const Footer = forwardRef<HTMLElement, FooterProps>((props, ref) => {
  return (
    <FooterContainer ref={ref} {...props}>
      <div className="footer-content">
        <div className="section">
          <h4 className="heading">Conócenos</h4>
          <ul className="list">
            <li className="list-item">
              <Link to="#">Contáctanos</Link>
            </li>
            <li className="list-item">
              <Link to="#">ESG y sostenibilidad</Link>
            </li>
            <li className="list-item">
              <Link to="#">Relación con accionistas</Link>
            </li>
            <li className="list-item">
              <Link to="#">Seguridad</Link>
            </li>
            <li className="list-item">
              <Link to="#">Medios</Link>
            </li>
          </ul>
        </div>

        <div className="section">
          <h4 className="heading">Recursos</h4>
          <ul className="list">
            <li className="list-item">
              <Link to="#">Educación</Link>
            </li>
            <li className="list-item">
              <Link to="#">Preguntas frecuentes</Link>
            </li>
            <li className="list-item">
              <Link to="#">Financiamiento</Link>
            </li>
            <li className="list-item">
              <Link to="#">Rendimientos</Link>
            </li>
            <li className="list-item">
              <Link to="/buzon-anonimo" className="underline">
                Buzón de Denuncias - Buzón Anónimo
              </Link>
            </li>
            <li className="list-item">
              <Link to="/quejas-cobranza">
                ¿Quieres generar una queja de los despachos de cobranza de FinSphera? Da click aquí
              </Link>
            </li>
          </ul>
        </div>

        <div className="section">
          <h4 className="heading">Legal</h4>
          <ul className="list">
            <li className="list-item">
              <Link to="#">Aviso de Privacidad</Link>
            </li>
            <li className="list-item">
              <Link to="#">Términos y condiciones</Link>
            </li>
            <li className="list-item">
              <Link to="#">Política de Cookies</Link>
            </li>
          </ul>
        </div>

        <div className="section compliance-section">
          <h4 className="heading">Reconocimiento</h4>
          <ul className="list">
            <li className="list-item">
              <Link to="https://www.buro.gob.mx/" target="_blank" rel="noreferrer">
                <img src={buro} alt="buro logo" />
              </Link>
            </li>
            <li className="list-item">
              <Link to="https://www.condusef.gob.mx/" target="_blank" rel="noreferrer">
                <img src={condusef} alt="condusef logo" />
              </Link>
            </li>
            <li className="list-item">
              <Link to="https://www.gob.mx/cnbv" target="_blank" rel="noreferrer">
                <img src={cnbv} alt="cnbv logo" />
              </Link>
            </li>
          </ul>
        </div>

        <div className="section social-media-section">
          <h4 className="heading">Síguenos</h4>
          <ul className="list social-media-list">
            <li className="list-item">
              <Link
                to="https://www.facebook.com/FinSphera-101778232177736"
                target="_blank"
                rel="noreferrer"
              >
                <FacebookLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
            <li className="list-item">
              <Link to="https://www.instagram.com/finsphera/" target="_blank" rel="noreferrer">
                <InstagramLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
            <li className="list-item">
              <Link
                to="https://open.spotify.com/user/316pjhuquaww2o33flzrhowjkdoa?si=F-pAWyxCQWa8i48liPRdVw&utm_source=whatsapp"
                target="_blank"
                rel="noreferrer"
              >
                <SpotifyLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
            <li className="list-item">
              <Link
                to="https://www.linkedin.com/company/finsphera"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
            <li className="list-item">
              <Link
                to="https://www.youtube.com/channel/UCqBUEbrVmyKit-rCM3akrPQ"
                target="_blank"
                rel="noreferrer"
              >
                <YoutubeLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
            <li className="list-item">
              <Link to="https://twitter.com/FinSphera" target="_blank" rel="noreferrer">
                <XLogo className="svg-socialmedia" fill="white" />
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer-legal">
        <div className="image-container">
          <img src={logoFinsphera} alt="logo finsphera" />
        </div>
        <p className="legal-text">
          Sustainable Tech Esg, S.A.P.I. de C.V. (“Finsphera”), obtuvo autorización para la
          organización y operación de una Institución de Financiamiento Colectivo, según el Oficio
          número 311-21134615/2022 emitido por la Comisión Nacional Bancaria y de Valores y su
          respectiva publicación en el Diario Oficial de la Federación el 12 de diciembre del 2022.
          Finsphera está actualmente acreditando ante la Comisión Nacional Bancaria y de Valores el
          cumplimiento de requisitos establecidos en el artículo 40 Ley para Regular las
          Instituciones de Tecnología Financiera (LRITF), por lo que próximamente estará comenzando
          operaciones. Ni el Gobierno Federal, ni las Entidades de la Administración Pública
          Paraestatal podrán: (i) responsabilizarse o garantizar los recursos que los Usuarios de
          FinSphera inviertan a través de nuestra plataforma; y (ii) asumir alguna responsabilidad
          por las obligaciones contraídas por FinSphera o por algún Usuario de FinSphera frente a
          otro, en virtud de la celebración de las Operaciones. Para efectos del presente párrafo,
          se entenderá por Autoridades Financieras, Usuarios y Operaciones, los términos así
          definidos por la Ley para Regular las Instituciones de Tecnología Financiera. En términos
          de la Ley de Protección y Defensa al Usuario de Servicios Financieros, se hace saber que:
          (i) el programa de educación financiera de Finsphera se encuentra publicado en{' '}
          <Link to="/">www.finsphera.com</Link>; y (ii) la Unidad Especializada de Atención a
          Usuarios de FinSphera se encuentra ubicada en Montecito 38 Piso 16 Oficina 28, Colonia
          Nápoles, Alcaldía de Benito Juárez, Código Postal 03810, Ciudad de México con teléfono 56
          3959 3368, correo electrónico atencionaclientes@finsphera.com, página en internet{' '}
          <Link to="/">www.finsphera.com</Link>. Titular de la Unidad: Javier Olivera Hernández.
          Horario de atención: Lunes a Viernes de 9 am a 2 pm y de 3 pm a 5 pm. El Usuario podrá
          ponerse en contacto con la Comisión Nacional para la Protección y Defensa de los Usuarios
          de Servicios Financieros (la “CONDUSEF”) mediante el sitio web{' '}
          <Link to="https://www.condusef.gob.mx/" target="_blank" rel="noreferrer">
            www.condusef.gob.mx
          </Link>
          , teléfono (55) 5340 0999 o 800 999 8080, o por correo electrónico:
          asesoria@condusef.gob.mx.
        </p>
      </div>
    </FooterContainer>
  );
});

Footer.displayName = 'Footer';
