import axios from 'axios';
import { url } from 'src/environments';

//ONBOARDING APPLICANT RISKS
export const evidenceRisksAPI = async (input) => {
  try {
    const riskRequest = await axios.post(
      `${url}/admin/documents/investor/evidence-of-risks`,
      input
    );
    return riskRequest;
  } catch (error) {
    console.error(error);
  }
};

export const literaxRisksAPI = async (body) => {
  try {
    const literaxRequest = await axios.post(`${url}/admin/literax/emision/pdf`, body);
    return literaxRequest;
  } catch (error) {
    console.error(error);
  }
};

export const literaxSatwsAPI = async (body) => {
  try {
    const [sendFiles] = await Promise.all([
      axios.post(`${url}/admin/satws/send-files-to-aws`, body)
    ]);
    return { sendFiles };
  } catch (error) {
    console.error(error);
  }
};

export const literaxCreditCircleAPI = async (body) => {
  try {
    const [creditAnalysis, creditCircle, satwsFiles, matiFiles] = await Promise.all([
      axios.post(`${url}/admin/literax/all-info-credit-analysis`, body),
      axios.post(`${url}/admin/literax/all-info-circulo-de-credito`, body),
      axios.post(`${url}/admin/literax/emision/satws-files`, body),
      axios.post(`${url}/admin/mati/send-files-to-aws`, body)
    ]);
    return { creditAnalysis, creditCircle, satwsFiles, matiFiles };
  } catch (error) {
    console.error(error);
  }
};

export const literaxCreditAnalysisAPI = async (body) => {
  try {
    const literaxRequest = await axios.post(`${url}/admin/literax/all-info-credit-analysis`, body);
    return literaxRequest;
  } catch (error) {
    console.error(error);
  }
};
//////

export const simafReqAPI = async (input) => {
  try {
    const response = await axios.get(`${url}/admin/simaf-ids?id=${input}`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const pdfInvestorGeneralContractAPI = async (input) => {
  try {
    const generalContract = await axios.post(
      `${url}/admin/documents/investor/contract-commission`,
      input
    );
    return generalContract;
  } catch (error) {
    console.error(error);
  }
};

export const circleCreditAPI = async (inputCreditAutofill, inputGovernance) => {
  try {
    const maximumPayment = inputCreditAutofill
      ? await axios.post(
          `${url}/circulo-de-credito/credit-analysis/maximum-payment-term`,
          inputCreditAutofill.id
        )
      : null;
    const creditAutofill = inputCreditAutofill
      ? await axios.post(`${url}/circulo-de-credito/credit-analysis/autofill`, inputCreditAutofill)
      : null;
    const borrowingCapacity = inputCreditAutofill
      ? await axios.post(
          `${url}/circulo-de-credito/credit-analysis/borrowing-capacity`,
          inputCreditAutofill.id
        )
      : null;
    const commonInput = inputCreditAutofill.id;
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const endpoints = [
      `${url}/circulo-de-credito/credit-analysis/entity-grants-credit`,
      `${url}/circulo-de-credito/credit-analysis/historic-queries`,
      `${url}/circulo-de-credito/credit-analysis/number-credit-grantors`,
      `${url}/circulo-de-credito/credit-analysis/rating`
    ];

    const makeRequestsWithDelay = async (inputCreditAutofill, delayMs) => {
      const results = [];
      for (const endpoint of endpoints) {
        const response = await axios.post(endpoint, inputCreditAutofill);
        results.push(response);
        await delay(delayMs);
      }
      return results;
    };
    let entityCredit, historicQueries, creditGrantors, creditRating;
    if (commonInput) {
      [entityCredit, historicQueries, creditGrantors, creditRating] = await makeRequestsWithDelay(
        commonInput,
        1000
      );
    } else {
      [entityCredit, historicQueries, creditGrantors, creditRating] = [null, null, null, null];
    }
    const governance = await axios.post(
      `${url}/admin/credit-analysis/corporate-governance/all`,
      inputGovernance
    );
    return [
      {
        maximumPayment: maximumPayment ? maximumPayment.data : null,
        creditAutofill: creditAutofill ? creditAutofill.data : null,
        borrowingCapacity: maximumPayment ? borrowingCapacity.data : null,
        entityCredit: entityCredit ? entityCredit.data : null,
        historicQueries: historicQueries ? historicQueries.data : null,
        creditGrantors: creditGrantors ? creditGrantors.data : null,
        creditRating: creditRating ? creditRating.data : null
      }
    ];
  } catch (error) {
    console.error(error);
    throw new Error(error.errorMessage || 'Unknown error');
  }
};
