/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResourcesProvider = /* GraphQL */ `
  query GetResourcesProvider($id: ID!) {
    getResourcesProvider(id: $id) {
      id
      applicantId
      investorId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listResourcesProviders = /* GraphQL */ `
  query ListResourcesProviders(
    $filter: ModelResourcesProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResourcesProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getAnonymousMailbox = /* GraphQL */ `
  query GetAnonymousMailbox($id: ID!) {
    getAnonymousMailbox(id: $id) {
      id
      userType
      description
      issueDate
      anyoneKnows
      hasEscaled
      email
      fullName
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAnonymousMailboxes = /* GraphQL */ `
  query ListAnonymousMailboxes(
    $filter: ModelAnonymousMailboxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnonymousMailboxes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userType
        description
        issueDate
        anyoneKnows
        hasEscaled
        email
        fullName
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBeneficiary = /* GraphQL */ `
  query GetBeneficiary($id: ID!) {
    getBeneficiary(id: $id) {
      id
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      percentage
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBeneficiariesId
      owner
    }
  }
`;
export const listBeneficiaries = /* GraphQL */ `
  query ListBeneficiaries($filter: ModelBeneficiaryFilterInput, $limit: Int, $nextToken: String) {
    listBeneficiaries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        investorId
        firstName
        lastNameM
        lastNameP
        relationship
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        percentage
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorBeneficiariesId
        owner
      }
      nextToken
    }
  }
`;
export const getInvestor = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      userId
      typeOfPerson
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBeneficiariesId
          owner
        }
        nextToken
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      risk {
        investorId
        loss
        liquidity
        information
        performance
        notApproved
        notAdvice
        investment
        disclosedInformation
        isSigned
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      investments {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      createdAt
      updatedAt
      isInNewsletter
      isInMarketing
      isPLDApproved
      isSIMAFSended
      economicActivities {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      isRisksSigned
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listInvestors = /* GraphQL */ `
  query ListInvestors($filter: ModelInvestorFilterInput, $limit: Int, $nextToken: String) {
    listInvestors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getObligadoSolidario = /* GraphQL */ `
  query GetObligadoSolidario($id: ID!) {
    getObligadoSolidario(id: $id) {
      id
      applicantId
      firstName
      lastNameM
      lastNameP
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      dateOfBirth
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      percentage
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      applicantObligadoSolidarioId
      owner
    }
  }
`;
export const listObligadoSolidarios = /* GraphQL */ `
  query ListObligadoSolidarios(
    $filter: ModelObligadoSolidarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObligadoSolidarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      nextToken
    }
  }
`;
export const getInvestment = /* GraphQL */ `
  query GetInvestment($id: ID!) {
    getInvestment(id: $id) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      status
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      porcentajeGananciaInteres
      porcentajeGananciaInteresFinal
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;
export const listInvestments = /* GraphQL */ `
  query ListInvestments($filter: ModelInvestmentFilterInput, $limit: Int, $nextToken: String) {
    listInvestments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        investorId
        campaignId
        compromisoInversion
        interesInversionistaPorcentaje
        interesInversionistaPesos
        retencionImpuestoInversionistaPorcentaje
        retencionImpuestoInversionistaPesos
        status
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        porcentajeGananciaInteres
        porcentajeGananciaInteresFinal
        createdAt
        updatedAt
        investorInvestmentsId
        owner
      }
      nextToken
    }
  }
`;
export const getApplicant = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      userId
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        simaf_nationalityId
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        pepCharge
        pepName
        pepDate
        isFamilyPEP
        generalActivity
        specificActivity
        simaf_specificActivityId
      }
      companyInformation {
        companyName {
          entityType
          legalName
          tradeName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        startedOperationsAt
        country
        phone {
          land
          mobile
          isVerified
          lada
          ext
        }
        numeroDeSerie
        obligadoSolidario
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
        activoCirculante
        activoFijo
        activoTotal
        pasivo
        pasivoCortoPlazo
        pasivoLargoPlazo
        capital
        ventas
        costos
        gastos
        utilidadNeta
        simaf_specificActivityId
      }
      interestRateFinsphera
      defaultInterestRateInvestor
      interestRateInvestor
      score
      paymentScore
      delayDays
      isApproved
      isBureauConsultAuthorized
      reasonOfRejection
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mati {
        stepId
        resource
        identityId
        verificationId
        eventName
        isFinished
        videoUrl
        spriteUrl
        selfieUrl {
          media
          isUrl
        }
        documentType
        score
        ageThreshold
        isAlterationDetection
        deviceFingerPrint {
          ua
          ip
          vpnDetectionEnabled
          browserName
          browserVersion
          browserMajor
          engineName
          engineVersion
          osName
          osVersion
          appPlatform
          appVersion
        }
        identityStatus
        age
        isDocumentExpired
        matiDashboardUrl
        finalStatus
      }
      isInNewsletter
      isPLDApproved
      isInMarketing
      isSIMAFSended
      requestedAmount
      termLoan
      loanDestination
      createdAt
      updatedAt
      creditBureau {
        applicantId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      creditAnalysis {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      file {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
          typeOfAddress
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          type
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          investorAddressId
          applicantAddressId
          owner
        }
        nextToken
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          campaignId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
          external_id
          remind_every
          expires_at
          days_to_expire
          created_by
          state
          original_hash
          manual_close
          encrypted
          allow_business
          file_file_name
          archived
          krs
          owner {
            id
            email
            name
            is_company
          }
          creator {
            id
            email
            name
          }
          callback_url
          sign_callback_url
          already_signed {
            mifiel_already_signed
          }
          has_not_signed {
            mifiel_has_not_signed
          }
          permissions {
            unknown_1
            unknown_2
            unknown_3
          }
          file
          file_download
          file_signed
          file_signed_download
          file_zipped
          file_xml
          signers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          viewers {
            id
            name
            email
            tax_id
            role
            rejections
            status
            field
            signed
            widget_id
            current
            last_reminded_at
            sat_auth
          }
          signatures {
            id
            name
            email
            signed
            signed_at
            certificate_number
            tax_id
            signature
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          investorMifielsId
          applicantMifielsId
        }
        nextToken
      }
      economicActivity {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      banks {
        items {
          CLABE
          applicantId
          investorId
          isMFA
          identifier
          isMainAccount
          messageId
          bankName
          bankCode
          status
          numberOfCard
          account
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorBanksId
          applicantBanksId
          owner
        }
        nextToken
      }
      campaigns {
        items {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      onboarding {
        complete
        currentStep
      }
      isContractSigned
      distributionChannel
      industryActivity
      verificationCode {
        code
        codeReceived
        messageId
        timestamp
        timestampReceived
        typeMessage
        isValid
      }
      navigationPage {
        action
        timestamp
        route
      }
      literaxs {
        items {
          idExterno
          applicantId
          investorId
          estado
          descripcion
          constancia
          serie
          timeStamp
          huellaDigital
          politicaPSC
          firmaPSC
          archivoConstancia
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          investorLiteraxsId
          applicantLiteraxsId
          owner
        }
        nextToken
      }
      customerNumberEfysis
      savingsAccountNumber
      trully {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      isAccountCanceled
      statusSIMAF
      obligadoSolidario {
        items {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        nextToken
      }
      resourcesProvider {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listApplicants = /* GraphQL */ `
  query ListApplicants($filter: ModelApplicantFilterInput, $limit: Int, $nextToken: String) {
    listApplicants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getBank = /* GraphQL */ `
  query GetBank($CLABE: ID!) {
    getBank(CLABE: $CLABE) {
      CLABE
      applicantId
      investorId
      isMFA
      identifier
      isMainAccount
      messageId
      bankName
      bankCode
      status
      numberOfCard
      account
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorBanksId
      applicantBanksId
      owner
    }
  }
`;
export const listBanks = /* GraphQL */ `
  query ListBanks(
    $CLABE: ID
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBanks(
      CLABE: $CLABE
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        CLABE
        applicantId
        investorId
        isMFA
        identifier
        isMainAccount
        messageId
        bankName
        bankCode
        status
        numberOfCard
        account
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorBanksId
        applicantBanksId
        owner
      }
      nextToken
    }
  }
`;
export const getAdmin = /* GraphQL */ `
  query GetAdmin($id: ID!) {
    getAdmin(id: $id) {
      id
      email
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      STP {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAdmins = /* GraphQL */ `
  query ListAdmins($filter: ModelAdminFilterInput, $limit: Int, $nextToken: String) {
    listAdmins(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      id
      applicantId
      nombreCampania
      montoSolicitado
      valueOfInvoices
      collectedAmount
      isActive
      isExpired
      isSuccessful
      isPromissoryNoteSigned
      dateStartCampaign
      dateEndCampaign
      signLimitDate
      signDate
      plazoCredito
      paymentDate
      areInvoicesUsed
      isMoneySent
      termCampaing
      status
      destinoCredito
      fuentePago
      investment {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          status
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          porcentajeGananciaInteres
          porcentajeGananciaInteresFinal
          createdAt
          updatedAt
          investorInvestmentsId
          owner
        }
        nextToken
      }
      invoices {
        items {
          id
          applicantId
          campaignId
          comprobante {
            lugarExpedicion
            metodoPago
            tipoDeComprobante
            total
            moneda
            subTotal
            condicionesDePago
            noCertificado
            formaPago
            sello
            fecha
            folio
            version
          }
          number
          name
          type
          isVerified
          isApproved
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          createdAt
          updatedAt
          isAvailable
          applicantInvoicesId
          owner
        }
        nextToken
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      TIIE
      tasaInteresAdicionalPorcentaje
      tasaCreditoPorcentaje
      interesCreditoPesos
      IVAinteresCreditoPesos
      interesesCreditoPesosConIVA
      interesMoratorioPesos
      IVAinteresMoratorio
      interesesMoratorioPesosConIVA
      comisionCreditoPesos
      IVAcomisionCreditoPesos
      comisionCreditoPesosConIVA
      inversionFinspheraCampaniaPorcentaje
      inversionFinspheraCampaniaPesos
      tasaInteresMoratorioPorcentaje
      montoTransferencia
      plazoCreditoFinal
      paymentDateFinal
      interesCreditoPesosFinal
      IVAinteresCreditoPesosFinal
      interesesCreditoPesosConIVAFinal
      comisionCreditoPesosFinal
      IVAcomisionCreditoPesosFinal
      comisionCreditoPesosConIVAFinal
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      applicantCampaignsId
      owner
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns($filter: ModelCampaignFilterInput, $limit: Int, $nextToken: String) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const getEfisysCampaign = /* GraphQL */ `
  query GetEfisysCampaign($creditRequestNumber: ID!) {
    getEfisysCampaign(creditRequestNumber: $creditRequestNumber) {
      creditRequestNumber
      applicantId
      campaignId
      creditRequest {
        test
      }
      investmentRequest {
        test
      }
      cancelInvestment {
        test
      }
      creditNumber
      status
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      deletedAt
      owner
    }
  }
`;
export const listEfisysCampaigns = /* GraphQL */ `
  query ListEfisysCampaigns(
    $creditRequestNumber: ID
    $filter: ModelEfisysCampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEfisysCampaigns(
      creditRequestNumber: $creditRequestNumber
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        creditRequestNumber
        applicantId
        campaignId
        creditRequest {
          test
        }
        investmentRequest {
          test
        }
        cancelInvestment {
          test
        }
        creditNumber
        status
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        deletedAt
        owner
      }
      nextToken
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      applicantId
      campaignId
      comprobante {
        lugarExpedicion
        metodoPago
        tipoDeComprobante
        total
        moneda
        subTotal
        condicionesDePago
        noCertificado
        formaPago
        sello
        fecha
        folio
        version
        emisor {
          RFC
          nombre
          regimenFiscal
        }
        receptor {
          RFC
          nombre
          usoCFDI
        }
        conceptos {
          concepto {
            claveProdServ
            cantidad
            claveUnidad
            unidad
            descripcion
            valorUnitario
            importe
          }
        }
        impuestos {
          totalImpuestosTrasladados
          impuestoTraslado {
            impuesto
            tipoFactor
            tasaOCuota
            importe
          }
        }
        complemento {
          timbreFiscalDigital {
            version
            UUID
            fechaTimbrado
            RFCProvCertif
            selloCFD
            noCertificadoSAT
            selloSAT
            folioFiscal
          }
        }
      }
      number
      name
      type
      isVerified
      isApproved
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      isAvailable
      applicantInvoicesId
      owner
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices($filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const getEconomicActivity = /* GraphQL */ `
  query GetEconomicActivity($id: ID!) {
    getEconomicActivity(id: $id) {
      id
      economicActivity
      specificActivity
      importToMexico
      exportFromMexico
      useCoinedMetals
      relationsWithCountries
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEconomicActivities = /* GraphQL */ `
  query ListEconomicActivities(
    $filter: ModelEconomicActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEconomicActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        economicActivity
        specificActivity
        importToMexico
        exportFromMexico
        useCoinedMetals
        relationsWithCountries
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCreditAnalysis = /* GraphQL */ `
  query GetCreditAnalysis($applicantId: ID!) {
    getCreditAnalysis(applicantId: $applicantId) {
      applicantId
      profitability {
        profitabilityValue
        profitabilityPoints
        compoundAnnualGrowthInSalesValue
        compoundAnnualGrowthInSalesPoints
        compoundAnnualGrowthInNetEarningsValue
        compoundAnnualGrowthInNetEarningsPoints
        rating
        isApproved
      }
      liquidity {
        creditLine
        creditBalance
        borrowingCapacityValue
        borrowingCapacityPoints
        liquidityRatioValue
        liquidityRatioPoints
        acidTestValue
        acidTestPoints
        immediatePaymentAbilityValue
        immediatePaymentAbilityPoints
        maximumPaymentTermValue
        maximumPaymentTermPoints
        rating
        isApproved
      }
      solvency {
        taxComplianceChecksValue
        taxComplianceChecksPoints
        taxRegimeValue
        taxRegimePoints
        allClients
        majorCorporateClients
        majorCorporateClientsValue
        majorCorporateClientsPoints
        annualGrowthInClientsValue
        annualGrowthInClientsPoints
        salesConcentrationValue
        salesConcentrationPoints
        ROEValue
        ROEPoints
        ROAValue
        ROAPoints
        rating
        isApproved
      }
      creditAccess {
        financialInstitutionGrantsCreditValue
        financialInstitutionGrantsCreditPoints
        creditBureauRatingValue
        creditBureauRatingPoints
        paymentHistoryValue
        paymentHistoryPoints
        numberOfCreditGrantorsValue
        numberOfCreditGrantorsPoints
        inquiriesInCreditBureauValue
        inquiriesInCreditBureauPoints
        rating
        isApproved
      }
      marketAndProduct {
        dayOfBirth
        businessAgeValue
        businessAgePoints
        mainActivityValue
        mainActivityPoints
        statusInTaxpayerRegistryValue
        statusInTaxpayerRegistryPoints
        capitalRegimeValue
        capitalRegimePoints
        federalEntityValue
        federalEntityPoints
        rating
        isApproved
      }
      corporateGovernance {
        experienceOfMainShareholderValue
        experienceOfMainShareholderPoints
        independetConselorsValue
        independetConselorsPoints
        independenceDirectorGeneralValue
        independenceDirectorGeneralPoints
        numberOfCommitteesValue
        numberOfCommitteesPoints
        rating
        isApproved
      }
      genderEquality {
        numberFemaleShareholdersValue
        numberFemaleShareholdersPoints
        numberFemaleDirectorsOnBoardValue
        numberFemaleDirectorsOnBoardPoints
        isCEOFemaleValue
        isCEOFemalePoints
        numberFemaleWithManagerialPositionValue
        numberFemaleWithManagerialPositionPoints
        numberFemaleEmployeesValue
        numberFemaleEmployeesPoints
        rating
        isApproved
      }
      environmentalAndSocialImpact {
        finPobreza {
          erradicarPobrezaExtrema {
            value
            description
          }
          reduccionPobrezaRelativa {
            value
            description
          }
          implantacionProteccionSocial {
            value
            description
          }
          garantiaAccesoServicioBasico {
            value
            description
          }
          resilienciaDesastresAmbientales {
            value
            description
          }
          creacionMarcosNormativos {
            value
            description
          }
          isApproved
        }
        hambreCero {
          finHambre {
            value
            description
          }
          finMalnutricion {
            value
            description
          }
          duplicarProductividadIngresosPequeniaEscala {
            value
            description
          }
          practicasAgricolasSosteniblesResilientes {
            value
            description
          }
          mantenimientoDiversidadGeneticaSemilla {
            value
            description
          }
          aumentoInversionAgricola {
            value
            description
          }
          estabilidadMercadosAgropecuarios {
            value
            description
          }
          controlVolatilidadPreciosAlimentos {
            value
            description
          }
          isApproved
        }
        saludBienestar {
          reduccionTasaMortalidadMaterna {
            value
            description
          }
          eliminarMortalidadInfantil {
            value
            description
          }
          finEnfermedadesTransmisibles {
            value
            description
          }
          reduccionEnfermedadesNoTransmisiblesYSaludMental {
            value
            description
          }
          prevencionTratamientoAbusosDrogasAlcohol {
            value
            description
          }
          controlTabaco {
            value
            description
          }
          apoyoIDVacunasMedicamentosEsenciales {
            value
            description
          }
          aumentoFinanciacionSistemaSanitario {
            value
            description
          }
          refuerzoGestionRiesgosSanitarios {
            value
            description
          }
          isApproved
        }
        educacionCalidad {
          asegurarCalidadPrimariaSecundaria {
            value
            description
          }
          asegurarAccesoCalidadEducacionPreescolar {
            value
            description
          }
          asegurarAccesoIgualitarioFormacionSuperior {
            value
            description
          }
          aumentoCompetenciasAccederEmpleo {
            value
            description
          }
          eliminacionDisparidadGenero {
            value
            description
          }
          asegurarAlfabetizacion {
            value
            description
          }
          fomentarEducacionGlobal {
            value
            description
          }
          mejoraInstalacionesEducativas {
            value
            description
          }
          aumentoBecas {
            value
            description
          }
          mejorarDocentes {
            value
            description
          }
          isApproved
        }
        igualdadGenero {
          eliminarViolenciaGenero {
            value
            description
          }
          eliminarMatrimonioInfantil {
            value
            description
          }
          reconocerTrabajoDomestico {
            value
            description
          }
          asegurarParticipacionMujer {
            value
            description
          }
          asegurarAccesoSaludSexual {
            value
            description
          }
          asegurarIgualdadDerechos {
            value
            description
          }
          mejorarUsoTecnologia {
            value
            description
          }
          aprobarPoliticasIgualdad {
            value
            description
          }
          isApproved
        }
        aguaLimpia {
          accesoAguaPotable {
            value
            description
          }
          accesoSanamiento {
            value
            description
          }
          mejorarCalidadAgua {
            value
            description
          }
          usoEficienteRecursosHidricos {
            value
            description
          }
          gestionRecursosHidricos {
            value
            description
          }
          proteccionEcosistemas {
            value
            description
          }
          creacionCapacidadGestion {
            value
            description
          }
          apoyarComunidadesLocales {
            value
            description
          }
          isApproved
        }
        energiaAsequible {
          accesoUniversalEnergia {
            value
            description
          }
          aumentoEnergiasRenovables {
            value
            description
          }
          duplicarEficienciaEnergetica {
            value
            description
          }
          aumentoInvestigacionInversion {
            value
            description
          }
          ampliarInfraestructuraYTecnologia {
            value
            description
          }
          isApproved
        }
        trabajoDecente {
          mantenimientoCrecimientoEconomico {
            value
            description
          }
          elevarProductividad {
            value
            description
          }
          fomentoPequeniaMedianaEmpresa {
            value
            description
          }
          mejorarProduccionYConsumo {
            value
            description
          }
          lograrPlenoEmpleo {
            value
            description
          }
          reduccionJovenesSinTrabajo {
            value
            description
          }
          erradicarEsclavitud {
            value
            description
          }
          proteccionDerechosLaborales {
            value
            description
          }
          promocionTurismo {
            value
            description
          }
          fortalecimientoInstitucionesFinancieras {
            value
            description
          }
          aumentoAyudaComercio {
            value
            description
          }
          desarrolloEmpleoJuvenil {
            value
            description
          }
          isApproved
        }
        industriaInnovacion {
          desarrolloInfraestructura {
            value
            description
          }
          promocionIndustriaInclusiva {
            value
            description
          }
          aumentoAccesoPymesServiciosFinancieros {
            value
            description
          }
          modernizacionInfraestructura {
            value
            description
          }
          aumentoInvestigacionCientifica {
            value
            description
          }
          apoyoInfraestructuraSostenible {
            value
            description
          }
          desarrolloTecnologia {
            value
            description
          }
          aumentoAccesoTecnologias {
            value
            description
          }
          isApproved
        }
        reduccionDesigualdades {
          crecimientosIngresos {
            value
            description
          }
          promocionInclusion {
            value
            description
          }
          igualdadOportinidades {
            value
            description
          }
          adopcionPoliticasFiscales {
            value
            description
          }
          mejorarRegulacionMercados {
            value
            description
          }
          participacionPaisesDesarrollo {
            value
            description
          }
          facilitarMigracion {
            value
            description
          }
          aplicacionTratoEspecial {
            value
            description
          }
          fomentarCorrientesFinancieras {
            value
            description
          }
          reduccionCostesRemesas {
            value
            description
          }
          isApproved
        }
        ciudadesComunidades {
          accesoVivienda {
            value
            description
          }
          accesoTransportePublico {
            value
            description
          }
          aumentoUrbanizacion {
            value
            description
          }
          proteccionPatrimonioCultural {
            value
            description
          }
          reduccionNumeroMuertes {
            value
            description
          }
          reduccionImpactoAmbiental {
            value
            description
          }
          accesoZonasVerdes {
            value
            description
          }
          apoyoZonasUrbanas {
            value
            description
          }
          reduccionRiesgos {
            value
            description
          }
          apoyoConstruccionEdificosSostenibles {
            value
            description
          }
          isApproved
        }
        produccionConsumo {
          aplicacionMarcoConsumo {
            value
            description
          }
          usoEficienteRecursosNaturales {
            value
            description
          }
          reduccionDesperdicioAlimentos {
            value
            description
          }
          gestionDeshechos {
            value
            description
          }
          prevencionReduccionReciclado {
            value
            description
          }
          adopcionPracticasSostenibles {
            value
            description
          }
          adquisicionPublicasSostenibles {
            value
            description
          }
          asegurarEducacionDesarrolloSostenible {
            value
            description
          }
          fortalecimientoCienciaTecnologia {
            value
            description
          }
          turismoSostenible {
            value
            description
          }
          regularSubsidios {
            value
            description
          }
          isApproved
        }
        accionClima {
          fortalecerResilencia {
            value
            description
          }
          incorporarCambioClimaticoPoliticas {
            value
            description
          }
          mejoraEducacion {
            value
            description
          }
          movilizacionRecursosEconomicos {
            value
            description
          }
          gestionCambioClimatico {
            value
            description
          }
          isApproved
        }
        vidaSubmarina {
          prevencionContaminacionMarina {
            value
            description
          }
          gestionEcosistemasMarinosCosteros {
            value
            description
          }
          minimizarAcidificacionOceanos {
            value
            description
          }
          regularExplotacionPesqueraSostenible {
            value
            description
          }
          conservarZonasCosterasYMarinas {
            value
            description
          }
          combatirPescaIlegal {
            value
            description
          }
          aumentoBeneficiosEconomicosPescaSostenible {
            value
            description
          }
          apoyoInvestigacionMarina {
            value
            description
          }
          fomentoPescaPequeniaEscala {
            value
            description
          }
          aplicacionConvencionNNUU {
            value
            description
          }
          isApproved
        }
        vidaEcosistema {
          conservacionEcosistemas {
            value
            description
          }
          gestionSostenibleBosques {
            value
            description
          }
          luchaContraDesertificacion {
            value
            description
          }
          conservarEcosistemasMontaniosos {
            value
            description
          }
          degradacionPerdidaBiodiversidad {
            value
            description
          }
          usoAdecuadoRecursosGeneticos {
            value
            description
          }
          combatirCazaFurtiva {
            value
            description
          }
          prevencionEspeciesInvasoras {
            value
            description
          }
          integracionPlanesMedioAmbiente {
            value
            description
          }
          movilizacionAumentoRecursosFinancieros {
            value
            description
          }
          aumentoRecursosGestionForestal {
            value
            description
          }
          luchaCazaFurtiva {
            value
            description
          }
          isApproved
        }
        pazJusticiaInstituciones {
          reduccionFormasViolencia {
            value
            description
          }
          erradicacionMaltrato {
            value
            description
          }
          promocionEstadoDerecho {
            value
            description
          }
          reduccionCorrientesFinancieras {
            value
            description
          }
          reduccionCorrupcion {
            value
            description
          }
          creacionInstitucionesEficaces {
            value
            description
          }
          fomentoParticipacionCiudadana {
            value
            description
          }
          fortalecerPaisesEnDesarrollo {
            value
            description
          }
          proporcionIdentidadJuridica {
            value
            description
          }
          accesoInformacion {
            value
            description
          }
          fortalecimientoInstituciones {
            value
            description
          }
          promocionAplicacionLeyes {
            value
            description
          }
          isApproved
        }
        alianzaOds {
          movilizacionRecursosDomesticos {
            value
            description
          }
          cumplirAyudaDesarrollo {
            value
            description
          }
          movilizarRecursosFinancieros {
            value
            description
          }
          reestructurarDeuda {
            value
            description
          }
          promocionInversion {
            value
            description
          }
          mejorarTraspasoTecnologia {
            value
            description
          }
          promoverTecnologiaEcologicas {
            value
            description
          }
          crearBancoTecnologia {
            value
            description
          }
          refuerzoCapacidades {
            value
            description
          }
          promocionComercio {
            value
            description
          }
          aumentoExportaciones {
            value
            description
          }
          accesoMercados {
            value
            description
          }
          aumentoEstabilidad {
            value
            description
          }
          mejoraCoherencia {
            value
            description
          }
          respetoSoberania {
            value
            description
          }
          mejorarAlianzaMundial {
            value
            description
          }
          fomentoAlianzas {
            value
            description
          }
          creacionCapacidadEstadistica {
            value
            description
          }
          promoverIndicadores {
            value
            description
          }
          isApproved
        }
        rating
        isApproved
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      isApproved
      rating
      interestRateApplicant
      hasAnotherCredit
      owner
    }
  }
`;
export const listCreditAnalyses = /* GraphQL */ `
  query ListCreditAnalyses(
    $applicantId: ID
    $filter: ModelCreditAnalysisFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditAnalyses(
      applicantId: $applicantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      nextToken
    }
  }
`;
export const getCreditBureau = /* GraphQL */ `
  query GetCreditBureau($applicantId: ID!) {
    getCreditBureau(applicantId: $applicantId) {
      applicantId
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCreditBureaus = /* GraphQL */ `
  query ListCreditBureaus(
    $applicantId: ID
    $filter: ModelCreditBureauFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCreditBureaus(
      applicantId: $applicantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        applicantId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      applicantId
      investorId
      fullAddress
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      streetType
      streetReferences
      statusRaw
      numInt
      numExt
      typeOfAddress
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      simaf_municipioId
      simaf_neighborhoodId
      simaf_postalCodeId
      simaf_stateId
      type
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      investorAddressId
      applicantAddressId
      owner
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses($filter: ModelAddressFilterInput, $limit: Int, $nextToken: String) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        fullAddress
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        streetType
        streetReferences
        statusRaw
        numInt
        numExt
        typeOfAddress
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        type
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        investorAddressId
        applicantAddressId
        owner
      }
      nextToken
    }
  }
`;
export const getRisk = /* GraphQL */ `
  query GetRisk($investorId: ID!) {
    getRisk(investorId: $investorId) {
      investorId
      loss
      liquidity
      information
      performance
      notApproved
      notAdvice
      investment
      disclosedInformation
      isSigned
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listRisks = /* GraphQL */ `
  query ListRisks(
    $investorId: ID
    $filter: ModelRiskFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRisks(
      investorId: $investorId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        investorId
        loss
        liquidity
        information
        performance
        notApproved
        notAdvice
        investment
        disclosedInformation
        isSigned
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      address
      FEA
      INE {
        s3Front
        s3Back
        matiFrontUrl
        matiBackUrl
        emmissionDate
        expirationDate
        documentNumber
        ocrNumber
        documentType
        issueCountry
        simafContryCode
        ne
      }
      taxID
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      areBusinessDocumentsUpload
      businessDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      odsDocuments {
        name
        isUploaded
        url
        literaxEmision
        isObligadoSolidario
        type
      }
      obligadoSolidario {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      owner
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles($filter: ModelFileFilterInput, $limit: Int, $nextToken: String) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        address
        FEA
        INE {
          s3Front
          s3Back
          matiFrontUrl
          matiBackUrl
          emmissionDate
          expirationDate
          documentNumber
          ocrNumber
          documentType
          issueCountry
          simafContryCode
          ne
        }
        taxID
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        areBusinessDocumentsUpload
        businessDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        odsDocuments {
          name
          isUploaded
          url
          literaxEmision
          isObligadoSolidario
          type
        }
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSTP = /* GraphQL */ `
  query GetSTP($id: ID!) {
    getSTP(id: $id) {
      id
      CLABE
      cambioEstado {
        id
        empresa
        folioOrigen
        estado
        causaDevolucion
        tsLiquidacion
        createdAt
        updatedAt
      }
      collection {
        id
        abono
        fechaOperacion
        institucionOrdenante
        institucionBeneficiaria
        claveRastreo
        monto
        nombreOrdenante
        tipoCuentaOrdenante
        cuentaOrdenante
        rfcCurpOrdenante
        nombreBeneficiario
        tipoCuentaBeneficiario
        cuentaBeneficiario
        nombreBeneficiario2
        tipoCuentaBeneficiario2
        cuentaBeneficiario2
        rfcCurpBeneficiario
        conceptoPago
        referenciaNumerica
        empresa
        tipoPago
        tsLiquidacion
        folioCodi
        createdAt
        updatedAt
      }
      registraOrden {
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        firma
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        registraOrdenId
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        ordenPago
        fechaOperacion
        folioOrigen
      }
      recursosDisponibles
      compromisoInversion
      status
      inversionesVigentes
      saldoTotal
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSTPS = /* GraphQL */ `
  query ListSTPS($filter: ModelSTPFilterInput, $limit: Int, $nextToken: String) {
    listSTPS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSTPNotification = /* GraphQL */ `
  query GetSTPNotification($registraOrdenId: ID!) {
    getSTPNotification(registraOrdenId: $registraOrdenId) {
      registraOrdenId
      STPId
      STP {
        items {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSTPNotifications = /* GraphQL */ `
  query ListSTPNotifications(
    $registraOrdenId: ID
    $filter: ModelSTPNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSTPNotifications(
      registraOrdenId: $registraOrdenId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        registraOrdenId
        STPId
        STP {
          items {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLiterax = /* GraphQL */ `
  query GetLiterax($idExterno: ID!) {
    getLiterax(idExterno: $idExterno) {
      idExterno
      applicantId
      investorId
      estado
      descripcion
      constancia
      serie
      timeStamp
      huellaDigital
      politicaPSC
      firmaPSC
      archivoConstancia
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      investorLiteraxsId
      applicantLiteraxsId
      owner
    }
  }
`;
export const listLiteraxes = /* GraphQL */ `
  query ListLiteraxes(
    $idExterno: ID
    $filter: ModelLiteraxFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLiteraxes(
      idExterno: $idExterno
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        idExterno
        applicantId
        investorId
        estado
        descripcion
        constancia
        serie
        timeStamp
        huellaDigital
        politicaPSC
        firmaPSC
        archivoConstancia
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorLiteraxsId
        applicantLiteraxsId
        owner
      }
      nextToken
    }
  }
`;
export const getMifiel = /* GraphQL */ `
  query GetMifiel($id: ID!) {
    getMifiel(id: $id) {
      id
      applicantId
      investorId
      campaignId
      type
      documentId
      send_mail
      signed_hash
      name
      signed_by_all
      signed
      signed_at
      created_at
      burned_at
      status {
        status
      }
      external_id
      remind_every
      expires_at
      days_to_expire
      created_by
      state
      original_hash
      manual_close
      encrypted
      allow_business
      file_file_name
      archived
      krs
      owner {
        id
        email
        name
        is_company
      }
      creator {
        id
        email
        name
      }
      callback_url
      sign_callback_url
      already_signed {
        mifiel_already_signed
      }
      has_not_signed {
        mifiel_has_not_signed
      }
      permissions {
        unknown_1
        unknown_2
        unknown_3
      }
      file
      file_download
      file_signed
      file_signed_download
      file_zipped
      file_xml
      signers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      viewers {
        id
        name
        email
        tax_id
        role
        rejections
        status
        field
        signed
        widget_id
        blacklisted_algs {
          blacklisted_algs
        }
        current
        last_reminded_at
        features {
          test
        }
        sat_auth
      }
      signatures {
        id
        name
        email
        signed
        signed_at
        certificate_number
        tax_id
        signature
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      createdAt
      updatedAt
      investorMifielsId
      applicantMifielsId
    }
  }
`;
export const listMifiels = /* GraphQL */ `
  query ListMifiels($filter: ModelMifielFilterInput, $limit: Int, $nextToken: String) {
    listMifiels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        campaignId
        type
        documentId
        send_mail
        signed_hash
        name
        signed_by_all
        signed
        signed_at
        created_at
        burned_at
        status {
          status
        }
        external_id
        remind_every
        expires_at
        days_to_expire
        created_by
        state
        original_hash
        manual_close
        encrypted
        allow_business
        file_file_name
        archived
        krs
        owner {
          id
          email
          name
          is_company
        }
        creator {
          id
          email
          name
        }
        callback_url
        sign_callback_url
        already_signed {
          mifiel_already_signed
        }
        has_not_signed {
          mifiel_has_not_signed
        }
        permissions {
          unknown_1
          unknown_2
          unknown_3
        }
        file
        file_download
        file_signed
        file_signed_download
        file_zipped
        file_xml
        signers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        viewers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        signatures {
          id
          name
          email
          signed
          signed_at
          certificate_number
          tax_id
          signature
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        investorMifielsId
        applicantMifielsId
      }
      nextToken
    }
  }
`;
export const getCirculoDeCredito = /* GraphQL */ `
  query GetCirculoDeCredito($applicantId: ID!) {
    getCirculoDeCredito(applicantId: $applicantId) {
      applicantId
      folioConsulta
      folioOtorgante
      claveRetorno
      fechaConsulta
      persona {
        RFC
        nombre
        nacionalidad
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
      }
      clavesBanxico {
        claveBanxico1
        claveBanxico2
        claveBanxico3
        claveBanxico4
        claveBanxico5
        claveBanxico6
        claveBanxico7
        claveBanxico8
        claveBanxico9
        claveBanxico10
      }
      calificacionCartera {
        calificacion
        nombreOtorgante
      }
      clavePrevenciones
      consultasInstitucionales {
        comerciales {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        financieras {
          ultimos3meses
          ultimos12meses
          ultimos24meses
          mas24meses
        }
        consultas {
          fechaConsulta
          nombreOtorgante
        }
      }
      declarativas
      accionistas {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        porcentaje
      }
      avales {
        RFC
        CURP
        nombre
        segundoNombre
        apellidoPaterno
        apellidoMaterno
        domicilio {
          direccion
          coloniaPoblacion
          delegacionMunicipio
          ciudad
          estado
          CP
          pais
          telefono
          extension
          fax
        }
        cantidad
      }
      credito {
        cuentasFinancieras {
          RFC
          contrato
          nombreOtorgante
          saldoInicial
          saldoTotal
          moneda
          fechaApertura
          plazo
          tipoCambio
          clavesObservacion
          tipoCredito
          vigente
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          fechaCierre
          pagoEfectivo
          quita
          dacionPago
          quebrantoCastigo
          historia
          atrasoMayor
          registroImpugnado
        }
        cuentasComerciales {
          RFC
          nombreOtorgante
          moneda
          vigente
          saldoTotal
          veintiNueveDias
          cincuentYNuevaDias
          ochentaYNueveDias
          cientoDiecinueveDias
          cientoSetentaYNueveDias
          cientoOchentaMasDias
          actualizacion
          historia
          atrasoMayor
        }
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCirculoDeCreditos = /* GraphQL */ `
  query ListCirculoDeCreditos(
    $applicantId: ID
    $filter: ModelCirculoDeCreditoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCirculoDeCreditos(
      applicantId: $applicantId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        applicantId
        folioConsulta
        folioOtorgante
        claveRetorno
        fechaConsulta
        persona {
          RFC
          nombre
          nacionalidad
          domicilio {
            direccion
            coloniaPoblacion
            delegacionMunicipio
            ciudad
            estado
            CP
            pais
            telefono
            extension
            fax
          }
        }
        clavesBanxico {
          claveBanxico1
          claveBanxico2
          claveBanxico3
          claveBanxico4
          claveBanxico5
          claveBanxico6
          claveBanxico7
          claveBanxico8
          claveBanxico9
          claveBanxico10
        }
        calificacionCartera {
          calificacion
          nombreOtorgante
        }
        clavePrevenciones
        consultasInstitucionales {
          comerciales {
            ultimos3meses
            ultimos12meses
            ultimos24meses
            mas24meses
          }
          financieras {
            ultimos3meses
            ultimos12meses
            ultimos24meses
            mas24meses
          }
          consultas {
            fechaConsulta
            nombreOtorgante
          }
        }
        declarativas
        accionistas {
          RFC
          CURP
          nombre
          segundoNombre
          apellidoPaterno
          apellidoMaterno
          domicilio {
            direccion
            coloniaPoblacion
            delegacionMunicipio
            ciudad
            estado
            CP
            pais
            telefono
            extension
            fax
          }
          porcentaje
        }
        avales {
          RFC
          CURP
          nombre
          segundoNombre
          apellidoPaterno
          apellidoMaterno
          domicilio {
            direccion
            coloniaPoblacion
            delegacionMunicipio
            ciudad
            estado
            CP
            pais
            telefono
            extension
            fax
          }
          cantidad
        }
        credito {
          cuentasFinancieras {
            RFC
            contrato
            nombreOtorgante
            saldoInicial
            saldoTotal
            moneda
            fechaApertura
            plazo
            tipoCambio
            clavesObservacion
            tipoCredito
            vigente
            veintiNueveDias
            cincuentYNuevaDias
            ochentaYNueveDias
            cientoDiecinueveDias
            cientoSetentaYNueveDias
            cientoOchentaMasDias
            actualizacion
            fechaCierre
            pagoEfectivo
            quita
            dacionPago
            quebrantoCastigo
            historia
            atrasoMayor
            registroImpugnado
          }
          cuentasComerciales {
            RFC
            nombreOtorgante
            moneda
            vigente
            saldoTotal
            veintiNueveDias
            cincuentYNuevaDias
            ochentaYNueveDias
            cientoDiecinueveDias
            cientoSetentaYNueveDias
            cientoOchentaMasDias
            actualizacion
            historia
            atrasoMayor
          }
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSTPRegistraOrden = /* GraphQL */ `
  query GetSTPRegistraOrden($registraOrdenId: ID!) {
    getSTPRegistraOrden(registraOrdenId: $registraOrdenId) {
      registraOrdenId
      applicantId
      investorId
      registraOrden
      ordenPago
      claveRastreo
      conceptoPago
      cuentaBeneficiario
      cuentaOrdenante
      empresa
      fechaOperacion
      folioOrigen
      institucionContraparte
      institucionOperante
      monto
      nombreBeneficiario
      nombreOrdenante
      referenciaNumerica
      rfcCurpBeneficiario
      rfcCurpOrdenante
      tipoCuentaBeneficiario
      tipoCuentaOrdenante
      tipoPago
      createdAt
      updatedAt
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      owner
    }
  }
`;
export const listSTPRegistraOrdens = /* GraphQL */ `
  query ListSTPRegistraOrdens(
    $registraOrdenId: ID
    $filter: ModelSTPRegistraOrdenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSTPRegistraOrdens(
      registraOrdenId: $registraOrdenId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        registraOrdenId
        applicantId
        investorId
        registraOrden
        ordenPago
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        fechaOperacion
        folioOrigen
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const getSimafPerfil = /* GraphQL */ `
  query GetSimafPerfil($id: ID!) {
    getSimafPerfil(id: $id) {
      id
      applicantId
      investorId
      simaf_cuentaId
      perfilDeclarado {
        frecuencia
        monto
      }
      perfilOperado {
        frecuencia
        monto
      }
      moneda
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      simaf_cuenta {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSimafPerfils = /* GraphQL */ `
  query ListSimafPerfils($filter: ModelSimafPerfilFilterInput, $limit: Int, $nextToken: String) {
    listSimafPerfils(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        simaf_cuentaId
        perfilDeclarado {
          frecuencia
          monto
        }
        perfilOperado {
          frecuencia
          monto
        }
        moneda
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        simaf_cuenta {
          id
          applicantId
          investorId
          cuenta_asociada_institucion
          institucion_financiera
          nacionalidad_cuenta_asociada
          nivel_cuenta
          numero_cuenta_proyecto
          regimen
          saldo
          tipo_cuenta
          tipo_financiamiento
          tipo_producto
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSimafCuenta = /* GraphQL */ `
  query GetSimafCuenta($id: ID!) {
    getSimafCuenta(id: $id) {
      id
      applicantId
      investorId
      cuenta_asociada_institucion
      institucion_financiera
      nacionalidad_cuenta_asociada
      nivel_cuenta
      numero_cuenta_proyecto
      regimen
      saldo
      tipo_cuenta
      tipo_financiamiento
      tipo_producto
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSimafCuentas = /* GraphQL */ `
  query ListSimafCuentas($filter: ModelSimafCuentaFilterInput, $limit: Int, $nextToken: String) {
    listSimafCuentas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSimafOperacion = /* GraphQL */ `
  query GetSimafOperacion($id: ID!) {
    getSimafOperacion(id: $id) {
      id
      applicantId
      investorId
      cuenta_destino
      cuenta_origen
      geolocalizacion
      instrumento_monetario
      moneda
      timestamp
      tipo_operacion
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSimafOperacions = /* GraphQL */ `
  query ListSimafOperacions(
    $filter: ModelSimafOperacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSimafOperacions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        cuenta_destino
        cuenta_origen
        geolocalizacion
        instrumento_monetario
        moneda
        timestamp
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getSimafCuestionario = /* GraphQL */ `
  query GetSimafCuestionario($id: ID!) {
    getSimafCuestionario(id: $id) {
      id
      applicantId
      investorId
      origen_recursos
      destino_recursos
      proposito
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listSimafCuestionarios = /* GraphQL */ `
  query ListSimafCuestionarios(
    $filter: ModelSimafCuestionarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSimafCuestionarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        origen_recursos
        destino_recursos
        proposito
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getTrully = /* GraphQL */ `
  query GetTrully($id: ID!) {
    getTrully(id: $id) {
      id
      applicantId
      investorId
      fraud
      label
      raw_data {
        document_ocr {
          document_id
          document_ocr {
            bbox_face
            detected
            doc_type
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          image_quality {
            quality_score
          }
        }
        enrichment {
          curp {
            age
            curp
            date_of_birth
            gender
            government_name
            government_valid
            is_mexican
            state_iso
            state_of_birth
          }
          external_fraud_service {
            carrier
            city
            country
            dialing_code
            do_not_call
            formatted
            fraud_score
            leaked
            line_type
            local_format
            mcc
            message
            mnc
            name
            prepaid
            recent_abuse
            region
            risky
            sms_domain
            sms_email
            spammer
            success
            timezone
            valid
            VOIP
            zip_code
          }
          ip {
            ip
          }
          phone_number {
            fraud_report
            phone
            active
            active_status
          }
          whatsapp {
            is_valid
            on_whatsapp
          }
        }
        face_comparison {
          match
        }
        face_recognition {
          face_attributes {
            beard
            emotion
            eyeglasses
            gender
            maximum_age
            minimum_age
            mustache
            smile
            sunglasses
          }
          face_id
          inquiry_date
          match
          confidence
          first_seen
          fraud_detail
          last_seen
          match_fraud_flag
          times_seen_last_month
        }
        identity_fraud_score {
          probability
        }
        listed_weeks {
          employment_summary {
            age_first_job
            average_working_days
            discounted_weeks
            employed_last_3_years
            imss_active
            issste_weeks
            listed_weeks
            longest_job_period
            max_base_salary
            min_base_salary
            nss
            projected_monthly_salary
            reinstated_weeks
            shortest_job_period
            total_active_jobs
            total_employers
            total_jobs
          }
          salary_summary {
            active_job
            avg_base_salary
            contribution_base_salary
            contribution_base_salary_duration
            discharge_date
            employer_id
            employer_name
            employer_state
            job_duration_days
            max_base_salary
            min_base_salary
            registration_date
            salary_difference
            salary_movements
          }
        }
      }
      reason
      request_id
      applicant {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      investor {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listTrullies = /* GraphQL */ `
  query ListTrullies($filter: ModelTrullyFilterInput, $limit: Int, $nextToken: String) {
    listTrullies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const resourcesProvidersByApplicantId = /* GraphQL */ `
  query ResourcesProvidersByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourcesProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesProvidersByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const resourcesProvidersByInvestorId = /* GraphQL */ `
  query ResourcesProvidersByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResourcesProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resourcesProvidersByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const beneficiariesByInvestorId = /* GraphQL */ `
  query BeneficiariesByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBeneficiaryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    beneficiariesByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investorId
        firstName
        lastNameM
        lastNameP
        relationship
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        percentage
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorBeneficiariesId
        owner
      }
      nextToken
    }
  }
`;
export const investorByTypeOfPerson = /* GraphQL */ `
  query InvestorByTypeOfPerson(
    $typeOfPerson: TypeOfPerson!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByTypeOfPerson(
      typeOfPerson: $typeOfPerson
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByIsInNewsletter = /* GraphQL */ `
  query InvestorByIsInNewsletter(
    $isInNewsletter: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByIsInNewsletter(
      isInNewsletter: $isInNewsletter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByIsInMarketing = /* GraphQL */ `
  query InvestorByIsInMarketing(
    $isInMarketing: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByIsInMarketing(
      isInMarketing: $isInMarketing
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByIsPLDApproved = /* GraphQL */ `
  query InvestorByIsPLDApproved(
    $isPLDApproved: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByIsPLDApproved(
      isPLDApproved: $isPLDApproved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByIsSIMAFSended = /* GraphQL */ `
  query InvestorByIsSIMAFSended(
    $isSIMAFSended: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByIsSIMAFSended(
      isSIMAFSended: $isSIMAFSended
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByIsAccountCanceled = /* GraphQL */ `
  query InvestorByIsAccountCanceled(
    $isAccountCanceled: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByIsAccountCanceled(
      isAccountCanceled: $isAccountCanceled
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const investorByStatusSIMAF = /* GraphQL */ `
  query InvestorByStatusSIMAF(
    $statusSIMAF: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investorByStatusSIMAF(
      statusSIMAF: $statusSIMAF
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        typeOfPerson
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        beneficiaries {
          items {
            id
            investorId
            firstName
            lastNameM
            lastNameP
            relationship
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            percentage
            createdAt
            updatedAt
            investorBeneficiariesId
            owner
          }
          nextToken
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        risk {
          investorId
          loss
          liquidity
          information
          performance
          notApproved
          notAdvice
          investment
          disclosedInformation
          isSigned
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        investments {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        createdAt
        updatedAt
        isInNewsletter
        isInMarketing
        isPLDApproved
        isSIMAFSended
        economicActivities {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        isRisksSigned
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const obligadoSolidariosByApplicantId = /* GraphQL */ `
  query ObligadoSolidariosByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelObligadoSolidarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    obligadoSolidariosByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        dateOfBirth
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        percentage
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        applicantObligadoSolidarioId
        owner
      }
      nextToken
    }
  }
`;
export const investmentsByInvestorId = /* GraphQL */ `
  query InvestmentsByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investmentsByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investorId
        campaignId
        compromisoInversion
        interesInversionistaPorcentaje
        interesInversionistaPesos
        retencionImpuestoInversionistaPorcentaje
        retencionImpuestoInversionistaPesos
        status
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        porcentajeGananciaInteres
        porcentajeGananciaInteresFinal
        createdAt
        updatedAt
        investorInvestmentsId
        owner
      }
      nextToken
    }
  }
`;
export const investmentsByCampaignId = /* GraphQL */ `
  query InvestmentsByCampaignId(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvestmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    investmentsByCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        investorId
        campaignId
        compromisoInversion
        interesInversionistaPorcentaje
        interesInversionistaPesos
        retencionImpuestoInversionistaPorcentaje
        retencionImpuestoInversionistaPesos
        status
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        porcentajeGananciaInteres
        porcentajeGananciaInteresFinal
        createdAt
        updatedAt
        investorInvestmentsId
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsApproved = /* GraphQL */ `
  query ApplicantByIsApproved(
    $isApproved: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsApproved(
      isApproved: $isApproved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsBureauConsultAuthorized = /* GraphQL */ `
  query ApplicantByIsBureauConsultAuthorized(
    $isBureauConsultAuthorized: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsBureauConsultAuthorized(
      isBureauConsultAuthorized: $isBureauConsultAuthorized
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsInNewsletter = /* GraphQL */ `
  query ApplicantByIsInNewsletter(
    $isInNewsletter: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsInNewsletter(
      isInNewsletter: $isInNewsletter
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsPLDApproved = /* GraphQL */ `
  query ApplicantByIsPLDApproved(
    $isPLDApproved: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsPLDApproved(
      isPLDApproved: $isPLDApproved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsInMarketing = /* GraphQL */ `
  query ApplicantByIsInMarketing(
    $isInMarketing: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsInMarketing(
      isInMarketing: $isInMarketing
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsSIMAFSended = /* GraphQL */ `
  query ApplicantByIsSIMAFSended(
    $isSIMAFSended: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsSIMAFSended(
      isSIMAFSended: $isSIMAFSended
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByIsAccountCanceled = /* GraphQL */ `
  query ApplicantByIsAccountCanceled(
    $isAccountCanceled: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByIsAccountCanceled(
      isAccountCanceled: $isAccountCanceled
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const applicantByStatusSIMAF = /* GraphQL */ `
  query ApplicantByStatusSIMAF(
    $statusSIMAF: String!
    $sortDirection: ModelSortDirection
    $filter: ModelApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByStatusSIMAF(
      statusSIMAF: $statusSIMAF
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        personalInformation {
          firstName
          fullName
          lastNameP
          lastNameM
          surname
          profileImage
          email
          age
          underage
          gender
          RFC
          CURP
          maritalStatus
          occupation
          nationality
          simaf_nationalityId
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          dateOfBirth
          countryOfBirth
          stateOfBirth
          isPEP
          pepCharge
          pepName
          pepDate
          isFamilyPEP
          generalActivity
          specificActivity
          simaf_specificActivityId
        }
        companyInformation {
          companyName {
            entityType
            legalName
            tradeName
          }
          email
          denomination
          profileImage
          RFC
          generalActivity
          specificActivity
          businessTurn
          businessSector
          isBusinessGroup
          societyType
          creationDate
          startedOperationsAt
          country
          phone {
            land
            mobile
            isVerified
            lada
            ext
          }
          numeroDeSerie
          obligadoSolidario
          employees
          activity
          webSite
          invoicesValue
          businessAge
          FEA
          CIEC
          owners
          activoCirculante
          activoFijo
          activoTotal
          pasivo
          pasivoCortoPlazo
          pasivoLargoPlazo
          capital
          ventas
          costos
          gastos
          utilidadNeta
          simaf_specificActivityId
        }
        interestRateFinsphera
        defaultInterestRateInvestor
        interestRateInvestor
        score
        paymentScore
        delayDays
        isApproved
        isBureauConsultAuthorized
        reasonOfRejection
        ipAddress {
          IPv4
          country_code
          country_name
          city
          state
          date
        }
        geolocation {
          accuracy
          latitude
          longitude
          date
        }
        mati {
          stepId
          resource
          identityId
          verificationId
          eventName
          isFinished
          videoUrl
          spriteUrl
          selfieUrl {
            media
            isUrl
          }
          documentType
          score
          ageThreshold
          isAlterationDetection
          deviceFingerPrint {
            ua
            ip
            vpnDetectionEnabled
            browserName
            browserVersion
            browserMajor
            engineName
            engineVersion
            osName
            osVersion
            appPlatform
            appVersion
          }
          identityStatus
          age
          isDocumentExpired
          matiDashboardUrl
          finalStatus
        }
        isInNewsletter
        isPLDApproved
        isInMarketing
        isSIMAFSended
        requestedAmount
        termLoan
        loanDestination
        createdAt
        updatedAt
        creditBureau {
          applicantId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        creditAnalysis {
          applicantId
          profitability {
            profitabilityValue
            profitabilityPoints
            compoundAnnualGrowthInSalesValue
            compoundAnnualGrowthInSalesPoints
            compoundAnnualGrowthInNetEarningsValue
            compoundAnnualGrowthInNetEarningsPoints
            rating
            isApproved
          }
          liquidity {
            creditLine
            creditBalance
            borrowingCapacityValue
            borrowingCapacityPoints
            liquidityRatioValue
            liquidityRatioPoints
            acidTestValue
            acidTestPoints
            immediatePaymentAbilityValue
            immediatePaymentAbilityPoints
            maximumPaymentTermValue
            maximumPaymentTermPoints
            rating
            isApproved
          }
          solvency {
            taxComplianceChecksValue
            taxComplianceChecksPoints
            taxRegimeValue
            taxRegimePoints
            allClients
            majorCorporateClients
            majorCorporateClientsValue
            majorCorporateClientsPoints
            annualGrowthInClientsValue
            annualGrowthInClientsPoints
            salesConcentrationValue
            salesConcentrationPoints
            ROEValue
            ROEPoints
            ROAValue
            ROAPoints
            rating
            isApproved
          }
          creditAccess {
            financialInstitutionGrantsCreditValue
            financialInstitutionGrantsCreditPoints
            creditBureauRatingValue
            creditBureauRatingPoints
            paymentHistoryValue
            paymentHistoryPoints
            numberOfCreditGrantorsValue
            numberOfCreditGrantorsPoints
            inquiriesInCreditBureauValue
            inquiriesInCreditBureauPoints
            rating
            isApproved
          }
          marketAndProduct {
            dayOfBirth
            businessAgeValue
            businessAgePoints
            mainActivityValue
            mainActivityPoints
            statusInTaxpayerRegistryValue
            statusInTaxpayerRegistryPoints
            capitalRegimeValue
            capitalRegimePoints
            federalEntityValue
            federalEntityPoints
            rating
            isApproved
          }
          corporateGovernance {
            experienceOfMainShareholderValue
            experienceOfMainShareholderPoints
            independetConselorsValue
            independetConselorsPoints
            independenceDirectorGeneralValue
            independenceDirectorGeneralPoints
            numberOfCommitteesValue
            numberOfCommitteesPoints
            rating
            isApproved
          }
          genderEquality {
            numberFemaleShareholdersValue
            numberFemaleShareholdersPoints
            numberFemaleDirectorsOnBoardValue
            numberFemaleDirectorsOnBoardPoints
            isCEOFemaleValue
            isCEOFemalePoints
            numberFemaleWithManagerialPositionValue
            numberFemaleWithManagerialPositionPoints
            numberFemaleEmployeesValue
            numberFemaleEmployeesPoints
            rating
            isApproved
          }
          environmentalAndSocialImpact {
            rating
            isApproved
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          isApproved
          rating
          interestRateApplicant
          hasAnotherCredit
          owner
        }
        file {
          id
          address
          FEA
          INE {
            s3Front
            s3Back
            matiFrontUrl
            matiBackUrl
            emmissionDate
            expirationDate
            documentNumber
            ocrNumber
            documentType
            issueCountry
            simafContryCode
            ne
          }
          taxID
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          areBusinessDocumentsUpload
          businessDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          odsDocuments {
            name
            isUploaded
            url
            literaxEmision
            isObligadoSolidario
            type
          }
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          owner
        }
        STP {
          id
          CLABE
          cambioEstado {
            id
            empresa
            folioOrigen
            estado
            causaDevolucion
            tsLiquidacion
            createdAt
            updatedAt
          }
          collection {
            id
            abono
            fechaOperacion
            institucionOrdenante
            institucionBeneficiaria
            claveRastreo
            monto
            nombreOrdenante
            tipoCuentaOrdenante
            cuentaOrdenante
            rfcCurpOrdenante
            nombreBeneficiario
            tipoCuentaBeneficiario
            cuentaBeneficiario
            nombreBeneficiario2
            tipoCuentaBeneficiario2
            cuentaBeneficiario2
            rfcCurpBeneficiario
            conceptoPago
            referenciaNumerica
            empresa
            tipoPago
            tsLiquidacion
            folioCodi
            createdAt
            updatedAt
          }
          registraOrden {
            claveRastreo
            conceptoPago
            cuentaBeneficiario
            cuentaOrdenante
            empresa
            firma
            institucionContraparte
            institucionOperante
            monto
            nombreBeneficiario
            nombreOrdenante
            referenciaNumerica
            registraOrdenId
            rfcCurpBeneficiario
            rfcCurpOrdenante
            tipoCuentaBeneficiario
            tipoCuentaOrdenante
            tipoPago
            createdAt
            updatedAt
            ordenPago
            fechaOperacion
            folioOrigen
          }
          recursosDisponibles
          compromisoInversion
          status
          inversionesVigentes
          saldoTotal
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        address {
          items {
            id
            applicantId
            investorId
            fullAddress
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            streetType
            streetReferences
            statusRaw
            numInt
            numExt
            typeOfAddress
            createdAt
            updatedAt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            type
            investorAddressId
            applicantAddressId
            owner
          }
          nextToken
        }
        mifiels {
          items {
            id
            applicantId
            investorId
            campaignId
            type
            documentId
            send_mail
            signed_hash
            name
            signed_by_all
            signed
            signed_at
            created_at
            burned_at
            external_id
            remind_every
            expires_at
            days_to_expire
            created_by
            state
            original_hash
            manual_close
            encrypted
            allow_business
            file_file_name
            archived
            krs
            callback_url
            sign_callback_url
            file
            file_download
            file_signed
            file_signed_download
            file_zipped
            file_xml
            createdAt
            updatedAt
            investorMifielsId
            applicantMifielsId
          }
          nextToken
        }
        economicActivity {
          id
          economicActivity
          specificActivity
          importToMexico
          exportFromMexico
          useCoinedMetals
          relationsWithCountries
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        banks {
          items {
            CLABE
            applicantId
            investorId
            isMFA
            identifier
            isMainAccount
            messageId
            bankName
            bankCode
            status
            numberOfCard
            account
            createdAt
            updatedAt
            investorBanksId
            applicantBanksId
            owner
          }
          nextToken
        }
        campaigns {
          items {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            signDate
            plazoCredito
            paymentDate
            areInvoicesUsed
            isMoneySent
            termCampaing
            status
            destinoCredito
            fuentePago
            createdAt
            updatedAt
            TIIE
            tasaInteresAdicionalPorcentaje
            tasaCreditoPorcentaje
            interesCreditoPesos
            IVAinteresCreditoPesos
            interesesCreditoPesosConIVA
            interesMoratorioPesos
            IVAinteresMoratorio
            interesesMoratorioPesosConIVA
            comisionCreditoPesos
            IVAcomisionCreditoPesos
            comisionCreditoPesosConIVA
            inversionFinspheraCampaniaPorcentaje
            inversionFinspheraCampaniaPesos
            tasaInteresMoratorioPorcentaje
            montoTransferencia
            plazoCreditoFinal
            paymentDateFinal
            interesCreditoPesosFinal
            IVAinteresCreditoPesosFinal
            interesesCreditoPesosConIVAFinal
            comisionCreditoPesosFinal
            IVAcomisionCreditoPesosFinal
            comisionCreditoPesosConIVAFinal
            applicantCampaignsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        onboarding {
          complete
          currentStep
        }
        isContractSigned
        distributionChannel
        industryActivity
        verificationCode {
          code
          codeReceived
          messageId
          timestamp
          timestampReceived
          typeMessage
          isValid
        }
        navigationPage {
          action
          timestamp
          route
        }
        literaxs {
          items {
            idExterno
            applicantId
            investorId
            estado
            descripcion
            constancia
            serie
            timeStamp
            huellaDigital
            politicaPSC
            firmaPSC
            archivoConstancia
            createdAt
            updatedAt
            investorLiteraxsId
            applicantLiteraxsId
            owner
          }
          nextToken
        }
        customerNumberEfysis
        savingsAccountNumber
        trully {
          id
          applicantId
          investorId
          fraud
          label
          reason
          request_id
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        isAccountCanceled
        statusSIMAF
        obligadoSolidario {
          items {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          nextToken
        }
        resourcesProvider {
          id
          applicantId
          investorId
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const banksByApplicantId = /* GraphQL */ `
  query BanksByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    banksByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CLABE
        applicantId
        investorId
        isMFA
        identifier
        isMainAccount
        messageId
        bankName
        bankCode
        status
        numberOfCard
        account
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorBanksId
        applicantBanksId
        owner
      }
      nextToken
    }
  }
`;
export const banksByInvestorId = /* GraphQL */ `
  query BanksByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    banksByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        CLABE
        applicantId
        investorId
        isMFA
        identifier
        isMainAccount
        messageId
        bankName
        bankCode
        status
        numberOfCard
        account
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorBanksId
        applicantBanksId
        owner
      }
      nextToken
    }
  }
`;
export const campaignsById = /* GraphQL */ `
  query CampaignsById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignsByApplicantId = /* GraphQL */ `
  query CampaignsByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignsByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignByIsActive = /* GraphQL */ `
  query CampaignByIsActive(
    $isActive: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByIsActive(
      isActive: $isActive
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignByIsExpired = /* GraphQL */ `
  query CampaignByIsExpired(
    $isExpired: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByIsExpired(
      isExpired: $isExpired
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignByIsSuccessful = /* GraphQL */ `
  query CampaignByIsSuccessful(
    $isSuccessful: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByIsSuccessful(
      isSuccessful: $isSuccessful
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignByIsPromissoryNoteSigned = /* GraphQL */ `
  query CampaignByIsPromissoryNoteSigned(
    $isPromissoryNoteSigned: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByIsPromissoryNoteSigned(
      isPromissoryNoteSigned: $isPromissoryNoteSigned
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const campaignByStatus = /* GraphQL */ `
  query CampaignByStatus(
    $status: Status!
    $sortDirection: ModelSortDirection
    $filter: ModelCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    campaignByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        paymentDate
        areInvoicesUsed
        isMoneySent
        termCampaing
        status
        destinoCredito
        fuentePago
        investment {
          items {
            id
            investorId
            campaignId
            compromisoInversion
            interesInversionistaPorcentaje
            interesInversionistaPesos
            retencionImpuestoInversionistaPorcentaje
            retencionImpuestoInversionistaPesos
            status
            porcentajeGananciaInteres
            porcentajeGananciaInteresFinal
            createdAt
            updatedAt
            investorInvestmentsId
            owner
          }
          nextToken
        }
        invoices {
          items {
            id
            applicantId
            campaignId
            number
            name
            type
            isVerified
            isApproved
            createdAt
            updatedAt
            isAvailable
            applicantInvoicesId
            owner
          }
          nextToken
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        TIIE
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        interesMoratorioPesos
        IVAinteresMoratorio
        interesesMoratorioPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        montoTransferencia
        plazoCreditoFinal
        paymentDateFinal
        interesCreditoPesosFinal
        IVAinteresCreditoPesosFinal
        interesesCreditoPesosConIVAFinal
        comisionCreditoPesosFinal
        IVAcomisionCreditoPesosFinal
        comisionCreditoPesosConIVAFinal
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        applicantCampaignsId
        owner
      }
      nextToken
    }
  }
`;
export const efisysCampaignsByApplicantId = /* GraphQL */ `
  query EfisysCampaignsByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEfisysCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    efisysCampaignsByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        creditRequestNumber
        applicantId
        campaignId
        creditRequest {
          test
        }
        investmentRequest {
          test
        }
        cancelInvestment {
          test
        }
        creditNumber
        status
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        deletedAt
        owner
      }
      nextToken
    }
  }
`;
export const efisysCampaignsByCampaignId = /* GraphQL */ `
  query EfisysCampaignsByCampaignId(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEfisysCampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    efisysCampaignsByCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        creditRequestNumber
        applicantId
        campaignId
        creditRequest {
          test
        }
        investmentRequest {
          test
        }
        cancelInvestment {
          test
        }
        creditNumber
        status
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        deletedAt
        owner
      }
      nextToken
    }
  }
`;
export const invoicesByApplicantId = /* GraphQL */ `
  query InvoicesByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const invoicesByCampaignId = /* GraphQL */ `
  query InvoicesByCampaignId(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoicesByCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const invoiceByIsVerified = /* GraphQL */ `
  query InvoiceByIsVerified(
    $isVerified: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByIsVerified(
      isVerified: $isVerified
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const invoiceByIsApproved = /* GraphQL */ `
  query InvoiceByIsApproved(
    $isApproved: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByIsApproved(
      isApproved: $isApproved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const invoiceByIsAvailable = /* GraphQL */ `
  query InvoiceByIsAvailable(
    $isAvailable: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invoiceByIsAvailable(
      isAvailable: $isAvailable
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        campaignId
        comprobante {
          lugarExpedicion
          metodoPago
          tipoDeComprobante
          total
          moneda
          subTotal
          condicionesDePago
          noCertificado
          formaPago
          sello
          fecha
          folio
          version
          emisor {
            RFC
            nombre
            regimenFiscal
          }
          receptor {
            RFC
            nombre
            usoCFDI
          }
          impuestos {
            totalImpuestosTrasladados
          }
        }
        number
        name
        type
        isVerified
        isApproved
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        isAvailable
        applicantInvoicesId
        owner
      }
      nextToken
    }
  }
`;
export const creditAnalysisByIsApproved = /* GraphQL */ `
  query CreditAnalysisByIsApproved(
    $isApproved: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creditAnalysisByIsApproved(
      isApproved: $isApproved
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      nextToken
    }
  }
`;
export const applicantByHasAnotherCredit = /* GraphQL */ `
  query ApplicantByHasAnotherCredit(
    $hasAnotherCredit: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCreditAnalysisFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantByHasAnotherCredit(
      hasAnotherCredit: $hasAnotherCredit
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        applicantId
        profitability {
          profitabilityValue
          profitabilityPoints
          compoundAnnualGrowthInSalesValue
          compoundAnnualGrowthInSalesPoints
          compoundAnnualGrowthInNetEarningsValue
          compoundAnnualGrowthInNetEarningsPoints
          rating
          isApproved
        }
        liquidity {
          creditLine
          creditBalance
          borrowingCapacityValue
          borrowingCapacityPoints
          liquidityRatioValue
          liquidityRatioPoints
          acidTestValue
          acidTestPoints
          immediatePaymentAbilityValue
          immediatePaymentAbilityPoints
          maximumPaymentTermValue
          maximumPaymentTermPoints
          rating
          isApproved
        }
        solvency {
          taxComplianceChecksValue
          taxComplianceChecksPoints
          taxRegimeValue
          taxRegimePoints
          allClients
          majorCorporateClients
          majorCorporateClientsValue
          majorCorporateClientsPoints
          annualGrowthInClientsValue
          annualGrowthInClientsPoints
          salesConcentrationValue
          salesConcentrationPoints
          ROEValue
          ROEPoints
          ROAValue
          ROAPoints
          rating
          isApproved
        }
        creditAccess {
          financialInstitutionGrantsCreditValue
          financialInstitutionGrantsCreditPoints
          creditBureauRatingValue
          creditBureauRatingPoints
          paymentHistoryValue
          paymentHistoryPoints
          numberOfCreditGrantorsValue
          numberOfCreditGrantorsPoints
          inquiriesInCreditBureauValue
          inquiriesInCreditBureauPoints
          rating
          isApproved
        }
        marketAndProduct {
          dayOfBirth
          businessAgeValue
          businessAgePoints
          mainActivityValue
          mainActivityPoints
          statusInTaxpayerRegistryValue
          statusInTaxpayerRegistryPoints
          capitalRegimeValue
          capitalRegimePoints
          federalEntityValue
          federalEntityPoints
          rating
          isApproved
        }
        corporateGovernance {
          experienceOfMainShareholderValue
          experienceOfMainShareholderPoints
          independetConselorsValue
          independetConselorsPoints
          independenceDirectorGeneralValue
          independenceDirectorGeneralPoints
          numberOfCommitteesValue
          numberOfCommitteesPoints
          rating
          isApproved
        }
        genderEquality {
          numberFemaleShareholdersValue
          numberFemaleShareholdersPoints
          numberFemaleDirectorsOnBoardValue
          numberFemaleDirectorsOnBoardPoints
          isCEOFemaleValue
          isCEOFemalePoints
          numberFemaleWithManagerialPositionValue
          numberFemaleWithManagerialPositionPoints
          numberFemaleEmployeesValue
          numberFemaleEmployeesPoints
          rating
          isApproved
        }
        environmentalAndSocialImpact {
          finPobreza {
            isApproved
          }
          hambreCero {
            isApproved
          }
          saludBienestar {
            isApproved
          }
          educacionCalidad {
            isApproved
          }
          igualdadGenero {
            isApproved
          }
          aguaLimpia {
            isApproved
          }
          energiaAsequible {
            isApproved
          }
          trabajoDecente {
            isApproved
          }
          industriaInnovacion {
            isApproved
          }
          reduccionDesigualdades {
            isApproved
          }
          ciudadesComunidades {
            isApproved
          }
          produccionConsumo {
            isApproved
          }
          accionClima {
            isApproved
          }
          vidaSubmarina {
            isApproved
          }
          vidaEcosistema {
            isApproved
          }
          pazJusticiaInstituciones {
            isApproved
          }
          alianzaOds {
            isApproved
          }
          rating
          isApproved
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        isApproved
        rating
        interestRateApplicant
        hasAnotherCredit
        owner
      }
      nextToken
    }
  }
`;
export const addressesByApplicantId = /* GraphQL */ `
  query AddressesByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        fullAddress
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        streetType
        streetReferences
        statusRaw
        numInt
        numExt
        typeOfAddress
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        type
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        investorAddressId
        applicantAddressId
        owner
      }
      nextToken
    }
  }
`;
export const addressesByInvestorId = /* GraphQL */ `
  query AddressesByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressesByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        fullAddress
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        streetType
        streetReferences
        statusRaw
        numInt
        numExt
        typeOfAddress
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        simaf_municipioId
        simaf_neighborhoodId
        simaf_postalCodeId
        simaf_stateId
        type
        obligadoSolidario {
          id
          applicantId
          firstName
          lastNameM
          lastNameP
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          simaf_municipioId
          simaf_neighborhoodId
          simaf_postalCodeId
          simaf_stateId
          percentage
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          applicantObligadoSolidarioId
          owner
        }
        investorAddressId
        applicantAddressId
        owner
      }
      nextToken
    }
  }
`;
export const sTPByCLABE = /* GraphQL */ `
  query STPByCLABE(
    $CLABE: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSTPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    STPByCLABE(
      CLABE: $CLABE
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        CLABE
        cambioEstado {
          id
          empresa
          folioOrigen
          estado
          causaDevolucion
          tsLiquidacion
          createdAt
          updatedAt
        }
        collection {
          id
          abono
          fechaOperacion
          institucionOrdenante
          institucionBeneficiaria
          claveRastreo
          monto
          nombreOrdenante
          tipoCuentaOrdenante
          cuentaOrdenante
          rfcCurpOrdenante
          nombreBeneficiario
          tipoCuentaBeneficiario
          cuentaBeneficiario
          nombreBeneficiario2
          tipoCuentaBeneficiario2
          cuentaBeneficiario2
          rfcCurpBeneficiario
          conceptoPago
          referenciaNumerica
          empresa
          tipoPago
          tsLiquidacion
          folioCodi
          createdAt
          updatedAt
        }
        registraOrden {
          claveRastreo
          conceptoPago
          cuentaBeneficiario
          cuentaOrdenante
          empresa
          firma
          institucionContraparte
          institucionOperante
          monto
          nombreBeneficiario
          nombreOrdenante
          referenciaNumerica
          registraOrdenId
          rfcCurpBeneficiario
          rfcCurpOrdenante
          tipoCuentaBeneficiario
          tipoCuentaOrdenante
          tipoPago
          createdAt
          updatedAt
          ordenPago
          fechaOperacion
          folioOrigen
        }
        recursosDisponibles
        compromisoInversion
        status
        inversionesVigentes
        saldoTotal
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const sTPNotificationsBySTPId = /* GraphQL */ `
  query STPNotificationsBySTPId(
    $STPId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSTPNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sTPNotificationsBySTPId(
      STPId: $STPId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        registraOrdenId
        STPId
        STP {
          items {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const literaxesByApplicantId = /* GraphQL */ `
  query LiteraxesByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLiteraxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    literaxesByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        idExterno
        applicantId
        investorId
        estado
        descripcion
        constancia
        serie
        timeStamp
        huellaDigital
        politicaPSC
        firmaPSC
        archivoConstancia
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorLiteraxsId
        applicantLiteraxsId
        owner
      }
      nextToken
    }
  }
`;
export const literaxesByInvestorId = /* GraphQL */ `
  query LiteraxesByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelLiteraxFilterInput
    $limit: Int
    $nextToken: String
  ) {
    literaxesByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        idExterno
        applicantId
        investorId
        estado
        descripcion
        constancia
        serie
        timeStamp
        huellaDigital
        politicaPSC
        firmaPSC
        archivoConstancia
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        investorLiteraxsId
        applicantLiteraxsId
        owner
      }
      nextToken
    }
  }
`;
export const mifielsByApplicantId = /* GraphQL */ `
  query MifielsByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMifielFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mifielsByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        campaignId
        type
        documentId
        send_mail
        signed_hash
        name
        signed_by_all
        signed
        signed_at
        created_at
        burned_at
        status {
          status
        }
        external_id
        remind_every
        expires_at
        days_to_expire
        created_by
        state
        original_hash
        manual_close
        encrypted
        allow_business
        file_file_name
        archived
        krs
        owner {
          id
          email
          name
          is_company
        }
        creator {
          id
          email
          name
        }
        callback_url
        sign_callback_url
        already_signed {
          mifiel_already_signed
        }
        has_not_signed {
          mifiel_has_not_signed
        }
        permissions {
          unknown_1
          unknown_2
          unknown_3
        }
        file
        file_download
        file_signed
        file_signed_download
        file_zipped
        file_xml
        signers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        viewers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        signatures {
          id
          name
          email
          signed
          signed_at
          certificate_number
          tax_id
          signature
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        investorMifielsId
        applicantMifielsId
      }
      nextToken
    }
  }
`;
export const mifielsByInvestorId = /* GraphQL */ `
  query MifielsByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMifielFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mifielsByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        campaignId
        type
        documentId
        send_mail
        signed_hash
        name
        signed_by_all
        signed
        signed_at
        created_at
        burned_at
        status {
          status
        }
        external_id
        remind_every
        expires_at
        days_to_expire
        created_by
        state
        original_hash
        manual_close
        encrypted
        allow_business
        file_file_name
        archived
        krs
        owner {
          id
          email
          name
          is_company
        }
        creator {
          id
          email
          name
        }
        callback_url
        sign_callback_url
        already_signed {
          mifiel_already_signed
        }
        has_not_signed {
          mifiel_has_not_signed
        }
        permissions {
          unknown_1
          unknown_2
          unknown_3
        }
        file
        file_download
        file_signed
        file_signed_download
        file_zipped
        file_xml
        signers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        viewers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        signatures {
          id
          name
          email
          signed
          signed_at
          certificate_number
          tax_id
          signature
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        investorMifielsId
        applicantMifielsId
      }
      nextToken
    }
  }
`;
export const mifielsByCampaignId = /* GraphQL */ `
  query MifielsByCampaignId(
    $campaignId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMifielFilterInput
    $limit: Int
    $nextToken: String
  ) {
    mifielsByCampaignId(
      campaignId: $campaignId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        campaignId
        type
        documentId
        send_mail
        signed_hash
        name
        signed_by_all
        signed
        signed_at
        created_at
        burned_at
        status {
          status
        }
        external_id
        remind_every
        expires_at
        days_to_expire
        created_by
        state
        original_hash
        manual_close
        encrypted
        allow_business
        file_file_name
        archived
        krs
        owner {
          id
          email
          name
          is_company
        }
        creator {
          id
          email
          name
        }
        callback_url
        sign_callback_url
        already_signed {
          mifiel_already_signed
        }
        has_not_signed {
          mifiel_has_not_signed
        }
        permissions {
          unknown_1
          unknown_2
          unknown_3
        }
        file
        file_download
        file_signed
        file_signed_download
        file_zipped
        file_xml
        signers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        viewers {
          id
          name
          email
          tax_id
          role
          rejections
          status
          field
          signed
          widget_id
          blacklisted_algs {
            blacklisted_algs
          }
          current
          last_reminded_at
          features {
            test
          }
          sat_auth
        }
        signatures {
          id
          name
          email
          signed
          signed_at
          certificate_number
          tax_id
          signature
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        campaign {
          id
          applicantId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          areInvoicesUsed
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          investment {
            nextToken
          }
          invoices {
            nextToken
          }
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
          interesesMoratorioPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          obligadoSolidario {
            id
            applicantId
            firstName
            lastNameM
            lastNameP
            dateOfBirth
            country
            postalCode
            city
            state
            district
            neighborhood
            street
            numInt
            numExt
            simaf_municipioId
            simaf_neighborhoodId
            simaf_postalCodeId
            simaf_stateId
            percentage
            createdAt
            updatedAt
            applicantObligadoSolidarioId
            owner
          }
          applicantCampaignsId
          owner
        }
        createdAt
        updatedAt
        investorMifielsId
        applicantMifielsId
      }
      nextToken
    }
  }
`;
export const sTPRegistraOrdensByApplicantId = /* GraphQL */ `
  query STPRegistraOrdensByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSTPRegistraOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sTPRegistraOrdensByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        registraOrdenId
        applicantId
        investorId
        registraOrden
        ordenPago
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        fechaOperacion
        folioOrigen
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const sTPRegistraOrdensByInvestorId = /* GraphQL */ `
  query STPRegistraOrdensByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSTPRegistraOrdenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sTPRegistraOrdensByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        registraOrdenId
        applicantId
        investorId
        registraOrden
        ordenPago
        claveRastreo
        conceptoPago
        cuentaBeneficiario
        cuentaOrdenante
        empresa
        fechaOperacion
        folioOrigen
        institucionContraparte
        institucionOperante
        monto
        nombreBeneficiario
        nombreOrdenante
        referenciaNumerica
        rfcCurpBeneficiario
        rfcCurpOrdenante
        tipoCuentaBeneficiario
        tipoCuentaOrdenante
        tipoPago
        createdAt
        updatedAt
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        owner
      }
      nextToken
    }
  }
`;
export const simafPerfilsByApplicantId = /* GraphQL */ `
  query SimafPerfilsByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafPerfilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafPerfilsByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        simaf_cuentaId
        perfilDeclarado {
          frecuencia
          monto
        }
        perfilOperado {
          frecuencia
          monto
        }
        moneda
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        simaf_cuenta {
          id
          applicantId
          investorId
          cuenta_asociada_institucion
          institucion_financiera
          nacionalidad_cuenta_asociada
          nivel_cuenta
          numero_cuenta_proyecto
          regimen
          saldo
          tipo_cuenta
          tipo_financiamiento
          tipo_producto
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafPerfilsByInvestorId = /* GraphQL */ `
  query SimafPerfilsByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafPerfilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafPerfilsByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        simaf_cuentaId
        perfilDeclarado {
          frecuencia
          monto
        }
        perfilOperado {
          frecuencia
          monto
        }
        moneda
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        simaf_cuenta {
          id
          applicantId
          investorId
          cuenta_asociada_institucion
          institucion_financiera
          nacionalidad_cuenta_asociada
          nivel_cuenta
          numero_cuenta_proyecto
          regimen
          saldo
          tipo_cuenta
          tipo_financiamiento
          tipo_producto
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafPerfilsBySimaf_cuentaId = /* GraphQL */ `
  query SimafPerfilsBySimaf_cuentaId(
    $simaf_cuentaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafPerfilFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafPerfilsBySimaf_cuentaId(
      simaf_cuentaId: $simaf_cuentaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        simaf_cuentaId
        perfilDeclarado {
          frecuencia
          monto
        }
        perfilOperado {
          frecuencia
          monto
        }
        moneda
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        simaf_cuenta {
          id
          applicantId
          investorId
          cuenta_asociada_institucion
          institucion_financiera
          nacionalidad_cuenta_asociada
          nivel_cuenta
          numero_cuenta_proyecto
          regimen
          saldo
          tipo_cuenta
          tipo_financiamiento
          tipo_producto
          applicant {
            id
            userId
            interestRateFinsphera
            defaultInterestRateInvestor
            interestRateInvestor
            score
            paymentScore
            delayDays
            isApproved
            isBureauConsultAuthorized
            reasonOfRejection
            isInNewsletter
            isPLDApproved
            isInMarketing
            isSIMAFSended
            requestedAmount
            termLoan
            loanDestination
            createdAt
            updatedAt
            isContractSigned
            distributionChannel
            industryActivity
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          investor {
            id
            userId
            typeOfPerson
            createdAt
            updatedAt
            isInNewsletter
            isInMarketing
            isPLDApproved
            isSIMAFSended
            isContractSigned
            isRisksSigned
            customerNumberEfysis
            savingsAccountNumber
            isAccountCanceled
            statusSIMAF
            owner
          }
          createdAt
          updatedAt
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafCuentasByApplicantId = /* GraphQL */ `
  query SimafCuentasByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafCuentaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafCuentasByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafCuentasByInvestorId = /* GraphQL */ `
  query SimafCuentasByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafCuentaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafCuentasByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        cuenta_asociada_institucion
        institucion_financiera
        nacionalidad_cuenta_asociada
        nivel_cuenta
        numero_cuenta_proyecto
        regimen
        saldo
        tipo_cuenta
        tipo_financiamiento
        tipo_producto
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafOperacionsByApplicantId = /* GraphQL */ `
  query SimafOperacionsByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafOperacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafOperacionsByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        cuenta_destino
        cuenta_origen
        geolocalizacion
        instrumento_monetario
        moneda
        timestamp
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafOperacionsByInvestorId = /* GraphQL */ `
  query SimafOperacionsByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafOperacionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafOperacionsByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        cuenta_destino
        cuenta_origen
        geolocalizacion
        instrumento_monetario
        moneda
        timestamp
        tipo_operacion
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafCuestionariosByApplicantId = /* GraphQL */ `
  query SimafCuestionariosByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafCuestionarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafCuestionariosByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        origen_recursos
        destino_recursos
        proposito
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const simafCuestionariosByInvestorId = /* GraphQL */ `
  query SimafCuestionariosByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSimafCuestionarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    simafCuestionariosByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        origen_recursos
        destino_recursos
        proposito
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const trulliesByApplicantId = /* GraphQL */ `
  query TrulliesByApplicantId(
    $applicantId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTrullyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trulliesByApplicantId(
      applicantId: $applicantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const trulliesByInvestorId = /* GraphQL */ `
  query TrulliesByInvestorId(
    $investorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTrullyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    trulliesByInvestorId(
      investorId: $investorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        applicantId
        investorId
        fraud
        label
        raw_data {
          document_ocr {
            document_id
          }
          face_comparison {
            match
          }
          face_recognition {
            face_id
            inquiry_date
            match
            confidence
            first_seen
            fraud_detail
            last_seen
            match_fraud_flag
            times_seen_last_month
          }
          identity_fraud_score {
            probability
          }
        }
        reason
        request_id
        applicant {
          id
          userId
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          interestRateFinsphera
          defaultInterestRateInvestor
          interestRateInvestor
          score
          paymentScore
          delayDays
          isApproved
          isBureauConsultAuthorized
          reasonOfRejection
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          isInNewsletter
          isPLDApproved
          isInMarketing
          isSIMAFSended
          requestedAmount
          termLoan
          loanDestination
          createdAt
          updatedAt
          creditBureau {
            applicantId
            createdAt
            updatedAt
            owner
          }
          creditAnalysis {
            applicantId
            createdAt
            updatedAt
            isApproved
            rating
            interestRateApplicant
            hasAnotherCredit
            owner
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          mifiels {
            nextToken
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          campaigns {
            nextToken
          }
          invoices {
            nextToken
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          distributionChannel
          industryActivity
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          obligadoSolidario {
            nextToken
          }
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        investor {
          id
          userId
          typeOfPerson
          personalInformation {
            firstName
            fullName
            lastNameP
            lastNameM
            surname
            profileImage
            email
            age
            underage
            gender
            RFC
            CURP
            maritalStatus
            occupation
            nationality
            simaf_nationalityId
            dateOfBirth
            countryOfBirth
            stateOfBirth
            isPEP
            pepCharge
            pepName
            pepDate
            isFamilyPEP
            generalActivity
            specificActivity
            simaf_specificActivityId
          }
          companyInformation {
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            startedOperationsAt
            country
            numeroDeSerie
            obligadoSolidario
            employees
            activity
            webSite
            invoicesValue
            businessAge
            FEA
            CIEC
            owners
            activoCirculante
            activoFijo
            activoTotal
            pasivo
            pasivoCortoPlazo
            pasivoLargoPlazo
            capital
            ventas
            costos
            gastos
            utilidadNeta
            simaf_specificActivityId
          }
          ipAddress {
            IPv4
            country_code
            country_name
            city
            state
            date
          }
          geolocation {
            accuracy
            latitude
            longitude
            date
          }
          mati {
            stepId
            resource
            identityId
            verificationId
            eventName
            isFinished
            videoUrl
            spriteUrl
            documentType
            score
            ageThreshold
            isAlterationDetection
            identityStatus
            age
            isDocumentExpired
            matiDashboardUrl
            finalStatus
          }
          STP {
            id
            CLABE
            recursosDisponibles
            compromisoInversion
            status
            inversionesVigentes
            saldoTotal
            createdAt
            updatedAt
            owner
          }
          banks {
            nextToken
          }
          beneficiaries {
            nextToken
          }
          file {
            id
            address
            FEA
            taxID
            createdAt
            updatedAt
            areBusinessDocumentsUpload
            owner
          }
          risk {
            investorId
            loss
            liquidity
            information
            performance
            notApproved
            notAdvice
            investment
            disclosedInformation
            isSigned
            createdAt
            updatedAt
            owner
          }
          address {
            nextToken
          }
          investments {
            nextToken
          }
          mifiels {
            nextToken
          }
          createdAt
          updatedAt
          isInNewsletter
          isInMarketing
          isPLDApproved
          isSIMAFSended
          economicActivities {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
          onboarding {
            complete
            currentStep
          }
          isContractSigned
          isRisksSigned
          verificationCode {
            code
            codeReceived
            messageId
            timestamp
            timestampReceived
            typeMessage
            isValid
          }
          navigationPage {
            action
            timestamp
            route
          }
          literaxs {
            nextToken
          }
          customerNumberEfysis
          savingsAccountNumber
          trully {
            id
            applicantId
            investorId
            fraud
            label
            reason
            request_id
            createdAt
            updatedAt
            owner
          }
          isAccountCanceled
          statusSIMAF
          resourcesProvider {
            id
            applicantId
            investorId
            createdAt
            updatedAt
            owner
          }
          owner
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
