import { Link } from 'react-router-dom';
import {
  OurShareholdersAndOurCommunityContainer,
  ShareholdersCommunityTitle,
  Directory,
  DirectoryInfo
} from './shareholderAndCommunity.styles';
import { Banner } from 'src/utilities/UILibrary/Media/Banner/Banner';

export default function OurShareholdersAndOurCommunity() {
  return (
    <OurShareholdersAndOurCommunityContainer>
      <ShareholdersCommunityTitle>
        <h2>Relación con nuestros accionistas y nuestra comunidad</h2>
        <img
          src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_shareholders.jpeg`}
          alt="imagen Acerca de nosotros"
        />
      </ShareholdersCommunityTitle>
      <Directory>
        <h2>Contáctanos</h2>
        <DirectoryInfo>
          <div>
            <div>
              <p>¿Quieres saber más de nosotros? Escríbenos a: </p>
              <div>
                <div>
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_email.png`}
                      alt="icono correo electrónico"
                    />
                  </figure>
                  <p>hola@finsphera.com</p>
                </div>
              </div>
            </div>
          </div>
        </DirectoryInfo>
      </Directory>
    </OurShareholdersAndOurCommunityContainer>
  );
}
