import { Card } from 'src/utilities/UILibrary2024/components';
import { CalculatorSectionStyles } from './CalculatorSection.styles';
import { useViewMobile } from 'src/hooks/useViewMobile';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const CalculatorSection = () => {
  const isMobileToExtraLargeDesktop = useViewMobile(
    parseInt(breakpoints.extraLargeDesktop.replace('px', ''))
  );
  const calculatorCardsWidth = isMobileToExtraLargeDesktop ? '17.25rem' : '24.25rem';

  return (
    <CalculatorSectionStyles>
      <h1>
        Fintech <span />
        de siguiente generación
      </h1>
      <div className="cards-section">
        <Card width={calculatorCardsWidth} aspectRatio="default" isFluid>
          Inversión
        </Card>
        <Card width={calculatorCardsWidth} aspectRatio="default" isFluid>
          Crédito
        </Card>
        <p className="cards-section--description">
          Para fines informativos y de comparación exclusivamente. | No incluye comisiones, ni otros
          gastos, favor de consultar en Detalles. | La simulación tiene la finalidad de orientar
          sobre posibles resultados con un rendimiento estimado como ejemplo para la tasa señalada.
          | Tasa de interés anual y fija de 15% a 20% sin IVA, sujeta a evaluación y calificación
          crediticia.
        </p>
      </div>
    </CalculatorSectionStyles>
  );
};
