import { forwardRef, useEffect, useRef } from 'react';
import { MetamapButtonContainer } from './metamapButton.styles';
import { MetamapButtonProps } from './metamapButton.interface';

export const MetamapButton = forwardRef<HTMLElement, MetamapButtonProps>((props) => {
  const {
    disabled,
    userId,
    typeOfUser,
    onLoaded,
    onUserExitedSdk,
    onUserFinishedSdk,
    onUserStartedSdk,
    ...restProps
  } = props;

  const button = useRef<HTMLElement | null>(null);
  const clientid = process.env.REACT_APP_METAMAP_CLIENTID;
  const flowid = process.env.REACT_APP_METAMAP_FLOWID;

  useEffect(() => {
    if (button.current) {
      button.current.addEventListener('metamap:loaded', (e) => {
        if (onLoaded) return onLoaded(e);
      });
      button.current.addEventListener('metamap:userStartedSdk', (e) => {
        if (onUserStartedSdk) return onUserStartedSdk(e);
      });
      button.current.addEventListener('metamap:userFinishedSdk', (e) => {
        if (onUserFinishedSdk) return onUserFinishedSdk(e);
      });
      button.current.addEventListener('metamap:exitedSdk', (e) => {
        if (onUserExitedSdk) return onUserExitedSdk(e);
      });
    }
  }, [button]);

  return (
    <MetamapButtonContainer disabled={disabled}>
      <metamap-button
        ref={button}
        clientid={clientid}
        flowid={flowid}
        metadata={`{"id":"${userId}","typeOfUser":"${typeOfUser}", "fixedLanguage":"es"}`}
        {...restProps}
      />
    </MetamapButtonContainer>
  );
});

MetamapButton.displayName = 'MetamapButton';
