import styled from 'styled-components';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { NavMenuListProps } from './navMenuList.interface';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const NavMenuListWrapper = styled.div<NavMenuListProps>`
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 100px;
  width: 100%;
  background-color: ${colors.background.sidenav};
  height: calc(100vh - 100px);
  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  overflow: auto;

  &.is-active {
    display: block;
    transform: translateX(0);
  }

  .list-item {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 2rem;
    text-align: end;
    gap: 2rem;

    li a {
      color: ${colors.text.black};
      font-size: ${uIFontSize.large};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 125px;

    height: calc(100vh - 125px);
  }
`;
