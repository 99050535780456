import {
  agropecuarioSilviculturaYPesca,
  comercioRestaurantesYHoteles,
  construccion,
  electricidadGasYAguaPotable,
  industriaManufacturera,
  mineria,
  politicamenteExpuestos,
  serviciosComunalesSocialesYPersonales,
  serviciosFinancierosSegurosYBienesInmuebles,
  transporteYAlmacenamientoYComunicaciones
} from './economicActivitiesSpecific';

type EconomicActivity = {
  name: string;
  code: number;
};

const economicActivitiesMapping = {
  'Agropecuario silvicultura y pesca': agropecuarioSilviculturaYPesca,
  'Comercio, restaurantes y hoteles': comercioRestaurantesYHoteles,
  Construcción: construccion,
  'Electricidad, gas y agua potable': electricidadGasYAguaPotable,
  'Industria manufacturera': industriaManufacturera,
  Mineria: mineria,
  'Politicamente expuestos': politicamenteExpuestos,
  'Servicios comunales, sociales y personales': serviciosComunalesSocialesYPersonales,
  'Servicios financieros, seguros y bienes inmuebles': serviciosFinancierosSegurosYBienesInmuebles,
  'Transporte, almacenamiento y comunicaciones': transporteYAlmacenamientoYComunicaciones,
  Other: ['otro']
} as unknown as { [key: string]: EconomicActivity[] };

export default economicActivitiesMapping;
