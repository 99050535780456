import { GlobalStyle } from 'src/utilities/UIStyles/globalStyles';
import Router from './routes';
import { signInUser, setLoadingState, signOut } from 'src/store/User/user.slice';
import { useEffect } from 'react';
import { RootState, useAppThunkDispatch } from './store/store';
import { signOutAll } from 'src/store/User/user.slice';
import { useIdleLogout } from 'src/hooks/useIdleLogout';
import { isUserLoggedInOrStillValid } from 'src/functions/isUserLoggedIn';
import { useSelector } from 'react-redux';
import HeadManager from 'src/components/shared/HeadManager/HeadManager';

function App(): JSX.Element {
  const dispatch = useAppThunkDispatch();
  const { isSignedIn } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    const checkUserStatus = async () => {
      // check the status login from the server vs the status in the redux store and update the redux store accordingly
      const isLogged = await isUserLoggedInOrStillValid();

      if (isLogged && !isSignedIn)
        await dispatch(signInUser({ email: '', password: '', isRetrievingUser: true }));

      if (!isLogged && isSignedIn) await dispatch(signOut());
    };

    const handleFocus = () => {
      checkUserStatus();
    };

    window.addEventListener('focus', handleFocus);

    const runEffect = async () => {
      await checkUserStatus();
      dispatch(setLoadingState(false));
    };

    runEffect();

    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  useIdleLogout(async () => {
    // manage a logout when the user have more than 5 minutes without any activity
    if (!isSignedIn) return;
    await dispatch(signOutAll());
    window.location.href = '/?sessionExpired=true';
  });

  return (
    <>
      <HeadManager />
      <GlobalStyle />
      <Router />
    </>
  );
}

export default App;
