import styled from 'styled-components/macro';
import { BannerProps } from './banner.interface';
import { uIFontSize } from '../../../UIStyles/variables/typography';
import { blackSphera, greenSp } from '../../../UIStyles/variables/colors';
import { breakpoints } from '../../../UIStyles/variables/breakpoints';
import { spacing } from '../../../UIStyles/variables/spacing';

type ExtendedBannerProps = BannerProps & { isRightElement?: boolean };

export const BannerContainer = styled.div<ExtendedBannerProps>`
  display: flex;
  flex-direction: ${(props) => (props.isImageFirstOnMobile ? 'column' : 'column-reverse')};
  align-items: center;
  h1,
  h2,
  p {
    margin-bottom: ${spacing.appMarginMobile};
    color: ${blackSphera};
  }

  h1 {
    font-size: ${uIFontSize.title};
    text-align: center;
  }

  h1 span {
    font-size: ${uIFontSize.title};
  }

  h2 {
    font-size: ${uIFontSize.subTitle};
  }

  h2 span {
    font-size: ${uIFontSize.subTitle};
  }

  span {
    color: ${greenSp};
  }

  p {
    font-size: ${uIFontSize.paragraph};
  }

  .banner-copy {
    padding: ${spacing.appPaddingMobile} ${spacing.appPaddingMobile};
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    width: 100%;
    position: relative;
    display: ${(props) => (props.isImgMobile ? 'flex' : 'none')};
    align-self: start;

    img {
      width: 100%;
    }

    .right-element {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      display: ${(props) => (props.rightElement ? 'flex' : 'none')};
      justify-content: center;
      align-items: center;
      margin-bottom: ${spacing.appMarginMobile};
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    .banner-copy {
      padding-left: ${spacing.appPadding};
      padding-right: ${(props) => (props.isImgMobile ? '2rem' : spacing.appPadding)};
      width: 100%;
    }
    h1,
    h2,
    p {
      margin-bottom: ${spacing.appMargin};
    }

    .image-container img {
      border-bottom-left-radius: 45%;
      object-fit: contain;
    }
  }
  @media (min-width: 2561px) {
    h1 {
      font-size: 3.5rem;
      text-align: left;
    }

    h1 span {
      font-size: 3rem;
    }
    h2 {
      font-size: 2.8rem;
    }
    h2 span {
      font-size: 2.8rem;
    }
    p {
      font-size: ${uIFontSize.xLarge};
    }
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    h1 {
      font-size: 2.8rem;
      text-align: left;
    }
    .banner-copy {
      padding: none;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    justify-content: space-between;
    flex-grow: ${(props) => (props.isRightElement ? '0' : '1')};
    .banner-copy {
      padding-right: 2rem;
      align-items: ${(props) => (props.isRightElement ? 'flex-start' : 'center')};
      width: 100%;
    }
    .image-container {
      display: flex;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
