import styled from 'styled-components/macro';
import { blackSphera, brandBlack, neutralBlueGray } from 'src/utilities/UIStyles/variables/colors';
import { neutralLight } from 'src/utilities/UIStyles/variables/colors';
import { greenSp } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const DetailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 2%;
`;

export const MainDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  margin: 2% 5%;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  > a {
    text-decoration: none;
    text-align: center;
    color: ${neutralBlueGray};
    font-weight: bold;
    font-size: ${uIFontSize.medium};
    cursor: pointer;
    padding: 1% 3% 1% 0;
    width: auto;
    align-self: flex-start;
  }
`;

export const CardLogo = styled.figure`
  width: 15%;
  height: auto;
  margin: 0 2%;
  > img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 60%;
    height: auto;
  }
`;

export const CardStatus = styled.div`
  width: 100%;
  padding: 5% 10%;
  > h3 {
    text-align: center;
    font-size: ${uIFontSize.large};
    font-weight: bold;
    color: ${brandBlack};
    margin-bottom: 5%;
  }
  > div {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: #f0f5ff;
    > div {
      width: ${(props) => props.percentage && `${props.percentage}%`};
      height: 100%;
      border-radius: 10px;
      background-color: ${greenSp};
    }
  }
`;

export const CampaignInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 10px;
  background-color: ${neutralLight};
  width: 48%;
  margin: 2% auto;
  padding: 0 2% 2% 2%;
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

export const GeneralInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 3%;
  > div {
    width: 50%;
    > h3 {
      margin-top: 5%;
      font-size: ${uIFontSize.medium};
      font-weight: bold;
    }
    > p {
      font-size: ${uIFontSize.small};
      margin-bottom: 3%;
    }
  }
`;

export const InterestInfo = styled.div`
  width: 45%;
  > h3 {
    border-bottom: 1px solid ${blackSphera};
    margin-bottom: 3%;
    color: ${greenSp};
    font-size: ${uIFontSize.medium};
  }
  > p {
    font-size: ${uIFontSize.small};
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    margin: 0 5%;
  }
`;

export const CompanyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 2% 5%;
  > h2 {
    font-size: ${uIFontSize.large};
    width: 100%;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  > div {
    width: 90%;
    > h3 {
      margin-top: 5%;
      font-size: ${uIFontSize.medium};
    }
    > p {
      font-size: ${uIFontSize.small};
      margin-bottom: 3%;
    }
  }
`;

export const CompanyAdd = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  > img {
    width: 20%;
    height: auto;
    align-self: flex-end;
  }
  > div {
    width: 90%;
    > h3 {
      margin-top: 5%;
      font-size: ${uIFontSize.medium};
    }
    > p {
      font-size: ${uIFontSize.small};
      margin-bottom: 3%;
    }
  }
`;

export const InvestDetail = styled.figure`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 50%;
    > h2 {
      color: ${brandBlack};
      font-weight: bold;
      font-size: ${uIFontSize.medium};
    }
    > p {
      color: ${brandBlack};
      font-size: ${uIFontSize.small};
      margin: 2% 0;
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    > div {
      width: 50%;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 90%;
    margin: 2% 5%;
    > div {
      width: 90%;
      margin: 0 5%;
      text-align: center;
    }
  }
`;
