/* eslint-disable no-console */
import { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  confirmSignUpInvestor,
  userSelector,
  signInInvestor,
  incrementalId,
  setLoadingState,
  trullyFirstStep
} from 'src/store/User/user.slice';
import addUserToGroup from 'src/functions/addUserToGroup';
import getIp from 'src/functions/getIp';
import getCurrentDate from 'src/functions/getCurrentDate';
import { useGeolocation } from 'src/hooks/useGeolocation';
import { ModalSignupSuccess } from './signupSuccess.styles';
import { RootState } from 'src/store/store';
import { useAppThunkDispatch } from 'src/store/store';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { setAppError } from 'src/store/app/app.slice';

const SignupSuccess: React.FC = () => {
  const showSuccessModal = useSelector((state: RootState) => state.user.showSuccessModal);
  const email = useSelector((state: RootState) => state.user.email);
  const typeOfPerson = useSelector((state: RootState) => state.user.typeOfPerson);
  const [disabled, setDisabled] = useState(true);
  const [verifierCode, setVerifierCode] = useState('');
  const [marketingChecked, setMarketingChecked] = useState('0');
  const currentUser = useSelector(userSelector);

  //Geolocation
  const { getUserLocation } = useGeolocation();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const { errorType } = useSelector((state: RootState) => state.user);

  const handleCode = (e: ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    setVerifierCode(code);
    const isValidCode = code.length >= 6 && /^\d+$/.test(code);
    setDisabled(!isValidCode);
  };
  const handleClick = () => {
    verifyAuthCode();
  };

  const errorText = () => {
    switch (errorType) {
      case 'CodeMismatchException':
        return 'Código incorrecto, vuelve a ingresarlo';
      case 'User is already confirmed.':
        return (
          <>
            Ya has verificado tu correo anteriormente
            <Link to="/iniciar-sesion">Iniciar sesión.</Link>
          </>
        );
      case 'Confirmation code cannot be empty':
        return 'Ingresa de nuevo el código de confirmación';
    }
  };

  const verifyAuthCode = async () => {
    dispatch(setLoadingState(true));
    setDisabled(true);
    dispatch(setLoadingState(true));
    const userGeolocation = await getUserLocation().catch((err) => {
      console.error('Error getting geolocation:', err);
      return null;
    });
    if (!userGeolocation) {
      dispatch(setLoadingState(false));
      return alert('Ocurrió un error con los permisos de geolocalización');
    }
    const [ip, date] = await Promise.all([getIp(), getCurrentDate()]);
    if (!ip.IPv4) {
      dispatch(setLoadingState(false));
      dispatch(setAppError({ errorCode: 'No se pudo obtener la IP' }));
    }
    const user = {
      id: currentUser.id,
      typeOfPerson,
      email: currentUser.email,
      password: currentUser.password,
      isInMarketing: marketingChecked,
      ip,
      geolocation: userGeolocation,
      date,
      code: verifierCode,
      showSuccessModal
    };
    const trully = {
      id: currentUser.id,
      typeOfUser: 'investor'
    };
    try {
      await dispatch(confirmSignUpInvestor({ user }));
      // dispatch(setLoadingState(true));
      await addUserToGroup(user.email, 'investor');
      await dispatch(incrementalId({ id: user.id, typeOfUser: 'investor' }));
      const trullyResponse = await dispatch(trullyFirstStep(trully));
      if (trullyResponse.payload === 'No Threat') {
        await dispatch(signInInvestor({ email: user.email, password: user.password }));
        navigate('/inversionista/identificacion');
      } else {
        return navigate('/pagina-validacion');
      }
    } catch (error) {
      dispatch(setLoadingState(false));
      console.error('Error confirming sign up', error);
    } finally {
      dispatch(setLoadingState(false));
    }
    dispatch(setLoadingState(false));
  };

  return (
    <>
      {showSuccessModal && (
        <ModalSignupSuccess>
          <div>
            <h2>
              ¡Has creado tu cuenta en <span>FINSPHERA!</span>
            </h2>
            <p>¡Estás a punto de pertenecer a FinSphera!</p>
            <p>
              Enviamos un correo a <span>{email}</span> para confirmar tu cuenta. Ingresa el código.
            </p>
            <input name="code" type="text" onChange={handleCode} />
            <button onClick={handleClick} disabled={disabled}>
              Enviar
            </button>
            {errorType && <p>{errorText()}</p>}
            <Checkbox
              label="Deseo recibir publicidad de Finsphera vía correo electrónico"
              onChange={() => setMarketingChecked('1')}
            />
          </div>
        </ModalSignupSuccess>
      )}
    </>
  );
};

export default SignupSuccess;
