import { keyframes } from 'styled-components/macro';

export const showMenuList = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const hideMenuList = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const showMenuListMobile = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const hideMenuListMobile = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;

export const showModalSignupSuccess = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const showModalRulesSecurity = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;
