import { Link } from 'react-router-dom';
import { HomeContainer, InvestUs, OurServices } from './home.styles';
import { Banner } from 'src/utilities/UILibrary/Media/Banner/Banner';
import Sponsors from '../../../components/Layout/sponsors/sponsors';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { environment } from 'src/environments';
import { WaitlistModal } from 'src/components/features/landing/modals/WaitlistModal/Waitlist';
export default function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSignedIn } = useSelector((state: RootState) => state.user);
  const isSessionExpired = location.search.includes('sessionExpired=true');
  const [modalState, setModalState] = useState({
    sessionExpired: isSessionExpired && !isSignedIn,
    waitingList: false,
    successfulSubmit: false
  });

  const handleModalClose = () => {
    navigate('/');
    setModalState({ ...modalState, sessionExpired: false });
  };

  const handleOnSuccessfulSubmit = () => {
    setModalState({ ...modalState, waitingList: false, successfulSubmit: true });
  };

  const handleOnFailedSubmit = () => {
    setModalState({ ...modalState, waitingList: false });
  };

  return (
    <HomeContainer>
      <ComposedModal
        messageTitle="Tu sesión expiró"
        message="Pulsa el botón para iniciar sesión nuevamente."
        type="warning"
        isOpen={modalState.sessionExpired}
        onClose={handleModalClose}
        onOverlayClose={false}
        buttons={[
          { label: 'Iniciar sesión', onClick: () => navigate('/iniciar-sesion') },
          {
            label: 'Cancelar',
            onClick: handleModalClose,
            color: 'secondary'
          }
        ]}
      />
      <ComposedModal
        type="success"
        isOpen={modalState.successfulSubmit}
        onClose={() => setModalState({ ...modalState, successfulSubmit: false })}
      />
      <WaitlistModal
        isOpen={modalState.waitingList}
        onClose={() => setModalState({ ...modalState, waitingList: false })}
        onSuccessfulSubmit={handleOnSuccessfulSubmit}
        onFailedSubmit={handleOnFailedSubmit}
      />
      <Banner
        className="banner-container"
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_home_principal_1.jpg`}
      >
        <h2>
          Nosotros somos la primera plataforma de Financiamiento Colectivo (Crowdfunding) Ambiental,
          Social y con buen Gobierno Corporativo. Gracias a tu participación estamos impactando
          positivamente a nuestra comunidad y a nuestro Medio Ambiente.{' '}
          <span>¡Tú eres el factor de impacto que estábamos buscando!</span>
        </h2>
        {environment === 'dev' ? (
          <Button onClick={() => navigate('/iniciar-sesion')} isFullWidth>
            Ingresar
          </Button>
        ) : (
          <>
            <p>
              Ya puedes solicitar crédito a una tasa justa para tu empresa. Si eres inversionista
              puedes financiar empresas recibiendo completa la tasa de interés porque no hay
              intermediarios.
            </p>
            <Button isFullWidth onClick={() => setModalState({ ...modalState, waitingList: true })}>
              Únete a nuestra lista de espera
            </Button>
          </>
        )}
      </Banner>

      <Sponsors />
      <InvestUs>
        <div>
          <h2>Invierte con nosotros</h2>
        </div>
        <div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_home_invest_money.png`}
                alt="Finsphera genera intereses"
              />
            </figure>
            <h3>Que tu dinero genere intereses</h3>
            <p>
              Ponlo a trabajar impulsando empresas que cumplen con buenas Prácticas Sociales,
              Ambientales y de Buen Gobierno.
            </p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_home_invest_time.png`}
                alt="Finsphera plazos 30 a 90 días"
              />
            </figure>
            <h3>Plazos de 30 a 150 días</h3>
            <p>Invierte a Corto Plazo generando atractivos rendimientos.</p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_home_invest_impact.png`}
                alt="Finsphera tu eres el factor de impacto"
              />
            </figure>
            <h3>Tú eres el Factor de Impacto</h3>
            <p>
              Muchas gracias por ayudarnos a transformar nuestra comunidad y nuestro Medio Ambiente.
            </p>
          </div>
        </div>
      </InvestUs>
      {environment === 'dev' ? (
        <OurServices>
          <div>
            <h2>Nuestros servicios:</h2>
            <div>
              <Link to="/solicitante/inicio">Solicita un crédito</Link>
              <Link to="/iniciar-sesion">Invierte</Link>
            </div>
          </div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_home_our_services.png`}
              alt="FinSphera nuestros servicios"
            />
          </figure>
        </OurServices>
      ) : null}
    </HomeContainer>
  );
}
