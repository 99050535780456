import { Route } from 'react-router-dom';
import InvestorDashboardInvestment from 'src/views/investor/Dashboard/investment/investment';
import InvestorDashboardWelcomeA from 'src/views/investor/Dashboard/welcomeA/welcome';
import InvestorDashboardOpportunities from 'src/views/investor/Dashboard/opportunities/opportunities';
import InvestorDashboardInvestSimulator from 'src/components/features/investor/dashboard/investSimulator/investSimulator';
import InvestorDashboardInvestDetail from 'src/views/investor/Dashboard/investDetail/investDetail';
import InvestorDashboardAccountCLABE from 'src/views/investor/Dashboard/accountCLABE/accountCLABE';
import InvestorDashboardCompanyDetail from 'src/views/investor/Dashboard/companyDetail/companyDetail';
import InvestorDashboardMyProfile from 'src/views/investor/Dashboard/myProfile/myProfile';
// import DashboardOpportunities from 'src/views/investor/Dashboard/opportunities/opportunities';
import OpportunitiesDetail from 'src/views/investor/Dashboard/opportunitiesDetail/opportunitiesDetail';
import { ProtectedOnboardingRoute } from 'src/components/shared/compositionComponents/ProtectedOnboardingRoute/ProtectedOnboardingRoute';
import { AllowedUser } from '../ProtectedRoute';
import AccountInfo from 'src/components/shared/accountInfo/account';

const AllowedUseMoralConfig: AllowedUser = {
  user: 'investor',
  person: 'MORAL'
};

const AllowedUserPhysicalConfig: AllowedUser = {
  user: 'investor',
  person: 'FISICA'
};

const AllowedUserArray = [AllowedUseMoralConfig, AllowedUserPhysicalConfig];

export const DashboardInvestorRoutes = (
  <>
    <Route path="/inversionista/dashboard">
      <Route
        index
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardWelcomeA />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="inversiones"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardInvestment />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="oportunidades"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardOpportunities />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="oportunidadesDetalle"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <OpportunitiesDetail />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="simulador-de-inversion"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardInvestSimulator />
            {/* <InvestorDashboardInvestSimulator isBalanceSufficient={undefined} /> */}
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="detalle-de-inversion"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardInvestDetail />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="cuenta-clabe"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardAccountCLABE />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="detalle-de-compañia/:invoiceId"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardCompanyDetail />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="mi-perfil"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <InvestorDashboardMyProfile />
          </ProtectedOnboardingRoute>
        }
      />
      <Route
        path="account"
        element={
          <ProtectedOnboardingRoute protectDashboard allowedUsers={AllowedUserArray}>
            <AccountInfo />
          </ProtectedOnboardingRoute>
        }
      />
    </Route>
  </>
);
