import { useState, useEffect } from 'react';

import {
  ModalCompanyDetailInvestPay,
  DetailInvestPay,
  Recordatory,
  AccountSection,
  AccountHeader,
  AccountInfo,
  AccountDetails,
  Status
} from './companyDetailInvestPay.styles';

export default function CompanyDetailInvestPay(props) {
  const { listBids } = props;
  const [detailInvestPay, setDetailInvestPay] = useState(props.showModalCompanyDetailInvestPay);
  const [showExtraInfo, setShowExtraInfo] = useState(false);
  const [available] = useState(true);
  useEffect(() => {
    setDetailInvestPay(props.showModalCompanyDetailInvestPay);
  }, [props.showModalCompanyDetailInvestPay]);

  const closeModal = () => {
    setDetailInvestPay(false);
    props.setCompanyDetailInvestPay(false);
  };

  const isExpired = listBids?.campaign?.isExpired === '1';
  const isSigned = listBids?.campaign?.isPromissoryNoteSigned === '1' ? 'Firmado' : 'No firmado';
  const isSuccessful = listBids?.campaign?.isSuccessful === '0' ? 'Incompleto' : 'Total';

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }

  return (
    <ModalCompanyDetailInvestPay showModalCompanyDetailInvestPay={detailInvestPay}>
      <div>
        <div>
          <h3>Campaña:{listBids?.campaign?.nombreCampania}</h3>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </div>
        <DetailInvestPay isExpired={isExpired}>
          <div>
            <Status>
              <h3>Estatus de la inversión:</h3>
              <p>{listBids?.status ? listBids?.status : '-'}</p>
            </Status>
            <AccountDetails>
              <div>
                <h3>Monto solicitado de campaña:</h3>
                <p>{formatCurrency(listBids?.campaign?.montoSolicitado)}</p>
              </div>
              <div>
                <h3>Monto recaudado de campaña:</h3>
                <p>{formatCurrency(listBids?.campaign?.collectedAmount)}</p>
              </div>
              <div>
                <h3>Monto invertido en campaña:</h3>
                <p>
                  {formatCurrency(listBids?.compromisoInversion)
                    ? formatCurrency(listBids?.compromisoInversion)
                    : '-'}
                </p>
              </div>
            </AccountDetails>
          </div>
          <AccountSection>
            <AccountInfo>
              <AccountHeader>
                <h3>Información de la campaña:</h3>
              </AccountHeader>
              <ul>
                <li>
                  <span>Estado de campaña:</span>
                  <p>{listBids?.campaign?.isActive === '1' ? 'Activa' : 'Finalizada'}</p>
                </li>
                <li>
                  <span>Fecha de inicio de campaña:</span>
                  <p>
                    {formatDate(listBids?.campaign?.dateStartCampaign)
                      ? formatDate(listBids?.campaign?.dateStartCampaign)
                      : '-'}
                  </p>
                </li>
                <li>
                  <span>Fecha de fin de campaña:</span>
                  <p>
                    {formatDate(listBids?.campaign?.dateEndCampaign)
                      ? formatDate(listBids?.campaign?.dateEndCampaign)
                      : '-'}
                  </p>
                </li>
                <li>
                  <span>Destino de campaña:</span>
                  <p>Factoraje a proveedores</p>
                </li>
                <li>
                  <span>Plazo del crédito:</span>
                  <p>{listBids?.campaign?.plazoCredito ? listBids?.campaign?.plazoCredito : '-'}</p>
                </li>
              </ul>
            </AccountInfo>
            <AccountInfo>
              <AccountHeader>
                <h3>Información de inversión:</h3>
              </AccountHeader>
              <ul>
                <li>
                  <span>Porcentaje del total invertido:</span>
                  <p>
                    {listBids?.porcentajeGananciaInteres.toFixed(2) * 100
                      ? listBids?.porcentajeGananciaInteres.toFixed(2) * 100
                      : '-'}
                    %
                  </p>
                </li>
                <li>
                  <span>Tasa de interés:</span>
                  <p>
                    {listBids?.interesInversionistaPorcentaje.toFixed(2) * 100
                      ? listBids?.interesInversionistaPorcentaje.toFixed(2) * 100
                      : '-'}
                    %
                  </p>
                </li>
                <li>
                  <span>Intereses generados del monto total solicitado de campaña:</span>
                  <p>
                    {formatCurrency(listBids?.interesInversionistaPesos)
                      ? formatCurrency(listBids?.interesInversionistaPesos)
                      : '-'}
                  </p>
                </li>
                <li>
                  <span>Retención ISR:</span>
                  <p>
                    {formatCurrency(listBids?.retencionImpuestoInversionistaPesos)
                      ? formatCurrency(listBids?.retencionImpuestoInversionistaPesos)
                      : '-'}
                  </p>
                </li>
                <li>
                  <span>Tasa de interes moratoria:</span>
                  <p>
                    {listBids?.campaign?.tasaInteresMoratorioPorcentaje.toFixed(2) * 100
                      ? listBids?.campaign?.tasaInteresMoratorioPorcentaje.toFixed(2) * 100
                      : '-'}
                    %
                  </p>
                </li>
              </ul>
            </AccountInfo>
          </AccountSection>
          <Recordatory>
            <p>
              * Recuerda que este es un monto aproximado de tu cobro final. Cuando se haya liquidado
              la campaña se verá relfejado automáticamente.
            </p>
          </Recordatory>
        </DetailInvestPay>
      </div>
    </ModalCompanyDetailInvestPay>
  );
}
