import { useState, useEffect } from 'react';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const useViewMobile = (viewport?: number) => {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWidth(width);
  };
  const isMobile = width < (viewport ?? parseInt(breakpoints.tablet));

  return isMobile;
};
