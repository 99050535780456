import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PersonalInformationContainer, Banner, FormContainer, Buttons } from './simaf.styles';
import axios from 'axios';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ModalAllDataRequired from 'src/components/shared/modals/allDataRequired/allDataRequired';
import ModalErrorUploadingInput from 'src/components/shared/modals/errorUploadingInput/errorUploadingInput';
import { FormValues } from './simaf.types';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import options from 'src/constants/simafOptionsApplicant';
import { fetchPersonalInformationApplicant } from 'src/store/Profile/profile.slice';
import { url } from 'src/environments';
import { InputTextArea } from 'src/utilities/UILibrary/Forms/InputTextArea/InputTextArea';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { setLoadingMessage, setLoadingState } from 'src/store/User/user.slice';

export default function Simaf() {
  const [allDataRequired, setAllDataRequired] = useState(false);
  const [errorUploadingInput, setErrorUploadingInput] = useState(false);
  const user = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormValues>();
  const [monthlyAmount, monthlyOperations] = options;
  const { nextStep } = useManageSteps();

  const loanDestination = useSelector(
    (state: RootState) => state.profile.personalInformation.data?.getApplicant?.loanDestination
  );

  const monthlyOperationsOptions = monthlyOperations.map((operation) => ({
    value: operation.code,
    label: operation.name
  }));
  const monthlyAmountOptions = monthlyAmount.map((amount) => ({
    value: amount.code,
    label: amount.name
  }));

  //Modificar el profile.slice a typescript para poder utilizar este useEffect
  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformationApplicant(user.id));
    }
  }, [user.id]);
  type Option = {
    name: string;
    code: number | string;
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const findOptionByCode = (options: Option[], code: number | string): string => {
      const foundOption = options.find((opt) => opt.code.toString() === code.toString());
      return foundOption ? foundOption.name : '';
    };
    //Ejecuta el endpoint registro-cuenta-persona-moral de STP
    const stpInput = {
      clientId: user.id,
      tipoCliente: 'applicant'
    };
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registro-cuenta-persona-moral`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: stpInput
    };
    try {
      dispatch(setLoadingMessage(''));
      dispatch(setLoadingState(true));
      await axios(config);
      const updateInput = {
        id: user.id,
        typeOfUser: 'applicant',
        simaf_frecuencia: data.monthlyOperations.toString() || '',
        simaf_monto: data.monthlyAmount.toString() || '',
        frecuencia: findOptionByCode(monthlyOperations, data.monthlyOperations),
        monto: findOptionByCode(monthlyAmount, data.monthlyAmount),
        dateTime: Date.now(),
        tipoCuenta: 0, //Inversionista es 1
        tipoFinanciamiento: 1,
        tipoProducto: 'Crédito Simple',
        nivelCuenta: 1, //Resolver de matriz de riesgos de oficial de cumplimiento
        numeroCuentaProyecto: user.id,
        regimen: 0, //Normal (0) o Simlpificado (1)
        origenRecursos: 'Inversionistas a través de FinSphera',
        destinoRecursos: loanDestination,
        proposito: data.investmentDescription
      };
      const simaf = await axios.post(`${url}/simaf/create-table`, updateInput);
      await nextStep();
      navigate('/solicitante/onboarding-phone');
      return simaf;
    } catch (error) {
      console.error(error);
      setErrorUploadingInput(true);
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const closeFlow = () => {
    navigate('/');
  };

  return (
    <PersonalInformationContainer>
      <ModalAllDataRequired
        showModalAllDataRequired={allDataRequired}
        setAllDataRequired={setAllDataRequired}
      />
      <ModalErrorUploadingInput
        showModalErrorUploadingInput={errorUploadingInput}
        setErrorUploadingInput={setErrorUploadingInput}
      />
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_personalInformation2_banner.png`}
            alt="icono informacion personal"
          />
        </figure>
        <h2>Perfil de uso de aplicación</h2>
      </Banner>
      <FormContainer>
        <Card className="card-container" isSlim isForm>
          <Card.Row>
            <Controller
              name="monthlyOperations"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  wrapLabel
                  label="Número de operaciones mensuales:"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={monthlyOperationsOptions}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>
          <Card.Row>
            <Controller
              name="monthlyAmount"
              control={control}
              defaultValue={undefined}
              rules={{ required: 'Es un campo requerido.' }}
              render={({ field, fieldState, ...rest }) => (
                <InputField
                  wrapLabel
                  label="Monto acumulado de operaciones mensuales:"
                  placeholder="Selecciona una opción"
                  isDropdown
                  options={monthlyAmountOptions}
                  onChangeInternalValue={field.onChange}
                  errorMessages={
                    fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                  }
                  {...rest}
                />
              )}
            />
          </Card.Row>
          <Card.Row>
            <Controller
              name="investmentDescription"
              control={control}
              rules={{
                minLength: {
                  value: 10,
                  message: 'Descripción de mínimo 10 caracteres'
                },
                maxLength: {
                  value: 100,
                  message: 'Descripción de máximo 100 caracteres'
                },
                required: 'Es un campo requerido.'
              }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputTextArea
                  label="Propósito de solicitud de crédito con Finsphera:"
                  placeholder="Breve descripción"
                  maxLength={100}
                  onChange={onChange}
                  errorMessages={error && error.message ? [error.message] : []}
                  wrapLabel
                  {...rest}
                />
              )}
            />
          </Card.Row>
        </Card>
      </FormContainer>
      <Buttons>
        <Button size="small" onClick={closeFlow}>
          Cerrar
        </Button>
        <Button size="small" type="submit" onClick={handleSubmit(onSubmit)}>
          Siguiente
        </Button>
      </Buttons>
    </PersonalInformationContainer>
  );
}
