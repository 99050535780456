function createNumber() {
  const numbers = '0123456789';
  let code = '';
  const firstDigit = Math.floor(Math.random() * (numbers.length - 1)) + 1;
  code = numbers.charAt(firstDigit);
  for (let i = 0; i < 6; i++) {
    const indiceAleatorio = Math.floor(Math.random() * numbers.length);
    code += numbers.charAt(indiceAleatorio);
  }
  return code;
}

export default createNumber;
