import { useSelector } from 'react-redux';
import { AddressContainer, AdressSpecific } from './address.style';

export default function Address({ addressInformation }) {
  const user = useSelector((state) => state.user.currentUser);
  const address = addressInformation.data.getAddress;
  return (
    <AddressContainer>
      <AdressSpecific>
        <div>
          <h3>Código postal:</h3>
          <p>{address?.postalCode ? address?.postalCode : '-'}</p>
        </div>

        <div>
          <h3>Alcaldía o Municipio:</h3>
          <p>{address?.district ? address?.district : '-'}</p>
        </div>

        <div>
          <h3>Ciudad:</h3>
          <p>{address?.city ? address?.city : '-'}</p>
        </div>

        <div>
          <h3>Estado:</h3>
          <p>{address?.state ? address?.state : '-'}</p>
        </div>
      </AdressSpecific>
      <AdressSpecific>
        <div>
          <h3>Número exterior:</h3>
          <p>{address?.numExt ? address?.numExt : '-'}</p>
        </div>

        <div>
          <h3>Número interior:</h3>
          <p>{address?.numInt ? address?.numInt : '-'}</p>
        </div>

        <div>
          <h3>Calle:</h3>
          <p>{address?.street ? address?.street : '-'}</p>
        </div>

        <div>
          <h3>Colonia:</h3>
          <p>{address?.neighborhood ? address?.neighborhood : '-'}</p>
        </div>
      </AdressSpecific>
    </AddressContainer>
  );
}
