import { forwardRef } from 'react';
import { BannerProps } from './banner.interface';
import { BannerContainer } from './banner.styles';

export const Banner = forwardRef<HTMLDivElement, BannerProps>((props, ref) => {
  const { children, imgSrc, rightElement } = props;
  const isRightElement = imgSrc || rightElement ? true : false;

  return (
    <BannerContainer ref={ref} isRightElement={isRightElement} {...props}>
      <div className="banner-copy">{children}</div>
      {isRightElement ? (
        <div className="image-container">
          {imgSrc ? <img src={imgSrc} alt="Imagen del baner" /> : null}
          {rightElement ? <div className="right-element">{rightElement}</div> : null}
        </div>
      ) : null}
    </BannerContainer>
  );
});

Banner.displayName = 'Banner';
