import { css } from 'styled-components/macro';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import { graySphera } from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const ComponentClasses = css`
  // Onboarding Classes
  .onboarding-banner {
    margin-bottom: 1rem;

    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: 3rem;
    }
  }

  .onboarding-card {
    ${spaceFlowContainer()}
    min-height: 19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    > h2 {
      color: ${graySphera};
      font-size: 1.5vw;
      margin-top: 5%;
    }

    > div {
      width: 80%;
    }
  }

  .onboarding-view-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background-color: rgb(245, 245, 245);
  }

  // Landing Classes (some classes repeated to have a separation of concerns)
  .landing-banner {
    margin-bottom: 1rem;

    @media (min-width: ${breakpoints.desktop}) {
      margin-bottom: 3rem;
    }
  }

  .landing-card {
    ${spaceFlowContainer()}
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    > h2 {
      color: ${graySphera};
      font-size: 1.5vw;
      margin-top: 5%;
    }

    > div {
      width: 80%;
    }
  }
`;
