import styled from 'styled-components';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const OurPartnersSectionContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-bottom: 6.2rem;

  h1 {
    text-align: center;
    font-weight: bold;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px;
    padding: 0 20px;
  }

  .carousel {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    overflow-x: auto;
    width: calc(100% - 80px);
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .carousel-item {
      height: 50px;
      flex: 0 0 auto;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .carousel {
      gap: 100px;

      .carousel-item {
        height: 100px;
      }
    }

    margin-bottom: 7.75rem;
  }
`;
