import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  CardContainer,
  CardLogo,
  CardSection,
  CardIcons,
  CardStatus,
  ImagesContainer
} from './card.styles';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';

export default function Card({ item, esgData }) {
  const defaultImage = `${process.env.REACT_APP_BUCKET_FINSPHERA}/image.png`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const nombre = item?.applicant?.companyInformation?.companyName?.legalName;
  const companyName = item?.applicant?.companyInformation?.companyName?.legalName;
  const esgApproved = esgData?.environmentalAndSocialImpact?.isApproved;

  const percentageFinanced =
    item.collectedAmount >= 0 ? Math.round((item.collectedAmount / item.montoSolicitado) * 100) : 0;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }

  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  const handleInvest = () => {
    navigate('/inversionista/dashboard/oportunidadesDetalle', { state: { detail: item } });
  };

  return (
    <CardContainer key={item.index}>
      <CardLogo>
        <ImagesContainer>
          <img
            src={`https://finsphera-documents.s3.amazonaws.com/${user.id}/media/profileImage.jpg`}
            alt="CompanyLogo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultImage;
            }}
          />
          {esgApproved === true ? (
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/ESG_2.png`}
              alt="Imagen de referencia ESG"
            />
          ) : (
            <></>
          )}
        </ImagesContainer>
        <h1>{item?.applicant?.companyInformation?.companyName?.legalName}</h1>
      </CardLogo>
      <CardSection>
        <div>
          <h3>Nombre de campaña: </h3>
          <p>{item.nombreCampania}</p>
        </div>
        <div>
          <h3>Monto: </h3>
          <p>{formatCurrency(item.montoSolicitado)}</p>
        </div>
        <div>
          <h3>Tasa del crédito : </h3>
          <p>{Number(item.tasaCreditoPorcentaje).toFixed(2) * 100} %</p>
        </div>
      </CardSection>
      <CardSection>
        <div>
          <h3>Plazo (días): </h3>
          <p>{item.plazoCredito}</p>
        </div>
        <div>
          <h3>Destino del crédito: </h3>
          <p>{item.destinoCredito}</p>
        </div>
        <div>
          <h3>Fecha de publicación: </h3>
          <p>{formatDate(item.dateStartCampaign)}</p>
        </div>
      </CardSection>
      <CardSection>
        <div>
          <h3>Fecha de finalización de campaña: </h3>
          <p>{formatDate(item.dateEndCampaign)}</p>
        </div>
        <div>
          <h3>Fuente para pago de crédito:</h3>
          <p>{item.fuentePago}</p>
        </div>
      </CardSection>
      <CardStatus percentage={percentageFinanced}>
        <h3>% financiado</h3>
        <div>
          <div></div>
        </div>
      </CardStatus>
      <button onClick={handleInvest}>Ver detalle de crédito</button>
    </CardContainer>
  );
}
