import { Helmet, HelmetData } from 'react-helmet-async';
import { config } from 'src/environments';

export default function HeadManager() {
  const isDev = process.env.REACT_APP_ENV === 'dev';

  const helmetData = new HelmetData({});
  return (
    <Helmet helmetData={helmetData}>
      {isDev && <meta name="robots" content="noindex, nofollow" />}
      <meta name="description" content="Plataforma de crowdfunding" />
      {config.env === 'prod' ? (
        <script>
          {`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:3919369,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      ) : null}
    </Helmet>
  );
}
