import { css } from 'styled-components/macro';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const spacing = {
  appPaddingMobile: '1rem',
  appPadding: '7.5rem',
  appMarginMobile: '1rem',
  appMargin: '2rem'
};

export const spaceFlowContainer = (isFullWidth = false) => css`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  width: ${isFullWidth === true ? '100%' : '95%'};

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.75rem;
    width: ${isFullWidth === true ? '100%' : '90%'};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.25rem;
    width: ${isFullWidth === true ? '100%' : '70%'};
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3rem;
    width: ${isFullWidth === true ? '100%' : '60%'};
  }
`;

export const spaceFlowContainerInPadding = () => css`
  padding: 0 2.5% 1.25rem 2.5%;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 5% 1.75rem 5%;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 15% 2.25rem 15%;
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    padding: 0 20% 3rem 20%;
  }
`;
