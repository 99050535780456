import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

export const renderShowInfoModal = (isOpen: boolean, onClose: () => void) => (
  <ComposedModal
    isOpen={isOpen}
    onClose={onClose}
    type="warning"
    messageTitle="¿Qué significa Soy un Proveedor de Recursos o un Propietario Real?"
    messageChildren={
      <>
        <b>PROVEEDOR DE RECURSOS</b>
        <p>
          Es aquella persona que, sin ser el titular de una cuenta abierta en una Entidad, aporta
          recursos a esta de manera regular sin obtener los beneficios económicos derivados de esa
          cuenta u operación.
        </p>
        <br />

        <b>PROPIETARIO REAL</b>

        <p>
          Es aquella persona que, por medio de otra o de cualquier acto o mecanismo, obtiene los
          beneficios derivados de una cuenta, contrato u operación y es quien, en última instancia,
          ejerce los derechos de uso, disfrute, aprovechamiento o disposición de los recursos, esto
          es, como el verdadero dueño de los recursos.
        </p>
      </>
    }
  />
);
