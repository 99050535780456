import styled from 'styled-components/macro';
import {
  greenSp,
  graySphera,
  whiteSphera,
  grayPlaceholder,
  grayLightSphera,
  brandBlack,
  grayMediumSphera
} from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalCancelAccount = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.cancelModal ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.cancelModal ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;
  > div {
    width: 50%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      width: 50%;
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;
        > h3 {
          font-size: 4.5vw;
        }
        > figure {
          > img {
          }
        }
      }
    }
  }
`;

export const Figure = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  > div {
    width: 80%;
  }
  > figure {
    width: 25px;
    height: 25px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  padding-bottom: 1.5%;
  > button {
    color: ${whiteSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: ${greenSp};
    cursor: pointer;
  }
`;

export const ConfirmSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  margin: 0 10%;
  padding-bottom: 1.5%;
  > button {
    color: ${whiteSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: ${greenSp};
    cursor: pointer;
    margin-top: 5%;
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 5%;
  > h4 {
    color: ${grayMediumSphera};
    font-weight: bold;
    text-align: left;
    font-size: 1vw;
    padding: 1% 5%;
  }
  > h2 {
    color: ${greenSp};
    font-weight: bold;
    text-align: center;
    font-size: 2vw;
  }
  > p {
    width: 80%;
    text-align: center;
    margin: 5%;
    font-size: 1.5vw;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 2% 5%;
    justify-content: space-evenly;
    align-items: center;
    > p {
      width: 80%;
      text-align: center;
      margin: 5%;
      font-size: 1.5vw;
    }
    > figure {
      img {
        width: 30px;
        height: auto;
        margin: 0 5%;
      }
    }
  }
  Button {
    margin-top: 5%;
  }

  textarea {
    width: 90%;
    margin: 0 5%;
    padding: 1%;
  }
`;

export const ErrorDisplay = styled.div`
  color: red;
  padding: 10px;
  border: 1px solid red;
  margin: 10px 0;
  border-radius: 4px;
  background-color: ${whiteSphera};
`;
