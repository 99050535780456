import { useRef } from 'react';
import { OurPartnersSectionContainer } from './ourPartners.styles';
import AficoLogo from 'src/assets/icons/partners/afico-logo.png';
import AiimxLogo from 'src/assets/icons/partners/aiimx-logo.png';
import FintechuLogo from 'src/assets/icons/partners/fintechu-logo.png';
import MefLogo from 'src/assets/icons/partners/mef-logo.jpg';
import OnuLogo from 'src/assets/icons/partners/onu-esg-logo.png';
import PactoGlobalLogo from 'src/assets/icons/partners/pacto-global-logo.png';

export const OurPartnersSection = () => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      const scrollAmount = carouselRef.current.offsetWidth;
      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <OurPartnersSectionContainer>
      <button onClick={scrollLeft}>&lt;</button>
      <div className="carousel" ref={carouselRef}>
        <img src={PactoGlobalLogo} alt="Pacto Global" className="carousel-item" />
        <img src={FintechuLogo} alt="Fintechu" className="carousel-item" />
        <img src={AficoLogo} alt="Afico" className="carousel-item" />
        <img src={MefLogo} alt="Mef" className="carousel-item" />
        <img src={AiimxLogo} alt="Aiimx" className="carousel-item" />
        <img src={OnuLogo} alt="Onu" className="carousel-item" />
      </div>
      <button onClick={scrollRight}>&gt;</button>
    </OurPartnersSectionContainer>
  );
};
