import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Success, Figure, ActionSection, ModalSign, ContainerText } from './contractsSign.styles';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Auth } from 'aws-amplify';
import { setAppError } from 'src/store/app/app.slice';
import { url } from 'src/environments';
import { useNavigate } from 'react-router-dom';

export default function ContractsSign({ showModal, setShowModal }) {
  const [password, setPassword] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const userType = user.groupName;

  const closeModal = () => {
    if (userType === 'investor') {
      navigate('/inversionista/dashboard/mi-perfil');
    } else if (userType === 'applicant') {
      navigate('/solicitante/dashboard/mi-perfil');
    }
    setShowModal(false);
  };

  const handleVerifyPassword = async (event) => {
    event.preventDefault();
    try {
      setLocalLoading(true);
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.signIn(currentUser.username, password);
      const input = {
        type: 'update_isContractSigned',
        input: {
          id: user.id,
          typeOfUser: userType,
          key: 'isContractSigned'
        }
      };
      const response = await axios.post(`${url}/admin/dynamo/update`, input);
      const parsedBody = JSON.parse(response.data.body);
      if (parsedBody.result === true) {
        setSuccessText(true);
        setErrorMessage('');
      } else {
        setAppError('No se pudo firmar el contrato');
      }
    } catch (error) {
      if (error.message === 'Incorrect username or password.') {
        setErrorMessage('La contraseña que ingresaste es incorrecta');
      } else {
        setAppError('Ocurrió un error al intentar firmar el contrato');
      }
      setLocalLoading(false);
    }
    setLocalLoading(false);
  };

  return (
    <ModalSign showModal={showModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡El contrato ha sido firmado de manera exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : errorMessage ? (
        <div>
          <p>{errorMessage}</p>
          <InputField
            label="Ingresa la contraseña con la que inicias sesión:"
            value={password}
            onChangeInternalValue={(value) => setPassword(value)}
            placeholder="Contraseña Finsphera"
            required
            type="password"
          />
          <Button onClick={handleVerifyPassword} isFullWidth size="small">
            Validar de nuevo
          </Button>
        </div>
      ) : (
        <ContainerText>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <ActionSection>
            <h3>
              Para firmar tu contrato deberás ingresar la contraseña con la que inicias sesión en
              Finsphera:
            </h3>
            <form>
              <InputField
                label="Ingresa la contraseña con la que inicias sesión:"
                value={password}
                onChangeInternalValue={(value) => setPassword(value)}
                placeholder="Contraseña Finsphera"
                required
                type="password"
              />
              <p>
                Al hacer click en el botón &quot;ACEPTO&quot;, reconozco que estoy firmando este
                contrato electrónicamente, produciendo los mismos efectos que las leyes otorgan a la
                firma autógrafa.
              </p>
              <Button
                isLoading={localLoading}
                onClick={handleVerifyPassword}
                isFullWidth
                type="submit"
              >
                Acepto
              </Button>
            </form>
          </ActionSection>
        </ContainerText>
      )}
    </ModalSign>
  );
}
