import styled from 'styled-components/macro';
import {
  brandBlack,
  whiteSphera,
  greenSp,
  grayPlaceholder,
  grayLightSphera
} from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const AccountContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > form {
    > h2 {
      color: ${greenSp};
      text-align: center;
      margin-bottom: 2%;
      width: 100%;
    }

    > div {
      > p {
        color: ${brandBlack};
        font-size: ${uIFontSize.small};
        width: 100%;
      }
      > input {
        width: 100%;
        border-radius: 5px;
      }

      > select {
        color: ${grayPlaceholder};
        font-size: ${uIFontSize.small};
        width: 80%;
        height: 35px;
        margin-top: 2%;
        margin-bottom: 2%;
        padding-left: 2.5%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }
    }
  }
`;

export const CodeSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 3%;
  > p {
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    > button {
      width: 100%;
      text-align: center;
      margin: 2% 0;
    }
    > p {
      text-align: center;
    }

    > input {
      margin-bottom: 2%;
      letter-spacing: 200px;
    }
  }
`;

export const FirstValidation = styled.div`
  display: flex;
`;

export const AccountInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 2% 5%;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${whiteSphera};
  }

  th,
  td {
    padding: 16px 20px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }

  th {
    font-weight: bold;
  }

  tbody tr:nth-child(odd) {
    background-color: #f7f7f7;
  }

  tbody tr:nth-child(even) {
    background-color: ${whiteSphera};
  }
  tbody tr:hover {
    background-color: #e0e0e0;
  }
  @media (max-width: ${breakpoints.tablet}) {
    border: 0;
    thead {
      display: none;
    }
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
    }
    tr {
      margin-bottom: 15px;
    }
    td {
      text-align: right;
      padding-left: 50%;
      position: relative;
    }
    td:before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  @media (max-width: ${breakpoints.mobile}) {
    td {
      padding-left: 20%;
      padding-right: 10px;
    }
    td:before {
      padding-left: 10px;
    }
  }
`;

export const FiltersSection = styled.table`
  display: flex;
  flex-wrap: wrap;
  > h2 {
    width: 90%;
    margin: 1% 5%;
    text-align: center;
  }
  > select {
    width: 30%;
    margin: 0 35%;
  }
  > div {
    display: flex;
    width: 80%;
    margin: 2% 10%;
    justify-content: space-evenly;
  }
`;

export const FirstAction = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin: 5% 0;
`;

export const Entries = styled.div`
  display: flex;
  width: 100%;
  > p {
    width: 100%;
    font-weight: bold;
    text-align: center;
    font-size: ${uIFontSize.large};
    margin: 2% 0;
  }
`;

export const AccountStateContainer = styled.div`
  display: flex;
  width: 100%;
`;
