import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { InvestmentCampaignDemoProps } from './types';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

// todo: the animation is not finished yet and isn't the same as the one in the design, it's a first iteration

const hoverAnimation = (mobileView: boolean): FlattenSimpleInterpolation => {
  if (!mobileView) {
    return css`
      background: #ffffff;
      box-shadow: inset -3px -3px 4px #97979738, 0px 6px 24px #00000029;
      border-radius: 15px;

      .progress-bar-container .progress-bar {
        min-width: 7.8125rem;
      }
    `;
  }

  return css``;
};

export const InvestmentCampaignDemoContainer = styled.div<InvestmentCampaignDemoProps>`
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  padding: 1rem;

  * {
    transition: all 0.4s ease-in-out;
  }

  &:hover {
    ${({ mobileView = false }) => hoverAnimation(mobileView)}

    .choose-button {
      background: ${colors.button.green};
      border-radius: 9px;
      padding: 0.5rem 1.5rem;
    }
  }

  .campaign-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    .campaign-info--text {
      color: ${colors.text.gray};
      font-size: ${uIFontSize.medium};
    }

    .progress-bar-container {
      display: flex;
      flex-direction: ${({ mobileView }) => (mobileView ? 'column' : 'row')};
      justify-content: space-between;
      align-items: center;
      width: 100%;
      gap: 4px;
      overflow: hidden;
    }

    .progress-bar-container > p {
      color: ${colors.text.gray};
      font-size: ${uIFontSize.xSmall};
      margin-left: ${({ mobileView }) => (mobileView ? '0' : 'auto')};
    }
  }

  .choose-button {
    background: transparent;
    margin-left: 1.5rem;
    font-size: ${uIFontSize.large};
    color: ${colors.white.pearl};
  }

  @media (min-width: ${breakpoints.desktop}) {
    .campaign-info {
      .campaign-info--text {
        font-size: ${uIFontSize.large};
      }
    }

    .choose-button {
      font-size: ${uIFontSize.xLarge};
    }
  }
`;
