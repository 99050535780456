import { grayLight } from 'src/utilities/UIStyles/variables/colors';
export const green = {
  default: '#3FBA73',
  secondary: '#D7FF67'
};

export const blue = {
  default: '#010534',
  link: '#3383EF'
};

export const orange = {
  default: '#FFA510',
  secondary: '#FED201'
};

export const white = {
  default: '#FFFFFF',
  pearl: '#F2F2F2'
};

export const black = {
  default: '#010534',
  gray: '#979797'
};

export const red = {
  default: '#F30000'
};

export const colors = {
  green: green,
  blue: blue,
  orange: orange,
  white: white,
  black: black,
  text: {
    default: white.default,
    description: black.gray,
    green: green.default,
    gray: black.gray,
    white: white.default,
    black: black.default,
    headerYellow: orange.secondary,
    link: blue.link
  },
  background: {
    footer: blue.default,
    navbar: white.default,
    sidenav: white.default,
    app: white.default,
    card: white.default,
    white: white.default
  },
  button: {
    green: green.default,
    orange: orange.default,
    gray: black.gray
  },
  status: {
    error: red.default,
    warning: orange.secondary,
    success: green.default
  }
};
