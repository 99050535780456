import { forwardRef } from 'react';
import { InputUploadProps } from './inputUpload.types';
import { InputUploadContainer } from './inputUpload.styles';
import { UploadButton } from 'src/utilities/UILibrary/Forms/UploadButton/UploadButton';
import { ErrorMessage } from '../InputField/ErrorMessage';

export const InputUpload = forwardRef<HTMLDivElement, InputUploadProps>((props, ref) => {
  const {
    onChange,
    children,
    label,
    id,
    errorMessages = [],
    errortype,
    wrapLabel,
    ...rest
  } = props;

  const inputId = id || `input-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <InputUploadContainer wrapLabel={wrapLabel} ref={ref}>
      {label ? <label htmlFor={inputId}>{label}</label> : null}
      <UploadButton isSlim isFullWidth onChange={onChange} id={inputId} {...rest}>
        {children}
      </UploadButton>
      {errorMessages.length > 0 && (
        <ErrorMessage errorType={errortype} errorMessages={errorMessages} />
      )}
    </InputUploadContainer>
  );
});

InputUpload.displayName = 'InputUpload';
