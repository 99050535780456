import { NavMenuListProps } from './navMenuList.interface';
import { NavMenuListWrapper } from './navMenuList.styles';

export default function NavMenuList({ showMenuList, callback, renderItems }: NavMenuListProps) {
  return (
    <NavMenuListWrapper
      className={`${showMenuList ? 'is-active' : ''}`}
      onClick={callback}
      callback={callback}
      renderItems={renderItems}
      showMenuList={showMenuList}
    >
      <ul>{renderItems()}</ul>
    </NavMenuListWrapper>
  );
}
