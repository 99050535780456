import styled from 'styled-components/macro';
import { fadeIn, fadeOut } from '../../../../utilities/UIStyles/animations';
import {
  whiteSphera,
  brandBlack,
  neutralBlueGray,
  grayMediumSphera,
  greenSp,
  grayLight
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalAdviceValidation ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-y: auto;
  animation: ${(props) => (props.showModalAdviceValidation ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;
  > div {
    width: 60%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding: 1% 0;
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
    }
  }
`;

export const SectionInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 100px;
  > h1 {
    color: ${neutralBlueGray};
    font-size: ${uIFontSize.title};
  }
  > figure {
    width: 25px;
    height: 25px;
    margin-left: 90%;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  > div {
    margin: 2%;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    > input {
      border-radius: 5px;
      border: 1px solid ${grayLight};
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
      color: ${neutralBlueGray};
      width: 60%;
      margin: 1% 20%;
      height: 35px;
      text-align: center;
      letter-spacing: 2vw;
    }
    > button {
      margin: 0 5%;
    }
  }

  @media (max-width: 600px) {
    > h1 {
      font-size: ${uIFontSize.title};
    }
    > div {
      margin: 2%;
      > input {
        font-size: ${uIFontSize.paragraph};
        width: 80%;
        margin: 1% 5%;
        height: 35px;
        text-align: center;
        letter-spacing: 2vw;
      }
      > button {
        margin: 0 5%;
      }
    }
    > figure {
      width: 25px;
      height: 25px;
      margin-left: 86%;
      cursor: pointer;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const GeneralDetail = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 2%;
  width: 90%;
  margin-top: 2%;
  > h2 {
    width: 90%;
    font-size: ${uIFontSize.subTitle};
    color: ${greenSp};
    margin-bottom: 3%;
  }
  > div {
    width: 40%;
    > h3 {
      color: ${grayMediumSphera};
      font-size: ${uIFontSize.h3};
    }
    > p {
      color: ${neutralBlueGray};
      font-size: ${uIFontSize.paragraph};
    }
    > div {
      > p {
        color: ${neutralBlueGray};
        font-size: ${uIFontSize.paragraph};
      }
      > h4 {
        font-size: ${uIFontSize.paragraph};
      }
    }
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: ${uIFontSize.subTitle};
    }
    > div {
      width: 45%;
      > h3 {
        font-size: ${uIFontSize.h3};
      }
      > p {
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const DetailData = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
  width: 100%;
  margin: 2% 0;
  > h4 {
    color: ${grayMediumSphera};
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
    margin: 5% 0 3% 0;
    text-align: center;
  }
  > h2 {
    width: 90%;
    font-size: ${uIFontSize.subTitle};
    margin: 3% 0;
    color: ${greenSp};
  }
  > div {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 1% 10%;
    > h3 {
      color: ${grayMediumSphera};
      font-size: ${uIFontSize.h3};
      text-align: left;
    }
    > p {
      color: ${grayMediumSphera};
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
      text-align: right;
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: ${uIFontSize.subTitle};
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
  > p {
    font-size: ${uIFontSize.paragraph};
    color: ${neutralBlueGray};
    margin-bottom: 3%;
    > a {
      color: ${greenSp};
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
    }
  }
`;
