import { navBarValues } from 'src/components/Layout/Navbar/navbar.styles';
import styled from 'styled-components/macro';
import { showMenuList, showMenuListMobile } from 'src/utilities/UIStyles/animations';
import { grayPlaceholder, graySphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { NavMenuListProps } from './navMenuList.interface';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { spacing } from 'src/utilities/UIStyles/variables/spacing';

export const NavMenuListWrapper = styled.div<NavMenuListProps>`
  display: none;
  position: absolute;
  top: 0;
  left: 0;

  padding-top: ${navBarValues.height};
  justify-content: flex-end;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  animation: ${showMenuList} 0.5s linear;

  > ul {
    list-style: none;
    width: 50%;
    height: 100vh;
    padding: 1% 2.5%;
    border-bottom-left-radius: 10px;
    background-color: ${whiteSphera};

    > li {
      padding-top: 5%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid ${grayPlaceholder};

      > a {
        display: block;
        font-size: ${uIFontSize.large};
        color: ${graySphera};
        text-decoration: none;
        width: 78%;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    display: ${(props) => (props.showMenuList ? 'flex' : 'none')};
    transition: all 0.5s linear;
    transform: translateX(100%);
    animation: none;
    background-color: initial;

    &.is-active {
      display: flex;
      transform: translateX(0);
      animation: ${showMenuListMobile} 0.5s linear;
    }

    > ul {
      width: 100%;
      padding: 1rem ${spacing.appPaddingMobile};
      display: flex;
      flex-direction: column;

      > li {
        padding-top: 2rem;
        padding-bottom: 1rem;

        > a {
          &:hover {
          }
        }
      }
    }
  }
`;
