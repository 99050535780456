import { ReasonsOfBlocking } from 'src/types/requests/graphql';

export const getReasonOfBlocking = (
  isBlockedCountry: boolean,
  isRealOwnerOrResourceProvider: boolean
): ReasonsOfBlocking => {
  if (isBlockedCountry) return 'countryBlocked';
  if (isRealOwnerOrResourceProvider) return 'resourceProviderOrRealOwner';
  return 'resourceProviderOrRealOwner';
};
