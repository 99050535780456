import styled from 'styled-components/macro';

import {
  whiteSphera,
  blackSphera,
  graySphera,
  blueSphera
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalErrorAmount = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalErrorAmount ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalErrorAmount ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 50%;
    padding: 2.5%;
    background-color: ${whiteSphera};
    border-radius: 5px;

    > figure:nth-child(1) {
      width: 25px;
      height: 25px;
      margin-left: 96%;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(2) {
      width: 120px;
      height: 120px;
      margin: 0 auto 2.5% auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > h2 {
      color: ${blackSphera};
      font-size: 2vw;
      text-align: center;
      margin: 2.5% auto 0 auto;
    }

    > p {
      color: ${blackSphera};
      font-size: 1.2vw;
      text-align: center;
      margin: 2.5% auto 5% auto;
    }

    > button {
      display: block;
      color: ${whiteSphera};
      font-weight: bold;
      font-size: 1.2vw;
      text-align: center;
      width: 50%;
      height: 50px;
      margin: auto;
      border: none;
      border-radius: 5px;
      background-color: ${blackSphera};
      cursor: pointer;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > figure:nth-child(1) {
        > img {
        }
      }

      > figure:nth-child(2) {
        > img {
        }
      }

      > h2 {
      }

      > p {
      }

      > button {
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding: 5%;

      > figure:nth-child(1) {
        margin-left: 93%;

        > img {
        }
      }

      > figure:nth-child(2) {
        width: 80px;
        height: 80px;
        margin: 0 auto 5% auto;

        > img {
        }
      }

      > h2 {
        font-size: 5vw;
        margin: 5% auto 0 auto;
      }

      > p {
        font-size: 4.5vw;
        width: 100%;
        margin: 5% auto;
      }

      > button {
        font-size: 4.2vw;
        width: 100%;
      }
    }
  }
`;
