import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { ForgotPasswordContainer } from './forgotPassword.styles';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import PasswordValid from 'src/components/shared/PasswordValidator/PasswordValid';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { FormValues } from './forgotPassword.types';

export function ForgotPassword() {
  const [successCodeSentModal, setSuccessCodeSentModal] = useState(false);
  const [codeSended, setCodeSended] = useState(false);
  const [successViewModal, setSuccessViewModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();
  const { control, handleSubmit, watch, setError } = useForm<FormValues>();

  useEffect(() => {
    if (localError) setErrorModal(true);

    if (!localError) setErrorModal(false);
  }, [localError]);

  const watchedPassword = watch('password');

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (!codeSended) {
      setLocalLoading(true);
      try {
        await Auth.forgotPassword(data.email);
        setCodeSended(true);
      } catch (error) {
        (error as Error).message.includes('Username/client id combination not found.')
          ? setLocalError(
              'El correo electrónico no existe en la plataforma, vuelve a intentarlo o Registrate'
            )
          : setLocalError('Ocurrió un error, por favor intenta de nuevo');
      }
      setLocalLoading(false);
    }

    if (codeSended) {
      setLocalLoading(true);
      if (data.password !== data.confirmPassword || !isPasswordValid) {
        setError('confirmPassword', {
          type: 'manual',
          message: !isPasswordValid ? 'La contraseña no es válida' : 'Las contraseñas no coinciden'
        });
        return setLocalLoading(false);
      }

      const emailCode = data.emailCode.toString();

      try {
        await Auth.forgotPasswordSubmit(data.email, emailCode, data.password);
      } catch (error) {
        (error as Error).message.includes('Invalid verification code provided, please try again.')
          ? setLocalError('El código ingresado no es válido')
          : setLocalError('Ocurrió un error, por favor intenta de nuevo');
        return setLocalLoading(false);
      }

      const date = new Date();
      date.setMonth(date.getMonth() + 5);
      document.cookie = `passwordUpdated=true; expires=${date.toUTCString()}; path=/iniciar-sesion`;

      setSuccessViewModal(true);
      setLocalLoading(false);
    }
  };

  return (
    <ForgotPasswordContainer>
      <ComposedModal
        isOpen={successCodeSentModal}
        type="success"
        onClose={() => setSuccessCodeSentModal(false)}
        messageTitle="Código enviado"
        message="Ingresa el código para establecer tu nueva contraseña en FinSphera"
      />
      <ComposedModal
        isOpen={successViewModal}
        onClose={() => {
          setSuccessViewModal(false);
          navigate('/iniciar-sesion');
        }}
        type="success"
        messageTitle="Contraseña actualizada"
        message="Tu contraseña ha sido actualizada correctamente, por favor inicia sesión."
        // TODO: Save code by mix those two components
        buttons={[{ label: 'Iniciar sesión', onClick: () => navigate('/iniciar-sesion') }]}
      />
      <ComposedModal
        isOpen={errorModal}
        onClose={() => setLocalError(null)}
        type="error"
        messageTitle="Ups"
        message={localError ?? 'Ocurrió un error, por favor intenta de nuevo'}
      />
      <PlatformBanner
        title="Restablecer tu contraseña"
        description=" Para cambiar tu contraseña, por favor completa ambos campos, considerando las siguientes
          reglas y deben coincidir entre ellas:"
        className="landing-banner"
      />
      <Card className="landing-card" isSlim isForm>
        <Card.Row>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputField
                label="Ingresa tu correo electrónico:"
                placeholder="Correo electrónico"
                onChangeInternalValue={onChange}
                regex={/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/}
                errorRegexMessage="Ingresa un correo electrónico válido"
                errorMessages={error && error.message ? [error.message] : []}
                disabled={codeSended}
                {...rest}
              />
            )}
          />
        </Card.Row>

        {/* Confirm code section*/}

        {codeSended ? (
          <>
            <Card.Row>
              <Controller
                name="emailCode"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Ingresa el código:"
                    placeholder="Código"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    type="number"
                    {...rest}
                  />
                )}
              />
            </Card.Row>

            <Card.Row>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    type="password"
                    label="Ingresa tu nueva contraseña:"
                    placeholder="Contraseña"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>

            <Card.Row>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    type="password"
                    label="Confirma tu nueva contraseña:"
                    placeholder="Contraseña"
                    onChangeInternalValue={onChange}
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>

            {watchedPassword ? (
              <Card.Row>
                <p>
                  <strong>
                    Recuerda que tu nueva contraseña debe seguir las siguientes reglas de seguridad
                    para transacciones en <span>FinSphera</span>:
                  </strong>
                </p>
                <PasswordValid
                  password={watchedPassword}
                  onChangePassword={(value) => setIsPasswordValid(value)}
                />
              </Card.Row>
            ) : null}
          </>
        ) : null}

        <Card.Row>
          <Button isSlim onClick={handleSubmit(onSubmit)} isLoading={localLoading}>
            Enviar
          </Button>
        </Card.Row>

        <Card.Row>
          <p>
            ¿Recordaste tu contraseña? Inicia sesión dando click{' '}
            <Link to="/iniciar-sesion">aquí</Link>
          </p>
        </Card.Row>
      </Card>
    </ForgotPasswordContainer>
  );
}
