import styled from 'styled-components/macro';

import {
  whiteSphera,
  neutralBlueGray,
  neutralBlue,
  brandBlack,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const GeneralInfoContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 1% 2.5% 2.5% 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  > button {
    background-color: ${greenSp};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 5%;
    color: ${brandBlack};
    font-size: 1vw;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  margin: 2.5%;
  width: 100%;
  padding-bottom: 1%;
  border-bottom: 1px solid ${greenSp};
  > div {
    width: 33%;
    padding: 0 1%;

    > h3 {
      color: ${neutralBlueGray};
      font-size: 1.5vw;
    }
    > p {
      color: ${neutralBlueGray};
      font-size: 1.3vw;
      padding: 1% 0;
    }
    > input {
      border-radius: 5px;
      width: 80%;
      border-style: none;
      border: 1px solid ${neutralBlue};
      padding: 1% 0;
    }
  }
`;

export const ProfileImage = styled.div`
  width: 40%;
  margin: 2% 30%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  > h3 {
    margin: 2% 0;
    text-align: center;
    font-size: ${uIFontSize.h3};
    color: ${neutralBlueGray};
  }
  > button {
    margin-top: 3%;
  }
  > p {
    width: 100;
    font-weight: bold;
    font-size: ${uIFontSize.medium};
  }
`;
