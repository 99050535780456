import { Auth } from 'aws-amplify';

export const getJsonHeadersWithAuth = async () => {
  try {
    const session = await Auth.currentSession();
    if (!session) {
      throw new Error('No session available');
    }
    return {
      'Content-Type': 'application/json',
      Finsphera_Auth: session.getIdToken().getJwtToken()
    };
  } catch (error) {
    console.error(error);
  }
};
