import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { ModalInfoVirtualAccount, DetailInfoVirtualAccount } from './infoVirtualAccount.styles';
import { getSTP_MainInfo } from 'src/graphql/customized_queries';

export default function InfoVirtualAccount({ showModalInfoVirtualAccount, setModal, amount }) {
  const [available, setAvailable] = useState(false);
  const [stpInfo, setStpInfo] = useState();
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getSTP_MainInfo,
          variables: { id: user.id }
        });
        setStpInfo(data.getSTP);
      } catch (error) {
        console.error(error);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id]);
  function formatCurrency(availableAmount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(availableAmount);
  }
  const closeModal = () => setModal(false);

  return (
    <ModalInfoVirtualAccount showModalInfoVirtualAccount={showModalInfoVirtualAccount}>
      <div>
        <div>
          <h3>Estos son los datos de tu Cuenta en FinSphera</h3>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </div>
        <DetailInfoVirtualAccount isAvailable={available}>
          <div>
            <h3>Saldo actual disponible en Finsphera:</h3>
            <p>{amount ? formatCurrency(amount) : '0.00'}</p>
          </div>
          <p>Estos son los datos para la transferencia bancaria:</p>
          <ul>
            <li>
              Institución de Financiamiento Colectivo Banco: <p>STP</p>
            </li>
            <li>
              CLABE
              <p>{stpInfo?.CLABE ? stpInfo?.CLABE : '-'}</p>
            </li>
          </ul>
          <button onClick={closeModal}>Cancelar</button>
        </DetailInfoVirtualAccount>
      </div>
    </ModalInfoVirtualAccount>
  );
}
