import { useRedirectPath } from 'src/hooks/useRedirectPath';
import { onboardingMapApplicantRoutes } from 'src/constants/onboardingMapRoutes';
import {
  onboardingMapInvestorMoralRoutes,
  onboardingMapInvestorPhysicalRoutes
} from 'src/constants/onboardingMapRoutes';

type HandleRedirectFromLoginProps = {
  typeOfUser: 'investor' | 'applicant';
  typeOfPerson: 'FISICA' | 'MORAL';
};

const ROUTE_MAP = {
  investor: {
    FISICA: onboardingMapInvestorPhysicalRoutes,
    MORAL: onboardingMapInvestorMoralRoutes,
    dashboard: '/inversionista/dashboard'
  },
  applicant: {
    onboarding: onboardingMapApplicantRoutes,
    dashboard: '/solicitante/dashboard'
  }
};

// make a invalid user error
export const useHandleLoginRedirect = () => {
  const redirectTo = useRedirectPath();

  const handleRedirectFromLogin = (
    { typeOfPerson, typeOfUser }: HandleRedirectFromLoginProps,
    toStep?: number,
    onboardingComplete?: boolean
  ) => {
    const step = toStep ? toStep - 1 : 0;

    if (onboardingComplete) {
      return redirectTo(ROUTE_MAP[typeOfUser].dashboard);
    }

    if (typeOfUser === 'investor') {
      if (ROUTE_MAP.investor[typeOfPerson]) {
        return redirectTo(ROUTE_MAP.investor[typeOfPerson][step].path);
      }
    }

    if (typeOfUser === 'applicant') {
      return redirectTo(ROUTE_MAP.applicant.onboarding[step].path);
    }

    throw new Error('Usuario invalido');
  };

  return { handleRedirectFromLogin };
};
