import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import {
  ModalCampaignSuccess,
  InfoContainer,
  ContentContainer,
  ButtonsContainer,
  ConfirmButton,
  CloseButton,
  MainInfo,
  ComplementaryInfo,
  CampaignCode,
  Success
} from './campaignSuccess.styles';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { url } from 'src/environments';
import axios from 'axios';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export default function ConfirmCampaignModal({
  showModal,
  onClose,
  onConfirm,
  campaignData,
  selectedObligado
}) {
  const [showInput, setShowInput] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [messageId, setMessageId] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [successText, setSuccessText] = useState(false);
  const user = useSelector((state) => state.user.currentUser);

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const handleSendEmail = async (event) => {
    const input = {
      id: user.id,
      typeOfUser: 'applicant',
      typeMessage: 'createCampaign',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };
  const handleOtpSubmit = async (event) => {
    event.preventDefault();
    setLocalLoading(true);
    const input = {
      id: user.id,
      messageId: messageId,
      code: verificationCode,
      timestamp: Date.now(),
      typeOfUser: 'applicant'
    };
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        alert('Validación de código fallida');
        return;
      }
      await onConfirm();
      setSuccessText(true);
    } catch (error) {
      console.error(error);
      setLocalLoading(false);
    }
    setLocalLoading(false);
  };
  return (
    <ModalCampaignSuccess showModalCampaignSuccess={showModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de operación exitosa"
            />
          </figure>
          <h2>¡Tu campaña se ha registrado de forma exitosa!</h2>
          <p>
            Puedes ver el avance de tu campaña en{' '}
            <Link to="/solicitante/dashboard/campañas">Mis campañas</Link>
          </p>
          <Button onClick={onClose}>Cerrar</Button>
        </Success>
      ) : (
        <ContentContainer>
          <h2>
            ¡Estás apunto de crear una campaña con <span>FINSPHERA!</span>
          </h2>
          <InfoContainer>
            <MainInfo>
              <h3>Nombre de campaña:</h3>
              <p> {campaignData?.nombreCampania ? campaignData?.nombreCampania : '-'} </p>
              <h3>Monto del crédito:</h3>
              <p>
                {formatCurrency(campaignData?.montoSolicitado)
                  ? formatCurrency(campaignData?.montoSolicitado)
                  : '-'}
              </p>
              <h3>Plazo en días:</h3>
              <p>{campaignData?.plazoCredito ? campaignData?.plazoCredito : '-'}</p>
              <h3>Destino del crédito:</h3>
              <p>{campaignData?.destinoCredito ? campaignData?.destinoCredito : '-'}</p>
              <h3>Fecha de publicación:</h3>
              <p>
                {formatDate(campaignData?.dateStartCampaign)
                  ? formatDate(campaignData?.dateStartCampaign)
                  : '-'}
              </p>
              <h3>Plazo máximo de fondeo:</h3>
              <p>
                {formatDate(campaignData?.dateEndCampaign)
                  ? formatDate(campaignData?.dateEndCampaign)
                  : '-'}
              </p>
              <h3>Obligado Solidario</h3>
              <p>{selectedObligado}</p>
            </MainInfo>
            <ComplementaryInfo>
              <table>
                <thead>
                  <tr>
                    <th>Concepto</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Tasa de interés del crédito</td>
                    <td>
                      {Number(campaignData?.tasaCreditoPorcentaje * 100).toFixed(2)
                        ? Number(campaignData?.tasaCreditoPorcentaje * 100).toFixed(2)
                        : '-'}{' '}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td>Interés del crédito en pesos</td>
                    <td>
                      {formatCurrency(campaignData?.interesCreditoPesos)
                        ? formatCurrency(campaignData?.interesCreditoPesos)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>IVA del interés del crédito en pesos</td>
                    <td>
                      {campaignData?.IVAinteresCreditoPesos
                        ? formatCurrency(campaignData?.IVAinteresCreditoPesos)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Interés del crédito en pesos + IVA</td>
                    <td>
                      {campaignData?.interesesCreditoPesosConIVA
                        ? formatCurrency(campaignData?.interesesCreditoPesosConIVA)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Comisión del crédito</td>
                    <td>
                      {campaignData?.comisionCreditoPesos
                        ? formatCurrency(campaignData?.comisionCreditoPesos)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>IVA de la comisión del crédito</td>
                    <td>
                      {campaignData?.IVAcomisionCreditoPesos
                        ? formatCurrency(campaignData?.IVAcomisionCreditoPesos)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Comisión del crédito + IVA</td>
                    <td>
                      {campaignData?.comisionCreditoPesosConIVA
                        ? formatCurrency(campaignData?.comisionCreditoPesosConIVA)
                        : '-'}
                    </td>
                  </tr>
                  <tr>
                    <td>Tasa de interés moratorio</td>
                    <td>
                      {(campaignData?.tasaInteresMoratorioPorcentaje * 100).toFixed(2)
                        ? (campaignData?.tasaInteresMoratorioPorcentaje * 100).toFixed(2)
                        : '-'}{' '}
                      %
                    </td>
                  </tr>
                  <tr>
                    <td>Monto de inversión de Finsphera en la campaña</td>
                    <td>
                      {formatCurrency(campaignData?.inversionFinspheraCampaniaPesos)
                        ? formatCurrency(campaignData?.inversionFinspheraCampaniaPesos)
                        : '-'}
                    </td>
                  </tr>
                </tbody>
              </table>
            </ComplementaryInfo>
          </InfoContainer>
          {showInput ? (
            <>
              <CampaignCode>
                <h3>Ingresa el código de verificación</h3>
                <>
                  <InputField
                    value={verificationCode}
                    maxLength={6}
                    onChangeInternalValue={(value) => setVerificationCode(value)}
                    type="number"
                    placeholder="Código de verificación"
                  />
                </>
              </CampaignCode>
              <ButtonsContainer>
                <CloseButton onClick={onClose}>Cancelar</CloseButton>
                <Button isLoading={localLoading} onClick={handleOtpSubmit}>
                  Confirmar código
                </Button>
              </ButtonsContainer>
            </>
          ) : (
            <ButtonsContainer>
              <CloseButton onClick={onClose}>Cancelar</CloseButton>
              <ConfirmButton onClick={handleSendEmail}>Aceptar</ConfirmButton>
            </ButtonsContainer>
          )}
        </ContentContainer>
      )}
    </ModalCampaignSuccess>
  );
}
