import axios from 'axios';
import { IpType } from 'src/requests';

const getIp = async () => {
  try {
    const ip = await axios.get('https://api.ipify.org?format=json');

    const data: IpType = {
      IPv4: ip.data.ip,
      date: new Date().toISOString()
    };

    return data;
  } catch (error) {
    const data: IpType = {
      IPv4: '',
      date: new Date().toISOString()
    };

    return data;
  }
};

export default getIp;
