import styled from 'styled-components/macro';
import {
  whiteSphera,
  greenSp,
  brandBlack,
  grayDarkSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const DocumentsInfoContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 2.5%;
  justify-content: space-between;
  align-items: center;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > h2 {
    width: 90%;
    margin: 0 5% 2rem;
    font-size: ${uIFontSize.large};
    text-align: center;
    color: ${greenSp};
  }
  > h1 {
    width: 90%;
    margin: 0 5% 2rem;
    font-size: ${uIFontSize.large};
    text-align: center;
    color: ${grayDarkSphera};
  }

  .submit-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
  }
`;

export const DashboardDocuments = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  min-height: 40vh;
  height: fit-content;
  margin: 2% 0;

  .button-box {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%;
    height: 50%;
    margin: 2% 0;
    > p {
      font-size: ${uIFontSize.medium};
      height: 50%;
      color: ${brandBlack};
    }

    > div p,
    label {
      font-weight: bold;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto 5% auto;
    row-gap: 1.5rem;

    > div {
      width: 100%;
    }
  }
`;
