import styled from 'styled-components/macro';
import {
  blackSphera,
  brandBlack,
  brandYellowgreen,
  grayMediumSphera,
  grayPlaceholder,
  greenSp,
  supportLemonGreen,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import { neutralLight } from 'src/utilities/UIStyles/variables/colors';

export const DetailContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 2%;
`;

export const MainDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 35%;
  margin: 2% 5%;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  @media (min-width: 600px) and (max-width: 1024px) {
    width: 40%;
  }
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const CardLogo = styled.figure`
  height: auto;
  margin: 2%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
  > img {
    width: 40%;
    height: auto;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
  }
`;

export const InvestDetail = styled.figure`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: ${grayMediumSphera};
  background-color: ${brandBlack};
  > div {
    width: 45%;
    padding: 3% 1%;
    > h2 {
      color: ${whiteSphera};
      font-weight: bold;
      font-size: 1.2vw;
    }
    > p {
      color: ${brandYellowgreen};
      font-size: 1vw;
      margin: 2% 0;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      width: 45%;
      > h2 {
        font-size: 1.5vw;
      }
      > p {
        font-size: 1vw;
      }
    }
  }
  @media (max-width: 600px) {
    width: 90%;
    margin: 2% 5%;
    > div {
      width: 45%;
      margin: 0 1%;
      text-align: center;
      > h2 {
        font-size: 3vw;
      }
      > p {
        font-size: 3vw;
      }
    }
  }
`;

export const FilterButton = styled.button`
  width: 25%;
  height: 40px;
  border-radius: 5px;
  font-size: 1.5vw;
  color: ${(props) => (props.isActive ? whiteSphera : blackSphera)};
  background-color: ${(props) => (props.isActive ? blackSphera : neutralLight)};
  border: none;
  @media (max-width: 600px) {
    font-size: 3vw;
  }
`;

export const FilterSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 90%;
  padding: 2% 10%;
  > p {
    text-align: center;
    font-size: 1.5vw;
    width: 100%;
    font-weight: bold;
    color: ${brandBlack};
    margin-bottom: 5%;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > p {
      font-size: 3vw;
    }
  }
`;

export const CampaignInfo = styled.div`
  border-radius: 10px;
  width: 90%;
  margin: 2% 2.5%;
  padding: 1% 2%;
  font-size: 1vw;
  > Button {
    background-color: ${greenSp};
    width: 40%;
    margin: 0 30%;
  }
  > div {
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
      font-size: 1.2vw;
    }
    > div {
      > h2 {
        font-size: 1.5vw;
      }
      > p {
        font-size: 1.2vw;
      }
    }
  }
  @media (max-width: 600px) {
    > p {
      font-size: 2.8vw;
    }
    > div {
      > h2 {
        font-size: 1.8vw;
      }
      > p {
        font-size: 2.6vw;
      }
    }
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  background-color: ${neutralLight};
  padding: 3% 2%;
  ${spaceFlowContainer(true)}
  > h2 {
    color: ${blackSphera};
    font-size: 3vw;
    width: 100%;
    text-align: center;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    padding: 5% 0;
    > h2 {
      font-size: 6vw;
      width: 100%;
      margin-bottom: 2%;
      text-align: center;
    }
  }
`;

export const ComplementaryInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${neutralLight};
  width: 100%;
  margin: 2% 0 2% 0;
  > div {
    width: 45%;
    margin: 1% 2.5%;
    padding: 1%;
    > h2 {
      font-size: 1.2vw;
      color: ${grayMediumSphera};
    }
    > p {
      font-size: 1.2vw;
      color: ${brandBlack};
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > div {
      > h2 {
        font-size: 2.3vw;
      }
      > p {
        font-size: 2.6vw;
      }
    }
  }
`;

export const CardStatus = styled.div`
  width: 100%;
  padding: 5% 10%;
  > h3 {
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    color: ${brandBlack};
    margin-bottom: 5%;
  }
  > div {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: #f0f5ff;
    > div {
      width: ${(props) => props.percentage && `${props.percentage}%`};
      height: 100%;
      border-radius: 10px;
      background-color: ${greenSp};
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > h3 {
      font-size: 3vw;
    }
  }
`;

export const MainInfo = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 4% 0;
  border: 1px solid ${grayPlaceholder};
  border-radius: 5px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: 8px;
      text-align: left;
      border-bottom: 1px solid ${grayPlaceholder};
    }

    th {
      width: 60%;
      color: ${brandBlack};
      font-size: 1vw;
    }

    td {
      color: ${grayMediumSphera};
      font-size: 1vw;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    table {
      th,
      td {
        font-size: 2.8vw;
      }
    }
  }
`;

export const CardIcons = styled.div`
  display: flex;
  width: 100%;
  margin: 0 5%;
  > div:nth-child(1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 5% 0;
    padding: 5% 2% 2.5% 2%;
    width: 70%;
    > h3 {
      font-size: 1.3vw;
      font-weight: bold;
      margin-bottom: 5%;
    }
    > a {
      display: block;
      color: ${whiteSphera};
      font-size: 1vw;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      padding: 1% 0;
      width: 70%;
      margin: 5% 20% 5% 0;
      border-radius: 5px;
      background-color: ${blackSphera};
    }
    > p {
      font-size: 1vw;
      color: ${supportLemonGreen};
      font-weight: bold;
      width: 100%;
      text-align: left;
      margin-bottom: 2%;
      > h3 {
        font-size: 1.5vw;
        color: ${supportLemonGreen};
        font-weight: bold;
        text-align: center;
      }
    }
  }
  > div:nth-child(2) {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5% 0;
    > img {
      width: 40%;
      height: auto;
    }
    > h2 {
      font-size: 1.2vw;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h2 {
        font-size: 2vw;
      }
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(1) {
      > p {
        font-size: 3vw;
      }
      > h3 {
        font-size: 3vw;
      }
    }
    > div:nth-child(2) {
      > h2 {
        font-size: 2.5vw;
      }
    }
  }
`;
