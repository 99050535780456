import styled from 'styled-components/macro';

import { blueSphera, graySphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalSuccessDetailInvestment = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalSuccessDetailInvestment ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalSuccessDetailInvestment ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 60%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid #b7bcc7;

      > h3 {
        font-size: 1.5vw;
        color: ${graySphera};
        width: 90%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      width: 80%;

      > div:nth-child(1) {
        > h3 {
        }

        > figure {
          > img {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
      margin-left: -5%;

      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;

        > h3 {
          font-size: 4.5vw;
        }

        > figure {
          > img {
          }
        }
      }
    }
  }
`;

export const DetailInvestmentAdvices = styled.div`
  width: 90%;
  margin: 2.5% auto;

  > div:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > figure {
      width: 55px;
      height: 55px;
      margin-right: 2.5%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > p {
      font-size: 1.2vw;
      color: ${graySphera};
      line-height: 25px;
      width: 70%;
    }
  }

  > div:nth-child(2) {
    margin-top: 2.5%;

    > p {
      font-size: 1.2vw;
      color: ${graySphera};
      line-height: 25px;
      width: 90%;

      > a {
        color: ${blueSphera};
        font-size: 1.2vw;
        font-weight: bold;
        text-decoration: none;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > figure {
        > img {
        }
      }

      > p {
      }
    }

    > div:nth-child(2) {
      > p {
        > a {
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin: 5% auto;

    > div:nth-child(1) {
      flex-direction: column;

      > figure {
        width: 50px;
        height: 50px;
        margin-right: 2.5%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > p {
        font-size: 4.2vw;
        width: 100%;
      }
    }

    > div:nth-child(2) {
      margin-top: 5%;

      > p {
        font-size: 4.2vw;
        width: 100%;

        > a {
          font-size: 4.2vw;
        }
      }
    }
  }
`;

export const DetailInvestmentInfo = styled.div`
  width: 90%;
  margin: 2.5% auto 2.5% auto;
  border: none;

  > h2 {
    font-size: 1.5vw;
    color: ${graySphera};
    margin-bottom: 1.5%;
    padding-bottom: 2%;
    border-bottom: 1px solid ${blueSphera};
  }

  > div {
    margin-top: 2%;

    > div:nth-child(1) {
      display: flex;
      justify-content: space-around;
      padding-top: 2.5%;
      padding-bottom: 2.5%;
      background-color: ${blueSphera};

      > h3 {
        font-size: 1.2vw;
        font-weight: normal;
        color: ${whiteSphera};
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: space-around;
      margin-bottom: 2.5%;
      padding-top: 2.5%;
      padding-bottom: 2.5%;
      background-color: #f0f5ff;

      > p {
        font-size: 1.2vw;
        font-weight: normal;
        color: ${graySphera};
      }
    }
  }

  > a {
    display: block;
    color: ${whiteSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    text-decoration: none;
    line-height: 50px;
    width: 20%;
    height: 50px;
    margin: auto;
    border: none;
    border-radius: 5px;
    background-color: ${blueSphera};
    cursor: pointer;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }

    > div {
      > div:nth-child(1) {
        > h3 {
        }
      }

      > div:nth-child(2) {
        > p {
        }
      }
    }

    > a {
    }
  }

  @media (max-width: 600px) {
    margin: 5% auto 5% auto;

    > h2 {
      font-size: 4.5vw;
      margin-bottom: 1.5%;
      padding-bottom: 2%;
    }

    > div {
      margin-top: 2%;

      > div:nth-child(1) {
        padding-top: 5%;
        padding-bottom: 5%;

        > h3 {
          font-size: 3vw;
        }
      }

      > div:nth-child(2) {
        margin-bottom: 5%;
        padding-top: 5%;
        padding-bottom: 5%;

        > p {
          font-size: 3vw;
        }
      }
    }

    > a {
      font-size: 4.2vw;
      width: 100%;
    }
  }
`;
