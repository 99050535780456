import styled from 'styled-components/macro';
import { blackSphera } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const OurShareholdersAndOurCommunityContainer = styled.section``;

export const NewShareholdersButtons = styled.section`
  padding-bottom: 3%;

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    > h2 {
      width: 100%;
      margin: 2% 0 2% 2%;
      font-size: ${uIFontSize.xLarge};
    }
  }
  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    > div:nth-child(1) {
      > h2 {
        font-size: 2.8rem;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h2 {
        font-size: 1.8vw;
      }
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(1) {
      > h2 {
        font-size: 5vw;
      }
    }
  }
`;

export const CorporatePresentationsButtons = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45%;
  margin: 1% 1% 1% 3%;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 2px solid ${blackSphera};
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      font-size: 1.3vw;
      color: ${blackSphera};
      margin-bottom: 1%;
      width: 95%;
      > figure {
        width: 15px;
        margin-top: 2%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  > p {
    padding-left: 7%;
    font-size: 1.2vw;
    color: #576176;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h3 {
        font-size: 1.5vw;
        font-weight: normal;
      }
      > a {
        > figure {
          width: 10px;
          margin: 0;
          > img {
          }
        }
      }
    }
    > p {
      font-size: 1.5vw;
    }
  }
  @media (max-width: 600px) {
    width: 90%;
    > div {
      > h3 {
        font-size: 2.8vw;
      }
      > a {
        font-size: 4.2vw;
        > figure {
          width: 10px;
          margin: 0;
          > img {
          }
        }
      }
    }
    > p {
      font-size: 2.5vw;
    }
  }
`;

export const LegalContractsSections = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45%;
  margin: 1% 1% 1% 3%;
  border-bottom: 2px solid ${blackSphera};
  > p {
    padding: 3% 0 0 0;
    margin-bottom: 3%;
    font-size: 1.2vw;
    color: ${blackSphera};
  }
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      font-size: 1.3vw;
      color: ${blackSphera};
      margin-bottom: 1%;
      width: 95%;
      > figure {
        width: 15px;
        margin-top: 2%;
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h3 {
        font-size: 1.5vw;
        font-weight: normal;
      }
      > a {
        > figure {
          width: 10px;
          margin: 0;
          > img {
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 90%;
    > div {
      > h3 {
        font-size: 2.8vw;
      }
      > a {
        font-size: 4.2vw;
        > figure {
          width: 10px;
          margin: 0;
          > img {
          }
        }
      }
    }
  }
`;

export const RegulationFinsphera = styled.section`
  width: 100%;
  margin: 5% auto 2% auto;
  background-color: #f8f8f9;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  padding: 1% 0;
  > h2 {
    padding: 3% 0 0 2%;
    font-size: 1.5vw;
    color: ${blackSphera};
    width: 80%;
  }
  > h1 {
    padding: 3% 0 0 2%;
    font-size: 2.3vw;
    color: ${blackSphera};
  }
  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    > h2 {
      font-size: 2.8rem;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      font-size: 1.8vw;
    }
    > h1 {
      font-size: 2.5vw;
    }
  }
  @media (max-width: 600px) {
    > h2 {
      font-size: 5vw;
    }
    > h1 {
      font-size: 6vw;
    }
  }
`;
