import styled from 'styled-components/macro';
import { whiteSphera, brandBlack } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const Contracts = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5% 2.5% 2.5% 5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  > div {
    width: 90%;
    margin: 2% 5%;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  display: flex;
  justify-content: center;
  border-collapse: collapse;
  background-color: ${whiteSphera};
  td,
  th {
    text-align: left;
    padding: 10px;
    font-size: ${uIFontSize.medium};
    > a {
      color: ${brandBlack};
      font-weight: bold;
      font-size: ${uIFontSize.medium};
      cursor: pointer;
      border: 1px xolid ${brandBlack};
      border-radius: 5px;
    }
  }
  th {
    background-color: ${brandBlack};
    color: ${whiteSphera};
  }
  tr:nth-child(even) {
    background-color: #f0f5ff;
  }
  tr:nth-child(odd) {
    background-color: #e1e8f6;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    td,
    th {
      font-size: ${uIFontSize.small};
      > a {
        font-size: ${uIFontSize.small};
      }
    }
  }
  @media (max-width: 600px) {
    td,
    th {
      font-size: ${uIFontSize.small};
      > a {
        font-size: ${uIFontSize.small};
      }
    }
  }
`;
