import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AddressContainer, AdressSpecific, ButtonContainer, InfoSection } from './address.style';
import axios from 'axios';
import { url } from 'src/environments';
import { Button } from 'src/utilities/UILibrary';
export default function Address({ addressInformation, personalInformation }) {
  const user = useSelector((state) => state.user.currentUser);
  const [editable, setEditable] = useState(false);
  const [addressData, setAddressData] = useState({
    investorId: user.id,
    country: '',
    postalCode: '',
    city: '',
    state: '',
    district: '',
    neighborhood: '',
    street: '',
    numInt: '',
    numExt: ''
  });
  const data = addressInformation.getAddress;
  const PldApproved = personalInformation?.data?.getInvestor?.isPLDApproved;
  //Function that sends the nwe data to dynamo
  const sendDataToServer = async () => {
    const typeOfPerson = user.typeOfPerson;
    let input = {};
    if (typeOfPerson === 'MORAL') {
      input = {
        type: 'investor_update_personalAddress',
        input: {
          id: `${user.id}_c`,
          postalCode: addressData.postalCode
            ? addressData.postalCode
            : addressInformation.postalCode,
          state: addressData.state ? addressData.state : addressInformation.state,
          district: addressData.district ? addressData.district : addressInformation.district,
          neighborhood: addressData.neighborhood
            ? addressData.neighborhood
            : addressInformation.neighborhood,
          street: addressData.street ? addressData.street : addressInformation.street,
          numInt: addressData.numInt ? addressData.numInt : addressInformation.numInt,
          numExt: addressData.numExt ? addressData.numExt : addressInformation.numExt
        }
      };
    } else if (typeOfPerson === 'FISICA') {
      input = {
        type: 'investor_update_personalAddress',
        input: {
          id: `${user.id}_p`,
          fullAddress: `${addressInformation.fullAddress}_deprecated`,
          postalCode: addressData.postalCode
            ? addressData.postalCode
            : addressInformation.postalCode,
          state: addressData.state ? addressData.state : addressInformation.state,
          district: addressData.district ? addressData.district : addressInformation.district,
          neighborhood: addressData.neighborhood
            ? addressData.neighborhood
            : addressInformation.neighborhood,
          street: addressData.street ? addressData.street : addressInformation.street,
          numInt: addressData.numInt ? addressData.numInt : addressInformation.numInt,
          numExt: addressData.numExt ? addressData.numExt : addressInformation.numExt
        }
      };
    }
    try {
      const response = await axios.post(`${url}/admin/dynamo/update`, input);
      setEditable(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = () => {
    setEditable(true);
  };
  const cancelEdit = () => {
    setEditable(false);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAddressData({ ...addressData, [name]: value });
  };
  return (
    <AddressContainer>
      <InfoSection>
        <div>
          <h3>Código postal:</h3>
          <p>{data?.postalCode ? data?.postalCode : '-'}</p>
        </div>
        <div>
          <h3>Alcaldía o Municipio:</h3>
          <p>{data?.district ? data?.district : '-'}</p>
        </div>
        <div>
          <h3>Estado:</h3>
          <p>{data?.state ? data?.state : '-'}</p>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Número exterior:</h3>
          <p>{data?.numExt ? data?.numExt : '-'}</p>
        </div>
        <div>
          <h3>Número interior:</h3>
          <p>{data?.numInt ? data?.numInt : '-'}</p>
        </div>
        <div>
          <h3>Calle:</h3>
          <p>{data?.street ? data?.street : '-'}</p>
        </div>
        <div>
          <h3>Colonia:</h3>
          <p>{data?.neighborhood ? data?.neighborhood : '-'}</p>
        </div>
      </InfoSection>
    </AddressContainer>
  );
}
