import { useState } from 'react';
import {
  HomeContainer,
  InvestUs,
  OurServices,
  StartRequest,
  Sponsors,
  Transformation,
  CardContainer,
  Card,
  CarouselControllers,
  CalculateCredit,
  CalculateCreditAdvices,
  CalculateCreditTitle,
  CalculateCreditButton
} from './ApplicantHomeLogin.styles';
// import LoginContainer from '../../applicant/';
import { Link } from 'react-router-dom';
import { ApplicantCalculator } from 'src/components/features/applicant/dashboard/ApplicantCalculator/ApplicantCalculator';
import { Banner } from 'src/utilities/UILibrary/Media/Banner/Banner';

export function ApplicantHomeLogin() {
  const [number, setNumber] = useState(1);

  const goBack = () => {
    if (number <= 1) {
      setNumber(4);
    } else {
      setNumber(number - 1);
    }
  };

  const goForward = () => {
    if (number >= 4) {
      setNumber(1);
    } else {
      setNumber(number + 1);
    }
  };

  return (
    <HomeContainer>
      <Banner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_app_home_solicita_un_credito.png`}
      >
        <h1>
          Solicita un <span>crédito</span>
        </h1>
        <h2>
          Unimos empresas que necesitan dinero con personas que desean prestarles, todas las
          operaciones son en Pesos Mexicanos y los servicios prestados solo están disponibles en
          México.
        </h2>
      </Banner>
      <InvestUs>
        <div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconFast.png`}
                alt="icono generar intereses"
              />
            </figure>
            <h3>Rápido</h3>
            <p>
              ¡5 minutos! Es el tiempo que te tomará llenar tu solicitud de préstamo. Una vez
              fondeado el monto solicitado, recibirás el dinero en un aproximado de 7 días.
            </p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconComfortable.png`}
                alt="icono plazos 30 a 90 días"
              />
            </figure>
            <h3>Cómodo</h3>
            <p>Puedes hacer el 100% del proceso desde tu equipo, con pagos como más te convenga.</p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_Economics.png`}
                alt="tu eres el factor de impacto"
              />
            </figure>
            <h3>Educación financiera</h3>
            <p>
              Continuamente recibirás consejos prácticos de nuestros expertos sobre temas
              financieros que te ayudarán en tu día a día.
            </p>
          </div>
        </div>
      </InvestUs>
      <CalculateCredit>
        <CalculateCreditTitle>
          <h2>Calcula tu crédito</h2>
          <p>Con crédito a corto plazo podrás solicitar un crédito desde 30 hasta 120 días.</p>
        </CalculateCreditTitle>
        <ApplicantCalculator />
        <CalculateCreditButton>
          <Link to="/iniciar-sesion">Solicita un crédito</Link>
        </CalculateCreditButton>
        <CalculateCreditAdvices>
          <li>
            *Este documento es para efectos informativos y no constituye obligación u ofrecimiento
            formal por parte de Comunidad FinSphera, S.A.P.I. de C.V., en el otorgamiento del
            crédito. Las condiciones de los préstamos estarán sujetas a cambios sin previo aviso.
          </li>
          <li>*Todos los montos están calculados en pesos mexicanos (MXN).</li>
          <li>
            *Las empresas pueden realizar pagos anticipados por el monto total o parcial de su
            crédito sin generar un costo por prepago.
          </li>
          <li>*Comunidad FinSphera, S.A.P.I. de C.V. solamente opera en la República Mexicana.</li>
        </CalculateCreditAdvices>
      </CalculateCredit>
      <OurServices>
        <div>
          <div>
            <h2>Haz del crédito tu mejor aliado</h2>
            <ul>
              <li>Solicita un crédito por 4 semanas o hasta 24 meses.</li>
              <li>Montos desde $50,000 hasta 10 millones de pesos.</li>
              <li>Adelanta pagos sin penalización.</li>
              <li>
                Por ser un cliente cumplido, sube de Nivel de crédito y obtén mejores tasas de
                interés en tus próximos créditos.
              </li>
            </ul>
          </div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_Inicio_allHands.png`}
              alt="FinSphera nuestros servicios"
            />
          </figure>
        </div>
        <div>
          <div>
            <h2>¿Cuáles son los requisitos?</h2>
            <ul>
              <li>Llena el registro con datos generales.</li>
              <li>Compártenos información financiera de tu empresa.</li>
              <li>
                <p>
                  Ingresa tu RFC y tu clave <span>CIEC</span>.
                </p>
                <figure>
                  <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/questionMark.png`} alt="" />
                </figure>
              </li>
            </ul>
          </div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_Inicio_Meeting.png`}
              alt="FinSphera nuestros servicios"
            />
          </figure>
        </div>
      </OurServices>
      <StartRequest>
        <div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_Register.png`}
                alt="icono para iniciar el registro"
              />
            </figure>
            <h3>Inicia el registro</h3>
            <p>Haz click en &quot;solicitar crédito&quot; y registra los datos de tu empresa.</p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconDialogue.png`}
                alt="icono de chat"
              />
            </figure>
            <h3>Queremos conocerte</h3>
            <p>
              Calcula tu crédito y define cómo lo quieres pagar: catorcenal, mensual, desde 4
              semanas hasta 24 meses.
            </p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconEvaluation.png`}
                alt="icono de lupa"
              />
            </figure>
            <h3>Evaluación directa</h3>
            <p>
              Con la información proporcionada evaluaremos la mejor opción de crédito para tu
              empresa en 5 minutos.
            </p>
          </div>
          <div>
            <figure>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconCongrats.png`}
                alt="icono de correo con dinero"
              />
            </figure>
            <h3>¡Muchas felicidades!</h3>
            <p>
              Tu crédito podrá ser preaprobado. Continúa el proceso para que tu negocio sea más
              exitoso.
            </p>
          </div>
        </div>
        <div>
          <Link to="/iniciar-sesion">Iniciar solicitud</Link>
        </div>
      </StartRequest>
      <Sponsors>
        <div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_YCombinator.png`}
              alt="empresa 1"
            />
          </figure>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_TechCrunch.png`}
              alt="empresa 1"
            />
          </figure>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_contxto.png`}
              alt="empresa 1"
            />
          </figure>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_latamlist.png`}
              alt="empresa 1"
            />
          </figure>
          <figure>
            <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/image_F.png`} alt="empresa 1" />
          </figure>
        </div>
      </Sponsors>
      {/* Section desactivated */}
      {/* <Transformation>
        <h2>
          Historias de <span>transformación</span>
        </h2>
        {number === 1 && (
          <CardContainer>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker1</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson1.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker2</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson2.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
          </CardContainer>
        )}
        {number === 2 && (
          <CardContainer>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker3</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson2.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker4</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson1.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
          </CardContainer>
        )}
        {number === 3 && (
          <CardContainer>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker5</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson1.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker6</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson2.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
          </CardContainer>
        )}
        {number === 4 && (
          <CardContainer>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker7</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson2.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
            <Card>
              <div>
                <div>
                  <figure>
                    <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Quotes.png`} alt="" />
                  </figure>
                  <h3>María Morker8</h3>
                  <p>Financiera en algo</p>
                </div>
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/transformationPerson1.jpg`}
                    alt=""
                  />
                </figure>
              </div>
              <div>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Magna felis integer amet
                  rutrum. Vel dignissim aliquam mollis consectetur porttitor. Vulputate rutrum at
                  amet, enim. Habitant ut dignissim suspendisse integer.
                </p>
              </div>
            </Card>
          </CardContainer>
        )}

        <CarouselControllers>
          <figure onClick={goBack}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha_izquierda.png`}
              alt="flecha izquierda"
            />
          </figure>
          <p>{number} de 4</p>
          <figure onClick={goForward}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha_derecha.png`}
              alt="flecha derecha"
            />
          </figure>
        </CarouselControllers>
      </Transformation> */}
    </HomeContainer>
  );
}
