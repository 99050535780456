import styled from 'styled-components';
import { BoxAmountProps } from './types';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';

export const BoxAmoutContainer = styled.div<BoxAmountProps>`
  max-height: 3.75rem;
  height: 100%;
  max-width: 14.625rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.white.pearl};
  box-shadow: inset 0px 2px 6px #00000029;
  border-radius: 10px;
  padding: 0 0.5rem;

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
    font-size: ${uIFontSize.xxxLarge};
    color: ${colors.text.gray};
  }

  .animate-reverse {
    animation: fadeOut 0.5s ease forwards;
  }

  .animate {
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
