/* eslint-disable no-useless-catch */
import { API, graphqlOperation, Auth } from 'aws-amplify';
import {
  createBeneficiaryCust,
  createComplaintAccount,
  createInvestment_Fin,
  createInvestment_M
} from 'src/graphql/customized_mutations';
import axios from 'axios';
import {
  getApplicantBankAccount,
  getApplicantFiles,
  getApplicantProfile,
  getInvestorBankAccount,
  getInvestor_Profile,
  getAddress,
  getMifiel_ProfileInv,
  getObligadoSol
} from 'src/graphql/customized_queries';

export const fetchPersonalInformationApplicantAPI = async (userId) => {
  if (!userId) return;
  try {
    const response = await API.graphql({
      query: getApplicantProfile,
      variables: { id: userId }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchObligadoSolidarioApplicantAPI = async (userId) => {
  if (!userId) return;
  try {
    const response = await API.graphql({
      query: getObligadoSol,
      variables: { applicantId: userId }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchCurrentSessionAPI = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userAttributes = await Auth.userAttributes(user);

    const creationDate = userAttributes
      .find((attr) => attr.getName() === 'UserCreateDate')
      .getValue();
    const lastModifiedDate = userAttributes
      .find((attr) => attr.getName() === 'UserLastModifiedDate')
      .getValue();
  } catch (error) {
    throw error;
  }
};

export const fetchPersonalInformationAPI = async (userId) => {
  try {
    const response = await API.graphql({
      query: getInvestor_Profile,
      variables: { id: userId }
    });
    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchAddressApplicantDataAPI = async (userId) => {
  try {
    const response = await API.graphql({
      query: getAddress,
      variables: { id: `${userId}_c` }
    });
    return response;
  } catch (error) {
    return { error };
  }
};

export const fetchAddressInformationAPI = async (userId, thunkAPI) => {
  const state = thunkAPI.getState();
  const typeOfPerson = state.user.currentUser.typeOfPerson;
  try {
    if (typeOfPerson === 'MORAL') {
      const response = await API.graphql({
        query: getAddress,
        variables: { id: `${userId}_c` }
      });
      return response.data;
    } else {
      const response = await API.graphql({
        query: getAddress,
        variables: { id: `${userId}_p` }
      });
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchFilesInformationAPI = async (data) => {
  try {
    const response = await API.graphql({
      query: getApplicantFiles,
      variables: { id: data }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const createEachBeneficiaryAPI = async (beneficiaryData) => {
  try {
    const data = await API.graphql(
      graphqlOperation(createBeneficiaryCust, { input: beneficiaryData })
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const createInvestmentAPI = async (input) => {
  try {
    const result = await API.graphql(graphqlOperation(createInvestment_M, { input: input }));
    return result;
  } catch (error) {
    throw error;
  }
};

//Buzón quejas, comentarios, dudas
export const createComplaintAPI = async (input) => {
  try {
    const data = await API.graphql(graphqlOperation(createComplaintAccount, { input: input }));
    return data;
  } catch (error) {
    throw error;
  }
};

export const createFinspheraInvestmentAPI = async (input) => {
  try {
    const result = await API.graphql(graphqlOperation(createInvestment_Fin, { input: input }));
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchBankApplicantDataAPI = async (input) => {
  try {
    const response = await API.graphql({
      query: getApplicantBankAccount,
      variables: { id: input }
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchBankInvestorDataAPI = async (input) => {
  try {
    const data = await API.graphql({
      query: getInvestorBankAccount,
      variables: { id: data.id }
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchMifielInformationAPI = async (user) => {
  try {
    const { data: dataRisks } = await API.graphql({
      query: getMifiel_ProfileInv,
      variables: { id: `${user}_evidenceOfRisks` }
    });
    const { data: dataGeneral } = await API.graphql({
      query: getMifiel_ProfileInv,
      variables: { id: user }
    });
    return { dataRisks, dataGeneral };
  } catch (error) {
    throw error;
  }
};

export const validateAccountAPI = async (stp) => {
  const config = {
    headers: {
      apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
      'Content-Type': 'application/json'
    }
  };
  try {
    const stpResp = await axios.put(
      `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registra-orden`,
      stp
    );
    return stpResp;
  } catch (error) {
    throw error;
  }
};
