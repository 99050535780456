import { Auth } from 'aws-amplify';

export const isUserLoggedInOrStillValid = async () => {
  try {
    const user = await Auth.currentUserInfo();
    if (!user || Object.keys(user).length === 0) throw new Error('No user available');
    return true;
  } catch (error) {
    return false;
  }
};
