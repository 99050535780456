import { useState } from 'react';

//import { getInvoice, getCampaign } from 'src/graphql/queries'

import {
  CompanyDetailContainer,
  CompanyCardsContainer,
  CompanyCardInvest,
  CompanyCardDetails,
  CompanyCardSimulator,
  CompanyCreditDescription,
  CompanyInformation,
  CompanyCreditRights,
  CompanyAmortization,
  CompanyFinancialIndicators,
  CompanyRiskFactor,
  CompanyAdditionalInfo,
  CompanyFollowInvest
} from './companyDetail.styles';
import ModalDetailInvestment from 'src/components/shared/modals/detailInvestment/detailInvestment';
import ModalDetailInvestmentB from 'src/components/shared/modals/detailInvestmentB/detailInvestmentB';
import ModalSuccessDetailInvestment from 'src/components/shared/modals/successDetailInvestment/successDetailInvestment';
import TooltipBackup from 'src/components/shared/modals/backup/backup';
import { Link } from 'react-router-dom';
import useCalculateInvest from 'src/hooks/useCalculateInvest';
import useHandleNumber from 'src/hooks/useHandleNumber';

export default function CompanyDetail(props) {
  const [id] = useState(props.match.params.invoiceId);
  const [invoice, setInvoice] = useState({});
  const [campaign, setCampaign] = useState({});
  // const [ detailInvoice, setDetailInvoice ] = useState(false)
  const [detailInvestment, setDetailInvestment] = useState(false);
  const [detailInvestmentB, setDetailInvestmentB] = useState(false);
  const [successDetailInvestment, setSuccessDetailInvestment] = useState(false);
  const [backup, setBackup] = useState(false);

  // useEffect( () => {
  //   API.graphql(graphqlOperation(getInvoice,{id}))
  //     .then( s => {
  //       setInvoice(s.data.getInvoice)
  //       API.graphql(graphqlOperation(getCampaign,{id:s.data.getInvoice?.campaignID}))
  //         .then( s => setCampaign(s.data.getCampaign) )
  //         .catch( e => console.error('Error getCampaign:',e))
  //     })
  //     .catch( e => console.error('Error getInvoice:',e) )
  //   }, [])

  const amountToInvest = useHandleNumber({ type: 'range', name: 'amountToInvest' });

  let returnOfInvestment = (useCalculateInvest(amountToInvest) / 360) * 120 * 0.15;
  let finalInvestment = useCalculateInvest(amountToInvest) + returnOfInvestment;

  const showBackup = () => {
    setBackup(true);
  };

  const hideBackup = () => {
    setBackup(false);
  };

  const showInvestments = () => {
    return;
  };

  const hideInvestments = () => {
    return;
  };

  // const showDetailInvoice = () => {
  //   setDetailInvoice(true)
  // }

  return (
    <CompanyDetailContainer>
      <TooltipBackup showTooltipBackup={backup} />
      {/* <ModalDetailInvoice showModalDetailInvoice = {detailInvoice} setDetailInvoice={setDetailInvoice} campaign={campaign} /> */}
      <ModalDetailInvestment showModalDetailInvestment={detailInvestment} />
      <ModalDetailInvestmentB showModalDetailInvestmentB={detailInvestmentB} />
      <ModalSuccessDetailInvestment showModalSuccessDetailInvestment={successDetailInvestment} />
      <CompanyCardInvest>
        <figure>
          <img src="https://finsphera-resources.s3.amazonaws.com/logoEspigaBig.png" alt="" />
        </figure>
        <div>
          <h2>{invoice?.companyName ? invoice?.companyName : 'VARIABLE'}</h2>
          <p>Monto a financiar</p>
          <p>${invoice?.comprobante?.total ? invoice?.companyName : '1,000,000.00'}</p>
        </div>
        <div>
          <div>
            <p>23% financiado</p>
            <div>
              <div></div>
            </div>
          </div>
          <div>
            <h3>Comparte este crédito:</h3>
            <div>
              <Link to="/">
                <figure>
                  <img
                    src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_facebook.png"
                    alt="Finsphera facebook"
                  />
                </figure>
              </Link>
              <Link to="/">
                <figure>
                  <img
                    src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_twitter.png"
                    alt="Finsphera twitter"
                  />
                </figure>
              </Link>
              <Link to="/">
                <figure>
                  <img
                    src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_linkedin.png"
                    alt="Finsphera LinkedIn"
                  />
                </figure>
              </Link>
              <Link to="/">
                <figure>
                  <img
                    src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_whatsapp.png"
                    alt="Finsphera Whatsapp"
                  />
                </figure>
              </Link>
            </div>
          </div>
        </div>
      </CompanyCardInvest>
      <CompanyCardsContainer>
        <CompanyCardDetails>
          <ul>
            <li>ID Solicitud: {invoice?.id?.substring(0, 4)}</li>
            <li>Tipo de producto: Factoraje a proveedores</li>
            <li>Plazo: 88 días</li>
            <li>Tasa: 15.0%</li>
            <li>
              <p>Respaldos: 3</p>
              <figure onMouseDown={showBackup} onMouseUp={hideBackup}>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_more_info.png"
                  alt="Icono de más información"
                />
              </figure>
            </li>
            <li>
              Fecha de publicación: {invoice?.createdAt?.split('T')[0].split('-')[2]}/
              {invoice?.createdAt?.split('T')[0].split('-')[1]}/
              {invoice?.createdAt?.split('T')[0].split('-')[0]}
            </li>
            <li>Visto por: 233 usuarios</li>
            <li>
              Inversionistas: {invoice?.bids?.length ? invoice?.bids?.length : 0} inversionistas
            </li>
            <li>
              <p>Inversiones: 48 finalizadas</p>
              <figure onMouseDown={showInvestments} onMouseUp={hideInvestments}>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_more_info.png"
                  alt="icono de más información"
                />
              </figure>
            </li>
          </ul>
          <div>
            <h2>Historial de pago solicitante:</h2>
            <p>N° de créditos o docs. pagados: 0/0</p>
            <p>% monto pagado en plazo normal: -</p>
          </div>
        </CompanyCardDetails>
        <CompanyCardSimulator>
          <h3>Simular tu inversión</h3>
          <div>
            <div>
              <h4>Tasa</h4>
              <p>15%</p>
            </div>
            <div>
              <h4>Plazo (días)</h4>
              <p>120 días</p>
            </div>
            <div>
              <h4>Monto total a financiar</h4>
              <p>${invoice?.comprobante?.total}</p>
            </div>
            <div>
              <h4>Monto pendiente a financiar</h4>
              <p>$1,000,000.97</p>
            </div>
            <div>
              <h4>Monto a invertir</h4>
              <input {...amountToInvest} min={100} max={50000} step={50} />
            </div>
            <div>
              <h4>Rendimiento</h4>
              <p>${new Intl.NumberFormat('ja-JP').format(returnOfInvestment)}</p>
            </div>
            <div>
              <h4>Monto a invertir + Rendimiento al final de la inversión</h4>
              <p>${new Intl.NumberFormat('ja-JP').format(finalInvestment)}</p>
            </div>
          </div>
          <div>
            <p>Volver a simular</p>
          </div>
          <button>Ver detalle de inversión</button>
        </CompanyCardSimulator>
      </CompanyCardsContainer>
      <CompanyCreditDescription>
        <div>
          <h3>Descripción del crédito</h3>
          <p>
            Es una operación de Factoraje a Proveedores* que {invoice?.companyName} solicita a
            través de FinSphera, para el pago de sus proveedores claves.
          </p>
          <ul>
            <li>
              <span>Destino del crédito:</span> Capital de trabajo
            </li>
            <li>
              <span>Factor moratorio:</span> 1.5 x tasa ordinaria
            </li>
            <li>
              <p>
                <span>Calificación FinSphera:</span> 9.2
              </p>
              <figure>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_inv_dash_companyDetail_more_info.png"
                  alt="icono de más información"
                />
              </figure>
            </li>
          </ul>
        </div>
      </CompanyCreditDescription>
      <CompanyInformation>
        <h3>Información del solicitante</h3>
        <ul>
          <li>
            <span>Razón social: </span>
            {invoice?.companyName}
          </li>
          <li>
            <span>Fecha de constitución:</span>
            {invoice?.applicant?.constitutionDate}
          </li>
          <li>
            <span>Domicilio fiscal:</span>Carretera Zacatecas km 11 220 C NA Jaral de Paisanos
            Mexquitic de Carmona 78465 San Luis Potosí.
          </li>
          <li>
            <span>Sitio web:</span>{' '}
            <Link to={invoice?.applicant?.webPage ? invoice?.applicant?.webPage : '#'}>
              {invoice?.applicant?.webPage ? invoice?.applicant?.webPage : 'N/A'}
            </Link>
          </li>
        </ul>
      </CompanyInformation>
      <CompanyCreditRights>
        <h3>Descripción de los derechos del crédito</h3>
        <p>
          <span>{invoice?.companyName ? invoice?.companyName : 'VARIABLE'}.</span> Es la marca de
          componentes y accesorios de diseños adaptados a tu estilo de vida. Actualmente tienen 13
          tiendas en la Ciudad de México, Querétaro, Monterrey y Guadalajara.
        </p>
        <ul>
          <li>
            <span>Tipo:</span> Paquete
          </li>
          <li>
            <span>Servicio que origina:</span> La factura fue verificada ante el SAT manteniéndose
            como vigente, antes de la publicación de la oportunidad.
          </li>
          <li>
            <span>Monto total de facturas:</span> ${invoice?.comprobante?.total}
          </li>
          <li>
            <span>Aforo:</span> 100.0%
          </li>
          <li>
            <span>N° Facturas:</span>{' '}
            {campaign.invoices?.items ? campaign.invoices?.items.length : 0}
          </li>
        </ul>
        <div>
          <div>
            <h3>Nombre del pagador</h3>
            <h3>Suma</h3>
            <h3>Cantidad</h3>
          </div>
          <div>
            <p>{invoice?.companyName ? invoice?.companyName : 'VARIABLE'}</p>
            <p>${invoice?.comprobante?.total}</p>
            <p>{campaign.invoices?.items ? campaign.invoices?.items.length : 0}</p>
          </div>
        </div>
      </CompanyCreditRights>
      <CompanyAmortization>
        <h3>Amortización del crédito</h3>
        <p>
          Se pagará en una sola cuota al vencimiento del crédito con una tasa de 1.25% mensual por
          un importe total de ${invoice?.comprobante?.total}
        </p>
        <ul>
          <li>
            <span>Fecha de pago estipulada:</span>{' '}
            {campaign.dateFullPay ? campaign.dateFullPay : 'N/A'}
          </li>
          <li>
            <span>Capital:</span> $2,319,657.97
          </li>
          <li>
            <span>Interés ordinario:</span> $85,054.13
          </li>
        </ul>
      </CompanyAmortization>
      <CompanyFinancialIndicators>
        <h3>Indicadores financieros</h3>
        <ul>
          <li>
            <span>Ingresos (año):</span> $565,820
          </li>
          <li>
            <span>Capital contable:</span> $193,261
          </li>
        </ul>
      </CompanyFinancialIndicators>
      <CompanyRiskFactor>
        <h3>Factores de riesgo</h3>
        <p>
          Los factores de riesgo externos que podrían afectar la capacidad del solicitante son los
          propios del mercado, tales como: liquidez, regulatorios y de desempeño macroeconómico. No
          existe riesgo por tasa de interés ya que el préstamo se otrogará a tasa fija. La
          calificación de riesgo por tasa de interés ya que el préstamo se otorgará en tasa fija. La
          calificación de riesgo asignada con base la metodología de FinSphera se basa en
          información histórica, por lo cual se asume el desempeño futuro del solicitante. No se
          identifican riesgos ambientales. También se deberán considerar los siguientes riesgos: La
          imposibilidad de disponer los recursos invertidos mientras está vigente la inversión. La
          posibilidad de perder la totalidad de los recursos que se hayan invertido a través de la
          Platafoma, en caso de que el Solicitante no pague el financiamiento o este no se pueda
          recuperar. La posibilidad de invertir, a través de la institución de financiamiento
          colectivo, en sociedades que no tengan historial de operación probado, pudiendo perderse
          hasta el cien por ciento de la inversión. La posibilidad de que los estados financieros de
          las sociedades o proyectos en los que se invierta, no estén dictaminados por un auditor
          externo independiente, por lo que la información financiera podría no reflejar
          razonablemente la situación financiera de la sociedad o proyecto de que se trate. La
          posibilidad de recibir información inicial y subsecuente limitada en comparación a lo
          observado en el mercado de valores por lo que, eventualmente, el Inversionista podría no
          contar con suficiente información para tomar decisiones de inversión. Tenemos prohibido
          asegurar retornos o rendimientos sobre las inversiones que realices a través de nuestra
          Plataforma. También tenemos prohibido garantizar el resultado o éxito de las inversiones.
          Ni el Gobierno Federal ni las entidades de la administración pública paraestatal son
          responsables ni garantizan los recursos de nuestros clientes, así como tampoco asumen
          alguna responsabilidad por las obligaciones contraídas por la Plataforma o por algún
          cliente frente a otro en virtud de las operaciones que celebren con la Plataforma.
        </p>
      </CompanyRiskFactor>
      <CompanyAdditionalInfo>
        <div>
          <h3>Información adicional</h3>
          <p>
            Para acceder a esta información debes confirmar con SMS, llamada o Authy desde tu
            celular ya guardado en tu perfil:
          </p>
        </div>
        <div>
          <h3>¿Cómo quieres solicitar tu código?</h3>
          <div>
            <select name="requestCode">
              <option value="none">Seleccione una opción</option>
            </select>
            <button>Enviar código</button>
          </div>
        </div>
      </CompanyAdditionalInfo>
      <CompanyFollowInvest>
        <h3>Seguimiento de la inversión</h3>
        <p>
          El seguimiento de la inversión podrá llevarse a cabo desde la plataforma en el módulo
          Panel de Inversiones. Asimismo, existe un canal de comunicación a través de la cuenta de
          correo electrónico{' '}
          <Link to="mailto:atencionaclientes@finsphera.com">atencionaclientes@finsphera.com</Link>
        </p>
        <Link to="/inversionista/dashboard/inversiones">Ir a mi Panel de Inversiones</Link>
      </CompanyFollowInvest>
    </CompanyDetailContainer>
  );
}
