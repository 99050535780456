import styled from 'styled-components/macro';
import {
  greenSp,
  graySphera,
  whiteSphera,
  brandBlack
} from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { ResourcesProviderProps } from './resourcesProvider.types';

export const ModalResources = styled.section<ResourcesProviderProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.resourcesModal ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: scroll;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 10000;
  animation: ${(props) => (props.resourcesModal ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;
  > div {
    width: 50%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;
    > div {
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
    }
  }
  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
    }
  }
`;

export const Figure = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  > div {
    width: 80%;
  }
  > figure {
    width: 25px;
    height: 25px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  > form {
    Button {
      margin-top: 5%;
    }
    > h3 {
      font-size: ${uIFontSize.h3};
      text-align: center;
      color: ${graySphera};
      margin-top: 2%;
    }
    > div {
      > h4 {
        font-size: ${uIFontSize.h3};
        text-align: center;
        color: ${graySphera};
        width: 100%;
        > a {
          color: ${greenSp};
          text-decoration: none;
          font-weight: bold;
          font-size: ${uIFontSize.h3};
        }
      }
      > p {
        width: 90%;
        margin: 5% 0;
        font-weight: bold;
        font-size: ${uIFontSize.medium};
      }
    }
  }
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: ${uIFontSize.large};
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
`;
