import { green } from './../../../../../utilities/UILibrary2024/stylesTokens/colors';
import styled from 'styled-components';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const CreditShowcaseSectionContainer = styled.section`
  margin-bottom: 6rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  width: 100%;
  max-width: 93.75rem;

  .credit-showcase--cards-section {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent radial-gradient(closest-side at 50% 50%, #ffa510 0%, #fef601 100%) 0%
        0% no-repeat padding-box;
      opacity: 0.36;
      filter: blur(50px);
    }

    > div {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      z-index: 1;
    }

    .fade-detail {
      position: absolute;
      top: -50px;
      left: -50px;
      width: 35%;
      aspect-ratio: 1/1;
      background: transparent radial-gradient(closest-side at 50% 50%, #3fba73 0%, #d7ff67 100%) 0%
        0% no-repeat padding-box;
      opacity: 0.3;
      filter: blur(50px);
    }

    .card {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .card--header {
        font-size: ${uIFontSize.large};
        font-weight: 900;
        color: ${colors.green.default};
        text-align: start;
        align-self: start;
        margin-bottom: 1rem;
      }

      .card--button {
        cursor: default;
        align-self: flex-end;
      }
    }

    .card1 {
      position: relative;

      .card1--body {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1.5rem;
      }

      .card1--section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .card--skeleton-section {
        position: relative;
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 1.25rem;
      }

      .card--review-icon {
        position: absolute;
        top: 0;
        right: 0;
        width: fit-content;
      }

      .card--checkmark-icon {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        background-color: ${colors.green.default};
        width: 71px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 0.5rem;
        border-radius: 5px;
      }

      .card--checkmark-icon p {
        color: white;
        font-size: ${uIFontSize.caption};
      }

      .card--checkmark-icon svg {
        width: 2.125rem;
        height: 2.125rem;
      }

      .card--checkmark-icon svg .a {
        fill: white;
      }
    }

    .card2 .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.25rem;

      .card2--body {
        width: 100%;
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        gap: 1.5rem;
      }

      .card2--campaign-section,
      .card2--card-section {
        display: flex;
        flex-direction: column;
      }

      .card2--campaign-section {
        flex-grow: 1;

        .card2--skeleton-section {
          display: flex;
          flex-direction: column;
          gap: 0.625rem;
          margin-bottom: 1rem;
        }
      }

      .card2--card-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        justify-content: space-between;
        align-items: center;
      }

      .card2--card-section svg {
        max-width: 90px;
        max-height: 90px;
        width: 100%;
        height: 100%;
      }

      .card2--card-section p {
        text-align: center;
        color: ${colors.text.green};
        font-weight: 900;
      }

      .card2--footer {
        width: 100%;
      }
    }

    .card3 .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .card3--body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        width: 100%;
        margin-bottom: 0.5rem;
      }

      .card3--footer {
        width: 100%;

        .hidden {
          opacity: 0;
        }
      }

      .card3--footer p {
        transition: opacity 0.3s ease-in-out;
        font-size: ${uIFontSize.small};
        color: ${colors.text.green};
        font-weight: 900;
      }
    }

    .card3--section {
      display: flex;
      gap: 0.75rem;
      justify-content: space-between;
      width: 100%;

      .left,
      .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .left {
        flex-grow: 1;
      }

      .right p {
        font-size: ${uIFontSize.caption};
        color: ${colors.text.gray};
      }

      .campaign-name {
        font-weight: 700;
        font-size: ${uIFontSize.large};
        color: ${colors.text.gray};
      }

      .left > .company-name,
      .right > .company-name {
        font-size: ${uIFontSize.paragraph};
        color: ${colors.text.gray};
        margin-bottom: 1rem;
      }

      .card3--skeleton-section {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        > div {
          height: 1rem;
        }
      }
    }
  }

  span {
    display: inline;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 3.5rem;
      text-align: center;
      margin-bottom: 2rem;
      text-align: center;
      font-weight: bold;
    }

    > p {
      font-size: ${uIFontSize.xLarge};
      color: ${colors.text.description};
      text-align: center;
      margin-bottom: 1.5rem;
      max-width: 600px;
    }
  }

  .description--rates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 1.5rem;
    }

    .info,
    .rate,
    .orange-rate {
      text-align: center;
      font-weight: bold;
    }

    .info {
      font-size: ${uIFontSize.xxxLarge};
      color: ${colors.text.gray};
    }

    .orange-info {
      font-size: ${uIFontSize.xxxLarge};
      color: ${colors.orange.default};
    }

    .rate,
    .orange-rate {
      font-size: 4.5rem;
    }

    .rate {
      background: linear-gradient(
        180deg,
        ${colors.green.default} 0%,
        ${colors.green.secondary} 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .orange-rate {
      background: linear-gradient(
        83deg,
        ${colors.orange.default} 0%,
        ${colors.orange.secondary} 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .credit-showcase--cards-section {
      display: grid;
      aspect-ratio: 1/1;
      max-width: 46.5625rem;
      width: 100%;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(24, 1fr);
      max-width: 54rem;
      flex-grow: 1;
      gap: 0;
      flex-direction: unset;
      justify-content: unset;
      flex-wrap: unset;

      .card1 {
        height: 100%;
        grid-area: 1 / 13 / 12 / 24;
      }

      .card2 {
        height: 100%;
        grid-area: 8 / 1 / 18 / 18;
      }

      .card3 {
        height: 100%;
        grid-area: 16 / 9 / 25 / 25;

        .card .card3--body {
          flex-direction: row;
        }
      }
    }

    span {
      display: block;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    gap: 6rem;

    .description {
      h1 {
        font-size: 5rem;
        margin-bottom: 2rem;
      }

      > p {
        font-size: ${uIFontSize.xxxLarge};
        margin-bottom: 3rem;
        max-width: unset;
      }
    }

    .description--rates {
      row-gap: 0.75rem;

      .info,
      .orange-info {
        font-size: 2rem;
      }

      .rate,
      .orange-rate {
        font-size: 5rem;
      }

      button {
        font-size: ${uIFontSize.xLarge};
        margin-top: 2rem;
      }
    }
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 4.125rem;
    margin-bottom: 12.75rem;
    position: relative;
    width: 104%;
    left: 4%;
    align-items: center;
    margin-bottom: 10.625rem;

    .description {
      align-self: start;
    }
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    margin-bottom: 18.75rem;

    .description {
      align-self: start;

      h1 {
        font-size: 7rem;
        margin-bottom: 3.875rem;
      }
    }

    .investment-showcase__card-section {
      .card {
        .card--header {
          font-size: ${uIFontSize.xxLarge};
        }
      }
    }
  }
`;
