import { Route } from 'react-router-dom';
import Home from 'src/views/applicant/Dashboard/home/home';
import ApplicantProfile from 'src/views/applicant/Dashboard/myProfile/myProfile';
import CreateCampaign from 'src/views/applicant/Dashboard/createCampaign/createCampaign';
import CampaignsDash from 'src/views/applicant/Dashboard/createCampaign/campaignsDash/campaignsDash';
import { ProtectedOnboardingRoute } from 'src/components/shared/compositionComponents/ProtectedOnboardingRoute/ProtectedOnboardingRoute';
import { AllowedUser } from '../ProtectedRoute';
import EsgForm from 'src/views/applicant/Dashboard/esgForm/esgForm';
import Equity from 'src/views/applicant/Dashboard/esgForm/equityForm';
import GoalsDocuments from 'src/views/applicant/Dashboard/esgForm/goalsDocuments/goalsDocuments';
import AccountInfo from 'src/components/shared/accountInfo/account';
import AddInvoices from 'src/views/applicant/Dashboard/createCampaign/AddInvoices/AddInvoices';
import JointlyObligated from 'src/views/applicant/Dashboard/createCampaign/JointlyObligated/JointlyObligated';
import ContractsSign from 'src/views/applicant/Dashboard/myProfile/contractsSign/contractSign';

const AllowedUserConfig: AllowedUser = {
  user: 'applicant',
  person: undefined
};

export const DashboardApplicantRoutes = (
  <>
    <Route
      path="/solicitante/dashboard"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <Home />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/mi-perfil"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <ApplicantProfile />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/miFiel/:widgetId"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <ContractsSign />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/crear-campaña"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <CreateCampaign />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/cargar-facturas"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <AddInvoices />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/obligado-solidario"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <JointlyObligated />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/campañas"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <CampaignsDash />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/account"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <AccountInfo />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/esg"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <EsgForm />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="/solicitante/dashboard/esg-equidad"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <Equity />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="solicitante/dashboard/esg-documentos"
      element={
        <ProtectedOnboardingRoute protectDashboard allowedUsers={[AllowedUserConfig]}>
          <GoalsDocuments />
        </ProtectedOnboardingRoute>
      }
    />
  </>
);
