import styled from 'styled-components/macro';
import { greenSp, whiteSphera, brandBlack } from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const ModalSign = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModal ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModal ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;
  > div {
    width: 50%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;
    > div {
      > button {
        color: ${whiteSphera};
        font-weight: bold;
        font-size: 1.2vw;
        text-align: center;
        width: 30%;
        height: 40px;
        border: none;
        border-radius: 5px;
        background-color: ${greenSp};
        cursor: pointer;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    > div {
      width: 50%;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    > div {
      width: 90%;
    }
  }
`;

export const Figure = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  > div {
    width: 80%;
  }
  > figure {
    width: 25px;
    height: 25px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    > div {
      width: 90%;
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 2% 10%;
  width: 80%;
  > h3 {
    width: 90%;
    text-align: center;
    margin-bottom: 5%;
    font-size: ${uIFontSize.xLarge};
  }
  > form {
    Button {
      margin-top: 5%;
    }
    > p {
      width: 90%;
      text-align: center;
      margin-top: 5%;
      font-size: ${uIFontSize.medium};
      font-weight: bold;
    }
  }
  @media (max-width: ${breakpoints.tablet}) {
    > div {
      width: 90%;
    }
  }
`;

export const ContainerText = styled.div`
  width: 90%;
`;

export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
`;
