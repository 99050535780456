import { FC } from 'react';
import { FormActionButtonsProps } from './formActionButtons.types';
import { FormActionButtonsContainer } from './formActionButtons.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useNavigate } from 'react-router-dom';

const copy = 'Si lo prefieres, puedes terminar tu registro después.';
const backButtonCopy = 'Terminar después';
const submitButtonCopy = 'Enviar';

export const FormActionButtons: FC<FormActionButtonsProps> = (props) => {
  const navigate = useNavigate();
  const {
    closeAction = () => navigate('/'),
    submitAction,
    disableCloseButton,
    disableSubmitButton,
    description = copy,
    submitButtonText = submitButtonCopy,
    cancelButtonText = backButtonCopy
  } = props;

  return (
    <FormActionButtonsContainer {...props}>
      <div className="body">
        <Button size="small" onClick={closeAction} disabled={disableCloseButton}>
          {cancelButtonText}
        </Button>
        <Button disabled={disableSubmitButton} size="small" onClick={submitAction}>
          {submitButtonText}
        </Button>
      </div>
      {description ? (
        <div className="footer">
          <p>{description}</p>
        </div>
      ) : null}
    </FormActionButtonsContainer>
  );
};
