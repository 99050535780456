import styled from 'styled-components';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const InvestmentShowcaseSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  margin-bottom: 5rem;
  width: 100%;
  max-width: 93.75rem;

  span {
    display: inline;
  }

  .investment-showcase__cards-section {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.25rem;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent radial-gradient(closest-side at 50% 50%, #3fba73 0%, #d7ff67 100%) 0%
        0% no-repeat padding-box;
      opacity: 0.36;
      filter: blur(50px);
    }

    > div {
      width: 100%;
      z-index: 1;
    }

    .fade-detail {
      position: absolute;
      top: -50px;
      right: 30px;
      width: 35%;
      aspect-ratio: 1/1;
      background: transparent radial-gradient(closest-side at 50% 50%, #ffa510 0%, #fef601 100%) 0%
        0% no-repeat padding-box;
      opacity: 0.3;
      filter: blur(50px);
    }

    .card {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .card--header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.25rem;

        > h3 {
          font-size: ${uIFontSize.large};
          font-weight: 900;
          color: ${colors.green.default};
          text-align: center;
        }

        > p {
          font-size: ${uIFontSize.medium};
          color: ${colors.text.description};
          align-self: start;
        }
      }

      .box-amount {
        max-height: 3rem;
      }

      .card--button {
        font-size: ${uIFontSize.large};
        cursor: default;
      }
    }

    .card1,
    .card2,
    .card3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
      font-weight: bold;
      font-size: 3.5rem;
      text-align: center;
      margin-bottom: 2rem;
    }

    > p {
      font-size: ${uIFontSize.xLarge};
      color: ${colors.text.description};
      text-align: center;
      margin-bottom: 1.5rem;
      max-width: 600px;
    }
  }

  .description--rates {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
      margin-top: 1.5rem;
    }

    .info,
    .rate,
    .orange-rate {
      text-align: center;
      font-weight: bold;
    }

    .info {
      font-size: ${uIFontSize.xxxLarge};
      color: ${colors.text.gray};
    }

    .orange-info {
      font-size: ${uIFontSize.xxxLarge};
      color: ${colors.orange.default};
    }

    .rate,
    .orange-rate {
      font-size: 4.5rem;
    }

    .rate {
      background: linear-gradient(
        180deg,
        ${colors.green.default} 0%,
        ${colors.green.secondary} 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .orange-rate {
      background: linear-gradient(
        83deg,
        ${colors.orange.default} 0%,
        ${colors.orange.secondary} 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    .investment-showcase__cards-section {
      display: grid;
      aspect-ratio: 1/1;
      grid-template-columns: repeat(24, 1fr);
      grid-template-rows: repeat(24, 1fr);
      width: 100%;
      max-width: 54rem;
      flex-grow: 1;
      gap: 0;
      flex-direction: unset;
      justify-content: unset;
      flex-wrap: unset;

      .fade-detail {
        right: -50px;
      }

      .card1 {
        display: flex;
        align-items: end;
        justify-content: center;
        grid-area: 1 / 4 / 14 / 14;
        z-index: 5;
      }
      .card2 {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: 7 / 15 / 18 / 25;
      }
      .card3 {
        display: flex;
        align-items: start;
        grid-area: 15 / 1 / 25 / 16;
      }
    }

    span {
      display: block;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .description {
      h1 {
        font-size: 5rem;
        margin-bottom: 2rem;
      }

      > p {
        font-size: ${uIFontSize.xxxLarge};
        margin-bottom: 3rem;
        max-width: unset;
      }
    }

    .description--rates {
      row-gap: 0.75rem;

      .info,
      .orange-info {
        font-size: 2rem;
      }

      .rate,
      .orange-rate {
        font-size: 5rem;
      }

      button {
        font-size: ${uIFontSize.xLarge};
        margin-top: 2rem;
      }
    }

    .investment-showcase__cards-section {
      .card {
        .box-amount {
          max-height: 3.75rem;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 4.125rem;
    width: 104%;
    position: relative;
    left: -4%;
    margin-bottom: 10.625rem;

    .description {
      align-self: start;
    }
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    margin-bottom: 16.625rem;

    .description {
      align-self: start;

      h1 {
        font-size: 7rem;
        margin-bottom: 3.875rem;
      }
    }

    .investment-showcase__cards-section {
      .card {
        .card--header {
          > h3 {
            font-size: ${uIFontSize.xxLarge};
          }

          > p {
            font-size: ${uIFontSize.large};
          }
        }
      }
    }
  }
`;
