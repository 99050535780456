import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import {
  blackSphera,
  whiteSphera,
  brandYellowgreen
} from 'src/utilities/UIStyles/variables/colors';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { ButtonProps } from './types';

const sizes = ({
  size = 'medium',
  isSlim = false,
  isFullWidth
}: ButtonProps): FlattenSimpleInterpolation => {
  const sizes = {
    small: css`
      min-width: ${isFullWidth ? '100%' : '8rem'};
      height: ${isSlim ? '1.75rem' : '2.25rem'};
    `,
    medium: css`
      min-width: ${isFullWidth ? '100%' : '10rem'};
      height: ${isSlim ? '2.25rem' : '2.75rem'};
    `,
    large: css`
      min-width: ${isFullWidth ? '100%' : '12rem'};
      height: ${isSlim ? '2.75rem' : '3.25rem'};
    `
  };

  return sizes[size];
};

const variants = {
  green: css`
    background-color: ${colors.button.green};
    color: ${colors.text.default};
  `,
  orange: css`
    background-color: ${colors.button.orange};
    color: ${colors.text.default};
  `,
  gray: css`
    background-color: ${colors.button.gray};
    color: ${colors.text.default};
  `,
  'border-orange': css`
    background-color: ${colors.background.white};
    color: ${colors.text.black};
    border: 1px solid ${colors.button.orange};
  `,
  'border-green': css`
    background-color: ${colors.background.white};
    color: ${colors.text.default};
    border: 1px solid ${colors.button.green};
  `
};

const disabledOrLoading = ({ disabled, isLoading }: ButtonProps): FlattenSimpleInterpolation => {
  if (disabled || isLoading) {
    return css`
      filter: grayscale(100%) opacity(0.5);
      pointer-events: none;
    `;
  }
  return css``;
};

export const ButtonContainer = styled.button<ButtonProps>`
  ${sizes}
  ${disabledOrLoading}

  font-size: ${({ fontSize }) => fontSize || uIFontSize.medium};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  border-radius: 8px;
  border: 0;
  padding: 0 1rem;
  cursor: pointer;

  ${({ variant = 'green' }) => variants[variant]}
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
