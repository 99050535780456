import styled from 'styled-components/macro';

import {
  graySphera,
  grayLightSphera,
  whiteSphera,
  blackSphera,
  greenSp,
  brandBlack
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const DashboardAccountCLABEContainer = styled.section`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  background-color: rgb(245, 245, 245);
  margin-bottom: 5%;
  > h3 {
    text-align: center;
    margin-bottom: 3%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const Banner = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  background-color: ${whiteSphera};
  padding: 5% 0 5% 10%;

  > h2 {
    color: ${blackSphera};
    font-size: ${uIFontSize.subTitle};
    width: 30%;
    margin-right: 10%;
  }

  > p {
    color: ${blackSphera};
    font-size: ${uIFontSize.paragraph};
    width: 50%;
  }

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
    padding: 25% 0 2.5% 0%;

    > h2 {
      font-size: ${uIFontSize.subTitle};
      width: 100%;
      margin-right: 0;
      margin-bottom: 5%;
      text-align: center;
    }

    > p {
      text-align: justify;
      font-size: ${uIFontSize.paragraph};
      width: 90%;
      margin-bottom: 5%;
    }
  }
`;

export const DashboardAccountCLABEInfo = styled.div`
  border: 1px solid ${grayLightSphera};
  width: 90%;
  margin: 1% auto 2.5% auto;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > h3 {
      font-size: ${uIFontSize.h3};
      color: ${blackSphera};
    }
  }

  > div:nth-child(2) {
    width: 90%;
    margin: auto;
    border: 1px solid ${graySphera};
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > p {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${blackSphera};
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 5%;

      > h3 {
        font-size: ${uIFontSize.h3};
        text-align: center;
      }
    }

    > div:nth-child(2) {
    }

    > div:nth-child(3) {
      padding-top: 5%;
      padding-bottom: 5%;

      > p {
        font-size: ${uIFontSize.paragraph};
        text-align: center;
      }
    }
  }
`;

export const DashboardAccountCLABEButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto 5% auto;

  > button {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${whiteSphera};
    width: 45%;
    padding: 2% 0;
    border: none;
    border-radius: 5px;
    background-color: ${blackSphera};
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto 5% auto;

    > button {
      font-size: ${uIFontSize.paragraph};
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
    }
  }
`;

export const DashboardAccountCLABETable = styled.table`
  width: 90%;
  margin: auto;

  > tbody {
    > tr {
      > th {
        font-size: ${uIFontSize.paragraph};
        color: ${whiteSphera};
        text-align: left;
        height: 50px;
        padding: 1% 1.5% 1% 0.5%;
        background-color: ${blackSphera};
        > button {
          font-size: ${uIFontSize.paragraph};
          font-weight: bold;
          color: ${greenSp};
          width: 35%;
          margin-left: 10%;
          padding: 2% 0;
          border: 1px solid ${greenSp};
          border-radius: 5px;
          background-color: ${brandBlack};
          cursor: pointer;
          transition: all 0.5s;
        }
      }

      > td {
        color: ${blackSphera};
        font-size: ${uIFontSize.paragraph};
        text-align: left;
        padding: 1% 0 1% 0.5%;
      }
    }

    > tr:nth-child(2n) {
      background-color: #f0f5ff;
    }

    > tr:nth-child(3n) {
      background-color: #e1e8f6;
    }
  }

  @media (max-width: 600px) {
    width: 400px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    > tbody {
      > tr {
        > th {
          font-size: ${uIFontSize.paragraph};
        }

        > td {
          font-size: ${uIFontSize.paragraph};
        }

        > td:last-child {
          > button {
            font-size: ${uIFontSize.paragraph};
          }
        }
      }
    }
  }
`;

export const DashboardAccountCLABENavigateButtons = styled.div`
  width: 60%;
  margin: 2.5% auto;

  > div {
    display: flex;
    justify-content: space-around;
    width: 60%;
    margin: 2.5% auto 0 auto;

    > button:nth-child(1) {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${blackSphera};
      width: 30%;
      padding-top: 3%;
      padding-bottom: 3%;
      border: 1px solid ${blackSphera};
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.05);
      }
    }

    > button:nth-child(2) {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${whiteSphera};
      width: 30%;
      padding-top: 3%;
      padding-bottom: 3%;
      border: none;
      border-radius: 5px;
      background-color: ${blackSphera};
      cursor: pointer;
      transition: all 0.5s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 2.5% auto;

    > div {
      width: 100%;
      margin: 5% auto 0 auto;

      > button:nth-child(1) {
        font-size: ${uIFontSize.paragraph};
        width: 40%;

        > button:nth-child(2) {
          font-size: ${uIFontSize.paragraph};
          width: 40%;
        }
      }
    }
  }
`;
