import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import {
  ModalContainer,
  SectionInfo,
  DetailData,
  GeneralDetail,
  Success
} from './investConfirm.styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { fetchPersonalInformation, createInvestment } from 'src/store/Profile/profile.slice';
import { useDispatch, useSelector } from 'react-redux';
import getCurrentDate from 'src/functions/getCurrentDate';
import { url } from 'src/environments';

export default function InvestConfirmation({
  showModalAdviceValidation,
  closeModal,
  displayInvestment,
  interesInversionistaPesos,
  item,
  amount,
  interestPercentage,
  retencionImpuestoInversionistaPesos
}) {
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const company = item?.applicant?.companyInformation?.companyName;
  const legalRep = item?.applicant?.personalInformation?.fullName;
  const [obligadoSolidario, setObligadoSolidario] = useState(null);
  const campaignId = item?.id;
  const applicantId = item?.applicant?.id;
  const applicantName = item?.applicant?.personalInformation?.fullName;
  const applicantEmail = item?.applicant?.personalInformation?.email;
  const applicantRFC = item?.applicant?.personalInformation?.RFC;

  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  //Para extraer data del Obligado Solidario
  const body = {
    type: 'getAllObligadoSolidario',
    applicantId: applicantId
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const request = await axios.post(`${url}/admin/queries/get-all`, body);
        const parsedBody = JSON.parse(request.data.body);
        const obligadoData = parsedBody.result;
        const matchedObligado = obligadoData.find(
          (obligado) => obligado.id === item?.obligadoSolidarioId
        );
        setObligadoSolidario(matchedObligado);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [user.id, url, item?.obligadoSolidarioId]);
  const obligadoMail = obligadoSolidario?.personalInformation?.email;
  const obligadoName = obligadoSolidario?.personalInformation?.fullName;
  const obligadoRFC = obligadoSolidario?.personalInformation?.RFC;

  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  function getCurrentDateFormatted() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  //Función para obtener fecha de pago para el body en pagaré
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + item?.plazoCredito);
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = currentDate.getFullYear();
  const dateToPay = `${day}-${month}-${year}`;
  //Format amount
  const amountAsNumber = parseFloat(item.collectedAmount);

  const executeAdditionalRequests = async () => {
    if (item.montoSolicitado === item.collectedAmount + parseInt(displayInvestment)) {
      const promisory = {
        campaignId: campaignId
      };
      const updateCampaign = {
        type: 'update_campaign_successful',
        input: {
          date: getCurrentDateFormatted(),
          campaignId: campaignId
        }
      };
      const updateAllInvestments = {
        type: 'update_allInvestment_status',
        input: {
          campaignId: campaignId
        }
      };
      const pdfMifiel = {
        campaignId: campaignId,
        type: 'promissoryNote',
        signatories: [
          {
            email: applicantEmail,
            name: applicantName,
            tax_id: applicantRFC
          },
          {
            email: obligadoMail,
            name: obligadoName,
            tax_id: obligadoRFC
          }
        ]
      };

      const sendEmailInvestor = {
        typeMessage: 'investors_campaign_successful',
        campaignId: campaignId,
        typeOfUser: 'investor'
      };
      const sendEmail = {
        typeMessage: 'applicant_campaign_successful',
        campaignId: campaignId,
        typeOfUser: 'applicant'
      };
      try {
        //Endpoint que actualiza la campaña isSuccessful=1, isActive=0
        const updateCampaignResponse = await axios.post(
          `${url}/admin/dynamo/update`,
          updateCampaign
        );
        //Endpoint que envía los correos de campaña 100% fondeada a los usuarios
        const sendMailResponse = await axios.post(`${url}/admin/campaign/send-email`, sendEmail);
        const sendMailInvestorResponse = await axios.post(
          `${url}/admin/campaign/send-email`,
          sendEmailInvestor
        );
        //Endpoint que crea el pagaré
        const promisoryResponse = await axios.post(
          `${url}/mifiel/documents/create-promissory-note`,
          promisory
        );
        //Endpoint que carga el pagaré
        const createPdfResponse = await axios.post(
          `${url}/mifiel/documents/create-document`,
          pdfMifiel
        );
        //Endpoint que ejecuta la actualización de compromiso de inversión a inversiones vigentes en STP
        const updateSTPInvestments = await axios.post(
          `${url}/admin/dynamo/update`,
          updateAllInvestments
        );
      } catch (additionalError) {
        setErrorText(true);
        console.error('Error with additional requests:', additionalError);
      }
    } else {
      //Agregar lógica
    }
  };
  const handleSendEmail = () => {
    const input = {
      id: user.id,
      typeOfUser: 'investor',
      typeMessage: 'investMoney',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const handleOtpSubmit = async () => {
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: 'investor'
    };
    setLocalLoading(true);
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        alert('Validación de código fallida');
        return;
      }
      const investment = {
        investorId: user.id,
        campaignId: item.id,
        compromisoInversion: displayInvestment,
        interesInversionistaPorcentaje: item.tasaCreditoPorcentaje,
        interesInversionistaPesos: interesInversionistaPesos,
        retencionImpuestoInversionistaPorcentaje: 0.02,
        retencionImpuestoInversionistaPesos: retencionImpuestoInversionistaPesos,
        porcentajeGananciaInteres: interestPercentage,
        createdAt: getCurrentDate(),
        updatedAt: getCurrentDate()
      };
      const finalInvestment = {
        campaignId: campaignId
      };
      const resources = {
        clientId: user.id,
        amount: parseInt(displayInvestment),
        type: 'compromisoinversion',
        campaignId: campaignId
      };
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/update-investor-balances-in-STP`,
        headers: {
          apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
          'Content-Type': 'application/json'
        },
        data: resources
      };
      try {
        const response = await axios(config);
        if (
          response.data.status === 201 &&
          response.data.message === 'Investor amounts updated correctly'
        ) {
          dispatch(createInvestment(investment));
          await executeAdditionalRequests();
          await axios.post(`${url}/admin/investment/all-investors-final`, finalInvestment);
          setSuccessText(true);
        } else {
          setErrorText(true);
          console.error('Request completed, but with a non-successful status:', response);
        }
      } catch (error) {
        setErrorText(true);
        console.error('Request failed:', error);
      }
    } catch (error) {
      console.error(error);
      setLocalLoading(false);
    }
    setLocalLoading(false);
  };

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  const formatedAmount = formatCurrency(amount);
  return (
    <ModalContainer showModalAdviceValidation={showModalAdviceValidation}>
      {!successText && !errorText ? (
        <SectionInfo>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
          <h1>¡Estás a punto de invertir en esta campaña!</h1>
          <GeneralDetail>
            <h2>Detalles de tu inversión: </h2>
            <div>
              <h3>Monto en tu cuenta de Finsphera: </h3>
              <p>{formatedAmount}</p>
            </div>
            <div>
              <h3>Monto a transferir: </h3>
              <p>{formatCurrency(displayInvestment)}</p>
            </div>
          </GeneralDetail>
          <DetailData>
            <div>
              <h3>Ganancia proyectada</h3>
              <p>{formatCurrency(interesInversionistaPesos)}</p>
            </div>
            <div>
              <h3>Monto de Retención de (ISR)</h3>
              <p>- {formatCurrency(retencionImpuestoInversionistaPesos)}</p>
            </div>
            <div>
              <h3>Ganancia real</h3>
              <p>
                {formatCurrency(interesInversionistaPesos - retencionImpuestoInversionistaPesos)}
              </p>
            </div>
            <h2>Información de la campaña: </h2>
            <div>
              <h3>Plazo estimado de retorno de la inversión: </h3>
              <p>
                {item?.plazoCredito ? item?.plazoCredito : '-'} días una vez firmados los pagarés
              </p>
            </div>
            <div>
              <h3>Nombre de campaña: </h3>
              <p>{item?.nombreCampania ? item?.nombreCampania : '-'}</p>
            </div>
            <div>
              <h3>Monto solicitado de la campaña: </h3>
              <p>{formatCurrency(item?.montoSolicitado ? item?.montoSolicitado : '-')}</p>
            </div>
            <div>
              <h3>Nombre de la compañía: </h3>
              <p>
                {item?.applicant.companyInformation.companyName.legalName
                  ? item?.applicant.companyInformation.companyName.legalName
                  : '-'}
              </p>
            </div>
            <h4>
              Recuerda que podrás cancelar tu inversión siempre y cuando la campaña no se haya
              cerrado.
            </h4>
          </DetailData>
          {showInput ? (
            <div>
              <p>Ingresa el código que enviamos a tu correo:</p>
              <input
                type="text"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <Button isLoading={localLoading} size="small" onClick={handleOtpSubmit}>
                Confirmar código
              </Button>
            </div>
          ) : (
            <Button isLoading={localLoading} size="small" onClick={handleSendEmail}>
              Confirmar
            </Button>
          )}
        </SectionInfo>
      ) : successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de operación exitosa"
            />
          </figure>
          <h2>¡Tu inversión se ha registrado de forma exitosa!</h2>
          <p>
            Puedes ver el avance de esta y otras campañas en las que inviertas en tu{' '}
            <Link to="/inversionista/dashboard/inversiones">Panel de inversiones</Link>
          </p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_error.png`}
              alt="icono de error"
            />
          </figure>
          <h2>¡Ocurrió un error en tu solicitud!</h2>
          <p>Tenemos un problema con este requerimiento. Por favor inténtalo más tarde.</p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalContainer>
  );
}
