import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import axios from 'axios';
import { getJsonHeadersWithAuth } from 'src/functions/getJsonHeadersWithAuth';

Amplify.configure(awsconfig);
axios.interceptors.request.use(
  async function (config) {
    try {
      const url = config.url ?? '';
      if (
        url.includes('/admin/add-user-to-group') ||
        url.includes('/admin/get-incremental-id') ||
        url.includes('mutations/create/anonymous-mailbox')
      ) {
        const key = process.env.REACT_APP_APIKEY_FINSPHERA;
        config.headers['x-api-key'] = key;
      } else if (!url.includes('https://geolocation-db.com/json/')) {
        const authHeader = await getJsonHeadersWithAuth();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        config.headers = { ...(config.headers as any), ...authHeader };
      }
    } catch (error) {
      console.error('Error getting token header:', error);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>
);
