import styled from 'styled-components/macro';

export const ValidatorPass = styled.div`
  > p {
    font-weight: 'bold';
    > img {
      width: 15px;
      height: 15px;
      margin-right: 2%;
    }
  }
`;
