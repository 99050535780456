import { errorMessages } from 'src/constants/errorMessages';

export const parseError = (error: unknown) => {
  let errorMessage: string;

  if (error instanceof Error) {
    // Standard JavaScript Error object
    errorMessage = error.message;
  } else if (typeof error === 'object' && error !== null && 'message' in error) {
    // Error structure from AWS or other sources with a 'message' property
    errorMessage = (error as { message: string }).message;
  } else {
    // Unknown error type without a 'message' property
    errorMessage = 'An unknown error occurred';
  }

  return (
    errorMessages[errorMessage] ||
    'La operación no se pudo completar. Por favor, intenta nuevamente.'
  );
};
