import React, { useState, useEffect } from 'react';

import { ModalErrorUploadingInput } from './errorUploadingInput.styles';

export default function ErrorUploadingInput(props) {
  const [errorUploadingInput, setErrorUploadingInput] = useState(
    props.showModalErrorUploadingInput
  );

  useEffect(() => {
    setErrorUploadingInput(props.showModalErrorUploadingInput);
  }, [props.showModalErrorUploadingInput]);

  const closeModal = () => {
    setErrorUploadingInput(false);
    props.setErrorUploadingInput(false);
  };

  return (
    <ModalErrorUploadingInput showModalErrorUploadingInput={errorUploadingInput}>
      <div>
        <figure onClick={closeModal}>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
            alt="icono para cerrar la ventana"
          />
        </figure>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconoError.png`}
            alt="icono de aviso"
          />
        </figure>
        <h2>Error para guardar tus datos.</h2>
        <p>Estamos teniendo problemas para guardar tus datos, favor de intentarlo más tarde.</p>
      </div>
    </ModalErrorUploadingInput>
  );
}
