/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_appsync_graphqlEndpoint:
    'https://dfu5liluxnbftm7nbinabybrcy.appsync-api.us-east-1.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: 'us-east-1:809c9d2b-9023-4f22-9437-2f79849bd8ed',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_NQlxY5bPK',
  aws_user_pools_web_client_id: '5619i0bphrq08279qfgd24d6ui',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL']
};

export default awsmobile;
