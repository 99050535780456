import styled from 'styled-components/macro';

type MetamapButtonContainerProps = {
  disabled?: boolean;
};

export const MetamapButtonContainer = styled.div<MetamapButtonContainerProps>`
  filter: ${(props) => (props.disabled ? 'grayscale(100%) opacity(0.5)' : 'none')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;
