import styled from 'styled-components/macro';
import {
  graySphera,
  whiteSphera,
  brandBlack,
  supportLemonGreen,
  brandYellowgreen
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const CardContainer = styled.div`
  width: 30%;
  border-radius: 10px 0 0 10px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 3%;
  background-color: ${whiteSphera};
  > button {
    background-color: ${brandBlack};
    border: none;
    color: ${whiteSphera};
    width: 60%;
    margin: 5% 20%;
    border-radius: 5px;
    padding: 2% 0;
    cursor: pointer;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > button {
      width: 70%;
      margin: 5% 15%;
      font-size: 1.2vw;
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    margin-top: 10%;
  }
`;

export const CardLogo = styled.figure`
  width: 80%;
  height: auto;
  margin: 2% 10%;
  > h1 {
    font-size: ${uIFontSize.xLarge};
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h1 {
      font-size: ${uIFontSize.large};
    }
  }
`;

export const ImagesContainer = styled.figure`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
  margin: 2% 0;
  > img {
    width: 25%;
    height: 25%;
  }
`;

export const CardSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 4% 10%;
  border: 1px solid ${brandBlack};
  border-radius: 5px;
  > div {
    width: 90%;
    display: flex;
    justify-content: start;
    text-align: left;
    margin: 0 5%;
    padding: 0;
    > h3 {
      font-size: 1vw;
      color: ${brandBlack};
      width: 60%;
    }
    > p {
      font-size: 1vw;
      text-align: left;
      color: ${graySphera};
      width: 37%;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > div {
      > h3 {
        font-size: 3.5vw;
      }
      > p {
        font-size: 3.5vw;
      }
    }
  }
`;

export const CardIcons = styled.div`
  display: flex;
  width: 90%;
  margin: 0 5%;
  > div {
    width: 70%;
    margin: 5% 15%;
    padding-top: 5%;
    padding-bottom: 2.5%;
    background-color: #fafafa;
    > figure {
      width: 25%;
      height: 60%;
      margin: 0 auto 2.5% auto;
      > img {
        width: 100%;
        height: 100%;
      }
    }
    > p {
      font-size: 1vw;
      text-align: center;
      color: ${supportLemonGreen};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > div {
      > figure {
        height: 65%;
      }
      > p {
        font-size: 3vw;
      }
    }
  }
`;

export const CardStatus = styled.div`
  width: 100%;
  padding: 5% 10%;
  > h3 {
    text-align: center;
    font-size: 1vw;
    font-weight: bold;
    color: ${brandBlack};
    margin-bottom: 5%;
  }
  > div {
    width: 100%;
    height: 20px;
    border-radius: 10px;
    background-color: #f0f5ff;
    > div {
      width: ${(props) => props.percentage && `${props.percentage}%`};
      height: 100%;
      border-radius: 10px;
      background-color: ${brandYellowgreen};
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
    > h3 {
      font-size: 4vw;
    }
  }
`;
