import { useState, useEffect } from 'react';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';
import {
  DashboardWelcomeContainer,
  Banner,
  DashboardWelcomeAdvice,
  DashboardWelcomeInfo,
  DashboardWelcomeDepositWithdrawButtons,
  DashboardHomeInvestmentOpportunity
} from './welcome.styles';
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import ModalInfoVirtualAccount from 'src/components/shared/modals/infoVirtualAccount/infoVirtualAccount';
import WithdrawVirtualAccount from 'src/components/shared/modals/withdrawVirtualAccount/withdrawVirtualAccount';
import Sponsors from 'src/components/Layout/sponsors/sponsors';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { listCampaigns_Dash } from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary';

const DashboardWelcome = () => {
  const dispatch = useDispatch();
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const [infoVirtualAccount, setInfoVirtualAccount] = useState(false);
  const [detailInvestPay, setDetailInvestPay] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const PldApproved = personalInformation?.data?.getInvestor?.isPLDApproved;
  const user = useSelector((state) => state.user.currentUser);
  const stp = personalInformation?.data?.getInvestor?.STP;
  const amount = stp?.recursosDisponibles;
  const allBids = personalInformation?.data?.getInvestor?.investments?.items || [];
  const compromiso = allBids.reduce((total, bid) => {
    if (bid.status === 'vigente') {
      return total + bid.compromisoInversion;
    }
    return total;
  }, 0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await API.graphql({
          query: listCampaigns_Dash,
          variables: { id: user.id }
        });
        const allCampaigns = data.data?.listCampaigns?.items;
        const activeCampaigns = allCampaigns.filter((campaign) => campaign.isActive === '1');
        setCampaignData(activeCampaigns);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (user.id) {
      fetchUserData();
    }
  }, [user.id]);
  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  const handleDeposit = () => {
    setInfoVirtualAccount(true);
  };
  const handleRetirement = () => {
    setDetailInvestPay(true);
  };
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  useEffect(() => {
    setInfoVirtualAccount(infoVirtualAccount);
  }, [infoVirtualAccount]);

  useEffect(() => {
    setDetailInvestPay(detailInvestPay);
  }, [detailInvestPay]);
  return (
    <DashboardWelcomeContainer>
      <WithdrawVirtualAccount
        showModalWithdrawVirtualAccount={detailInvestPay}
        setModal={setDetailInvestPay}
        amount={amount}
        personalInformation={personalInformation}
      />
      <ModalInfoVirtualAccount
        showModalInfoVirtualAccount={infoVirtualAccount}
        setModal={setInfoVirtualAccount}
        amount={amount}
      />
      <Banner>
        <div>
          <h2>¡Bienvenido a FinSphera!</h2>
          <p>
            ¡Ya eres un inversionista y estás listo para impactar positivamente a nuestra Comunidad
            y Medio Ambiente! Cualquier duda estámos a tu disposición en{' '}
            <span>atenciónaclientes@finshpera.com.mx</span>
          </p>
        </div>
      </Banner>
      <DashboardWelcomeAdvice>
        {PldApproved === '0' ? (
          <div>
            <h4>
              En un lapso no mayor a 24hrs. recibirás un correo con la notificación de que tu cuenta
              ha sido validada y podrás comenzar a invertir en las campañas activas.
            </h4>
          </div>
        ) : (
          <div>
            <p>
              *Recuerda que al hacer retiros de dinero antes de las 16:00 hrs, este se verá
              reflejado en tu cuenta bancaria después de las 16:01 del mismo día hábil. O bien, si
              la solicitud de retiro se hace después de las 16:00 hrs, este se verá reflejado hasta
              el día hábil siguiente después de las 16:01 hrs hora del centro de México.
            </p>
          </div>
        )}
      </DashboardWelcomeAdvice>
      <DashboardWelcomeInfo>
        <div>
          <h3>Saldo disponible</h3>
          <h3>Total invertido activo</h3>
          <h3>Saldo total</h3>
        </div>
        <div></div>
        <div>
          <p>{stp?.recursosDisponibles ? formatCurrency(stp?.recursosDisponibles) : '0.00'}</p>
          <p>{compromiso ? formatCurrency(compromiso) : '0.00'}</p>
          <p>{stp?.saldoTotal ? formatCurrency(stp?.saldoTotal) : '0.00'}</p>
        </div>
      </DashboardWelcomeInfo>
      <DashboardWelcomeDepositWithdrawButtons>
        <Button disabled={PldApproved === '0'} onClick={handleDeposit}>
          Depositar a Cuenta Virtual
        </Button>
        <Button onClick={handleRetirement}>Retiros Cuenta Virtual</Button>
      </DashboardWelcomeDepositWithdrawButtons>
      <Sponsors />
      <DashboardHomeInvestmentOpportunity>
        <h2>Oportunidades de inversión</h2>
        <p>Aquí tienes las últimas campañas creadas por las PyMEs registradas en Finsphera.</p>
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Plazo</th>
              <th>% de financiamiento</th>
              <th>Monto solicitado</th>
              <th>Nombre de compañía</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="5">Validando campañas...</td>
              </tr>
            ) : (
              campaignData &&
              campaignData
                .sort((a, b) => new Date(b.dateStartCampaign) - new Date(a.dateStartCampaign))
                .slice(0, 5)
                .map((campaign) => (
                  <tr key={campaign.id}>
                    <td>{campaign.nombreCampania}</td>
                    <td>{campaign.plazoCredito}</td>
                    <td>
                      {Math.round((campaign.collectedAmount / campaign.montoSolicitado) * 100)}%
                    </td>
                    <td>{formatCurrency(campaign.montoSolicitado)}</td>
                    <td>{campaign.applicant?.companyInformation?.companyName?.legalName || '-'}</td>
                  </tr>
                ))
            )}
          </tbody>
        </table>
        <div>
          <Link to="/inversionista/dashboard/oportunidades">Ir a oportunidades</Link>
          <Link to="/inversionista/dashboard/inversiones">Ir a mis inversiones</Link>
        </div>
      </DashboardHomeInvestmentOpportunity>
    </DashboardWelcomeContainer>
  );
};
export default DashboardWelcome;
