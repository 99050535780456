import { useEffect, useState } from 'react';
import { NavContainer, Left, Menu } from './navbar.styles';
import NavMenuList from 'src/components/Layout/navMenuList/NavMenuList';
import { signInSelector, signOutAll, userGroupSelector } from 'src/store/User/user.slice';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Squash as Hamburger } from 'hamburger-react';
import { useViewMobile } from 'src/hooks/useViewMobile';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { useHandleLoginRedirect } from 'src/hooks/useHandleLoginRedirect';
import { environment } from 'src/environments';

const Navbar = () => {
  const [showMenuList, setShowMenuList] = useState(false);
  const navigate = useNavigate();
  const isMobile = useViewMobile(parseInt(breakpoints.desktop));
  const isSignedIn = useSelector(signInSelector);
  const dispatch = useAppThunkDispatch();
  const userGroup = useSelector(userGroupSelector);
  const onboarding = useSelector((state: RootState) => state.user?.currentUser?.onboarding);
  const { groupName, typeOfPerson } = useSelector((state: RootState) => state.user?.currentUser);
  const { handleRedirectFromLogin } = useHandleLoginRedirect();
  const [hiddenButton, setHiddenButton] = useState(false);

  useEffect(() => {
    if (
      location.pathname.includes('/inversionista') ||
      location.pathname.includes('/solicitante') ||
      location.pathname.includes('/pagina-validacion')
    ) {
      return setHiddenButton(true);
    }

    return setHiddenButton(false);
  }, [location.pathname]);

  //Verificar signOut
  const handleLogout = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      dispatch(signOutAll());
      navigate('/');
    } catch (error) {
      console.error('ERROR en Signout', error);
    }
  };

  useEffect(() => {
    if (!isMobile) {
      document.body.style.overflow = 'auto';
      setShowMenuList(false);
      isSignedIn;
    }
  }, [isMobile]);

  const onChangeMenuList = () => {
    document.body.style.overflow = showMenuList ? 'auto' : 'hidden';
    return setShowMenuList(!showMenuList);
  };

  const ApplicantNavItems = () => (
    <>
      <li>
        <Link to="/solicitante/dashboard">Home</Link>
      </li>
      <li>
        <Link to="/solicitante/dashboard/mi-perfil">Perfil</Link>
      </li>
      <li>
        <Link to="/solicitante/dashboard/crear-campaña">Crear campaña</Link>
      </li>
      <li>
        <Link to="/solicitante/dashboard/campañas">Mis campañas</Link>
      </li>
      <li>
        <Link to="/solicitante/dashboard/account">Cuenta</Link>
      </li>
    </>
  );

  const InvestorNavItems = () => (
    <>
      <li>
        <Link to="/inversionista/dashboard">Home</Link>
      </li>
      <li>
        <Link to="/inversionista/dashboard/oportunidades">Oportunidades</Link>
      </li>
      <li>
        <Link to="/inversionista/dashboard/mi-perfil">Perfil</Link>
      </li>
      <li>
        <Link to="/inversionista/dashboard/cuenta-clabe">Información bancaria</Link>
      </li>
      <li>
        <Link to="/inversionista/dashboard/account">Cuenta</Link>
      </li>
    </>
  );

  const NonCompletedOnboarding = () => (
    <>
      <li>
        <Link to="/quienes-somos">¿Quiénes somos?</Link>
      </li>
      <li>
        <Link to="/contratos-y-legal">Contratos e Información legal</Link>
      </li>
      <li>
        <Link to="/relacion-con-accionistas-comunidad">Accionistas</Link>
      </li>
    </>
  );

  const NonSignedInNavItems = () => (
    <>
      {environment === 'dev' ? (
        <>
          <li>
            <Link to="/inversionista/registrate">Invierte | Impacta</Link>
          </li>
          <li>
            <Link to="/solicitante/registrate">Solicita crédito</Link>
          </li>
        </>
      ) : null}
      <li>
        <Link to="/quienes-somos">¿Quiénes somos?</Link>
      </li>
      <li>
        <Link to="/contratos-y-legal">Contratos e Información legal</Link>
      </li>
      <li>
        <Link to="/relacion-con-accionistas-comunidad">Accionistas</Link>
      </li>
    </>
  );

  const renderNavItems = () => {
    if (!isSignedIn) return <NonSignedInNavItems />;
    if (onboarding?.complete) {
      return userGroup === 'applicant' ? <ApplicantNavItems /> : <InvestorNavItems />;
    }
    // Handle other cases
    return <NonCompletedOnboarding />;
  };

  const renderButtons = () => {
    if (!isSignedIn) {
      return (
        <Button
          className="navbar-button"
          size="small"
          isSlim
          onClick={() => navigate('/iniciar-sesion')}
        >
          Ingresar
        </Button>
      );
    }
    return (
      <>
        {!hiddenButton && (
          <Button className="flow-button" size="small" isSlim onClick={handleFlowRedirect}>
            {onboarding?.complete ? 'Dashboard' : 'Completar registro'}
          </Button>
        )}
        <Button size="small" isSlim onClick={handleLogout}>
          Cerrar sesión
        </Button>
      </>
    );
  };

  const handleFlowRedirect = () => {
    if (onboarding?.isBlocked) return navigate('/pagina-validacion');

    handleRedirectFromLogin(
      { typeOfPerson, typeOfUser: groupName },
      onboarding?.currentStep,
      onboarding?.complete
    );
  };

  return (
    <NavContainer>
      <Left>
        <Link to="/">
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_nav_finsphera.png"
            alt="logotipo finsphera"
          />
        </Link>
        <div className="menu-left">{renderNavItems()}</div>
      </Left>
      <Menu>
        {environment === 'dev' ? renderButtons() : null}
        <Hamburger toggled={showMenuList} toggle={onChangeMenuList} distance="sm" size={24} />
        <NavMenuList
          showMenuList={showMenuList}
          callback={onChangeMenuList}
          renderItems={renderNavItems}
        />
      </Menu>
    </NavContainer>
  );
};

export default Navbar;
