import { Auth, API } from 'aws-amplify';
import { Dispatch } from 'redux';
import { getInvestor_login, getApplicant_login } from 'src/graphql/customized_queries';
import { SignInApplicantResponse, SignInInvestorResponse } from 'src/store/User/user.interface';
import { signInUserAPI } from './signInUserAPI';

export const signUpApplicantAPI = async (email: string, password: string) => {
  try {
    const { userSub } = await Auth.signUp({
      username: email,
      password
    });

    return userSub;
  } catch (error) {
    console.error('error signing up Applicant:', error);
    throw error;
  }
};

export const signUpInvestorAPI = async (email: string, password: string) => {
  try {
    const { userSub } = await Auth.signUp({
      username: email,
      password
    });
    return userSub;
  } catch (error) {
    console.error('error signing up InvestorAPI:', error);
    throw error;
  }
};

//Confirm Signup with Code
export const confirmSignupAPI = async (email: string, code: string): Promise<string> => {
  try {
    const response = await Auth.confirmSignUp(email, code);
    return response;
  } catch (error) {
    console.error('Error confirming SignupAPI', error);
    throw error;
  }
};

//Sign In Applicant
export const signInApplicantAPI = async (
  email: string,
  password: string
): Promise<SignInApplicantResponse> => {
  try {
    const singInResponse = await Auth.signIn({
      username: email,
      password
    });
    const applicant = await API.graphql({
      query: getApplicant_login,
      variables: {
        id: singInResponse.username
      }
    });
    if ('data' in applicant) {
      const response = {
        groupName: singInResponse.signInUserSession.idToken.payload['cognito:groups'][0],
        ...applicant.data.getApplicant
      };
      return response;
    } else {
      throw new Error('Applicant data is not available');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//Sign In Investor
export const signInInvestorAPI = async (
  email: string,
  password: string,
  dispatch: Dispatch
): Promise<SignInInvestorResponse> => {
  try {
    const singInResponse = await Auth.signIn({
      username: email,
      password
    });
    const investor = await API.graphql({
      query: getInvestor_login,
      variables: {
        id: singInResponse.username
      }
    });
    if ('data' in investor) {
      const response = {
        groupName: singInResponse.signInUserSession.idToken.payload['cognito:groups'][0],
        ...investor.data.getInvestor
      };
      return response;
    } else {
      throw new Error('Investor data is not available');
    }
  } catch (error) {
    console.error(error, 'Error SignIn Investor INDEX');
    throw error;
  }
};

//SignIn API
export { signInUserAPI };

//SignOut Global
export const signOutAllDevices = async () => {
  try {
    await Auth.signOut({ global: true });
    return true;
  } catch (error) {
    await Auth.signOut();
    return false;
  }
};

export const signOut = async () => {
  try {
    await Auth.signOut();
    return true;
  } catch (error) {
    console.error('error signing out: ', error);
    return false;
  }
};
