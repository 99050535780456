import styled from 'styled-components/macro';
import {
  brandBlack,
  supportOliveGreen,
  supportDarkGreen,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const TermsContainer = styled.div`
  margin: 5% 3%;
  border-radius: 5px;
  opacity: 0.8;
  padding: 1% 3%;
  & > p {
    margin: 0;
    color: black;
    font-size: ${uIFontSize.paragraph};
    & > a {
      color: ${supportOliveGreen};
      font-weight: bold;
      text-decoration: underline;
      font-size: ${uIFontSize.paragraph};
    }
    & > span {
      color: ${supportOliveGreen};
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
    }
  }
  & > h2 {
    margin: 2% 0;
    color: ${brandBlack};
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
  }
  & > h1 {
    text-align: center;
    color: ${greenSp};
    font-weight: bold;
    margin: 0 3%;
    padding: 2% 0;
    font-size: ${uIFontSize.title};
  }
  & > h3 {
    color: ${supportDarkGreen};
    margin: 2% 0;
    font-weight: 600;
    font-size: ${uIFontSize.medium};
  }
  & > h4 {
    color: ${supportOliveGreen};
    margin: 2%;
    font-weight: 600;
    font-size: ${uIFontSize.medium};
  }
  & > ul {
    padding: 2% 5%;
    & > p {
      padding: 0;
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
    }
    & > li {
      margin: 2% 0;
      & > span {
        color: ${brandBlack};
        text-align: center;
        font-weight: 600;
        font-size: ${uIFontSize.paragraph};
      }
      > a {
        color: ${supportOliveGreen};
        font-weight: bold;
        text-decoration: underline;
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  width: 100%;
  margin: 1% 0 0 80%;
`;
////////////////
export const TableContainer = styled.section`
  display: flex;
  margin-bottom: 5%;
  padding-top: 5%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
  }
`;

export const TableOne = styled.table`
  margin: auto;
  margin-top: 5%;
  width: 50%;
  border-collapse: collapse;

  > thead {
    background-color: #6eac46;
    border: 1.5px solid #6eac46;

    > tr {
      > th {
        padding: 2%;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        font-weight: bold;
        padding: 1%;
        border: 1.5px solid #6eac46;
      }

      > td:nth-child(1) {
        padding-left: 3%;
      }

      > td:nth-child(2) {
        text-align: center;
      }
    }

    > tr:nth-child(odd) {
      background-color: #e0edd9;
    }
  }
`;

export const TableTwo = styled.table`
  margin: auto;
  margin-top: 5%;
  width: 30%;
  border-collapse: collapse;

  > thead {
    background-color: #6eac46;
    border: 1.5px solid #6eac46;

    > tr {
      > th {
        padding: 2%;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        font-weight: bold;
        text-align: center;
        border: 1.5px solid #6eac46;
      }

      > td:nth-child(1) {
        padding-left: 3%;
      }

      > td:nth-child(2) {
        text-align: center;
      }
    }

    > tr:nth-child(odd) {
      background-color: #e0edd9;
    }
  }
`;
