import { Link } from 'react-router-dom';
import {
  OurShareholdersAndOurCommunityContainer,
  RegulationFinsphera,
  NewShareholdersButtons,
  CorporatePresentationsButtons,
  LegalContractsSections
} from './contractsAndLegal.styles';
import { Banner } from 'src/utilities/UILibrary/Media/Banner/Banner';

export default function ContractsAndLegal() {
  return (
    <OurShareholdersAndOurCommunityContainer>
      <Banner imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_contracts.jpg`}>
        <h1>Contratos e información legal</h1>
      </Banner>
      <NewShareholdersButtons>
        <div>
          <h2>Aviso de Privacidad y Términos y Condiciones</h2>
          <CorporatePresentationsButtons>
            <div>
              <Link to="/terminos-y-condiciones" target="_blank">
                Términos y Condiciones de nuestra página web
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                    alt="icono de flecha"
                  />
                </figure>
              </Link>
            </div>
          </CorporatePresentationsButtons>
          <CorporatePresentationsButtons>
            <div>
              <Link to="/aviso-de-privacidad" target="_blank">
                Aviso de Privacidad
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                    alt="icono de flecha"
                  />
                </figure>
              </Link>
            </div>
          </CorporatePresentationsButtons>
          <h2>Contratos</h2>
          <LegalContractsSections>
            <div>
              <Link
                to="https://finsphera-webapp-resources.s3.amazonaws.com/inversionistasContratoDeComisionMercantil.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Inversionistas | Contratos de Comisión Mercantil
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                    alt="icono de flecha"
                  />
                </figure>
              </Link>
            </div>
          </LegalContractsSections>
          <LegalContractsSections>
            <div>
              <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/pymesContratoDeCredito.pdf">
                Pymes | Contrato de Crédito
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                    alt="icono de flecha"
                  />
                </figure>
              </Link>
            </div>
          </LegalContractsSections>
          <LegalContractsSections>
            <div>
              <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/pagareSolicitante.pdf">
                Pymes | Pagaré
                <figure>
                  <img
                    src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                    alt="icono de flecha"
                  />
                </figure>
              </Link>
            </div>
          </LegalContractsSections>

          <RegulationFinsphera>
            <h1>Regulación aplicable a FinSphera</h1>
            <h2>Leyes y Reglamentos</h2>
            <LegalContractsSections>
              <div>
                <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/institucionesTecnologiaFinanciera_200521.pdf">
                  Ley para Regular las Instituciones de Tecnología Financiera.
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/firmaElectronicaAvanzada_200521.pdf">
                  Ley de Firma Electrónica Avanzada.
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/transparenciaOrdenamientoServiciosFinancieros_090318.pdf">
                  Ley para la Transparencia y Ordenamiento de los Servicios Financieros.
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="https://www.cnbv.gob.mx/SECTORES-SUPERVISADOS/Fintech/Paginas/NORMATIVIDAD-FINTECH.aspx">
                  Disposición de Carácter General aplicables a las Instituciones de Tecnología
                  Financiera
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <h2>Tratamiento de Datos Personales</h2>
            <LegalContractsSections>
              <div>
                <Link to="https://finsphera-webapp-resources.s3.amazonaws.com/proteccionDatosPersonales.pdf">
                  Ley Federal de Protección de Datos Personales en Posesión de los Particulares
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <h2>Preguntas Frecuentes</h2>
            <LegalContractsSections>
              <div>
                <Link to="#">
                  Operación de nuestra página web
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="#">
                  Inversionistas
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="#">
                  Pymes Solicitantes de Crédito
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <h2>Prevención de Lavado de Dinero</h2>
            <LegalContractsSections>
              <div>
                <Link to="https://catalogonacional.gob.mx/FichaRegulacion?regulacionId=109202">
                  Disposiciones de Carácter General en materia de Prevención de Lavado de Dinero y
                  Financiamiento al Terrorismo
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <h2>Ciberseguridad</h2>
            <LegalContractsSections>
              <div>
                <Link to="https://dof.gob.mx/nota_detalle.php?codigo=5555030&fecha=25/03/2019#gsc.tab=0">
                  Disposición de Carácter General en materia de Ciberseguridad
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <h2>Defensa de Nuestros Usuarios</h2>
            <LegalContractsSections>
              <div>
                <Link to="https://www.condusef.gob.mx/">
                  Contacto en la Condusef. Comisión para la defensa de los usuarios de los servicios
                  financieros
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
            <LegalContractsSections>
              <div>
                <Link to="https://www.buro.gob.mx/">
                  Buró de Entidades Financieras
                  <figure>
                    <img
                      src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/flecha.png`}
                      alt="icono de flecha"
                    />
                  </figure>
                </Link>
              </div>
            </LegalContractsSections>
          </RegulationFinsphera>
        </div>
      </NewShareholdersButtons>
    </OurShareholdersAndOurCommunityContainer>
  );
}
