import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import {
  updateExistentCampaign,
  getlistCampaigns,
  getSpecificCampaign,
  deleteSpecificCampaign,
  createNewCampaignAPI,
  campaignInvoicesAPI,
  validateInvoicesAPI,
  usedInvoicesAPI,
  saveInvoicesAPI,
  totalInvoicesAPI
} from '../../middlewares/API/Campaigns/campaign';
import { udpateApplicantCampaign } from 'src/middlewares/API/User/user';
import { transformPostData } from 'src/functions/transformPostData';

const campaignsAdapter = createEntityAdapter();

//THUNKS
export const listAllCampaigns = createAsyncThunk('campaigns/listCampaigns', async (id) => {
  const response = await getlistCampaigns(id);
  return response;
});

export const updateCampaign = createAsyncThunk('campaings/updateCampaign', async (input) => {
  const response = await updateExistentCampaign(input);
  return response;
});

export const specificCampaign = createAsyncThunk('campaigns/specificCampaign', async (id) => {
  const response = await getSpecificCampaign(id);
  return response;
});

export const deleteCampaign = createAsyncThunk('campaigns/deleteCampaign', async (input) => {
  const response = await deleteSpecificCampaign(input);
  return response;
});

const campaignSlice = createSlice({
  name: 'campaign',
  initialState: {
    currentCampaignId: null,
    calculatedCampaignData: null,
    id: '',
    currentUser: {},
    userGroup: '',
    isLoading: false,
    collectedAmount: 0,
    validateInvoicesResponse: null,
    contractsSigned: false
  },
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },
    setContractsSigned: (state, action) => {
      state.contractsSigned = action.payload;
    },
    setCurrentCampaignId: (state, action) => {
      state.currentCampaignId = action.payload;
    },
    setCalculatedCampaignData: (state, action) => {
      state.calculatedCampaignData = action.payload;
    },
    saveTransformedData: (state, action) => {
      state.transformedData = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createCampaignSlice.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createCampaignSlice.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentCampaignId = action.payload.createCampaign.id;
      state.error = null;
      state.calculatedCampaignData = null;
      // state.currentUser = {
      //   ...state.currentUser,
      //   ...action.payload
      // };
    });
    builder.addCase(createCampaignSlice.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.calculatedCampaignData = null;
    });
    builder.addCase(campaignInvoices.fulfilled, (state, action) => {
      state.validateInvoicesResponse = action.payload.validateInvoicesResponse;
      state.campaignInvoicesResponse = action.payload.campaignInvoicesResponse;
      state.isLoading = false;
    });
    builder.addCase(usedInvoices.fulfilled, (state, action) => {
      if (action.payload && action.payload.amount) {
        state.collectedAmount = action.payload.amount;
      }
    });
  }
});

//THUNK CREAR CAMPAÑA PASO 1 - EXTRACCIÓN DE FACTURAS
export const campaignInvoices = createAsyncThunk('campaignInvoices', async (userId) => {
  try {
    const idObject = { id: userId };
    const campaignInvoicesResponse = await campaignInvoicesAPI(idObject);
    return { campaignInvoicesResponse };
  } catch (error) {
    throw new Error(error.message || 'Unknown error');
  }
});

//THUNK CREAR CAMPAÑA PASO 2 - VALIDACIÓN DE UUID
export const validateInvoicesThunk = createAsyncThunk('validateInvoicesThunk', async (userId) => {
  try {
    const idObject = { id: userId };
    const validateInvoicesResponse = await validateInvoicesAPI(idObject);
    return { validateInvoicesResponse };
  } catch (error) {
    throw new Error(error.message || 'Unknown error');
  }
});

//THUNK CREAR CAMPAÑA PASO 3 - FACTURAS USADAS Y MONTO
export const usedInvoices = createAsyncThunk('usedInvoices', async (input) => {
  const { invoices, id, requestedAmount } = input;
  const usedInvoicesResponse = await usedInvoicesAPI({ invoices, id });
  let parsedUsedInvoicesResponseBody;
  try {
    parsedUsedInvoicesResponseBody = JSON.parse(usedInvoicesResponse.data.body);
    let result = parsedUsedInvoicesResponseBody.result;
    if (Array.isArray(result) && result.length > 0) {
      return {
        usedInvoices: result,
        used: true
      };
    } else {
      const inputForSecondAPI = { invoices: input.invoices };
      const amountResponse = await totalInvoicesAPI(inputForSecondAPI);
      const parsedAmountResponse = JSON.parse(amountResponse.data.body);
      if (requestedAmount < parsedAmountResponse.total) {
        await saveInvoicesAPI(input);
        return { usedInvoices: [], used: false, canProceed: true };
      } else {
        return {
          usedInvoices: [],
          used: false,
          canProceed: false,
          totalAmount: parsedAmountResponse.total
        };
      }
    }
  } catch (error) {
    console.error('Error in usedInvoices thunk:', error);
    throw error;
  }
});

//THUNK CREAR CAMPAÑA PASO FINAL
export const createCampaignSlice = createAsyncThunk(
  'createCampaignSlice',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async ({ input, postData }, { dispatch, rejectWithValue }) => {
    try {
      const campaignData = await createNewCampaignAPI(input);
      const campaignId = campaignData?.data?.createCampaign?.id;
      if (!campaignId) {
        throw new Error('Failed to fetch campaignId');
      }
      const transformedData = transformPostData(postData, campaignId);
      dispatch(saveInvoicesAPI(transformedData));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//THUNK UPDATE APPLICANT
export const updateApplicantWeb = createAsyncThunk(
  'updateApplicantWeb',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (inputs, { rejectWithValue }) => {
    try {
      const response = await udpateApplicantCampaign(inputs);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

export const {
  setLoadingState,
  setContractsSigned,
  setCurrentStep,
  setCurrentCampaignId,
  setCalculatedCampaignData,
  saveTransformedData
} = campaignSlice.actions;

export default campaignSlice.reducer;

export const {
  selectAll: selectAllCampaigns,
  selectById: selectCampaignById,
  selectIds: selectCampaignIds
} = campaignsAdapter.getSelectors((state) => state.campaigns);
