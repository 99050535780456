import styled from 'styled-components';

export const AnonymousMailboxContainer = styled.div`
  background-color: rgb(245, 245, 245);

  textarea[name='unusualAspects'] {
    width: 100%;
    resize: vertical;
  }

  .radio-group {
    display: flex;
    gap: 0.5rem;
  }

  .error-text {
    color: red;
  }

  .anonymous-mailbox-form {
    .card-row {
      display: flex;
      flex-direction: column;
    }
  }
`;
