export const spacing = {
  appHorizontalPadding: {
    landingPage: {
      mobile: '1rem',
      tablet: '4rem',
      desktop: '8rem',
      largeDesktop: '16.125rem'
    }
  }
};
