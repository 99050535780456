export const agropecuarioSilviculturaYPesca = [
  { name: 'Agricultura', code: '0100008' },
  { name: 'Cultivo De Alpiste', code: '0111013' },
  { name: 'Cultivo De Arroz', code: '0111021' },
  { name: 'Cultivo De Avena', code: '0111039' },
  { name: 'Cultivo De Cebada', code: '0111047' },
  { name: 'Cultivo De Linaza', code: '0111055' },
  { name: 'Cultivo De Maiz', code: '0111063' },
  { name: 'Cultivo De Milo', code: '0111071' },
  { name: 'Cultivo De Sorgo', code: '0111089' },
  { name: 'Cultivo De Soya', code: '0111097' },
  { name: 'Cultivo De Trigo', code: '0111104' },
  { name: 'Cultivo De Ajo', code: '0112011' },
  { name: 'Cultivo De Calabaza', code: '0112029' },
  { name: 'Cultivo De Camote', code: '0112037' },
  { name: 'Cultivo De Cebolla', code: '0112045' },
  { name: 'Cultivo De Chile', code: '0112053' },
  { name: 'Cultivo De Esparrago', code: '0112061' },
  { name: 'Cultivo De Frijol', code: '0112079' },
  { name: 'Cultivo De Garbanzo', code: '0112087' },
  { name: 'Cultivo De Jitomate', code: '0112095' },
  { name: 'Cultivo De Lenteja', code: '0112102' },
  { name: 'Cultivo De Nopal', code: '0112110' },
  { name: 'Cultivo De Otras Hortalizas', code: '0112128' },
  { name: 'Cultivo De Papa', code: '0112136' },
  { name: 'Cultivo De Remolacha', code: '0112144' },
  { name: 'Cultivo De Tomate', code: '0112152' },
  { name: 'Cultivo De Yuca', code: '0112160' },
  { name: 'Cultivo De Alfalfa', code: '0113019' },
  { name: 'Cultivo De Fresa', code: '0119017' },
  { name: 'Cultivo De Melon', code: '0119025' },
  { name: 'Cultivo De Piña', code: '0119033' },
  { name: 'Cultivo De Sandia', code: '0119041' },
  { name: 'Cultivo De Algodon', code: '0121012' },
  { name: 'Cultivo De Caña De Azucar', code: '0122010' },
  { name: 'Cultivo De Tabaco', code: '0123018' },
  { name: 'Cultivo De Ajonjoli', code: '0124016' },
  { name: 'Cultivo De Cacahuate', code: '0124024' },
  { name: 'Cultivo De Cartamo', code: '0124032' },
  { name: 'Cultivo De Nuez', code: '0124040' },
  { name: 'Cultivo De Olivo', code: '0124058' },
  { name: 'Cultivo De Especias', code: '0129016' },
  { name: 'Cultivo De Otras Semillas', code: '0129024' },
  { name: 'Cultivo De Semillas Mejoradas', code: '0129032' },
  { name: 'Cultivo De Vainilla', code: '0129040' },
  { name: 'Cultivo De Flores Y Plantas De Ornato', code: '0131011' },
  { name: 'Cultivo De Cafe', code: '0141010' },
  { name: 'Cultivo De Aguacate', code: '0142018' },
  { name: 'Cultivo De Durazno', code: '0142026' },
  { name: 'Cultivo De Guayaba', code: '0142034' },
  { name: 'Cultivo De Limon', code: '0142042' },
  { name: 'Cultivo De Mango', code: '0142050' },
  { name: 'Cultivo De Manzana', code: '0142068' },
  { name: 'Cultivo De Naranja', code: '0142076' },
  { name: 'Cultivo De Otros Arboles Frutales', code: '0142084' },
  { name: 'Cultivo De Papaya', code: '0142092' },
  { name: 'Cultivo De Platano', code: '0142109' },
  { name: 'Cultivo De Tamarindo', code: '0142117' },
  { name: 'Cultivo De Toronja', code: '0142125' },
  { name: 'Cultivo De Vid', code: '0143016' },
  { name: 'Cultivo De Henequen', code: '0144014' },
  { name: 'Cultivo De Lino', code: '0144022' },
  { name: 'Cultivo De Otras Fibras Duras', code: '0144030' },
  { name: 'Cultivo De Agave O Mezcal', code: '145012' },
  { name: 'Cultivo De Maguey', code: '0145020' },
  { name: 'Cultivo De Copra', code: '0146010' },
  { name: 'Cultivo De Cacao', code: '0147018' },
  { name: 'Otros Cultivos Permanentes', code: '0149014' },
  { name: 'Usuarios Menores Agricultura', code: '0149907' },
  { name: 'Cartera Agricola De Estados Analiticos', code: '0149915' },
  { name: 'Irrigacion De Tierras', code: '0191015' },
  { name: 'Preparacion De Tierras De Cultivo Y Otros Servicios Agricolas', code: '0191023' },
  { name: 'Ganadería', code: '0200006' },
  { name: 'Cria Y Explotacion De Ganado Vacuno Para Carne', code: '0211011' },
  { name: 'Cria Y Explotacion De Ganado Vacuno Para Leche', code: '0212019' },
  { name: 'Cria De Ganado De Lidia', code: '0213017' },
  { name: 'Cria De Ganado Caballar', code: '0214015' },
  { name: 'Cria De Otros Equinos Y Ganado Para El Trabajo', code: '0219015' },
  { name: 'Usuarios Menores Ganaderia', code: '0219908' },
  { name: 'Cartera Ganadera De Estados Analiticos', code: '0219916' },
  { name: 'Cria Y Explotacion De Ganado Porcino', code: '0221010' },
  { name: 'Cria Y Explotacion De Ganado Ovino', code: '0222018' },
  { name: 'Cria Y Explotacion De Ganado Caprino', code: '0223016' },
  { name: 'Cria Y Explotacion De Gallina Para Produccion De Huevo', code: '0231019' },
  { name: 'Cria Y Explotacion De Pollos', code: '0232017' },
  { name: 'Cria Y Explotacion De Otras Aves Para Alimentacion', code: '0233015' },
  { name: 'Cria Y Explotacion De Abejas', code: '0241018' },
  { name: 'Cria Y Explotacion De Conejos Y Liebres', code: '0251017' },
  {
    name: 'Cria y explotación de animales domésticos para laboratorio y otros fines no alimenticios',
    code: '0259011'
  },
  { name: 'Cria Y Explotacion De Gusano De Seda', code: '0259029' },
  { name: 'Formacion De Praderas Y Potreros Artificiales', code: '0291013' },
  { name: 'Inseminacion Artificial Y Otros Servicios De Ganaderia', code: '0291021' },
  { name: 'Silvicultura', code: '0300004' },
  { name: 'Plantacion Y Reforestacion', code: '0311019' },
  {
    name: 'Extracción de troncos para aserraderos y para pulpa incluso la madera toscamente aserrada',
    code: '0311017'
  },
  { name: 'Extraccion De Carbon Vegetal', code: '0313015' },
  { name: 'Extraccion De Chicle', code: '0321018' },
  { name: 'Explotacion de candelilla', code: '0322016' },
  { name: 'Explotacion de hule', code: '0322024' },
  { name: 'Extraccion De Colofonia', code: '0322032' },
  { name: 'Extraccion De Otras Resinas', code: '0322040' },
  { name: 'Explotacion de raices', code: '0323014' },
  { name: 'Extraccion De Alquitran Vegetal', code: '0323022' },
  { name: 'Extraccion De Trementina', code: '0323030' },
  { name: 'Cultivo Y Explotacion De Palma Y Lechuguilla', code: '0329012' },
  { name: 'Explotacion De Barbasco Arborescentes Y Arbustos', code: '0329020' },
  {
    name: 'Usuarios Menores Silvicultura, Pesca Y Preservacion De Animales Salvajes',
    code: '0329905'
  },
  { name: 'Cartera Silvicola De Estados Analiticos', code: '0329913' },
  {
    name: 'Servicios de cortado estimacion del volumen de madera proteccion de bosques y otros servicios relativos a la explotacion forestal',
    code: '0391011'
  },
  { name: 'Pesca', code: '0400002' },
  { name: 'Captura de atun bonito barrilete y similares', code: '0411017' },
  { name: 'Captura De Sardina Y Similares', code: '0412015' },
  { name: 'Captura de tiburon cazon raya y similares', code: '0413013' },
  { name: 'Captura de otros peces en estuarios costas o alta mar', code: '0419011' },
  { name: 'Cartera Pesquera De Estados Analiticos', code: '0419912' },
  { name: 'Captura De Camaron', code: '0421016' },
  { name: 'Captura De Ostion', code: '0422014' },
  { name: 'Captura De Otros Crustaceos Y Moluscos Marinos', code: '0429010' },
  { name: 'Captura De Tortuga Y Otros Reptiles Marinos', code: '0431015' },
  { name: 'Recoleccion De Algas Y Otras Plantas Acuaticas', code: '0442012' },
  { name: 'Captura de peces en rios lagos y estuarios', code: '0451013' },
  {
    name: 'Captura de crustaceos moluscos reptiles anfibios y otra fauna de agua dulce',
    code: '0452011'
  },
  { name: 'Cria Y Explotacion De Ostras', code: '0459017' },
  {
    name: 'Cria y explotacion de peces otras especoes animales y plantas acuaticas',
    code: '0459025'
  },
  { name: 'Servicios De Pesquerias Maritimas Y De Agua Dulce Por Contrato', code: '0491019' },
  { name: 'Caza', code: '0500000' },
  { name: 'Captura Y Preservacion De Especies Animales Salvajes', code: '0511015' },
  { name: 'OTRO', code: '0000000' }
];

export const comercioRestaurantesYHoteles = [
  { name: 'Fundicion De Sebo', code: '3072022' },
  { name: 'Captacion', code: '5111018121' },
  { name: 'Compra Venta De Semillas Y Granos', code: '6111017170' },
  { name: 'Compra Venta De Frutas', code: '6112015170' },
  { name: 'Compra Venta De Legumbres Y Hortalizas', code: '6112023170' },
  { name: 'Compra Venta De Forrajes En Estado Natural', code: '6114011170' },
  {
    name: 'Compra Venta De Otros Productos Alimenticios Agricolas En Estado Natural',
    code: '6119011170'
  },
  { name: 'Compra Venta De Aves En Pie', code: '6121016170' },
  { name: 'Compra Venta De Ganado Mayor En Pie', code: '6121024170' },
  { name: 'Compra Venta De Ganado Menor En Pie', code: '6121032170' },
  { name: 'Compra Venta De Carne De Res Y Otras Especies De Ganado', code: '6122014170' },
  { name: 'Compra Venta De Visceras De Ganado Crudas Y Semicrudas', code: '6123012170' },
  { name: 'Compra Venta De Carne De Aves', code: '6124010170' },
  { name: 'Compra Venta De Huevo', code: '6125018170' },
  { name: 'Compra Venta De Manteca', code: '6126016170' },
  { name: 'Compra Venta De Materias Primas Animales', code: '6126024170' },
  { name: 'Compra Venta De Pescados Y Mariscos', code: '6126032170' },
  { name: 'Tienda De Abarrotes Y Miscelanea', code: '6131023' },
  { name: 'Compra Venta De Azucar', code: '6132013171' },
  { name: 'Compra Venta De Especias Y Articulos Alimenticos Deshidratados', code: '6132021170' },
  { name: 'Compra Venta De Leche', code: '6132039170' },
  { name: 'Compra Venta De Otros Productos Lacteos', code: '6132047170' },
  { name: 'Compra Venta De Pan Y Pasteles', code: '6132055170' },
  { name: 'Venta Y Distribucion De Despensas Familiares', code: '6132071' },
  { name: 'Compraventa De Azucar', code: '6132089' },
  { name: 'Compraventa De Alimento Para Ganado', code: '6133011' },
  { name: 'Compra Venta De Refrescos Aguas Gaseosas Y Aguas Purificadas', code: '6134019170' },
  { name: 'Compra Venta De Cerveza', code: '6135017170' },
  { name: 'Compra Venta De Pulque', code: '6136015170' },
  { name: 'Compra Venta De Vinos Y Licores', code: '6136023170' },
  { name: 'Compra Venta De Tabaco', code: '6139019170' },
  { name: 'Compra Venta De Tabacos Puros Y Cigarros', code: '6139027170' },
  { name: 'Compraventa De Prendas De Vestir Y Otros Artículos De Uso Personal', code: '6200000' },
  { name: 'Compra Venta De Articulos De Lenceria', code: '6211015170' },
  { name: 'Compra Venta De Ropa', code: '6211023170' },
  { name: 'Compra Venta De Calzado', code: '6212013170' },
  { name: 'Compra Venta De Sombreros', code: '6213011170' },
  { name: 'Compra Venta De Pieles Finas Con Pelo', code: '6214019170' },
  { name: 'Compra Venta De Articulos De Merceria Y Sederia', code: '6215017170' },
  { name: 'Compraventa De Articulos De Boneteria', code: '6215025' },
  { name: 'Compra Venta De Casimires', code: '6216015170' },
  { name: 'Compra Venta De Otros Productos Textiles', code: '6216023170' },
  { name: 'Compra Venta De Telas', code: '6216031170' },
  { name: 'Compra Venta De Articulos Para Deporte', code: '6221014170' },
  { name: 'Compra Venta De Articulos De Optica', code: '6222012170' },
  { name: 'Compra Venta De Juguetes', code: '6223010170' },
  { name: 'Compra Venta De Instrumentos Y Articulos Musicales', code: '6224018170' },
  { name: 'Compra Venta De Articulos De Plata', code: '6225016170' },
  { name: 'Compra Venta De Otras Joyas', code: '6225024170' },
  { name: 'Compra Venta De Relojes', code: '6225032170' },
  { name: 'Compra Venta De Articulos De Talabarteria', code: '6226014170' },
  { name: 'Compra Venta De Otros Articulos De Piel', code: '6226022170' },
  { name: 'Compra Venta De Sillas De Montar', code: '6226030170' },
  { name: 'Compra Venta De Libros', code: '6227012170' },
  { name: 'Distribucion Y Compraventa De Periodicos Y Revistas', code: '6228010' },
  { name: 'Compra Venta De Articulos Fotograficos Y Cinematograficos', code: '6229018170' },
  { name: 'Compra Venta De Medicinas', code: '6231013170' },
  { name: 'Distribucion De Otros Productos Quimicos-Farmaceuticos', code: '6232011170' },
  {
    name: 'Compra Venta De Televisores Consolas Radios Modulares Y Tocacintas',
    code: '6311021170'
  },
  {
    name: 'Compraventa De Otros Aparatos Electricos Y Electronicos De Uso Domestico',
    code: '6311039'
  },
  { name: 'Compra Venta De Muebles', code: '6312011170' },
  { name: 'Compra Venta De Articulos De Cristal Loza Y Porcelana', code: '6321012170' },
  { name: 'Compra Venta De Articulos Para Decoracion', code: '6322010170' },
  { name: 'Compra Venta De Tapetes Y Alfombras', code: '6322028170' },
  { name: 'Compra Venta De Discos Y Cassettes', code: '6323018170' },
  { name: 'Compra Venta De Articulos Religiosos', code: '6324016170' },
  { name: 'Compra Venta De Antiguedades', code: '6325014170' },
  { name: 'Compra Venta De Articulos Regionales Curiosidades Y Artesanias', code: '6325048170' },
  { name: 'Compra Venta De Flores Y Adornos Florales Artificiales', code: '6326012170' },
  { name: 'Compra Venta De Flores Y Adornos Florales Naturales', code: '6326020170' },
  { name: 'Compra Venta De Otros Articulos Para El Hogar', code: '6329016170' },
  {
    name: 'Compraventa En Tiendas De Autoservicio Y De Departamentos Especializados Por Línea De Mercancías',
    code: '6400006'
  },
  { name: 'Tienda De Autoservicio', code: '6411011' },
  { name: 'Tiendas De Departamentos Especializados Excepto De Comestibles', code: '6412019' },
  { name: 'Compra Venta De Otros Aparatos Electricos De Uso No Domestico', code: '6419015170' },
  { name: 'Compraventa De Gases, Combustibles Y Lubricantes', code: '6500004' },
  { name: 'Compra Venta De Fluidos Y Gases', code: '6511019170' },
  { name: 'Distrbucion Y Comercializacion De Gas', code: '6512017171' },
  { name: 'Compra Venta De Gasolina Y Diesel', code: '6513015170' },
  { name: 'Compra Venta De Petroleo Combustible', code: '6514013170' },
  { name: 'Compraventa De Lubricantes', code: '6515011' },
  {
    name: 'Compraventa De Materias Primas, Materiales Y Auxiliares (Algodón, Cemento, Sanitarios, Pieles, Ferreteria, Madera, Pinturas)',
    code: '6600002'
  },
  { name: 'Compra Venta De Algodon', code: '6611017170' },
  { name: 'Compra Venta De Semillas Para Siembra', code: '6612015170' },
  { name: 'Compra Venta De Cueros Y Pieles Sin Curtir', code: '6613013170' },
  { name: 'Compra Venta De Henequen E Ixtle', code: '6619011170' },
  { name: 'Compra Venta De Otras Materias Primas Vegetales', code: '6619037170' },
  {
    name: 'Compra Venta De Cemento Cal Yeso Y Otros Productos A Base De Minerales No Metalicos',
    code: '6621016170'
  },
  { name: 'Compra Venta De Fierro Laminado Y En Lingotes', code: '6622014170' },
  { name: 'Compraventa De Articulos De Ferreteria', code: '6622022' },
  { name: 'Compra Venta De Pinturas Barnices Y Brochas', code: '6623020170' },
  { name: 'Compra Venta De Vidrios Cristales Y Emplomados', code: '6625018170' },
  { name: 'Compra Venta De Anilinas', code: '6626016170' },
  { name: 'Compra Venta De Articulos De Tlapaleria', code: '6626024170' },
  { name: 'OTRO', code: '0' }
];

export const construccion = [
  {
    name: 'Contratación De Obras Completas De Construcción (Casas, Departamentos, Inmuebles, Pavimentación, No Residenciales, Vias De Comunicación)',
    code: '4100004'
  },
  { name: 'Construccion De Vivienda De Interes Social', code: '4111019110' },
  {
    name: 'Prestamos Para La Construccion De Vivienda  Tanto De Interes Social Como Provenientes De La Reserva Para Pensiones Del Personal',
    code: '4111035'
  },
  { name: 'Cartera De Vivienda De Interes Social De Estados A', code: '4111910' },
  { name: 'Construccion De Vivienda Tipo Medio', code: '4111928' },
  { name: 'Usuarios Menores Construccion De Vivienda Tipo Medio', code: '4111936' },
  { name: 'Construccion De Vivienda Residencial', code: '4111944' },
  { name: 'Menores Construccion De Vivienda Residencial', code: '4111952' },
  {
    name: 'Construccion De Edificios Para Oficinas Escuelas Hospitales Hoteles Y Otros No Residenciales',
    code: '4112017'
  },
  { name: 'Construccion De Vias De Comunicacion', code: '4121018' },
  { name: 'Pavimentacion', code: '4121026' },
  { name: 'Urbanizacion', code: '4121034' },
  { name: 'Construccion De Puertos', code: '4122016' },
  { name: 'Captura De Otros Peces En Estuarios', code: '419011030' },
  { name: 'Perforacion De Pozos Petroleros Y De Gas', code: '4194015' },
  { name: 'Construccion De Estadios Monumentos Y Otras Obras De Ingenieria', code: '4199015' },
  { name: 'Usuarios Menores Construccion Diferente A Vivienda', code: '4199908' },
  {
    name: 'Servicios Especiales Prestados Por Subcontratistas (Demoliciones, Carpinteria, Impermiabilización, Inst Eslectricas, Pintura, Plomeria)',
    code: '4200002'
  },
  { name: 'Labrado Y Colocacion De Piedra', code: '4292017' },
  {
    name: 'Colocacion De Duelas Parquet Lambrines Y Otros Trabajos De Carpinteria',
    code: '4293015'
  },
  { name: 'OTRO', code: '0' }
];

export const electricidadGasYAguaPotable = [
  { name: 'Perforacion De Norias Y Pozos Para Agua', code: '4192019' },
  {
    name: 'Construccion E Instalacion De Plantas Generadoras Y Lineas De Transmision Y Distribucion De Energia Electrica',
    code: '4193017'
  },
  { name: 'Perforacion De Pozos Petroleros Y De Gas', code: '4194015' },
  {
    name: 'Instalaciones Del Sistema Electrico Incluye Sistemas De Intercomunicacion',
    code: '4222014'
  },
  { name: 'Generacion Y Suministro De Energia Electrica', code: '5011010' },
  { name: 'Usuarios Menores Energia Electrica', code: '5011903' },
  { name: 'Cartera De Energia Electrica De Estados Analiticos', code: '5011911' },
  { name: 'Distribucion De Energia Electrica', code: '5012018' },
  { name: 'Pidiregas Energia Electrica', code: '5021019' },
  { name: 'OTRO', code: '0' }
];

export const industriaManufacturera = [
  { name: 'Fabricación De Alimentos', code: '2000008' },
  { name: 'Deshidratacion De Frutas', code: '2011013' },
  { name: 'Empacadora De Conservas Alimenticias', code: '2012011' },
  { name: 'Empacadora De Frutas Y Legumbres', code: '2012029' },
  { name: 'Fabricacion De Concentrados De Frutas', code: '2012037' },
  { name: 'Fabricacion De Encurtidos', code: '2012045' },
  { name: 'Fabricacion De Ates', code: '2013019' },
  { name: 'Fabricacion De Queso Y Miel De Tuna', code: '2013027' },
  { name: 'Fabricacion De Salsas', code: '2014017' },
  { name: 'Molino De Trigo', code: '2021012' },
  { name: 'Molino De Maiz', code: '2022010' },
  { name: 'Molino De Nixtamal', code: '2023018' },
  { name: 'Molino De Arroz', code: '2024016' },
  { name: 'Beneficio De Cafe Excepto Molienda Y Tostado', code: '2025014' },
  { name: 'Molino Y Tostador De Cafe', code: '2026012' },
  { name: 'Empacadora De Te', code: '2027010' },
  { name: 'Beneficio De Arroz Excepto Molienda', code: '2028018' },
  { name: 'Descascarado Y Tostado De Cacahuate Y Nuez', code: '2028026' },
  { name: 'Descascaradora Y Tostadora De Semilla De Calabaza', code: '2028034' },
  { name: 'Molino Para Otros Granos Excepto Cereales', code: '2028042' },
  { name: 'Molino De Avena', code: '2029016' },
  { name: 'Molino De Cebada', code: '2029024' },
  { name: 'Molino De Otros Cereales', code: '2029032' },
  { name: 'Fabricacion De Marquetas Y Estuchados De Azucar', code: '2031011' },
  { name: 'Ingenio Azucarero', code: '2031029' },
  { name: 'Fabricacion De Piloncillo', code: '2032019' },
  { name: 'Destilacion De Alcohol Etilico', code: '2033017' },
  { name: 'Rastro', code: '2041010' },
  { name: 'Empacadora De Carne', code: '2049014' },
  { name: 'Fabricacion De Carnes Frias Y Embutidos', code: '2049022' },
  { name: 'Refrigeracion De Carnes', code: '2049030' },
  { name: 'Fabricacion Y Prehidratacion De Leche', code: '2051019' },
  { name: 'Pasteurizacion Homogeneizacion Y Envasado De Leche', code: '2051027' },
  { name: 'Fabricacion De Crema Mantequilla Y Queso', code: '2052017' },
  { name: 'Fabricacion De Leche Condensada Evaporada Y Pulverizada', code: '2053015' },
  { name: 'Fabricacion De Gelatinas', code: '2054013' },
  { name: 'Fabricacion De Grenetina', code: '2054021' },
  { name: 'Fabricacion De Cajetas Yogurts Y Otros Productos A Base De Leche', code: '2059013' },
  { name: 'Congeladora De Productos Marinos', code: '2061018' },
  { name: 'Empacadora De Otros Mariscos', code: '2061026' },
  { name: 'Empacadora De Pescado', code: '2061034' },
  { name: 'Fabricacion De Pan Y Pasteles', code: '2071017' },
  { name: 'Fabricacion De Conos Para Nieve', code: '2072015' },
  { name: 'Fabricacion De Galletas', code: '2072023' },
  { name: 'Fabricacion De Pastas Alimenticias', code: '2072031' },
  { name: 'Fabricacion De Chocolates', code: '2081016' },
  { name: 'Fabricacion De Dulces Bombones Y Confites', code: '2082014' },
  { name: 'Fabricacion De Goma De Mascar', code: '2083012' },
  { name: 'Tratamiento Y Envase De Miel De Abeja', code: '2084010' },
  { name: 'Fabricacion De Jarabes', code: '2089010' },
  { name: 'Fabricacion De Aceites Vegetales Comestibles', code: '2091015' },
  { name: 'Fabricacion De Mantecas Vegetales Comestibles', code: '2091023' },
  { name: 'Fabricacion De Almidon', code: '2092013' },
  { name: 'Fabricacion De Levaduras', code: '2092021' },
  { name: 'Tortilleria', code: '2093011' },
  { name: 'Fabricacion De Frituras', code: '2094019' },
  { name: 'Fabricacion De Otros Preparados Alimenticios Derivados De Cereales', code: '2094027' },
  { name: 'Fabricacion De Vinagre', code: '2095017' },
  { name: 'Refinacion De Sal', code: '2095025' },
  { name: 'Fabricacion De Hielo', code: '2096015' },
  { name: 'Fabricacion De Helados Nieves Y Paletas', code: '2097013' },
  { name: 'Deshidratacion De Plantas Para Forrajes', code: '2098011' },
  { name: 'Fabricacion De Alimento Para Ganado Y Otros Animales', code: '2098029' },
  { name: 'Fabricacion De Alimentos Para Aves', code: '2098037' },
  { name: 'Empacadora De Especias', code: '2099019' },
  { name: 'Fabricacion De Aceites Y Mantecas Animales Comestibles', code: '2099027' },
  { name: 'Fabricacion De Harina De Pescado', code: '2099035' },
  { name: 'Fabricacion De Otros Productos Alimenticios', code: '2099043' },
  { name: 'Fabricacion De Pastas Para Guisos', code: '2099051' },
  { name: 'Fabricacion De Tequila Y Mezcal', code: '2111011' },
  { name: 'Fabricacion De Aguardiente De Caña', code: '2112019' },
  { name: 'Fabricacion De Sotol', code: '2113017' },
  { name: 'Fabricacion De Otros Aguardientes No De Caña', code: '2114015' },
  { name: 'Fabricacion De Vinos Y Otros Licores', code: '2114023' },
  { name: 'Elaboracion De Pulque', code: '2115013' },
  { name: 'Fabricacion De Malta', code: '2121010' },
  { name: 'Fabricacion De Cerveza', code: '2122018' },
  { name: 'Embotellado De Aguas Minerales', code: '2131019' },
  { name: 'Fabricacion De Bebidas Gaseosas', code: '2131027' },
  { name: 'Fabricacion De Refrescos De Frutas Naturales', code: '2131035' },
  { name: 'Fabricación Y Beneficio De Productos De Tabaco', code: '2200004' },
  { name: 'Desecado De Tabaco', code: '2211019' },
  { name: 'Fabricacion De Cigarros', code: '2212017' },
  { name: 'Fabricacion De Puros', code: '2219013' },
  { name: 'Picado De Tabaco', code: '2219021' },
  { name: 'Industria Textil (Fabricación De: Hilados Y Tejidos)', code: '2300002' },
  { name: 'Desfibracion De Algodon', code: '2311017' },
  { name: 'Despepite De Algodon', code: '2311025' },
  { name: 'Compresora De Algodon', code: '2311033' },
  { name: 'Beneficio De Lanas', code: '2312015' },
  { name: 'Beneficio De Otras Fibras Textiles', code: '2312023' },
  { name: 'Fabricacion De Hilados Y Tejidos De Algodon', code: '2312031' },
  { name: 'Fabricacion De Hilados Y Tejidos De Lana', code: '2312049' },
  { name: 'Fabricacion De Hilos Para Coser', code: '2313013' },
  { name: 'Acabado De Hilos', code: '2313021' },
  { name: 'Fabricacion De Otros Hilados Y Tejidos No Sinteticos', code: '2313039' },
  { name: 'Fabricacion De Estambres', code: '2314011' },
  { name: 'Fabricacion De Sarapes Y Cobijas', code: '2315019' },
  { name: 'Fabricacion De Casimires Y Paños', code: '2315027' },
  { name: 'Fabricacion De Toallas', code: '2316017' },
  { name: 'Fabricacion De Colchas', code: '2316025' },
  { name: 'Fabricacion De Hilados Y Tejidos De Seda', code: '2317015' },
  { name: 'Fabricacion De Otros Articulos De Lana', code: '2317023' },
  { name: 'Fabricacion De Otras Telas Mixtas De Fibras Blandas', code: '2317031' },
  { name: 'Fabricacion De Cintas Agujetas Y Listones', code: '2318013' },
  { name: 'Fabricacion De Encajes', code: '2318021' },
  { name: 'Fabricacion De Telas Elasticas', code: '2318039' },
  { name: 'Acabado De Telas', code: '2319011' },
  { name: 'Fabricacion De Calcetines', code: '2321016' },
  { name: 'Fabricacion De Medias', code: '2321024' },
  { name: 'Fabricacion De Sueteres', code: '2322014' },
  { name: 'Fabricacion De Hilados Y Tejidos De Otras Fibras Sinteticas', code: '2329010' },
  { name: 'Desfibracion De Henequen', code: '2331015' },
  { name: 'Fabricacion De Hilados Y Tejidos De Henequen', code: '2332013' },
  { name: 'Fabricacion De Articulos De Palma Y Tule', code: '2333011' },
  { name: 'Fabricacion De Tejidos Y Torcidos De Palma', code: '2333029' },
  { name: 'Fabricacion De Tejidos Y Torcidos De Ixtle', code: '2333037' },
  { name: 'Fabricacion De Hilados Y Tejidos De Yute', code: '2339019' },
  { name: 'Fabricacion De Hilados Y Tejidos De Fibra De Coco', code: '2339027' },
  { name: 'Fabricacion De Articulos De Lona', code: '2391019' },
  { name: 'Fabricacion De Lona', code: '2391027' },
  { name: 'Fabricacion De Telas Impermeables', code: '2391035' },
  { name: 'Fabricacion De Tapetes Y Alfombras', code: '2392017' },
  { name: 'Fabricacion De Telas Para Tapiceria', code: '2392025' },
  { name: 'Fabricacion De Entretelas Y Fieltros', code: '2393015' },
  { name: 'Beneficio De Pelo Y Cerda Para La Industria Textil', code: '2394013' },
  { name: 'Fabricacion De Borras Y Estopas', code: '2394021' },
  { name: 'Fabricacion De Otros Articulos De Algodon', code: '2394039' },
  {
    name: 'Fabricación De Prendas De Vestir Y Otros Artículos Confeccionados Con Textiles Y Otros Materiales Excepto Calzado',
    code: '2400000'
  },
  {
    name: 'Fabricacion De Vestidos Y Otras Prendas Exteriores De Vestir Para Dama',
    code: '2411015'
  },
  { name: 'Taller De Confeccion De Vestidos', code: '2411023' },
  { name: 'Fabricacion De Otras Prendas Exteriores De Vestir Para Caballero', code: '2412013' },
  { name: 'Fabricacion De Trajes Para Caballero', code: '2412021' },
  { name: 'Taller De Sastreria', code: '2412039' },
  { name: 'Fabricacion De Uniformes', code: '2413011' },
  { name: 'Fabricacion De Camisas', code: '2414019' },
  { name: 'Fabricacion De Otras Prendas Exteriores De Vestir Para Niño', code: '2415017' },
  { name: 'Fabricacion De Corbatas', code: '2416015' },
  { name: 'Fabricacion De Guantes', code: '2416023' },
  { name: 'Fabricacion De Pañuelos Pañoletas Y Mascadas', code: '2416031' },
  { name: 'Fabricacion De Cachuchas Y Gorras', code: '2417013' },
  { name: 'Fabricacion De Sombreros Excepto De Palma', code: '2417021' },
  { name: 'Fabricacion De Sombreros De Palma', code: '2418011' },
  { name: 'Fabricacion De Chamarras Y Abrigos', code: '2419019' },
  { name: 'Fabricacion De Impermeables', code: '2419027' },
  { name: 'Fabricacion De Tirantes', code: '2419035' },
  { name: 'Fabricacion De Mantones Y Chalinas', code: '2419043' },
  { name: 'Fabricacion De Rebozos Y Cinturones Tejidos De Hilo', code: '2419051' },
  { name: 'Fabricacion De Ropa Con Piel', code: '2419069' },
  { name: 'Fabricacion De Corseteria Y Ropa Interior Para Dama', code: '2421014' },
  { name: 'Fabricacion De Ropa Interior Para Caballero', code: '2429018' },
  { name: 'Fabricacion De Sabanas', code: '2431013' },
  { name: 'Fabricacion De Cortinas De Tela Y Manteleria', code: '2431021' },
  { name: 'Fabricacion De Cubre Asientos Para Vehiculos', code: '2432011' },
  { name: 'Fabricacion De Tapices Plasticos', code: '2432029' },
  {
    name: 'Fabricacion De Algodon Absorbente Vendas Tela Adhesiva Y Productos Similares',
    code: '2433019'
  },
  { name: 'Fabricacion De Articulos Bordados Y Deshilados', code: '2434017' },
  { name: 'Forrado De Botones Y Hebillas', code: '2434025' },
  { name: 'Fabricacion De Banderas Y Adornos De Tela', code: '2439017' },
  { name: 'Fabricacion De Costales', code: '2439025' },
  { name: 'Fabricación De Calzado E Industria Del Cuero', code: '2500008' },
  { name: 'Fabricacion De Calzado Para Deporte Excepto De Plastico Y Hule', code: '2511013' },
  { name: 'Fabricacion De Alpargatas Babuchas Y Pantuflas', code: '2512011' },
  { name: 'Fabricacion De Guaraches Y Sandalias', code: '2512029' },
  { name: 'Fabricacion De Calzado De Cuero O Piel', code: '2519017' },
  { name: 'Curtiduria', code: '2521012' },
  { name: 'Preparacion De Visceras Para Industrias No Alimenticias', code: '2521020' },
  { name: 'Fabricacion De Cepillos Y Plumeros', code: '2529016' },
  { name: 'Fabricacion De Articulos De Cuero Para Viaje', code: '2529024' },
  { name: 'Fabricacion De Articulos De Cuero Y Hueso', code: '2529032' },
  { name: 'Fabricacion De Articulos De Cuero Y Piel Para Zapatero', code: '2529040' },
  { name: 'Fabricacion De Articulos Tejidos De Cuero', code: '2529058' },
  { name: 'Fabricacion De Bandas Y Correas De Cuero', code: '2529066' },
  { name: 'Fabricacion De Bolsas Y Carteras De Cuero', code: '2529074' },
  { name: 'Fabricacion De Brochas Y Pinceles', code: '2529082' },
  { name: 'Fabricacion De Fornituras Militares De Cuero', code: '2529090' },
  { name: 'Fabricacion De Otros Articulos De Cuero', code: '2529107' },
  { name: 'Talabarteria', code: '2529115' },
  { name: 'Industria Y Productos De Madera Y Corcho; Excepto Muebles', code: '2600006' },
  { name: 'Aserradero', code: '2611011' },
  { name: 'Beneficio De Maderas (Desflemado Estufado Cepillado Etc)', code: '2611029' },
  { name: 'Fabricacion De Triplay Y Otros Aglomerados De Madera', code: '2612019' },
  { name: 'Fabricacion De Cajas Y Empaques De Madera', code: '2621010' },
  { name: 'Fabricacion De Toneles Y Barricas De Madera', code: '2621028' },
  { name: 'Fabricacion De Articulos De Palma Vara Carrizo Mimbre Y Similares', code: '2622018' },
  { name: 'Fabricacion De Ataudes De Madera', code: '2631019' },
  { name: 'Fabricacion De Juntas Y Empaques De Corcho', code: '2632017' },
  { name: 'Fabricacion De Otros Articulos De Corcho', code: '2632025' },
  { name: 'Fabricacion De Asbestos Y Corchos Aislantes', code: '2632033' },
  { name: 'Fabricacion De Duelas Y Otros Materiales De Madera Para Piso', code: '2633015' },
  { name: 'Fabricacion De Puertas Y Ventanas De Madera', code: '2633023' },
  { name: 'Fabricacion De Carrocerias Y Redilas De Madera', code: '2639013' },
  { name: 'Fabricacion De Mangos De Madera', code: '2639021' },
  { name: 'Fabricacion De Otros Articulos De Madera', code: '2639039' },
  {
    name: 'Fabricación Y Reparación De Muebles Y Accesorios; Excepto Los De Metal Y Los De Plástico Moldeado',
    code: '2700004'
  },
  { name: 'Fabricacion De Muebles De Madera', code: '2711019' },
  { name: 'Fabricacion De Muebles De Material Sintetico', code: '2711027' },
  { name: 'Fabricacion De Persianas De Madera', code: '2712017' },
  { name: 'Fabricacion De Almohadas Y Cojines', code: '2713015' },
  { name: 'Fabricacion De Colchones Y Colchonetas', code: '2713023' },
  { name: 'Fabricacion De Marcos Y Molduras De Madera', code: '2719013' },
  { name: 'Industria Del Papel', code: '2800002' },
  { name: 'Fabricacion De Papel', code: '2811017' },
  { name: 'Fabricacion De Papel Para Cigarros', code: '2811025' },
  { name: 'Fabricacion De Pasta De Celulosa', code: '2811033' },
  { name: 'Fabricacion De Pasta O Pulpa Para Papel', code: '2811041' },
  { name: 'Fabricacion De Carton', code: '2812015' },
  { name: 'Fabricacion De Sacos Y Bolsas De Papel Para Envase', code: '2821016' },
  { name: 'Fabricacion De Envases De Carton', code: '2822014' },
  { name: 'Fabricacion De Otros Articulos De Carton', code: '2829010' },
  { name: 'Fabricacion De Otros Articulos De Papel', code: '2829028' },
  { name: 'Edicion De Periodicos Y Revistas', code: '2911015' },
  { name: 'Edicion De Libros Y Similares', code: '2912005' },
  { name: 'Edicion De Libros', code: '2912013' },
  { name: 'Encuadernacion', code: '2921014' },
  { name: 'Fabricacion De Formularios Y Formas Continuas', code: '2921022' },
  { name: 'Fabricacion De Libretas Cuadernos Y Hojas Para Encuadernacion', code: '2921030' },
  { name: 'Fotocopiado', code: '2921048' },
  { name: 'Imprenta ( Tipografia)', code: '2921056' },
  { name: 'Impresion Mediante Cilindros De Caucho', code: '2921064' },
  { name: 'Impresion Mediante Cilindros (Rotograbado)', code: '2921072' },
  { name: 'Fabricacion De Calcomanias', code: '2929018' },
  { name: 'Fabricacion De Tipos Para Imprenta', code: '2929026' },
  { name: 'Grabado E Impresion Fotomecanica Mediante Agua Fuerte(Heliograbado)', code: '2929042' },
  { name: 'Grabado En Metal (Fabricacion De Cliches Y Fotograbado)', code: '2929050' },
  { name: 'Industria Química', code: '3000007' },
  { name: 'Fabricacion De Anilinas', code: '3011020' },
  { name: 'Fabricacion De Otras Materias Colorantes', code: '3011038' },
  { name: 'Fabricacion De Gas Acetileno', code: '3012010' },
  { name: 'Fabricacion De Acidos Industriales', code: '3013018' },
  { name: 'Fabricacion De Otras Substancias Quimicas Basicas', code: '3013026' },
  { name: 'Fabricacion De Productos Amoniacales', code: '3013034' },
  { name: 'Fabricacion De Sosa', code: '3013042' },
  { name: 'Fabricacion De Abonos Y Fertilizantes Quimicos', code: '3021011' },
  { name: 'Fabricacion De Insecticidas Y Plaguicidas', code: '3022019' },
  { name: 'Fabricacion De Hule Espuma', code: '3031010' },
  { name: 'Fabricacion De Fibras Sinteticas', code: '3032018' },
  { name: 'Fabricacion De Otros Productos Farmaceuticos Y Medicamentos', code: '3051026' },
  { name: 'Fabricacion De Oxigeno Medicinal', code: '3051034' },
  { name: 'Fabricacion De Dentifrico', code: '3061017' },
  { name: 'Fabricacion De Jabon Y Detergente', code: '3061025' },
  { name: 'Fabricacion De Perfumes Y Cosmeticos', code: '3062015' },
  { name: 'Fabricacion De Esencias', code: '3071016' },
  {
    name: 'Fabricacion Y Refinacion De Sebo Grasas Y Aceites Animales Para Uso Industrial',
    code: '3072014'
  },
  { name: 'Fundicion De Sebo', code: '3072022' },
  { name: 'Fabricacion De Colas Y Pegamentos', code: '3091014' },
  { name: 'Fabricacion De Impermeabilizantes', code: '3091022' },
  { name: 'Fabricacion De Cera Pulimentos Y Abrillantadores', code: '3092012' },
  { name: 'Fabricacion De Desinfectantes Y Desodorizantes', code: '3092020' },
  { name: 'Fabricacion De Grasas Y Cremas Lustradoras', code: '3092038' },
  { name: 'Fabricacion De Lanolina', code: '3092046' },
  { name: 'Fabricacion De Aguarras', code: '3093010' },
  { name: 'Fabricacion De Cerillos Y Fosforos', code: '3094018' },
  { name: 'Fabricacion De Otros Articulos De Cera', code: '3095016' },
  { name: 'Fabricacion De Velas Veladoras Y Cirios', code: '3095024' },
  { name: 'Fabricacion De Tintas Para Impresion', code: '3096014' },
  { name: 'Fabricacion De Dinamita', code: '3097012' },
  { name: 'Fabricacion De Mechas Para Minas', code: '3097020' },
  { name: 'Fabricacion De Otros Explosivos', code: '3097038' },
  { name: 'Fabricacion De Polvora', code: '3097046' },
  { name: 'Fabricacion De Productos Pirotecnicos', code: '3097054' },
  { name: 'Fabricacion De Articulos De Baquelita', code: '3099018' },
  { name: 'Fabricacion De Desincrustantes', code: '3099026' },
  { name: 'Fabricacion De Hielo Seco', code: '3099034' },
  { name: 'Industrializacion De Basura', code: '3099042' },
  { name: 'Refinación De Petróleo Y Derivados Del Carbón Mineral', code: '3100005' },
  {
    name: 'Fabricacion De Gasolina Y Otros Productos Derivados De La Refinacion De Petroleo',
    code: '3111010'
  },
  { name: 'Fabricacion De Productos Petroquimicos Basicos', code: '3112018' },
  { name: 'Fabricacion De Aceites Y Lubricantes', code: '3113016' },
  { name: 'Fabricacion De Coque Y Otros Derivados Del Carbon Mineral', code: '3121019' },
  { name: 'Fabricacion De Materiales Asfalticos Para Pavimentacion Y Techado', code: '3122017' },
  { name: 'Fabricación De Productos De Hule Y De Plástico', code: '3200003' },
  { name: 'Fabricacion De Llantas Y Camaras Para Vehiculos', code: '3211018' },
  { name: 'Regeneracion De Hule', code: '3212016' },
  { name: 'Vulcanizacion De Llantas Y Camaras', code: '3212024' },
  { name: 'Fabricacion De Articulos Con Hule Usado', code: '3219012' },
  { name: 'Fabricacion De Linoleo', code: '3219020' },
  { name: 'Fabricacion De Otros Articulos De Hule', code: '3219038' },
  {
    name: 'Fabricacion De Laminas Perfiles Tubos Y Otros Materiales Similares De Plastico',
    code: '3221017'
  },
  { name: 'Fabricacion De Articulos De Polietileno', code: '3222015' },
  { name: 'Fabricacion De Celuloide Y Polietileno', code: '3222023' },
  { name: 'Fabricacion De Calzado De Plastico', code: '3223013' },
  { name: 'Fabricacion De Otros Juguetes De Plastico', code: '3223021' },
  { name: 'Fabricacion De Articulos De Celuloide', code: '3229011' },
  { name: 'Fabricacion De Balones Globos Y Pelotas', code: '3229029' },
  { name: 'Fabricacion De Botones De Plastico', code: '3229037' },
  { name: 'Fabricacion De Otros Articulos De Plastico', code: '3229045' },
  { name: 'Fabricacion De Otros Materiales De Plastico', code: '3229053' },
  { name: 'Fabricacion De Peines Peinetas Y Cepillos Para Uso Personal', code: '3229061' },
  { name: 'Fabricacion De Pieles Artificiales', code: '3229079' },
  {
    name: 'Fabricación De Productos De Minerales No Metálicos; Excepto Del Petróleo Y Del Carbón Mineral',
    code: '3300001'
  },
  {
    name: 'Fabricacion De Vajillas Y Otros Productos De Alfareria Y Ceramica Para El Hogar',
    code: '3311016'
  },
  { name: 'Elaboracion De Objetos Artisticos De Alfareria Y Ceramica', code: '3319010' },
  { name: 'Fabricacion De Azulejos', code: '3319028' },
  { name: 'Fabricacion De Loza Y Porcelana', code: '3319036' },
  { name: 'Fabricacion De Muebles Y Articulos Sanitarios', code: '3319044' },
  { name: 'Fabricacion De Vidrio', code: '3321015' },
  { name: 'Fabricacion De Cristales Para Automovil', code: '3322013' },
  { name: 'Fabricacion De Fibra De Vidrio', code: '3322021' },
  { name: 'Fabricacion De Ampolletas', code: '3323011' },
  { name: 'Fabricacion De Botellas', code: '3323029' },
  { name: 'Fabricacion De Crisoles', code: '3323037' },
  { name: 'Fabricacion De Otros Envases De Vidrio', code: '3323045' },
  { name: 'Fabricacion De Emplomados', code: '3324019' },
  { name: 'Fabricacion De Espejos Y Lunas', code: '3324027' },
  { name: 'Fabricacion De Esferas De Vidrio', code: '3329019' },
  { name: 'Fabricacion De Objetos Artisticos De Vidrio Y Cristal', code: '3329027' },
  { name: 'Fabricacion De Otros Objetos De Cristal', code: '3329035' },
  { name: 'Fabricacion De Adobe', code: '3331014' },
  { name: 'Fabricacion De Ladrillos', code: '3331022' },
  { name: 'Fabricacion De Teja Y Tubo De Arcilla', code: '3331030' },
  { name: 'Fabricacion De Materiales Para Muros', code: '3332012' },
  { name: 'Fabricacion De Productos Refractarios', code: '3332020' },
  { name: 'Fabricacion De Cemento', code: '3341013' },
  { name: 'Fabricacion De Concreto Cientifico', code: '3341021' },
  { name: 'Fabricacion De Concreto Para Construccion', code: '3341039' },
  { name: 'Fabricacion De Bloques De Cemento', code: '3342011' },
  { name: 'Fabricacion De Yeso', code: '3342029' },
  { name: 'Horno De Cal', code: '3343019' },
  { name: 'Fabricacion De Tinacos De Asbesto', code: '3351012' },
  { name: 'Fabricacion De Abrasivos', code: '3352010' },
  { name: 'Fabricacion De Esmeriles', code: '3352028' },
  { name: 'Fabricacion De Lija', code: '3352036' },
  { name: 'Taller De Marmoleria', code: '3353018' },
  { name: 'Fabricacion De Mosaicos Terrazos Y Granito', code: '3354016' },
  { name: 'Fabricacion De Postes Y Durmientes De Concreto', code: '3354024' },
  { name: 'Fabricacion De Tubos De Concreto', code: '3354032' },
  { name: 'Industrias Metálicas Básicas', code: '3400009' },
  { name: 'Fabricacion De Fierro Esponja', code: '3411014' },
  { name: 'Fundicion De Fierro Y Acero', code: '3411022' },
  { name: 'Planta Metalurgica', code: '3411030' },
  { name: 'Usuarios Menores De Industria Siderurgica Y Productos Metalicos', code: '3411907' },
  { name: 'Cartera Siderurgica Y De Productos Metalicos De Es', code: '3411915' },
  { name: 'Fabricacion De Laminas De Hierro Y Acero', code: '3412012' },
  { name: 'Fabricacion De Otros Articulos De Lamina', code: '3412020' },
  { name: 'Fabricacion De Tubos De Hierro Y Acero', code: '3413010' },
  {
    name: 'Fundicion Refinacion Laminacion Extrusion Y Estiraje De Cobre Y Sus Aleaciones',
    code: '3421013'
  },
  {
    name: 'Fundicion Laminacion Extrusion Y Estiraje De Aluminio Y Fabricacion De Soldaduras Aluminotermicas',
    code: '3422011'
  },
  { name: 'Fabricacion De Tubos De Estaño', code: '3423019' },
  { name: 'Fabricacion De Equipos Para Soldar Y Soldaduras', code: '3423027' },
  { name: 'Fundicion De Metales No Ferruginosos', code: '3429017' },
  { name: 'Fabricación De Productos Metálicos; Excepto Maquinaria Y Equipo', code: '3500007' },
  { name: 'Fabricacion De Cubiertos Y Cuchilleria', code: '3511012' },
  { name: 'Fabricacion De Utensilios Agricolas Y Herramientas De Mano', code: '3512010' },
  { name: 'Fabricacion De Remaches', code: '3513018' },
  { name: 'Fabricacion De Tornillos Tuercas Y Pijas', code: '3513026' },
  { name: 'Fabricacion De Clavos Cadenas Grapas Y Tachuelas', code: '3514016' },
  { name: 'Fabricacion De Candados', code: '3515014' },
  { name: 'Fabricacion De Cerraduras', code: '3515022' },
  { name: 'Fabricacion De Cortinas De Metal', code: '3516012' },
  { name: 'Fabricacion De Jaulas De Metal', code: '3516020' },
  { name: 'Fabricacion De Otros Articulos De Aluminio', code: '3516038' },
  { name: 'Fabricacion De Perfiles Puertas Y Ventanas De Metal', code: '3516046' },
  { name: 'Taller De Herreria', code: '3516054' },
  { name: 'Fabricacion De Butacas De Metal', code: '3521011' },
  { name: 'Fabricacion De Estufas', code: '3521029' },
  { name: 'Fabricacion De Otros Muebles De Metal', code: '3521037' },
  { name: 'Fabricacion De Estructuras De Metal', code: '3531010' },
  { name: 'Fabricacion De Tanques Para Envasado De Gases O Liquidos', code: '3531028' },
  { name: 'Fabricacion De Calderas Tanques Y Tinacos De Metal', code: '3532018' },
  { name: 'Fabricacion De Calentadores Para Baño', code: '3532026' },
  { name: 'Fabricacion De Envases De Lamina', code: '3591014' },
  { name: 'Taller De Hojalateria', code: '3591022' },
  { name: 'Fabricacion De Corcholatas', code: '3592012' },
  { name: 'Fabricacion De Alambrados Y Telas De Metal', code: '3593010' },
  { name: 'Fabricacion De Alambre', code: '3593028' },
  { name: 'Fabricacion De Otros Articulos De Alambre', code: '3593036' },
  { name: 'Fabricacion De Articulos De Peltre', code: '3594018' },
  { name: 'Galvanizacion De Lamina', code: '3595016' },
  { name: 'Galvanizacion De Tuberia', code: '3595024' },
  { name: 'Taller De Cobrizado', code: '3595032' },
  { name: 'Taller De Cromado Y Niquelado', code: '3595040' },
  { name: 'Taller De Esmaltado', code: '3595058' },
  {
    name: 'Fabricacion De Piezas Metalicas Por Fundicion Y Moldeo Excepto Para Maquinaria Equipo Y Material De Transporte',
    code: '3596014'
  },
  { name: 'Fabricacion De Alfileres Agujas Y Broches', code: '3599018' },
  { name: 'Fabricacion De Cajas Fuertes', code: '3599026' },
  { name: 'Fabricacion De Cierres Automaticos De Metal', code: '3599034' },
  { name: 'Fabricacion De Espuelas Frenos Y Arneses De Metal', code: '3599042' },
  { name: 'Fabricacion De Hebillas De Metal', code: '3599050' },
  { name: 'Fabricacion De Hojas Para Rasurar', code: '3599068' },
  { name: 'Fabricacion De Ojillos De Metal', code: '3599076' },
  { name: 'Fabricacion De Otros Articulos De Laton', code: '3599084' },
  {
    name: 'Fabricacion De Otros Articulos De Metal No Clasificados En Otra Parte',
    code: '3599092'
  },
  { name: 'Fabricacion De Pasadores Y Horquillas', code: '3599109' },
  { name: 'Fabricacion De Planchas Industriales', code: '3599117' },
  { name: 'Fabricacion De Tapones De Metal', code: '3599125' },
  { name: 'Fabricacion De Troqueles', code: '3599133' },
  { name: 'Fabricacion Ensamble Y Reparacion De Vehiculos De Uso Agropecuario', code: '3611010' },
  { name: 'Fabricacion De Maquinaria E Implementos Agricolas', code: '3611028' },
  { name: 'Fabricacion De Herramientas De Metal', code: '3621019' },
  { name: 'Fabricacion De Refacciones Y Maquinaria Industrial', code: '3621027' },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Maquinaria Y Equipo Para La Industria De Alimentos Y Bebidas',
    code: '3631018'
  },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Maquinaria Equipo Y Tractores Para Las Industrias Extractivas Y De La Construccion',
    code: '3632016'
  },
  { name: 'Fabricacion De Molinos Para Granos', code: '3639012' },
  { name: 'Usuarios Menores Fabricacion Maquinaria Y Articulos Electricos', code: '3639905' },
  { name: 'Cartera De Fabricacion De Maquinaria Y Articulos E', code: '3639913' },
  { name: 'Fabricacion De Maquinas Calculadoras Registradoras Y De Escribir', code: '3641017' },
  { name: 'Fabricacion De Equipo De Procesamiento Electronico De Datos', code: '3641025' },
  { name: 'Fabricacion De Maquinas De Coser', code: '3691012' },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Gruas Montacargas Y Otras Maquinas Para Transportar O Levantar',
    code: '3692010'
  },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Motores No Electricos Excepto Para Vehiculos Automoviles',
    code: '3693018'
  },
  { name: 'Fabricacion De Extinguidores', code: '3694016' },
  { name: 'Fabricacion De Bombas Para Agua', code: '3694024' },
  { name: 'Fabricacion De Valvulas De Metal', code: '3695014' },
  { name: 'Fabricacion De Filtros De Metal', code: '3696012' },
  {
    name: 'Fabricacion E Instalacion De Equipos Y Aparatos De Aire Acondicionado Calefaccion Y Refrigeracion',
    code: '3697010'
  },
  { name: 'Fabricacion De Engranes De Metal', code: '3699016' },
  { name: 'Taller Mecanico De Fabricacion Y Reparacion De Partes Industriales', code: '3699024' },
  { name: 'Fabricacion De Accesorios Para La Industria Textil', code: '3699032' },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Transformadores Motores Y Maquinaria Y Equipo Para Generacion Y Utilizacion De La Energia Electrica',
    code: '3711018'
  },
  { name: 'Fabricacion De Radios Tocadiscos Grabadoras Y Televisores', code: '3721017' },
  { name: 'Fabricacion De Discos Y Cintas Para Grabaciones', code: '3722015' },
  { name: 'Grabacion De Discos Y Cintas', code: '3722023' },
  { name: 'Fabricacion De Aparatos De Intercomunicacion', code: '3723013' },
  {
    name: 'Fabricacion De Partes Dispositivos Y Accesorios Para Equipo Y Aparatos De Radio Television Y Comunicaciones',
    code: '3729011'
  },
  { name: 'Fabricacion De Otros Aparatos Electricos Para El Hogar', code: '3731016' },
  { name: 'Fabricacion De Refrigeradores Y Equipos De Calefaccion Domestica', code: '3731024' },
  { name: 'Fabricacion De Acumuladores', code: '3791010' },
  { name: 'Fabricacion De Pilas Secas', code: '3791028' },
  { name: 'Fabricacion De Focos', code: '3792018' },
  { name: 'Fabricacion De Cables De Metal', code: '3793016' },
  { name: 'Fabricacion De Material Para Instalaciones Electricas', code: '3793024' },
  { name: 'Fabricacion De Refacciones Para Aparatos Electricos', code: '3793032' },
  { name: 'Fabricacion De Anuncios Luminosos', code: '3799014' },
  { name: 'Fabricacion De Candiles Y Arbotantes', code: '3799022' },
  { name: 'Fabricacion De Lamparas Electricas', code: '3799030' },
  { name: 'Fabricacion Y Ensamble De Automoviles Y Camiones', code: '3811016' },
  { name: 'Fabricacion De Carrocerias De Metal', code: '3812014' },
  { name: 'Fabricacion De Furgones Y Vagones', code: '3812022' },
  { name: 'Fabricacion Y Ensamble De Otros Vehiculos', code: '3812030' },
  {
    name: 'Fabricacion De Pistones Bielas Anillos Cigüeñales Y Monoblocks Para Motores',
    code: '3813012'
  },
  {
    name: 'Fabricacion De Partes Para El Sistema De Transmision De Vehiculos Automoviles',
    code: '3814010'
  },
  { name: 'Fabricacion De Muelles Y Resortes Para Vehiculos', code: '3815018' },
  {
    name: 'Fabricacion De Partes Para El Sistema De Frenos De Vehiculos Automoviles',
    code: '3816016'
  },
  { name: 'Fabricacion De Bujias', code: '3817014' },
  { name: 'Fabricacion De Refacciones Y Accesorios Automotrices', code: '3819010' },
  {
    name: 'Fabricacion Ensamble Y Reparacion De Carros De Ferrocarril Y Otro Equipo Ferroviario',
    code: '3821015'
  },
  { name: 'Fabricacion Y Reparacion De Buques Y Barcos', code: '3831014' },
  { name: 'Fabricacion Y Reparacion De Otros Vehiculos Acuaticos', code: '3831022' },
  { name: 'Fabricacion Ensamble Y Reparacion De Aeronaves', code: '3832012' },
  {
    name: 'Fabricacion Y Ensamble De Motocicletas Bicicletas Y Otros Vehiculos De Pedal',
    code: '3891018'
  },
  {
    name: 'Fabricacion De Partes Refacciones Y Accesorios Para Motocicletas Bicicletas Y Otros Vehiculos De Pedal',
    code: '3892016'
  },
  { name: 'Fabricacion De Carreolas Y Andaderas', code: '3899012' },
  { name: 'Fabricacion Y Reparacion De Articulos De Optica', code: '3911014' },
  { name: 'Fabricacion De Camaras Fotograficas De Cine Y Proyectores', code: '3912012' },
  { name: 'Fabricacion De Material Fotografico', code: '3912020' },
  { name: 'Fabricacion De Relojes', code: '3921013' },
  { name: 'Fabricacion De Medallas Insignias Y Placas', code: '3931012' },
  { name: 'Fabricacion De Articulos De Joyeria', code: '3932010' },
  { name: 'Orfebreria', code: '3932028' },
  { name: 'Tallado De Piedras Preciosas', code: '3932036' },
  { name: 'Fabricacion De Articulos De Quincalleria Y Bisuteria', code: '3933018' },
  { name: 'Fabricacion De Cuerdas Para Guitarra', code: '3941011' },
  { name: 'Fabricacion Y Reparacion De Instrumentos Musicales', code: '3941029' },
  { name: 'Fabricacion De Aparatos Para Gimnasia', code: '3951010' },
  { name: 'Fabricacion De Mesas De Billar', code: '3951028' },
  { name: 'Fabricacion De Otros Articulos Para Deportes', code: '3951036' },
  { name: 'Fabricacion De Otros Instrumentos De Medicion', code: '3961019' },
  { name: 'Fabricacion Y Reparacion De Basculas Y Balanzas', code: '3961027' },
  { name: 'Fabricacion Y Reparacion De Instrumentos De Fisica Y Quimica', code: '3961035' },
  { name: 'Fabricacion Y Reparacion De Instrumentos De Ingenieria', code: '3961043' },
  { name: 'Fabricacion De Aparatos Ozonizadores', code: '3961051' },
  { name: 'Fabricacion De Taximetros', code: '3961069' },
  { name: 'Fabricacion Y Reparacion De Aparatos Ortopedicos', code: '3962017' },
  { name: 'Fabricacion Y Reparacion De Instrumentos De Cirugia', code: '3962025' },
  { name: 'Fabricacion De Juguetes Excepto Los De Hule Y De Plastico Moldeados', code: '3991016' },
  { name: 'Fabricacion De Articulos Para Escritorio', code: '3992014' },
  { name: 'Fabricacion De Canuteros', code: '3992022' },
  {
    name: 'Fabricacion De Cintas Para Maquinas Calculadoras Registradoras Y De Escribir',
    code: '3992030'
  },
  { name: 'Fabricacion De Lapices', code: '3992048' },
  { name: 'Fabricacion De Pizarrones', code: '3992056' },
  { name: 'Fabricacion De Sellos De Goma', code: '3993012' },
  { name: 'Fabricacion De Sellos Para Carros De Ferrocarril', code: '3993020' },
  { name: 'Fabricacion De Articulos Para Dentista', code: '3994010' },
  { name: 'Fabricacion De Cercos Para Calzado', code: '3995018' },
  { name: 'Fabricacion De Hormas Para Calzado Y Tacones', code: '3995026' },
  { name: 'Fabricacion De Escobas', code: '3996016' },
  { name: 'Fabricacion De Armas', code: '3997014' },
  { name: 'Fabricacion De Cartuchos', code: '3997022' },
  { name: 'Fabricacion De Casquillos', code: '3997030' },
  { name: 'Fabricacion De Municiones Para Caza', code: '3997048' },
  { name: 'Fabricacion De Abanicos', code: '3999010' },
  { name: 'Fabricacion De Articulos De Carey', code: '3999028' },
  { name: 'Fabricacion De Articulos De Concha', code: '3999036' },
  { name: 'Fabricacion De Bastones Paraguas Y Sombrillas', code: '3999044' },
  { name: 'Fabricacion De Flores Artificiales', code: '3999052' },
  { name: 'Fabricacion De Otros Articulos De Pluma De Ave', code: '3999060' },
  { name: 'Fabricacion De Rotulos', code: '3999078' },
  { name: 'Fabricacion De Talco Industrial', code: '3999086' },
  { name: 'Fabricacion De Tragaluces Y Marquesinas', code: '3999094' },
  {
    name: 'Fabricacion De Productos Manufacturados No Clasificados En Otra Parte',
    code: '3999101'
  },
  { name: 'Ajuste Programa Venta De Cartera Fobaproa', code: '3999119' },
  { name: 'Ajuste Estadistico', code: '3999127' },
  { name: 'Usuarios Menores Industria Manufacturera', code: '3999903' },
  { name: 'Cartera De Industria Manufacturera De Estados Anal', code: '3999911' },
  { name: 'Taller De Plomeria', code: '4221016' },
  {
    name: 'Instalaciones De Sistemas De Aire Acondicionado Y Calefaccion Y Otras Instalaciones Analogas',
    code: '4229010'
  },
  { name: 'Labrado Y Colocacion De Piedra', code: '4292017' },
  { name: 'OTRO', code: '0' }
];

export const mineria = [
  { name: 'Extraccion Y Beneficio De Carbon Mineral Y Grafito', code: '1100007' },
  { name: 'Extraccion De Petroleo Crudo Y Gas Natural', code: '1200005' },
  { name: 'Exploracion De Petroleo Por Compañias', code: '1211010' },
  { name: 'Usuarios Menores Petroleo', code: '1211903' },
  { name: 'Cartera Petrolera De Estados Analiticos', code: '1211911' },
  { name: 'Pidiregas Petroleo', code: '1221019' },
  { name: 'Extracción Y Beneficio De Minerales Metálicos', code: '1300003' },
  { name: 'Extraccion Y Beneficio De Mineral De Hierro', code: '1311018' },
  { name: 'Extraccion Y Beneficio De Oro Plata Y Otros Metales Preciosos', code: '1321017' },
  { name: 'Extraccion Y Beneficio De Mercurio Y Antimonio', code: '1322015' },
  { name: 'Usuarios Menores Mineria', code: '1329904' },
  { name: 'Cartera Minera De Estados Analiticos', code: '1329912' },
  { name: 'Extraccion Y Beneficio De Piedra', code: '1411016' },
  { name: 'Extraccion De Yeso', code: '1412014' },
  { name: 'Extraccion Y Beneficio De Arena Y Grava', code: '1413012' },
  { name: 'Extraccion Y Beneficio De Otros Materiales Para Construccion', code: '1419010' },
  { name: 'Extraccion Y Beneficio De Arcillas Refractarias', code: '1421015' },
  { name: 'Extraccion Y Beneficio De Barita Y Roca Fosforica', code: '1431014' },
  { name: 'Extraccion Y Beneficio De Fluorita', code: '1432012' },
  { name: 'Extraccion Y Beneficio De Silice', code: '1433010' },
  { name: 'Extraccion Y Beneficio De Otros Minerales No Metalicos Excepto Sal', code: '1439018' },
  { name: 'Explotación De Sal', code: '1500009' },
  { name: 'Explotacion De Sal Marina Y De Yacimientos', code: '1511014' },
  { name: 'Usuarios Menores Industria Productos De Minerales No Metalicos', code: '3354909' },
  { name: 'Cartera De Productos Minerales No Metalicos De Est', code: '3354917' },
  { name: 'OTRO', code: '0' }
];

export const politicamenteExpuestos = [{ name: 'OTRO', code: '0' }];

export const serviciosComunalesSocialesYPersonales = [
  { name: 'Desarrolladores De Vivienda', code: '4111051' },
  { name: 'Venta Y Distribucion De Despensas Familiares', code: '6132071' },
  { name: 'Distribucion De Otros Productos Quimicos Farmaceuticos', code: '6232011' },
  { name: 'OTRO', code: '0' }
];

export const serviciosFinancierosSegurosYBienesInmuebles = [
  { name: 'Cartera De Vivienda De Interes Social De Estados A', code: '4111910' },
  { name: 'Demolicion De Inmuebles', code: '4212015' },
  { name: 'Impermeabilizacion De Inmuebles', code: '4219011' },
  { name: 'Servicios De Pintado Y Tapizado De Inmuebles', code: '4291019' },
  { name: 'OTRO', code: '0' }
];

export const transporteYAlmacenamientoYComunicaciones = [
  { name: 'Construccion De Vias De Comunicacion', code: '4121018' },
  {
    name: 'Construccion De Pistas De Aterrizaje Ductos Y Otras Obras Vinculadas A Las Vias De Comunicacion',
    code: '4129012'
  },
  { name: 'OTRO', code: '0' }
];
