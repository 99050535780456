import { ConfirmationAccountWrapper } from './confirmationAccount.styles';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useState } from 'react';
import { FormTypes } from './confirmationAccount.types';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { Auth } from 'aws-amplify';
import { useAppThunkDispatch } from 'src/store/store';
import { confirmSignUpInvestor, incrementalId, signOutAll } from 'src/store/User/user.slice';
import axios from 'axios';
import getIp from 'src/functions/getIp';
import getCurrentDate from 'src/functions/getCurrentDate';
import addUserToGroup from 'src/functions/addUserToGroup';
import { InvestorUser } from 'src/store/User/user.interface';
import { ErrorMessage } from 'src/utilities/UILibrary/Forms/InputField/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { url } from 'src/environments';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

const resendCodeCopy =
  'Por favor, introduce tu correo electrónico, tipo de usuario, persona y contraseña para reenviar el código de confirmación.';

const resendCodeConfirmationCopy =
  'Hemos enviado un correo a tu dirección con el código de confirmación. Por favor, revisa tu correo y copia el código en el campo correspondiente.';

const defaultError = 'Ha ocurrido un error. Por favor, inténtalo de nuevo.';

const getCognitoUser = async (email: string) => {
  const key = process.env.REACT_APP_APIKEY_FINSPHERA;

  const data = await axios.post(
    `${url}/admin/cognito/get-data`,
    {
      email: email
    },
    {
      headers: { 'x-api-key': key }
    }
  );

  const response = JSON.parse(data.data.body);
  return response;
};

export const ConfirmationAccount = (): JSX.Element => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormTypes>();
  const [resendedCode, setResendedCode] = useState(false);
  const [codeConfirmed, setCodeConfirmed] = useState(false);
  const dispatch = useAppThunkDispatch();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [userData, setUserData] = useState<InvestorUser>({
    id: '',
    typeOfPerson: '',
    email: '',
    password: '',
    isInMarketing: '',
    geolocation: {},
    ip: {},
    date: '',
    code: '',
    showSuccessModal: false
  });
  const [typeOfUser, setTypeOfUser] = useState<'investor' | 'applicant'>('investor');

  const onSubmit: SubmitHandler<FormTypes> = async (data) => {
    setIsLoading(true);

    try {
      if (!resendedCode) {
        const [cognitoData, ip, date] = await Promise.all([
          getCognitoUser(data.email), // check if the user exists in cognito and if the password is correct
          getIp(),
          getCurrentDate()
        ]);
        const userSub = cognitoData.cognitoData.Username;
        const typeOfPerson = data.typeOfPerson;
        const email = data.email;
        const password = data.password;

        setTypeOfUser(data.typeOfUser);
        setUserData({
          id: userSub,
          typeOfPerson,
          email,
          password,
          isInMarketing: userData.isInMarketing,
          ip,
          date,
          code: '',
          showSuccessModal: false,
          geolocation: {}
        });
        await Auth.resendSignUp(email);
        setResendedCode(true);
        return setIsLoading(false);
      }

      if (resendedCode && typeOfUser) {
        setUserData({ ...userData, code: data.code });
        const response = await dispatch(
          confirmSignUpInvestor({ user: { ...userData, code: data.code } })
        );

        if ((response as { type: string; error: object })?.error) {
          if (response.type === 'confirmSignUpInvestor/rejected')
            throw new Error('El código no es válido');

          throw new Error(defaultError);
        }
        await addUserToGroup(userData.email, typeOfUser);
        await dispatch(incrementalId({ id: userData.id, typeOfUser }));
        await dispatch(signOutAll());
        setCodeConfirmed(true);
        setIsLoading(false);

        return setTimeout(() => {
          navigate('/iniciar-sesion');
        }, 5000);
      }
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(defaultError);
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <ComposedModal
        isOpen={error !== undefined}
        onClose={() => setError(undefined)}
        message={error}
        type="error"
      />
      <ComposedModal
        isOpen={codeConfirmed}
        onClose={() => setCodeConfirmed(false)}
        messageTitle="¡Bienvenido!"
        message="Serás redirigido automáticamente a la página de inicio de sesión en 5 segundos. Después de iniciar sesión, podrás comenzar tu proceso de incorporación. También puedes hacer clic en el botón de abajo para proceder inmediatamente."
        type="success"
        buttons={[
          {
            label: 'Empieza a invertir ahora',
            onClick: () => navigate('/iniciar-sesion')
          }
        ]}
      />

      <ConfirmationAccountWrapper>
        <Card className="card-container">
          <Card.Row>
            <h1>Confirma tu cuenta</h1>
          </Card.Row>
          <Card.Row>
            <p>{resendedCode ? resendCodeConfirmationCopy : resendCodeCopy}</p>
          </Card.Row>
          <Card.Row>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Correo electrónico inválido'
                }
              }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="Correo electrónico:"
                  placeholder="Ingresa tu correo electrónico"
                  onChangeInternalValue={onChange}
                  type="email"
                  errorMessages={error && error.message ? [error.message] : []}
                  {...rest}
                />
              )}
            />
          </Card.Row>
          <Card.Row>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{
                required: 'Este campo es requerido',
                minLength: {
                  value: 8,
                  message: 'La contraseña debe tener al menos 8 caracteres'
                }
              }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  label="Contraseña:"
                  placeholder="Ingresa tu contraseña"
                  onChangeInternalValue={onChange}
                  type="password"
                  errorMessages={error && error.message ? [error.message] : []}
                  {...rest}
                />
              )}
            />
          </Card.Row>
          <Card.Row>
            <div className="radio-container">
              <p>Selecciona tu tipo de usuario:</p>
              <div className="radio-group">
                <div>
                  <label htmlFor="applicant">Solicitante</label>
                  <input
                    type="radio"
                    id="applicant"
                    {...register('typeOfUser', { required: 'Este campo es requerido' })}
                    value="applicant"
                  />
                </div>
                <div>
                  <label htmlFor="investor">Inversionista</label>
                  <input
                    type="radio"
                    id="investor"
                    {...register('typeOfUser', { required: 'Este campo es requerido' })}
                    value="investor"
                  />
                </div>
              </div>
              <ErrorMessage
                errorMessages={
                  errors.typeOfPerson && errors.typeOfPerson?.message
                    ? [errors.typeOfPerson.message]
                    : []
                }
              />
            </div>
          </Card.Row>
          <Card.Row>
            <div className="radio-container">
              <p>Selecciona tu tipo de persona:</p>
              <div className="radio-group">
                <div>
                  <label htmlFor="moral">Persona Moral</label>
                  <input
                    type="radio"
                    id="moral"
                    {...register('typeOfPerson', { required: 'Este campo es requerido' })}
                    value="MORAL"
                  />
                </div>
                <div>
                  <label htmlFor="physical">Persona Física</label>
                  <input
                    type="radio"
                    id="physical"
                    {...register('typeOfPerson', { required: 'Este campo es requerido' })}
                    value="FISICA"
                  />
                </div>
              </div>
              <ErrorMessage
                errorMessages={
                  errors.typeOfUser && errors.typeOfUser?.message ? [errors.typeOfUser.message] : []
                }
              />
            </div>
          </Card.Row>
          {resendedCode ? (
            <Card.Row>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Este campo es requerido'
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <InputField
                    label="Código de confirmación:"
                    placeholder="Ingresa tu código de confirmación"
                    onChangeInternalValue={onChange}
                    type="number"
                    errorMessages={error && error.message ? [error.message] : []}
                    {...rest}
                  />
                )}
              />
            </Card.Row>
          ) : null}
          <Card.Row>
            <Button
              size="small"
              isFullWidth
              onClick={handleSubmit(onSubmit)}
              disabled={codeConfirmed}
              isLoading={isLoading}
            >
              {resendedCode ? 'Confirmar código' : 'Enviar código'}{' '}
            </Button>
          </Card.Row>
        </Card>
      </ConfirmationAccountWrapper>
    </>
  );
};
