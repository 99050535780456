import styled from 'styled-components/macro';

import {
  greenSp,
  brandBlack,
  grayLightSphera,
  grayDarkSphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const LoginContainer = styled.section`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const LoginInfo = styled.div`
  width: 50%;
  padding: 5% 0 5% 5%;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > h2 {
      color: ${brandBlack};
      line-height: 50px;
      margin-bottom: 5%;
      font-size: ${uIFontSize.subTitle};
      > span {
        color: ${greenSp};
        font-size: ${uIFontSize.subTitle};
        line-height: 50px;
        margin-right: 2%;
      }
    }
  }
  > p {
    color: ${brandBlack};
    font-size: ${uIFontSize.paragraph};
    width: 85%;
    margin-bottom: 5%;
  }

  > ul {
    margin-left: 5%;

    > li {
      font-size: ${uIFontSize.paragraph};
      color: ${brandBlack};
      margin-bottom: 1%;
    }
  }

  @media (min-width: 2561px) {
    > div {
      > h2 {
        font-size: 3rem;
        width: 100%;
        > span {
          font-size: 3rem;
        }
      }
    }
    > p {
      font-size: ${uIFontSize.xxLarge};
    }
    > ul {
      > li {
        font-size: ${uIFontSize.large};
      }
    }
  }
  @media (min-width: 1500px) and (max-width: 2000px) {
    > div {
      > h2 {
        font-size: 3rem;
        width: 100%;
        > span {
          font-size: 3rem;
        }
      }
    }
    > p {
      font-size: ${uIFontSize.xxLarge};
    }
    > ul {
      > li {
        font-size: ${uIFontSize.large};
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1500px) {
    width: 45%;
    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      > h2 {
        width: 70%;
        font-size: ${uIFontSize.xxxLarge};
        > span {
          margin-right: 2%;
          font-weight: bold;
          font-size: ${uIFontSize.xxxLarge};
        }
      }
      > p {
        font-weight: bold;
      }
    }

    > ul {
      margin: 5%;
    }
  }

  @media (max-width: 600px) {
    width: 100%;

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      > h2 {
        width: 100%;
        color: ${brandBlack};
        line-height: 50px;
        margin-bottom: 5%;
        font-weight: bold;
        > span {
          color: ${greenSp};
          line-height: 50px;
          margin-right: 2%;
        }
      }
    }
    > p {
      width: 95%;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > p {
    font-size: ${uIFontSize.paragraph};
    color: ${grayDarkSphera};
    width: 100%;
    text-align: center;
  }
  > h3 {
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
  }
  > button {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${greenSp};
    text-decoration: none;
    background-color: transparent;
    border: none;
    width: 90%;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > button {
    margin: 5% 0;
  }
  > a:nth-child(1) {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${greenSp};
    text-decoration: none;
    background-color: transparent;
    border: none;
    width: 90%;
    text-align: center;
  }
  > a:nth-child(3),
  a:nth-child(4) {
    font-size: ${uIFontSize.paragraph};
    color: ${grayDarkSphera};
    font-weight: bold;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
    > p {
      text-align: center;
      font-size: ${uIFontSize.paragraph};
      color: ${grayDarkSphera};
    }
    > a {
      font-size: ${uIFontSize.paragraph};
      color: ${greenSp};
      text-decoration: none;
      text-align: center;
      margin-bottom: 5%;
      font-weight: bold;
      background-color: transparent;
      cursor: pointer;
      border: none;
    }
  }

  @media (min-width: 1900px) and (max-width: 2550px) {
    > a:nth-child(1),
    a:nth-child(3),
    a:nth-child(4) {
      font-size: ${uIFontSize.large};
    }
    > div {
      > a {
        font-size: ${uIFontSize.large};
      }
      > p {
        font-size: ${uIFontSize.large};
      }
    }
  }
  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.largeDesktop}) {
    > a:nth-child(1),
    a:nth-child(3),
    a:nth-child(4) {
      font-size: ${uIFontSize.medium};
    }
    > div {
      > a {
        font-size: ${uIFontSize.small};
      }
      > p {
        font-size: ${uIFontSize.small};
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    margin-top: 3%;
    > div {
      width: 80%;
      margin: 5% 10%;
    }
  }
`;

export const LoginForm = styled.div`
  width: 50%;
  background-image: url('https://finsphera-webapp-resources.s3.amazonaws.com/ImageLoginForm.png');
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5%;
  > div {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
    padding: 5%;
    border-radius: 5px;
    background-color: ${whiteSphera};
    > h3 {
      color: ${brandBlack};
      font-size: ${uIFontSize.subTitle};
      font-weight: normal;
      text-align: center;
      margin-bottom: 5%;
    }
    > p {
      font-size: ${uIFontSize.paragraph};
    }
    > input {
      font-size: ${uIFontSize.paragraph};
      margin: 2.5% 0;
      padding: 1% 0 1% 2.5%;
      border: none;
      border-bottom: 1px solid ${brandBlack};
      background-color: transparent;
      outline: none;
      &::placeholder {
        color: ${grayLightSphera};
      }
    }
  }

  @media (min-width: 1900px) and (max-width: 2550px) {
    > div {
      width: 60%;
      margin: 0 20%;
      > h3 {
        font-size: ${uIFontSize.xxxLarge};
      }
      > p {
        font-size: ${uIFontSize.medium};
      }
      > input {
        font-size: ${uIFontSize.xLarge};
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.largeDesktop}) {
    padding-top: 3%;
    > div {
      width: 80%;
      margin: 0 10%;
      > h3 {
        font-size: ${uIFontSize.xLarge};
      }
      > input {
        font-size: ${uIFontSize.medium};
      }
      > button:nth-child(5) {
        line-height: 40px;
        height: 40px;
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-top: 3%;
    padding-top: 3%;
    > div {
      width: 80%;
      margin: 5% 10%;
    }
  }
`;
