import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  InvestSimulatorContainer,
  Investmentexceeded,
  SimulatorInput,
  SimulatorResult
} from './investSimulator.styles';
import { useSelector } from 'react-redux';
import InvestConfirmation from 'src/components/shared/modals/investDetails/investConfirm';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import ModalInfoVirtualAccount from 'src/components/shared/modals/infoVirtualAccount/infoVirtualAccount';

export default function InvestSimulator() {
  const location = useLocation();
  const item = location.state?.detail || null;
  const [adviceValidation, setAdviceValidation] = useState(false);
  const [displayInvestment, setDisplayInvestment] = useState('');
  const [investment, setInvestment] = useState(0);
  const [profit, setProfit] = useState(0);
  const [interesInversionistaPesos, setInteresInversionistaPesos] = useState(0);
  const [retencionImpuestoInversionistaPesos, setRetencionImpuestoInversionistaPesos] = useState(0);
  const [infoVirtualAccount, setInfoVirtualAccount] = useState(false);
  const personalInformation = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor
  );
  const availableAmount = personalInformation?.STP?.recursosDisponibles;
  //This amount field comes from the STP operations balance
  const amount = availableAmount;
  function formatCurrency(availableAmount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(availableAmount);
  }

  const interestPercentage = displayInvestment / item?.montoSolicitado;
  const handleInputChange = (e) => {
    setInvestment(e.target.value);
  };
  const handleDeposit = () => {
    setInfoVirtualAccount(true);
  };
  const isInvestmentExceedsBalance =
    investment > amount || item?.montoSolicitado - item?.collectedAmount < investment;
  const nullInvestment = displayInvestment === 0;

  const calculateProfit = (event) => {
    event.preventDefault();
    if (isInvestmentExceedsBalance) {
      return;
    }
    const newInteresInversionistaPesos =
      Number(item?.tasaCreditoPorcentaje / 360) * item?.plazoCredito * investment;
    setInteresInversionistaPesos(newInteresInversionistaPesos);
    const newRetencionImpuestoInversionistaPesos = newInteresInversionistaPesos * 0.02;
    setRetencionImpuestoInversionistaPesos(newRetencionImpuestoInversionistaPesos);
    const result = newInteresInversionistaPesos + Number(investment);
    setProfit(result);
    setDisplayInvestment(investment);
    setInvestment('');
  };
  const openModal = () => {
    setAdviceValidation(true);
  };
  const closeModal = () => {
    setAdviceValidation(false);
  };
  return (
    <InvestSimulatorContainer>
      <InvestConfirmation
        showModalAdviceValidation={adviceValidation}
        closeModal={closeModal}
        displayInvestment={displayInvestment}
        item={item}
        profit={profit}
        amount={amount}
        interesInversionistaPesos={interesInversionistaPesos}
        interestPercentage={interestPercentage}
        retencionImpuestoInversionistaPesos={retencionImpuestoInversionistaPesos}
      />
      <ModalInfoVirtualAccount
        showModalInfoVirtualAccount={infoVirtualAccount}
        setModal={setInfoVirtualAccount}
        amount={amount}
      />
      <SimulatorInput>
        <h3>Simula tu inversión</h3>
        <div>
          <div>
            <h4>Tasa de interés en porcentaje</h4>
            <p>{Number(item?.tasaCreditoPorcentaje * 100).toFixed(2)} %</p>
          </div>
          <hr />
          <div>
            <h4>Ganancia proyectada</h4>
            <p>{formatCurrency(profit)}</p>
          </div>
          <div>
            <h4>Porcentaje inversión del monto total</h4>
            <p>{(interestPercentage * 100).toFixed(2)} %</p>
          </div>
          <div>
            <h4>Monto a invertir</h4>
            <p>{formatCurrency(displayInvestment)}</p>
          </div>
          <div>
            <h4>Saldo</h4>
            <p>{formatCurrency(amount)}</p>
          </div>
        </div>
        <div>
          <input type="number" value={investment} onChange={handleInputChange} />
        </div>
        {investment > amount ? (
          <Investmentexceeded>
            <p>
              Tienes saldo insuficiente para aportar ese monto de financiamiento. ¡Fondea tu cuenta
              ahora!
            </p>
            <Button size="small" onClick={handleDeposit}>
              Fondear
            </Button>
          </Investmentexceeded>
        ) : item?.montoSolicitado - item?.collectedAmount < investment ? (
          <Investmentexceeded>
            <p>El monto de inversión excede el monto solicitado en la campaña</p>
          </Investmentexceeded>
        ) : (
          <Investmentexceeded>
            <Button size="small" disabled={isInvestmentExceedsBalance} onClick={calculateProfit}>
              Simular tu inversión
            </Button>
          </Investmentexceeded>
        )}
      </SimulatorInput>
      <SimulatorResult>
        <h3>Simulación de inversión</h3>
        <div>
          <div>
            <h4>Porcentaje de Retención de (ISR)</h4>
            <p>2 %</p>
          </div>
          <hr />
          <div>
            <h4>Monto de Retención de (ISR)</h4>
            <p>{formatCurrency(retencionImpuestoInversionistaPesos)}</p>
          </div>
          <hr />
          <div>
            <h4>Interés en pesos</h4>
            <p>{formatCurrency(interesInversionistaPesos)}</p>
          </div>
        </div>
        <Button
          size="small"
          disabled={isInvestmentExceedsBalance || nullInvestment}
          onClick={openModal}
        >
          Confirmar
        </Button>
      </SimulatorResult>
    </InvestSimulatorContainer>
  );
}
