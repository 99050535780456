import styled from 'styled-components';
import { FormActionButtonsProps } from './formActionButtons.types';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const FormActionButtonsContainer = styled.div<FormActionButtonsProps>`
  ${spaceFlowContainer()}
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .body {
    display: flex;
    justify-content: space-around;

    @media (min-width: ${breakpoints.desktop}) {
      justify-content: center;
      gap: 10rem;
    }
  }

  .footer {
    text-align: center;
    display: flex;
    justify-content: center;
  }
`;
