import { createGlobalStyle } from 'styled-components/macro';
import 'src/utilities/fonts/fonts.css';
import { ComponentClasses } from './componentClasses';
import { uIFontSize } from './variables/typography';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'NunitoRegular';
  };

  h1, h2, h3, h4, h5, h6 {
    text-wrap: balance;
  };

  h1 {
    font-size: ${uIFontSize.title};
  }

  h2 {
    font-size: ${uIFontSize.subTitle};
  };

  h3 {
    font-size: ${uIFontSize.h3};
  }

  a {
    text-decoration: none;
  };

  p {
    text-wrap: pretty;
    font-size: ${uIFontSize.paragraph};
  }

  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }

  ${ComponentClasses};
`;
