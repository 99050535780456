import styled from 'styled-components/macro';

import {
  blueSphera,
  brandBlack,
  brandYellowgreen,
  grayLightSphera,
  grayPlaceholder,
  graySphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalDeleteRegisterCLABE = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalDeleteCLABE ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1;
  animation: ${(props) => (props.showModalDeleteCLABE ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 50%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;
    > div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      padding-bottom: 1.5%;
      > h3 {
        font-size: ${uIFontSize.h3};
        color: ${graySphera};
        width: 60%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      width: 80%;
    }

    @media (max-width: 600px) {
      > div {
        width: 90%;
        padding-top: 5%;
        padding-bottom: 5%;

        > h3 {
          font-size: ${uIFontSize.h3};
        }
      }
    }
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  max-width: 800px;
  border-radius: 5px;
  background-color: ${whiteSphera};
  padding: 3%;
`;

export const Figure = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  > div {
    width: 80%;
  }
  > figure {
    width: 25px;
    height: 25px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const RegisterCLABEAdvice = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  margin: 1% 0;
  > h2 {
    font-size: ${uIFontSize.subTitle};
    color: ${graySphera};
    width: 90%;
  }
  > figure {
    width: 40px;
    height: 40px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > p {
    font-size: ${uIFontSize.paragraph};
    color: ${graySphera};
  }

  > a {
    display: block;
    color: ${whiteSphera};
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
    text-align: center;
    text-decoration: none;
    line-height: 40px;
    width: 30%;
    height: 40px;
    margin: 5% auto 0 auto;
    border: none;
    border-radius: 5px;
    background-color: ${blueSphera};
    cursor: pointer;
  }

  @media (max-width: 600px) {
    margin: 5% auto 0 auto;

    > div:nth-child(1) {
      width: 100%;

      > figure {
        margin-left: 5%;
      }

      > p {
        font-size: ${uIFontSize.paragraph};
        color: ${graySphera};
      }
    }

    > a {
      font-size: ${uIFontSize.paragraph};
      width: 100%;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const CampaignCode = styled.div`
  width: 90%;
  margin: 2.5% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  > p {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${greenSp};
    margin-bottom: 5%;
  }
  > input {
    font-size: ${uIFontSize.paragraph};
    color: ${grayPlaceholder};
    width: 80%;
    height: 35px;
    margin-bottom: 2.5%;
    padding-left: 2.5%;
    border: 1px solid ${grayLightSphera};
    border-radius: 5px;
  }
  > select {
    font-size: ${uIFontSize.paragraph};
    color: ${grayPlaceholder};
    width: 80%;
    height: 35px;
    margin-bottom: 2.5%;
    padding-left: 2.5%;
    border: 1px solid ${grayLightSphera};
    border-radius: 5px;
  }

  > h3 {
    font-size: ${uIFontSize.h3};
    color: ${graySphera};
    margin-bottom: 2.5%;
    text-align: center;
  }

  > h2 {
    font-size: ${uIFontSize.subTitle};
    color: ${greenSp};
    margin-bottom: 2.5%;
    text-align: center;
    font-weight: bold;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    > h3 {
      font-size: ${uIFontSize.h3};
      color: ${graySphera};
      margin-bottom: 2.5%;
    }
    > input {
      font-size: ${uIFontSize.paragraph};
      color: ${grayPlaceholder};
      width: 10%;
      height: 30px;
      margin-bottom: 2.5%;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }
`;

export const CloseButton = styled.button`
  color: ${whiteSphera};
  font-size: ${uIFontSize.paragraph};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandBlack};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;

export const ConfirmButton = styled.button`
  color: ${brandBlack};
  font-size: ${uIFontSize.paragraph};
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  width: 40%;
  margin: 0;
  padding: 1%;
  border: none;
  border-radius: 5px;
  background-color: ${brandYellowgreen};
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
`;
