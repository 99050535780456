import styled from 'styled-components/macro';

import { whiteSphera, blackSphera, greenSp } from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

type MoralErrorWrapperProps = {
  showModal: boolean;
};

export const ModalErrorWrapper = styled.section<MoralErrorWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${({ showModal }) => (showModal ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  animation: ${(showModal) => (showModal ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;
  z-index: 1001;

  > div {
    width: 50%;
    padding: 2.5%;
    background-color: ${whiteSphera};
    border-radius: 5px;

    > figure:nth-child(1) {
      width: 25px;
      height: 25px;
      margin-left: 96%;
      cursor: pointer;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(2) {
      width: 100px;
      height: 100px;
      margin: 0 auto 2.5% auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > figure:nth-child(1) {
        > img {
        }
      }

      > figure:nth-child(2) {
        > img {
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding: 5%;

      > figure:nth-child(1) {
        margin-left: 93%;

        > img {
        }
      }

      > figure:nth-child(2) {
        width: 80px;
        height: 80px;
        margin: 0 auto 5% auto;

        > img {
        }
      }
    }
  }
`;

export const Message = styled.div`
  > h2 {
    color: ${blackSphera};
    font-size: 2vw;
    text-align: center;
    margin: 2.5% auto 2.5% auto;

    > span {
      color: ${greenSp};
    }
  }

  > p {
    color: ${blackSphera};
    font-size: 1.5vw;
    width: 90%;
    margin: 2.5% auto 2.5% auto;
    text-align: center;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    > a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5vw;
      font-weight: bold;
      text-decoration: none;
      color: ${blackSphera};
      width: 45%;
      padding: 1.5% 0;
      border-radius: 5px;
      border: 1px solid ${blackSphera};
    }

    > button {
      color: ${whiteSphera};
      font-weight: bold;
      font-size: 1.5vw;
      padding: 1.5% 0;
      width: 45%;
      border: 1px solid ${blackSphera};
      border-radius: 5px;
      background-color: ${blackSphera};
      cursor: pointer;
    }
    > p {
      color: ${greenSp};
      font-size: 1.5vw;
      width: 100%;
      text-align: center;
      font-weight: bold;
      margin: 2% 0;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      > span {
      }
    }

    > p {
    }

    > div {
      > a {
      }

      > button {
      }
    }
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: 5vw;

      > span {
      }
    }

    > p {
      font-size: 4.5vw;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      > a {
        font-size: 4.5vw;
        width: 90%;
      }

      > button {
        font-size: 4.5vw;
        width: 90%;
      }
      > p {
        font-size: 3vw;
      }
    }
  }
`;
