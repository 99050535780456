import styled from 'styled-components/macro';

import {
  blueSphera,
  graySphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalInfoVirtualAccount = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalInfoVirtualAccount ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalInfoVirtualAccount ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 40%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid #b7bcc7;

      > h3 {
        font-size: 1.5vw;
        color: ${graySphera};
        width: 90%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > div:nth-child(1) {
        > h3 {
        }

        > figure {
          > img {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;

      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;

        > h3 {
          font-size: 4.5vw;
          width: 80%;
        }

        > figure {
          > img {
          }
        }
      }
    }
  }
`;

export const DetailInfoVirtualAccount = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2.5%;
  > div {
    width: 100%;
    > h3 {
      font-size: 1.5vw;
      color: ${greenSp};
      text-align: center;
    }
    > p {
      font-size: 1.5vw;
      color: ${graySphera};
      text-align: center;
    }
  }
  > p {
    color: ${graySphera};
    font-size: 1.2vw;
    margin: 3% 0;
    padding: 0 5%;
  }
  > ul {
    list-style: none;
    margin: 5%;
    > li {
      color: ${graySphera};
      font-size: 1.2vw;
      margin-bottom: 1%;
    }
  }

  > button {
    color: ${whiteSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    width: 30%;
    height: 50px;
    margin: auto;
    border: none;
    border-radius: 5px;
    background-color: ${greenSp};
    cursor: pointer;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p:nth-child(1) {
    }

    > div:nth-child(2) {
      > p {
      }

      > ul {
        > li {
        }
      }
    }

    > a:nth-child(3) {
    }
  }

  @media (max-width: 600px) {
    padding-top: 5%;
    padding-left: 0%;

    > p:nth-child(1) {
      font-size: 4.2vw;
      padding-left: 5%;
    }

    > div:nth-child(2) {
      > p {
        font-size: 4.2vw;
        margin-bottom: 5%;
      }

      > ul {
        > li {
          font-size: 4vw;
        }
      }
    }

    > a:nth-child(3) {
      font-size: 4.2vw;
      width: 95%;
    }
  }
`;
