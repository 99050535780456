import styled from 'styled-components/macro';

import {
  brandBlack,
  whiteSphera,
  neutralBlue,
  neutralBlueGray,
  neutralLight,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';

export const AddressContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > button {
    background-color: ${neutralBlue};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 90%;
    color: ${brandBlack};
    font-size: 1vw;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const AdressSpecific = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3% 2.5%;
  width: 100%;
  > div {
    width: 25%;
    > h3 {
      color: ${neutralBlueGray};
      font-size: 1.5vw;
    }
    > p {
      color: ${neutralBlueGray};
      font-size: 1.3vw;
      padding: 1% 0;
      background-color: ${neutralLight};
    }
    > input {
      border-radius: 5px;
      border-style: none;
      width: 80%;
      padding: 1% 0;
      border: 1px solid ${neutralBlue};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  margin-bottom: 3%;
  > button {
    background-color: ${greenSp};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 5%;
    color: ${brandBlack};
    font-size: 1vw;
  }
`;
