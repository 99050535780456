import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import styled from 'styled-components';
import { CountrySelectorProps } from './countrySelector.interface';
import { error, warning } from '../../../UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const SelectorContainer = styled.div<CountrySelectorProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 9.375rem;
  gap: 0.3rem;

  cursor: pointer;

  label {
    white-space: ${({ wrapLabel }) => (wrapLabel ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
  }

  select {
    width: 100%;
    height: 2rem;
    border: 1px solid black;
    border-radius: 5px;
    z-index: 10;
    font-size: ${uIFontSize.paragraph};
    background-color: transparent;
    padding: 0 0.2rem;
  }

  select:invalid {
    color: gray;
  }

  option {
    padding: 5px;
  }

  .is-error {
    border-color: ${({ errortype }) => (errortype === 'error' ? error : warning)};
  }

  @media (min-width: ${breakpoints.tablet}) {
    select {
      height: 2.5rem;
    }
  }
`;
