import { useSelector } from 'react-redux';
import { Contracts, StyledTable } from './contractInfo.styles';
import axios from 'axios';
import { url } from 'src/environments';
import { useEffect, useState } from 'react';
import ContractsSign from 'src/components/shared/modals/contractsSign/contractsSign';
import { Button } from 'src/utilities/UILibrary';

export default function ContractsInfo({ personalInformation }) {
  const [contracts, setContracts] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const statusRisk = personalInformation?.data?.getInvestor?.isRisksSigned;
  const statusInvestor = personalInformation?.data?.getInvestor?.isContractSigned;
  const [showModal, setShowModal] = useState(false);
  const PldApproved = personalInformation?.data?.getInvestor?.isPLDApproved;

  const fetchUrl = async () => {
    try {
      const responses = await Promise.all([
        axios.post(`${url}/admin/documents/get-presignedurl`, {
          type: 'risk',
          Key: `${user.id}/contracts/`
        }),
        axios.post(`${url}/admin/documents/get-presignedurl`, {
          type: 'invest',
          Key: `${user.id}/contracts/`
        })
      ]);
      const contractsData = responses.map((response, index) => {
        const preSignedURL = JSON.parse(response.data.body);
        return {
          url: preSignedURL,
          name: index === 0 ? 'Contrato de riesgos' : 'Contrato de inversión',
          isSigned: index === 0 ? statusRisk : statusInvestor
        };
      });
      setContracts(contractsData);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []);

  return (
    <Contracts>
      <ContractsSign showModal={showModal} setShowModal={setShowModal} />
      <StyledTable>
        <table>
          <thead>
            <tr>
              <th>Contrato</th>
              <th>Documento</th>
              <th>Firmado</th>
              <th>Firmar</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract, index) => (
              <tr key={index}>
                <td>{contract.name}</td>
                <td>
                  <a href={contract.url} target="_blank" rel="noopener noreferrer">
                    Ver documento
                  </a>
                </td>
                <td>{contract.isSigned ? 'Sí' : 'No'}</td>
                <td>
                  {' '}
                  {!contract.isSigned && (
                    <Button
                      disabled={PldApproved === '0'}
                      size="small"
                      onClick={() => setShowModal(true)}
                    >
                      Firmar
                    </Button>
                  )}
                  {contract.isSigned && '-'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </StyledTable>
    </Contracts>
  );
}
