import { forwardRef, Fragment } from 'react';
import { OptionToggleProps } from './optionToggle.types';
import { OptionToggleContainer } from './optionToggle.styles';

export const OptionToggle = forwardRef<HTMLDivElement, OptionToggleProps>((props, ref) => {
  const { options, ...rest } = props;

  return (
    <OptionToggleContainer ref={ref} {...rest}>
      {options.map((option, index) => (
        <Fragment key={index}>
          {index !== 0 && <span />}
          <p>{option}</p>
        </Fragment>
      ))}
    </OptionToggleContainer>
  );
});

OptionToggle.displayName = 'OptionToggle';
