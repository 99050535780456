import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import {
  ButtonContainer,
  GeneralInfoContainer,
  InfoSection,
  ProfileImage,
  StyledTable
} from './generalInfo.styles';
import { InputUpload } from 'src/utilities/UILibrary/Forms/InputUpload/InputUpload';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { url } from 'src/environments';
import { setAppError } from 'src/store/app/app.slice';

const GeneralInfo = ({ personalInformation }) => {
  const [editable, setEditable] = useState(false);
  const [webSite, setWebSite] = useState('');
  const [phoneLand, setPhoneLand] = useState('');
  const user = useSelector((state) => state.user.currentUser);
  const [base64String, setBase64String] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [localLoading, setLocalLoading] = useState(false);
  // const [legalName, setLegalName] = useState('');
  const [reset, setReset] = useState(false);
  const generalInfo = personalInformation?.data?.getApplicant;

  const handleUpdate = async () => {
    try {
      if (phoneLand) {
        const inputPhone = {
          type: 'applicant_profile_phone',
          input: {
            id: user.id,
            personalInformation: {
              phone: {
                land: phoneLand
              }
            }
          }
        };
        await axios.post(`${url}/admin/dynamo/update`, inputPhone);
      }
      if (webSite) {
        const inputWeb = {
          type: 'applicant_profile_webSite',
          input: {
            id: user.id,
            companyInformation: {
              webSite: `https://${webSite}`
            }
          }
        };
        await axios.post(`${url}/admin/dynamo/update`, inputWeb);
      }
      setEditable(false);
    } catch (error) {
      console.error('Error updating profile information', error);
    }
  };

  const handleFile = async (e) => {
    const file = e.target.files[0];
    try {
      const base64String = await fileToBase64(file);
      setBase64String(base64String);
      setReset(false);
    } catch (error) {
      console.error('Error al convertir el archivo a Base64:', error);
    }
  };
  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(',')[1];
        resolve(base64String);
        setFileSelected(true);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const profileBody = {
    id: user.id,
    base64File: base64String,
    folder: 'media',
    fileName: 'profileImage.jpg'
  };

  const sendDataToServer = async () => {
    try {
      setLocalLoading(true);
      const response = await axios.post(`${url}/admin/documents/upload-single`, profileBody);
      const responseParsed = JSON.parse(response.data.body);
      if (responseParsed.result === true) {
        setUploadSuccess(true);
        setTimeout(() => {
          setReset(true);
        }, 3000);
      }
    } catch (error) {
      setAppError();
      setLocalLoading(false);
    }
    setLocalLoading(false);
  };

  useEffect(() => {
    if (reset) {
      setFileSelected(false);
      setUploadSuccess(false);
      setShowButton(true);
    }
  }, [reset]);

  const handleEdit = () => {
    setEditable(true);
  };
  const cancelEdit = () => {
    setEditable(false);
  };
  return (
    <GeneralInfoContainer>
      {editable ? (
        <ButtonContainer>
          <button onClick={handleUpdate}>Aceptar</button>
          <button onClick={cancelEdit}>Cancelar</button>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <button onClick={() => setEditable(true)}>Editar</button>
        </ButtonContainer>
      )}
      <InfoSection>
        <div>
          <h3>Nombre comercial de la PyME:</h3>
          <p>
            {generalInfo?.companyInformation?.companyName?.legalName
              ? generalInfo?.companyInformation?.companyName?.legalName
              : '-'}
          </p>
        </div>
        <div>
          <h3>Correo electrónico de la empresa:</h3>
          <p>
            {generalInfo?.companyInformation?.email ? generalInfo.companyInformation.email : '-'}
          </p>
        </div>
        <div>
          <h3>RFC</h3>
          <p> {generalInfo?.companyInformation?.RFC ? generalInfo.companyInformation.RFC : '-'}</p>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Nombre representante legal:</h3>
          <p>
            {generalInfo?.personalInformation?.fullName
              ? generalInfo.personalInformation.fullName
              : '-'}
          </p>
        </div>
        <div>
          <h3>Teléfono compañía:</h3>
          <p>
            {generalInfo?.companyInformation?.phone.land
              ? generalInfo.companyInformation.phone.land
              : '-'}
          </p>
        </div>
        {editable ? (
          <div>
            <h3>Sitio web:</h3>
            <input
              type="text"
              name="webSite"
              value={webSite}
              onChange={(event) => setWebSite(event.target.value)}
            />
          </div>
        ) : (
          <div>
            <h3>Sitio web:</h3>
            <p>
              {generalInfo?.companyInformation?.webSite
                ? generalInfo?.companyInformation?.webSite
                : '-'}
            </p>
          </div>
        )}
      </InfoSection>
      <InfoSection>
        <div>
          <h3>Id del usuario:</h3>
          <p>{user.id}</p>
        </div>
      </InfoSection>
      <ProfileImage>
        <h3>Subir logo de empresa</h3>
        <InputUpload
          id="profileImage"
          name="profileImage"
          onChange={handleFile}
          value={reset ? '' : undefined}
        >
          Subir archivo
        </InputUpload>
        {fileSelected && showButton && (
          <Button isLoading={localLoading} onClick={sendDataToServer}>
            Subir archivo
          </Button>
        )}
        {uploadSuccess && <p>Carga exitosa</p>}
      </ProfileImage>
    </GeneralInfoContainer>
  );
};

export default GeneralInfo;
