import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreditInformationContainer,
  Banner,
  FormContainer,
  FormData,
  FormDataTitle,
  FormDataGeneral,
  SectionFields,
  StyledButton
} from './createCampaign.styles';
import { url } from 'src/environments';
import ModalAllDataRequired from 'src/components/shared/modals/allDataRequired/allDataRequired';
import ModalErrorAmount from 'src/components/shared/modals/errorAmount/errorAmount';
import { setCalculatedCampaignData } from 'src/store/Campaigns/campaign.slice';
import getCurrentDate from 'src/functions/getCurrentDate';
import { getCurrentDatePlus } from 'src/functions/getCurrentDate';
import { getApplicantFiles, getApplicantPLD, getCreditInfo } from 'src/graphql/customized_queries';
import generateUUID from 'src/functions/uuid';

export default function CreateCampaign() {
  const [data, setData] = useState({
    campaignName: '',
    requestedAmount: '',
    loanDestination: '',
    termLoan: '',
    paymentSource: ''
  });
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const [errorAmount, setErrorAmount] = useState(false);
  const [tiie, setTiie] = useState(null);
  const [allDataRequired, setAllDataRequired] = useState(false);
  const [simafValid, setSimafValid] = useState(null);
  const [filesValidator, setFilesValidator] = useState(null);
  const [tasaInteresAdicionalPorcentaje, setTasaInteresAdicionalPorcentaje] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  //Get the total of montoSolicitado in campaigns by applicantId
  const campaigns = personalInformation?.data?.getApplicant?.campaigns?.items;
  let activeCampaignsMontoSolicitadoSum = 0;
  if (campaigns) {
    const activeCampaignsMontoSolicitado = campaigns
      .filter((campaign) => campaign.isActive === '1' && campaign.isExpired === '1')
      .map((campaign) => campaign.montoSolicitado);
    activeCampaignsMontoSolicitadoSum = activeCampaignsMontoSolicitado.reduce(
      (acc, curr) => acc + curr,
      0
    );
  }
  //Validates if the credit limit is exceeded
  const totalRequested = parseInt(data.requestedAmount, 10) + activeCampaignsMontoSolicitadoSum;
  const exceedsLimit = totalRequested >= 12801218;
  //JALAR TIIE
  useEffect(() => {
    axios
      .get(`${url}/admin/get-tiie`)
      .then((s) => {
        const parsedData = JSON.parse(s.data.body);
        setTiie(Number(parsedData.TIIE));
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getCreditInfo,
          variables: { applicantId: user.id }
        });
        setTasaInteresAdicionalPorcentaje(Number(data.getCreditAnalysis.interestRateApplicant));
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: filesData } = await API.graphql({
          query: getApplicantFiles,
          variables: { id: user.id }
        });
        const files = filesData?.getFile?.areBusinessDocumentsUpload;
        setFilesValidator(files);
      } catch (error) {
        console.error(error);
      }
      try {
        const { data } = await API.graphql({
          query: getApplicantPLD,
          variables: { id: user.id }
        });
        const simaf = data.getApplicant.isPLDApproved;
        setSimafValid(simaf);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  //Funciones para validar los inputs para crear campañas y habilitar botón de crear
  const handleData = (e) => {
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const handleNumber = (e) => {
    let onlyNumber = e.target.value.replace(/[^0-9]/gi, '');
    setData((prevData) => ({
      ...prevData,
      [e.target.name]: onlyNumber
    }));
  };
  const validateFields = () => {
    return (
      data.campaignName &&
      data.requestedAmount &&
      data.termLoan &&
      data.loanDestination &&
      data.paymentSource &&
      filesValidator === true &&
      simafValid === '1'
    );
  };
  const calculateCampaignData = () => {
    const campaignInput = {
      id: generateUUID(),
      applicantId: user.id,
      montoSolicitado: data.requestedAmount,
      nombreCampania: data.campaignName,
      plazoCredito: data.termLoan,
      destinoCredito: data.loanDestination,
      fuentePago: data.paymentSource,
      createdAt: getCurrentDate(),
      updatedAt: getCurrentDate(),
      dateStartCampaign: getCurrentDate(),
      dateEndCampaign: getCurrentDatePlus(getCurrentDate(), 7),
      tasaInteresAdicionalPorcentaje: tasaInteresAdicionalPorcentaje,
      tasaCreditoPorcentaje: tiie + tasaInteresAdicionalPorcentaje,
      interesCreditoPesos:
        ((tiie + tasaInteresAdicionalPorcentaje) / 360) * data.termLoan * data.requestedAmount,
      IVAinteresCreditoPesos:
        ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
        data.termLoan *
        data.requestedAmount *
        0.16,
      interesesCreditoPesosConIVA:
        ((tiie + tasaInteresAdicionalPorcentaje) / 360) * data.termLoan * data.requestedAmount +
        ((tiie + tasaInteresAdicionalPorcentaje) / 360) *
          data.termLoan *
          data.requestedAmount *
          0.16,
      comisionCreditoPesos: (0.18 / 360) * data.termLoan * data.requestedAmount,
      IVAcomisionCreditoPesos: (0.18 / 360) * data.termLoan * data.requestedAmount * 0.16,
      comisionCreditoPesosConIVA:
        (0.18 / 360) * data.termLoan * data.requestedAmount +
        (0.18 / 360) * data.termLoan * data.requestedAmount * 0.16,
      inversionFinspheraCampaniaPesos: 0.01 * data.requestedAmount,
      tasaInteresMoratorioPorcentaje: (tiie + tasaInteresAdicionalPorcentaje) * 1.5
    };
    const formatToTwoDecimals = (num) => +num.toFixed(2);
    return {
      ...campaignInput,
      interesCreditoPesos: formatToTwoDecimals(campaignInput.interesCreditoPesos),
      IVAinteresCreditoPesos: formatToTwoDecimals(campaignInput.IVAinteresCreditoPesos),
      interesesCreditoPesosConIVA: formatToTwoDecimals(campaignInput.interesesCreditoPesosConIVA),
      comisionCreditoPesos: formatToTwoDecimals(campaignInput.comisionCreditoPesos),
      IVAcomisionCreditoPesos: formatToTwoDecimals(campaignInput.IVAcomisionCreditoPesos),
      comisionCreditoPesosConIVA: formatToTwoDecimals(campaignInput.comisionCreditoPesosConIVA),
      inversionFinspheraCampaniaPesos: formatToTwoDecimals(
        campaignInput.inversionFinspheraCampaniaPesos
      ),
      tasaInteresMoratorioPorcentaje: formatToTwoDecimals(
        campaignInput.tasaInteresMoratorioPorcentaje
      )
    };
  };
  const handleCalcs = () => {
    const campaignData = calculateCampaignData();
    dispatch(setCalculatedCampaignData(campaignData));
    navigate(`/solicitante/dashboard/cargar-facturas`);
  };
  return (
    <CreditInformationContainer>
      <ModalErrorAmount showModalErrorAmount={errorAmount} setErrorAmount={setErrorAmount} />
      <ModalAllDataRequired
        showModalAllDataRequired={allDataRequired}
        setAllDataRequired={setAllDataRequired}
      />
      <Banner>
        <div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_companyInformation_banner.png`}
              alt="icono informacion personal"
            />
          </figure>
          <h2>Crear campaña</h2>
        </div>
      </Banner>
      <FormContainer>
        {filesValidator !== null && filesValidator === false ? (
          <div>
            <h4>
              Recuerda que para crear campañas, debes subir tu documentación. Puedes hacerlo desde
              <Link to="/solicitante/dashboard/mi-perfil"> aquí</Link>
            </h4>
          </div>
        ) : null}
        <></>
        {simafValid !== null &&
          (simafValid === '0' ? (
            <div>
              <h4>
                Aún no puedes crear campañas ya que tu cuenta se encuentra en proceso de validación.
                <br />
                Si tienes comentarios o dudas escríbenos a:{' '}
                <Link to="mailto:atencionaclientes@finsphera.com">
                  atencionaclientes@finsphera.com
                </Link>
              </h4>
            </div>
          ) : (
            <></>
          ))}
        <FormData>
          <FormDataTitle>Datos generales de mi campaña:</FormDataTitle>
          {exceedsLimit && (
            <p>
              Lo sentimos, no podemos crear tu campaña porque ya alcanzaste el monto máximo para
              solicitud de crédito.
            </p>
          )}
          <FormDataGeneral>
            <SectionFields>
              <div>
                <p>Nombre de la campaña:</p>
                <input type="text" name="campaignName" onChange={handleData} />
              </div>
              <div>
                <p>Monto del crédito que quieres solicitar</p>
                <input
                  type="text"
                  placeholder="$0.00 MXN"
                  name="requestedAmount"
                  value={data.requestedAmount}
                  onChange={handleNumber}
                />
              </div>
              <div>
                <p>¿En qué utilizarás el crédito de esta campaña?</p>
                <select name="loanDestination" onChange={handleData}>
                  <option value={false}>Seleccionar una opción</option>
                  <option value="NOMINA">Pago de nómina</option>
                  <option value="COMPRAS">Compra de Inventario y/o Materia Prima</option>
                  <option value="PROVEEDORES">Pago a proveedores</option>
                  <option value="IMPUESTOS">Pago de impuestos</option>
                  <option value="GASTOS">Pago gastos imprevistos</option>
                  <option value="OTRO">Otro</option>
                </select>
              </div>
            </SectionFields>
            <SectionFields>
              <div>
                <p>Plazo para realizar el pago total de esta campaña:</p>
                <input
                  type="text"
                  name="termLoan"
                  placeholder="(30-120 días)"
                  onChange={handleNumber}
                />
              </div>
              <div>
                <p>Principal fuente de ingresos para el pago de este préstamo:</p>
                <select name="paymentSource" onChange={handleData}>
                  <option value={false}>Seleccionar una opción</option>
                  <option value="VENTAS">Ventas al contado</option>
                  <option value="COBRANZA">Cobranza de ventas a plazo</option>
                  <option value="CREDITO">Disposición de otro crédito</option>
                  <option value="SOCIOS">Nuevas aportaciones de los socios</option>
                  <option value="OTRO">Otro</option>
                </select>
              </div>
            </SectionFields>
          </FormDataGeneral>
          <p>
            Recuerda que para crear campaña, deberás proporcionar la información de biométricos e
            INE en caso de que el obligado solidario sea una persona diferente al representante
            legal.
          </p>
        </FormData>
      </FormContainer>
      <StyledButton onClick={handleCalcs} disabled={!validateFields() || exceedsLimit}>
        Siguiente
      </StyledButton>
    </CreditInformationContainer>
  );
}
