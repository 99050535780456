export const environment = process.env.REACT_APP_ENV || 'dev';
export const url =
  environment === 'prod' ? process.env.REACT_APP_URL_FINSPHERA : 'https://api-dev.finsphera.com';
export const isByPass = process.env.REACT_APP_BYPASS === 'true' ? true : false;

export const config = {
  environment,
  url,
  isByPass,
  apiKeyAccountState: process.env.REACT_APP_APIKEY_ACCOUNT_STATE,
  urlFinsphera: process.env.REACT_APP_URL_FINSPHERA,
  env: process.env.REACT_APP_ENV,
  bypassMetamap: process.env.REACT_APP_BYPASS_METAMAP === 'true' ? true : false
};
