const monthlyOperations = [
  {
    name: 'Menos de 2 operaciones',
    code: 1
  },
  { name: 'Entre 3 y 6 operaciones', code: 2 },
  { name: 'Entre 7 y 9 operaciones', code: 3 },
  { name: 'Entre 10 y 15 operaciones', code: 4 },
  { name: 'Más de 15 operaciones', code: 5 }
];
const monthlyAmount = [
  { name: 'Menos de $25,000', code: 1 },
  { name: 'Entre $25,000 y $250,000', code: 2 },
  { name: 'Entre $250,000 y $1,500,000', code: 3 },
  { name: 'Entre $1,500,000 y $5,000,000', code: 4 },
  { name: 'Más de $5,000,000', code: 5 }
];
const resourcesOrigin = [
  { name: 'Sueldo Fijo', code: 1 },
  { name: 'Honorarios', code: 2 },
  { name: 'Herencia y/o Donación', code: 3 },
  { name: 'Sucesión testamentaria', code: 4 },
  { name: 'Aguinaldo o utilidades', code: 5 },
  { name: 'Negocio Propio', code: 6 },
  { name: 'Comisiones', code: 7 },
  { name: 'Rifas, Sorteos, Premios', code: 8 },
  { name: 'Becas', code: 9 },
  { name: 'Pensiones', code: 10 },
  { name: 'Ahorros', code: 11 },
  { name: 'Fondos de Ahorro', code: 12 },
  { name: 'Rentas', code: 13 },
  { name: 'Arrendamiento', code: 14 },
  { name: 'Préstamos Personales', code: 15 },
  { name: 'Bonos e Incentivos', code: 16 },
  { name: 'Dividendos', code: 17 },
  { name: 'Fideicomisos', code: 18 },
  { name: 'Inversiones', code: 19 },
  { name: 'Por especificar', code: 20 },
  { name: 'Recursos de terceros', code: 21 },
  { name: 'Regalías', code: 22 },
  { name: 'Remesas del extranjero', code: 23 },
  { name: 'Ventas', code: 24 },
  { name: 'Venta de bienes', code: 25 }
];
const resourcesOriginMoral = [
  { name: 'Cobranza de cartera de clientes principalmente con fondos bancarios', code: 1 },
  { name: 'Cobranza de cartera de clientes principalmente en efectivo', code: 2 },
  { name: 'Emisión y venta de acciones', code: 3 },
  { name: 'Préstamo bancario', code: 4 },
  { name: 'Préstamo no bancario', code: 5 },
  { name: 'Recursos públicos', code: 6 },
  { name: 'Ingresos por regalías (royalties)', code: 7 },
  { name: 'Inversiones reguladas', code: 8 },
  { name: 'Inversiones no reguladas', code: 9 },
  { name: 'Dividendos', code: 10 },
  { name: 'Donativos bancarizados', code: 11 },
  { name: 'Donativos en efectivo', code: 12 },
  { name: 'Venta de inventarios', code: 13 },
  { name: 'Venta de propiedad de activos, planta y/o vehículos corporativos', code: 14 }
];
const resourcesDestination = [
  { name: 'Ahorro e Inversiones', code: 1 },
  { name: 'Consumo', code: 2 },
  { name: 'Pago de Créditos, Servicios y/o Productos', code: 3 },
  { name: 'Diversos', code: 4 }
];
const isPEP = [
  { name: 'No PEP (Persona políticamente expuesta)', code: 1 },
  { name: 'PEP Nacional', code: 2 },
  { name: 'PEP Extranjero', code: 3 }
];

export default [
  monthlyAmount,
  monthlyOperations,
  resourcesOrigin,
  resourcesOriginMoral,
  resourcesDestination,
  isPEP
];
