import { CheckboxContainer } from './checkbox.styles';
import { CheckboxProps } from './checkbox.types';
import { forwardRef } from 'react';
import { ErrorMessage } from 'src/utilities/UILibrary/Forms/InputField/ErrorMessage';

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { label, id, errorMessages = [], boxSize, ...restProps } = props;

  const inputId = id || `input-checkbox-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <CheckboxContainer boxSize={boxSize}>
      <div className="input-wrapper">
        <label htmlFor={inputId}>{label}</label>
        <input id={inputId} ref={ref} type="checkbox" {...restProps} />
      </div>
      {errorMessages.length > 0 && <ErrorMessage errorMessages={errorMessages} />}
    </CheckboxContainer>
  );
});

Checkbox.displayName = 'Checkbox';
