import styled from 'styled-components/macro';

export const PersonalInformationContainer = styled.section`
  form {
    div:nth-child(1) {
      justify-content: space-between;
    }
  }

  .radio-group {
    min-width: 100px;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;

    input {
      width: 25px;
      height: 25px;
    }
  }
`;
