import styled from 'styled-components/macro';
import { blackSphera, grayPlaceholder, whiteSphera } from 'src/utilities/UIStyles/variables/colors';

export const InvestmentContainer = styled.tr`
  > td {
    color: ${blackSphera};
    font-size: 1vw;
    text-align: left;
    padding-left: 0.5%;
  }
  > td:last-child {
    width: 15%;
    padding-top: 1%;
    padding-bottom: 1%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > td {
    }
    > td:last-child {
    }
  }
  @media (max-width: 600px) {
    > td {
      font-size: 3vw;
    }
    > td:last-child {
    }
  }
`;

export const Button = styled.button`
  font-size: 1vw;
  font-weight: bold;
  color: ${whiteSphera};
  width: auto;
  padding: 6% 1%;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.cancelDisabled ? grayPlaceholder : blackSphera)};
  cursor: ${(props) => (props.cancelDisabled ? 'not-allowed' : 'pointer')};

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    font-size: 3vw;
  }
`;
