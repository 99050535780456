import styled from 'styled-components/macro';
import {
  blackSphera,
  brandBlack,
  grayMediumSphera,
  greenSp,
  whiteSphera,
  neutralBlueGray,
  neutralLight,
  graySphera
} from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const navBarValues = {
  height: '4.125rem'
};

export const NavContainer = styled.nav`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1% 5%;
  background-color: ${whiteSphera};
  z-index: 1000;
  max-height: ${navBarValues.height};
  height: 100%;
  gap: 2rem;

  @media (max-width: ${breakpoints.tablet}) {
    gap: 0.5rem;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;

  a {
    height: min-content;

    img {
      width: 8rem;
      justify-self: center;
    }
  }

  .menu-left {
    display: flex;
    width: 100%;
    justify-content: start;
    gap: 2rem;
    align-items: center;

    li {
      list-style: none;
      margin: 0;
      padding: 0;

      a {
        all: unset;
        display: block;
        font-size: ${uIFontSize.medium};
        color: ${graySphera};
        text-decoration: none;

        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    .menu-left {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    a {
      img {
        width: 6rem;
      }
    }
  }
`;

export const Menu = styled.div`
  gap: 1rem;

  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;

  > div {
    > p {
      color: ${grayMediumSphera};
    }
  }
  > a {
    display: block;
    color: ${whiteSphera};
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    padding: 3% 10%;
    border-radius: 5px;
    background-color: ${blackSphera};
  }

  .hamburger-react {
    display: none;
    top: -2px;
    div > div {
      height: 4px !important;
    }
  }

  .navbar-button {
    width: 8.625rem;
  }

  .flow-button {
    background-color: ${greenSp};
  }

  @media (max-width: ${breakpoints.desktop}) {
    .hamburger-react {
      display: block;
    }

    > a {
      padding: 5% 10%;
    }
    > div {
      > p {
        color: ${grayMediumSphera};
      }
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    max-width: 65%;
    gap: 0.5rem;

    button {
      max-width: 7rem;
    }

    .hamburger-react {
      display: block;
    }

    > a {
      padding: 5% 10%;
    }

    > div {
      > p {
        color: ${grayMediumSphera};
      }
    }
  }
`;

export const MenuLogged = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-end;
  align-items: center;
  > div {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    > a {
      border-radius: 10px;
      padding: 2% 0;
      text-decoration: none;
      color: ${neutralBlueGray};
      width: 20%;
      text-align: center;
    }
    > a:hover {
      color: ${brandBlack};
      font-weight: bold;
    }
  }
  > button {
    background-color: ${neutralLight};
    color: ${brandBlack};
    border-radius: 20px;
    border-style: none;
    width: 15%;
    padding: 1%;
    cursor: pointer;
  }
  > button:hover {
    font-weight: bold;
  }
`;
