import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

export default function ContractsSign() {
  const [successViewModal, setSuccessViewModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const { widgetId } = useParams();
  const navigate = useNavigate();

  const onSuccess = () => {
    setSuccessViewModal(true);
  };
  const onError = () => {
    setErrorModal(true);
  };
  useEffect(() => {
    (window.mifiel = window.mifiel || []),
      (function () {
        'use strict';
        for (
          var e = ['widget'],
            i = function (e) {
              return function () {
                window.mifiel.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              };
            },
            t = 0;
          t < e.length;
          t++
        ) {
          var n = e[t];
          window.mifiel[n] || (window.mifiel[n] = i(n));
        }
        if (!document.getElementById('mifiel-js')) {
          var r = document.createElement('script'),
            o = document.getElementsByTagName('script')[0];
          (r.type = 'text/javascript'),
            (r.id = 'mifiel-js'),
            (r.async = !0),
            (r.src = 'https://www.mifiel.com/sign-widget-v1.0.0.js'),
            o.parentNode.insertBefore(r, o);
        }
      })();
    window.mifiel.widget({
      widgetId: widgetId,
      appendTo: 'mifiel-widget',
      successBtnText: 'Proceed to next step',
      onSuccess: {
        callToAction: function () {
          onSuccess();
        }
      },
      onError: {
        callToAction: function () {
          onError();
        }
      }
    });
  }, [widgetId]);

  return (
    <>
      <ComposedModal
        isOpen={successViewModal}
        onClose={() => {
          setSuccessViewModal(false);
          navigate('/solicitante/dashboard/mi-perfil');
        }}
        type="success"
        messageTitle="Pagaré firmado exitosamente"
        message="El pagaré ha sido firmado de forma exitosa"
      />
      <ComposedModal
        isOpen={errorModal}
        onClose={() => setErrorModal(false)}
        type="error"
        messageTitle="Ups"
        message={'Ocurrió un error, por favor intenta de nuevo'}
      />
      <div id="mifiel-widget"></div>
    </>
  );
}
