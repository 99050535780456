import React, { useEffect, useState } from 'react';

import { ModalAllDataRequired } from './allDataRequired.styles';

export default function AllDataRequired(props) {
  const [allDataRequired, setAllDataRequired] = useState(props.showModalAllDataRequired);

  useEffect(() => {
    setAllDataRequired(props.showModalAllDataRequired);
  }, [props.showModalAllDataRequired]);

  const closeModal = () => {
    setAllDataRequired(false);
    props.setAllDataRequired(false);
  };

  return (
    <ModalAllDataRequired showModalAllDataRequired={allDataRequired}>
      <div>
        <h2>¡Todos los campos son requeridos para poder avanzar!</h2>
        <button onClick={closeModal}>Cerrar</button>
      </div>
    </ModalAllDataRequired>
  );
}
