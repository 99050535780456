import { Link } from 'react-router-dom';
import { TermsContainer, TableContainer, TableOne, TableTwo } from './Terms.styles';

export default function Terms() {
  return (
    <TermsContainer>
      <h1>TÉRMINOS Y CONDICIONES</h1>
      <h2>
        EN VIRTUD DEL PRESENTE CONTRATO, SE DESCRIBEN LOS TÉRMINOS Y CONDICIONES CONTRACTUALES (LOS
        “TÉRMINOS Y CONDICIONES”) QUE REGULAN EL ACCESO Y/O UTILIZACIÓN (EL “USO”) QUE EL USUARIO
        HAGA DE LA PÁGINA WEB, LA PLATAFORMA DE FINANCIAMIENTO COLECTIVO QUE OPERA EN ELLA (LA
        “PLATAFORMA”) SU CONTENIDO Y SERVICIOS (LOS “SERVICIOS”) PUESTOS A SU DISPOSICIÓN POR
        SUSTAINABLE TECH ESG, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN DE CAPITAL VARIABLE,
        INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO (“FINSPHERA”), SOCIEDAD CONSTITUIDA EN LOS ESTADOS
        UNIDOS MEXICANOS CON DOMICILIO SOCIAL EN MONTECITO 38 OF. 16 PISO 28, COLONIA NÁPOLES,
        ALCALDÍA DE BENITO JUÁREZ, C.P. 03810, CIUDAD DE MÉXICO. FINSPHERA CUENTA CON UNA
        AUTORIZACIÓN DE LA COMISIÓN NACIONAL BANCARIA Y DE VALORES PARA ORGANIZARSE Y OPERAR COMO
        INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO PUBLICADA EL DÍA 12DE DICIEMBREDE 2022 EN EL DIARIO
        OFICIAL DE LA FEDERACIÓN.
      </h2>
      <p>
        Mediante su registro en www.finsphera.com, el Usuario consiente establecer una relación
        contractual con FINSPHERA, la cual estará sujeta en todo momento al presente instrumento, a
        la Ley para Regular las Instituciones de Tecnología Financiera y demás normatividad de la
        materia aplicable en los Estados Unidos Mexicanos. En caso de que el Usuario no acepte los
        presentes Términos y Condiciones o sujetarse a la Ley para Regular las Instituciones de
        Tecnología Financiera, ambos con carácter de obligatorio y vinculante, deberá de abstenerse
        de acceder o utilizar los Servicios. Los Términos y Condiciones sustituyen expresamente los
        acuerdos o compromisos previos con el Usuario. Los presentes Términos y Condiciones deberán
        ser aceptados expresamente por el Usuario, al efecto, podrá hacerlo mediante factores de
        autenticación en términos del artículo 56 de la Ley para Regular las Instituciones de
        Tecnología Financiera. Se podrán aplicar condiciones suplementarias a determinados
        Servicios, como la celebración de contratos adicionales, accesorios o manifestaciones
        unilaterales de la voluntad que puedan ser necesarios o convenientes en términos de la Ley
        para Regular las Instituciones de Tecnología Financiera con Solicitantes o Inversionistas,
        por lo que dichas condiciones suplementarias se le comunicarán en relación con los Servicios
        aplicables. Las condiciones suplementarias se establecen además de los presentes Términos y
        Condiciones, y se considerarán una parte de éstos, para los fines de los Servicios
        aplicables. Las condiciones suplementarias prevalecerán sobre los Términos y Condiciones en
        el caso de conflicto con respecto a los Servicios aplicables. En caso de que el Usuario
        viole lo dispuesto en los presentes Términos y Condiciones, FINSPHERA podrá restringir su
        Uso, así como excluir al Usuario de futuras operaciones y tomar las acciones legales que
        juzgue convenientes para sus intereses.
      </p>
      <h2>
        CONTRATO DE ADHESIÓN RESPECTO DEL USO Y ACCESO (EN LO SUCESIVO, “TÉRMINOS Y CONDICIONES”)
        QUE CELEBRAN, POR UNA PARTE, SUSTAINABLE TECH ESG, SOCIEDAD ANÓNIMA PROMOTORA DE INVERSIÓN
        DE CAPITAL VARIABLE, INSTITUCIÓN DE FINANCIAMIENTO COLECTIVO (EN LO SUCESIVO, “FINSPHERA”)
        Y, POR LA OTRA, EL USUARIO, SIENDO LOS ANTERIORES EN SU CONJUNTO REFERIDOS COMO “LAS
        PARTES”, SUJETÁNDOSE AMBOS AL TENOR DE LAS SIGUIENTES CLÁUSULAS.
      </h2>
      <h3>1. Definiciones</h3>
      <p>
        Para efectos del presente Aviso de Privacidad se tendrá por definición, en la totalidad del
        documento, a efecto de brindar claridad y exactitud al significado de las siguientes
        palabras, con independencia de ser escritas en singular o en plural:
      </p>
      <br />
      <ul>
        <li>
          <span> Aviso de Privacidad.-</span>
          Documento puesto a disposición del público en el Sitio, en el que FINSPHERA hace del
          conocimiento de los Usuarios qué Datos Personales son recabados, las finalidades de su
          tratamiento, así como las formas, mecanismos y requisitos para el ejercicio de sus
          derechos de acceso, rectificación, cancelación y oposición, amén de los medios para
          realizar la revocación de su consentimiento y la limitación del uso o divulgación de su
          información.
        </li>
        <li>
          <span> Compromiso de Inversión.- </span>
          Las aportaciones que los Inversionistas se hayan comprometido a realizar en favor de los
          Solicitantes durante el plazo de publicación y republicación de una solicitud.
        </li>
        <li>
          <span> Constancia de Inversión.- </span>
          El documento en virtud del cual FINSPHERA hace saber al Inversionista que su inversión ha
          sido completada de conformidad con sus instrucciones y dentro del marco del Contrato de
          Comisión Mercantil que hubieren celebrado.
        </li>
        <li>
          <span> Constancia de Recepción y Posterior Disposición.- </span>
          Aquel documento en virtud del cual el Solicitante, mediante su firma, consiente y reafirma
          que los recursos puestos a su disposición han sido recibidos y, por lo tanto, podrá
          proceder a su completa disposición.
        </li>
        <li>
          <span>Constancia Electrónica de Riesgos.- </span>
          El documento en virtud del cual FINSPHERA hace del conocimiento del Inversionista los
          riesgos a los que está sujeta su inversión y que no está respaldada por el Gobierno
          Federal. Este documento se elabora y pone a disposición de los inversionistas de
          conformidad con el Anexo 8 de las Disposiciones de Carácter General aplicables a
          Instituciones de Tecnología Financiera.
        </li>
        <li>
          <span>Contrato de Adhesión.- </span>
          Los presentes términos y condiciones de uso de la Plataforma de FINSPHERA, en toda su
          extensión.
        </li>
        <li>
          <span>Contrato de Comisión Mercantil.- </span>
          Contrato mediante el cual el Inversionista comisiona a FINSPHERA para que realice por su
          cuenta y orden actos de comercio consistentes en la recepción, manejo y administración de
          los recursos que el Inversionista entregue a FINSPHERA, con la finalidad de otorgar
          financiamientos colectivos de deuda por medio del Sitio y Plataforma, a los Solicitantes
          que el propio Inversionista seleccione. De manera general, el Inversionista también
          comisiona a FINSPHERA para efectuar todo tipo de operaciones, negocios y actos jurídicos
          que resulten necesarios para el otorgamiento de financiamientos colectivos de deuda, así
          como las diligencias de cobranza que de ellos se deriven.
        </li>
        <li>
          <span>Contrato de Financiamiento Colectivo.- </span>
          Contrato en virtud del cual, en nombre de los Inversionistas, FINSPHERA otorga un crédito
          simple con obligado solidario a un Solicitante y éste se compromete a pagarlo en la fecha
          o fechas convenidas. Al crédito podrán sumarse intereses ordinarios, intereses moratorios,
          comisiones, accesorios y demás gastos que en virtud de este contrato deberá cubrir el
          Solicitante a favor de FINSPHERA en nombre de los Inversionistas
        </li>
        <li>
          <span>Cuenta de Usuario.-</span>
          Cuenta única, intransferible y personal de los Usuarios que realicen su registro en el
          Sitio.
        </li>
        <li>
          <span>Datos Personales.-</span>
          Cualquier información concerniente al Usuario, o a cualquier persona física identificada o
          identificable.
        </li>
        <li>
          <span>Factor de Autenticación.-</span>
          Aquellos mecanismos o conjunto de técnicas y procedimientos utilizados para verificar la
          identidad, tangible o intangible, basado en dispositivos o información que solo el Usuario
          posea o conozca. Estos mecanismos podrán incluir:
          <p>Información que solamente el Usuario conozca, tales como contraseñas.</p>
          <p>
            Información contenida o generada en medios o dispositivos respecto de los cuales el
            Usuario tenga posesión, tales como contraseñas de un solo uso o “Tokens”.
          </p>
        </li>
        <li>
          <span>Inversionista.- </span>
          Aquella persona física o moral que aporte recursos a los Solicitantes a través del Sitio
          de FINSPHERA.
        </li>
        <li>
          <span>Nivel FINSPHERA.-</span>
          Aquella clasificación que determina la jerarquía que tiene un Solicitante tomando como
          referencia o medida su historial positivo o negativo del cumplimiento de sus obligaciones
          con los Inversionistas. Por lo que, ante un historial positivo la tasa de interés cobrada
          podrá ir disminuyendo y los rendimientos esperados para el Inversionista podrán también ir
          disminuyendo.
        </li>
        <li>
          <span>Plataforma.-</span>
          La plataforma de financiamiento colectivo de FINSPHERA que opera en el Sitio. Reporte a la
          Sociedad Información Crediticia.- La información que FINSPHERA envíe a una Sociedad de
          Información Crediticia con la finalidad de actualizar el historial crediticio de los
          Solicitantes.
        </li>
        <li>
          <span>Saldo FINSPHERA.- </span>
          El sistema de entrega de recursos para operaciones de carácter financiero en el Sitio.
        </li>
        <li>
          <span>Servicios.-</span>
          Aquellas operaciones que FINSPHERA ofrece al público a través de su Sitio web.
        </li>
        <li>
          <span>Sitio.-</span>
          La página web registrada bajo el nombre de dominio <Link to="/">www.finsphera.com.</Link>
        </li>
        <li>
          <span>Sociedad de Información Crediticia.- </span>
          Institución dedicada a prestar los servicios de recopilación, manejo y entrega o envío de
          información relativa al historial crediticio de personas físicas y morales.
        </li>
        <li>
          <span>Solicitante.- </span>
          Aquella persona moral que requiera un financiamiento colectivo a través de la Plataforma.
        </li>
        <li>
          <span>Términos y Condiciones.- </span>
          El presente Contrato de Adhesión, en toda su extensión.
        </li>
        <li>
          <span> Uso.- </span>
          (a) El acceso y utilización del Sitio en cuanto al ingreso a la Plataforma de FINSPHERA;
          (b) el registro y creación de una cuenta de usuario; (c) la inversión en Moneda Nacional
          de recursos en la figura denominada Saldo FINSPHERA; (d) la publicación y consulta, según
          corresponda, de solicitudes de financiamientos colectivos de deuda; (e) la emisión y
          consulta de estados de cuenta y estados de operación; (f) el seguimiento a inversiones y
          créditos corrientes, según corresponda; (g) el pago de créditos; (h) la obtención de
          rendimientos, según corresponda.
        </li>
        <li>
          <span>Usuario.- </span>
          Persona que ingresa, navega y/o utiliza el Sitio para cualquier finalidad conforme a
          derecho o las buenas costumbres mexicanas.
        </li>
      </ul>
      <br />
      <h3>2. Objeto</h3>
      <h4>Objeto del Contrato.</h4>
      <p>
        Los presentes Términos y Condiciones establecen los derechos y obligaciones que gozarán y a
        las que se sujetarán FINSPHERA y los Usuarios por el Uso del Sitio, la Plataforma, su
        contenido, secciones y funcionalidades. Las Partes convienen que la prestación de los
        Servicios que solicitaren se regirá por contratos independientes que corresponden a cada uno
        de ellos.
      </p>
      <h4>Puesta a Disposición.</h4>
      <p>
        {' '}
        Los presentes Términos y Condiciones serán puestos a disposición del Usuario en la
        plataforma que opera bajo el nombre de dominio de{''}
        <Link to="/>">https://www.finsphera.com</Link>, ubicados en la esquina inferior derecha de
        la misma, abajo del título denominado “LEGAL”.
      </p>
      <ul>
        <li>
          Uso de la Plataforma General. Comprende de manera general: (i) el acceso y utilización del
          Sitio en cuanto al ingreso a la Plataforma de FINSPHERA; (ii) el registro y creación de
          una cuenta de usuario; (iii) la emisión y consulta de estados de cuenta y estados de
          operación.
        </li>
        <li>
          Uso de la Plataforma para Inversionistas. Comprende: (i) la consulta, según corresponda,
          de solicitudes de financiamientos colectivos de deuda; (ii) la elección de solicitudes
          para ejecutar inversiones en Moneda Nacional; (iii) el seguimiento a inversiones
          corrientes, según corresponda; (iv) la dispersión de rendimientos, según corresponda.
        </li>
        <li>Uso de la Plataforma para Solicitantes.</li>
        <li>
          Uso de la Plataforma General. Comprende de manera general: (i) el acceso y utilización del
          Sitio en cuanto al ingreso a la Plataforma de FINSPHERA; (ii) el registro y creación de
          una cuenta de usuario; (iii) la emisión y consulta de estados de cuenta y estados de
          operación.
        </li>
        <li>
          Uso de la Plataforma para Inversionistas. Comprende: (i) la consulta, según corresponda,
          de solicitudes de financiamientos colectivos de deuda; (ii) la elección de solicitudes
          para ejecutar inversiones en Moneda Nacional; (iii) el seguimiento a inversiones
          corrientes, según corresponda; (iv) la dispersión de rendimientos, según corresponda.
        </li>
        <li>
          Uso de la Plataforma para Solicitantes. Comprende: (i) la solicitud de financiamiento
          colectivo de deuda; (ii) la publicación de solicitudes de financiamiento colectivo en
          Moneda Nacional; (iii) el seguimiento a créditos corrientes, según corresponda; (iv) el
          pago de créditos.
        </li>
        <li>
          Restricciones.El uso de la Plataforma se restringe de conformidad con lo dispuesto en la
          Ley para Regular las Instituciones de Tecnología Financiera, sus disposiciones
          secundarias, el Contrato de Comisión Mercantil y el Contrato de Financiamiento Colectivo.
          De la misma manera, el Usuario deberá sujetarse a las prohibiciones mencionadas en el
          numeral 6.3 del presente.
        </li>
      </ul>
      <br />
      <h3>3. Propiedad Intelectual.</h3>
      <p>
        Propiedad del Sitio. Este Sitio y la Plataforma, incluyendo sin limitación alguna todo su
        contenido como son textos, fotografías, ilustraciones, gráficos, videos, audio, diseños,
        códigos, algoritmos, datos y cualquier otra información, salvo que se indique lo contrario,
        son propiedad única y exclusiva de FINSPHERA, incluyendo cualesquiera derechos de autor,
        derechos de marca, derechos de patente, derechos de base de datos, derechos morales, y otras
        propiedades intelectuales y derechos patrimoniales del mismo, que se encuentran protegidos
        por la Ley Federal del Derecho de Autor, la Ley Federal de Protección a la Propiedad
        Industrial, así como cualquier otro ordenamiento en materia de Propiedad Intelectual
        aplicable.
      </p>
      <ul>
        <li>
          <span>Inexistencia de Transmisión de Propiedad o Licencia. </span> El uso de los Servicios
          no le otorga en ningún momento al Usuario propiedad del contenido del Sitio, ni tampoco se
          le otorga licencia para explotación de éstos, por lo que el Usuario reconoce que por
          ningún motivo adquiere derecho alguno de propiedad al descargar material con derechos de
          autor del Sitio o relacionado con los Servicios.
        </li>
        <li>
          <span>Marcas. </span> Los signos distintivos, incluyendo las marcas y avisos comerciales,
          así como demás Contenido, expuestos en los Servicios serán en todo momento propiedad de
          FINSPHERA, salvo que se indique lo contrario, aun cuando los mismos se encuentren
          registrados o no, y no pueden ser usados por el Usuario sin consentimiento expreso y por
          escrito de FINSPHERA.
        </li>
      </ul>
      <br />
      <h3>4. Capacidad del Usuario.</h3>
      <p>
        Manifestación del Usuario. Desde el momento en el que acceden y hacen uso del Sitio, los
        Usuarios manifiestan que cuentan con capacidad de ejercicio suficiente para obligarse en
        todos los extremos establecidos en los presentes Términos y Condiciones.
      </p>
      <ul>
        <li>
          <span>Menores de Edad. </span> En el supuesto de que los Usuarios del Sitio sean menores
          de edad o incapaces, el acceso, uso y aprovechamiento del Sitio, su contenido, secciones y
          funcionalidades, así como la contratación de los Servicios se entenderán bajo la total y
          completa responsabilidad de quienes ejerzan la patria potestad o sean sus tutores. Para
          hacer uso del Sitio y para contratar los Servicios, los menores de edad o incapaces
          deberán obtener previa autorización de sus padres, tutores o representantes legales,
          quienes serán entendidos como enteros responsables de todos los actos realizados por los
          aquellos a su cargo. De manera íntegra y total, la responsabilidad en la determinación de
          los contenidos y Servicios a los que accede un menor de edad o incapaz corresponde
          enteramente a quienes ejerzan la patria potestad, sean sus tutores o representantes
          legales.
        </li>
      </ul>
      <br />
      <h3>5. Naturaleza de los Servicios.</h3>
      <p>
        Servicios de Tecnología Financiera. La solicitud de los Servicios y su debida prestación
        consiste, comprende y debe ser entendida en el panorama de que FINSPHERA cuenta con una
        autorización para organizarse y operar como Institución de Financiamiento Colectivo.
      </p>
      <ul>
        <li>
          <span>Alcance. </span>
          Este documento comprende los Términos y Condiciones de uso de la Plataforma que utiliza
          FINSPHERA para soportar sus operaciones. La solicitud de los Servicios se regirá por los
          presentes Términos y Condiciones. En este entendido, este documento comprende las bases
          legales y manifestaciones que FINSPHERA establece con sus Usuarios. Adicionalmente, estos
          Términos y Condiciones se entienden complementarios a la prestación de los Servicios, que
          operan bajo el marco del Contrato de Comisión Mercantil y el Contrato de Financiamiento
          Colectivo que celebre FINSPHERA con los Inversionistas y Solicitantes, respectivamente.
        </li>
      </ul>
      <br />
      <h3>6. Reglas generales de Uso del Sitio y Plataforma.</h3>
      <p>
        6.1 Ingreso. El ingreso a la Plataforma se realiza mediante el acceso al Sitio que opera
        bajo el nombre de dominio de www.finsphera.com. El ingreso comprende la navegación libre y
        responsable del Usuario en el Sitio.
      </p>
      <p>
        6.2 Registro y creación de Cuenta de Usuario. Para realizar el registro y creación de una
        Cuenta de Usuario se debe de seleccionar la opción de “Crear Cuenta”, al seleccionar dicha
        opción se debe de elegir entre la opción de utilizar la plataforma para “Invertir” o para
        “Solicitar Financiamiento”. Adicionalmente se debe de proporcionar la información requerida:
        correo electrónico, teléfono celular, nombre de usuario y contraseña.
      </p>
      <p>
        6.3 Prohibiciones. En el uso de los Servicios, el Usuario tendrá estrictamente prohibido:
      </p>
      <p>6.4 Difundir información falsa o engañosa o que induzca al error.</p>
      <p>6.5 Restringir o inhibir a cualquier otro usuario de usar y disfrutar los Servicios.</p>
      <p>
        6.6 El comportamiento fraudulento, ilegal, amenazante, abusivo, hostigante, calumnioso,
        difamatorio, discriminatorio, obsceno, vulgar, ofensivo, pornográfico, profano, sexualmente
        explícito o indecente.
      </p>
      <p>
        6.7 Generar o alentar conductas que pudieran constituir una ofensa criminal, dar lugar a
        responsabilidad civil o de otro modo violar cualquier ley local, nacional o internacional.
      </p>
      <p>
        6.8 Violar, plagiar o infringir los derechos de terceros incluyendo, sin limitación,
        derechos de autor, marcas comerciales, secretos comerciales, confidencialidad, contratos,
        patentes, derechos de privacidad o publicidad o cualquier otro derecho de propiedad.
      </p>
      <p>6.9 Diseminar virus, elementos de espionaje u otro componente dañino.</p>
      <ul>
        <li>
          Emitir enlaces fijos, publicidad, cartas de cadenas o esquemas de pirámides de cualquier
          tipo.
        </li>
        <li>
          Retirar cualquier nota de derechos de autor, marca registrada u otra nota de propiedad de
          cualquier parte de los Servicios.
        </li>
        <li>
          Reproducir, modificar, preparar obras derivadas sobre los Servicios, distribuir,
          licenciar, arrendar, revender, transferir, exhibir públicamente, presentar públicamente,
          transmitir, retransmitir o explotar de otra forma los Servicios, excepto como se permita
          expresamente por FINSPHERA.
        </li>
        <li>
          Descompilar, realizar ingeniería inversa o desmontar los Servicios, excepto como se
          permita por la ley aplicable.
        </li>
        <li>
          Causar o lanzar cualquier programa o código informático con el objeto de extraer, indexar,
          analizar o de otro modo realizar prospección de datos de cualquier parte de los Servicios
          o sobrecargar o bloquear indebidamente la operación y/o funcionalidad de cualquier aspecto
          de los Servicios.
        </li>
        <li>
          Intentar obtener un acceso no autorizado o dañar cualquier aspecto de los Servicios o sus
          sistemas o redes relacionados.
        </li>
        <li>
          Usar la plataforma con el propósito de adquirir, enajenar, administrar, custodiar, poseer,
          cambiar, convertir, depositar, retirar, dar o recibir por cualquier motivo, invertir,
          traspasar, transportar o transferir dentro del territorio nacional, de éste hacia el
          extranjero o a la inversa, recursos, derechos o bienes de cualquier naturaleza, cuando se
          tenga o no se tenga conocimiento de que proceden o representan el producto de una
          actividad ilícita o que no pueda acreditarse su legítima procedencia acorde a derecho o
          las buenas costumbres.
        </li>
        <li>
          Usar la plataforma con el propósito de ocultar, encubrir o pretender ocultar o encubrir la
          naturaleza, origen, ubicación, destino, movimiento, propiedad o titularidad de recursos,
          derechos o bienes, cuando se tenga o no se tenga conocimiento de que proceden o
          representan el producto de una actividad ilícita o que no pueda acreditarse su legítima
          procedencia acorde a derecho o las buenas costumbres mexicanas.
        </li>
        <li>
          Realizar pactos relacionados con operaciones realizadas en el Sitio y Plataforma con otros
          Usuarios y/o terceros por medios ajenos al propio Sitio, Plataforma o canales autorizados
          por FINSPHERA. Mientras que entre los Usuarios, Solicitantes e Inversionistas subsista la
          relación contractual con FINSPHERA, todo acto relacionado con las operaciones de
          financiamiento colectivo deberá de hacerse mediante la plataforma. Ahora bien, ante su
          contravención, FINSPHERA no será responsable ni avalará cualesquiera pactos realizados
          entre los Usuarios.
        </li>
        <li>
          Exclusión. En este sentido, se hace saber al Usuario que el incumplimiento a lo anterior
          podrá derivar en la decisión de FINSPHERA de excluir al Usuario de los Servicios por no
          hacer uso adecuado de ellos o por incumplir los presentes Términos y Condiciones. Para
          tales efectos, se le hará llegar una notificación mediante la Plataforma y se le harán
          saber los motivos por la cual se le excluye de los servicios del presente sitio. Al
          efecto, se le asignará un folio de referencia y en caso de tener saldos en su cuenta de
          usuario se le devolverán a la cuenta bancaria que hubiere asociado en un periodo no mayor
          a treinta días naturales. En caso de que la cuenta bancaria del usuario no reciba el monto
          correspondiente, los recursos estarán disponibles para el usuario en las oficinas de
          FINSPHERA, en días y horas hábiles.
        </li>
        <li>
          Reglas de Registro, Acceso y Uso. El registro, acceso y uso de los Servicios por cada
          Usuario deberá ser en todo momento personal, por lo que los derechos y obligaciones que
          tiene al amparo los Términos y Condiciones son personales, únicos e intransferibles. El
          Usuario deberá mantener en secreto y abstenerse de revelar a terceros los datos asociados
          a su cuenta y Servicios, incluyendo, sin limitación, el nombre de usuario y clave de
          seguridad, así como, en su caso, la cantidad de dinero que deposita el Usuario en la
          plataforma de FINSPHERA, para evitar la realización de operaciones irregulares o ilegales
          que vayan en detrimento del patrimonio del Usuario. El Usuario será responsable por todas
          las operaciones efectuadas en el acceso a la plataforma, en la creación de una cuenta de
          Usuario, en el depósito de dinero y en el uso de la cuenta del Usuario, la cual es de
          conocimiento exclusivo del mismo.
        </li>
        <li>
          Consentimiento. El Usuario deberá manifestar su consentimiento al tiempo de la celebración
          del Contrato de Comisión Mercantil o del Contrato de Financiamiento Colectivo a través de
          la firma, en caso de ser Solicitante y, en caso de ser Inversionista, lo hará empleando un
          Factor de Autenticación. El Factor de Autenticación utilizado por el Inversionista
          comprenderá aquellos mecanismos generadores de contraseñas dinámicas de un solo uso
          (Token). Lo anterior, en virtud de proporcionar una mayor seguridad como agilización para
          la celebración contractual entre el Solicitante e Inversionista.
        </li>
        <li>
          Disponibilidad de Acceso la Plataforma.La plataforma de FINSPHERA está a disposición del
          Usuario las 24 (veinticuatro) horas del día, así como los 365 (trescientos sesenta y
          cinco) días del año. Lo anterior, con excepción de los días en los que la plataforma esté
          llevando a cabo un procedimiento de actualización para ofrecer un mejor Servicio, con la
          debida notificación de la suspensión del uso de la plataforma.
        </li>
        <li>
          Con el objetivo de brindar seguridad a nuestros clientes, en caso de presentarse un fallo
          en nuestros servicios, FINSPHERA comunicará a través de nuestro centro de atención a
          clientes, las opciones de continuidad del servicio. FINSPHERA dará seguimiento a las dudas
          y aclaraciones en todo momento.
        </li>
        <li>
          Disponibilidad Transaccional en la Plataforma. La plataforma de FINSPHERA está a
          disposición del Usuario, para fines de completar operaciones, cualquier día en el cual las
          oficinas principales de las instituciones de crédito del país en la Ciudad de México estén
          abiertas al público para la realización de operaciones bancarias, en un horario de 9:00
          horas a 17:00 horas.
        </li>
        <li>
          Indemnización. En todo momento el Usuario se obliga a defender e indemnizar a FINSPHERA, a
          sus directores, funcionarios, empleados y agentes, y a sacarlos en paz y a salvo de
          cualquier demanda, responsabilidad, costos y gastos, de cualquier naturaleza, incluyendo
          honorarios de abogados, en que incurriera como resultado del uso indebido de los
          Servicios, o su incumplimiento o violación de la Ley para Regular las Instituciones de
          Tecnología Financiera o de los presentes Términos y Condiciones.
        </li>
      </ul>
      <br />
      <h3>7. Inactividad de la Sesión.</h3>
      <p>
        Causales de Terminación de la Sesión. El Usuario consiente que las sesiones de su Cuenta de
        Usuario en la Plataforma podrán ser terminadas automática e inmediatamente por FINSPHERA en
        los siguientes casos:
      </p>
      <ul>
        <li>La sesión se ha mantenido inactiva por un plazo superior a 5 (cinco) minutos.</li>
        <li>
          Durante el curso de la sesión, FINSPHERA haya identificado cambios relevantes en los
          parámetros de comunicación. Los parámetros de comunicación que serán considerados para
          efectos de esta previsión son -enunciativa, más no limitativamente- los siguientes:
        </li>
        <li>Identificación del dispositivo de acceso.</li>
        <li>Rango de direcciones de los protocolos de comunicación.</li>
        <li>Ubicación geográfica.</li>
        <li>
          Notificación Previa. FINSPHERA informará al Usuario que el plazo de terminación por
          inactividad está por cumplirse, con el fin de que, en su caso, evite la finalización de la
          sesión.
        </li>
        <li>
          Informe al Usuario. En todo caso, FINSPHERA informará al Usuario la causal de terminación
          automática de la sesión.
        </li>
      </ul>
      <br />
      <h3>8. Manifestación del Usuario..</h3>
      <p>
        Reconocimiento del Usuario. Al acceder y usar los Servicios el Usuario reconoce y acepta
        que:
      </p>
      <ul>
        <li>
          FINSPHERA ha tomado medidas para evitar que se difunda información falsa o engañosa a
          través de los Servicios
        </li>
        <li>
          Con el fin de promover la diversificación de inversiones, FINSPHERA limita el monto que
          los Inversionistas podrán invertir en las Solicitudes de Inversión publicadas en la
          Plataforma de acuerdo con lo dispuesto en los artículos 49 y 50 de las Disposiciones de
          Carácter General Aplicables a las Instituciones de Tecnología Financiera publicadas en el
          Diario Oficial de la Federación el 10 de septiembre de 2018, reformadas mediante
          publicación en el Diario Oficial de la Federación el 25 de marzo de 2019, los cuales
          establecen en su parte conducente lo siguiente:{' '}
          <p>
            Artículo 49.- Las instituciones de financiamiento colectivo deberán establecer en sus
            Plataformas controles que impidan que un mismo Inversionista realice Compromisos de
            Inversión que superen los porcentajes a los que se refiere la siguiente tabla, una vez
            aplicada la fórmula indicada:{' '}
          </p>
        </li>
      </ul>
      <p>
        Para los efectos de este artículo, se entenderán como inversiones efectivas: Al saldo
        insoluto del que sea acreedor un Inversionista a la fecha en que se pretenda realizar el
        nuevo Compromiso de Inversión, en el caso de Operaciones de financiamiento colectivo de
        deuda.
      </p>
      <ul>
        <li>
          El importe del total de los Valores o derechos cuya titularidad siga siendo del
          Inversionista conforme a la información más reciente con la que cuente cada institución de
          financiamiento colectivo o la que le provean los Inversionistas, considerando para ello el
          monto nominal efectivamente invertido en moneda nacional en el momento en que el
          Inversionista hubiere adquirido cada Valor o derecho a través de la institución de
          financiamiento colectivo, tratándose de Operaciones de Financiamiento Colectivo de Capital
          o de Financiamiento Colectivo de Copropiedad o Regalías.
        </li>
      </ul>
      <p>
        Cuando un Inversionista realice Operaciones de financiamiento de distinto tipo a través de
        una misma institución de financiamiento colectivo, el porcentaje aplicable al que se
        sujetarán los nuevos Compromisos de Inversión será aquel que corresponda al tipo de
        financiamiento de que se trate conforme a la tabla señalada en el presente artículo,
        debiendo sumar en el denominador de la fórmula prevista, las inversiones efectivas y los
        Compromisos de Inversión realizados en cualquier tipo de Operación de financiamiento
        colectivo. Artículo 50.- Los porcentajes previstos en el artículo anterior, no serán
        aplicables en los siguientes supuestos: Cuando los Inversionistas mantengan un monto
        agregado de inversiones efectivas y Compromisos de Inversión que no supere el equivalente en
        moneda nacional a 2,000 UDI’s en la propia institución de financiamiento colectivo, esta les
        permitirá realizar Compromisos de Inversión por Inversionista en una misma solicitud de
        financiamiento hasta por el equivalente en moneda nacional a 667 UDI’s, tratándose de
        Operaciones de Financiamiento Colectivo de Deuda de Préstamos Personales entre Personas o
        Empresariales entre Personas.
      </p>
      <ul>
        Cuando en la Plataforma existan 25 o menos solicitudes de financiamiento colectivo
        publicadas, el Compromiso de Inversión a ser realizado por un mismo Inversionista a un
        Solicitante no podrá exceder del máximo entre 5 % del monto de financiamiento requerido o el
        equivalente en moneda nacional a 167,000 UDI’s.
        <li>
          Cuando la institución de financiamiento colectivo, como parte de los esquemas para
          compartir riesgos con los Inversionistas, realice Compromisos de Inversión en algún
          Solicitante o proyecto, podrá aumentar los porcentajes referidos en el artículo 49 de
          estas disposiciones, adicionando el porcentaje de inversión que la propia institución se
          esté comprometiendo en tal Solicitante o proyecto, o el 25 %, lo que resulte menor.
        </li>
        <li>
          Tratándose de Inversionistas Relacionados y Experimentados. Adicionalmente, a estos
          Inversionistas no les resultarán aplicables los límites de las fracciones anteriores.
        </li>
      </ul>
      <p>
        Las instituciones de financiamiento colectivo solicitarán la información que acredite dicha
        calidad. En el caso de los Inversionistas Experimentados a que se refiere el inciso d) de la
        fracción X del artículo 2 de las presentes disposiciones, las instituciones de
        financiamiento colectivo obtendrán la manifestación contenida en el Anexo 9 de estas
        disposiciones, así como la documentación comprobatoria, al inicio de su relación contractual
        o en el momento en que los Inversionistas soliciten obtener el carácter de Inversionista
        Experimentado conforme a las presentes disposiciones. La CNBV evaluará periódicamente los
        límites establecidos en este artículo y en los artículos 47 a 49 anteriores y podrá
        modificarlos, a fin de que se refleje el sano y adecuado desarrollo de las instituciones de
        financiamiento colectivo y las Operaciones que se puedan efectuar a través de estas.
      </p>
      <br />
      <TableContainer>
        <TableOne>
          <thead>
            <tr>
              <th>Rubro</th>
              <th>Ponderacion</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1. Mercado y Producto</td>
              <td>14.28%</td>
            </tr>
            <tr>
              <td>2. Acceso al Crédito</td>
              <td>14.28%</td>
            </tr>
            <tr>
              <td>3. Rentabilidad</td>
              <td>14.28%</td>
            </tr>
            <tr>
              <td>4. Liquidez</td>
              <td>14.28%</td>
            </tr>
            <tr>
              <td>5. Solvencia</td>
              <td>14.28%</td>
            </tr>
            <tr>
              <td>6. Gobierno Corporativo</td>
              <td>14.30%</td>
            </tr>
            <tr>
              <td>7. Impacto Ambiental y Social</td>
              <td>14.30%</td>
            </tr>
          </tbody>
        </TableOne>
        <TableTwo>
          <thead>
            <tr>
              <th>Evaluación Final</th>
              <th>Puntuación</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Óptima</td>
              <td>10 a 9</td>
            </tr>
            <tr>
              <td>Muy Buena</td>
              <td>8.9 a 8.5</td>
            </tr>
            <tr>
              <td>Deficiente</td>
              <td>8.4 a 0</td>
            </tr>
          </tbody>
        </TableTwo>
      </TableContainer>
      <ul>
        En efecto, la mecánica para restringir los montos en que los clientes de FINSPHERA operan en
        la plataforma obedece a lo siguiente:
        <li>
          a) Dentro de la plataforma se generan las variables NCI, EIE, ECI y ENCI, las cuales se
          alimentan de la actividad que realice o lleve a cabo el inversionista en la plataforma.
        </li>
        <li>
          b) Cada una de dichas variables corresponde a: (i) NCI, Compromisos de Inversión que se
          pretenden realizar en una misma solicitud de financiamiento; (ii) EIE, la suma de
          inversiones efectivas realizadas por el inversionista previamente a través de FINSPHERA.;
          (iii) ECI la suma de Compromisos de Inversión previamente realizados en otras solicitudes
          de financiamiento por el inversionista a través de FINSPHERA y; (iv) ENCI, la suma de los
          Compromisos de Inversión que se pretenden realizar en una misma solicitud de
          financiamiento.
        </li>
        <li>
          c) Conforme el usuario realiza compromisos de inversión o inversiones efectivas en la
          plataforma, dichos montos y eventos se registran al momento de querer invertir y,
          adicionalmente, de forma histórica.
        </li>
        <li>
          d) En el momento en que un inversionista desea hacer un nuevo compromiso de inversión, la
          plataforma calcula el resultado de implementar los valores de las variables señaladas en
          la siguiente fórmula:
        </li>
        <li>
          e) Al resultado de dicha operación aritmética, por programación de la plataforma, se le
          aplica una prueba lógica en cuanto a validar que el resultado sea menor o igual a 15% en
          caso de que el inversionista sea persona física y 20% en caso de que el inversionista sea
          persona moral.
        </li>
        <li>
          f) Por su parte, las reglas previas también tienen una condición lógica en la cual: (i) Si
          el inversionista mantiene un monto histórico de inversiones efectivas y Compromisos de
          Inversión que no supere las 2,000 UDI’s en FINSPHERA, se les permitirá realizar
          Compromisos de Inversión por Inversionista en una misma solicitud de financiamiento hasta
          por el equivalente a 667 UDI’s; (ii) si en la Plataforma existen 25 o menos solicitudes de
          financiamiento publicadas, el inversionista podrá invertir hasta el 5% del monto de
          financiamiento requerido o el equivalente en moneda nacional a 167,000 UDI’s; (iii) si
          FINSPHERA, como parte de los esquemas para compartir riesgos con los Inversionistas,
          realiza Compromisos de Inversión en alguna solicitud, se aumentarán los porcentajes
          referidos en el inciso e), adicionando el porcentaje de inversión que FINSPHERA se esté
          comprometiendo a realizar o el 25 %, lo que resulte menor y; (iv) si el inversionista
          acredita ser un Inversionistas Relacionado o Inversionista Experimentado, no se aplicarán
          límites mencionados.
        </li>
      </ul>
      <p>
        Manifestación del Usuario. El Usuario manifiesta que los recursos utilizados para la
        realización de operaciones con FINSPHERA son propios y que actúa a nombre y por cuenta
        propia y por ende reconoce que será el único responsable del cumplimiento a estos Términos y
        Condiciones. En caso de actuar a nombre de un tercero proporcionará la información necesaria
        para su debida identificación. Asimismo, declara que la fuente de los recursos utilizados en
        las operaciones es lícita.
      </p>
      <br />
      <h3>9. Manifestación del Usuario.</h3>
      <p>
        <span>Consulta y Verificación. </span> El Inversionista consultará y verificará la
        Metodología de evaluación, selección y clasificación de Solicitantes y proyectos puesta a su
        disposición en el Sitio para tomar una decisión de inversión.
      </p>
      <p>
        <span>Resultado de la Metodología. </span> Al efecto y en términos prácticos, el Usuario
        entiende y acepta que dicha metodología se refleja en los Niveles FINSPHERA y Niveles de
        Riesgo de los Solicitantes. En consecuencia, FINSPHERA asignará un resultado de acuerdo con
        los siguientes criterios:
      </p>
      <p>
        <span>Niveles de Riesgo</span>Todos los Solicitantes en la Plataforma de Financiamiento
        Colectivo FINSPHERA se sujetan a un proceso de análisis y selección que consta de aspectos
        personales y corporativos. De la misma manera, se hace una revisión de la sustentabilidad
        del negocio, con base en sus relaciones comerciales con sus clientes, así como en sus
        indicadores financieros dependiendo de la antigüedad del negocio. También se realiza una
        evaluación del grado de implementación de buenas prácticas Medio Ambientales, Sociales y de
        Buena Gobernanza por parte del Solicitante de acuerdo con los 17 Objetivos de Desarrollo
        Sostenible de la Organización de las Naciones Unidas. Finalmente, FINSPHERA realiza
        consultas con Sociedades de Información Crediticia para revisar comportamiento crediticio
        previo y referencias comerciales.
      </p>
      <br />
      <p>
        Todas las solicitudes publicadas por FINSPHERA se sujetan a este proceso, que es revisado y
        avalado por el Gerente de Riesgos y Seguimiento de FINSPHERA. Este órgano asigna un nivel de
        riesgo a cada uno de los Solicitantes, en función de lo siguiente: Con el propósito de
        realizar una evaluación del riesgo del Solicitante, la información analizada se estructura
        en las siguientes siete áreas de valoración, las cuales tienen un peso relativo en el
        cálculo de la puntuación final. El algoritmo de obtención de dicha puntuación se efectúa
        como la sumatoria del producto de la puntuación y el porcentaje de ponderación asignada a
        cada una de estas áreas:
      </p>

      <ul>
        La puntuación final se calcula con un promedio simple de la calificación de cada área, no
        olvidando en ningún momento que el Solicitante en cada área debe obtener una calificación
        mínima de 8 para poder realizar una campaña de Financiamiento Colectivo a través de
        FinSphera.
        <li>
          Niveles FINSPHERA. Todos los Solicitantes generarán un historial positivo conforme cumplan
          con sus obligaciones. En función de ello, la tasa de interés cobrada podrá disminuir y, en
          consecuencia, los rendimientos esperados para el Inversionista también disminuyen.
        </li>
      </ul>
      <p>
        En ese sentido, el Usuario autoriza a FINSPHERA a emplear mecanismos de monitoreo o
        revisiones periódicas a su información pública contenida en el portal del Servicio de
        Administración Tributaria. En caso de que el Usuario no esté de acuerdo con lo señalado en
        los dos párrafos inmediatos anteriores, puede solicitar, en cualquier momento, la revocación
        de la autorización antes otorgada. Para tal efecto, el Usuario deberá formular y enviar su
        solicitud de revocación a la Unidad Especializada de Atención a Clientes (UNE o UNE
        FINSPHERA), a través de los medios de contacto que se señalan en el punto 24.2. de los
        presentes Términos y Condiciones.
      </p>
      <br />
      <h3>10. Flujo para Inversionistas.</h3>
      <ul>
        <li>
          <span>Generación y Propósito de Cuenta Virtual.</span>
          Las partes convienen en que, los Usuarios que deseen ser Inversionistas contarán con una
          cuenta virtual con una Clabe Interbancaria asignada individualmente por la propia
          Plataforma, a la que transferirán los recursos que deseen financiar a los Solicitantes. En
          ella se recibirán los pagos que realicen los Solicitantes como pago del financiamiento
          otorgado por el Inversionista.
        </li>
        <li>
          <span>Constancia Electrónica de Riesgos.</span>
          Desde el momento de su registro y previo a realizar operaciones, se presentará al
          Inversionista una Constancia Electrónica de Riesgos que deberá ser leída y comprendida
          para la posible celebración del Contrato de Comisión Mercantil, con el propósito de
          informar lo siguiente:
        </li>
        <li>
          La imposibilidad de disponer los recursos invertidos en el momento en el que el
          Inversionista lo requiera, una vez que se hubiere completado la operación. Ello no será
          aplicable antes de que se complete el financiamiento.
        </li>
        <li>
          La posibilidad de que no existan las condiciones para que se lleve a cabo la venta de los
          derechos o títulos que documenten las operaciones realizadas entre el Solicitante y el
          Inversionista.
        </li>
        <li>
          La posibilidad de perder la totalidad de los recursos que se hayan invertido en caso de
          que el Solicitante incumpla con sus obligaciones.
        </li>
        <li>
          La posibilidad de que los estados financieros de las sociedad o proyectos en los que se
          invierta no estén dictaminados por un auditor externo, por lo que la información
          financiera podría no reflejar razonablemente la situación financiera de la sociedad o
          proyecto de que se trate.
        </li>
        <li>
          La posibilidad de recibir información inicial y subsecuentemente limitada en comparación a
          lo observado en el mercado de valores, por lo que el Inversionista podría no contar con
          suficiente información para tomar decisiones coherentes de inversión.
        </li>
        <li>
          FINSPHERA no asegura retornos o rendimientos sobre la inversión realizada o garantiza el
          resultado o éxito sobre las mismas.
        </li>
        <li>
          Las instituciones gubernamentales no podrán: (a) responsabilizarse o garantizar los
          recursos que como Usuario de FINSPHERA utiliza o utilizará en operaciones, según dicho
          término se define en la Ley para Regular las Instituciones de Tecnología Financiera; y (b)
          asumir alguna responsabilidad por las obligaciones contraídas por FINSPHERA o por algún
          Usuario de FINSPHERA, en virtud de la celebración de las operaciones.
        </li>
        <li>
          FINSPHERA ha sido autorizada, regulada y supervisada por las Autoridades Financieras,
          según dicho término se define en la Ley para Regular las Instituciones de Tecnología
          Financiera.
        </li>
        <li>
          <span>Constancia de Inversión. </span>
          Posteriormente a la asignación de los recursos a una solicitud en concreto por el
          Inversionista, se le presentará una Constancia de Inversión, que deberá ser leída y
          entendida con la finalidad de constatar que la inversión se realizó acorde a todos los
          procedimientos convenidos y dispuestos en el Contrato de Comisión celebrado con FINSPHERA.
        </li>
        <li>
          <span>Seguimiento.</span>
          FINSPHERA se compromete a proporcionar continuamente la información sobre el
          comportamiento de pago del Solicitante, sobre su desempeño o cualquiera otra información
          relevante para el Inversionista. Por lo que el Usuario deberá consultar, revisar y
          verificar la información sobre el comportamiento del Solicitante.
        </li>
      </ul>
      <br />
      <h3>11. Flujo para Solicitantes.</h3>
      <ul>
        <li>
          <span>Solicitud de Financiamiento Colectivo.</span>
          El Usuario Solicitante que pretenda publicar una solicitud en la Plataforma, deberá
          proporcionar los datos necesarios para llenar la Solicitud de Financiamiento Colectivo y
          firmarla de conformidad. Este es un requisito indispensable para iniciar el procedimiento.
        </li>
        <li>
          <span>Publicación</span>
          Una vez completadas por el Usuario Solicitante, las Solicitudes de Financiamiento
          Colectivo serán publicadas en la Plataforma por un periodo de 7 (siete) días. Durante
          dicho periodo, los Inversionistas podrán fondear la solicitud en comento.
        </li>
        <li>
          <span>Republicación.</span>
          En caso de que no se alcanzare la meta de recursos planteada en la solicitud, el
          Solicitante tendrá derecho de publicar nuevamente la Solicitud por un plazo adicional de 7
          (siete) días. Al finalizar dicho plazo, y solo si los recursos alcanzados son menores al
          80% de la meta planteada, el Solicitante podrá decidir tomar los recursos que fueron
          fondeados, o bien, cancelar la solicitud. Si los recursos alcanzados son iguales o mayores
          al 80% de la meta planteada, el Solicitante deberá tomarlos.
        </li>
        <li>
          <span>Toma y Destino de los Recursos.</span>
          El Usuario que sea Solicitante se obliga a tomar los recursos que el Usuario que sea
          Inversionista le otorgue y se obliga a utilizarlos para el fin que haya publicado en su
          solicitud, los cuales serán puestos a su disposición por medio del Sitio de FINSPHERA, en
          caso de que se haya completado el financiamiento solicitado.
        </li>
        <li>
          <span>Plazo para Tomar Recursos.</span>
          El Solicitante contará con un periodo de 48 horas para tomar los recursos del
          financiamiento del Crédito que solicitó, contadas a partir de que terminó el Plazo de
          Publicación o Plazo de Re-Publicación de la Solicitud de Financiamiento Colectivo
          realizada en la Plataforma. Si terminado el periodo el Solicitante no toma los recursos,
          FINSPHERA emitirá una alerta en la Plataforma que se mantendrá por un periodo de 24 horas,
          haciéndole saber por la Plataforma que debe tomar los recursos correspondientes a su
          Solicitud de Financiamiento Colectivo.
        </li>
        <li>
          <span>Cancelación de la Solicitud y del Compromiso de Inversión.</span>
          En caso de que el Solicitante no tome los recursos, se cancelará su solicitud de fondeo.
          Los recursos que los Inversionistas hubieren aportado como Compromiso de Inversión serán
          regresados a sus respectivos saldos en sus correspondientes cuentas FINSPHERA, a más
          tardar, 24 horas después de que haya sido cancelada la solicitud de fondeo. En
          consecuencia, se dará por cancelado el Compromiso de Inversión.
        </li>
        <li>
          <span>Contrato</span>
          Previo a que el Solicitante tome los recursos fondeados por los Inversionistas, firmará el
          Contrato de Financiamiento Colectivo así como el Pagaré correspondiente con FINSPHERA.
        </li>
      </ul>
      <br />
      <h3>12. Interpretación de las Operaciones.</h3>
      <p>
        Naturaleza de las Operaciones. Lo establecido en los presentes Términos y Condiciones
        consiste, comprende y debe ser entendido en el panorama de que FINSPHERA cuenta con una
        autorización para organizarse y operar como Institución de Financiamiento Colectivo.
      </p>
      <ul>
        Nada de lo pactado en los presentes Términos y Condiciones, ni el acceso o uso de los
        Servicios podrá interpretarse como:
        <li>
          Una inversión en Valores, ni emisión de Valores de ninguna especie. Por lo tanto, no
          implica una oferta de Valores, ni privada ni pública.
        </li>
        <li>
          Una intermediación de Valores, ni administración de cartera de Valores, ni toma decisiones
          en nombre de terceros. FINSPHERA no es asesor en inversiones. Excepto por la gestión de
          evaluación del Solicitante, no ha realizado un análisis propio de la información, datos y
          cifras contenidas en las solicitudes de financiamiento colectivo, por lo que dicha
          información no debe ni puede ser interpretado por el Inversionista como una recomendación
          de inversión. El Usuario deberá realizar sus propios análisis sobre la pertinencia o no de
          participar en los Servicios de FINSPHERA. FINSPHERA sugiere que el Inversionista obtenga
          su propia asesoría legal, fiscal y financiera.
        </li>
        <li>
          Un fondo de inversión, ni una administración de fondos de inversión o realización de
          actividades descrita en la Ley de Fondos de Inversión.
        </li>
        <li>
          FINSPHERA no realizará, ni está autorizado a realizar, ninguna actividad reservada a
          Instituciones de Crédito.
        </li>
        <li>
          Actividades auxiliares del crédito, incluyendo la realización habitual y profesional de
          operaciones de crédito.
        </li>
      </ul>
      <h3>13. Cuenta del usuario.</h3>
      <p>
        <span>Creación de Cuenta.</span>Para efectos de solicitar cualquiera de los Servicios, el
        Usuario, previa lectura y aceptación del Aviso de Privacidad, deberá llevar a cabo el
        registro de sus Datos Personales y la generación de una Cuenta de Usuario como se menciona
        en la cláusula 6.2. de este instrumento, relativa a las Reglas Generales de Uso del Sitio.
        La creación de una Cuenta de Usuario se lleva a cabo directamente en el Sitio. El Usuario se
        obliga a proporcionar y mantener la información personal de su cuenta de forma exacta,
        completa y actualizada. La falta a ello podría derivar en la imposibilidad de acceder y
        utilizar los Servicios.
      </p>
      <p>
        <span>Responsabilidad sobre Contraseñas y Cuenta.</span>Cada Usuario del Sitio es el único
        responsable de mantener sus contraseñas. El Usuario es totalmente responsable de todas las
        actividades que ocurran bajo su contraseña o cuenta. Por otra parte, el Usuario debe
        notificar a FINSPHERA de cualquier uso no autorizado de su contraseña o cuenta. De ninguna
        manera FINSPHERA será responsable, directa o indirectamente, por cualquier pérdida o daño de
        cualquier tipo incurridos como resultado de la falta de cumplimiento con esta sección por
        parte del Usuario.
      </p>
      <p>
        i. En caso de extravió o robo de la Contraseña, podrá ponerse en contacto con un
        administrador de soporte mediante la dirección de correo electrónico:
        atencionaclientes@finsphera.com, haciendo del conocimiento a FINSPHERA sobre la situación en
        que se encuentra el Usuario proporcionado los siguientes elementos:
      </p>
      <ul>
        <li>Nombre Completo</li>
        <li>Tipo de Usuario (Inversionista o Solicitante)</li>
        <li>Breve Relatoría de los hechos</li>
      </ul>
      <p>
        Se aclara que el Correo Electrónico en el que se envía el presente reporte, debe coincidir
        con el correo que se utilizó para darse de alta en un inicio como Usuario. Asimismo,
        FINSPHERA deberá implementar las acciones que considere necesarias para evitar un mayor
        grado de afectación al Usuario, procediendo a su vez con el bloqueo inmediato de la cuenta.
      </p>
      <br />
      <p>
        ii. Por otro lado, en caso de olvidar la contraseña de su cuenta, se podrá dar click en el
        Apartado “Recupera Tu Contraseña”, posteriormente se enviará un mensaje de confirmación al
        correo electrónico personal registrado inicialmente por el Usuario, al cual tendrá que
        acceder con el fin de modificar su contraseña.
      </p>
      <p>
        <span>Características de la Cuenta de Usuario. </span>La Cuenta del Usuario es personal,
        única e intransferible. El Usuario será responsable por todas las operaciones efectuadas
        desde su Cuenta del Usuario. Está prohibida la venta, cesión, transferencia o transmisión de
        la Cuenta del Usuario bajo cualquier título, ya sea oneroso o gratuito.
      </p>
      <p>
        <span>Recuperación de Contraseña.</span> En caso de olvidar la contraseña de su cuenta de
        usuario, el Usuario deberá seleccionar la opción de “Recupera tu contraseña”, posteriormente
        se le solicitará su correo electrónico con el cual se creó la cuenta de usuario donde el
        mismo deberá ingresarla. En virtud de lo anterior se le enviará un correo electrónico en el
        cual deberá de seleccionar la opción de “Recupera tu contraseña” que remitirá a la página
        web de FINSPHERA en donde se le dará la opción de crear una nueva contraseña.
      </p>
      <p>
        <span>Robo o Extravío de Autenticación Multifactor.</span>FINSPHERA pone a disposición de
        sus usuarios y clientes un mecanismo para realizar reportes de incidencias relacionadas con
        la autenticación multifactor, específicamente, casos de robo o extravío. Estos reportes
        deberán ser realizados por el usuario, en caso de configurarse un robo o extravío, de
        conformidad con los siguientes pasos:
      </p>
      <ul>
        <li>El usuario ingresa a la Plataforma FINSPHERA.</li>
        <li>El usuario ingresa a la sección “Iniciar sesión”.</li>
        <li>
          La Plataforma le solicita las credenciales de acceso (usuario y contraseña sucesivamente).
        </li>
        <li>El usuario ingresa las credenciales solicitadas.</li>
        <li>La Plataforma valida las credenciales.</li>
        <li>
          En caso de que la validación del punto anterior se exitosa, la Plataforma muestra en
          pantalla un enlace con la opción de reportar el robo o extravío de la autenticación
          multifactor.
        </li>
        <li>
          El usuario pulsa el enlace para reportar la incidencia de robo o extravío mencionado en el
          paso anterior.
        </li>
        <li>
          La Plataforma FINSPHERA muestra en pantalla un formulario que el usuario deberá
          seleccionar si se trata de un robo o extravío.
        </li>
        <li>El usuario deberá realizar una confirmación mediante su contraseña.</li>
        <li>
          El usuario selecciona el tipo de incidente y confirma la operación ingresando su
          contraseña. La Plataforma FINSPHERA valida la contraseña.
        </li>
        <li>
          Si la validación del paso anterior fue exitosa la Plataforma FINSPHERA restablece el doble
          factor de autenticación y genera un folio único.
        </li>
        <li>
          La Plataforma FINSPHERA muestra en pantalla un mensaje notificando que la operación se
          llevó a cabo de manera exitosa y también muestra el folio generado en el paso anterior
          para darle seguimiento en caso de que el usuario así lo desee.
        </li>
      </ul>
      <p>
        <span>Responsabilidades en caso de Robo o Extravío.</span>Las partes convienen en que, las
        operaciones que se hubieren realizado desde el momento del robo o extravío de la información
        de identificación o autenticación del cliente, hasta que se haya dado aviso a FINSPHERA, se
        atribuirán inicialmente al cliente y se presumirá que dicho usuario las realizó, salvo
        prueba en contrario. FINSPHERA proveerá las facilidades necesarias para que los usuarios
        acrediten el posible robo o extravío ante las autoridades correspondientes, en la medida de
        lo razonable.
      </p>
      <br />
      <p>
        FINSPHERA deberá realizar debida diligencia de la información de identificación o
        autenticación del cliente en términos de la Ley para Regular las Instituciones de Tecnología
        Financiera y sus disposiciones secundarias. Se obliga a no atribuir al cliente las
        operaciones que se realicen posteriormente a tener conocimiento -ya fuere por medios propios
        o por reporte del usuario- del robo o extravío de la información de identificación o
        autenticación del cliente en cuestión. FINSPHERA se abstendrá de coartar o limitar la
        posibilidad de que el cliente reporte el robo o extravío de su información de identificación
        o autenticación. Asimismo, valorará objetivamente las pruebas del usuario respecto a las
        operaciones que aluda fueron realizadas posteriormente al robo o extravío de su información
        y previo al reporte.
      </p>
      <p>
        <span>Notificación sobre Falta de Operaciones.</span> Las Partes acuerdan que, en caso de
        que el Usuario haya fondeado recursos a su cuenta virtual en el Sitio y no haya realizado
        movimientos por un periodo igual o mayor a treinta días naturales, así como tampoco haya
        iniciado sesión en el Sitio durante dicho periodo, FINSPHERA devolverá dichos recursos a más
        tardar el día hábil siguiente de aquél en el que finalizó el periodo de inactividad antes
        mencionado. Esta devolución se realizará a la Cuenta Bancaria registrada del cliente. Lo
        anterior, en virtud de que el objeto de FINSPHERA no es acumular recursos de los
        Inversionistas.
      </p>
      <br />
      <h3>14. Cuenta de la cuenta.</h3>
      <ul>
        Causales. FINSPHERA se reserva el derecho de bloquear el acceso o remover en forma parcial o
        total toda información, comunicación o material que a su exclusivo juicio pueda resultar:
        <li>Abusivo, difamatorio u obsceno.</li>
        <li>Fraudulento, artificioso o engañoso.</li>
        <li>
          Violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o
          cualquier derecho de propiedad intelectual de un tercero.
        </li>
        <li>
          Que de cualquier forma contravenga lo establecido en estos Términos y Condiciones, la Ley
          para Regular las Instituciones de Tecnología Financiera y su regulación secundaria.
        </li>
        <li>
          Cancelación por el Usuario. El usuario podrá requerir mediante su sesión de usuario en la
          plataforma, la terminación anticipada de su relación con FINSPHERA, para lo cual FINSPHERA
          le proporcionará por medios digitales un número de referencia de dicha solicitud, así como
          la fecha y hora en que esta se recibió. Dicha información deberá entregarse en el momento
          en que el Usuario hubiere presentado la solicitud de terminación, o bien, a más tardar al
          cierre del día hábil bancario en el que se hubiere presentado la solicitud. FINSPHERA se
          cerciorará de la autenticidad y veracidad de la identidad del Usuario que formule la
          solicitud de terminación respectiva. Una vez realizado lo anterior, FINSPHERA:
        </li>
        <li>
          Se abstendrá de cobrar al Usuario cualquier comisión o penalización por la terminación del
          contrato.
        </li>
        <li>
          Se abstendrá se solicitar al Usuario a que informe o justifique los motivos de la
          terminación del contrato.
        </li>
        <li>
          Cancelará, sin su responsabilidad, el cobro de algún producto o servicio asociado, en la
          fecha de la solicitud de terminación.
        </li>
        <li>
          Se abstendrá de condicionar la terminación del presente Contrato de Adhesión a cualquier
          otro acto no previsto en el contrato respectivo o a la entrega del mismo.
        </li>
      </ul>
      <br />
      <h3>15. Riesgos de inversión.</h3>
      <p>
        <span>Información Adicional sobre Riesgos.</span> Toda información que sea entregada al
        Usuario, en relación con los Servicios, contendrá declaraciones basadas en expectativas que
        que involucran riesgos e incertidumbres comúnmente asociados al tipo de operaciones
        desarrolladas a través de los Servicios. Por lo anterior, los resultados pueden diferir de
        forma sustancial de cualquier expectativa que el Usuario pueda tener frente a sus
        inversiones. Cada Usuario deberá considerar cuidadosamente toda la información que le sea
        presentada a través de los Servicios, incluyendo los riesgos inherentes a las inversiones
        que se realizarán a través de estos.
      </p>
      <p>
        <span>Aceptación de Riesgos.</span> Al aceptar los presente Términos y Condiciones el
        Usuario acepta que previo a la consumación de su primera operación de inversión, el
        Inversionista deberá firmar la Constancia Electrónica de Riesgos, que tiene por objeto que
        el Usuario reconozca los riesgos a los que está sujeta su inversión en FINSPHERA, en los
        términos dispuestos por la Ley para Regular las Instituciones de Tecnología Financiera y
        regulación secundaria aplicable.
      </p>
      <br />
      <h3>16. Continuidad de Servicios.</h3>
      <p>
        <span>Notificación de Incidencias. </span>Si se llegase a presentar alguna eventualidad por
        la cual el sitio presente fallas, FINSPHERA mostrará un mensaje en la plataforma mediante el
        cual se le notificará al usuario que los servicios no estarán disponibles por el momento y
        se hará de su conocimiento que se está trabajando para restablecer su normal funcionamiento,
        así mismo se le indicarán las opciones de continuidad mediante el número telefónico: +52
        (55) 6146 9604 y/o correo electrónico: atencionaclientes@finsphera.com, canales de
        comunicación que estarán disponibles al presentarse cualquier eventualidad que pudiera
        afectar directamente la continuidad de los servicios de la Plataforma, y a través de los
        cuales FINSPHERA mantendrá comunicación con los Usuarios.
      </p>
      <p>
        <span>Notificación a Autoridades. </span>Dicha interrupción de los Servicios será notificada
        a la Comisión Nacional para la Protección y Defensa de los Usuarios de Servicios Financieros
        mediante envío de un correo electrónico a la dirección suptech@condusef.gob.mx, o a través
        de los demás medios dispuestos por la Comisión Nacional para la Protección y Defensa de los
        Usuarios de Servicios Financieros, en tanto las interrupciones se presenten al menos con una
        duración de 24 (veinticuatro) horas.
      </p>
      <p>
        <span>Opciones de continuidad.</span>Con el objetivo brindar seguridad a nuestros clientes
        en caso de presentarse un fallo en nuestros servicios, FINSPHERA dará atención vía
        telefónica y/o a través de nuestro centro de atención a clientes, en cual se dará
        seguimiento a las dudas y aclaraciones en todo momento.
      </p>
      <br />
      <h3>17. Licencia.</h3>
      <p>
        <span>Concesión. </span>
        FINSPHERA concede al Usuario una licencia no exclusiva, no transferible y revocable para
        utilizar el Sitio en virtud de los presentes Términos y Condiciones.
      </p>
      <p>
        <span>Permisos. </span>
        El Usuario podrá imprimir o copiar cualquier información o imagen contenida o publicada en
        el Sitio exclusivamente para uso personal, por lo que queda expresa y terminantemente
        prohibido el uso comercial de dicha información. En caso de ser persona moral se podrá hacer
        acreedor a las sanciones establecidas en la Ley Federal del Derecho de Autor y/o la Ley de
        Propiedad Industrial, vigentes, las cuales prohíben el plagio de contenidos y el lucro
        indebido obtenido por medio de prácticas conocidas como “piratería”, entre otros.
      </p>
      <p>
        <span>Prohibiciones. </span>
        La reimpresión, publicación, distribución, asignación, sublicencia, venta, reproducción
        electrónica o por otro medio, parcial o total, de cualquier información, imagen, documento o
        gráfico que aparezca en el Sitio para cualquier uso distinto al personal no comercial le
        está expresamente prohibido al Usuario, a menos que cuente con la autorización previa y por
        escrito de FINSPHERA. Cualquier infracción de estos Términos y Condiciones dará lugar a la
        revocación inmediata de la licencia otorgada en este apartado sin previo aviso.
      </p>
      <br />
      <h3>18. Privacidad, Protección de Datos Personales y Confidencialidad.</h3>
      <p>
        <span>Propiedad. </span>Los Datos Personales y documentos que reciba FINSPHERA como parte de
        la solicitud de cualquier Servicio son propiedad exclusiva del Usuario y en ninguna
        circunstancia serán publicados, difundidos, o compartidos con terceros sin que medie previa
        autorización por escrito.
      </p>
      <p>
        <span>Confidencialidad. </span>FINSPHERA hace del conocimiento del Usuario, quien a su vez
        comprende y acepta, que toda la información proporcionada, adicionada, provista o agregada
        por los Usuarios y toda la información de carácter personal que se obtenga del Usuario en
        razón del uso del Sitio o la provisión de los Servicios, será tratada de manera
        estrictamente confidencial y protegida contra cualquier uso indebido o alteración, de
        acuerdo con lo establecido en el Aviso de Privacidad puesto a disposición del público en el
        Sitio.
      </p>
      <p>
        <span>Provisión de Información. </span>En este acto el Usuario reconoce y acepta que
        FINSPHERA obtiene su información personal por medio de la provisión o captura de forma
        directa a través del Sitio, sus funcionalidades o módulos.
      </p>
      <p>
        <span>Aviso de Privacidad. </span>Adicionalmente, el Usuario reconoce y consiente que,
        previo a recabar su información, FINSPHERA puso a su disposición el Aviso de Privacidad
        correspondiente, documento en el cual se le hizo sabedor sobre qué Datos Personales son
        recabados, cuáles son las finalidades de su tratamiento, así como la forma, mecanismos y
        requisitos para el debido ejercicio de sus derechos de acceso, rectificación, cancelación y
        oposición, de conformidad con la Ley Federal de Protección de Datos Personales en Posesión
        de los Particulares. Amén de lo anterior, el Usuario reconoce que en el documento de
        referencia se le proporcionó –y, a su vez, consintió- la información necesaria sobre los
        mecanismos para llevar a cabo la revocación de su consentimiento para el tratamiento de sus
        Datos Personales, así como los medios para limitar su uso y divulgación.
      </p>
      <p>
        <span>Consentimiento.</span> El Usuario reconoce que otorga su información y Datos
        Personales a FINSPHERA de manera enteramente libre e informada, utilizando su capacidad y
        criterio, además de que lo hace en ejercicio de su plena voluntad, libre de coacción,
        coerción o violencia. Debido a lo anterior, se entiende que el Usuario es entera y
        completamente responsable de la veracidad y confiabilidad de la información que al efecto
        proporcione.
      </p>
      <p>
        <span>Veracidad de la Información. </span>Al aceptar los presentes Términos y Condiciones,
        el Usuario se obliga a que toda la información que le proporcione a FINSPHERA sea veraz y
        comprobable garantizando así, la autenticidad de los Datos Personales proporcionados.
      </p>
      <p>
        <span>Observación de Principios. </span>FINSPHERA se obliga a observar, respecto de los
        datos personales que recaba del Usuario, los principios de licitud, consentimiento,
        información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.
      </p>
      <p>
        <span>Modificaciones al Aviso de Privacidad.</span> Asimismo, el Aviso de Privacidad puede
        ser modificado en el tiempo y forma que FINSPHERA lo determine, quien notificará al Usuario
        de dicha modificación mediante avisos en la misma plataforma de FINSPHERA, el envío de
        correos electrónicos al usuario proporcionado por el Usuario o mensaje por SMS a través del
        celular proporcionado al momento del ingreso y uso del Sitio, a efecto de que el Usuario, se
        encuentre en posibilidad de ejercer sus Derechos ARCO.
      </p>
      <p>
        <span>Revisiones. </span>Ahora bien, será responsabilidad y obligación del Usuario revisar
        el Aviso de Privacidad, el cual estará a su disposición, en todo momento actualizado en la
        plataforma de FINSPHERA. La no manifestación de inconformidad por parte del Usuario
        representa su consentimiento y autorización al mismo en todos sus términos.
      </p>
      <br />
      <h3>19. Información Adicional.</h3>
      <p>
        <span>Fundamentación. </span>El Usuario entiende y acepta que FINSPHERA podrá solicitarle en
        cualquier momento comprobantes de la información provista por el Usuario o datos adicionales
        en cumplimiento del marco regulatorio en materia de Prevención de Lavado de Dinero al que se
        sujeta FINSPHERA, específicamente, las Disposiciones de carácter general a que se refiere el
        Artículo 58 de la Ley para Regular las Instituciones de Tecnología Financiera.
      </p>
      <p>
        <span>Suspensión. </span>El uso de los Servicios podrá ser suspendido para el Usuario hasta
        en tanto se realice la verificación de todo o parte de la información provista por este
        último.
      </p>
      <p>
        <span>Comisión por Transmisión de Dinero. </span>Toda vez que el objeto de FINSPHERA no es
        la transmisión de dinero y que utiliza servicios externos para ello, en caso de que el
        Usuario pretenda transmitir recursos de su Saldo FINSPHERA, FINSPHERA podrá cobrar al
        Usuario una comisión de $10.00 M.N. (diez pesos 00/100 Moneda Nacional) más IVA. Esta
        comisión se sustenta en los cargos que realiza la institución que, al efecto, transmite los
        montos por esta clase de operaciones.
      </p>
      <p>
        Por el simple uso del sitio Web FINSPHERA no se genera cargo o comisión alguna, en el
        entendido de que FINSPHERA únicamente estará facultada para cobrar comisiones que estén
        debidamente determinadas directamente en el contrato especifico celebrado, las cuales
        dependerán según el producto y/o servicio que se contrate.
      </p>
      <br />
      <h3>20. Renuncias y Responsabilidades.</h3>
      <p>
        <span>Renuncias de FINSPHERA</span>FINSPHERA no establece representación o garantía alguna
        sobre la exactitud, confiabilidad o integridad del contenido (texto e imágenes) de este
        Sitio. Dentro de las limitantes establecidas por la legislación mexicana, FINSPHERA renuncia
        a toda garantía, explícita o implícita, incluyendo sin limitación las garantías implícitas
        sobre mercadibilidad y aptitud para un propósito determinado. Más aún, FINSPHERA no
        garantiza que este Sitio o el servidor que lo hace disponible estén libres de “virus de
        computadoras” por lo que el uso del Sitio es efectuado por el Usuario bajo su propia
        responsabilidad y riesgo.
      </p>
      <p>
        <span>Limitación de Responsabilidad.</span>FINSPHERA no será responsable por daños de
        cualquier tipo, incluyendo sin limitación daños especiales o consecuenciales, derivados del
        acceso del Usuario o imposibilidad de acceso a los Servicios, así como de su uso o
        dependencia de estos.
      </p>
      <p>
        Es importante destacar que si la Plataforma presenta fallas ya sea de manera parcial o
        total, se mostrará en el sitio web un anuncio en el cual se establecerá que el servicio de
        manera parcial o total no se encuentra disponible en ese momento. Dicha interrupción traerá
        consigo una notificación dirigida a la CONDUSEF al correo suptech@condusef.gob.mx, siempre
        que duren al menos veinticuatro horas. Para poder continuar con el servicio, FINSPHERA
        mantendrá comunicación con los Usuarios mediante número telefónico: +52 (55) 6146 9604 y
        correo electrónico: atencionaclientes@finsphera.com.
      </p>
      <br />
      <h3>21. Política de Alineación de incentivos.</h3>
      <p>
        FINSPHERA alinea sus incentivos con los de sus Inversionistas, En su caso, FINSPHERA
        participará como inversionista en cada uno de los créditos otorgados a través de la
        Plataforma con un porcentaje que estará entre el 1% al 5% del monto total solicitado en cada
        campaña.
      </p>
      <li>
        Cobro de comisiones a los inversionistas hasta que hayan recibido el monto total de su
        inversión más intereses en cada operación.
      </li>
      <p>
        FINSPHERA establece esquemas para compartir con los Inversionistas los riesgos de las
        operaciones de financiamiento colectivo de deuda, razón por la que no cobra comisión a los
        Inversionistas hasta que hayan recibido el pago completo de su inversión más los intereses
        correspondientes en cada operación
      </p>
      <li>Atraso de algún Solicitante en el pago de su crédito.</li>
      <p>
        Cuando algún Solicitante se atrase en sus pagos de capital e intereses y por lo tanto,
        FINSPHERA lleve a cabo el proceso de cobranza judicial y/o extrajudicial ya sea directamente
        o a través de Despachos de Cobranza autorizados, FINSPHERA no cobrará comisiones a los
        Inversionistas por concepto de cobranza.
      </p>
      <li>
        En caso de que el Solicitante no pague el monto total del crédito más los intereses
        correspondientes, las comisiones cobradas por FinSphera al solicitante al inicio del crédito
        serán depositadas a los inversionistas.
      </li>
      <p>
        FINSPHERA está comprometido a depositar a los Inversionistas de manera proporcional, la
        totalidad de la comisión que en su momento FINSPHERA haya cobrado al Solicitante al inicio
        del crédito para la eventualidad de que éste no pague su crédito incluyendo intereses.
      </p>
      <br />
      <h3>22. Divulgación de la Información.</h3>
      <ul>
        Casos de Divulgación Permitida. Las Partes, en virtud de los presentes Términos y
        Condiciones, acuerdan que no incurrirán en responsabilidad alguna cuando la Información
        Confidencial que se les haya proporcionado sea conocida por cualquier tercero por alguna de
        las siguientes causas:
        <li>
          Cuando la Información Confidencial se haga del dominio público durante la vigencia de los
          presentes Términos y Condiciones, sin que medie incumplimiento por alguna de las Partes de
          sus obligaciones de confidencialidad de conformidad con lo dispuesto en los mismos.
        </li>
        <li>
          Cuando el titular de la Información Confidencial autorice por escrito a través de sus
          representantes legales, que la contraparte difunda la Información Confidencial sin
          restricciones a terceros, según lo disponga.
        </li>
        <li>
          En caso de que el Usuario sea obligado por disposición legal, administrativa o por orden
          judicial a entregar, total o parcialmente la Información Confidencial. El Usuario deberá
          comunicar a FINSPHERA en el momento en que tenga conocimiento del requerimiento
          correspondiente. En caso de que el requerimiento a que hace alusión el presente inciso no
          sea claro o no se encuentre delimitado con relación a la información que se solicite
          divulgar, el Usuario deberá solicitar a la autoridad administrativa o judicial que
          corresponda que delimite la Información Confidencial correspondiente.
        </li>
        <li>
          Cuando el Usuario obtenga la Información Confidencial de un tercero sin que medie
          incumplimiento de los presentes Términos y Condiciones.
        </li>
        <li>
          Revelaciones a Autoridades. En todo caso, la revelación de cualquier tipo de información
          del Usuario por parte de FINSPHERA a las Autoridades Financieras, no implicará
          transgresión alguna a la obligación de confidencialidad legal, ni constituirá violación a
          las restricciones sobre revelación de información establecidas por cualquier vía
          contractual.
        </li>
      </ul>
      <br />
      <h3>23. Modificaciones Vigencia y Subsistencia de los presentes Términos y Condiciones.</h3>
      <p>
        <span>Reglas Específicas. </span>FINSPHERA podrá modificar el presente contrato dando aviso
        con 30 (treinta) días naturales de anticipación a su entrada en vigor en el portal
        www.finsphera.com. En caso de que el Usuario no esté de acuerdo con las modificaciones,
        puede solicitar, por medio de la Plataforma, la terminación del presente acuerdo dentro de
        los treinta días posteriores al aviso de modificación sin responsabilidad alguna a su cargo,
        y bajo las condiciones pactadas originalmente. Deberá cubrir, en su caso, los adeudos que se
        generen hasta el término de los Servicios, sin que FINSPHERA pueda cobrarle penalización
        alguna por dicha causa.
      </p>
      <p>
        <span>Vigencia. </span>Los presentes Términos y Condiciones son de vigencia indefinida y su
        terminación será efectiva después de un término de 30 (treinta) días contados a partir de su
        notificación.
      </p>
      <p>
        <span>Invalidez de una Cláusula. </span>Asimismo, en caso de que alguna cláusula de los
        presentes Términos y Condiciones sea declarada como inválida, esta será eliminada o
        modificada a elección de FINSPHERA y el resto de las cláusulas subsistirán.
      </p>
      <br />
      <h3>24. Métodos de Autenticación.</h3>
      <p>
        <span>Firma Electrónica. </span>Debido a que el Usuario, en caso de solicitar algún Servicio
        debe de firmar distintos contratos, la firma electrónica podrá ser utilizada a través del
        Sitio para la firma de éstos y tendrá la misma validez que la firma autógrafa.
      </p>
      <p>
        <span>Factores de Autenticación. </span>Los Factores de Autenticación sustituirán a la firma
        autógrafa del Usuario, por lo que las constancias derivadas del uso de esos medios
        producirán los mismos efectos que las leyes otorgan a los documentos tradicionales y tendrán
        igual valor probatorio.
      </p>
      <br />
      <h3>25. Legislación aplicable y Jurisdicción.</h3>
      <p>
        <span>Legislación Aplicable. </span>Los presentes Términos y Condiciones, y al estar
        FINSPHERA autorizada para organizarse y operar como Institución de Financiamiento Colectivo,
        se rigen e interpretan bajo la luz de la Ley para Regular las Instituciones de Tecnología
        Financiera, la Ley de Protección y Defensa al Usuario de Servicios Financieros, la Ley
        Federal para la Prevención e Identificación de Operaciones con Recursos de Procedencia
        Ilícita, el Código de Comercio, el Código Penal, Código Civil Federal, sus respectivas
        disposiciones secundarias, y demás legislación mexicana aplicable.
      </p>
      <p>
        La Unidad Especializada de Atención a Clientes (UNE o UNE FINSPHERA), mediante la cual el
        Usuario podrá realizar consultas, reclamaciones y aclaraciones, se encuentra en Montecito 38
        Of. 16 Piso 28, colonia Nápoles, alcaldía de Benito Juárez, C.P. 03810, Ciudad de México,
        con correo electrónico atencionaclientes@finsphera.com y número teléfono (+52)55-6146-9604.
      </p>
      <p>
        <span>Jurisdicción. </span>Para la resolución de cualquier disputa, controversia,
        reclamación o diferencia que surja de o se relacione con los presentes Términos y
        Condiciones, el Usuario y FINSPHERA se someten expresamente a la competencia de los órganos
        jurisdiccionales establecidos en la Ciudad de México, renunciando a cualquier otro fuero
        presente o futuro que pudiera corresponderle en virtud de sus domicilios presentes o futuros
        o por cualquier otra causa.
      </p>
    </TermsContainer>
  );
}
