import styled from 'styled-components/macro';
import {
  brandBlack,
  whiteSphera,
  greenSp,
  grayLightSphera
} from 'src/utilities/UIStyles/variables/colors';

export const BeneficiariesContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > h3 {
    width: 100%;
    text-align: center;
    color: ${greenSp};
    margin-top: 5%;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const AddBeneficiary = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > button {
    background-color: ${(props) => (props.totalPercentage >= 1 ? grayLightSphera : brandBlack)};
    color: ${whiteSphera};
    margin-bottom: 5%;
  }
  > button:disabled {
    background-color: ${grayLightSphera};
    cursor: not-allowed;
  }
`;
