import styled from 'styled-components/macro';
import {
  brandYellowgreen,
  graySphera,
  grayLightSphera,
  blueSphera,
  whiteSphera,
  grayPlaceholder,
  brandBlack,
  neutralBlueGray,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';

export const DashboardWelcomeContainer = styled.section`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  /* background-color: rgb(245,245,245); */
`;

export const DashboardHomeInvestmentOpportunity = styled.section`
  background-color: rgb(248, 248, 249);
  padding: 2.5% 5%;
  margin: 2% 0;
  > h2 {
    font-size: 2.5vw;
    color: ${brandBlack};
  }
  > p {
    font-size: 1.2vw;
    color: ${brandBlack};
    margin-bottom: 2.5%;
  }
  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > a {
      background-color: ${greenSp};
      color: ${brandBlack};
      padding: 1%;
      border-radius: 10px;
      margin-right: 0;
    }
  }
  > table {
    margin: 2% 0;
    width: 100%;
    > thead {
      > tr {
        border: 1px solid red;

        > th {
          text-align: left;
          color: ${brandBlack};
          width: 15%;
          padding-bottom: 1%;
          border-bottom: 1px solid ${brandBlack};
          cursor: pointer;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          color: ${brandBlack};
          padding: 0.5% 0 0.5% 0.5%;
          > a {
            color: ${brandBlack};
            border-bottom: 1px solid ${brandBlack};
          }
        }
      }

      > tr:nth-child(even) {
        background-color: #f8f8f9;
      }

      > tr:nth-child(odd) {
        background-color: #c5d0e5;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > table {
      margin: 2% 0;
      width: 100%;
      > thead {
        > tr {
          > th {
            font-size: 1.3vw;
          }
        }
      }
      > tbody {
        > tr {
          > td {
            font-size: 1.3vw;
            > a {
              font-size: 1.3vw;
            }
          }
        }
      }
    }
    > p {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    > div:nth-child(1) {
      > h2:nth-child(1) {
        font-size: 5vw;
      }

      > p:nth-child(2) {
        font-size: 4.2vw;
      }

      > div:nth-child(3) {
        width: 100%;
      }

      > div:nth-child(4) {
        flex-direction: column;

        > p {
          font-size: 4.2vw;
        }

        > div {
          width: 100%;

          > button {
            font-size: 4vw;
          }
        }
      }
    }
    > table {
      margin: 2% 0;
      width: 100%;
      > thead {
        > tr {
          > th {
            font-size: 3vw;
          }
        }
      }
      > tbody {
        > tr {
          > td {
            font-size: 2.5vw;
            > a {
              font-size: 3vw;
            }
          }
        }
      }
    }
    > p {
      font-size: 2vw;
    }

    > div {
      > a {
        border-radius: 5px;
        font-size: 3vw;
      }
    }
  }
`;

export const Banner = styled.div`
  background-color: ${whiteSphera};
  padding: 3% 0 2.5% 10%;

  > div {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    > h2 {
      color: ${brandBlack};
      font-size: 2.5vw;
      font-weight: bold;
      width: 20%;
      margin-right: 10%;
    }

    > p {
      color: ${brandBlack};
      font-size: 1.2vw;
      width: 50%;
      > span {
        color: ${neutralBlueGray};
        font-weight: bold;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > h2 {
      }
      > p {
      }
    }
  }
  @media (max-width: 600px) {
    padding: 10% 0 2.5% 0%;
    > div {
      justify-content: center;
      flex-direction: column;
      > h2 {
        font-size: 6vw;
        width: 100%;
        margin-right: 0;
        margin-bottom: 5%;
        text-align: center;
      }
      > p {
        text-align: justify;
        font-size: 4vw;
        width: 90%;
        margin-bottom: 5%;
      }
    }
  }
`;

export const DashboardWelcomeAdvice = styled.div`
  width: 75%;
  margin: 2.5% auto;
  padding: 2.5%;
  background-color: ${brandYellowgreen};
  border-radius: 10px;
  > p {
    font-size: 1.2vw;
    font-weight: bold;
    color: ${brandBlack};
    text-align: center;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > p {
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 5% auto;
    padding: 2.5%;
    > p {
      font-size: 4vw;
    }
  }
`;

export const DashboardWelcomeInfo = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > h3 {
      font-size: 1.3vw;
      font-weight: normal;
      color: ${graySphera};
    }
  }

  > div:nth-child(2) {
    width: 90%;
    margin: auto;
    border: 1px solid ${neutralBlueGray};
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > p {
      font-size: 1.3vw;
      font-weight: bold;
      color: ${graySphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }
    }

    > div:nth-child(2) {
    }

    > div:nth-child(3) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    width: 90%;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 5%;

      > h3 {
        font-size: 3.5vw;
        text-align: center;
      }
    }

    > div:nth-child(2) {
    }

    > div:nth-child(3) {
      padding-top: 5%;
      padding-bottom: 5%;

      > p {
        font-size: 3.5vw;
        text-align: center;
      }
    }
  }
`;

export const DashboardWelcomeDepositWithdrawButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto 5% auto;
  > button {
    font-size: 1.2vw;
    font-weight: bold;
    color: ${whiteSphera};
    width: 45%;
    padding: 2% 0;
    border: none;
    border-radius: 5px;
    background-color: ${brandBlack};
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > button {
      &:hover {
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto 5% auto;
    > button {
      font-size: 4.2vw;
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      &:hover {
      }
    }
  }
`;
