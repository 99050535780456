import { useState } from 'react';
import {
  ProfessionalInfoContainer,
  DashboardWithdrawTable,
  DashboardButtonSection,
  StyledButton
} from './withdrawCredit.styles';
import CreditWithdrawModal from 'src/components/shared/modals/creditWithdrawSuccess/creditWithdrawSuccess';
import CreditPaymentModal from 'src/components/shared/modals/creditPayment/creditWithdrawSuccess';
import CreditUnsuccess from 'src/components/shared/modals/creditUnsuccess/creditUnsuccess';
import { Button } from 'src/utilities/UILibrary';

export default function WithdrawCredit({ personalInformation, bankInformation, obligadoInfo }) {
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showModalUnsuccess, setShowModalUnsuccess] = useState(false);
  const [isCredit, setIsCredit] = useState(true);
  const [isPayment, setIsPayment] = useState(false);
  const [isCampaingUnsuccess, setIsCampaingUnsuccess] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const dataInfo = personalInformation?.data?.getApplicant;
  const allCampaigns = dataInfo?.campaigns?.items;

  //Funciones para formatos en cálculos de fechas
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  function formatDate(dateString) {
    if (dateString === null) {
      return '0000-00-00';
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  function getElapsedDays(date) {
    const currentDate = new Date(Date.now());
    const startDate = new Date(date);
    const differenceInTime = currentDate - startDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays >= 0 ? differenceInDays : null;
  }
  function isFutureDate(dateEndCampaign) {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const endCampaignDate = new Date(dateEndCampaign);
    endCampaignDate.setHours(0, 0, 0, 0);
    return endCampaignDate > currentDate;
  }

  //Modales
  const openModal = (campaign) => {
    setSelectedCampaign(campaign);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const openModalUnsuccess = (campaign) => {
    setSelectedCampaign(campaign);
    setShowModalUnsuccess(true);
  };
  const closeModalUnsuccess = () => {
    setShowModalUnsuccess(false);
  };
  const openPaymentModal = (campaign) => {
    setSelectedCampaign(campaign);
    setShowPaymentModal(true);
  };
  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  return (
    <ProfessionalInfoContainer>
      <CreditWithdrawModal
        showModalCreditSuccess={showModal}
        closeModal={closeModal}
        campaignData={selectedCampaign}
        personalInformation={personalInformation}
        bankInformation={bankInformation}
      />
      <CreditPaymentModal
        showModalCreditSuccess={showPaymentModal}
        closeModal={closePaymentModal}
        campaignData={selectedCampaign}
        personalInformation={personalInformation}
        bankInformation={bankInformation}
      />
      <CreditUnsuccess
        showCreditUnsuccess={showModalUnsuccess}
        closeModal={closeModalUnsuccess}
        campaignData={selectedCampaign}
        personalInformation={personalInformation}
        bankInformation={bankInformation}
        obligadoInfo={obligadoInfo}
      />
      <DashboardButtonSection>
        <StyledButton
          selected={isCredit}
          onClick={() => {
            setIsCredit(true);
            setIsPayment(false);
            setIsCampaingUnsuccess(false);
          }}
        >
          Campañas
        </StyledButton>
        <StyledButton
          selected={isPayment}
          onClick={() => {
            setIsCredit(false);
            setIsPayment(true);
            setIsCampaingUnsuccess(false);
          }}
        >
          Ver Pagos
        </StyledButton>
        <StyledButton
          selected={isCampaingUnsuccess}
          onClick={() => {
            setIsCredit(false);
            setIsPayment(false);
            setIsCampaingUnsuccess(true);
          }}
        >
          Campañas no exitosas
        </StyledButton>
      </DashboardButtonSection>
      {isCampaingUnsuccess && (
        <>
          <DashboardWithdrawTable>
            <tbody>
              <tr>
                <th>Nombre</th>
                <th>Porcentaje reunido</th>
                <th>Monto solicitado</th>
                <th>Comisión con IVA del monto solicitado</th>
                <th>Monto recolectado</th>
                <th>Fecha cierre de campaña</th>
                <th>Operación transferencias</th>
              </tr>
              {dataInfo?.campaigns?.items
                .filter(
                  (campaign) =>
                    campaign.isMoneySent === '0' &&
                    campaign.isExpired === '1' &&
                    campaign.isSuccessful === '0'
                )
                .map((campaign, index) => (
                  <tr key={index}>
                    <td>{campaign?.nombreCampania}</td>
                    <td>
                      {Math.round((campaign?.collectedAmount / campaign?.montoSolicitado) * 100)} %
                    </td>
                    <td>
                      {campaign?.montoSolicitado ? formatCurrency(campaign?.montoSolicitado) : '-'}
                    </td>
                    <td>
                      {campaign?.comisionCreditoPesosConIVA
                        ? formatCurrency(campaign?.comisionCreditoPesosConIVA)
                        : '-'}
                    </td>
                    <td>
                      {campaign?.collectedAmount ? formatCurrency(campaign?.collectedAmount) : '-'}
                    </td>
                    <td>
                      {campaign?.dateEndCampaign ? formatDate(campaign?.dateEndCampaign) : '-'}
                    </td>
                    <td>
                      <Button
                        size="small"
                        // disabled={!(campaign.status === 'FIRMADO' && campaign.isMoneySent === '0')}
                        onClick={() => openModalUnsuccess(campaign)}
                      >
                        {campaign?.status === 'FIRMADO' && campaign.isMoneySent === '0'
                          ? 'Transferir'
                          : 'Detalle'}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </DashboardWithdrawTable>
          <h3>
            Recuerda que para hacer transferencias, debes de haber firmado previamente tus pagarés
            en la sección de contratos.
          </h3>
        </>
      )}
      {isCredit && (
        <>
          <DashboardWithdrawTable>
            <tbody>
              <tr>
                <th>Nombre</th>
                <th>Porcentaje reunido</th>
                <th>Monto solicitado</th>
                <th>Comisión con IVA del monto solicitado</th>
                <th>Monto recolectado</th>
                <th>Fecha cierre de campaña</th>
                <th>Operación transferencias</th>
              </tr>
              {dataInfo?.campaigns?.items
                .filter(
                  (campaign) =>
                    (campaign.isMoneySent === '0' && campaign.isExpired === '1') ||
                    campaign.isSuccessful === '1'
                )
                .map((campaign, index) => (
                  <tr key={index}>
                    <td>{campaign?.nombreCampania}</td>
                    <td>
                      {Math.round((campaign?.collectedAmount / campaign?.montoSolicitado) * 100)} %
                    </td>
                    <td>
                      {campaign?.montoSolicitado ? formatCurrency(campaign?.montoSolicitado) : '-'}
                    </td>
                    <td>
                      {campaign?.comisionCreditoPesosConIVA
                        ? formatCurrency(campaign?.comisionCreditoPesosConIVA)
                        : '-'}
                    </td>
                    <td>
                      {campaign?.collectedAmount ? formatCurrency(campaign?.collectedAmount) : '-'}
                    </td>
                    <td>
                      {campaign?.dateEndCampaign ? formatDate(campaign?.dateEndCampaign) : '-'}
                    </td>
                    <td>
                      <Button
                        size="small"
                        disabled={!(campaign.status === 'FIRMADO' && campaign.isMoneySent === '0')}
                        onClick={() => openModal(campaign)}
                      >
                        {campaign?.status === 'FIRMADO' && campaign.isMoneySent === '0'
                          ? 'Transferir'
                          : 'Transferido'}
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </DashboardWithdrawTable>
          <h3>
            Recuerda que para hacer transferencias, debes de haber firmado previamente tus pagarés
            en la sección de contratos.
          </h3>
        </>
      )}
      {isPayment && (
        <DashboardWithdrawTable>
          <tbody>
            <tr>
              <th>Nombre</th>
              <th>Monto del crédito</th>
              <th>Fecha inicial del crédito</th>
              <th>Plazo crédito</th>
              <th>Fecha límite de pago</th>
              <th>Días transcurridos de crédito</th>
              <th>Estatus crédito</th>
              <th></th>
            </tr>
            {allCampaigns.length > 0 ? (
              allCampaigns
                .filter((campaign) => campaign.isMoneySent === '1')
                .map((campaign, index) => {
                  const elapsedDays = getElapsedDays(campaign?.signDate);
                  return (
                    <tr key={index}>
                      <td>{campaign?.nombreCampania}</td>
                      <td> {formatCurrency(campaign?.collectedAmount)}</td>
                      <td>{formatDate(campaign?.signDate)}</td>
                      <td>{campaign?.plazoCredito}</td>
                      <td>{formatDate(campaign?.paymentDate)}</td>
                      <td>{elapsedDays !== null ? elapsedDays : '0'}</td>
                      <td>{campaign?.status === 'MOROSO' ? 'En mora' : 'Al corriente'}</td>
                      <td>
                        <Button
                          size="small"
                          onClick={() => openPaymentModal(campaign)}
                          disabled={campaign?.status === 'PAGADO_INVERSIONISTAS'}
                        >
                          {campaign?.status === 'PAGADO_INVERSIONISTAS' ? 'Pagado' : 'Pagar'}
                        </Button>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="7">
                  <h2>No tienes campañas cerradas</h2>
                </td>
              </tr>
            )}
          </tbody>
        </DashboardWithdrawTable>
      )}
    </ProfessionalInfoContainer>
  );
}
