const banks = [
  { name: 'BANXICO', code: 2001 },
  { name: 'BANCOMEXT', code: 37006 },
  { name: 'BANOBRAS', code: 37009 },
  { name: 'BANJERCITO', code: 37019 },
  { name: 'NAFIN', code: 37135 },
  { name: 'BABIEN', code: 37166 },
  { name: 'HIPOTECARIA FED', code: 37168 },
  { name: 'BANAMEX', code: 40002 },
  { name: 'BBVA MEXICO', code: 40012 },
  { name: 'SANTANDER', code: 40014 },
  { name: 'HSBC', code: 40021 },
  { name: 'BAJIO', code: 40030 },
  { name: 'INBURSA', code: 40036 },
  { name: 'MIFEL', code: 40042 },
  { name: 'SCOTIABANK', code: 40044 },
  { name: 'BANREGIO', code: 40058 },
  { name: 'INVEX', code: 40059 },
  { name: 'BANSI', code: 40060 },
  { name: 'AFIRME', code: 40062 },
  { name: 'BANORTE', code: 40072 },
  { name: 'BANK OF AMERICA', code: 40106 },
  { name: 'MUFG', code: 40108 },
  { name: 'JP MORGAN', code: 40110 },
  { name: 'BMONEX', code: 40112 },
  { name: 'VE POR MAS', code: 40113 },
  { name: 'CREDIT SUISSE', code: 40126 },
  { name: 'AZTECA', code: 40127 },
  { name: 'AUTOFIN', code: 40128 },
  { name: 'BARCLAYS', code: 40129 },
  { name: 'COMPARTAMOS', code: 40130 },
  { name: 'MULTIVA BANCO', code: 40132 },
  { name: 'ACTINVER', code: 40133 },
  { name: 'INTERCAM BANCO', code: 40136 },
  { name: 'BANCOPPEL', code: 40137 },
  { name: 'ABC CAPITAL', code: 40138 },
  { name: 'CONSUBANCO', code: 40140 },
  { name: 'VOLKSWAGEN', code: 40141 },
  { name: 'CIBANCO', code: 40143 },
  { name: 'BBASE', code: 40145 },
  { name: 'BANKAOOL', code: 40147 },
  { name: 'PAGATODO', code: 40148 },
  { name: 'INMOBILIARIO', code: 40150 },
  { name: 'DONDE', code: 40151 },
  { name: 'BANCREA', code: 40152 },
  { name: 'BANCO COVALTO', code: 40154 },
  { name: 'ICBC', code: 40155 },
  { name: 'SABADELL', code: 40156 },
  { name: 'SHINHAN', code: 40157 },
  { name: 'MIZUHO BANK', code: 40158 },
  { name: 'BANK OF CHINA', code: 40159 },
  { name: 'BANCO S3', code: 40160 },
  { name: 'MONEXCB', code: 90600 },
  { name: 'GBM', code: 90601 },
  { name: 'MASARI', code: 90602 },
  { name: 'VALUE', code: 90605 },
  { name: 'VECTOR', code: 90608 },
  { name: 'MULTIVA CBOLSA', code: 90613 },
  { name: 'FINAMEX', code: 90616 },
  { name: 'VALMEX', code: 90617 },
  { name: 'PROFUTURO', code: 90620 },
  { name: 'CB INTERCAM', code: 90630 },
  { name: 'CI BOLSA', code: 90631 },
  { name: 'FINCOMUN', code: 90634 },
  { name: 'NU MEXICO', code: 90638 },
  { name: 'REFORMA', code: 90642 },
  { name: 'STP', code: 90646 },
  { name: 'EVERCORE', code: 90648 },
  { name: 'CREDICAPITAL', code: 90652 },
  { name: 'KUSPIT', code: 90653 },
  { name: 'UNAGRA', code: 90656 },
  { name: 'ASP INTEGRA OPC', code: 90659 },
  { name: 'ALTERNATIVOS', code: 90661 },
  { name: 'LIBERTAD', code: 90670 },
  { name: 'CAJA POP MEXICA', code: 90677 },
  { name: 'CRISTOBAL COLON', code: 90680 },
  { name: 'CAJA TELEFONIST', code: 90683 },
  { name: 'OPM', code: 90684 },
  { name: 'FONDO (FIRA)', code: 90685 },
  { name: 'INVERCAP', code: 90686 },
  { name: 'FOMPED', code: 90689 },
  { name: 'TESORED', code: 90703 },
  { name: 'NVIO', code: 90710 },
  { name: 'INDEVAL', code: 90902 },
  { name: 'CoDi Valida', code: 90903 },
  { name: 'BBVA BANCOMER2*', code: 91812 },
  { name: 'SANTANDER2*', code: 91814 },
  { name: 'HSBC2*', code: 91821 },
  { name: 'AZTECA2*', code: 91927 },
  { name: 'BANORTE2*', code: 91872 },
  { name: 'ARCUS', code: 90706 },
  { name: 'NVIO', code: 90710 },
  { name: 'BANAMEX2', code: 91802 }
];
export default banks;
