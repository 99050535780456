import { FC, ReactNode } from 'react';
import Modal from 'src/utilities/UILibrary/Feedback/Modal/Modal';
import { ModalProps } from 'src/utilities/UILibrary/Feedback/Modal/modal.interface';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { ComposedModalWrapper } from './composedModal.styles';

type ButtonsProps = {
  label: string;
  onClick?: () => void;
  color?: 'primary' | 'secondary' | 'green';
  isFullWidth?: boolean;
}[];

type ComposedModal = {
  message?: string;
  messageTitle?: string;
  messageChildren?: ReactNode;
  buttons?: ButtonsProps;
  type: 'error' | 'success' | 'warning' | 'none';
} & Omit<ModalProps, 'children'>;

const genericMessagesMap = {
  error: {
    message: 'La operación no se pudo completar. Por favor, intenta nuevamente.',
    title: '¡Error!'
  },
  success: {
    message: 'La operación se completó con éxito. Gracias por tu paciencia.',
    title: '¡Éxito!'
  },
  warning: {
    message:
      'La operación se completó, pero hay algunos aspectos que podrían necesitar tu atención.',
    title: '¡Advertencia!'
  },
  none: {
    message: 'La operación se completó con éxito. Gracias por tu paciencia.',
    title: '¡Éxito!'
  }
};

const typeToUrlMap = {
  error: 'https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png',
  success: 'https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success.png',
  warning: 'https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_warning.png',
  none: 'https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success.png'
};

export const ComposedModal: FC<ComposedModal> = (props) => {
  const {
    type,
    buttons,
    message = genericMessagesMap[type]?.message,
    messageChildren,
    messageTitle = genericMessagesMap[type]?.title,
    ...restProps
  } = props;
  const genericSetOfButtons: ButtonsProps = [
    {
      label: 'Cerrar',
      onClick: restProps.onClose
    }
  ];

  const imgSrc = typeToUrlMap[type] || '';

  return (
    <ComposedModalWrapper {...restProps}>
      <Modal.Header className="modal-header" isCentered>
        {type !== 'none' ? (
          <figure>
            <img src={imgSrc} alt="icono de aviso" />
          </figure>
        ) : null}
        <h3>{messageTitle}</h3>
      </Modal.Header>
      <Modal.Body isCentered>
        {messageChildren || type === 'none'
          ? null
          : message.split('\\n\\n').map((paragraph, index) => <p key={index}>{paragraph}</p>)}
        {messageChildren ? messageChildren : null}
      </Modal.Body>
      <Modal.Footer>
        {buttons?.map((button) => (
          <Button
            key={button.label}
            size="small"
            color={button.color}
            isFullWidth={button.isFullWidth}
            onClick={button.onClick ?? restProps.onClose}
          >
            {button.label}
          </Button>
        ))}
        {!buttons?.length &&
          genericSetOfButtons.map((button) => (
            <Button key={button.label} size="small" onClick={button.onClick}>
              {button.label}
            </Button>
          ))}
      </Modal.Footer>
    </ComposedModalWrapper>
  );
};
