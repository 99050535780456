//Main imports
import { useState, FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { SignupContainer, SignupInfo, SignupForm } from './signupApplicant.styles';
import { openSucessModal, setLoadingState, signUpApplicant } from 'src/store/User/user.slice';
import { errorHandler } from 'src/middlewares/Auth/index.utilities';
import { RootState } from 'src/store/store';
import { useAppThunkDispatch } from 'src/store/store';

//External Components
import ModalSignupSuccess from 'src/components/shared/modals/signupSuccessApplicant/signupSuccess';
import PasswordValid from 'src/components/shared/PasswordValidator/PasswordValid';
import { Link } from 'react-router-dom';
import { ErrorObject } from 'src/store/User/user.interface';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

const SignupApplicant = () => {
  const dispatch = useAppThunkDispatch();
  const showSuccessModal = useSelector((state: RootState) => state.user.showSuccessModal);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [type, setType] = useState('password');

  const onSignUpRequest = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      errorHandler(dispatch, { code: 'passwordsNotEqual' });
      return;
    }
    if (!acceptTerms) {
      errorHandler(dispatch, { code: 'acceptTerms' });
      return;
    }
    if (!email || !password || !confirmPassword) {
      errorHandler(dispatch, { code: 'allDataRequired' });
      return;
    }
    try {
      dispatch(setLoadingState(true));
      await dispatch(
        signUpApplicant({
          username: email,
          password: password
        })
      ).unwrap();
      dispatch(openSucessModal());
    } catch (error: unknown) {
      if (isErrorObject(error)) {
        errorHandler(dispatch, error);
      } else {
        console.error(error);
      }
    } finally {
      dispatch(setLoadingState(false));
    }

    function isErrorObject(error: unknown): error is ErrorObject {
      return typeof error === 'object' && error !== null && 'code' in error;
    }
  };

  return (
    <SignupContainer>
      <ModalSignupSuccess email={email} showSuccessModal={showSuccessModal} />
      <SignupInfo>
        <h2>
          La opción <span>más rentable</span> para invertir tu dinero
        </h2>
        <p>Invierte en PyMEs mexicanas comprometidas</p>
      </SignupInfo>
      <SignupForm>
        <form onSubmit={onSignUpRequest}>
          <h3>Regístrate | Solicitante</h3>
          <input
            type="email"
            name="email"
            placeholder="Correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div>
            <input
              type={type}
              placeholder="Ingresa contraseña"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {type === 'password' ? (
              <figure onClick={() => setType('text')}>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/iconoUnlockPassword.png`}
                  alt="icono mostrar contraseña"
                />
              </figure>
            ) : (
              <figure onClick={() => setType('password')}>
                <img
                  src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/Eye-Open.png`}
                  alt="icono mostrar contraseña"
                />
              </figure>
            )}
          </div>
          <div>
            <input
              type={type}
              name="confirmPassword"
              placeholder="Confirmar contraseña"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <PasswordValid
            password={password}
            onChangePassword={(value) => setIsPasswordValid(value)}
          />
          <div>
            <input
              type="checkbox"
              name="acceptTermsAndPrivacy"
              onChange={() => setAcceptTerms((acceptTerms) => !acceptTerms)}
            />
            <p>
              He leído y estoy de acuerdo con los{' '}
              <Link to="/terminos-y-condiciones">términos y condiciones</Link> y{' '}
              <Link to="/aviso-de-privacidad">políticas de privacidad del sitio</Link>.
            </p>
          </div>
          <Button isSlim type="submit" disabled={!isPasswordValid}>
            Regístrate
          </Button>
          <p>¿Ya tienes tu cuenta en FinSphera?</p>
          <Link to="/iniciar-sesion">Inicia sesión ahora</Link>
        </form>
      </SignupForm>
    </SignupContainer>
  );
};

export default SignupApplicant;
