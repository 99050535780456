export const blockedCountriesInEnglish = [
  'Afghanistan',
  'Balkans',
  'Belarus',
  'Burma',
  'Burundi',
  'Central African Republic',
  'Cuba',
  'Democratic Republic of the Congo',
  'Ethiopia',
  'Hong Kong',
  'Iran',
  'Iraq',
  'Lebanon',
  'Libya',
  'Mali',
  'Nicaragua',
  'North Korea',
  'Russia',
  'Somalia',
  'Sudan',
  'Syria',
  'Ukraine',
  'Venezuela',
  'Yemen',
  'Zimbabwe'
];

export const blockedCountriesInSpanish = [
  'Afganistán',
  'Bielorrusia',
  'Burundi',
  'República Centroafricana',
  'Cuba',
  'Etiopía',
  'Congo',
  'Libia',
  'Hong Kong',
  'Irán',
  'Irak',
  'Líbano',
  'Mali',
  'Nicaragua',
  'Corea del Norte',
  'Rusia',
  'Somalia',
  'Sudán del Sur',
  'Siria',
  'Ucrania',
  'Venezuela',
  'Yemen',
  'Zimbabue'
];
