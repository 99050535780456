import { Link } from 'react-router-dom';
import {
  AboutUsContainer,
  AboutUsVideo,
  FintechSection,
  ImpactSection,
  OurPartners,
  OurPartnersCardCont,
  OurPartnersCard,
  OurPartnerFigureAfinco,
  OurPartnerFigureAii,
  OurPartnerFigureMef,
  OurPartnerFigureBid,
  OurPartnerFigurePactoGlobal,
  OurPartnerFigureFintech,
  DevelopmentGoals,
  DevelopmentGoalsImage,
  DevelopmentGoalsInfo,
  DevelopmentGoalsLogoONU,
  DevelopmentGoalsLogos589,
  DevelopmentGoalsLogos131415,
  Suma,
  OurValueOfferTitle,
  Pymes,
  PymesTitle,
  PymesInfo,
  PymesInvestorsSection,
  Investors,
  InvestorsTitle,
  InvestorsInfo
} from './aboutUs.styles';
import { Banner } from 'src/utilities/UILibrary/Media/Banner/Banner';

export default function aboutUs() {
  return (
    <AboutUsContainer>
      <Banner
        imgSrc="https://finsphera-webapp-resources.s3.amazonaws.com/img_aboutUs.jpg"
        isImgMobile
      >
        <h1>
          Comprometidos con impactar positivamente en nuestra comunidad y en nuestro medio ambiente.
        </h1>
      </Banner>
      <AboutUsVideo>
        <div>
          <p>
            Simplificamos los procesos de inversión para poder apoyar proyectos que{' '}
            <span>impacten positivamente en nuestra comunidad y en nuestro medio ambiente</span> al
            mismo tiempo que generan atractivos rendimientos para nuestros socios inversionistas.
          </p>
          <p>
            Únete a nuestra comunidad de inversionistas para recibir atractivos rendimientos
            mientras mejoramos nuestro mundo.
          </p>
        </div>
      </AboutUsVideo>
      <FintechSection>
        <div>
          <h3>
            Somos una <span>Fintech</span>{' '}
          </h3>
          <p>Porque prestamos servicios financieros utilizando lo último en tecnología.</p>
          <p>
            Somos una plataforma de <span>Crowdfunding </span>
            también conocida como Institución de
            <span> Financiamiento Colectivo de Deuda</span> ya que ponemos en contacto a las mejores
            Pymes con inversionistas como tú, que quieres ganar atractivos rendimientos y al mismo
            tiempo realizar un Impacto positivo en nuestro Medio Ambiente y Sociedad.
          </p>
        </div>
        <figure>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/Fintech_AboutUs.png"
            alt=""
          />
        </figure>
      </FintechSection>
      <ImpactSection>
        <div>
          <h3>
            Somos una <span>empresa de impacto</span>{' '}
          </h3>
          <p>
            Porque hemos implementado internamente principios Ambientales, Sociales y de Buen
            Gobierno interno (ASG por sus siglas en español o ESG por sus siglas en inglés). Nuestro
            modelo de negocios fomenta que las Pequeñas y Medianas empresas también implementen
            buenas prácticas ASG porque mientras más mejoren en estos temas, obtendrán créditos a
            una tasa de interés cada vez más baja a través de nuestra plataforma..
          </p>
        </div>
        <figure>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/Impact_AboutUs.png"
            alt=""
          />
        </figure>
      </ImpactSection>
      <OurValueOfferTitle>
        <h2>Nuestra oferta de valor</h2>
      </OurValueOfferTitle>
      <PymesInvestorsSection>
        <Pymes>
          <PymesTitle>
            <h2>Pymes</h2>
            <h3>Banco</h3>
            <figure>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/FinspheraLogo_Text.png"
                alt=""
              />
            </figure>
          </PymesTitle>
          <PymesInfo>
            <div>
              <p>Tasa de interés anual que paga por tomar crédito a 3 meses</p>
              <h4>27%</h4>
              <h3>15%</h3>
            </div>
            <div>
              <p>Comisión por utilizar la línea de crédito.</p>
              <h4>24%</h4>
              <h3>12%</h3>
            </div>
            <div>
              <p>Total</p>
              <h4>51%</h4>
              <h3>27%</h3>
            </div>
          </PymesInfo>
          <p>
            Por cada Mx $100 Millones financiados por Impact Factor las Pymes se ahorran $24
            Millones al año.
          </p>
        </Pymes>
        <Investors>
          <InvestorsTitle>
            <h2>Inversionistas</h2>
            <h3>Banco</h3>
            <figure>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/FinspheraLogo_Text.png"
                alt=""
              />
            </figure>
          </InvestorsTitle>
          <InvestorsInfo>
            <div>
              <p>Tasa de interés anual que reciben por invertir a plazos de 3 meses</p>
              <h4>3%</h4>
              <h3>15%</h3>
            </div>
          </InvestorsInfo>
          <p>
            Por cada Mx $100 Millones que inviertan recibirán en Finsphera Mx $12 Millones más de
            interés al año.
          </p>
          <p>
            En resumen por cada Mx $100 Millones Finsphera estará impactando positivamente a Pymes y
            ahorradores en Mx $36 Millones en México.
          </p>
        </Investors>
      </PymesInvestorsSection>
      <OurPartners>
        <h3>Nuestros aliados</h3>
        <OurPartnersCardCont>
          <OurPartnersCard>
            <OurPartnerFigureAfinco>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersAfinco.png"
                alt=""
              />
            </OurPartnerFigureAfinco>
            <p>
              Somos miembros de la Asociación que agrupa en México a las Plataformas de
              Crowdfunding: la Asociación de Plataformas de Financiamiento Colectivo AFICO. La
              Asociación nos representa como gremio ante terceros como son las Autoridades
              Financieras, Medios de Comunicación, Proveedores y la Comunidad en general.
            </p>
          </OurPartnersCard>
          <OurPartnersCard>
            <OurPartnerFigureAii>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersAii.png"
                alt=""
              />
            </OurPartnerFigureAii>
            <p>
              Somos miembros de la Alianza por la Inversión de Impacto en México AII quién es el
              capítulo para México del Global Steering Group for Impact Investment GSG. Formamos
              parte de la AII porque somos una empresa que buscamos impulsar la agenda de Inversión
              de Impacto en México para contribuir a resolver problemas Sociales y Medioambientales.
            </p>
          </OurPartnersCard>
          <OurPartnersCard>
            <OurPartnerFigureMef>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersMef.jpg"
                alt=""
              />
            </OurPartnerFigureMef>
            <p>
              Somos Aliados de Mujeres en Finanzas quienes promueven la participación de las mujeres
              en el mundo financiero para posiciones de liderazgo para que logren un rol más activo
              en la economía y los negocios.
            </p>
          </OurPartnersCard>
          <OurPartnersCard>
            <OurPartnerFigureBid>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_home_genderEquality.jpg"
                alt="Logotipo de equidad de genero"
              />
            </OurPartnerFigureBid>
            <p>
              Somos parte de esta Aceleradora para empresas participantes del Pacto Mundial que
              buscan implementar y promover los Principios de Equidad de Género.
            </p>
          </OurPartnersCard>
          <OurPartnersCard>
            <OurPartnerFigureFintech>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersFintech.png"
                alt=""
              />
            </OurPartnerFigureFintech>
            <p>
              Tenemos una alianza con FintechU, la primera institución educativa en Latinoamérica
              enfocada al 100% en el ecosistema Fintech. Esto nos permite desarrollar cursos para
              nuestro usuarios y tener altos niveles de preparación de nuestros empleados.
            </p>
          </OurPartnersCard>
          {/* <OurPartnersCard>
              <OurPartnerFigureCcmx>
                <img src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersCcmx.png" alt="" />
              </OurPartnerFigureCcmx>
              <p>
               El Centro de Competitividad de México CCMX es la
               iniciativa del Consejo Mexicano de Negocios que
               impulsa la productividad de las Pymes y fortalece la
               cadena de valor de las Grandes Empresas que integran el
               Consejo Mexicano de Negocios CMN.
               Nosotros tenemos un convenio de colaboración con el CCMX
               para facilitar el crecimiento y la productividad de
               las Pymes en México.
              </p>
            </OurPartnersCard> */}
          <OurPartnersCard>
            <OurPartnerFigurePactoGlobal>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/OurPartnersPactoGlobal.png"
                alt=""
              />
            </OurPartnerFigurePactoGlobal>
            <p>
              El Pacto Global de la Organización de Naciones Unidas es un llamado a las empresas a
              alinear sus estrategias y operaciones con diez principios universales sobre Derechos
              Humanos, Estándares Laborales, Medio Ambiente y Anticorrupción y a tomar medidas que
              promuevan los Objetivos de Desarrollo Sostenible. En Finsphera estamos adheridos a
              esta iniciativa porque nos identificamos con sus objetivos.
            </p>
          </OurPartnersCard>
        </OurPartnersCardCont>
        <DevelopmentGoals>
          <DevelopmentGoalsImage>
            <Link
              to="https://www.un.org/sustainabledevelopment/es/objetivos-de-desarrollo-sostenible/"
              target="_blank"
              rel="noreferrer"
            >
              <DevelopmentGoalsLogoONU>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoOnu.png"
                  alt="Logo ONU"
                />
              </DevelopmentGoalsLogoONU>
              <DevelopmentGoalsLogos589>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoIgualdadDeGenero.png"
                  alt="Logo Igualdad De Genero"
                />
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoTrabajoDecenteCrecimientoEconomico.png"
                  alt="Logo Trabajo Decente Crecimiento Economico"
                />
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoIndustriaInnovacionInfraestructura.png"
                  alt="Logo Industria Innovacion Infraestructura"
                />
              </DevelopmentGoalsLogos589>
              <DevelopmentGoalsLogos131415>
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoAccionPorElClima.png"
                  alt="Logo Accion Por El Clima"
                />
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoVidaSubmarina.png"
                  alt="Logo Vida Submarina"
                />
                <img
                  src="https://finsphera-webapp-resources.s3.amazonaws.com/logoVidaDeEcosistemasTerrestres.png"
                  alt="Logo Vida De Ecosistemas Terrestres"
                />
              </DevelopmentGoalsLogos131415>
            </Link>
          </DevelopmentGoalsImage>
          <DevelopmentGoalsInfo>
            <p>
              Los 17 Objetivos de Desarrollo Sostenible ODS se adoptaron por todos los países
              miembros de la Organización de las Naciones Unidas ONU en 2015 como un llamado
              universal para poner fin a la pobreza, proteger el planeta y garantizar que todas las
              personas gocen de paz y prosperidad para 2030.
            </p>
            <p>
              Gracias a ti que inviertes en Finsphera estás colaborando para cumplir 6 de los 17
              objetivos: Igualdad de Género, Trabajo Decente y Crecimiento Económico, Industria,
              Innovación e Infraestructura, Acción por el Agua, Vida Submarina y Vida de Ecosistemas
              Terrestres.
            </p>
          </DevelopmentGoalsInfo>
        </DevelopmentGoals>
        <Suma>
          <Link
            to="https://finsphera-webapp-resources.s3.amazonaws.com/suma_boletin.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <figure>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/logoSuma.png"
                alt="Logo suma"
              />
            </figure>
          </Link>
          <div>
            <p>
              Somos graduados del proyecto SUMA creado por New Ventures y Fundación MetLife que
              tiene como objetivo fomentar el uso de estrategias basadas en ciencias del
              comportamiento para mejorar la salud financierade personas con bajos ingresos. New
              Ventures brinda inversión y promoción de impacto para el desarrollo de empresas
              sociales y ambientales en etapas tempranas, apoyo estratégico a través de programas de
              aceleración y acompaña a inversionistas interesados en invertir en empresas que
              cumplen con criterios Ambientales, Sociales y de buen Gobierno corporativo ASG.
              Fundación MetLife proporciona subvenciones a organizaciones que se dediquen a promover
              la inclusión financiera para ayudar a construir un futuro seguro para las personas y
              las comunidades en todo el mundo.
            </p>
          </div>
        </Suma>
      </OurPartners>
    </AboutUsContainer>
  );
}
