import { useEffect, useState } from 'react';
import {
  ModalEditPassword,
  Success,
  Figure,
  ActionSection,
  ErrorDisplay
} from './changePassword.styles';
import { Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import PasswordValid from 'src/components/shared/PasswordValidator/PasswordValid';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { FormValues, ChangePasswordProps } from './changePassword.types';
import { RootState } from 'src/store/store';

export default function ChangePassword({ passwordModal, setPasswordModal }: ChangePasswordProps) {
  const [successText, setSuccessText] = useState(false);
  //
  const [codeSended, setCodeSended] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const { control, handleSubmit, watch, setError } = useForm<FormValues>();
  const user = useSelector((state: RootState) => state.user.currentUser);

  useEffect(() => {
    if (localError) setErrorText(true);
    if (!localError) setErrorText(false);
  }, [localError]);
  const watchedPassword = watch('password');
  const closeModal = () => {
    setPasswordModal(false);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (data.password !== data.confirmPassword || !isPasswordValid) {
      setError('confirmPassword', {
        type: 'manual',
        message: !isPasswordValid ? 'La contraseña no es válida' : 'Las contraseñas no coinciden'
      });
      return;
    }
    if (!codeSended) {
      setLocalLoading(true);
      try {
        await Auth.forgotPassword(user.email);
        setCodeSended(true);
        setLocalLoading(false);
        return;
      } catch (error) {
        setLocalError('Ocurrió un error, por favor intenta de nuevo');
        setLocalLoading(false);
        return;
      }
    }
    if (codeSended) {
      setLocalLoading(true);
      const emailCode = data.emailCode.toString();
      try {
        await Auth.forgotPasswordSubmit(user.email, emailCode, data.password);
        const date = new Date();
        date.setMonth(date.getMonth() + 5);
        document.cookie = `passwordUpdated=true; expires=${date.toUTCString()}; path=/inversionista/dashboard/account`;
        setSuccessText(true);
      } catch (error) {
        (error as Error).message.includes('Invalid verification code provided, please try again.')
          ? setLocalError('El código ingresado no es válido')
          : setLocalError('Ocurrió un error, por favor intenta de nuevo');
      } finally {
        setLocalLoading(false);
      }
    }
  };

  return (
    <ModalEditPassword passwordModal={passwordModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu contraseña se ha actualizado de forma exitosa!</h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <ActionSection>
            <Card className="landing-card" isSlim isForm>
              <Card.Row>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputField
                      type="password"
                      label="Ingresa tu nueva contraseña:"
                      placeholder="Contraseña"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
              </Card.Row>
              <Card.Row>
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputField
                      type="password"
                      label="Confirma tu nueva contraseña:"
                      placeholder="Contraseña"
                      onChangeInternalValue={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
              </Card.Row>
              {codeSended ? (
                <>
                  <Card.Row>
                    <Controller
                      name="emailCode"
                      control={control}
                      rules={{ required: true }}
                      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                        <InputField
                          label="Ingresa el código que enviamos a tu correo:"
                          placeholder="Código"
                          onChangeInternalValue={onChange}
                          errorMessages={error && error.message ? [error.message] : []}
                          type="number"
                          {...rest}
                        />
                      )}
                    />
                  </Card.Row>
                </>
              ) : null}
              <Card.Row>
                {watchedPassword ? (
                  <Card.Row>
                    <p>
                      <strong>
                        Recuerda que tu nueva contraseña debe seguir las siguientes reglas de
                        seguridad para transacciones en <span>FinSphera</span>:
                      </strong>
                    </p>
                    <PasswordValid
                      password={watchedPassword}
                      onChangePassword={(value) => setIsPasswordValid(value)}
                    />
                  </Card.Row>
                ) : null}
                <Button
                  isFullWidth
                  size="small"
                  onClick={handleSubmit(onSubmit)}
                  isLoading={localLoading}
                >
                  Actualizar contraseña
                </Button>
              </Card.Row>
              {errorText && <ErrorDisplay>{localError}</ErrorDisplay>}
            </Card>
          </ActionSection>
        </div>
      )}
    </ModalEditPassword>
  );
}
