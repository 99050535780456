import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  CampaignInvoicesContainer,
  Banner,
  CreateCampaign,
  FormDataTitle,
  NavigationSection,
  SolidaryData
} from './jointlyObligated.styles';
import { url } from 'src/environments';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import ConfirmCampaignModal from 'src/components/shared/modals/CampaignSuccess/campaignSuccess';
import { createCampaignSlice, setCalculatedCampaignData } from 'src/store/Campaigns/campaign.slice';
import { errorHandler } from 'src/middlewares/Auth/index.utilities';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import getCurrentDate from 'src/functions/getCurrentDate';
import { createFinspheraInvestment } from 'src/store/Profile/profile.slice';

export default function JointlyObligated() {
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorType, setErrorType] = useState('');
  const [correctData, setCorrectData] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);
  const [showSolidaryData, setShowSolidaryData] = useState(true);
  const [profit, setProfit] = useState(0);
  const [investment, setInvestment] = useState(0);
  const [interesInversionistaPesos, setInteresInversionistaPesos] = useState(0);
  const [retencionImpuestoInversionistaPesos, setRetencionImpuestoInversionistaPesos] = useState(0);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [obligadoInformation, setObligadoInformation] = useState([]);
  const [selectedObligado, setSelectedObligado] = useState({});
  const [uploadStatus, setUploadStatus] = useState('pending');
  const calculatedCampaignData = useSelector((state) => state.campaign.calculatedCampaignData);
  const finspheraInvestment = calculatedCampaignData?.inversionFinspheraCampaniaPesos;
  const campaignId = calculatedCampaignData?.id;
  const {
    formState: { errors }
  } = useForm();

  const body = {
    type: 'getAllObligadoSolidario',
    applicantId: user.id
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const request = await axios.post(`${url}/admin/queries/get-all`, body);
        const parsedBody = JSON.parse(request.data.body);
        const response = parsedBody.result;
        setObligadoInformation(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, [user.id, url]);

  const obligadoOptions = obligadoInformation
    .filter((info) => info.isPLDApproved === '1')
    .map((info) => ({
      label: info.personalInformation.fullName,
      value: info.id
    }));
  const handleDropdownChange = (selectedValue) => {
    const selectedOption = obligadoOptions.find((option) => option.value === selectedValue);
    setSelectedObligado(selectedOption || {});
    const updatedCampaignData = {
      ...calculatedCampaignData,
      obligadoSolidarioId: selectedOption ? selectedOption.value : null
    };
    dispatch(setCalculatedCampaignData(updatedCampaignData));
  };
  const handleNextEnabled = selectedObligado && correctData === 'yes';

  const handleRadioChange = (value) => {
    setCorrectData(value);
  };

  const handleBack = () => {
    navigate(`/solicitante/dashboard/cargar-facturas`);
  };
  const modalErrorMessages = {
    error1: 'Mensaje aquí 1'
  };

  const calculateProfit = () => {
    const newInteresInversionistaPesos =
      Number(calculatedCampaignData?.tasaCreditoPorcentaje / 360) *
      calculatedCampaignData?.plazoCredito *
      finspheraInvestment;
    setInteresInversionistaPesos(newInteresInversionistaPesos);
    const newRetencionImpuestoInversionistaPesos = newInteresInversionistaPesos * 0.02;
    setRetencionImpuestoInversionistaPesos(newRetencionImpuestoInversionistaPesos);
    const result = newInteresInversionistaPesos + Number(finspheraInvestment);
    setProfit(result);
    setInvestment('');
  };

  const handleConfirmCampaign = async (data) => {
    const campaignInput = calculatedCampaignData;
    dispatch(createCampaignSlice({ input: campaignInput }));
    const investment = {
      investorId: 'fin782a-65cb-4697-a870-5f68548e1111',
      campaignId: campaignId,
      compromisoInversion: parseInt(finspheraInvestment),
      interesInversionistaPorcentaje: calculatedCampaignData?.tasaCreditoPorcentaje,
      interesInversionistaPesos: interesInversionistaPesos,
      retencionImpuestoInversionistaPorcentaje: 0.02,
      retencionImpuestoInversionistaPesos: retencionImpuestoInversionistaPesos,
      porcentajeGananciaInteres: 0.01,
      createdAt: getCurrentDate(),
      updatedAt: getCurrentDate()
    };
    const resources = {
      clientId: 'fin782a-65cb-4697-a870-5f68548e1111',
      amount: parseInt(finspheraInvestment),
      type: 'compromisoinversion',
      campaignId: campaignId
    };
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/update-investor-balances-in-STP`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: resources
    };
    try {
      const response = await axios(config);
      if (
        response.data.status === 201 &&
        response.data.message === 'Investor amounts updated correctly'
      ) {
        dispatch(createFinspheraInvestment(investment));
        await navigate(`/solicitante/dashboard/campañas`);
      } else {
        console.error('Request completed, but with a non-successful status:', response);
      }
    } catch (error) {
      console.error('Request failed:', error);
    }
    setShowModal(false);
    setLocalLoading(false);
  };

  //Buttons logic
  const handleNext = async () => {
    try {
      setShowModal(true);
      calculateProfit();
    } catch (error) {
      console.error(error);
      dispatch(errorHandler);
      setLocalLoading(false);
    }
  };

  return (
    <CreateCampaign>
      <ConfirmCampaignModal
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmCampaign}
        campaignData={calculatedCampaignData}
        selectedObligado={selectedObligado.label}
        showModal={showModal}
      />
      <ComposedModal
        isOpen={showErrorModal}
        message={modalErrorMessages[errorType] || modalErrorMessages.generic}
        type="error"
        onClose={() => setShowErrorModal(false)}
      />
      <Banner>
        <div>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_companyInformation_banner.png`}
              alt="icono informacion personal"
            />
          </figure>
          <h2>Crear campaña</h2>
        </div>
      </Banner>
      <CampaignInvoicesContainer>
        <FormDataTitle>Información del Obligado Solidario: </FormDataTitle>
        <p>Como último paso, selecciona el nombre del obligado solidario para esta campaña:</p>
        {showSolidaryData && (
          <SolidaryData>
            <InputField
              onChangeInternalValue={(selectedValue) => {
                handleDropdownChange(selectedValue);
              }}
              isDropdown
              placeholder="Selecciona una opción"
              options={obligadoOptions}
            />
            {selectedObligado && (
              <>
                <p>¿Es correcta la opción seleccionada?</p>
                <div>
                  <label>
                    <input
                      type="radio"
                      value="yes"
                      checked={correctData === 'yes'}
                      onChange={() => handleRadioChange('yes')}
                    />
                    Sí
                  </label>
                  <label>
                    <input
                      type="radio"
                      value="no"
                      checked={correctData === 'no'}
                      onChange={() => handleRadioChange('no')}
                    />
                    No
                  </label>
                </div>
              </>
            )}
          </SolidaryData>
        )}
      </CampaignInvoicesContainer>
      <NavigationSection>
        <Button onClick={handleBack}>Regresar</Button>
        <Button onClick={handleNext} isLoading={localLoading} disabled={!handleNextEnabled}>
          {uploadStatus === 'completed' ? 'Siguiente' : 'Siguiente'}
        </Button>
      </NavigationSection>
    </CreateCampaign>
  );
}
