import styled from 'styled-components/macro';
import {
  graySphera,
  whiteSphera,
  brandBlack,
  blackSphera
} from 'src/utilities/UIStyles/variables/colors';
import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { ExperiencedInvestorProps } from './experiencedInvestor.types';

export const ModalExperienced = styled.section<ExperiencedInvestorProps>`
  min-width: 16.875rem;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  padding: 3rem 1.5rem;
  overflow: scroll;
  background-color: rgb(0, 0, 0, 0.5);
  visibility: ${(props) => (props.experiencedModal ? 'visible' : 'hidden')};
  animation: ${(props) => (props.experiencedModal ? fadeIn : fadeOut)} 0.3s linear forwards;
  > div {
    top: -2;
    left: 0;
    width: 80%;
    margin: 0 10%;
    > div {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      padding-bottom: 1.5%;
    }
  }
`;

export const Figure = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  > div {
    width: 90%;
  }
  > figure {
    width: 30px;
    height: 30px;
    cursor: pointer;
    > img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ActionSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  .card-row {
    display: flex;
    flex-wrap: wrap;
    > h2 {
      width: 90%;
    }
    > p {
      width: 90%;
      margin: 0 5%;
      margin-bottom: 5%;
      color: ${graySphera};
      font-weight: bold;
      font-size: ${uIFontSize.medium};
      > span {
        font-weight: bold;
        color: ${blackSphera};
      }
    }
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 2% 0;
      > h3 {
        text-align: center;
        width: 100%;
      }
    }
  }
  > form {
    Button {
      margin-top: 5%;
    }
  }
`;

export const ButtonsSection = styled.div`
  width: 100%;
  padding: 2% 5%;
  display: flex;
  justify-content: space-evenly;
  > button {
    width: 40%;
  }
`;
export const Success = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: auto;
  padding: 10% 0;
  border-radius: 5px;
  background-color: ${whiteSphera};
  > figure {
    width: 150px;
    margin: 0 auto 5% auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${brandBlack};
    font-size: 1.5vw;
    width: 100%;
    text-align: center;
    margin: 5% 0;
  }
`;
