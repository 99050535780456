const economicActivitiesGeneral = [
  'Agropecuario silvicultura y pesca',
  'Comercio, restaurantes y hoteles',
  'Construcción',
  'Electricidad, gas y agua potable',
  'Industria manufacturera',
  'Mineria',
  'Politicamente expuestos',
  'Servicios comunales, sociales y personales',
  'Servicios financieros, seguros y bienes inmuebles',
  'Transporte, almacenamiento y comunicaciones',
  'Otro'
];

export default economicActivitiesGeneral;
