import React, { useState, useEffect } from 'react';

import {
  ModalDetailInvestmentB,
  DetailInvestmentBInfo,
  DetailInvestmentBForm,
  DetailInvestmentBGetCode,
  DetailInvestmentAdvices
} from './detailInvestmentB.styles';

export default function DetailInvestmentB(props) {
  const [detailInvestmentB, setDetailInvestmentB] = useState(props.showModalDetailInvestmentB);
  const [available, setAvailable] = useState(false);
  const [codeProvided, setCodeProvided] = useState(true);

  useEffect(() => {
    setDetailInvestmentB(props.showModalDetailInvestmentB);
  }, [props.showModalDetailInvestmentB]);

  const closeModal = () => setDetailInvestmentB(false);

  return (
    <ModalDetailInvestmentB showModalDetailInvestmentB={detailInvestmentB}>
      <div>
        <div>
          <h3>Detalle de tu inversión</h3>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </div>
        <DetailInvestmentBInfo>
          <div>
            <h3>Amortización</h3>
            <h3>Capital</h3>
            <h3>Interés</h3>
            <h3>Costo cobranza</h3>
            <h3>Monto a recibir</h3>
            <h3>Fecha vencimiento</h3>
          </div>
          <div>
            <p>15.0%</p>
            <p>$1,000</p>
            <p>$36.67</p>
            <p>$6.81</p>
            <p>$1,029.86</p>
            <p>18/05/2021</p>
          </div>
        </DetailInvestmentBInfo>
        <DetailInvestmentBForm>
          <h2>Tu Cuenta Virtual tiene salgo disponible para esta inversión ¿Quieres utilizarla?</h2>
          <div>
            <div>
              <input type="checkbox" name="useMyVirtualAccount" />
              <p>Si, utilizar mi saldo de Cuenta Virtual</p>
            </div>
            <div>
              <input type="checkbox" name="acceptNoGuarantee" />
              <p>
                Entiendo y acepto que este crédito no se encuentra garantizado por Finsphera y{' '}
                <span>
                  declaro asumir el riesgo de no diversificar mi inversión, mora, incumplimiento y
                  no pago por parte del deudor.
                </span>{' '}
                FinSphera, S.A.P.I. de C.V. no es una entidad supervisada por organismos reguladores
                en México.
              </p>
            </div>
          </div>
        </DetailInvestmentBForm>
        <DetailInvestmentBGetCode>
          {codeProvided ? (
            <h2>Ingrese el código proporcionado:</h2>
          ) : (
            <h2>¿Cómo quieres solicitar tu código?</h2>
          )}
          {codeProvided ? (
            <div>
              <input type="text" name="enterCode" />
              <button>Enviar</button>
            </div>
          ) : (
            <div>
              <select name="getCode">
                <option value="none">Seleccione:</option>
                <option value="smsOrAuthy">Enviando un SMS /Authy</option>
                <option value="phoneCall">Haciendo una llamada</option>
              </select>
              <button>Enviar</button>
            </div>
          )}
        </DetailInvestmentBGetCode>
        <DetailInvestmentAdvices isCodeProvided={codeProvided}>
          {codeProvided ? (
            <p>
              ¿No recibiste el código? <button>Enviar de nuevo</button> o seleccionar{' '}
              <button>método de validación</button>.
            </p>
          ) : (
            <>
              <p>
                *Los intereses de este crédito comenzarán a generarse el día en que se financie.
              </p>
              <p>
                ** Los valores y fechas de pago son estimados. El desarrollo definitivo del crédito
                dependerá del día de firma del pagaré y estará disponible para los inversionistas en
                el detalle de cada una de sus inversiones.
              </p>
              <p>
                *** En términos del artículo octavo transitorio de la Ley para Regular las
                Instituciones de Tecnología Financiera, la autorización para llevar a cabo
                operaciones dentro de esta plataforma de financiamiento colectivo se encuentra en
                trámite, por lo que la operación actual de la plataforma no es una actividad
                supervisada por los autoridades mexicanas.
              </p>
            </>
          )}
        </DetailInvestmentAdvices>
      </div>
    </ModalDetailInvestmentB>
  );
}
