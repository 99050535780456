import styled from 'styled-components';
import { MilestoneProgressProps } from './types';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const MilestoneProgressContainer = styled.div<MilestoneProgressProps>`
  width: 100%;
  padding: 0 0.5rem;

  .milestone-progress-parent {
    position: relative;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: space-between;
  }

  p {
    font-size: ${uIFontSize.xSmall};
    color: ${colors.text.description};
  }

  .progress-bar {
    position: absolute;
    height: 0.6rem;
    transition: width 0.5s ease-in-out;
    width: ${({ percentage }) => `${percentage}%`};
    border-radius: 5px;
    background-color: ${({ percentage }) =>
      percentage < 8 ? colors.black.gray : colors.green.default};
    top: 6.5px;
    z-index: 1;
  }

  .container {
    display: flex;
    height: 43px;
    width: 1rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .day-milestone {
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    background-color: ${colors.black.gray};
  }

  .milestone {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: ${colors.black.gray};
  }

  .active {
    background-color: ${colors.green.default};
  }

  .hidden {
    visibility: hidden;
  }
`;
