/* eslint-disable */
// this is an auto generated file. This will be overwritten

// SE EJECUTA CUANDO SE REGISTRA O SE INICIA SESION
// GUARDA EL DATO DE IP Y GEOLOCATION EN CADA SESIÓN INICIADA
export const updateInvestor_login = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      # updatedAt
    }
  }
`;

export const CreateResourceProvider = /* GraphQL */ `
  mutation CreateResourcesProvider(
    $input: CreateResourcesProviderInput!
    $condition: ModelResourcesProviderConditionInput
  ) {
    createResourcesProvider(input: $input, condition: $condition) {
      createResourcesProvider(input: $input, condition: $condition) {
        id
        investorId
        applicantId
        personalInformation {
          email
        }
      }
    }
  }
`;

export const updateInvestor_biomoral = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      personalInformation {
        RFC
      }
      companyInformation {
        companyName
        denomination
        RFC
        generalActivity
        specificActivity
      }
    }
  }
`;

export const updateInvestor_generalInfo = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      personalInformation {
        firstName
        lastNameP
        lastNameM
        profileImage
        email
        gender
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        phone {
          land
          mobile
          isVerified
          lada
        }
        dayOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        isFamilyPEP
        isInNewsletter
      }
      companyInformation {
        companyName
        denomination
        profileImage
        RFC
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        country
        phone {
          land
          mobile
          isVerified
          lada
        }
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
      }
      address {
        id
        country
        postalCode
        city
        state
        district
        neighborhood
        street
        numInt
        numExt
        typeOfAddress
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;

export const updateApplicant_login = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      # updatedAt
    }
  }
`;
export const CreateObligadoSolidarioCust = /* GraphQL */ `
  mutation CreateObligadoSolidario(
    $input: CreateObligadoSolidarioInput!
    $condition: ModelObligadoSolidarioConditionInput
  ) {
    createObligadoSolidario(input: $input, condition: $condition) {
      id
      applicantId
      personalInformation {
        email
        RFC
        isPep
      }
    }
  }
`;

export const updateApplicant_Onboarding = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      requestedAmount
      termLoan
      loanDestination
      distributionChannel
      industryActivity
    }
  }
`;

export const createRiskOnboarding = /* GraphQL */ `
  mutation CreateRisk($input: CreateRiskInput!, $condition: ModelRiskConditionInput) {
    createRisk(input: $input, condition: $condition) {
      investorId
      loss
      liquidity
      information
      performance
      notApproved
      notAdvice
      investment
      disclosedInformation
      createdAt
      updatedAt
    }
  }
`;

export const createComplaintAccount = /* GraphQL */ `
  mutation CreateComplaint(
    $input: CreateComplaintInput!
    $condition: ModelComplaintConditionInput
  ) {
    createComplaint(input: $input, condition: $condition) {
      id
      applicantId
      investorId
      type
      typeOfContact
      contact
      description
      createdAt
      updatedAt
    }
  }
`;

export const CreateCampaignDash = /* GraphQL */ `
  mutation CreateCampaignOnboarding(
    $input: CreateCampaignInput!
    $condition: ModelCampaignConditionInput
  ) {
    createCampaign(input: $input, condition: $condition) {
      id
      applicantId
      montoSolicitado
      plazoCredito
      destinoCredito
      fuentePago
      createdAt
      updatedAt
      obligadoSolidarioId
    }
  }
`;

export const updateApplicant_webPage = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      companyInformation {
        webSite
      }
    }
  }
`;

export const updateApplicant_companyInformation = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      id
      companyInformation {
        email
        companyName
        denomination
        profileImage
        RFC
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        country
        phone {
          land
        }
        employees
        activity
        webSite
        invoicesValue
        businessAge
        CIEC
      }
      updatedAt
    }
  }
`;

//CREAR APLICANTE EN EL REGISTRO
export const createApplicant_signup = /* GraphQL */ `
  mutation CreateApplicant(
    $input: CreateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    createApplicant(input: $input, condition: $condition) {
      id
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      isInMarketing
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      createdAt
      updatedAt
      personalInformation {
        email
      }
    }
  }
`;

//CREAR INVERSIONISTA EN EL REGISTRO
export const createInvestor_signup = /* GraphQL */ `
  mutation CreateInvestor($input: CreateInvestorInput!, $condition: ModelInvestorConditionInput) {
    createInvestor(input: $input, condition: $condition) {
      id
      typeOfPerson
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      isInMarketing
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      createdAt
      updatedAt
      personalInformation {
        email
      }
    }
  }
`;

//UPDATE INVESTOR PERSONAL INFORMATION 1
export const updateInvestorPi = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      personalInformation {
        firstName
        lastNameP
        lastNameM
        gender
        RFC
        CURP
        nationality
        phone {
          land
          mobile
          lada
        }
        dayOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        isFamilyPEP
      }
    }
  }
`;

export const createAddress_Pi1 = /* GraphQL */ `
  mutation CreateAddress($input: CreateAddressInput!, $condition: ModelAddressConditionInput) {
    createAddress(input: $input, condition: $condition) {
      id
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      typeOfAddress
      createdAt
      updatedAt
    }
  }
`;

export const createCreditAnalysis = /* GraphQL */ `
  mutation CreateCreditAnalysis(
    $input: CreateCreditAnalysisInput!
    $condition: ModelCreditAnalysisConditionInput
  ) {
    createCreditAnalysis(input: $input, condition: $condition) {
      applicantId
      profitability {
        probitabilityValue
        probitabilityPoints
        compoundAnnualGrowthInSalesValue
        compoundAnnualGrowthInSalesPoints
        compoundAnnualGrowthInNetEarningsValue
        compoundAnnualGrowthInNetEarningsPoints
        rating
        isApproved
      }
      liquidity {
        borrowingCapacityValue
        borrowingCapacityPoints
        liquidityRatioValue
        liquidityRatioPoints
        acidTestValue
        acidTestPoints
        immediatePaymentAbilityValue
        immediatePaymentAbilityPoints
        maximumPaymentTermValue
        maximumPaymentTermPoints
        rating
        isApproved
      }
      solvency {
        taxComplianceChecksValue
        taxComplianceChecksPoints
        taxRegimeValue
        taxRegimePoints
        majorCorporateClientsValue
        majorCorporateClientsPoints
        annualGrowthInTheNumberOfClientsValue
        annualGrowthInTheNumberOfClientsPoints
        salesConcentrationValue
        salesConcentrationPoints
        ROEValue
        ROEPoints
        ROAValue
        ROAPoints
        rating
        isApproved
      }
      creditAccess {
        financialInstitutionGrantsCreditValue
        financialInstitutionGrantsCreditPoints
        creditBureauRatingValue
        creditBureauRatingPoints
        paymentHistoryValue
        paymentHistoryPoints
        numberOfCreditGrantorsValue
        numberOfCreditGrantorsPoints
        inquiriesInCreditBureauValue
        inquiriesInCreditBureauPoints
        rating
        isApproved
      }
      marketAndProduct {
        businessAgeValue
        businessAgePoints
        mainActivityValue
        mainActivityPoints
        statusInTheTaxpayerRegistryValue
        statusInTheTaxpayerRegistryPoints
        capitalRegimeValue
        capitalRegimePoints
        federalEntityValue
        federalEntityPoints
        rating
        isApproved
      }
      corporateGovernance {
        experienceOfMainShareholderValue
        experienceOfMainShareholderPoints
        independetConselorsValue
        independetConselorsPoints
        independenceDirectorGeneralValue
        independenceDirectorGeneralPoints
        numberOfCommitteesValue
        numberOfCommitteesPoints
        rating
        isApproved
      }
      createdAt
      updatedAt
    }
  }
`;

//UPDATE APPLICANT PN PHONE CONFIRMATION
export const updateApplicant_OnboardingStatus = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    updateApplicant(input: $input, condition: $condition) {
      # id
      onboarding {
        complete
        currentStep
        isBlocked
        reasonOfBlocking
      }
    }
  }
`;

//UPDATE INVESTOR PN PHONE CONFIRMATION
export const updateInvestor_OnboardingStatus = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      # id
      onboarding {
        complete
        currentStep
        isBlocked
        reasonOfBlocking
      }
    }
  }
`;

//
export const updateInvestor_biophysical = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    updateInvestor(input: $input, condition: $condition) {
      id
      personalInformation {
        RFC
        generalActivity
        specificActivity
      }
    }
  }
`;

//Create bank account
export const CreateBankAccount = /* GraphQL */ `
  mutation CreateBank($input: CreateBankInput!, $condition: ModelBankConditionInput) {
    createBank(input: $input, condition: $condition) {
      applicantId
      investorId
      isMainAccount
      bankName
      bankCode
      CLABE
      identifier
      createdAt
      updatedAt
    }
  }
`;

//Update bank account
export const updateMainBankAccount = /* GraphQL */ `
  mutation UpdateBank($input: UpdateBankInput!, $condition: ModelBankConditionInput) {
    updateBank(input: $input, condition: $condition) {
      id
      isMainAccount
      updatedAt
    }
  }
`;

//Profile Investor Create Beneficiaries
export const createBeneficiaryCust = /* GraphQL */ `
  mutation CreateBeneficiary(
    $input: CreateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    createBeneficiary(input: $input, condition: $condition) {
      id
      _type
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      dateOfBirth
      country
      postalCode
      state
      district
      street
      numInt
      numExt
      percentage
    }
  }
`;

//Profile Investor Edit Beneficiaries
export const updateBeneficiary = /* GraphQL */ `
  mutation UpdateBeneficiary(
    $input: UpdateBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    updateBeneficiary(input: $input, condition: $condition) {
      id
      investorId
      firstName
      lastNameM
      lastNameP
      relationship
      percentage
      street
      numInt
      numExt
      country
      state
      district
      postalCode
    }
  }
`;

//profile Investor Delete Beneficiaries
export const deleteBeneficiary = /* GraphQL */ `
  mutation DeleteBeneficiary(
    $input: DeleteBeneficiaryInput!
    $condition: ModelBeneficiaryConditionInput
  ) {
    deleteBeneficiary(input: $input, condition: $condition) {
      id
    }
  }
`;

//Update Phone number inside investor's profile
export const updateInvestor_PhoneNumber = /* GraphQL */ `
  mutation UpdateInvestor($input: UpdateInvestorInput!, $condition: ModelInvestorConditionInput) {
    id
    personalInformation {
      phone {
        land
        mobile
      }
    }
  }
`;

export const updateApplicant_PhoneNumber = /* GraphQL */ `
  mutation UpdateApplicant(
    $input: UpdateApplicantInput!
    $condition: ModelApplicantConditionInput
  ) {
    id
    personalInformation {
      phone {
        land
        mobile
      }
    }
  }
`;

//CREATE INVESTMENT
export const createInvestment_M = /* GraphQL */ `
  mutation CreateInvestment(
    $input: CreateInvestmentInput!
    $condition: ModelInvestmentConditionInput
  ) {
    createInvestment(input: $input, condition: $condition) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;

export const createInvestment_Fin = /* GraphQL */ `
  mutation CreateInvestment(
    $input: CreateInvestmentInput!
    $condition: ModelInvestmentConditionInput
  ) {
    createInvestment(input: $input, condition: $condition) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      createdAt
      updatedAt
      investorInvestmentsId
      owner
    }
  }
`;
