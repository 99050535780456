import { MetamapButton } from 'src/components/shared/ MetamapButton/MetamapButton';
import { MetamapContainer } from './metamap.styles';
import { Card, PlatformBanner } from 'src/utilities/UILibrary';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { useForm } from 'react-hook-form';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { isByPass } from 'src/environments';
import { setLoadingState } from 'src/store/User/user.slice';
import { setAppError } from 'src/store/app/app.slice';
import { TypeOfPerson } from 'src/models';
import { useNavigate } from 'react-router-dom';
import { config } from 'src/environments';
import { updateMetamapDummyData } from 'src/requests/onboardingRequests';

type MetamapProps = {
  typeOfUser: 'investor' | 'applicant';
};

const navigateTo = {
  investor: '/inversionista/onboarding-data-f',
  moralInvestor: '/inversionista/onboarding-data-m',
  applicant: '/solicitante/onboarding-data'
};

const getRoute = (typeOfUser: string, typeOfPerson?: TypeOfPerson): string => {
  const routes: { [key: string]: string } = {
    applicant: navigateTo.applicant,
    investor_FISICA: navigateTo.investor,
    investor_MORAL: navigateTo.moralInvestor
  };

  const key = typeOfUser === 'investor' ? `${typeOfUser}_${typeOfPerson}` : 'applicant';
  return routes[key];
};

export const Metamap: FC<MetamapProps> = ({ typeOfUser }) => {
  const [isMetamapCompleted, setIsMetamapCompleted] = useState(config.bypassMetamap);
  const user = useSelector((state: RootState) => state.user.currentUser);
  const typeOfPerson: TypeOfPerson = user?.typeOfPerson;
  const { handleSubmit } = useForm();
  const { nextStep } = useManageSteps();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const route = getRoute(typeOfUser, typeOfPerson);

  const onSubmit = async () => {
    try {
      dispatch(setLoadingState(true));
      if (config.bypassMetamap) await updateMetamapDummyData(user.id, typeOfUser);
      await nextStep();
      navigate(route);
    } catch (error) {
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  // manage the error of the metamap sdk
  return (
    <MetamapContainer className="onboarding-view-content">
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_app_dash_personalInformation_banner.png`}
        imgAlt="Finsphera icono información personal"
        title="Identificación personal"
        className="onboarding-banner"
      />
      <Card className="onboarding-card" isSlim isForm>
        <Card.Row>
          <h3>
            Para iniciar con el primer paso de tu solicitud, es necesario que nos proporciones tus
            datos biométricos dando click en el botón de abajo. Deberás tener tu INE a la mano:
          </h3>
        </Card.Row>
        <Card.Row>
          <MetamapButton
            userId={user.id}
            typeOfUser={typeOfUser}
            onUserFinishedSdk={() => setIsMetamapCompleted(true)}
            disabled={isMetamapCompleted}
          />
        </Card.Row>
      </Card>
      <FormActionButtons
        disableSubmitButton={!isMetamapCompleted && !isByPass}
        submitAction={handleSubmit(onSubmit)}
      />
    </MetamapContainer>
  );
};
