import { useEffect } from 'react';
import { NotFoundContainer } from './notFound.styles';

export const NotFound = () => {
  useEffect(() => {
    const footer = document.querySelector('footer');
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  return (
    <NotFoundContainer>
      <h1>
        ¡Ups! <br /> No pudimos encontrar la página que buscas...
      </h1>
    </NotFoundContainer>
  );
};
