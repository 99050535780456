import {
  CreateBeneficiaryModal,
  InfoContainer,
  ContentContainer,
  ButtonsContainer,
  ConfirmButton,
  CloseButton,
  MainInfo
} from './CreateBeneficiary.styles';
import { useState, useEffect } from 'react';
import states from 'src/constants/states';
import countries from 'src/constants/countries/countries';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { Button } from 'src/utilities/UILibrary';

export default function CreateBeneficiary({
  showModal,
  onClose,
  onConfirm,
  beneficiaryData,
  setBeneficiaryData,
  totalAssignedPercentage,
  numberOfBeneficiaries
}) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleInputChange = (updatedValue, { name }) => {
    setBeneficiaryData({
      ...beneficiaryData,
      [name]: name === 'percentage' ? parseFloat(updatedValue) : updatedValue
    });
  };
  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    setBeneficiaryData({
      ...beneficiaryData,
      [name]: name === 'percentage' ? parseFloat(value) : value
    });
  };
  function allFieldsFilled(data) {
    return Object.entries(data).every(([key, value]) => {
      if (key === 'numExt' || key === 'numInt') {
        return true;
      }

      return value !== '' && value !== undefined && value !== null;
    });
  }

  useEffect(() => {
    setIsButtonDisabled(!allFieldsFilled(beneficiaryData));
  }, [beneficiaryData]);

  const percentageOptions = [0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  const remainingPercentage = parseFloat((1 - totalAssignedPercentage).toFixed(2));
  let availableOptions;
  if (numberOfBeneficiaries === 2) {
    availableOptions = [remainingPercentage];
  } else {
    availableOptions = percentageOptions.filter((option) => option <= remainingPercentage);
  }

  return (
    <CreateBeneficiaryModal showModalCreateBeneficiary={showModal}>
      <ContentContainer>
        <h2>
          Datos del <span>beneficiario</span>
        </h2>
        <p>Puedes tener un máximo de 3 beneficiarios</p>
        <InfoContainer>
          <MainInfo>
            <div>
              <h3>Nombre(s):</h3>
              <InputField
                value={beneficiaryData.firstName}
                onChangeInternalValue={handleInputChange}
                name="firstName"
              />
            </div>
            <div>
              <h3>Apellido Paterno:</h3>
              <InputField
                value={beneficiaryData.lastNameM}
                onChangeInternalValue={handleInputChange}
                name="lastNameM"
              />
            </div>
            <div>
              <h3>Apellido Materno:</h3>
              <InputField
                value={beneficiaryData.lastNameP}
                onChangeInternalValue={handleInputChange}
                name="lastNameP"
              />
            </div>
            <div>
              <h3>Fecha de nacimiento:</h3>
              <InputField
                value={beneficiaryData.dateOfBirth}
                onChangeInternalValue={handleInputChange}
                name="dateOfBirth"
                type="date"
              />
            </div>
            <div>
              <h3>Relación o parentesco:</h3>
              <select
                value={beneficiaryData.relationship}
                onChange={handleSelectChange}
                name="relationship"
              >
                <option value={undefined}>Seleccionar...</option>
                <option value="padre">Padre</option>
                <option value="madre">Madre</option>
                <option value="hijo-a">Hijo (a)</option>
                <option value="esposo-a">Esposo (a)</option>
                <option value="hermano-a">Hermano (a)</option>
              </select>
            </div>
            <div>
              <h3>Porcentaje:</h3>
              <select
                value={beneficiaryData.percentage}
                onChange={handleSelectChange}
                name="percentage"
              >
                <option value={0}>Seleccionar...</option>
                {availableOptions.map((option) => (
                  <option key={option} value={option}>
                    {Math.round(option * 100)}%
                  </option>
                ))}
              </select>
            </div>
          </MainInfo>
          <MainInfo>
            <div>
              <h3>Calle:</h3>
              <InputField
                value={beneficiaryData.street}
                onChangeInternalValue={handleInputChange}
                name="street"
              />
            </div>
            <div>
              <h3>Número externo:</h3>
              <InputField
                value={beneficiaryData.numExt}
                onChangeInternalValue={handleInputChange}
                name="numExt"
              />
            </div>
            <div>
              <h3>Número interno:</h3>
              <InputField
                value={beneficiaryData.numInt}
                onChangeInternalValue={handleInputChange}
                name="numInt"
              />
            </div>
            <div>
              <h3>Municipio o Alcaldía:</h3>
              <InputField
                value={beneficiaryData.district}
                onChangeInternalValue={handleInputChange}
                name="district"
              />
            </div>
            <div>
              <h3>Código Postal:</h3>
              <InputField
                value={beneficiaryData.postalCode}
                onChangeInternalValue={handleInputChange}
                name="postalCode"
              />
            </div>
            <div>
              <h3>País:</h3>
              <select name="country" onChange={handleSelectChange} value={beneficiaryData.country}>
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <h3>Estado:</h3>
              <select name="state" onChange={handleSelectChange} value={beneficiaryData.state}>
                {states.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>
          </MainInfo>
        </InfoContainer>
        <ButtonsContainer>
          <Button onClick={onClose}>Cancelar</Button>
          <Button onClick={onConfirm} disabled={isButtonDisabled}>
            Crear
          </Button>
        </ButtonsContainer>
      </ContentContainer>
    </CreateBeneficiaryModal>
  );
}
