import { useState, useEffect, FC } from 'react';
import { ValidatorPass } from './PasswordValid.styles';

type PasswordValidProps = {
  password: string;
  onChangePassword?: (value: boolean) => void;
};

const PasswordValid: FC<PasswordValidProps> = ({ password, onChangePassword }) => {
  //Password Validation
  const [passwordValid, setPasswordValid] = useState(false);
  const upper = new RegExp('^(?=.*[A-Z]).+$');
  const num = new RegExp('^(?=.*[0-9]).+$');
  const special = new RegExp('^(?=.*[_.@$%&-/#?¿*]).+$');
  const length = new RegExp('^.{8,}$');
  const repeating = new RegExp(/(\w)\1{2,}/);

  const isSeqPresent = (str: string) => {
    // algorithm to check if there are 3 consecutive characters
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const numbers = '0123456789';
    const len = str.length;
    for (let i = 0; i < len - 2; i++) {
      const part = str.substring(i, i + 3);

      if (
        alphabet.includes(part) ||
        alphabet.toUpperCase().includes(part) ||
        numbers.includes(part)
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (
      upper.test(password) &&
      num.test(password) &&
      special.test(password) &&
      length.test(password) &&
      !repeating.test(password) &&
      !isSeqPresent(password) &&
      password !== 'Finsphera' &&
      password !== 'finsphera'
    ) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  }, [password]);

  useEffect(() => {
    if (!onChangePassword) return;

    if (passwordValid) {
      onChangePassword(true);
    } else {
      onChangePassword(false);
    }
  }, [onChangePassword, passwordValid]);

  return (
    <ValidatorPass>
      {password && (
        <>
          {password === 'Finsphera' || password === 'finsphera' ? (
            <p>
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
              No debe contener la palabra Finsphera
            </p>
          ) : null}
          <p>
            {upper.test(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            Debe tener una mayúscula
          </p>
          <p>
            {num.test(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            Debe tener al menos un número
          </p>
          <p>
            {special.test(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            Debe tener un caracter especial
          </p>
          <p>
            {length.test(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            Mínimo 8 caracteres
          </p>
          <p>
            {!isSeqPresent(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            No más de tres caracteres numéricos o alfabéticos en secuencia
          </p>
          <p>
            {!repeating.test(password) ? (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_success_value.png"
                alt="icono de acierto"
              />
            ) : (
              <img
                src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error_value.png"
                alt="icono de error"
              />
            )}
            No más de tres caracteres idénticos en fila
          </p>
        </>
      )}
    </ValidatorPass>
  );
};

export default PasswordValid;
