import { forwardRef, ForwardRefExoticComponent, RefObject, useEffect, useState } from 'react';
import {
  ModalContainer,
  ModalBodyContainer,
  ModalFooterContainer,
  ModalHeaderContainer
} from './modal.styles';
import {
  ModalComponentProps,
  ModalProps,
  ModalBodyProps,
  ModalFooterProps,
  ModalHeaderProps
} from './modal.interface';
import Card from 'src/utilities/UILibrary/UI/Card/Card';

// Resolve Ref problem

const Modal = forwardRef<HTMLDivElement, ModalComponentProps>((props, ref) => {
  const {
    children,
    isOpen,
    onClose,
    isOverlay = true,
    onOverlayClose = true,
    isCloseButton = true,
    ...restProps
  } = props;
  const [displayElement, setDisplayElement] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisplayElement(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
      setTimeout(() => {
        setDisplayElement(false);
      }, 300);
    }
  }, [isOpen]);

  return (
    <ModalContainer
      ref={ref as RefObject<HTMLDivElement>}
      isOpen={isOpen}
      displayElement={displayElement}
      isOverlay={isOverlay}
      onClose={onClose}
      {...restProps}
    >
      <div className="overlay" onClick={onOverlayClose ? onClose : undefined} />
      <Card className="card">
        {isCloseButton ? (
          <button className="close-button" onClick={onClose}>
            <div />
            <div />
          </button>
        ) : null}
        {children}
      </Card>
    </ModalContainer>
  );
}) as ModalComponentProps & ForwardRefExoticComponent<ModalProps>;

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>((props, ref) => {
  const { children } = props;

  return (
    <ModalHeaderContainer ref={ref} {...props}>
      {children}
    </ModalHeaderContainer>
  );
});

const ModalBody = forwardRef<HTMLDivElement, ModalBodyProps>((props, ref) => {
  const { children } = props;

  return (
    <ModalBodyContainer ref={ref} {...props}>
      {children}
    </ModalBodyContainer>
  );
});

const ModalFooter = forwardRef<HTMLDivElement, ModalFooterProps>((props, ref) => {
  const { children } = props;

  return (
    <ModalFooterContainer ref={ref} {...props}>
      {children}
    </ModalFooterContainer>
  );
});

Modal.displayName = 'Modal';
ModalBody.displayName = 'ModalBody';
ModalFooter.displayName = 'ModalFooter';
ModalHeader.displayName = 'ModalHeader';

Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
Modal.Header = ModalHeader;

export default Modal;
