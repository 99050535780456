import styled from 'styled-components/macro';
import { greenSp } from 'src/utilities/UIStyles/variables/colors';

export const ThreatContainer = styled.section`
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const TextContainer = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: rgb(248, 248, 249);

  > h2 {
    font-size: 1.5rem;
  }
  > p {
    font-size: 1.2rem;
    margin: 2%;
    > a {
      color: ${greenSp};
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
`;
