import { forwardRef } from 'react';
import { PlatformBannerContainer } from './platformBanner.styles';
import { PlatformBannerProps } from './platformBanner.types';

export const PlatformBanner = forwardRef<HTMLDivElement, PlatformBannerProps>((props, ref) => {
  const { imgSrc, imgAlt, description, title } = props;

  return (
    <PlatformBannerContainer ref={ref} {...props}>
      {imgSrc ? (
        <div className="image-container">
          <img src={imgSrc} alt={imgAlt} />
        </div>
      ) : null}
      <div className="body">
        <h1>{title}</h1>
        {description ? <p>{description}</p> : null}
      </div>
    </PlatformBannerContainer>
  );
});

PlatformBanner.displayName = 'OnboardingBanner';
