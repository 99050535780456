import styled from 'styled-components/macro';
import {
  blackSphera,
  brandBlack,
  grayLightSphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const CampaignInvoicesContainer = styled.section`
  width: 80%;
  margin: 2.5% auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const RequestedSection = styled.div`
  background-color: ${whiteSphera};
  width: 80%;
  margin: 5% 10%;
  padding: 0 3%;
  display: flex;
  flex-wrap: wrap;
  > p {
    color: red;
    font-weight: bold;
  }
  > button {
    width: 50%;
    margin: 2% 25%;
    align-self: center;
  }
`;

export const ButtonContainer = styled.div`
  background-color: ${whiteSphera};
  width: 80%;
  margin: 2% 10%;
  padding: 0 3%;
  display: flex;
  justify-content: space-around;
`;

export const InvoiceSection = styled.div`
  background-color: rgb(245, 245, 245);
  width: 80%;
  margin: 3% 10%;
  padding: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  > div {
    margin-bottom: 5%;
  }
`;

export const IdentityQuestion = styled.div`
  width: 100%;
  text-align: center;
  > p {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    margin-top: 3%;
  }
  > div {
    width: 80%;
    margin: 1% 10%;
    display: flex;
    justify-content: space-evenly;
    > label {
      font-size: ${uIFontSize.paragraph};
    }
  }
`;

export const CreateCampaign = styled.section`
  background-color: rgb(245, 245, 245);
  @media (min-width: 600px) and (max-width: 1024px) {
    > button {
      background-color: ${blackSphera};
    }
  }

  @media (max-width: 600px) {
    > button {
      font-size: ${uIFontSize.paragraph};
      margin: 5% 25%;
      padding: 1% 3%;
    }
  }
`;

export const FormContainer = styled.div`
  width: 80%;
  margin: 2.5% auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > div {
    > h4 {
      text-align: center;
      color: ${brandBlack};
      margin: 2% 0;
      > a {
        color: ${greenSp};
      }
    }
  }

  @media (max-width: 600px) {
    width: 95%;
    margin: 5% auto;
    padding: 5%;
  }
`;

export const FormData = styled.div`
  padding: 0 2%;
`;

export const FormDataTitle = styled.h2`
  font-size: ${uIFontSize.subTitle};
  color: ${greenSp};
  margin-bottom: 2%;
  border-bottom: 1px solid ${grayLightSphera};

  @media (max-width: 600px) {
    font-size: ${uIFontSize.subTitle};
    margin-bottom: 5%;
  }
`;

export const FormDataGeneral = styled.div`
  display: flex;
`;

export const FormDataInvoices = styled.div`
  margin-top: 2.5%;
  > div:nth-child(2) {
    display: flex;
    margin-bottom: 2.5%;
    > button:nth-child(1) {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${blackSphera};
      margin-right: 5%;
      padding: 1% 6%;
      border: 1px solid ${blackSphera};
      border-radius: 5px;
      background-color: transparent;
      cursor: pointer;
    }
    > button:nth-child(2) {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${whiteSphera};
      padding: 1% 6%;
      border: none;
      border-radius: 5px;
      background-color: ${blackSphera};
      cursor: pointer;
    }
  }

  > div:nth-child(2n + 3) {
    background-color: rgba(0, 0, 0, 0.03);
  }

  > div:nth-child(n + 3) {
    width: 95%;
    margin: auto;
    padding: 1%;

    > h2 {
      font-size: ${uIFontSize.subTitle};
    }

    > div {
      display: flex;

      > div:nth-child(1) {
        margin-right: 15%;
      }

      > div {
        margin: 1% 0;

        > h3 {
          color: ${blackSphera};
          font-size: ${uIFontSize.h3};
          margin-bottom: 1%;
        }

        > input {
          visibility: hidden;
          width: 0;
          padding: 0;
        }

        > label {
          display: inline-block;
          color: ${whiteSphera};
          font-size: ${uIFontSize.paragraph};
          font-weight: bold;
          text-align: center;
          width: 200px;
          padding-top: 5%;
          padding-bottom: 5%;
          border-radius: 5px;
          background-color: ${blackSphera};
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin-top: 2.5%;
    > div:nth-child(2) {
      flex-direction: column;
      > button:nth-child(1) {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.5%;
        padding: 3%;
      }
      > button:nth-child(2) {
        font-size: ${uIFontSize.paragraph};
        width: 100%;
        padding: 3%;
      }
    }
    > div:nth-child(n + 3) {
      > h2 {
        font-size: ${uIFontSize.subTitle};
        margin-top: 10%;
      }
      > div {
        flex-direction: column;

        > div {
          > h3 {
            font-size: ${uIFontSize.h3};
            margin-top: 5%;
          }
          > label {
            font-size: ${uIFontSize.h3};
            width: 250px;
          }
        }
      }
    }
  }
`;

export const StyledButton = styled.button`
  background-color: ${(props) => (props.disabled ? grayLightSphera : blackSphera)};
  font-size: ${uIFontSize.paragraph};
  color: ${whiteSphera};
  border: none;
  border-radius: 5px;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.disabled ? grayLightSphera : blackSphera)};
  }
`;

export const NavigationSection = styled.div`
  display: flex;
  width: 80%;
  margin: 2% 10%;
  justify-content: space-between;
`;

export const UploadSection = styled.div`
  display: flex;
  justify-content: center;
`;
