import { Auth, API } from 'aws-amplify';
import { getInvestor_login, getApplicant_login } from 'src/graphql/customized_queries';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function fetchUserData(userType: string, singInResponse: any) {
  const data = await API.graphql({
    query: userType === 'applicant' ? getApplicant_login : getInvestor_login,
    variables: {
      id: singInResponse.username
    }
  });

  if ('data' in data) {
    const { personalInformation, ipAddress, ...remainingInvestorData } =
      userType === 'applicant' ? data.data.getApplicant : data.data.getInvestor;
    const lastIpAddress = ipAddress[ipAddress.length - 1];

    const response = {
      groupName: singInResponse.signInUserSession.idToken.payload['cognito:groups'][0],
      email: personalInformation.email,
      ipAddress: [lastIpAddress],
      ...remainingInvestorData
    };
    return response;
  } else {
    throw new Error(
      `${userType.charAt(0).toUpperCase() + userType.slice(1)} data is not available`
    );
  }
}

export const signInUserAPI = async (email: string, password: string, isRetrivingUser?: boolean) => {
  const singInResponse = isRetrivingUser
    ? await Auth.currentAuthenticatedUser()
    : await Auth.signIn({
        username: email,
        password
      });

  const user = singInResponse.signInUserSession.idToken.payload['cognito:groups'][0];
  if (user === 'applicant' || user === 'investor') {
    return await fetchUserData(user, singInResponse);
  }
};
