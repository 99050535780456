import Navbar from 'src/components/Layout/Navbar/Navbar';
import Footer from 'src/components/Layout/Footer/Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutWrapperContainer } from './layoutWrapper.styles';
import { useScrollToTop } from 'src/hooks/useScrollToTop';
import { useSelector } from 'react-redux';
import { loadingMessageSelector, loadingSelector } from 'src/store/User/user.slice';
import Spiner from 'src/components/shared/loaders/Spin';
import { errorAppSelector } from 'src/store/app/app.slice';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { removeAppError } from 'src/store/app/app.slice';
import { useAppThunkDispatch } from 'src/store/store';
import { FC } from 'react';
import { Navbar as NewNavbar } from 'src/utilities/UILibrary2024/components';
import { Footer as NewFooter } from 'src/utilities/UILibrary2024/components';

export type LayoutWrapperProps = {
  isNewDesign?: boolean;
};

export const LayoutWrapper: FC<LayoutWrapperProps> = ({ isNewDesign }) => {
  useScrollToTop();
  const dispatch = useAppThunkDispatch();
  const isLoading = useSelector(loadingSelector);
  const loadingMessage = useSelector(loadingMessageSelector);
  const errorApp = useSelector(errorAppSelector);
  const location = useLocation();
  const isTransparent = location.pathname === '/beta' || location.pathname === '/';

  return (
    <LayoutWrapperContainer isNewDesign={isNewDesign}>
      {isNewDesign ? <NewNavbar isTransparent={isTransparent} /> : <Navbar />}
      <ComposedModal
        type="error"
        isOpen={errorApp.isError}
        onClose={() => dispatch(removeAppError())}
        message={errorApp.errorCode === '' ? undefined : errorApp.errorCode}
      />
      {isLoading ? <Spiner message={loadingMessage} /> : null}
      {/* This ideally has to be a main label, but actually we have various mains nested into the app, so will be bad for SEO */}
      <div className="main-wrapper">
        <Outlet />
      </div>
      {isNewDesign ? <NewFooter /> : <Footer />}
    </LayoutWrapperContainer>
  );
};
