export const getCurrentDate = () => {
  const date = new Date();

  const padZero = (number: number) => (number < 10 ? `0${number}` : number.toString());

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1);
  const year = date.getFullYear().toString();
  const hour = padZero(date.getHours());
  const minute = padZero(date.getMinutes());
  const second = padZero(date.getSeconds());

  return `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;
};

export const getCurrentDatePlus = (stringDate: string, plus: number) => {
  const date = new Date(stringDate);
  date.setDate(date.getDate() + plus);
  const newDate = date.toISOString();
  return newDate;
};

export default getCurrentDate;
