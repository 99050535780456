//Main imports
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignupContainer, SignupInfo, SignupForm } from './mainSignup.styles';

const MainSignup = () => {
  const navigate = useNavigate();

  const [type, setType] = useState('');
  const signTypeSelected = () => {
    if (type === 'investor') {
      navigate('/inversionista/registrate');
    } else if (type === 'applicant') {
      navigate('/solicitante/registrate');
    }
  };

  return (
    <SignupContainer>
      <SignupInfo>
        <h2>
          Este es el primer paso para empezar a crear impacto con <span>Finsphera</span>
        </h2>
        <form onSubmit={signTypeSelected}>
          <p>Selecciona la opción que se ajuste a tu requerimiento: </p>
          <button onClick={() => setType('investor')}>Inversionista</button>
          <button onClick={() => setType('applicant')}>Solicitante </button>
        </form>
      </SignupInfo>
      <SignupForm>
        <img
          src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/img_app_signup.png`}
          alt="imagen Acerca de nosotros"
        />
      </SignupForm>
    </SignupContainer>
  );
};

export default MainSignup;
