import styled from 'styled-components/macro';

import {
  blueSphera,
  grayPlaceholder,
  graySphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

export const ModalDetailInvestment = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  visibility: ${(props) => (props.showModalDetailInvestment ? 'visible' : 'hidden')};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 100;
  animation: ${(props) => (props.showModalDetailInvestment ? fadeIn : fadeOut)} 0.5s linear;
  transition: all 0.5s;

  > div {
    width: 60%;
    background-color: ${whiteSphera};
    border-radius: 5px;
    padding-top: 1%;
    padding-bottom: 2.5%;

    > div:nth-child(1) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid #b7bcc7;

      > h3 {
        font-size: 1.5vw;
        color: ${graySphera};
        width: 90%;
        padding-left: 5%;
      }

      > figure {
        width: 25px;
        height: 25px;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > div:nth-child(1) {
        > h3 {
        }

        > figure {
          > img {
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 90%;
      padding-top: 5%;
      padding-bottom: 5%;
      margin-left: -5%;

      > div:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 5%;
        border-bottom: 1px solid #b7bcc7;

        > h3 {
          font-size: 4.5vw;
        }

        > figure {
          > img {
          }
        }
      }
    }
  }
`;

export const DetailInvestmentInfo = styled.div`
  width: 90%;
  margin: 5% auto 2.5% auto;
  border: none;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    background-color: ${blueSphera};

    > h3 {
      font-size: 1.3vw;
      font-weight: normal;
      color: ${whiteSphera};
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;
    background-color: #f0f5ff;

    > p {
      font-size: 1.3vw;
      font-weight: normal;
      color: ${graySphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > h3 {
      }
    }

    > div:nth-child(2) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    margin: 5% auto 5% auto;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 5%;

      > h3 {
        font-size: 3.8vw;
      }
    }

    > div:nth-child(2) {
      padding-top: 5%;
      padding-bottom: 5%;

      > p {
        font-size: 3.5vw;
      }
    }
  }
`;

export const DetailInvestmentCost = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;

  > h2 {
    color: ${graySphera};
    font-size: 1.5vw;
  }

  > div {
    margin-top: 2.5%;
    margin-left: 2.5%;

    > div:nth-child(1) {
      border-bottom: 1px solid ${grayPlaceholder};
      padding-bottom: 1%;

      > div {
        display: flex;
        justify-content: space-between;

        > h3 {
          font-size: 1.4vw;
          font-weight: normal;
          color: ${graySphera};
          margin-bottom: 0.5%;
        }

        > p {
          font-size: 1.4vw;
          color: ${graySphera};
        }
      }

      > p {
        color: ${graySphera};
        font-size: 1.2vw;
        font-style: italic;
      }
    }

    > div:nth-child(2) {
      margin-top: 2.5%;

      > div {
        display: flex;
        justify-content: space-between;

        > h3 {
          font-size: 1.4vw;
          font-weight: normal;
          color: ${graySphera};
          margin-bottom: 0.5%;
        }

        > p {
          font-size: 1.4vw;
          color: ${graySphera};
        }
      }

      > p {
        color: ${graySphera};
        font-size: 1.2vw;
        font-style: italic;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }

    > div {
      > div:nth-child(1) {
        > div {
          > h3 {
          }

          > p {
          }
        }

        > p {
        }
      }

      > div:nth-child(2) {
        > div {
          > h3 {
          }

          > p {
          }
        }

        > p {
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin: 1% auto 5% auto;

    > h2 {
      font-size: 4.5vw;
    }

    > div {
      margin-top: 5%;
      margin-left: 5%;

      > div:nth-child(1) {
        > div {
          > h3 {
            font-size: 4.4vw;
          }

          > p {
            font-size: 4.4vw;
          }
        }

        > p {
          font-size: 4.2vw;
        }
      }

      > div:nth-child(2) {
        margin-top: 5%;

        > div {
          > h3 {
            font-size: 4.4vw;
          }

          > p {
            font-size: 4.4vw;
          }
        }

        > p {
          font-size: 4.2vw;
        }
      }
    }
  }
`;

export const DetailInvestmentReturn = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;

  > h2 {
    color: ${graySphera};
    font-size: 1.5vw;
  }

  > div {
    margin-top: 2.5%;
    margin-left: 2.5%;

    > div {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1%;
      border-bottom: 1px solid ${grayPlaceholder};

      > p {
        color: ${graySphera};
        font-size: 1.4vw;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
    }

    > div {
      > div {
        > p {
        }
      }
    }
  }

  @media (max-width: 600px) {
    margin: 1% auto 5% auto;

    > h2 {
      font-size: 4.5vw;
    }

    > div {
      margin-top: 5%;
      margin-left: 5%;

      > div {
        > p {
          font-size: 4.4vw;
        }
      }
    }
  }
`;

export const DetailInvestmentButtons = styled.div`
  display: flex;
  width: 70%;
  margin: auto;

  > button:nth-child(1) {
    color: ${whiteSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    width: 60%;
    height: 50px;
    margin: auto;
    border: none;
    border-radius: 5px;
    background-color: ${blueSphera};
    cursor: pointer;
  }

  > button:nth-child(2) {
    color: ${blueSphera};
    font-weight: bold;
    font-size: 1.2vw;
    text-align: center;
    width: 20%;
    height: 50px;
    margin: auto;
    border: 1px solid ${blueSphera};
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > button:nth-child(1) {
    }

    > button:nth-child(2) {
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 100%;

    > button:nth-child(1) {
      font-size: 4.2vw;
      width: 90%;
    }

    > button:nth-child(2) {
      font-size: 4.2vw;
      width: 90%;
      margin: 5% auto;
    }
  }
`;
