import { Link } from 'react-router-dom';
import { Sponsors as SponsorsContainer } from './sponsors.styles';

export default function Sponsors() {
  return (
    <SponsorsContainer>
      <h2>Nuestros principales aliados</h2>
      <div>
        <Link to="http://www.afico.org/" target={'_blank'} rel="noreferrer">
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_home_aliados_afico.png`}
              alt="Finsphera aliado AII"
            />
          </figure>
        </Link>
        <Link to="https://aiimx.com.mx/" target={'_blank'} rel="noreferrer">
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/OurPartnersAii.png`}
              alt="Finsphera aliado CCMX"
            />
          </figure>
        </Link>
        <Link to="https://www.pactomundial.org.mx/" target={'_blank'} rel="noreferrer">
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/OurPartnersPactoGlobal.png`}
              alt="Finsphera aliado L & C A"
            />
          </figure>
        </Link>
        <Link to="https://fintechu.mx/" target={'_blank'} rel="noreferrer">
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/OurPartnersFintech.png`}
              alt="Finsphera aliado BIOFIN"
            />
          </figure>
        </Link>
        <Link to="https://mujeresenfinanzas.mx/" target={'_blank'} rel="noreferrer">
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/OurPartnersMef.jpg`}
              alt="Finsphera aliado PNUD"
            />
          </figure>
        </Link>
        <Link
          to="https://www.unglobalcompact.org/take-action/target-gender-equality"
          target={'_blank'}
          rel="noreferrer"
        >
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_home_genderEquality.jpg`}
              alt="Finsphera aliado AFICO"
            />
          </figure>
        </Link>
      </div>
    </SponsorsContainer>
  );
}
