const experienceOfMainShareholder = ['>7', '<7 - >6', '<6 - >5', '<5 - >4', '<4 - >3', '<3'];
const independetConselors = [
  '> 50%',
  '<50% - >40%',
  '<40% - >30%',
  '<30% - >20%',
  '<20% - >10%',
  '<10'
];
const numberOfCommittees = ['> 4', '3', '2', '1', '0'];

export default [experienceOfMainShareholder, independetConselors, numberOfCommittees];
