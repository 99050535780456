import styled from 'styled-components/macro';
import {
  blackSphera,
  grayLightSphera,
  whiteSphera,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const SignupContainer = styled.section`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const SignupInfo = styled.div`
  width: 65%;
  padding: 10% 0 0 2%;

  > h2 {
    color: ${blackSphera};
    font-size: ${uIFontSize.xxLarge};
    line-height: 50px;
    margin-bottom: 2.5%;
    > span {
      color: ${greenSp};
      font-size: ${uIFontSize.xxLarge};
    }
  }

  > p {
    color: ${blackSphera};
    font-size: ${uIFontSize.medium};
    width: 82%;

    > span {
      font-weight: bold;
    }
  }

  @media (max-width: ${breakpoints.extraLargeDesktop}) {
    width: 65%;
    padding: 10% 0 0 2%;
    > h2 {
      font-size: 3rem;
      > span {
        font-size: 3rem;
      }
    }
    > p {
      font-size: ${uIFontSize.xxxLarge};
    }
  }

  @media (min-width: 1401px) and (max-width: 2600px) {
    width: 70%;
    padding: 8% 0 0 2%;
    > h2 {
      font-size: 3.5rem;
      > span {
        font-size: 3.5rem;
      }
    }
    > p {
      font-size: ${uIFontSize.xxxLarge};
    }
  }

  @media (min-width: 600px) and (max-width: 1400px) {
    padding: 5% 0 5% 5%;

    > h2 {
      line-height: 40px;
      font-size: 2.8rem;
      > span {
        font-size: 2.8rem;
      }
    }
    > p {
      font-size: ${uIFontSize.xxLarge};
      > span {
        font-size: ${uIFontSize.xxxLarge};
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    padding: 5% 0 5% 5%;

    > h2 {
      font-size: ${uIFontSize.xxxLarge};
      line-height: 40px;
      width: 95%;
      margin-bottom: 2.5%;
      text-align: center;
      > span {
        font-size: ${uIFontSize.xxxLarge};
      }
    }
    > p {
      font-size: ${uIFontSize.medium};
      width: 95%;
      text-align: center;

      > span {
      }
    }
  }
`;

export const SignupForm = styled.div`
  width: 65%;
  padding: 8% 0 5% 5%;
  background-image: url('https://finsphera-webapp-resources.s3.amazonaws.com/img_app_signup.png');
  background-size: cover;

  > form {
    display: flex;
    flex-direction: column;
    width: 55%;
    margin: auto;
    padding: 5%;
    border-radius: 5px;
    background-color: ${whiteSphera};

    > h3 {
      color: ${blackSphera};
      font-size: ${uIFontSize.xLarge};
      font-weight: bold;
      text-align: center;
      margin-bottom: 5%;
    }

    > input:nth-child(2) {
      font-size: ${uIFontSize.medium};
      padding-bottom: 1%;
      border: none;
      border-bottom: 1px solid ${grayLightSphera};
      margin-bottom: 2.5%;

      &::placeholder {
        color: ${grayLightSphera};
      }
    }

    > div:nth-child(3),
    > div:nth-child(4) {
      display: flex;
      margin-top: 1%;
      margin-bottom: 2.5%;
      padding-bottom: 1%;
      border-bottom: 1px solid ${grayLightSphera};

      > input {
        font-size: ${uIFontSize.medium};
        width: 90%;
        padding-bottom: 1%;
        border: none;

        &::placeholder {
          color: ${grayLightSphera};
        }
      }

      > figure:nth-child(2) {
        width: 20px;
        height: 19px;
        margin-right: 2.5%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(3) {
        width: 25px;
        height: 20px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > h4 {
      font-size: 0.8rem;
      margin-bottom: 3%;
      color: ${blackSphera};

      > span {
        color: ${greenSp};
        font-weight: bold;
        cursor: pointer;
      }
    }

    > div:nth-child(6) {
      display: flex;
      width: 100%;
      align-items: baseline;
      margin: 5% 0;
      > input {
        cursor: pointer;
        padding-top: 5%;
      }
      > p {
        font-size: ${uIFontSize.small};
        color: ${blackSphera};
        margin-left: 2%;
        > a {
          color: ${greenSp};
          font-weight: bold;
          text-decoration: none;
          font-size: ${uIFontSize.small};
        }
      }
    }

    > p:nth-child(8) {
      font-size: ${uIFontSize.small};
      color: ${blackSphera};
      text-align: center;
      margin-top: 5%;
      margin-bottom: 2%;
    }

    > a:nth-child(9) {
      font-size: ${uIFontSize.small};
      font-weight: bold;
      color: ${greenSp};
      text-decoration: none;
      text-align: center;
    }

    button {
      align-self: center;
    }
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    width: 70%;
    padding: 5% 0 5% 5%;
    > form {
      > h3 {
        font-size: ${uIFontSize.xxxLarge};
      }
      > input:nth-child(2) {
        font-size: ${uIFontSize.xLarge};
      }
      > div:nth-child(3),
      > div:nth-child(4) {
        > input {
          font-size: ${uIFontSize.xLarge};
        }
      }
      > p:nth-child(8) {
        font-size: ${uIFontSize.large};
      }
      > a:nth-child(9) {
        font-size: ${uIFontSize.large};
      }
      > div:nth-child(6) {
        margin: 5% 0;
        > p {
          font-size: ${uIFontSize.medium};
          > a {
            font-size: ${uIFontSize.medium};
          }
        }
      }
    }
  }

  @media (min-width: 2561px) {
    padding: 8% 0 5% 5%;
    width: 70%;
    > form {
      > h3 {
        font-size: 2.8rem;
      }
      > input:nth-child(2) {
        font-size: ${uIFontSize.xLarge};
      }
      > div:nth-child(3),
      > div:nth-child(4) {
        > input {
          font-size: ${uIFontSize.xLarge};
        }
      }
      > div:nth-child(6) {
        margin: 5% 0;
        > p {
          font-size: ${uIFontSize.large};
          > a {
            font-size: ${uIFontSize.large};
          }
        }
      }
      > p:nth-child(8) {
        font-size: ${uIFontSize.large};
      }
      > a:nth-child(9) {
        font-size: ${uIFontSize.xxLarge};
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1400px) {
    width: 80%;
    padding: 5% 0 5% 5%;
    > form {
      width: 70%;
      > h3 {
        font-size: ${uIFontSize.large};
      }
      > div:nth-child(6) {
        margin: 5% 0;
        > p {
          font-size: ${uIFontSize.small};
          > a {
            font-size: ${uIFontSize.small};
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    width: 100%;
    > form {
      width: 95%;
      margin: 5% 0;
      > h3 {
        font-size: 5vw;
      }
      > input:nth-child(2) {
        font-size: 1rem;
        &::placeholder {
        }
      }
      > div:nth-child(3),
      > div:nth-child(4) {
        margin-bottom: 5%;

        > input {
          font-size: ${uIFontSize.medium};
          width: 90%;

          &::placeholder {
          }
        }
      }
      > div:nth-child(6) {
        > input {
          margin-top: 2%;
          margin-right: 2.5%;
        }
        > p {
          font-size: ${uIFontSize.small};
        }
      }
      > input[type='submit'] {
        font-size: ${uIFontSize.small};
        width: 95%;
      }
      > p:nth-child(8) {
        font-size: ${uIFontSize.small};
      }
      > a:nth-child(9) {
        font-size: ${uIFontSize.small};
      }
    }
  }
`;
