import { AnonymousMailboxContainer } from './anonymousMailbox.styles';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { createAnonymousMailbox } from 'src/requests';
import { useAppThunkDispatch } from 'src/store/store';
import { setAppError } from 'src/store/app/app.slice';
import { setLoadingState } from 'src/store/User/user.slice';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
import { useNavigate } from 'react-router-dom';

export type FormValues = {
  userType: string;
  description: string;
  observationDate: string;
  anyoneKnows: string;
  hasEscalated: string;
  userName?: string;
  userEmail: string;
}; // check the types, there is some booleans values as strings

export const AnonymousMailbox = () => {
  const [formattedDate, setFormattedDate] = useState('');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormValues>();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const formattedDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
      2,
      '0'
    )}-${String(today.getDate()).padStart(2, '0')}`;
    setFormattedDate(formattedDate);
  }, []);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      dispatch(setLoadingState(true));
      await createAnonymousMailbox(data);
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error(error);
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <AnonymousMailboxContainer>
      <ComposedModal
        type="success"
        isOpen={isSuccessModalOpen}
        onClose={() => navigate('/')}
        message="¡Gracias por tu reporte! Tu contribución es valiosa y nos ayuda a mejorar. Revisaremos tu reporte lo más pronto posible."
      />
      <PlatformBanner
        title="¡Bienvenido a tu buzón anónimo!"
        description="Valoramos tu voz y perspectiva. Utiliza este buzón anónimo para reportar cualquier inquietud o irregularidad. Tu identidad es opcional y cualquier información que proporciones será tratada con confidencialidad."
        className="landing-banner"
      />

      <Card
        isForm
        isSlim
        className="landing-card anonymous-mailbox-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Card.Row itemPosition="left">
          <p>
            <b>1. ¿El reporte que estás realizando es sobre un cliente, un colaborador, o ambos?</b>
          </p>
          <div className="radio-group">
            <input
              type="radio"
              id="customer"
              value="customer"
              {...register('userType', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="customer">Cliente</label>
            <input
              type="radio"
              id="collaborator"
              value="collaborator"
              {...register('userType', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="collaborator">Colaborador</label>
            <input
              type="radio"
              id="clientAndCollaborator"
              value="clientAndCollaborator"
              {...register('userType', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="clientAndCollaborator">Ambos</label>
          </div>
          {errors.userType && <p className="error-text">{errors.userType.message}</p>}
        </Card.Row>
        <Card.Row itemPosition="left">
          <p>
            <b>
              2. Cuáles son los aspectos que consideras inusuales y crees que requieren revisión por
              parte del área de cumplimiento?
            </b>
          </p>
          <textarea
            rows={4}
            {...register('description', {
              required: 'Es un campo requerido.',
              minLength: {
                value: 50,
                message: 'El campo debe tener al menos 50 caracteres.'
              }
            })}
          />
          {errors.description && <p className="error-text">{errors.description.message}</p>}
        </Card.Row>
        <Card.Row itemPosition="left">
          <p>
            <b>3. ¿Desde cuándo has observado estas conductas posiblemente inusuales?</b>
          </p>
          <input
            type="date"
            max={formattedDate}
            min="2023-01-01"
            {...register('observationDate', { required: 'Es un campo requerido.' })}
          />
          {errors.observationDate && <p className="error-text">{errors.observationDate.message}</p>}
        </Card.Row>
        <Card.Row itemPosition="left">
          <p>
            <b>4. ¿Alguien más sabe de estas conductas, que sea de tu conocimiento?</b>
          </p>
          <div className="radio-group">
            <input
              type="radio"
              id="si4"
              value="yes"
              {...register('anyoneKnows', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="si4">Si</label>
            <input
              type="radio"
              id="no4"
              value="no"
              {...register('anyoneKnows', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="no4">No</label>
          </div>
          {errors.anyoneKnows && <p className="error-text">{errors.anyoneKnows.message}</p>}
        </Card.Row>
        <Card.Row itemPosition="left">
          <p>
            <b>
              5. ¿Además de este reporte anónimo, has escalado formalmente este tema con tu superior
              jerárquico o alguien más dentro de la empresa?
            </b>
          </p>
          <div className="radio-group">
            <input
              type="radio"
              id="si5"
              value="yes"
              {...register('hasEscalated', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="si5">Si</label>
            <input
              type="radio"
              id="no5"
              value="no"
              {...register('hasEscalated', { required: 'Es un campo requerido.' })}
            />
            <label htmlFor="no5">No</label>
          </div>
          {errors.hasEscalated && <p className="error-text">{errors.hasEscalated.message}</p>}
        </Card.Row>
        <Card.Row itemPosition="left">
          <p>
            <b>6. Datos de contacto.</b>
          </p>
        </Card.Row>
        <Card.Row itemPosition="left">
          <Controller
            name="userName"
            control={control}
            defaultValue={undefined}
            render={({ field, ...rest }) => (
              <InputField
                type="text"
                label="Nombre (Opcional):"
                onChangeInternalValue={field.onChange}
                placeholder="Tu nombre aquí"
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row itemPosition="left">
          <Controller
            name="userEmail"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Correo Electrónico*:"
                type="email"
                onChangeInternalValue={field.onChange}
                placeholder="Tu correo electrónico aquí"
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row itemPosition="left">
          <Button type="submit" isSlim isFullWidth>
            Enviar Reporte
          </Button>
        </Card.Row>
      </Card>
    </AnonymousMailboxContainer>
  );
};
