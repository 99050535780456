import {
  ContentContainer,
  ButtonsContainer,
  MainInfo,
  Success,
  Headers,
  ModalCreditUnsuccess,
  ButtonsContainerOne
} from './creditUnsuccess.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { useState } from 'react';
import axios from 'axios';
import { url } from 'src/environments';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';

export default function CreditUnsuccess({
  showCreditUnsuccess,
  closeModal,
  campaignData,
  personalInformation,
  obligadoInfo
}) {
  //Doble autenticación
  const [showInput, setShowInput] = useState(false);
  const [userChoice, setUserChoice] = useState(null);
  const [messageId, setMessageId] = useState();
  const [verificationCode, setVerificationCode] = useState('');
  const [otp, setOtp] = useState('');
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();
  const campaignId = campaignData?.id;
  const applicant = personalInformation?.data?.getApplicant;
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  const handleModalClose = () => {
    setShowInput(false);
    setUserChoice(null);
    closeModal();
  };
  function getCurrentDateFormatted() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const ObligadoCampaign = campaignData?.obligadoSolidarioId;
  const findObligadoSolidario = () => {
    return obligadoInfo.find((obligado) => obligado.id === ObligadoCampaign);
  };

  const handleSendEmail = (choice) => {
    const input = {
      id: user.id,
      typeOfUser: 'applicant',
      typeMessage: 'acceptRejectProcess',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setUserChoice(choice);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const executeRejectRequests = async () => {
    const sendEmailInvestor = {
      typeMessage: 'investors_campaign_cancelled_applicant',
      campaignId: campaignId,
      typeOfUser: 'investor'
    };
    const sendEmail = {
      typeMessage: 'applicant_campaign_cancelled_applicant',
      campaignId: campaignId,
      typeOfUser: 'applicant'
    };
    try {
      //Endpoints que envían los correos de campaña no fondeada al 100% y rechazada por la pyme
      const sendMailResponse = await axios.post(`${url}/admin/campaign/send-email`, sendEmail);
      if (sendMailResponse.data && sendMailResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
      const sendMailInvestorResponse = await axios.post(
        `${url}/admin/campaign/send-email`,
        sendEmailInvestor
      );
      if (sendMailInvestorResponse.data && sendMailInvestorResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
    } catch (additionalError) {
      setErrorText(true);
      console.error('Error with additional requests:', additionalError);
    }
  };
  const executeAcceptRequests = async () => {
    const obligadoSolidario = findObligadoSolidario();
    if (!obligadoSolidario) {
      console.error('Obligado Solidario not found');
      return;
    }
    const updateCampaign = {
      type: 'update_campaign_successful',
      input: {
        date: getCurrentDateFormatted(),
        campaignId: campaignId
      }
    };
    const promisory = {
      campaignId: campaignId
    };
    const pdfMifiel = {
      id: campaignId,
      type: 'promissoryNote', //Son dos tipos, promissoryNote y contracts (con esto se forma el key de s3)
      fileName: campaignId,
      signatories: [
        {
          email: applicant.personalInformation.email,
          name: applicant.personalInformation.fullName,
          tax_id: applicant.personalInformation.RFC
        },
        {
          email: obligadoSolidario.personalInformation.email,
          name: obligadoSolidario.personalInformation.fullName,
          tax_id: obligadoSolidario.personalInformation.RFC
        }
      ]
    };
    const sendEmailInvestor = {
      typeMessage: 'investors_campaign_unsuccessful',
      campaignId: campaignId,
      typeOfUser: 'investor'
    };
    const updateAllInvestments = {
      type: 'update_allInvestment_status',
      input: {
        campaignId: campaignId
      }
    };
    try {
      //Endpoint que actualiza la campaña isSuccessful=1, isActive=0
      const updateCampaignResponse = await axios.post(`${url}/admin/dynamo/update`, updateCampaign);
      if (updateCampaignResponse.data && updateCampaignResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
      //Endpoint que crea el pagaré
      const promisoryResponse = await axios.post(
        `${url}/mifiel/documents/create-promissory-note`,
        promisory
      );
      if (promisoryResponse.data && promisoryResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
      //Endpoint que carga el pagaré
      const createPdfResponse = await axios.post(
        `${url}/mifiel/documents/create-document`,
        pdfMifiel
      );
      if (createPdfResponse.data && createPdfResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
      //Endpoint que envía los correos de campaña no fondeada al 100% a los usuarios
      const sendMailInvestorResponse = await axios.post(
        `${url}/admin/campaign/send-email`,
        sendEmailInvestor
      );
      if (sendMailInvestorResponse.data && sendMailInvestorResponse.data.errorMessage) {
        setErrorText(true);
        return;
      }
      //Endpoint que ejecuta la actualización de compromiso de inversión a inversiones vigentes en STP
      const updateSTPInvestments = await axios.post(
        `${url}/admin/dynamo/update`,
        updateAllInvestments
      );
      if (updateSTPInvestments.data && updateSTPInvestments.data.errorMessage) {
        setErrorText(true);
        return;
      }
    } catch (additionalError) {
      setErrorText(true);
      setLocalLoading(false);
    }
  };

  const onConfirm = async () => {
    setLocalLoading(true);
    const input = {
      id: user.id,
      messageId: messageId,
      code: verificationCode,
      timestamp: Date.now(),
      typeOfUser: 'applicant'
    };
    const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
    const parsedBody = JSON.parse(validationCode.data.body);
    if (!parsedBody.isVerified) {
      alert('Validación de código fallida');
      setLocalLoading(false);
      return;
    }
    const resources = {
      type: 'noAceptada',
      campaignId: campaignId
    };
    const finalInvestment = {
      campaignId: campaignId
    };
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/update-campaign`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: resources
    };
    if (userChoice === 'rechazar') {
      try {
        await executeRejectRequests();
        const response = await axios(config);
        if (
          response.data.status === 201 &&
          response.data.message === 'The campaign was updated successfully'
        ) {
          setSuccessText(true);
        } else {
          setErrorText(true);
          console.error('Request completed, but with a non-successful status:', response);
        }
      } catch (error) {
        console.error(error);
        setErrorText(true);
        setLocalLoading(false);
      }
    } else {
      try {
        await executeAcceptRequests();
        await axios.post(`${url}/admin/investment/all-investors-final`, finalInvestment);
        setSuccessText(true);
      } catch (error) {
        console.error(error);
        setLocalLoading(false);
      }
    }
  };

  return (
    <ModalCreditUnsuccess showCreditUnsuccess={showCreditUnsuccess}>
      {errorText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_error.png`}
              alt="icono de error"
            />
          </figure>
          <h2>¡Ocurrió un error con tu solicitud!</h2>
          <p>Inténtalo más tarde, por favor.</p>
          <Button onClick={handleModalClose}>Cerrar</Button>
        </Success>
      ) : !successText ? (
        <ContentContainer>
          <Headers>
            <div></div>
            <figure onClick={handleModalClose}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </Headers>
          <h2>
            En este apartado podrás aceptar o rechazar el monto recaudado del total del crédito que
            solicitaste con <span>FINSPHERA</span>
          </h2>
          <MainInfo>
            <div>
              <h3>Nombre de campaña:</h3>
              <p>{campaignData?.nombreCampania ? campaignData?.nombreCampania : '-'} </p>
            </div>
            <div>
              <h3>Monto solicitado:</h3>
              <p>
                {formatCurrency(campaignData?.montoSolicitado)
                  ? formatCurrency(campaignData?.montoSolicitado)
                  : '-'}
              </p>
            </div>
            <div>
              <h3>Monto recolectado:</h3>
              <p>
                {formatCurrency(campaignData?.collectedAmount)
                  ? formatCurrency(campaignData?.collectedAmount)
                  : '-'}
              </p>
            </div>
            <div>
              <h3>Comisión + IVA del monto recolectado:</h3>
              <p>
                {formatCurrency(
                  (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount +
                    (0.18 / 360) * campaignData?.plazoCredito * campaignData?.collectedAmount * 0.16
                )}
              </p>
            </div>
            <div>
              <h3>Plazo del crédito:</h3>
              <p>{campaignData?.plazoCredito ? campaignData?.plazoCredito : '-'} días</p>
            </div>
          </MainInfo>
          {showInput ? (
            <ButtonsContainer>
              <p>Ingresa el código que enviamos a tu correo:</p>
              <InputField
                value={verificationCode}
                maxLength={6}
                onChangeInternalValue={(value) => setVerificationCode(value)}
                type="number"
                placeholder="Código de verificación"
              />
              <Button isLoading={localLoading} onClick={onConfirm}>
                Confirmar código
              </Button>
              <h3>
                {userChoice === 'rechazar'
                  ? 'Al confirmar el código rechazo la solicitud de crédito que hice con Finsphera por lo que los recursos del monto recolectado, serán liberados de nueva cuenta a los inversionistas.'
                  : 'Al confirmar el código acepto que el monto recolectado no cubre la totalidad del crédito solicitado, sin embargo estoy de acuerdo y deseo continuar con el proceso.'}
              </h3>
            </ButtonsContainer>
          ) : (
            <ButtonsContainerOne>
              <Button onClick={() => handleSendEmail('rechazar')}>Rechazar</Button>
              <Button onClick={() => handleSendEmail('aceptar')}>Aceptar</Button>
            </ButtonsContainerOne>
          )}
        </ContentContainer>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu solicitud ha finalizado!</h2>
          <p>
            {userChoice === 'rechazar'
              ? 'La campaña ha sido cancelada. Si deseas puedes crear nuevas campañas en el apartado de Crear campaña.'
              : 'El proceso de aceptar campaña ha sido exitoso. Ahora deberás ingresar al apartado de Mi Perfil / Contratos para firmar los pagarés y realizar la transferencia del monto recolectado.'}
          </p>
          <Button onClick={handleModalClose}>Cerrar</Button>
        </Success>
      )}
    </ModalCreditUnsuccess>
  );
}
