import styled from 'styled-components/macro';

import { blackSphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';

export const ResetPasswordContainer = styled.section`
  display: flex;
  background-image: url('https://finsphera-resources.s3.amazonaws.com/loginMain.jpg');
  background-size: cover;
  padding: 10% 0 15% 5%;

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 15% 0 5% 5%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 25% 0 15% 5%;
  }
`;

export const ResetPasswordInfo = styled.div`
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin: auto;
    padding: 5% 0;
    border-radius: 5px;
    background-color: ${whiteSphera};

    > figure {
      width: 150px;
      margin: 0 auto 5% auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    > h2 {
      color: ${blackSphera};
      font-size: 1.6vw;
      font-weight: bold;
      text-align: center;
      margin-bottom: 5%;
    }

    > p {
      text-align: center;
      font-size: 1.2vw;
      color: ${blackSphera};
      line-height: 25px;
      width: 65%;
      margin: auto;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > figure {
        img {
        }
      }

      > h2 {
      }

      > p {
        line-height: 20px;
        width: 70%;
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      width: 99%;
      margin-left: -2.5%;

      > figure {
        width: 120px;

        img {
        }
      }

      > h2 {
        font-size: 4.6vw;
      }

      > p {
        font-size: 4.2vw;
        width: 90%;
      }
    }
  }
`;
