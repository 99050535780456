import styled from 'styled-components/macro';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import {
  blackSphera,
  greenSp,
  grayLightSphera,
  graySphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const HomeContainer = styled.section``;

export const CalculateCredit = styled.section`
  background-color: #e7fd4f;
  padding: 2.5%;

  @media (max-width: 600px) {
    padding-top: 5%;
    padding-bottom: 10%;
  }
`;

export const CalculateCreditTitle = styled.div`
  margin-bottom: 2rem;

  > h2 {
    color: ${blackSphera};
    font-size: ${uIFontSize.subTitle};
    text-align: center;
  }

  > p {
    color: ${blackSphera};
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    text-align: center;
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: ${uIFontSize.subTitle};
    }

    > p {
      font-size: ${uIFontSize.paragraph};
    }
  }
`;

export const CalculateCreditButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > a {
    display: block;
    color: ${whiteSphera};
    font-weight: bold;
    font-size: ${uIFontSize.paragraph};
    text-decoration: none;
    text-align: center;
    line-height: 50px;
    width: 30%;
    height: 50px;
    border-radius: 5px;
    background-color: ${blackSphera};
  }

  @media (max-width: 600px) {
    > a {
      font-size: ${uIFontSize.paragraph};
      width: 90%;
    }
  }
`;

export const CalculateCreditAdvices = styled.ul`
  list-style: none;
  width: 80%;
  margin: 2.5% auto 0 auto;

  > li {
    font-size: ${uIFontSize.paragraph};
    color: ${blackSphera};
    margin-bottom: 0.5%;
  }

  @media (max-width: 600px) {
    width: 90%;
    margin: 5% auto 0 auto;

    > li {
      font-size: ${uIFontSize.paragraph};
      margin-bottom: 2.5%;
    }
  }
`;

export const InvestUs = styled.section`
  padding-top: 5%;
  padding-bottom: 5%;

  > div:nth-child(1) {
    > h2 {
      color: ${blackSphera};
      font-size: ${uIFontSize.subTitle};
      text-align: center;
    }
  }

  > div:nth-child(1) {
    display: flex;
    width: 90%;
    margin: 5% auto 1% auto;

    > div {
      width: 33%;

      > figure {
        align-self: center;
        width: 150px;
        height: 150px;
        margin: 0 auto 2.5% auto;
      }

      > h3 {
        color: ${blackSphera};
        text-align: center;
        font-size: ${uIFontSize.h3};
      }

      > p {
        color: ${graySphera};
        text-align: center;
        font-size: ${uIFontSize.paragraph};
        width: 85%;
        margin: 2.5% auto 0 auto;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > div {
        > figure {
          width: 120px;
          height: 120px;
          margin: 0 auto 5% auto;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding-top: 5%;
    > div:nth-child(1) {
      flex-direction: column;
      margin: 5% auto 1% auto;

      > div {
        width: 100%;

        > figure {
          width: 110px;
          height: 110px;
          margin: 5% auto 2.5% auto;
        }

        > h3 {
          font-size: ${uIFontSize.h3};
        }

        > p {
          font-size: ${uIFontSize.paragraph};
          margin: 2.5% auto 2.5% auto;
        }
      }
    }
  }
`;

export const OurServices = styled.section`
  > div:nth-child(1) {
    display: flex;

    > div {
      width: 50%;
      background-color: ${blackSphera};
      padding: 4% 5% 2.5% 5%;

      > h2 {
        color: ${whiteSphera};
        font-size: ${uIFontSize.subTitle};
        width: 80%;
        margin-bottom: 5%;
      }

      > ul {
        margin-left: 2.5%;

        > li {
          color: ${whiteSphera};
          font-size: ${uIFontSize.paragraph};
          margin-bottom: 1%;
        }
      }
    }

    > figure {
      width: 50%;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: row-reverse;

    > div {
      width: 50%;
      background-color: ${greenSp};
      padding: 5% 5% 2.5% 5%;

      > h2 {
        color: ${blackSphera};
        font-size: ${uIFontSize.subTitle};
        width: 90%;
        margin-bottom: 5%;
      }

      > ul {
        > li {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: ${blackSphera};
          font-size: ${uIFontSize.paragraph};
          margin-bottom: 1%;

          > p {
            margin-right: 2.5%;

            > span {
              color: ${blackSphera};
              font-weight: bold;
            }
          }

          > figure {
            width: 20px;
            height: 20px;
            cursor: pointer;

            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    > figure {
      width: 50%;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    > div:nth-child(1) {
      flex-direction: column;

      > div {
        width: 100%;

        > h2 {
          font-size: ${uIFontSize.subTitle};
          width: 90%;
        }

        > ul {
          margin-left: 5%;

          > li {
            font-size: ${uIFontSize.paragraph};
          }
        }
      }

      > figure {
        width: 100%;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > div {
        width: 100%;

        > h2 {
          font-size: ${uIFontSize.subTitle};
          width: 100%;
        }

        > ul {
          padding-bottom: 5%;

          > li {
            font-size: 4.2vw;

            > p {
              margin-right: 2.5%;
            }
          }
        }
      }
    }

    > figure {
      width: 100%;
    }
  }
`;

export const StartRequest = styled.div`
  padding-top: 5%;
  padding-bottom: 5%;

  > div:nth-child(1) {
    display: flex;
    width: 90%;
    margin: 0 auto 1% auto;

    > div {
      width: 25%;

      > figure {
        text-align: center;
        width: 150px;
        height: 150px;
        margin: 0 auto 2.5% auto;
      }

      > h3 {
        color: ${graySphera};
        text-align: center;
        font-size: ${uIFontSize.h3};
      }

      > p {
        color: ${graySphera};
        text-align: center;
        font-size: ${uIFontSize.paragraph};
        width: 85%;
        margin: 2.5% auto 0 auto;
      }
    }
  }

  > div:nth-child(2) {
    > a {
      display: block;
      color: ${whiteSphera};
      font-size: 1.5vw;
      font-weight: bold;
      text-decoration: none;
      text-align: center;
      line-height: 60px;
      width: 20%;
      height: 60px;
      margin: 5% auto 0 auto;
      border-radius: 5px;
      background-color: ${blackSphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > div {
        > figure {
          width: 120px;
          height: 120px;
          margin: 0 auto 5% auto;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding-top: 5%;

    > div:nth-child(1) {
      flex-direction: column;
      margin: 5% auto 1% auto;

      > div {
        width: 100%;

        > figure {
          width: 110px;
          height: 110px;
          margin: 5% auto 2.5% auto;
        }

        > h3 {
          font-size: ${uIFontSize.h3};
        }

        > p {
          font-size: ${uIFontSize.paragraph};
          margin: 2.5% auto 2.5% auto;
        }
      }
    }

    > div:nth-child(2) {
      > a {
        font-size: ${uIFontSize.paragraph};
        line-height: 60px;
        width: 100%;
        height: 60px;
      }
    }
  }
`;

export const Sponsors = styled.section`
  background: ${greenSp};
  height: 200px;

  > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 90%;
    height: 100%;
    margin: auto;

    > figure:nth-child(1) {
      width: 300px;

      img {
        width: 100%;
        height: 100%;
        filter: grayscale(1);
      }
    }

    > figure:nth-child(2) {
      width: 110px;

      img {
        width: 100%;
        height: 100%;
        filter: grayscale(1);
      }
    }

    > figure:nth-child(3) {
      width: 250px;

      img {
        width: 100%;
        height: 100%;
        filter: grayscale(1);
      }
    }

    > figure:nth-child(4) {
      width: 250px;

      img {
        width: 100%;
        height: 100%;
        filter: grayscale(1);
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    height: 150px;

    > div {
      > figure:nth-child(1) {
        width: 60px;
      }

      > figure:nth-child(2) {
        width: 100px;
      }

      > figure:nth-child(3) {
        width: 40px;
      }

      > figure:nth-child(4) {
        width: 100px;
      }
    }
  }
`;

export const Transformation = styled.section`
  padding-bottom: 5%;

  > h2 {
    color: ${graySphera};
    font-size: 3vw;
    text-align: center;
    width: 40%;
    margin: 5% auto 5% auto;

    > span {
      color: ${greenSp};
      font-size: 3vw;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > h2 {
      > span {
      }
    }
  }

  @media (max-width: 600px) {
    > h2 {
      font-size: 5vw;
      width: 90%;
      margin: 10% auto;

      > span {
        font-size: 5vw;
      }
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: auto;

  @media (min-width: 600px) and (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    width: 90%;
  }
`;

export const Card = styled.div`
  padding: 4% 5%;
  width: 40%;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;

    > div {
      margin-bottom: 5%;

      > figure {
        width: 50px;
        height: 45px;
        margin-bottom: 40%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      > h3 {
        color: ${graySphera};
        font-size: 1.5vw;
      }

      > p {
        color: ${graySphera};
        font-size: 1.2vw;
      }
    }

    > figure {
      width: 140px;
      height: 140px;

      > img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }

  > div:nth-child(2) {
    > p {
      font-size: 1.1vw;
      color: ${grayLightSphera};
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(1) {
      > div {
        > figure {
          img {
          }
        }

        > h3 {
        }

        > p {
        }
      }

      > figure {
        > img {
        }
      }
    }

    > div:nth-child(2) {
      > p {
      }
    }
  }

  @media (max-width: 600px) {
    width: 100%;
    margin-bottom: 10%;

    > div:nth-child(1) {
      > div {
        > figure {
          width: 40px;
          height: 35px;

          img {
          }
        }

        > h3 {
          font-size: 4.5vw;
        }

        > p {
          font-size: 4.2vw;
        }
      }

      > figure {
        width: 140px;
        height: 140px;

        > img {
        }
      }
    }

    > div:nth-child(2) {
      > p {
        font-size: 4.1vw;
      }
    }
  }
`;

export const CarouselControllers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 12%;
  margin: 2.5% auto 0 auto;

  > figure:nth-child(1) {
    width: 25px;
    height: 35px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  > p:nth-child(2) {
    color: ${grayLightSphera};
    font-size: 1.3vw;
  }

  > figure:nth-child(3) {
    width: 25px;
    height: 35px;
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.1);
    }

    > img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure:nth-child(1) {
      &:hover {
      }

      img {
      }
    }

    > p:nth-child(2) {
    }

    > figure:nth-child(3) {
      &:hover {
      }

      > img {
      }
    }
  }

  @media (max-width: 600px) {
    width: 40%;

    > figure:nth-child(1) {
      &:hover {
      }

      img {
      }
    }

    > p:nth-child(2) {
      font-size: 4.3vw;
    }

    > figure:nth-child(3) {
      &:hover {
      }

      > img {
      }
    }
  }
`;
