import { getApplicant } from './../graphql/queries';
//---------  ACTUALIZADO AL 12 ENERO 2023 17:18 ---------\\
//--------- CUSTOMIZE QUERIES ---------\\
export const getApplicant_login = /* GraphQL */ `
  query GetApplicant_login($id: ID!) {
    getApplicant(id: $id) {
      id
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      onboarding {
        complete
        currentStep
        isBlocked
        reasonOfBlocking
      }
      personalInformation {
        email
      }
      trully {
        label
      }
    }
  }
`;

export const getInvestor_login = /* GraphQL */ `
  query GetInvestor_login($id: ID!) {
    getInvestor(id: $id) {
      id
      typeOfPerson
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      onboarding {
        complete
        currentStep
        isBlocked
        reasonOfBlocking
      }
      personalInformation {
        email
      }
      trully {
        label
      }
    }
  }
`;

export const getInvestor_Onboarding = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      userId
      mati {
        isFinished
      }
      personalInformation {
        firstName
        fullName
        email
        RFC
      }
    }
  }
`;

export const getAp_CompanyInformation = /* GraphQL */ `
  query GetAp_CompanyInformation($id: ID!) {
    getApplicant(id: $id) {
      id
      companyInformation {
        companyName
        email
        denomination
        profileImage
        RFC
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        country
        phone {
          land
          mobile
          isVerified
          lada
        }
        employees
        activity
        webSite
        invoicesValue
        businessAge
        FEA
        CIEC
        owners
      }
    }
  }
`;

export const getCreditApproved = /* GraphQL */ `
  query GetCreditAnalysis($applicantId: ID!) {
    getCreditAnalysis(applicantId: $applicantId) {
      applicantId
      isApproved
      rating
      environmentalAndSocialImpact {
        rating
        isApproved
      }
      corporateGovernance {
        rating
        isApproved
      }
      genderEquality {
        numberFemaleShareholdersValue
        numberFemaleShareholdersPoints
        numberFemaleDirectorsOnBoardValue
        numberFemaleDirectorsOnBoardPoints
        isCEOFemaleValue
        isCEOFemalePoints
        numberFemaleWithManagerialPositionValue
        numberFemaleWithManagerialPositionPoints
        numberFemaleEmployeesValue
        numberFemaleEmployeesPoints
        rating
        isApproved
      }
    }
  }
`;

//Querys para información de crédito en el onboarding
export const getObligadoSol = /* GraphQL */ `
  query GetObligadoSolidario($id: ID!) {
    getObligadoSolidario(id: $id) {
      id
      applicantId
      firstName
      lastNameM
      lastNameP
      personalInformation {
        fullName
      }
    }
  }
`;

export const listObligadoSolidariosTest = /* GraphQL */ `
  query ListObligadoSolidarios(
    $filter: ModelObligadoSolidarioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listObligadoSolidarios(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        applicantId
        firstName
        lastNameM
        lastNameP
      }
      nextToken
    }
  }
`;

//Querys para información de crédito en el onboarding
export const getApplicant_creditInfo = /* GraphQL */ `
  query getApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      companyInformation {
        companyName {
          legalName
        }
        RFC
      }
    }
  }
`;

export const getAddress_creditInfo = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      street
      country
      postalCode
      city
      state
      district
      neighborhood
    }
  }
`;

export const getCampaign_Confirm = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      montoSolicitado
      plazoCredito
      destinoCredito
      fuentePago
      dateStartCampaign
      dateEndCampaign
      tasaInteresAdicionalPorcentaje
      tasaCreditoPorcentaje
      interesCreditoPesos
      IVAinteresCreditoPesos
      interesesCreditoPesosConIVA
      comisionCreditoPesos
      IVAcomisionCreditoPesos
      comisionCreditoPesosConIVA
      inversionFinspheraCampaniaPorcentaje
      inversionFinspheraCampaniaPesos
      tasaInteresMoratorioPorcentaje
      isPromissoryNoteSigned
      status
      signDate
      applicant {
        companyInformation {
          companyName
        }
      }
    }
  }
`;

//Query para interestRateFinsphera en crear campañas
export const getCreditInfo = /* GraphQL */ `
  query GetCreditAnalysis($applicantId: ID!) {
    getCreditAnalysis(applicantId: $applicantId) {
      interestRateApplicant
    }
  }
`;

//Query para mostrar modales de resultado de análisis crediticio
export const getCreditResult = /* GraphQL */ `
  query GetCreditAnalysis($applicantId: ID!) {
    getCreditAnalysis(applicantId: $applicantId) {
      isApproved
      rating
    }
  }
`;

export const listCampaigns_Dash = /* GraphQL */ `
  query ListCampaigns($limit: Int, $nextToken: String) {
    listCampaigns(limit: $limit, nextToken: $nextToken) {
      # listCampaigns(filter: { isExpired: { eq: "0" } }, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        plazoCredito
        destinoCredito
        fuentePago
        isActive
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        tasaInteresAdicionalPorcentaje
        tasaCreditoPorcentaje
        interesCreditoPesos
        IVAinteresCreditoPesos
        interesesCreditoPesosConIVA
        comisionCreditoPesos
        IVAcomisionCreditoPesos
        comisionCreditoPesosConIVA
        inversionFinspheraCampaniaPorcentaje
        inversionFinspheraCampaniaPesos
        tasaInteresMoratorioPorcentaje
        signLimitDate
        signDate
        termCampaing
        status
        isExpired
        obligadoSolidarioId
        applicant {
          id
          personalInformation {
            fullName
            email
            RFC
          }
          companyInformation {
            companyName {
              legalName
            }
            email
            denomination
            profileImage
            RFC
            generalActivity
            specificActivity
            businessTurn
            businessSector
            isBusinessGroup
            societyType
            creationDate
            country
            employees
            activity
            webSite
            invoicesValue
            businessAge
            startedOperationsAt
            obligadoSolidario
          }
          economicActivity {
            id
            economicActivity
            specificActivity
            importToMexico
            exportFromMexico
            useCoinedMetals
            relationsWithCountries
            createdAt
            updatedAt
            owner
          }
        }
      }
    }
  }
`;

export const getApplicant_dashboardInfo = /* GraphQL */ `
  query getApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      companyInformation {
        companyName
        RFC
      }
      address {
        street
        postalCode
        neighborhood
        district
        city
        state
        country
      }
    }
  }
`;

export const getApplicant_address = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      street
      postalCode
      neighborhood
      district
      city
      state
      country
    }
  }
`;

export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      country
      postalCode
      city
      state
      district
      neighborhood
      street
      numInt
      numExt
      typeOfAddress
    }
  }
`;

//Query para información en Mi perfil Aplicante
export const getApplicantProfile = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      userId
      loanDestination
      createdAt
      isInMarketing
      isContractSigned
      isPLDApproved
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        RFC
        CURP
        maritalStatus
        occupation
        nationality
        phone {
          land
          mobile
          isVerified
          lada
        }
        dateOfBirth
      }
      companyInformation {
        companyName {
          legalName
        }
        email
        denomination
        profileImage
        RFC
        generalActivity
        specificActivity
        businessTurn
        businessSector
        isBusinessGroup
        societyType
        creationDate
        webSite
        phone {
          land
        }
      }
      address {
        items {
          id
          applicantId
          investorId
          fullAddress
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          streetType
          streetReferences
          statusRaw
          numInt
          numExt
        }
      }
      campaigns {
        items {
          id
          applicantId
          obligadoSolidarioId
          nombreCampania
          montoSolicitado
          valueOfInvoices
          collectedAmount
          isActive
          isExpired
          isSuccessful
          isPromissoryNoteSigned
          dateStartCampaign
          dateEndCampaign
          signLimitDate
          signDate
          plazoCredito
          paymentDate
          isMoneySent
          termCampaing
          status
          destinoCredito
          fuentePago
          createdAt
          updatedAt
          TIIE
          tasaInteresAdicionalPorcentaje
          tasaCreditoPorcentaje
          interesCreditoPesos
          IVAinteresCreditoPesos
          interesesCreditoPesosConIVA
          comisionCreditoPesos
          IVAcomisionCreditoPesos
          comisionCreditoPesosConIVA
          inversionFinspheraCampaniaPorcentaje
          inversionFinspheraCampaniaPesos
          tasaInteresMoratorioPorcentaje
          montoTransferencia
          plazoCreditoFinal
          paymentDateFinal
          interesCreditoPesosFinal
          IVAinteresCreditoPesosFinal
          interesesCreditoPesosConIVAFinal
          comisionCreditoPesosFinal
          IVAcomisionCreditoPesosFinal
          comisionCreditoPesosConIVAFinal
          interesesMoratorioPesosConIVA
          interesMoratorioPesos
          IVAinteresMoratorio
        }
      }
      STP {
        id
        CLABE
        recursosDisponibles
        compromisoInversion
        inversionesVigentes
        saldoTotal
      }
    }
  }
`;

//Query for the profile component
export const getInvestor_Profile = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      typeOfPerson
      createdAt
      isInMarketing
      userId
      isRisksSigned
      isContractSigned
      isPLDApproved
      personalInformation {
        firstName
        fullName
        lastNameP
        lastNameM
        surname
        profileImage
        email
        age
        underage
        RFC
        CURP
        specificActivity
        maritalStatus
        occupation
        generalActivity
        nationality
        phone {
          land
          mobile
          isVerified
          lada
        }
        dateOfBirth
        countryOfBirth
        stateOfBirth
        isPEP
        isFamilyPEP
      }
      ipAddress {
        IPv4
        country_code
        country_name
        city
        state
        date
      }
      geolocation {
        accuracy
        latitude
        longitude
        date
      }
      mifiels {
        items {
          id
          applicantId
          investorId
          type
          documentId
          send_mail
          signed_hash
          name
          signed_by_all
          signed
          signed_at
          created_at
          burned_at
          status {
            status
          }
        }
      }
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
          createdAt
          updatedAt
        }
      }
      companyInformation {
        companyName {
          legalName
        }
        denomination
        RFC
        generalActivity
        specificActivity
        obligadoSolidario
      }
      investments {
        items {
          id
          investorId
          campaignId
          compromisoInversion
          interesInversionistaPorcentaje
          interesInversionistaPesos
          retencionImpuestoInversionistaPorcentaje
          retencionImpuestoInversionistaPesos
          porcentajeGananciaInteres
          status
          campaign {
            id
            applicantId
            nombreCampania
            montoSolicitado
            valueOfInvoices
            collectedAmount
            isActive
            isExpired
            isSuccessful
            isPromissoryNoteSigned
            dateStartCampaign
            dateEndCampaign
            signLimitDate
            plazoCredito
            paymentDateFinal
            tasaInteresMoratorioPorcentaje
          }
        }
      }
      STP {
        id
        CLABE
        recursosDisponibles
        compromisoInversion
        inversionesVigentes
        saldoTotal
      }
    }
  }
`;

export const getMifiel_ProfileInv = /* GraphQL */ `
  query GetMifiel($id: ID!) {
    getMifiel(id: $id) {
      id
      applicantId
      investorId
      type
      name
      signed
      signed_at
      signers {
        widget_id
      }
    }
  }
`;

export const getMifiel_Profile = /* GraphQL */ `
  query GetMifielApplicantId($applicantId: ID!) {
    mifielsByApplicantId(applicantId: $applicantId) {
      items {
        id
        applicantId
        investorId
        campaignId
        type
        name
        signed_by_all
        signed
        signed_at
        createdAt
        signers {
          id
          name
          widget_id
        }
        status {
          status
        }
        signatures {
          id
          name
          signed
          signed_at
          certificate_number
          tax_id
          signature
        }
      }
    }
  }
`;

export const getBankAccountInfo = /* GraphQL */ `
  query GetBank($id: ID!) {
    getBank(id: $id) {
      id
      applicantId
      investorId
      identifier
      isMainAccount
      isValid
      messageId
      bankName
      CLABE
    }
  }
`;

export const getInvestorBankAccount = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      banks {
        items {
          CLABE
          identifier
          isMainAccount
          isMFA
          status
          messageId
          bankName
          bankCode
          #isDeleted
        }
      }
    }
  }
`;

export const getApplicantBankAccount = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      banks {
        items {
          CLABE
          identifier
          isMainAccount
          isMFA
          status
          messageId
          bankName
          bankCode
        }
      }
    }
  }
`;

export const getVerificationCode = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      id
      VerificationCode {
        messageId
        code
      }
    }
  }
`;

export const getApplicantFiles = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      address
      FEA
      INE {
        s3Front
        s3Back
        matiFrontUrl
        matiBackUrl
        emmissionDate
        expirationDate
        documentNumber
        ocrNumber
        documentType
        issueCountry
        simafContryCode
        ne
      }
      areBusinessDocumentsUpload
      businessDocuments {
        name
        isUploaded
        url
      }
    }
  }
`;

//Query para información en Mi perfil Aplicante
export const getApplicantPLD = /* GraphQL */ `
  query GetApplicant($id: ID!) {
    getApplicant(id: $id) {
      id
      userId
      isPLDApproved
    }
  }
`;

//Render beneficiaries on the investor profile
export const getInvestorBeneficiaries = /* GraphQL */ `
  query GetInvestor($id: ID!) {
    getInvestor(id: $id) {
      beneficiaries {
        items {
          id
          investorId
          firstName
          lastNameM
          lastNameP
          relationship
          dateOfBirth
          country
          postalCode
          city
          state
          district
          neighborhood
          street
          numInt
          numExt
          percentage
        }
      }
    }
  }
`;

//Trae data de tabla STP
export const getSTP_MainInfo = /* GraphQL */ `
  query GetSTP($id: ID!) {
    getSTP(id: $id) {
      id
      CLABE
    }
  }
`;

export const getInvestment = /* GraphQL */ `
  query GetInvestment($id: ID!) {
    getInvestment(id: $id) {
      id
      investorId
      campaignId
      compromisoInversion
      interesInversionistaPorcentaje
      interesInversionistaPesos
      retencionImpuestoInversionistaPorcentaje
      retencionImpuestoInversionistaPesos
      createdAt
      updatedAt
      investorInvestmentsId
      owner
      campaign {
        id
        applicantId
        nombreCampania
        montoSolicitado
        valueOfInvoices
        collectedAmount
        isActive
        isExpired
        isSuccessful
        isPromissoryNoteSigned
        dateStartCampaign
        dateEndCampaign
        signLimitDate
        signDate
        plazoCredito
        termCampaing
      }
    }
  }
`;
