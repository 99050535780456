import { InputContainer } from './inputField.styles';
import {
  forwardRef,
  useState,
  useEffect,
  ChangeEvent,
  RefObject,
  ComponentPropsWithoutRef
} from 'react';
import { InputProps } from './inputField.interface';
import { DialCodeSelect } from './DialCodeSelect';
import { ErrorMessage } from './ErrorMessage';
import { Eye, EyeOff } from 'src/assets/icons/interfaceIcons';

export const InputField = forwardRef<HTMLInputElement | HTMLSelectElement, InputProps>(
  (props, ref) => {
    const {
      value,
      onChangeInternalValue,
      id,
      label,
      regex,
      isDropdown = false,
      errorMessages = [],
      errorRegexMessage = 'El valor no es válido',
      autoUpperCase = false,
      isDialCode = false,
      errortype = 'error',
      name,
      onChangeError,
      wrapLabel = false,
      restrictRegex = false,
      type = 'text',
      blockDialCodeCountry,
      variant = 'outline',
      ...restProps
    } = props;
    const [internalValue, setInternalValue] = useState(value ?? '');
    const [errorRegex, setErrorRegex] = useState(false);
    const [dialCode, setDialCode] = useState('+52');
    const [showPassword, setShowPassword] = useState(false);

    const inputId = id || `input-${Math.random().toString(36).substr(2, 9)}`;

    useEffect(() => {
      setInternalValue(value ?? '');
    }, []);

    useEffect(() => {
      isInvalidInput(internalValue);

      onChangeInternalValue &&
        internalValue &&
        onChangeInternalValue((isDialCode ? dialCode + ' ' : '') + internalValue, {
          name: name || '',
          value: internalValue,
          label: label || '',
          actualOption: restProps.options?.find(
            (option) => option.value.toString() === internalValue
          )?.label
        });
    }, [internalValue]);

    useEffect(() => {
      onChangeError && onChangeError(errorRegex);
    }, [errorRegex]);

    const isInvalidInput = (value: string | number | readonly string[]) => {
      if (regex && value !== '') {
        const isValid = regex.test(value.toString());
        if (!isValid) return setErrorRegex(true);
      }

      return setErrorRegex(false);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value;
      const newValue = autoUpperCase ? rawValue.toUpperCase() : rawValue;

      if (isDialCode) {
        const onlyNumsAndParentheses = newValue.replace(/[^0-9() \-+]/g, '');
        setInternalValue(onlyNumsAndParentheses);
        return onChangeInternalValue?.(onlyNumsAndParentheses, {
          name: name || '',
          value: onlyNumsAndParentheses,
          label: label || ''
        });
      }

      if (restrictRegex && regex && !regex.test(newValue) && newValue !== '') return;

      setInternalValue(newValue);
      onChangeInternalValue?.(newValue, {
        name: name || '',
        value: newValue,
        label: label || ''
      });
    };

    const handleChangeDropdown = (e: ChangeEvent<HTMLSelectElement>) => {
      setInternalValue(e.target.value);
    };

    const handleChangeDialCode = (e: ChangeEvent<HTMLSelectElement>) => {
      setDialCode(e.target.value);
    };

    const handleEyeClick = () => {
      setShowPassword(!showPassword);
    };

    const renderInput = () => {
      if (isDropdown) {
        return (
          <select
            className={errorMessages.length > 0 || errorRegex ? 'is-error' : ''}
            ref={ref as RefObject<HTMLSelectElement>}
            value={internalValue}
            onChange={handleChangeDropdown}
            name={name}
            id={inputId}
            {...(restProps as ComponentPropsWithoutRef<'select'>)}
          >
            <option value="" disabled hidden>
              {restProps.placeholder}
            </option>
            {props.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      }

      return (
        <input
          className={errorMessages.length > 0 || errorRegex ? 'is-error' : ''}
          ref={ref as RefObject<HTMLInputElement>}
          value={internalValue}
          onChange={handleChange}
          maxLength={isDialCode ? 15 : undefined}
          type={
            isDialCode ? 'tel' : type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          name={name}
          id={inputId}
          {...(restProps as ComponentPropsWithoutRef<'input'>)}
        />
      );
    };

    return (
      <InputContainer
        wrapLabel={wrapLabel}
        isDialCode={isDialCode}
        errortype={errortype}
        variant={variant}
      >
        <label htmlFor={inputId}>{label}</label>

        <div className="input-wrapper">
          {isDialCode && (
            <DialCodeSelect
              onChange={handleChangeDialCode}
              value={dialCode}
              blockDialCodeCountry={blockDialCodeCountry}
            />
          )}

          {renderInput()}
          {type === 'password' &&
            (showPassword ? (
              <Eye onClick={handleEyeClick} className="svg-eye" />
            ) : (
              <EyeOff onClick={handleEyeClick} className="svg-eye" />
            ))}
        </div>
        {(errorRegex || errorMessages.length > 0) && (
          <ErrorMessage
            errorType={errortype}
            errorMessages={errorMessages}
            errorRegex={errorRegex}
            errorRegexMessage={errorRegexMessage}
          />
        )}
      </InputContainer>
    );
  }
);

InputField.displayName = 'InputField';
