import { Route } from 'react-router-dom';
import { PhoneConfirmation } from 'src/views/investor/Onboarding/OnboardingPhone/PhoneConfirmation';
import CreditInformation from 'src/views/applicant/Onboarding/onboarding_creditInfo/creditInformation';
import { AllowedUser } from 'src/routes/ProtectedRoute';
import Simaf from 'src/views/applicant/Onboarding/onboarding_simaf/simaf';
import { ProtectedOnboardingRoute } from 'src/components/shared/compositionComponents/ProtectedOnboardingRoute/ProtectedOnboardingRoute';
import { Metamap } from 'src/views/investor/Onboarding/Metamap/Metamap';
import BiometricsApplicant from 'src/views/applicant/Onboarding/onboarding_bio_satws/Biometrics';

const AllowedUserConfig: AllowedUser = {
  user: 'applicant',
  person: undefined
};

export const OnboardingApplicantRoutes = (
  <Route path="/solicitante">
    <Route
      path="identificacion"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserConfig]}>
          <Metamap typeOfUser="applicant" />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-data"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserConfig]}>
          <BiometricsApplicant />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-creditInfo"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserConfig]}>
          <CreditInformation />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-perfil-uso"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserConfig]}>
          <Simaf />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-phone"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserConfig]}>
          <PhoneConfirmation typeOfUser="applicant" />
        </ProtectedOnboardingRoute>
      }
    />
  </Route>
);
