import styled, { css } from 'styled-components/macro';
import { CardProps, CardRowProps } from './card.interface';
import { whiteSphera } from '../../../UIStyles/variables/colors';
import { breakpoints } from '../../../UIStyles/variables/breakpoints';

const commonStyles = css`
  background-color: ${whiteSphera};
  border-radius: 10px;
  position: relative;

  .card-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3.5%;
    row-gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .card-row:last-child {
    margin-bottom: 0;
  }

  .card-row > .is-half {
    grid-column: span 2;
  }

  .card-row > .is-three-quarters {
    grid-column: span 3;
  }

  @media (min-width: ${breakpoints.tablet}) {
    .card-row {
      row-gap: 1rem;

      margin-bottom: 1.5rem;
    }
  }
  @media (min-width: ${breakpoints.largeDesktop}) {
    .card-row {
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
  }
  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    .card-row {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
`;

export const CardContainer = styled.div<CardProps>`
  width: 100%;
  max-width: 32.5rem;
  padding: ${(p) => (p.isSlim ? '2rem' : '3.75rem')} 8.1%;
  height: fit-content;

  ${commonStyles}

  @media (min-width: ${breakpoints.largeDesktop}) {
    padding: 3.75rem 5%;
  }
`;

export const CardFormContainer = styled.form<CardProps>`
  padding: ${(props) => (props.isSlim ? '2rem' : '5rem')} 8.5%;
  box-shadow: 0px 4px 80px -10px rgba(0, 0, 0, 0.1);

  ${commonStyles}

  @media (min-width: ${breakpoints.largeDesktop}) {
    padding: 5rem 5%;
  }
`;

export const CardRowContainer = styled.div<CardRowProps>`
  ${(props) =>
    props.itemPosition === 'left' &&
    css`
      justify-items: start;
    `}

  ${(props) =>
    props.itemPosition === 'center' &&
    css`
      justify-items: center;
    `}

  ${(props) =>
    props.itemPosition === 'right' &&
    css`
      justify-items: end;
    `}

  ${(props) =>
    props.oneLine &&
    css`
      display: flex !important;
      align-items: center;
    `}

  ${(props) =>
    props.direction === 'column' &&
    css`
      display: flex !important;
      flex-direction: column;
    `}
`;
