import { Routes, Route } from 'react-router-dom';
import { Home } from 'src/views/new/landingPage/Home/Home';

export const LandingPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
    </Routes>
  );
};
