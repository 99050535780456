import styled from 'styled-components/macro';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';

import {
  blackSphera,
  grayLightSphera,
  grayPlaceholder,
  graySphera,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const CreditInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);

  .card-container {
    ${spaceFlowContainer}
    .card-row {
      .radio-group {
        width: 50%;
        justify-content: space-evenly;
        align-items: center;
        display: flex;
      }
    }
  }
`;

export const Banner = styled.div`
  ${spaceFlowContainer(true)}

  display: flex;
  align-items: center;
  background-color: ${whiteSphera};
  padding: 2.5% 0 2.5% 15%;
  > figure {
    width: 85px;
    height: 85px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: 100%;
    }
  }
  > h2 {
    color: ${blackSphera};
    font-size: 2.5vw;
    margin-right: 5%;
  }

  > p {
    color: ${graySphera};
    font-size: 1.5vw;
    width: 42%;
  }

  @media (max-width: 600px) {
    padding: 2.5% 0 2.5% 2.55%;
    justify-content: center;
    flex-wrap: wrap;

    > figure {
      width: 65px;
      height: 65px;

      > img {
      }
    }

    > h2 {
      font-size: 4vw;
      width: 70%;
    }

    > p {
      text-align: justify;
      font-size: 4vw;
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
    }
  }
`;

export const DataContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  > div {
    width: 45%;
    > p {
      color: ${blackSphera};
      font-size: 1.2vw;
      margin-top: 2.5%;
      margin-bottom: 1%;
    }

    > select {
      color: ${grayPlaceholder};
      font-size: 1.2vw;
      width: 100%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
    > input {
      color: ${grayPlaceholder};
      font-size: 1.2vw;
      width: 100%;
      height: 35px;
      padding-left: 2.5%;
      border: 1px solid ${grayLightSphera};
      border-radius: 5px;
    }
  }
`;

export const Container = styled.div`
  ${spaceFlowContainer}

  display: flex;
  justify-content: space-around;

  text-align: center;
`;
