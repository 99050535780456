import styled from 'styled-components/macro';
import { blackSphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const HomeContainer = styled.div``;

export const InvestUs = styled.section`
  background-color: #f8f8f9;
  padding: 5% 0;
  > div:nth-child(1) {
    > h2 {
      color: ${blackSphera};
      font-size: ${uIFontSize.subTitle};
      text-align: center;
    }
  }

  > div:nth-child(2) {
    display: flex;
    width: 90%;
    margin: 2.5% auto 1% auto;
    > div {
      width: 33%;

      > figure {
        text-align: center;
        width: 150px;
        height: 150px;
        margin: 0 auto 2.5% auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      > h3 {
        color: ${blackSphera};
        text-align: center;
        font-size: ${uIFontSize.h3};
      }

      > p {
        color: ${blackSphera};
        text-align: center;
        font-size: ${uIFontSize.paragraph};
        width: 90%;
        margin: 2.5% auto 0 auto;
      }
    }
  }

  @media (min-width: 2561px) {
    > div:nth-child(1) {
      > h2 {
        font-size: 2.8rem;
      }
    }
    > div:nth-child(2) {
      > div {
        > h3 {
          font-size: ${uIFontSize.xLarge};
        }
        > p {
          font-size: ${uIFontSize.large};
        }
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > div:nth-child(2) {
      > div {
        > figure {
          width: 120px;
          height: 120px;
          margin: 0 auto 5% auto;
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div:nth-child(2) {
      flex-direction: column;
      margin: 5% auto 1% auto;

      > div {
        width: 100%;

        > figure {
          width: 110px;
          height: 110px;
          margin: 5% auto 2.5% auto;
        }
      }
    }
  }
`;

export const OurServices = styled.section`
  display: flex;

  > div:nth-child(1) {
    width: 50%;
    background-color: #e7fd4f;
    padding: 5%;

    > h2 {
      color: ${blackSphera};
      font-size: ${uIFontSize.subTitle};
      margin-bottom: 15%;
    }

    > div {
      display: flex;
      width: 90%;
      > a {
        display: block;
        font-size: ${uIFontSize.paragraph};
        text-decoration: none;
        text-align: center;
        line-height: 40px;
        width: 240px;
        height: 40px;
        border-radius: 5px;
      }
      > a:nth-child(1) {
        color: ${whiteSphera};
        background-color: ${blackSphera};
        margin-right: 10%;
        cursor: pointer;
      }
      > a:nth-child(2) {
        color: ${blackSphera};
        border: 1px solid ${blackSphera};
      }
    }
  }

  > figure {
    width: 50%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: 2561px) {
    > div:nth-child(1) {
      > h2 {
        font-size: 3rem;
      }
      div {
        > a {
          font-size: ${uIFontSize.xLarge};
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: auto;

    > div:nth-child(1) {
      width: 100%;
      padding-bottom: 10%;

      > h2 {
        margin-top: 5%;
        margin-bottom: 10%;
      }
      > div {
        > a {
          line-height: 50px;
          height: 50px;
        }
      }
    }

    > figure {
      width: 100%;
    }
  }
`;
