import { Route } from 'react-router-dom';
import Biometrics from 'src/views/investor/Onboarding/onboarding_bioPhysical/Biometrics';
import BiometricsMoral from 'src/views/investor/Onboarding/onboarding_bioMoral/Biometrics';
import Risks from 'src/views/investor/Onboarding/onboardingRisks/OnboardingRisks';
import { PhoneConfirmation } from 'src/views/investor/Onboarding/OnboardingPhone/PhoneConfirmation';
import { AllowedUser } from 'src/routes/ProtectedRoute';
import SimafInfo from 'src/views/investor/Onboarding/Onboarding_simaf/Simaf';
import { ProtectedOnboardingRoute } from 'src/components/shared/compositionComponents/ProtectedOnboardingRoute/ProtectedOnboardingRoute';
import { Metamap } from 'src/views/investor/Onboarding/Metamap/Metamap';

const AllowedUseMoralConfig: AllowedUser = {
  user: 'investor',
  person: 'MORAL'
};

const AllowedUserPhysicalConfig: AllowedUser = {
  user: 'investor',
  person: 'FISICA'
};

export const OnboardingInvestorRoutes = (
  <Route path="/inversionista">
    <Route
      path="identificacion"
      element={
        <ProtectedOnboardingRoute
          allowedUsers={[...[AllowedUseMoralConfig, AllowedUserPhysicalConfig]]}
        >
          <Metamap typeOfUser="investor" />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-data-f"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUserPhysicalConfig]}>
          <Biometrics />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-data-m"
      element={
        <ProtectedOnboardingRoute allowedUsers={[AllowedUseMoralConfig]}>
          <BiometricsMoral />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-risks"
      element={
        <ProtectedOnboardingRoute
          allowedUsers={[...[AllowedUseMoralConfig, AllowedUserPhysicalConfig]]}
        >
          <Risks />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-perfil-uso"
      element={
        <ProtectedOnboardingRoute
          allowedUsers={[...[AllowedUseMoralConfig, AllowedUserPhysicalConfig]]}
        >
          <SimafInfo />
        </ProtectedOnboardingRoute>
      }
    />
    <Route
      path="onboarding-phone"
      element={
        <ProtectedOnboardingRoute
          allowedUsers={[...[AllowedUseMoralConfig, AllowedUserPhysicalConfig]]}
        >
          <PhoneConfirmation typeOfUser="investor" />
        </ProtectedOnboardingRoute>
      }
    />
  </Route>
);
