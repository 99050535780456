import styled from 'styled-components/macro';

import { fadeIn, fadeOut } from 'src/utilities/UIStyles/animations';

import { blackSphera, grayPlaceholder } from 'src/utilities/UIStyles/variables/colors';

export const TooltipBackup = styled.section`
  visibility: ${(props) => (props.showTooltip ? 'visible' : 'hidden')};
  position: absolute;
  top: 70%;
  left: 40%;
  animation: ${(props) => (props.showTooltip ? fadeIn : fadeOut)} 0.5s linear;

  > div {
    width: 200%;
    padding: 2% 0;
    background-color: rgb(248, 248, 249);

    > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2.5%;
      padding-left: 5%;
      padding-bottom: 2.5%;
      border-bottom: 1px solid ${grayPlaceholder};

      > h3 {
        color: ${blackSphera};
        font-size: 1.2vw;
      }

      > figure {
        width: 30px;
        height: 30px;
        margin-right: 2.5%;
        cursor: pointer;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > div:nth-child(2) {
      padding-left: 5%;
      padding-right: 5%;

      > p {
        font-size: 1.2vw;
        color: ${blackSphera};
      }
    }
  }
`;
