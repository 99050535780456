import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ModalCancelAccount,
  Figure,
  ActionSection,
  ConfirmSection,
  ButtonSection,
  ErrorDisplay
} from './cancelAccount.styles';
import { Auth } from 'aws-amplify';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { url } from 'src/environments';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';

export default function CancelAccountModal({
  typeOfUser,
  setCancelModal,
  cancelModal,
  activeCampaigns
}) {
  const dispatch = useDispatch();
  const [type, setType] = useState('password');
  const [localLoading, setLocalLoading] = useState(false);
  const [localError, setLocalError] = useState(null);
  const [errorText, setErrorText] = useState(false);
  //Para validar teléfono MFA
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  //Para habilitar botón
  const [isChecked, setIsChecked] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [password, setPassword] = useState('');

  const isButtonEnabled = isChecked && feedback && password;

  const user = useSelector((state) => state.user.currentUser);
  const navigate = useNavigate();

  const closeModal = () => {
    setCancelModal(false);
  };
  useEffect(() => {
    if (localError) setErrorText(true);
    if (!localError) setErrorText(false);
  }, [localError]);

  const handleSendCode = async () => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.signIn(currentUser.username, password);
      const input = {
        id: user.id,
        typeOfUser: typeOfUser,
        typeMessage: 'cancelledAccount',
        email: user.email,
        timestamp: Date.now()
      };
      axios
        .post(`${url}/admin/send-otp-to-email`, input)
        .then((response) => {
          const parsedBody = JSON.parse(response.data.body);
          setMessageId(parsedBody.messageId);
          setShowInput(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      setLocalError('La contraseña es incorrecta');
    }
  };

  const confirmCancelAccount = async () => {
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: typeOfUser
    };
    setLocalLoading(true);
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        setLocalError('El código ingresado no es válido');
        return;
      }
      await Auth.deleteUser();
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      setLocalError('Ocurrió un error, por favor intenta de nuevo');
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <ModalCancelAccount setCancelModal={setCancelModal} cancelModal={cancelModal}>
      <div>
        <Figure>
          <div></div>
          <figure onClick={closeModal}>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
              alt="icono para cerrar la ventana"
            />
          </figure>
        </Figure>
        <ActionSection>
          <h2>¡Estás a punto de cancelar tu cuenta con Finsphera!</h2>
          <p>
            Lamentamos mucho que estés tomando esta decisión, para poder llevar a cabo esta acción
            es necesario que nos proporciones la siguiente información:
          </p>
          <div>
            <InputField
              type={type}
              placeholder="Contraseña actual"
              label="Ingresa la contraseña con la que inicias sesión:"
              value={password}
              onChangeInternalValue={(value) => setPassword(value)}
              required
            />
          </div>
          <textarea
            placeholder="Por favor escribe una breve descripción del motivo de la cancelación"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <h4>
            Una vez que confirmes la solicitud, dejarás de pertenecer a la comunidad de Finsphera.
            Si en algún momento quisieras darte de alta de nueva cuenta en la plataforma, tendrás
            que crear un usuario desde cero y proporcionar toda la documentación para poder realizar
            operaciones dentro de Finsphera.
          </h4>
          <Checkbox
            label="Entiendo las consecuencias de esta acción"
            onChange={(e) => setIsChecked(e.target.checked)}
          />
        </ActionSection>
        {showInput ? (
          <ConfirmSection>
            <InputField
              label="Ingresa el código que enviamos a tu correo:"
              type="text"
              value={otp}
              onChangeInternalValue={(value) => setOtp(value)}
            />
            <Button
              isFullWidth
              size="small"
              type="submit"
              disabled={!isButtonEnabled}
              onClick={confirmCancelAccount}
              isLoading={localLoading}
            >
              Confirmar código
            </Button>
          </ConfirmSection>
        ) : (
          <ButtonSection>
            <Button size="small" type="submit" disabled={!isButtonEnabled} onClick={handleSendCode}>
              Cancelar cuenta
            </Button>
            <Button onClick={closeModal}>Cerrar</Button>
          </ButtonSection>
        )}
        {errorText && <ErrorDisplay>{localError}</ErrorDisplay>}
      </div>
    </ModalCancelAccount>
  );
}
