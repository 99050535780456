import styled from 'styled-components/macro';
import {
  brandBlack,
  whiteSphera,
  greenSp,
  brandYellowgreen,
  grayMediumSphera,
  blackSphera,
  graySphera,
  neutralBlueGray
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const DashboardHomeContainer = styled.section``;

export const DashboardHomeMain = styled.div`
  display: flex;
  margin-top: 3%;

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    padding: 2% 5%;
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 2% 5%;
  }
`;

export const DashboardWelcomeInfo = styled.div`
  width: 90%;
  margin: 1% auto 2.5% auto;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > h3 {
      font-size: ${uIFontSize.h3};
      font-weight: normal;
      color: ${graySphera};
    }
  }

  > div:nth-child(2) {
    width: 90%;
    margin: auto;
    border: 1px solid ${neutralBlueGray};
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5%;
    padding-bottom: 2.5%;

    > p {
      font-size: ${uIFontSize.paragraph};
      font-weight: bold;
      color: ${graySphera};
    }
  }

  @media (${breakpoints.tablet}) {
    width: 90%;

    > div:nth-child(1) {
      padding-top: 5%;
      padding-bottom: 5%;

      > h3 {
        font-size: ${uIFontSize.h3};
        text-align: center;
      }
    }

    > div:nth-child(2) {
    }

    > div:nth-child(3) {
      padding-top: 5%;
      padding-bottom: 5%;

      > p {
        font-size: ${uIFontSize.paragraph};
        text-align: center;
      }
    }
  }
`;

export const DashboardWelcomeDepositWithdrawButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  margin: 0 auto 5% auto;
  > button {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    color: ${whiteSphera};
    width: 45%;
    padding: 2% 0;
    border: none;
    border-radius: 5px;
    background-color: ${brandBlack};
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.05);
    }
  }

  @media (${breakpoints.tablet}) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto 5% auto;
    > button {
      font-size: ${uIFontSize.paragraph};
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
      &:hover {
      }
    }
  }
`;

export const DashboardHomeInvestmentOpportunity = styled.div`
  padding: 2.5%;
  background-color: rgb(248, 248, 249);
  display: flex;
  flex-wrap: wrap;
  > div:nth-child(1) {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    > h2 {
      font-size: ${uIFontSize.xxLarge};
      width: 100%;
      color: ${grayMediumSphera};
    }
    > h3 {
      font-size: ${uIFontSize.large};
      width: 100%;
      color: ${graySphera};
    }
    > p {
      font-size: ${uIFontSize.large};
      color: ${brandBlack};
      margin-bottom: 2.5%;
      width: 100%;
      text-align: center;
    }
    > a {
      color: ${whiteSphera};
      font-weight: bold;
      font-size: ${uIFontSize.paragraph};
      text-decoration: none;
      text-align: center;
      line-height: 40px;
      width: 80%;
      height: 40px;
      border: none;
      border-radius: 5px;
      background-color: ${brandBlack};
      cursor: pointer;
    }
  }

  > div:nth-child(2) {
    width: 40%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > figure {
      margin: 2% 5%;
      > img {
        width: 200px;
        height: 200px;
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 80%;
    }
  }

  @media (${breakpoints.tablet}) {
    padding: 5% 0 5% 5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    > div:nth-child(1) {
      width: 80%;
      > h2 {
        font-size: ${uIFontSize.subTitle};
        color: ${grayMediumSphera};
      }
      > p {
        font-size: ${uIFontSize.paragraph};
      }
      > a {
        width: 35%;
        font-size: ${uIFontSize.paragraph};
        height: 20px;
        line-height: 20px;
      }
    }
    > div:nth-child(2) {
      width: 60%;
      > h2 {
        font-size: ${uIFontSize.subTitle};
        color: ${greenSp};
      }
      > figure {
        margin: 2% 5%;
        > img {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    > h2 {
      color: ${greenSp};
      font-size: ${uIFontSize.subTitle};
      font-weight: bold;
      width: 80%;
      margin-right: 10%;
    }
    > h3 {
      color: ${brandBlack};
      font-size: ${uIFontSize.h3};
      font-weight: bold;
      width: 80%;
      margin-right: 10%;
    }
  }
  > h4 {
    color: ${brandBlack};
    font-size: ${uIFontSize.h3};
    font-weight: bold;
    width: 50%;
    padding: 0 2%;
  }
  > p {
    color: ${brandBlack};
    font-size: ${uIFontSize.paragraph};
    width: 80%;
    margin: 2% 10%;
    background-color: ${brandYellowgreen};
    padding: 2% 1%;
    > a {
      color: ${grayMediumSphera};
      font-weight: bold;
      border-bottom: 1px solid;
    }
  }

  @media (${breakpoints.tablet}) {
    > div {
      > h2 {
        font-size: ${uIFontSize.subTitle};
      }
      > h3 {
        font-size: ${uIFontSize.h3};
      }
    }
    > h4 {
      font-size: ${uIFontSize.subTitle};
    }
  }
`;

export const Campaigns = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 2% 5%;
  padding: 2%;
  justify-content: center;
  align-items: center;
  > div {
    width: 100%;
    background-color: ${brandYellowgreen};
    padding: 3% 1%;
    display: flex;
    flex-wrap: wrap;
    > h3 {
      color: ${brandBlack};
      font-size: ${uIFontSize.h3};
      width: 100%;
      text-align: center;
      margin-bottom: 2%;
      > a {
        text-align: center;
        color: ${blackSphera};
        font-weight: bold;
        padding: 0;
        border-bottom: 1px solid ${brandBlack};
        width: 30%;
        font-size: ${uIFontSize.paragraph};
      }
    }
    > h4 {
      color: ${brandBlack};
      font-size: ${uIFontSize.subTitle};
      width: 100%;
      text-align: center;
      margin-bottom: 2%;
      font-weight: lighter;
      > a {
        text-align: center;
        color: ${blackSphera};
        font-weight: bold;
        font-size: ${uIFontSize.subTitle};
        padding: 0;
        border-bottom: 1px solid ${brandBlack};
        width: 30%;
      }
    }
    > p {
      text-align: center;
      width: 100%;
      font-size: ${uIFontSize.paragraph};
      margin-top: 3%;
    }
    > a {
      text-align: center;
      background-color: ${brandBlack};
      color: ${whiteSphera};
      padding: 1%;
      border-radius: 10px;
      width: 40%;
      margin: 0 30%;
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.desktop}) {
    > div {
      > a {
        width: 30%;
        font-size: ${uIFontSize.paragraph};
        border-radius: 5px;
        margin: 0 35%;
      }
      > p {
        width: 100%;
        font-size: ${uIFontSize.paragraph};
      }
    }
  }

  @media (${breakpoints.tablet}) {
    padding: 5% 0 5% 5%;
    > div {
      > a {
        width: 30%;
        font-size: ${uIFontSize.paragraph};
        border-radius: 5px;
        margin: 0 35%;
      }
      > p {
        width: 100%;
        font-size: ${uIFontSize.paragraph};
      }
    }
  }
`;
