import axios from 'axios';
import { url } from 'src/environments';

export const reqSatCompany = async (body) => {
  try {
    const response = await axios.post(`${url}/satws/credit-analysis/autofill`, body);
    return response.data;
  } catch (error) {
    console.error('Error in reqSatCompany:', error);
    throw error;
  }
};
