import { useState, useEffect } from 'react';
import {
  DetailInvestPay,
  Recordatory,
  ModalCancelInvestment,
  Success,
  AccountDetails,
  AccountSection,
  AccountInfo
} from './cancelInvestment.styles';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { url } from 'src/environments';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { Link } from 'react-router-dom';

export default function CancelInvestmentInvestor({
  showModalCancelInvest,
  listBids,
  setCancelInvestmentInvestor
}) {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState(false);
  const [detailInvestPay, setDetailInvestPay] = useState(showModalCancelInvest);
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  //Para STP
  // const amount = listBids?.compromisoInversion;
  // const company = personalInformation?.data?.getInvestor?.companyInformation;
  // const personal = personalInformation?.data?.getInvestor?.personalInformation;
  // const account = personalInformation?.data?.getInvestor?.STP;
  // const finspheraAccount = process.env.REACT_APP_STPACCOUNT_FINSPHERA;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const calculatePercentageCollected = () => {
    const collectedAmount = listBids?.campaign?.collectedAmount;
    const montoSolicitado = listBids?.campaign?.montoSolicitado;
    if (collectedAmount && montoSolicitado && montoSolicitado > 0) {
      return (collectedAmount / montoSolicitado) * 100;
    }
    return 0;
  };
  useEffect(() => {
    setDetailInvestPay(showModalCancelInvest);
  }, [showModalCancelInvest]);

  const closeModal = () => {
    setDetailInvestPay(false);
    setCancelInvestmentInvestor(false);
  };
  const handleSendEmail = () => {
    const input = {
      id: user.id,
      typeOfUser: 'investor',
      typeMessage: 'regretMoney',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
      });
    setShowInput(true);
  };

  const handleOtpSubmit = async () => {
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: 'investor'
    };
    try {
      const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
      const parsedBody = JSON.parse(validationCode.data.body);
      if (!parsedBody.isVerified) {
        alert('Validación de código fallida');
        return;
      }
      const resources = {
        clientId: user.id,
        amount: listBids?.compromisoInversion,
        campaignId: listBids?.campaign?.id,
        type: 'cancelacion',
        investmentId: listBids?.id
      };
      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/update-investor-balances-in-STP`,
        headers: {
          apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
          'Content-Type': 'application/json'
        },
        data: resources
      };
      try {
        const response = await axios(config);
        if (
          response.data.status === 201 &&
          response.data.message === 'Investor amounts updated correctly'
        ) {
          setSuccessText(true);
        } else {
          setErrorText(true);
          console.error('Request completed, but with a non-successful status:', response);
        }
      } catch (error) {
        console.error(error);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const isExpired = listBids?.campaign?.isExpired === '1';
  // const isSigned = listBids?.campaign?.isPromissoryNoteSigned === '1' ? 'Firmado' : 'No firmado';
  // const isSuccessful = listBids?.campaign?.isSuccessful === '0' ? 'Incompleta' : 'Exitosa';

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }

  return (
    <ModalCancelInvestment showModalCancelInvest={showModalCancelInvest}>
      {!successText && !errorText ? (
        <div>
          <div>
            <h3>Cancelar inversión</h3>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="icono para cerrar la ventana"
              />
            </figure>
          </div>
          <DetailInvestPay isExpired={isExpired}>
            <h3>Estás a punto de cancelar tu inversión:</h3>
            <AccountDetails>
              <div>
                <h4>Monto invertido en la campaña:</h4>
                <p>
                  {formatCurrency(listBids?.compromisoInversion)
                    ? formatCurrency(listBids?.compromisoInversion)
                    : '-'}
                </p>
              </div>
              <div>
                <h4>Monto solicitado de campaña:</h4>
                <p>
                  {formatCurrency(listBids?.campaign?.montoSolicitado)
                    ? formatCurrency(listBids?.campaign?.montoSolicitado)
                    : '0'}
                </p>
              </div>
              <div>
                <h4>Monto recaudado de campaña:</h4>
                <p>{formatCurrency(listBids?.campaign?.collectedAmount)}</p>
              </div>
            </AccountDetails>
            <AccountSection>
              <AccountInfo>
                <div>
                  <h4>Porcentaje reunido del total de la campaña:</h4>
                  <p>{calculatePercentageCollected()} %</p>
                </div>
                <div>
                  <h4>Estado de la campaña:</h4>
                  <p>{listBids?.campaign?.isActive === '1' ? 'Activa' : 'Finalizada'}</p>
                </div>
                <div>
                  <h4>Fecha de cierre de campaña:</h4>
                  <p>
                    {formatDate(listBids?.campaign?.dateEndCampaign)
                      ? formatDate(listBids?.campaign?.dateEndCampaign)
                      : '-'}
                  </p>
                </div>
              </AccountInfo>
              <AccountInfo>
                <div>
                  <h4>Porcentaje invertido del total de la campaña:</h4>
                  <p>
                    {listBids?.porcentajeGananciaInteres.toFixed(2) * 100
                      ? listBids?.porcentajeGananciaInteres.toFixed(2) * 100
                      : '-'}
                    %
                  </p>
                </div>
                <div>
                  <h4>Intereses de campaña fondeada al 100%:</h4>
                  <p>
                    {formatCurrency(listBids?.interesInversionistaPesos)
                      ? formatCurrency(listBids?.interesInversionistaPesos)
                      : '-'}
                  </p>
                </div>
                <div>
                  <h4>Fecha de cierre de campaña:</h4>
                  <p>
                    {formatDate(listBids?.campaign?.dateEndCampaign)
                      ? formatDate(listBids?.campaign?.dateEndCampaign)
                      : '-'}
                  </p>
                </div>
              </AccountInfo>
            </AccountSection>
            <Recordatory>
              {showInput ? (
                <div>
                  <p>Ingresa el código que enviamos a tu correo:</p>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                  <Button onClick={handleOtpSubmit}>Confirmar código</Button>
                </div>
              ) : (
                <div>
                  <Button onClick={handleSendEmail}>Aceptar</Button>
                  <p>
                    * Recuerda que si deseas volver a invertir en esta campaña, los cálculos de los
                    intereses generados y el porcentaje del total invertido pueden variar respecto a
                    los que actualmente tienes.
                  </p>
                </div>
              )}
            </Recordatory>
          </DetailInvestPay>
        </div>
      ) : successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de operación exitosa"
            />
          </figure>
          <h2>¡Tu inversión ha sido cancelada!</h2>
          <p>
            Puedes ver el estatus de tus inversiones en tu{' '}
            <Link to="/inversionista/dashboard/inversiones">Panel de inversiones</Link>
          </p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_error.png`}
              alt="icono de error"
            />
          </figure>
          <h2>¡Ocurrió un error en tu solicitud!</h2>
          <p>Tenemos un problema con este requerimiento. Por favor inténtalo más tarde.</p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalCancelInvestment>
  );
}
