import { ChangeEvent, useState } from 'react';

const useHandleNumber = ({ type, name }: { type: string; name: string }) => {
  const [data, setData] = useState<undefined | string>('');
  const [error, setError] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const number = /^[0-9]*$/;

    if (number.test(e.target.value) === true) {
      setData(e.target.value);
      setError(false);
    } else {
      setData(undefined);
      setError(true);
    }
  };

  return {
    type,
    name,
    data,
    error,
    onChange
  };
};

export default useHandleNumber;
