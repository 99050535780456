import { ChangeEvent, forwardRef, useState } from 'react';
import { CountrySelectorProps, Country } from './countrySelector.interface';
import { SelectorContainer } from './countrySelector.styles';
import { countryListFiltered } from '../../utils/countryListFiltered';
import { ErrorMessage } from '../InputField/ErrorMessage';

export const CountrySelector = forwardRef<HTMLSelectElement, CountrySelectorProps>((props, ref) => {
  const {
    defaultValue,
    errortype = 'error',
    errorMessages = [],
    label,
    wrapLabel = false,
    id,
    countries,
    className,
    placeholder = 'Selecciona un país',
    onChange,
    ...rest
  } = props;
  const [isSelected, setIsSelected] = useState(false);

  const inputId = id || `input-${Math.random().toString(36).substr(2, 9)}`;

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(e);
    setIsSelected(true);
  };

  return (
    <SelectorContainer
      defaultValue={defaultValue}
      errortype={errortype}
      className={className}
      wrapLabel={wrapLabel}
    >
      {label ? <label htmlFor={inputId}>{label}</label> : null}

      <select
        ref={ref}
        className={errorMessages.length > 0 ? 'is-error' : ''}
        onChange={handleChange}
        {...rest}
      >
        {!isSelected ? (
          <option value="" disabled={isSelected}>
            {placeholder}
          </option>
        ) : null}
        {(countries || countryListFiltered).map((country: Country) => (
          <option key={country.name_es} value={country.name_es}>
            {country.flag_emoji} {country.name_es}
          </option>
        ))}
      </select>
      {errorMessages.length > 0 ? (
        <ErrorMessage errorType={errortype} errorMessages={errorMessages} />
      ) : null}
    </SelectorContainer>
  );
});

CountrySelector.displayName = 'CountrySelector';
