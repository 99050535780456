import styled from 'styled-components/macro';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { blackSphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const FooterContainer = styled.footer`
  background-color: ${blackSphera};
  color: ${whiteSphera};
  padding: 2.5% 5%;

  > h2 {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    margin-bottom: 1rem;
  }

  > p {
    font-size: ${uIFontSize.paragraph};
    text-align: justify;
    line-height: 25px;
    width: 95%;
    margin-bottom: 2rem;

    > a {
      color: ${whiteSphera};
      text-decoration: none;
      font-weight: 900;
    }

    > span {
      font-weight: bold;
    }
  }
`;

export const LastPartFooter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > h3 {
    font-size: ${uIFontSize.paragraph};
    font-weight: bold;
    width: 100%;
  }
`;

export const MediaCont = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60%;
  margin-right: 23%;

  > figure {
    width: 45px;
    height: 45px;
    margin-right: 2%;

    > a {
      > img {
        width: 100%;
        height: auto;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 40%;
    margin-right: 35%;

    > figure {
      margin-right: 5%;
    }
  }
`;

export const LogoCont = styled.figure`
  width: 5rem;

  > img {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 8rem;
  }
`;
