export const occupations = [
  { name: 'Desocupado (Buscando Trabajo)', code: 10 },
  { name: 'Ama De Casa', code: 20 },
  { name: 'Ayuda Con Quehaceres Del Hogar', code: 21 },
  { name: 'Desocupado (Adulto Que No Busca Empleo Ni Ayuda Con El Quehacer Del Hogar)', code: 30 },
  { name: 'Persona En Edad Escolar, No Especificado', code: 40 },
  { name: 'Persona En Edad Escolar O Menor Que No Asiste A La Escuela', code: 41 },
  { name: 'Estudiante', code: 42 },
  { name: 'Estudiante Y Trabajador', code: 43 },
  { name: 'Retirado, No Especificado', code: 50 },
  { name: 'Retirado Sin Pensión', code: 51 },
  { name: 'Jubilado', code: 52 },
  { name: 'Retirado Por Discapacidad', code: 53 },
  { name: 'Pensionado Que Trabaja', code: 54 },
  { name: 'Otro No Especificado (Discapacitado, Preso, Turista, Etcétera)', code: 60 },
  { name: 'Discapacitado, Enfermo', code: 61 },
  { name: 'Preso', code: 62 },
  { name: 'Turista', code: 63 },
  { name: 'En Asistencia Social', code: 64 },
  { name: 'Otro, Desempleado', code: 99 },
  {
    name: 'Arquitectos, Ingenieros Civiles, Ingenieros Químicos, Industriales Y Similares',
    code: 110
  },
  { name: 'Físicos, Astrónomos, Matemáticos, Estadísticos Y Actuarios', code: 111 },
  { name: 'Químicos Y Farmacólogos', code: 112 },
  { name: 'Médicos, Odontólogos, Optometristas, Nutriólogos Y Similares', code: 113 },
  { name: 'Biólogos, Ecólogos, Profesionistas En Ciencias Del Mar Y Similares', code: 114 },
  { name: 'Agrónomos, Veterinarios Y Profesionistas En Forestación Y Pesca', code: 115 },
  { name: 'Profesionistas En Ciencias Sociales', code: 116 },
  { name: 'Economistas, Administradores De Empresas, Contadores Públicos Y Similares', code: 117 },
  { name: 'Religiosos Profesionistas', code: 118 },
  {
    name: 'Otros Profesionistas Que Desempeñan Una Ocupación Afín A Las Comprendidas En Este Grupo',
    code: 119
  },
  {
    name: 'Técnicos En Dibujo, Ingeniería Y Operación De Equipos De Grabación De Imagen Y Sonido',
    code: 120
  },
  { name: 'Técnicos En Física, Matemáticas Y Actuaría', code: 121 },
  { name: 'Técnicos En Medicina Humana (Enfermeros, Técnicos Dentales)', code: 122 },
  { name: 'Técnicos Laboratoristas Químicos, Biólogos, Farmacéuticos Y Ecólogos', code: 123 },
  { name: 'Técnicos En Agronomía, Veterinaria, Forestación, Pesca Y Similares', code: 124 },
  { name: 'Técnicos En Ciencias Sociales, Contables Y Administrativas', code: 125 },
  { name: 'Técnicos Actividades Religiosas', code: 126 },
  {
    name: 'Profesores Universitarios Y De Otros Establecimientos De Enseñanza Superior',
    code: 130
  },
  { name: 'Profesores De Preparatorios Y Equivalentes', code: 131 },
  { name: 'Profesores De Enseñanza Secundaria', code: 132 },
  { name: 'Profesores De Enseñanza Primaria Y Alfabetización', code: 133 },
  { name: 'Profesores De Enseñanza Preescolar', code: 134 },
  { name: 'Profesores De Enseñanza Especial', code: 135 },
  {
    name: 'Profesores E Instructores De Educación Artística, Administrativa, Técnica Y Deportiva',
    code: 136
  },
  {
    name: 'Otros Trabajadores Que Desempeñan Una Ocupación Afín A Las Comprendidas En Este Grupo',
    code: 139
  },
  { name: 'Escritores, Críticos, Periodistas Y Redactores', code: 140 },
  { name: 'Compositores, Cantantes, Músicos, Actores Y Bailarines', code: 141 },
  { name: 'Pintores, Escultores, Dibujantes, Diseñadores, Coreógrafos Y Similares', code: 142 },
  {
    name: 'Directores, Productores, Locutores Y Conductores De Espectáculos Y Programas Artísticos, Culturales Y Deportivos',
    code: 143
  },
  { name: 'Deportistas', code: 144 },
  { name: 'Árbitros, Jueces Y Entrenadores Deportivos', code: 145 },
  {
    name: 'Animadores, Magos, Ilusionistas, Payasos, Cirqueros Y Similares En Establecimientos',
    code: 146
  },
  { name: 'Funcionarios Gubernamentales Superiores Y Legisladores', code: 210 },
  {
    name: 'Presidentes, Directores Y Gerentes Generales En Instituciones Y Empresas Públicas Y Privadas',
    code: 211
  },
  {
    name: 'Directores, Gerentes Y Administradores De Área En Establecimientos, Empresas, Instituciones Y Negocios Públicos Y Privados',
    code: 212
  },
  {
    name: 'Directivos De Organizaciones Políticas, Sindicales Y De Asociaciones Civiles',
    code: 213
  },
  { name: 'Dueños De Pequeñas Y Medianas Fábricas ', code: 214 },
  { name: 'Dueños De Pequeños Y Medianos Establecimientos De Servicios', code: 215 },
  { name: 'Trabajadores En Actividades Agrícolas', code: 410 },
  { name: 'Trabajadores En Actividades Ganaderas Y En La Cría De Otros Animales', code: 411 },
  { name: 'Trabajadores Que Combinan Actividades Agrícolas Con Ganaderas', code: 412 },
  { name: 'Trabajadores En Actividades Silvícolas Y Forestales', code: 413 },
  { name: 'Trabajadores En Actividades De Caza, Trampería Y Similares', code: 414 },
  { name: 'Trabajadores En Actividades Pesqueras Y De Acuacultura', code: 415 },
  {
    name: 'Trabajadores En Actividades De Beneficio De Productos Agropecuarios Y Pesqueros',
    code: 416
  },
  {
    name: 'Capataces, Caporales, Mayorales Y Otros Trabajadores De Control De Las Actividades Agropecuarias, Silvícolas Y Pesqueras',
    code: 417
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación De Alimentos, Bebidas Y Productos De Tabaco',
    code: 510
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Extracción En Minas, Canteras Y Pozos',
    code: 511
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación De Textiles Y Productos De Cuero, Piel Y Similares',
    code: 512
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación De Productos De Madera Y Papel, Y En Trabajos De Impresión',
    code: 513
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación Metalúrgica Y En La Fabricación, Reparación Y Mantenimiento De Maquinaria Y Productos Metálicos Y De Precisión',
    code: 514
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación De Productos De Cerámica, Vidrio Y Otros Minerales No Metálicos',
    code: 515
  },
  {
    name: 'Jefes, Supervisores, Contratistas Y Similares En La Construcción, Instalación, Mantenimiento Y Acabados',
    code: 516
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Generación De Energía, Instalación, Reparación Y Mantenimiento De Equipo Eléctrico Y De Telecomunicaciones',
    code: 517
  },
  {
    name: 'Jefes, Supervisores Y Similares En La Fabricación De Químicos, Petroquímica, Hule, Caucho Y Plástico',
    code: 518
  },
  {
    name: 'Trabajadores En La Elaboración De Alimentos, Bebidas Y Productos De Tabaco (Incluye Trabajadores En La Elaboración De Comidas)',
    code: 520
  },
  { name: 'Trabajadores En Minas Y Canteras', code: 521 },
  {
    name: 'Artesanos Y Trabajadores Fabriles En La Elaboración De Productos Textiles, Cuero, Piel Y Similares (Incluye Sastres, Tapiceros, Costureras, Bordadores)',
    code: 522
  },
  {
    name: 'Artesanos Y Trabajadores Fabriles En La Elaboración De Productos De Madera Y Similares, Papel Y Trabajos De Impresión (Incluye Carpinteros, Ebanistas, Linotipistas, Reveladores De Fotografías)',
    code: 523
  },
  {
    name: 'Artesanos Y Trabajadores Fabriles En El Tratamiento De Metales Y En La Reparación Y Mantenimiento De Vehíc, Maquinaria, Equipos, Instrumentos Y Similares (Incluye Moldeadores, Soldadores, Joyeros, Orfebres, Cerrajeros, Pulidores De Metal, )',
    code: 524
  },
  {
    name: 'Artesanos Y Trabajadores Fabriles En La Elaboración De Productos De Cerámica, Azulejo, Vidrio Y Otros Minerales No Metálicos (Incluye Alfareros Y Trabajadores Del Vidrio)',
    code: 525
  },
  {
    name: 'Trabajadores En La Construcción, Instalación, Acabados Y Mantenimiento De Edificios Y Otras Construcciones (Incluye Albañiles; Pintores De Brocha Gorda; Yeseros; Techadores; Instaladores De Mosaicos, Azulejos, Pisos Y Baldosas; Plomeros)',
    code: 526
  },
  {
    name: 'Trabajadores En La Instalación Y Reparación De Equipos Eléctricos, Electrónicos Y De Telecomunicaciones',
    code: 527
  },
  {
    name: 'Trabajadores En La Elaboración Y Reparación De Productos De Hule, Caucho, Plástico Y La Preparación De Sustancias Químicas',
    code: 528
  },
  {
    name: 'Operadores De Máquinas Y Equipos En La Fabricación De Alimentos, Bebidas Y Productos De Tabaco',
    code: 530
  },
  {
    name: 'Operadores De Máquinas Y Equipos Para La Extracción En Minas, Canteras Y Pozos',
    code: 531
  },
  {
    name: 'Operadores De Máquinas Y Equipos En La Fabricación De Textiles Y Productos De Cuero, Piel Y Similares',
    code: 532
  },
  {
    name: 'Operadores De Máquinas En La Fabricación De Productos De Madera Y Similares, Papel Y Trabajos De Impresión',
    code: 533
  },
  {
    name: 'Operadores De Máquinas Y Equipos En La Fabricación Metalúrgica, Fabricación De Maquinaria, Productos Metálicos E Industria Automotriz',
    code: 534
  },
  {
    name: 'Operadores De Máquinas Y Equipos En La Fabricación De Productos De Cerámica, Vidrio Y Similares',
    code: 535
  },
  {
    name: 'Operadores De Equipo Portátil Especializado Para La Construcción (Perforadora, Barrenadota, Compresora)',
    code: 536
  },
  {
    name: 'Operadores De Máquinas E Instalaciones Para La Generación De Energía Y Equipos De Bombeo Y Refrigeración',
    code: 537
  },
  {
    name: 'Operadores De Máquinas Y Equipos En La Fabricación De Químicos, Tratamiento De Agua, Petroquímica, Hule Y Plástico',
    code: 538
  },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación De Alimentos, Bebidas Y Productos De Tabaco',
    code: 540
  },
  { name: 'Ayudantes, Peones Y Similares En La Extracción En Minas, Canteras Y Pozos', code: 541 },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación De Textiles Y Productos De Cuero, Piel Y Similares',
    code: 542
  },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación De Productos De Madera Y Papel Y Trabajos De Impresión',
    code: 543
  },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación Metalúrgica Y En La Fabricación De Maquinaria Y Productos Metálicos',
    code: 544
  },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación De Productos De Cerámica, Vidrio Y Otros Minerales No Metálicos',
    code: 545
  },
  { name: 'Ayudantes, Peones Y Similares En La Construcción', code: 546 },
  {
    name: 'Ayudantes, Peones Y Similares En La Generación De Energía, La Electrónica Y Las Telecomunicaciones',
    code: 547
  },
  {
    name: 'Ayudantes, Peones Y Similares En La Fabricación De Químicos, Petroquímica, Hule, Caucho Y Plástico',
    code: 548
  },
  { name: 'Conductores De Maquinaria Móvil', code: 550 },
  { name: 'Conductores Y Ayudantes De Conductores De Transporte En Vías Férreas', code: 551 },
  {
    name: 'Conductores Y Ayudantes De Conductores De Transporte Terrestre Con Motor (Ver También 712) Y Automóviles De Pasajeros',
    code: 552
  },
  { name: 'Conductores De Transporte Aéreo', code: 553 },
  { name: 'Conductores Y Ayudantes De Conductores De Transporte Marítimo', code: 554 },
  { name: 'Conductores De Vehículos De Transporte De Tracción Humana Y Animal', code: 555 },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios De Salud, Asistencia Social, Educación Y Justicia',
    code: 610
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Contabilidad, Finanzas, Recursos Humanos, Archivo Y Similares',
    code: 611
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Comunicaciones Y Transportes',
    code: 612
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios Estadísticos, Informáticos, De Ingeniería, En Investigaciones Sociales, Publicidad Y Otros Servicios Especializados',
    code: 613
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios De Infraestructura (Agua, Luz, Caminos, Etc.)',
    code: 614
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios Culturales Y De Esparcimiento',
    code: 615
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios De Restaurante, Hospedaje Y Comercio',
    code: 616
  },
  {
    name: 'Jefes De Departamento, Coordinadores Y Supervisores En Servicios Agropecuarios, Pesqueros Y Forestales',
    code: 617
  },
  {
    name: 'Otros Jefes De Departamento, Coordinadores Y Supervisores En Actividades No Clasificadas Anteriormente',
    code: 618
  },
  { name: 'Secretarias, Taquígrafos, Capturistas Y Similares', code: 620 },
  { name: 'Cajeros, Cobradores, Taquilleros Y Similares', code: 621 },
  { name: 'Trabajadores En Archivo, Correspondencia Y Control De Almacenes Y Bodegas', code: 622 },
  {
    name: 'Recepcionistas, Trabajadores De Agencias De Viajes, Encuestadores Y Similares',
    code: 623
  },
  { name: 'Telefonistas Y Telegrafistas', code: 624 },
  { name: 'Trabajadores En Servicios De Correos Y Mensajería En General', code: 625 },
  { name: 'Despachadores, Chocadores Y Similares En La Operación Del Transporte', code: 626 },
  {
    name: 'Otros Trabajadores En Servicios Administrativos No Clasificados Anteriormente',
    code: 627
  },
  {
    name: 'Otros Trabajadores Que Desempeñan Una Ocupación Afín A Las Comprendidas En Este Grupo',
    code: 629
  },
  {
    name: 'Comerciantes En Establecimientos (Para Dueños De Fábricas, Ver 214; Para Dueños De Establecimientos De Servicios Medianos Y Pequeños, Ver 215)',
    code: 710
  },
  {
    name: 'Empleados De Comercio En Establecimientos (Incluye Encargados Y Responsables De Comercio; Despachadores Y Dependientes De Comercio)',
    code: 711
  },
  { name: 'Demostradores Y Repartidores En Establecimientos', code: 712 },
  {
    name: 'Agentes Y Representantes De Ventas, Corredores De Valores, Seguros, Bienes Raíces, Subastadores Y Rematadores',
    code: 713
  },
  { name: 'Vendedores Ambulantes (De Periódico, Lotería Y Artículos Diversos)', code: 720 },
  {
    name: 'Trabajadores Ambulantes En Servicios: Vendedores Y Preparadores De Alimentos En La Vía Pública; Limpiadores De Calzado; Lavadores Y Cuidadores De Coche}; Payasos, Malabaristas Y Similares Que Se Presentan En La Vía Pública',
    code: 721
  },
  { name: 'Fonderos, Cantineros, Meseros Y Azafatas', code: 810 },
  { name: 'Lavanderos, Planchadores Y Otros Trabajadores En La Limpieza De Ropa', code: 811 },
  {
    name: 'Porteros, Conserjes, Ascensoristas, Mozos De Hotel, Trabajadores De Limpieza, Jardineros Y Cargadores',
    code: 812
  },
  { name: 'Peluqueros, Embellecedores Y Similares', code: 813 },
  {
    name: 'Trabajadores En Servicios De Alquiler De Bienes Muebles (Autos, Vajillas, Maquinaria, Etc.)',
    code: 814
  },
  {
    name: 'Trabajadores De Apoyo Para La Realización De Espectáculos, Turismo, Deportes Y En Cuidados Personales',
    code: 815
  },
  { name: 'Trabajadores En Servicios Funerarios Y En Panteones', code: 816 },
  {
    name: 'Trabajadores En Servicios Domésticos: Personas Dedicadas Al Cuidado De Ancianos, Enfermos Y Niños En Casas Particulares, Excepto Enfermeras; Choferes; Jardineros; Vigilantes Y Porteros En Casas Particulares',
    code: 820
  },
  {
    name: 'Trabajadores En Servicios De Protección Y Vigilancia (Bomberos, Policías, Detectives, Personal De Aduanas Y Migración)',
    code: 830
  },
  { name: 'Trabajadores De Las Fuerzas Armadas', code: 831 },
  { name: 'Ayudantes De Conductor', code: 5522 },
  { name: 'Otros Trabajadores Con Ocupaciones Insuficientemente Especificadas', code: 9999 }
];
