import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalErrorWrapper, Message } from './error.styles';
import { closeErrorModal } from 'src/store/User/user.slice';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/store';
import { ModalErrorProps } from './error.type';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

const ModalError: FC<ModalErrorProps> = ({ onReset }) => {
  const dispatch = useDispatch();
  const showErrorModal = useSelector((state: RootState) => state.user.showErrorModal);
  const errorType = useSelector((state: RootState) => state.user.errorType);
  const navigate = useNavigate();

  if (!showErrorModal) {
    return null;
  }

  const handleClose = () => {
    dispatch(closeErrorModal());
  };

  return (
    <ModalErrorWrapper showModal={showErrorModal}>
      {errorType && errorType.usernameExists && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>
            ¡Este correo ya esta registrado en <span>FINSPHERA!</span>
          </h2>
          <div>
            <Link onClick={handleClose} to="/iniciar-sesion">
              Iniciar sesión
            </Link>
          </div>
        </Message>
      )}
      {errorType && errorType.allDataRequired && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Falta información</h2>
          <p>¡Todos los campos son requeridos para poder avanzar!</p>
          <div>
            <button onClick={handleClose}>Cerrar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.incorrectPhoneCode && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>El código que ingresaste es incorrecto</h2>
          <p>Por favor inténtalo de nuevo</p>
          <div>
            <button
              onClick={() => {
                handleClose();
                if (onReset) onReset();
              }}
            >
              Cerrar
            </button>
          </div>
        </Message>
      )}
      {errorType && errorType.acceptTerms && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Para poder continuar con el registro</h2>
          <p>Es necesario que aceptes los &quot;Términos y condiciones&quot;</p>
          <div>
            <button onClick={handleClose}>Cerrar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.passwordsNotEqual && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Tus contraseñas no son las mismas</h2>
          <p>Verifica que tu contraseña y tu confirmación de contraseña sean las mismas.</p>
          <div>
            <button onClick={handleClose}>Cerrar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.passwordWrong && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Correo electrónico y/o contraseña incorrecto</h2>
          <div>
            <p>¿Olvidaste tu contraseña?</p>
            <a href="/olvide-mi-contrasenia">Cambiar contraseña</a>
          </div>
        </Message>
      )}
      {errorType && errorType.somethingWentWrong && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Ocurrió un error</h2>
          <p>Por favor inténtalo de nuevo</p>
          <div>
            <button onClick={handleClose}>Cerrar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.checkboxNotChecked && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Falta llenar casillas</h2>
          <p>¡Debes aceptar tu autorización para validar tus datos!</p>
          <div>
            <button onClick={handleClose}>Cerrar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.userDoesNotExists && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>
            ¡No te has registrado! Para ingresar a <span>Finsphera</span>, da click aquí:
          </h2>
          <div>
            <a href="/registrate">Registrarse</a>
          </div>
        </Message>
      )}
      {errorType && errorType.wrongConfirmPass && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>
            ¡Tu contraseña de Finsphera es incorrecta! <br /> Por favor, inténtalo de nuevo.
          </h2>
          <div>
            <button onClick={handleClose}>Aceptar</button>
          </div>
        </Message>
      )}
      {errorType && errorType.onboardingError && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>
            ¡Ocurrió un error con tu solicitud de crédito! <br /> Por favor, inténtalo más tarde.
          </h2>
          <div>
            <a href="/solicitante/dashboard">Salir</a>
          </div>
        </Message>
      )}
      {errorType && errorType.tooManyAttempts && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>
            ¡Has superado el límte de intentos para ingresar a <span>Finsphera</span>.Inténtalo de
            nuevo en 10 min.
          </h2>
        </Message>
      )}
      {errorType && errorType.userNotConfirmed && (
        <Message>
          <figure onClick={handleClose}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono para cerrar la ventana"
            />
          </figure>
          <figure>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
              alt="icono de aviso"
            />
          </figure>
          <h2>Ups, tu usuario correo electrónico no ha sido confirmado. Por favor, confírmalo.</h2>
          <div>
            <Button onClick={() => navigate('/confirmar-cuenta')} size="small">
              Confirmar correo electrónico
            </Button>
          </div>
        </Message>
      )}
    </ModalErrorWrapper>
  );
};

export default ModalError;
