import axios from 'axios';
import { url } from 'src/environments';
import { FormValues as AnonymousMailboxFormValues } from 'src/views/landingPage/AnonymousMailbox/AnonymousMailbox';
import { FormValues as WaitlistFormValues } from 'src/components/features/landing/modals/WaitlistModal/Waitlist';
import { config } from 'src/environments';

export const createAnonymousMailbox = async (input: AnonymousMailboxFormValues) => {
  const response = await axios.post(`${url}/admin/mutations/create/anonymous-mailbox`, input);
  return response;
};

export const postWaitlist = async (payload: WaitlistFormValues) => {
  const data = {
    name: payload.fullName,
    email: payload.email,
    whatsapp: payload.phone,
    type: payload.operationType
  };

  const url = `${config.urlFinsphera}/prod/admin/wish-list`;

  const validationCode = await axios.post(url, data);
  const response = JSON.parse(validationCode.data.body);
  if (response === 'Success: inserted data') {
    return response;
  } else {
    throw new Error(response);
  }
};
