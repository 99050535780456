import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import styled from 'styled-components';

export const CalculatorSectionStyles = styled.section`
  margin-top: 2rem;
  margin-bottom: 2.5rem;

  span {
    display: inline;
  }

  h1 {
    text-align: center;
    font-weight: bold;
    font-size: 3rem;
    opacity: 0.7;
    margin-bottom: 3.75rem;
  }

  .cards-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 2rem;
    row-gap: 1.25rem;

    .cards-section--description {
      color: ${colors.text.description};
      font-size: ${uIFontSize.small};
      text-align: center;
      text-wrap: balance;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    span {
      display: block;
    }

    .cards-section {
      column-gap: 4rem;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 1rem;
    margin-bottom: 3.625rem;

    h1 {
      font-size: 3.625rem;
      margin-bottom: 3rem;
    }

    .cards-section {
      column-gap: 7rem;
      row-gap: 2.5rem;
    }
  }

  @media (min-width: ${breakpoints.extraLargeDesktop}) {
    margin-top: 2.75rem;
    margin-bottom: 5.625rem;

    h1 {
      font-size: 4.625rem;
      margin-bottom: 5.625rem;
    }

    .cards-section--description {
      padding: 0 9.25rem;
    }
  }
`;
