import React, { useState, useEffect } from 'react';

import { ModalErrorAmount } from './errorAmount.styles';

export default function ErrorAmount(props) {
  const [errorAmount, setErrorAmount] = useState(props.showModalErrorAmount);

  useEffect(() => {
    setErrorAmount(props.showModalErrorAmount);
  }, [props.showModalErrorAmount]);

  const closeModal = () => {
    setErrorAmount(false);
    props.setErrorAmount(false);
  };

  return (
    <ModalErrorAmount showModalErrorAmount={errorAmount}>
      <div>
        <figure onClick={closeModal}>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
            alt="icono para cerrar la ventana"
          />
        </figure>
        <figure>
          <img
            src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_error.png"
            alt="icono de aviso"
          />
        </figure>
        <h2>Error de validación</h2>
        <p>El monto no puede ser menor a $50,000 pesos.</p>
        <button onClick={closeModal}>Cerrar</button>
      </div>
    </ModalErrorAmount>
  );
}
