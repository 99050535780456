const monthlyOperations = [
  {
    name: 'Menos de 2 operaciones',
    code: 1
  },
  { name: 'Entre 3 y 6 operaciones', code: 2 },
  { name: 'Entre 7 y 9 operaciones', code: 3 },
  { name: 'Entre 10 y 15 operaciones', code: 4 },
  { name: 'Más de 15 operaciones', code: 5 }
];
const monthlyAmount = [
  { name: 'Menos de $25,000', code: 1 },
  { name: 'Entre $25,000 y $250,000', code: 2 },
  { name: 'Entre $250,000 y $1,500,000', code: 3 },
  { name: 'Entre $1,500,000 y $5,000,000', code: 4 },
  { name: 'Más de $5,000,000', code: 5 }
];

export default [monthlyAmount, monthlyOperations];
