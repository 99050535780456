import { FC } from 'react';
import { countryListFiltered } from '../../utils/countryListFiltered';

type DialCodeSelectProps = {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  blockDialCodeCountry?: boolean;
};

export const DialCodeSelect: FC<DialCodeSelectProps> = ({
  onChange,
  value,
  blockDialCodeCountry = false
}) => {
  return (
    <div className="dial-code-container">
      <select
        className="dial-code-select"
        value={value}
        onChange={onChange}
        disabled={blockDialCodeCountry}
      >
        {countryListFiltered.map((country) => (
          <option key={country.code_2} value={country.dial_code}>
            {country.flag_emoji} {country.name_es} {country.dial_code}
          </option>
        ))}
      </select>
      <span className="dial-code">{value}</span>
    </div>
  );
};
