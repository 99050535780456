import { useState, useEffect } from 'react';
import { EsgInformationForm, DropDown } from './goal.styles';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';

const Goal = ({ goalId, title, options, onGoalSelect, setData, handleSelectedOptions }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (optionId, isSelected) => {
    const selectedOption = options.find((option) => option.id === optionId);
    setSelectedOptions((prev) => ({ ...prev, [optionId]: isSelected }));
    handleSelectedOptions(goalId, optionId, isSelected, selectedOption.description);
  };

  useEffect(() => {
    const selectedItems = Object.entries(selectedOptions)
      .filter(([_, isSelected]) => isSelected)
      .map(([optionId]) => options.find((option) => option.id === optionId));
  }, [selectedOptions, options, goalId]);

  return (
    <EsgInformationForm>
      <h4>{title}</h4>
      <figure onClick={() => setShowDropdown(!showDropdown)}>
        <img src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/dropdown.png`} alt="icono de flecha" />
      </figure>
      {showDropdown && (
        <DropDown>
          {options.map((option, index) => (
            <div key={index}>
              <Checkbox
                checked={selectedOptions[option.id] === true}
                onChange={(e) => handleOptionChange(option.id, e.target.checked)}
              />
              <p>
                <span>{option.label}</span> {option.description}
              </p>
            </div>
          ))}
        </DropDown>
      )}
    </EsgInformationForm>
  );
};

export default Goal;
