import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ButtonContainer, GeneralInfoContainer, InfoSection } from './generalInfo.styles';
import EditPhone from 'src/components/shared/modals/editPhone/editPhone';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { url } from 'src/environments';
import { Button } from 'src/utilities/UILibrary';

const GeneralInfo = ({ personalInformation }) => {
  const [editable, setEditable] = useState(false);
  const [mobilephone, setMobilephone] = useState('');
  const user = useSelector((state) => state.user.currentUser);
  const data = personalInformation?.data?.getInvestor?.personalInformation;
  const [referenceId, setReferenceId] = useState('');
  const [showModalTelephone, setShowModalTelephone] = useState(false);
  const mobileP = mobilephone.split(' ')[0] + mobilephone.split(' ')[1];
  const PldApproved = personalInformation?.data?.getInvestor?.isPLDApproved;
  const handleSendCode = async (event) => {
    event.preventDefault();
    const input = {
      id: user.id,
      typeOfUser: 'investor'
    };
    const phone = await axios.post(`${url}/admin/mobile/${mobileP}/get-otp`, input);
    const parsedBody = JSON.parse(phone.data.body);
    const referenceId = parsedBody.ReferenceId;
    setReferenceId(referenceId);
    setShowModalTelephone(true);
    setEditable(false);
  };

  const cancelEdit = () => {
    setEditable(false);
  };
  return (
    <GeneralInfoContainer>
      <EditPhone
        showModalTelephone={showModalTelephone}
        setShowModalTelephone={setShowModalTelephone}
        referenceId={referenceId}
        mobilephone={mobilephone}
      />
      {editable ? (
        <ButtonContainer>
          <Button size="small" onClick={handleSendCode}>
            Aceptar
          </Button>
          <Button size="small" onClick={cancelEdit}>
            Cancelar
          </Button>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <Button disabled={PldApproved === '0'} size="small" onClick={() => setEditable(true)}>
            Editar
          </Button>
        </ButtonContainer>
      )}
      <InfoSection>
        <div>
          <h3>Nombre(s):</h3>
          <p>{data?.firstName ? data?.firstName : '-'}</p>
        </div>
        <div>
          <h3>Apellidos:</h3>
          <p>{data?.surname ? data?.surname : '-'}</p>
        </div>
        <div>
          <h3>Fecha de nacimiento:</h3>
          <p>{data?.dateOfBirth ? data?.dateOfBirth : '-'}</p>
        </div>
      </InfoSection>
      <InfoSection>
        {editable ? (
          <div>
            <h3>Teléfono móvil:</h3>
            <InputField
              value={mobilephone}
              onChangeInternalValue={(value) => setMobilephone(value)}
              type="number"
              placeholder="Ingresa tu número"
            />
          </div>
        ) : (
          <div>
            <h3>Teléfono móvil:</h3>
            <p>{data?.phone.mobile ? data?.phone.mobile : '-'}</p>
          </div>
        )}
        <div>
          <h3>Correo electrónico:</h3>
          <p>{data?.email ? data?.email : '-'}</p>
        </div>
      </InfoSection>
      <InfoSection>
        <div>
          <h3>CURP:</h3>
          <p>{data?.CURP ? data?.CURP : '-'}</p>
        </div>
        <div>
          <h3>RFC:</h3>
          <p>{data?.RFC ? data?.RFC : '-'}</p>
        </div>
        <div>
          <h3>Edad:</h3>
          <p>{data?.age ? data?.age : '-'}</p>
        </div>
      </InfoSection>
    </GeneralInfoContainer>
  );
};

export default GeneralInfo;
