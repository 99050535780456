import styled from 'styled-components/macro';
import {
  brandBlack,
  whiteSphera,
  neutralBlue,
  greenSp,
  grayMediumSphera
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const JointlyContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2.5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > button {
    background-color: ${neutralBlue};
    border: none;
    border-radius: 5px;
    padding: 0.5% 3%;
    margin-left: 90%;
    color: ${brandBlack};
    font-size: 1vw;
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const IdentityQuestion = styled.div`
  width: 100%;
  text-align: center;
  > p {
    font-size: ${uIFontSize.large};
    font-weight: bold;
    margin-top: 3%;
    color: ${grayMediumSphera};
  }
  > div {
    width: 80%;
    margin: 1% 10%;
    display: flex;
    justify-content: space-evenly;
    > h3 {
      font-size: ${uIFontSize.h3};
      text-align: left;
    }
    > p {
      font-size: ${uIFontSize.medium};
      text-align: left;
    }
    > label {
      font-size: 1.5vw;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 3%;
  > button:nth-child(2),
  button:nth-child(3) {
    background-color: ${greenSp};
    color: ${brandBlack};
  }
  > p {
    font-size: ${uIFontSize.medium};
    width: 100%;
    text-align: center;
  }
`;

export const TermsPolicies = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 3%;
  > p {
    font-size: ${uIFontSize.medium};
    width: 70%;
    text-align: center;
    > a {
      color: ${greenSp};
      font-weight: bold;
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  display: flex;
  border-collapse: collapse;
  background-color: ${whiteSphera};
  justify-content: center;
  td,
  th {
    text-align: left;
    padding: 8px;
    font-size: ${uIFontSize.medium};
  }
  th {
    background-color: ${brandBlack};
    color: ${whiteSphera};
  }
  tr:nth-child(even) {
    background-color: #f0f5ff;
  }
  tr:nth-child(odd) {
    background-color: #e1e8f6;
  }
  button {
    background-color: ${greenSp};
    color: ${whiteSphera};
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
  }
`;
