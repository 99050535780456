import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { url } from 'src/environments';
import { AddBeneficiary, BeneficiariesContainer, Editables } from './beneficiaries.styles';
import CardBeneficiaries from 'src/components/features/investor/dashboard/myProfile/beneficiaries/cardBeneficiaries/cardBeneficieries';
import CreateBeneficiary from 'src/components/shared/modals/beneficiaries/CreateBeneficiary';
import { deleteBeneficiary } from 'src/store/Profile/profile.slice';
import { Button } from 'src/utilities/UILibrary';
import axios from 'axios';
import generateUUID from 'src/functions/uuid';

export default function Beneficiaries({ beneficiariesInformation = [], onRefreshData }) {
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState(beneficiariesInformation || []);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const newUuid = generateUUID();
  const [beneficiaryData, setBeneficiaryData] = useState({
    id: newUuid,
    investorId: user.id,
    firstName: '',
    lastNameP: '',
    lastNameM: '',
    dateOfBirth: '',
    relationship: '',
    percentage: 0,
    street: '',
    numExt: '',
    numInt: '',
    country: '',
    state: '',
    district: '',
    postalCode: ''
  });
  const numberOfBeneficiaries = beneficiaries.length;

  const handleCreateBeneficiary = useCallback(async () => {
    try {
      const totalAssigned = beneficiaries.reduce(
        (total, beneficiary) => total + beneficiary.percentage,
        0
      );
      if (totalAssigned + beneficiaryData.percentage > 1) {
        alert('El porcentaje total excede el 100% disponible. Favor de validar');
        return;
      }
      const result = await axios.post(`${url}/admin/mutations/create/beneficiary`, beneficiaryData);
      const createdBeneficiary = result.data.createBeneficiary;
      addBeneficiary(createdBeneficiary);
      setBeneficiaries((prevBeneficiaries) => [...prevBeneficiaries, createdBeneficiary]);
      window.location.reload();
      setModalIsOpen(false);
      setTotalPercentage((prevPercentage) => prevPercentage + createdBeneficiary.percentage);
    } catch (error) {
      console.error(error);
    }
  }, [beneficiaryData, totalPercentage]);
  const beneficiariesList = beneficiariesInformation?.data?.getInvestor?.beneficiaries?.items;
  useEffect(() => {
    let beneficiariesList = beneficiariesInformation?.data?.getInvestor?.beneficiaries?.items;
    if (beneficiariesList) {
      setBeneficiaries(beneficiariesList);
      let total = 0;
      beneficiariesList.forEach((beneficiary) => {
        total += beneficiary.percentage;
      });
      setTotalPercentage(total);
    }
  }, [beneficiariesInformation]);
  const canAddBeneficiary = Math.round(totalPercentage) !== 1 || numberOfBeneficiaries < 3;
  const addBeneficiary = () => {
    setModalIsOpen(true);
  };
  const handleEdit = () => {
    setEditable(true);
  };
  const handleDeleteBeneficiary = (id) => {
    window.location.reload();
    dispatch(deleteBeneficiary(id));
  };
  return (
    <BeneficiariesContainer>
      <CreateBeneficiary
        onRefreshData={onRefreshData}
        showModal={modalIsOpen}
        beneficiaryData={beneficiaryData}
        setBeneficiaryData={setBeneficiaryData}
        onClose={() => setModalIsOpen(false)}
        onConfirm={() => handleCreateBeneficiary()}
        totalAssignedPercentage={totalPercentage}
        numberOfBeneficiaries={numberOfBeneficiaries}
      />
      <AddBeneficiary totalPercentage={totalPercentage}>
        <Button disabled={!canAddBeneficiary} onClick={addBeneficiary}>
          Agregar beneficiario
        </Button>
      </AddBeneficiary>
      {beneficiariesList && beneficiariesList.length > 0 ? (
        beneficiariesList.map((beneficiary, index) => (
          <CardBeneficiaries
            onRefreshData={onRefreshData}
            key={index}
            beneficiaryData={beneficiary}
            setBeneficiaryData={setBeneficiaryData}
            editData={handleEdit}
            onDelete={handleDeleteBeneficiary}
            numberOfBeneficiaries={numberOfBeneficiaries}
            totalAssignedPercentage={totalPercentage}
          />
        ))
      ) : (
        <h3>Aún no tienes beneficiarios agregados.</h3>
      )}
    </BeneficiariesContainer>
  );
}
