import { AnyAction, combineReducers } from 'redux';
//REDUCERS
import appReducer from './app/app.slice';
import userSliceReducer from 'src/store/User/user.slice';
import campaignsReducer from 'src/store/Campaigns/campaign.slice';
import profileReducer from './Profile/profile.slice';

const combineReducer = combineReducers({
  app: appReducer,
  user: userSliceReducer,
  campaign: campaignsReducer,
  profile: profileReducer
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
  return combineReducer(state, action);
};

export default rootReducer;
