import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';

import {
  DashboardInvestmentContainer,
  Banner,
  DashboardInvestmentInfo,
  DashboardInvestmentTable,
  FilterSection
} from './investment.styles';
import { fetchPersonalInformation } from 'src/store/Profile/profile.slice';
import ModalCompanyDetailInvestPay from 'src/components/shared/modals/companyDetailInvestPay/companyDetailInvestPay';

import Investment from './subComponents/investment';
import { getInvestor_Profile } from 'src/graphql/customized_queries';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export default function DashboardInvestment() {
  const dispatch = useDispatch();
  const [activeFilter, setActiveFilter] = useState('all');
  const user = useSelector((state) => state.user.currentUser);
  const [companyDetailInvestPay, setCompanyDetailInvestPay] = useState(false);
  const [investorData, setInvestorData] = useState(null);
  const personalInformation = useSelector((state) => state.profile.personalInformation);
  const stp = personalInformation?.data?.getInvestor?.STP;
  const allBids = personalInformation?.data?.getInvestor?.investments?.items || [];
  useEffect(() => {
    if (user.id) {
      dispatch(fetchPersonalInformation(user.id));
    }
  }, [user.id, dispatch]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await API.graphql({
          query: getInvestor_Profile,
          variables: { id: user.id }
        });
        setInvestorData(data.getInvestor);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  const compromiso = allBids.reduce((total, bid) => total + bid?.compromisoInversion, 0);

  let filteredBids = allBids;

  switch (activeFilter) {
    case 'expiredInactive':
      filteredBids = allBids.filter(
        (bid) => bid.campaign.isExpired === '1' && bid?.campaign?.isActive === '0'
      );
      break;
    case 'moroso':
      filteredBids = allBids.filter((bid) => bid?.campaign?.status === 'MOROSO');
      break;
    case 'dateEndCampaign':
      filteredBids = allBids.filter((bid) => bid?.status === 'CANCELADO');
      break;
    default:
      break;
  }

  return (
    <DashboardInvestmentContainer>
      <ModalCompanyDetailInvestPay showModalCompanyDetailInvestPay={companyDetailInvestPay} />
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_dash_investment_banner.png`}
            alt="Finsphera inversiones"
          />
        </figure>
        <h2>Panel de inversiones</h2>
        <p>
          Aquí podrás encontrar toda la información de tus inversiones realizadas a través de la
          plataforma de FinSphera.
        </p>
      </Banner>
      <DashboardInvestmentInfo>
        <div>
          <h3>Saldo disponible</h3>
          <h3>Total invertido activo:</h3>
          <h3>Saldo total</h3>
        </div>
        <div>
          <p>{formatCurrency(stp?.recursosDisponibles ? stp?.recursosDisponibles : '-')}</p>
          <p>{formatCurrency(compromiso ? compromiso : '0')}</p>
          <p>
            {stp?.recursosDisponibles + compromiso
              ? formatCurrency(stp?.recursosDisponibles + compromiso)
              : '-'}
          </p>
        </div>
      </DashboardInvestmentInfo>
      <FilterSection className="filters">
        <h3>Filtrar por:</h3>
        <Button size="small" onClick={() => setActiveFilter('all')}>
          Mostrar todas
        </Button>
        <Button size="small" onClick={() => setActiveFilter('expiredInactive')}>
          Campañas cerradas
        </Button>
        <Button size="small" onClick={() => setActiveFilter('moroso')}>
          En mora
        </Button>
        <Button size="small" onClick={() => setActiveFilter('dateEndCampaign')}>
          Canceladas
        </Button>
      </FilterSection>
      <DashboardInvestmentTable>
        <tbody>
          {/* <thead> */}
          <tr>
            <th>Campaña</th>
            <th>Monto de inversión</th>
            <th>Estatus Campaña</th>
            <th>Monto solicitado</th>
            <th>Fecha de término</th>
            <th>Detalles</th>
            <th>Opciones</th>
          </tr>
          {/* </thead> */}
          {filteredBids.length > 0 ? (
            filteredBids.map((lB, index) => <Investment listBids={lB} keys={index} key={index} />)
          ) : (
            <tr>
              <td colSpan="7">
                <h2>No tienes registro</h2>
              </td>
            </tr>
          )}
        </tbody>
      </DashboardInvestmentTable>
      <h3>
        Recuerda que puedes cancelar tus compromisos de inversión siempre y cuando las campañas aún
        se encuentren activas, si están cerradas no será posible realizar este proceso.
      </h3>
    </DashboardInvestmentContainer>
  );
}
