import { useEffect } from 'react';

const useCalculateInvest = (amntToInvst: string) => {
  useEffect(() => {
    return;
  }, [amntToInvst]);

  if (parseInt(amntToInvst) > 0) {
    return parseInt(amntToInvst);
  } else return 0;
};

export default useCalculateInvest;
