import { InsightsSectionContainer } from './insightsSection.styles';
import { Card } from 'src/utilities/UILibrary2024/components';
import { useViewMobile } from 'src/hooks/useViewMobile';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { IconCheckList } from 'src/assets/icons/various';
import { CheckMark } from 'src/assets/icons/interfaceIcons';
import Stonks from 'src/assets/icons/various/stonks.svg';
import Lottie from 'lottie-react';
import fastResponse from 'src/assets/animations/lottie/respuesta-rapida.json';
import advanceAutomation from 'src/assets/animations/lottie/automatizacion-avanzada.json';
import bestBenefits from 'src/assets/animations/lottie/mayores-beneficios.json';

export const InsightsSection = () => {
  const isMobileToLargeDesktop = useViewMobile(
    parseInt(breakpoints.extraLargeDesktop.replace('px', ''))
  );
  const sightsCardsWidth = isMobileToLargeDesktop ? '19rem' : '20.3125rem';
  const CardCommonProps = {
    width: sightsCardsWidth,
    aspectRatio: 'slim',
    isFluid: true,
    paddingX: 24,
    paddingY: 68,
    isHoverable: true
  };

  return (
    <InsightsSectionContainer>
      <h1>Proceso de financiamiento</h1>
      <div className="insights__cards-section">
        <Card {...CardCommonProps}>
          <div className="image-container">
            <Lottie animationData={fastResponse} />
          </div>
          <div className="body-container">
            <h3>Respuesta rápida</h3>
            <p>
              Tiempo de respuesta <span />
              inferior a 24 horas
            </p>
          </div>
        </Card>
        <Card {...CardCommonProps}>
          <div className="image-container">
            <Lottie animationData={advanceAutomation} />
          </div>
          <div className="body-container">
            <h3>Automatización avanzada</h3>
            <p>
              Tecnología que facilita <span />
              financiar y obtener crédito
            </p>
          </div>
        </Card>
        <Card {...CardCommonProps}>
          <div className="image-container">
            <Lottie animationData={bestBenefits} />
          </div>
          <div className="body-container">
            <h3>Mayores beneficios</h3>
            <p>
              Altos rendimientos y mejores márgenes <span />
              de operación en los negocios
            </p>
          </div>
        </Card>
      </div>
    </InsightsSectionContainer>
  );
};
