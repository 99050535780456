import { API, graphqlOperation } from 'aws-amplify';
import {
  updateInvestor_OnboardingStatus,
  updateApplicant_OnboardingStatus
} from 'src/graphql/customized_mutations';
import { useSelector } from 'react-redux';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { flowTypeToRoutesMap } from 'src/constants';
import { getUserFlow, getCurrentOnboardingStep } from 'src/functions';
import { setCurrentStep } from 'src/store/User/user.slice';

// TODO: this hook can be improved automatically getting the flow type from the user

export const useManageSteps = () => {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useAppThunkDispatch();

  const flowType = getUserFlow(user);

  const actualStep = getCurrentOnboardingStep(user);

  const toStep = getNextStep(actualStep ?? 1);

  const nextStep = async () => {
    const onboardingStatus = {
      id: user.id,
      onboarding: {
        complete:
          actualStep === flowTypeToRoutesMap[flowType].length && user.onboarding.complete === false,
        currentStep: actualStep === flowTypeToRoutesMap[flowType].length ? actualStep : toStep,
        isBlocked: false,
        reasonOfBlocking: ''
      }
    };

    const userType =
      flowType === 'physicalInvestor' || flowType === 'moralInvestor' ? 'investor' : 'applicant';
    const updateMethod =
      userType === 'investor' ? updateInvestor_OnboardingStatus : updateApplicant_OnboardingStatus;
    await API.graphql(graphqlOperation(updateMethod, { input: onboardingStatus }));

    if (actualStep < flowTypeToRoutesMap[flowType].length) {
      dispatch(setCurrentStep(toStep));
    }
  };

  return {
    nextStep
  };
};

function getNextStep(actualStep: number): number {
  // Here you can implement more complex logic for determining the next step if needed.
  return actualStep + 1;
}
