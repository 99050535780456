import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CreditInformationContainer,
  FormContainer,
  FormData,
  FormDataTitle,
  StyledButton,
  FormInfo,
  FormDate,
  FormEditable,
  ComplaintSection
} from './account.styles';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { userGroupSelector } from 'src/store/User/user.slice';
import {
  fetchPersonalInformationApplicant,
  fetchPersonalInformation
} from 'src/store/Profile/profile.slice';
import UpdatePhoneModal from 'src/components/shared/modals/updatePhone/updatePhone';
import CancelAccountModal from 'src/components/shared/modals/cancelAccount/cancelAccount';
import ChangePassword from 'src/components/shared/modals/changePassword/changePassword';
import UpdateMarketingModal from 'src/components/shared/modals/updateMarketing/updateMarketing';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import Complaint from '../modals/complaint/complaint';
import ExperiencedInvestor from '../modals/experiencedInvestor/experiencedInvestor';

export default function AccountInfo() {
  const [showModal, setShowModal] = useState(false);
  const [complaintModal, setComplaintModal] = useState(false);
  const [experiencedModal, setExperiencedModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(null);
  const [marketingModal, setMarketingModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [applicant, setApplicant] = useState(false);
  const userGroup = useSelector(userGroupSelector);
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  //Data fetch
  useEffect(() => {
    if (user.id) {
      if (userGroup === 'applicant') {
        dispatch(fetchPersonalInformationApplicant(user.id));
        setApplicant(true);
      } else {
        dispatch(fetchPersonalInformation(user.id));
        setApplicant(false);
      }
    }
  }, [user.id, dispatch]);
  //Applicant
  const personalInformationApplicant = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant?.personalInformation
  );
  const marketingApplicant = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant?.isInMarketing
  );
  const creationDateApplicant = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant?.createdAt
  );
  const campaigns = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant?.campaigns?.items
  );
  const activeCampaigns = campaigns?.filter((campaign) => campaign.isActive === '1');
  const fundedCampaign = campaigns?.filter((campaign) => campaign.isMoneySent === '1');
  const lastSessionApplicant = useSelector(
    (state) => state.profile.personalInformation?.data?.getApplicant?.ipAddress?.slice(-1)[0].date
  );
  //Investor
  const personalInformationInvestor = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor?.personalInformation
  );
  const marketingInvestor = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor?.isInMarketing
  );
  const creationDateInvestor = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor?.createdAt
  );
  const investments = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor?.investments?.items
  );
  const lastSessionInvestor = useSelector(
    (state) => state.profile.personalInformation?.data?.getInvestor?.ipAddress?.slice(-1)[0].date
  );
  const stp = useSelector((state) => state.profile.personalInformation?.data?.getInvestor);
  const isCancelDisabled =
    stp?.compromisoInversion > 0 || stp?.recursosDisponibles > 0 || stp?.inversionesVigentes !== 0;

  //Modales de actualizaciones
  const openModal = () => {
    setShowModal(true);
  };
  const openPasswordModal = () => {
    setPasswordModal(true);
  };
  const openMarketingModal = () => {
    setMarketingModal(true);
  };
  const openCancelModal = () => {
    setCancelModal(true);
  };
  const openComplaintModal = () => {
    setComplaintModal(true);
  };
  const openExperiencedInvestor = () => {
    setExperiencedModal(true);
  };

  const creationDate = applicant ? creationDateApplicant : creationDateInvestor;
  const personal = applicant ? personalInformationApplicant : personalInformationInvestor;
  const marketing = applicant ? marketingApplicant : marketingInvestor;
  const lastSession = applicant ? lastSessionApplicant : lastSessionInvestor;
  const typeOfUser = applicant ? 'applicant' : 'investor';

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hour = String(date.getUTCHours()).padStart(2, '0');
    const minute = String(date.getUTCMinutes()).padStart(2, '0');
    const second = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
  };

  return (
    <CreditInformationContainer>
      <ChangePassword passwordModal={passwordModal} setPasswordModal={setPasswordModal} />
      <UpdatePhoneModal showModal={showModal} setShowModal={setShowModal} />
      <UpdateMarketingModal
        marketingModal={marketingModal}
        setMarketingModal={setMarketingModal}
        typeOfUser={typeOfUser}
        marketing={marketing}
      />
      <CancelAccountModal
        activeCampaigns={activeCampaigns}
        cancelModal={cancelModal}
        setCancelModal={setCancelModal}
      />
      <Complaint complaintModal={complaintModal} setComplaintModal={setComplaintModal} />
      {userGroup === 'investor' && (
        <ExperiencedInvestor
          experiencedModal={experiencedModal}
          setExperiencedModal={setExperiencedModal}
          personal={personal}
          investments={investments}
        />
      )}
      <PlatformBanner title="Configuración de cuenta" className="landing-banner" />
      {applicant ? (
        <FormContainer>
          <FormData>
            <FormDataTitle>Campañas creadas:</FormDataTitle>
            <p>{campaigns?.length ? campaigns?.length : '-'}</p>
          </FormData>
          <FormData>
            <FormDataTitle>Campañas financiadas:</FormDataTitle>
            <p>{fundedCampaign?.length ? fundedCampaign?.length : '-'}</p>
          </FormData>
          <FormData>
            <FormDataTitle>Campañas activas:</FormDataTitle>
            <p>{activeCampaigns?.length ? activeCampaigns?.length : '-'}</p>
          </FormData>
        </FormContainer>
      ) : (
        <FormContainer>
          <FormData>
            <FormDataTitle>Total inversiones:</FormDataTitle>
            <p>{investments?.length ? investments?.length : '-'}</p>
          </FormData>
          <FormData>
            <FormDataTitle>Cuenta creada:</FormDataTitle>
            <p>{formatDate(creationDate) ? formatDate(creationDate) : '-'}</p>
          </FormData>
          <FormData>
            <FormDataTitle>Último inicio de sesión:</FormDataTitle>
            <p>{formatDate(lastSession) ? formatDate(lastSession) : '-'}</p>
          </FormData>
        </FormContainer>
      )}
      <ComplaintSection>
        <div>
          <p>Buzón:</p>
          <h4>Aclaraciones, dudas y quejas</h4>
        </div>
        <Button size="small" onClick={openComplaintModal}>
          Crear
        </Button>
      </ComplaintSection>
      <h2>Información del usuario:</h2>
      <FormInfo>
        <p>Nombre:</p>
        <h4>{personal?.fullName ? personal?.fullName : '-'}</h4>
      </FormInfo>
      <FormInfo>
        <p>Correo electrónico:</p>
        <h4>{user.email}</h4>
      </FormInfo>
      <FormEditable>
        <div>
          <p>Contraseña:</p>
          <h4>*******</h4>
        </div>
        <Button size="small" onClick={openPasswordModal}>
          Cambiar contraseña
        </Button>
      </FormEditable>
      <FormEditable>
        <div>
          <p>¿Recibes publicidad de Finsphera?</p>
          <h4>{marketing === '1' ? 'Sí' : 'No'}</h4>
        </div>
        {marketing === '1' ? (
          <Button size="small" onClick={openMarketingModal}>
            Cancelar publicidad
          </Button>
        ) : (
          <Button size="small" onClick={openMarketingModal}>
            Habilitar publicidad
          </Button>
        )}
      </FormEditable>
      <FormEditable>
        <div>
          <p>Teléfono:</p>
          <h4>{personal?.phone?.mobile ? personal?.phone?.mobile : '-'}</h4>
        </div>
        <Button size="small" onClick={openModal}>
          Actualizar teléfono
        </Button>
      </FormEditable>
      <ComplaintSection>
        <div>
          <p>Quiero ser inversionista experimentado</p>
        </div>
        <Button size="small" onClick={openExperiencedInvestor}>
          Ver más
        </Button>
      </ComplaintSection>
      {applicant ? (
        <FormDate>
          <div>
            <p>Fecha de creación de cuenta:</p>
            <h4>{formatDate(creationDate) ? formatDate(creationDate) : '-'}</h4>
          </div>
          <div>
            <p>Fecha de mi última sesión:</p>
            <h4>{formatDate(lastSession) ? formatDate(lastSession) : '-'}</h4>
          </div>
        </FormDate>
      ) : (
        <div></div>
      )}
      {isCancelDisabled && (
        <p>
          Para poder cancelar tu cuenta deber de transferir los recursos disponibles dentro de
          Finsphera a tu cuenta bancaria y/o no tener inversiones vigentes
        </p>
      )}
      <StyledButton disabled={isCancelDisabled} onClick={openCancelModal}>
        Cancelar cuenta
      </StyledButton>
    </CreditInformationContainer>
  );
}
