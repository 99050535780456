import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { PersonalInformationContainer } from './simaf.styles';
import { RootState } from 'src/store/store';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { FormValues } from './simaf.types';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import options from 'src/constants/simafOptionsInvestor';
import { url } from 'src/environments';
import { InputTextArea } from 'src/utilities/UILibrary/Forms/InputTextArea/InputTextArea';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { setLoadingMessage, setLoadingState } from 'src/store/User/user.slice';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { Checkbox } from 'src/utilities/UILibrary/Forms/Checkbox/Checkbox';
import { udiValue, maxUdisInvestment } from 'src/constants/values';
import { setAppError } from 'src/store/app/app.slice';

const maxInvestmentInPesos = (udiValue * maxUdisInvestment).toLocaleString('es-MX', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export default function Simaf() {
  const user = useSelector((state: RootState) => state.user.currentUser);
  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors }
  } = useForm<FormValues>();
  const { nextStep } = useManageSteps();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isCheckboxChecked = watch('udisTerms');
  const [
    monthlyAmount,
    monthlyOperations,
    resourcesOrigin,
    resourcesOriginMoral,
    resourcesDestination
  ] = options;
  const monthlyOperationsOptions = monthlyOperations.map((operation) => ({
    value: operation.code,
    label: operation.name
  }));
  const monthlyAmountOptions = monthlyAmount.map((amount) => ({
    value: amount.code,
    label: amount.name
  }));
  const selectedResourcesOrigin =
    user.typeOfPerson === 'FISICA' ? resourcesOrigin : resourcesOriginMoral;
  const resourcesOriginOptions = selectedResourcesOrigin.map((origin) => ({
    value: origin.code,
    label: origin.name
  }));
  const resourcesDestinationOptions = resourcesDestination.map((destination) => ({
    value: destination.code,
    label: destination.name
  }));
  type Option = {
    name: string;
    code: number | string;
  };
  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    //Ejecuta el endpoint registro-cuenta-persona-fisica de STP
    const findOptionByCode = (options: Option[], code: number | string): string => {
      const foundOption = options.find((opt) => opt.code.toString() === code.toString());
      return foundOption ? foundOption.name : '';
    };
    dispatch(setLoadingMessage(''));
    dispatch(setLoadingState(true));
    //Ejecuta el endpoint registro-cuenta-persona-fisica de STP  registro-cuenta-persona-fisica
    const stpInput = {
      clientId: user.id,
      tipoCliente: 'investor'
    };
    try {
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/registro-cuenta-persona-${
          user.typeOfPerson.toLowerCase() === 'fisica' ? 'fisica' : 'moral'
        }`,
        headers: {
          apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
          'Content-Type': 'application/json'
        },
        data: stpInput
      };
      await axios(config);
      const updateInput = {
        id: user.id,
        typeOfUser: 'investor',
        frecuencia: findOptionByCode(monthlyOperations, data.monthlyOperations),
        monto: findOptionByCode(monthlyAmount, data.monthlyAmount),
        destinoRecursos: findOptionByCode(resourcesDestination, data.resourcesDestination),
        origenRecursos: findOptionByCode(selectedResourcesOrigin, data.resourcesOrigin),
        simaf_monto: data.monthlyAmount?.toString() || '',
        simaf_frecuencia: data.monthlyOperations?.toString() || '',
        simaf_origenRecursos: data.resourcesOrigin?.toString() || '',
        simaf_destinoRecursos: data.resourcesDestination?.toString() || '',
        dateTime: Date.now(),
        tipoCuenta: 1, //Inversionista es 1
        tipoFinanciamiento: 1,
        tipoProducto: 'Inversión',
        nivelCuenta: 1, //Resolver de matriz de riesgos de oficial de cumplimiento
        numeroCuentaProyecto: user.id,
        regimen: 0, //Normal (0) o Simlpificado (1)
        proposito: data.investmentDescription
      };
      const simaf = await axios.post(`${url}/simaf/create-table`, updateInput);
      await nextStep();
      navigate('/inversionista/onboarding-phone');
      return simaf;
    } catch (error) {
      dispatch(setAppError({}));
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <PersonalInformationContainer className="onboarding-view-content">
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_personalInformation2_banner.png`}
        imgAlt="Finsphera icono información personal"
        title="Perfil de uso de aplicación"
        className="onboarding-banner"
      />
      <Card className="onboarding-card" isSlim isForm>
        <Card.Row>
          <Controller
            name="monthlyOperations"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Número máximo de operaciones mensuales:"
                placeholder="Selecciona una opción"
                isDropdown
                options={monthlyOperationsOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="monthlyAmount"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Monto máximo acumulado de operaciones mensuales:"
                placeholder="Selecciona una opción"
                isDropdown
                options={monthlyAmountOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="resourcesOrigin"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Origen de los recursos:"
                placeholder="Selecciona una opción"
                isDropdown
                options={resourcesOriginOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="resourcesDestination"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'Es un campo requerido.' }}
            render={({ field, fieldState, ...rest }) => (
              <InputField
                label="Destino de los recursos:"
                placeholder="Selecciona una opción"
                isDropdown
                options={resourcesDestinationOptions}
                onChangeInternalValue={field.onChange}
                errorMessages={
                  fieldState.error && fieldState.error.message ? [fieldState.error.message] : []
                }
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Controller
            name="investmentDescription"
            control={control}
            rules={{
              minLength: {
                value: 10,
                message: 'Descripción de mínimo 10 caracteres'
              },
              maxLength: {
                value: 100,
                message: 'Descripción de máximo 100 caracteres'
              },
              required: 'Es un campo requerido.'
            }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputTextArea
                label="Propósito de inversión con Finsphera:"
                placeholder="Breve descripción"
                maxLength={100}
                onChange={onChange}
                errorMessages={error && error.message ? [error.message] : []}
                {...rest}
              />
            )}
          />
        </Card.Row>
        <Card.Row>
          <Checkbox
            label={`¿Estás de acuerdo que el Monto máximo mensual de Inversión no excederá de tres mil UDIs ($${maxInvestmentInPesos})?`}
            {...register('udisTerms', { required: true })}
            errorMessages={
              errors.udisTerms && [
                'Es necesario estar de acuerdo con el monto máximo de inversión.'
              ]
            }
          />
        </Card.Row>
      </Card>
      <FormActionButtons
        disableSubmitButton={!isCheckboxChecked}
        submitAction={handleSubmit(onSubmit)}
      />
    </PersonalInformationContainer>
  );
}
