import styled, { FlattenSimpleInterpolation, css } from 'styled-components/macro';
import { CheckboxProps } from './checkbox.types';
import { uIFontSize } from '../../../UIStyles/variables/typography';
import { breakpoints } from '../../../UIStyles/variables/breakpoints';

const sizeMap = {
  small: '1rem',
  medium: '1.5rem',
  large: '2rem'
};

const sizes = ({ boxSize }: CheckboxProps): FlattenSimpleInterpolation => {
  const width = boxSize ? sizeMap[boxSize] : '1.5rem';
  return css`
    width: ${width};
    height: ${width};
    min-width: ${width};
    min-height: ${width};
  `;
};

export const CheckboxContainer = styled.div<CheckboxProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .input-wrapper {
    display: flex;
    flex-direction: ${({ isLeftLabel }) => (isLeftLabel ? 'row' : 'row-reverse')};
    gap: 0.5rem;
    align-items: center;

    label {
      font-size: ${uIFontSize.medium};
    }

    input {
      ${sizes}
    }
  }

  // from tablet
  @media (min-width: ${breakpoints.tablet}) {
    .input-wrapper {
      gap: 1rem;
    }
  }
`;
