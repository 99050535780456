import styled from 'styled-components/macro';

import {
  graySphera,
  brandBlack,
  supportLemonGreen,
  grayPlaceholder,
  greenSp
} from 'src/utilities/UIStyles/variables/colors';

export const DashboardOpportunitiesContainer = styled.section`
  padding-bottom: 5%;
  background-color: #fafafa;
`;

export const Banner = styled.div`
  background-color: transparent;
  padding: 8% 0 0 10%;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > h2 {
      color: ${brandBlack};
      font-size: 2.5vw;
      font-weight: bold;
      margin-bottom: 1%;
    }

    > p {
      color: ${brandBlack};
      font-size: 1.2vw;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 10% 10% 2% 10%;
  }
  @media (max-width: 600px) {
    padding: 20% 0 2.5% 0%;
    > div {
      justify-content: center;
      flex-direction: column;
      > h2 {
        font-size: 6vw;
        width: 90%;
        margin: 0 5%;
        margin-bottom: 5%;
      }
      > p {
        text-align: justify;
        font-size: 3vw;
        width: 90%;
        margin: 0 5%;
      }
    }
  }
`;

export const DashboardOpportunitiesOrderBy = styled.div`
  padding: 2% 10%;
  > p {
    font-size: 1.6vw;
    color: ${supportLemonGreen};
    font-weight: bold;
    margin-bottom: 2%;
  }
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    /* > button {
      color: ${brandBlack};
      width: 20%;
      font-size: 1.2vw;
      padding: 0 1%;
      border: none;
      border-bottom: 1.5px solid ${graySphera};
      background-color: transparent;
      cursor: pointer;
      text-align: left;
    } */
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    padding: 1% 10%;
    margin: 0;
    > p {
      font-size: 1.8vw;
      margin: 0;
    }
    > div {
      > button {
        width: 20%;
        margin: 2% 0;
        font-size: 1.5vw;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 2% 5%;
    > p {
      font-size: 4vw;
      margin: 0;
    }
    > div {
      > button {
        width: 45%;
        margin: 2% 0;
        font-size: 3vw;
      }
    }
  }
`;

export const DashboardOpportunitiesCardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;

  @media (min-width: 600px) and (max-width: 1024px) {
  }
  @media (max-width: 600px) {
  }
`;

export const Button = styled.button`
  width: 20%;
  font-size: 1.2vw;
  padding: 0 1%;
  border: none;
  border-bottom: 1.5px solid ${graySphera};
  color: ${(props) => (props.filterType === props.buttonType ? brandBlack : grayPlaceholder)};
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  @media (min-width: 600px) and (max-width: 1024px) {
    font-size: 1.5vw;
  }
  @media (max-width: 600px) {
    font-size: 3vw;
  }
`;
