import { useState } from 'react';
import { InvestmentContainer } from './investment.styles';
import ModalCompanyDetailInvestPay from 'src/components/shared/modals/companyDetailInvestPay/companyDetailInvestPay';
import ModalCancelInvestment from 'src/components/shared/modals/cancelInvestmentInvestor/cancelInvestment';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';

export default function Investment({ listBids }) {
  const [companyDetailInvestPay, setCompanyDetailInvestPay] = useState(false);
  const [cancelInvestmentInvestor, setCancelInvestmentInvestor] = useState(false);
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }
  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const showCompanyDetailInvestPay = () => setCompanyDetailInvestPay(true);
  const showModalCancelInvest = () => setCancelInvestmentInvestor(true);
  const cancelDisabled =
    listBids?.campaign?.isExpired === '1' ||
    listBids?.campaign?.isActive === '0' ||
    listBids?.status === 'CANCELADO';
  const buttonText = listBids?.status === 'CANCELADO' ? 'Cancelada' : 'Cancelar';
  return (
    <>
      <ModalCompanyDetailInvestPay
        showModalCompanyDetailInvestPay={companyDetailInvestPay}
        setCompanyDetailInvestPay={setCompanyDetailInvestPay}
        listBids={listBids}
      />
      <ModalCancelInvestment
        showModalCancelInvest={cancelInvestmentInvestor}
        setCancelInvestmentInvestor={setCancelInvestmentInvestor}
        listBids={listBids}
      />
      <InvestmentContainer>
        <td>{listBids?.campaign?.nombreCampania ? listBids?.campaign?.nombreCampania : '-'}</td>
        <td>
          {formatCurrency(listBids?.compromisoInversion)
            ? formatCurrency(listBids?.compromisoInversion)
            : '-'}
        </td>
        <td>{listBids?.campaign?.isActive === '1' ? 'Activa' : 'Cerrada'}</td>
        <td>
          {formatCurrency(listBids?.campaign?.montoSolicitado)
            ? formatCurrency(listBids?.campaign?.montoSolicitado)
            : '0'}
        </td>
        <td>
          {formatDate(listBids?.campaign?.dateEndCampaign)
            ? formatDate(listBids?.campaign?.dateEndCampaign)
            : '00/00/0000'}
        </td>
        <td>
          <Button size="small" isFullWidth onClick={showCompanyDetailInvestPay}>
            Ver más
          </Button>
        </td>
        <td>
          <Button
            isFullWidth
            size="small"
            disabled={cancelDisabled}
            onClick={!cancelDisabled ? showModalCancelInvest : undefined}
          >
            {buttonText}
          </Button>
        </td>
      </InvestmentContainer>
    </>
  );
}
