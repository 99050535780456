import { useState, ChangeEvent, useEffect } from 'react';
import {
  LoginContainer,
  LoginInfo,
  LoginForm,
  NameContainer,
  ButtonSection
} from 'src/views/landingPage/Login/login.styles';
import { signInUser, setLoadingState } from 'src/store/User/user.slice';
import { useNavigate, Link } from 'react-router-dom';
import { url } from 'src/environments';
import axios from 'axios';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';

//Functions
import getIp from 'src/functions/getIp';
import { isSixMonthsFromLastLogin } from 'src/functions';
import { SignProps } from 'src/store/User/user.interface';
import { useAppThunkDispatch } from 'src/store/store';
import { useGeolocation } from 'src/hooks/useGeolocation';
import { useHandleLoginRedirect } from 'src/hooks/useHandleLoginRedirect';
import { setAppError } from 'src/store/app/app.slice';
import { getUserLastDateLogin } from 'src/requests/user/getUserInformation';
import { Auth } from 'aws-amplify';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import {
  updateIpAddress,
  updateGeolocation,
  GeolocationType,
  IpType,
  TypeOfUser
} from 'src/requests';
import { parseError } from 'src/functions/parseError';
import { InputField } from 'src/utilities/UILibrary';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();
  const [data, setData] = useState<SignProps>({
    email: '',
    password: ''
  });
  const [userNotConfirmed] = useState(false);
  const [userNotFound] = useState(false);
  const [userFullName, setUserFullName] = useState<string | null>(null);
  const [localLoading, setLocalLoading] = useState(false);
  //Modals
  const { getUserLocation, error: errorGeolocation } = useGeolocation();
  const [geolocationNotSupportedError, setGeolocationNotSupportedError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const { handleRedirectFromLogin } = useHandleLoginRedirect();

  const modalErrorMessages = {
    geolocationNotSupportedError:
      'Lamentamos informarte que tu navegador actual no soporta la geolocalización. Por favor, prueba con un navegador diferente o actualiza tu versión actual.',
    generic:
      'Es posible que este error se deba a que has rechazado los permisos para acceder a tu geolocalización. Por favor, verifica tus ajustes de privacidad y asegúrate de que has permitido el acceso a la geolocalización en tu navegador.'
  };

  useEffect(() => {
    if (errorGeolocation === 'Not supported') {
      setGeolocationNotSupportedError(true);
    }
  }, [errorGeolocation]);

  const handleSignInAction = async () => {
    dispatch(setLoadingState(true));
    try {
      const { email, password } = data;

      await Auth.signIn(email, password);

      await Auth.signOut({ global: true });
    } catch (error) {
      dispatch(setLoadingState(false));
      const friendlyError = parseError(error);
      return dispatch(
        setAppError({
          errorCode: friendlyError
        })
      );
    }
    await sendDataToServer();
  };

  const handleData = (value: string, name: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  useEffect(() => {
    if (errorGeolocation === 'Not supported') {
      setGeolocationNotSupportedError(true);
    }
  }, [errorGeolocation]);

  const validateEmail = async () => {
    await Auth.signOut({ global: true });
    setLocalLoading(true);
    const key = process.env.REACT_APP_APIKEY_FINSPHERA;
    try {
      const response = await axios.post(
        `${url}/admin/get-fullname`,
        {
          email: data.email
        },
        {
          headers: { 'x-api-key': key }
        }
      );
      const anonymousName = JSON.parse(response.data.body).anonymousName;
      setUserFullName(anonymousName);
    } catch (error) {
      setUserFullName('****');
    } finally {
      setLocalLoading(false);
    }
  };

  const handleUpdateUserLocation = async (
    id: string,
    ipObject: IpType,
    geolocationObject: GeolocationType,
    typeOfUser: TypeOfUser
  ) => {
    await updateIpAddress(id, typeOfUser, ipObject);
    await updateGeolocation(id, typeOfUser, geolocationObject);
  };

  const sendDataToServer = async () => {
    // sign in, retrieve user geolocation and ip, and trully validation
    const { email, password } = data;
    try {
      const [userGeolocation, ip] = await Promise.all([getUserLocation(), getIp()]);
      if (!userGeolocation || !ip.IPv4) {
        return setShowModal(true);
      }

      const response = await dispatch(signInUser({ email, password })).unwrap();

      if (
        response?.trully?.label === 'Review' ||
        response?.trully?.label === 'Threat' ||
        response?.onboarding?.isBlocked
      )
        return navigate('/pagina-validacion');

      const { id: userId, groupName } = response;
      const lastDateLogin = await getUserLastDateLogin(userId, groupName);
      const isMoreOfSixMonths = isSixMonthsFromLastLogin(lastDateLogin);
      const passwordUpdatedCookie = document.cookie
        .split('; ')
        .find((row) => row.startsWith('passwordUpdated='));
      if (isMoreOfSixMonths && !passwordUpdatedCookie) {
        await Auth.signOut({ global: true });
        return setAppError({ errorCode: 'Por favor, reestablece tu contraseña para continuar' });
      }

      if (response?.groupName === 'applicant') {
        await handleUpdateUserLocation(response.id, ip, userGeolocation, 'applicant');
        handleRedirectFromLogin(
          { typeOfPerson: response.typeOfPerson, typeOfUser: 'applicant' },
          response.onboarding?.currentStep,
          response.onboarding?.complete
        );
      } else if (response?.groupName === 'investor') {
        await handleUpdateUserLocation(response.id, ip, userGeolocation, 'investor');
        handleRedirectFromLogin(
          { typeOfPerson: response.typeOfPerson, typeOfUser: 'investor' },
          response.onboarding?.currentStep,
          response.onboarding?.complete
        );
      }
    } catch (error: unknown) {
      const friendlyError = parseError(error);

      dispatch(
        setAppError({
          errorCode: friendlyError
        })
      );
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  return (
    <LoginContainer>
      <ComposedModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        messageTitle="Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo más tarde."
        message={
          geolocationNotSupportedError
            ? modalErrorMessages.geolocationNotSupportedError
            : errorGeolocation
            ? modalErrorMessages.generic
            : undefined
        }
        type="error"
      />
      <LoginInfo>
        <div>
          <h2>
            <span>Financiamiento</span>a tu medida.
          </h2>
        </div>
        <p>
          Unimos empresas que necesitan créditos con personas que desean invertir en ellas en un
          modelo innovador de fondeo colectivo.
        </p>
        <ul>
          <li>Pre-Aprobación en 5 minutos</li>
          <li>Trámite 100% en línea.</li>
          <li>Solicita hasta 14 millones de pesos.</li>
        </ul>
      </LoginInfo>
      <LoginForm>
        <div>
          <h3>Iniciar sesión</h3>
          <p>
            Estimado usuario. Para cumplir con las leyes Mexicanas y asegurar la funcionalidad de
            nuestra aplicación, necesitamos tu permiso para la geolocalización. Por favor, autoriza
            este acceso en tu navegador. ¡Gracias por tu cooperación!
          </p>
          {userFullName && (
            <NameContainer>
              <p>Bienvenido</p>
              <h3>{userFullName}</h3>
              <button onClick={() => setUserFullName(null)}>Regresar</button>
            </NameContainer>
          )}
          <InputField
            type="email"
            name="email"
            variant="flushed"
            placeholder="Correo electrónico"
            onChangeInternalValue={(value, metadata) => handleData(value.toString(), metadata.name)}
            disabled={userFullName ? true : false}
          />
          {userFullName && (
            <>
              <InputField
                type="password"
                name="password"
                variant="flushed"
                placeholder="Contraseña"
                onChangeInternalValue={(value, metadata) =>
                  handleData(value.toString(), metadata.name)
                }
              />
              <ButtonSection>
                <Button isSlim onClick={handleSignInAction}>
                  Ingresar
                </Button>
              </ButtonSection>
            </>
          )}
          <ButtonSection>
            <Link to="/:usuario/reestablecer-contraseña">Olvidé mi contraseña</Link>
            {!userFullName && (
              <Button isSlim onClick={validateEmail} isLoading={localLoading}>
                Continuar
              </Button>
            )}
            <div>
              <p>¿Eres nuevo en FinSphera?</p>
              <Link to="/registro">Regístrate aquí</Link>
            </div>
            <Link to="/confirmar-cuenta">Reenviar confirmación de cuenta</Link>
          </ButtonSection>
          {userNotConfirmed && <p>Este usuario ya confirmó su correo electrónico</p>}
          {userNotFound && (
            <p>
              Este correo no se ha registrado. Inicia el proceso de registro{' '}
              <Link to="/registro">aquí</Link>
            </p>
          )}
        </div>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
