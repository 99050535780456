import { useEffect, useState } from 'react';
import { API } from 'aws-amplify';
import {
  DashboardOpportunitiesContainer,
  Banner,
  DashboardOpportunitiesOrderBy,
  DashboardOpportunitiesCardsContainer,
  Button
} from './opportunities.styles';
import Card from './subComponents/card';
import { getCreditApproved, listCampaigns_Dash } from 'src/graphql/customized_queries';

export default function DashboardOpportunities() {
  const [campaignData, setCampaignData] = useState();
  const [filterType, setFilterType] = useState('vigentes');
  const [orderByField, setOrderByField] = useState(null);
  const [esgDataMap, setEsgDataMap] = useState({});

  useEffect(() => {
    const fetchEsgDataForAll = async () => {
      if (campaignData && campaignData.length > 0) {
        try {
          const esgDataPromises = campaignData
            .filter((item) => item?.applicant?.id)
            .map((item) => {
              return API.graphql({
                query: getCreditApproved,
                variables: { applicantId: item.applicant.id }
              }).then((response) => ({
                id: item.applicant.id,
                data: response.data.getCreditAnalysis
              }));
            });

          const esgDataResults = await Promise.all(esgDataPromises);
          const esgDataMap = esgDataResults.reduce((acc, curr) => {
            acc[curr.id] = curr.data;
            return acc;
          }, {});

          setEsgDataMap(esgDataMap);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchEsgDataForAll();
  }, [campaignData]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await API.graphql({
          query: listCampaigns_Dash
        });
        const campaign = data?.data?.listCampaigns?.items;
        setCampaignData(campaign);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUserData();
  }, []);

  const handleFilter = (type) => {
    setFilterType(type);
    if (type === 'plazo' || type === 'monto') {
      setOrderByField(type);
    } else {
      setOrderByField(null);
    }
  };
  const filteredCampaigns = campaignData?.filter((item) => {
    if (filterType === 'cerradas') {
      return item.isActive === '0' && item.isExpired === '1';
    } else if (filterType === 'vigentes') {
      return item.isActive === '1';
    } else {
      return true;
    }
  });

  const orderedCampaigns = orderByField
    ? [...filteredCampaigns]
        .filter((item) => item.isActive === '1')
        .sort((a, b) =>
          orderByField === 'plazo'
            ? a.plazoCredito - b.plazoCredito
            : a.collectedAmount - b.collectedAmount
        )
    : filteredCampaigns;

  return (
    <DashboardOpportunitiesContainer>
      <Banner>
        <div>
          <h2>Oportunidades de inversión</h2>
          <p>
            Aquí puedes ver la información referente a nuestros créditos. Revísalos a continuación y
            sé parte de la cultura de financiamiento colectivo:
          </p>
        </div>
      </Banner>
      <DashboardOpportunitiesOrderBy>
        <p>Ordenar por:</p>
        <div>
          <Button
            onClick={() => handleFilter('vigentes')}
            filterType={filterType}
            buttonType="vigentes"
          >
            Campañas vigentes
          </Button>
          <span></span>
          <Button onClick={() => handleFilter('plazo')} filterType={filterType} buttonType="plazo">
            Plazo
          </Button>
          <span></span>
          <Button onClick={() => handleFilter('monto')} filterType={filterType} buttonType="monto">
            Monto recaudado
          </Button>
          <span></span>
          <Button
            onClick={() => handleFilter('cerradas')}
            filterType={filterType}
            buttonType="cerradas"
          >
            Campañas cerradas
          </Button>
        </div>
      </DashboardOpportunitiesOrderBy>
      <DashboardOpportunitiesCardsContainer>
        {orderedCampaigns?.map((item, index) => (
          <>
            <Card key={index} item={item} esgData={esgDataMap[item?.applicant?.id]} />
          </>
        ))}
      </DashboardOpportunitiesCardsContainer>
    </DashboardOpportunitiesContainer>
  );
}
