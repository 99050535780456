import React, { useEffect, useState } from 'react';

import { TooltipBackup } from './backup.styles';

export default function Backup(props) {
  const [backup, setBackup] = useState(props.showTooltipBackup);

  useEffect(() => {
    setBackup(props.showTooltipBackup);
  }, [props.showTooltipBackup]);

  const closeModal = () => setBackup(false);

  return (
    <TooltipBackup showTooltip={backup}>
      <div>
        <div>
          <h3>Respaldo</h3>
          <figure onClick={closeModal}>
            <img
              src="https://finsphera-webapp-resources.s3.amazonaws.com/icon_modal_close.png"
              alt="icono de tache"
            />
          </figure>
        </div>
        <div>
          <p>- Aval</p>
          <p>- Facturas</p>
          <p>- Pagaré Firmado</p>
        </div>
      </div>
    </TooltipBackup>
  );
}
