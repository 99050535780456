import { FC } from 'react';
import { GeneralLoader } from './spin.styles';

type SpinerProps = {
  message?: string;
};

const Spiner: FC<SpinerProps> = ({ message }) => {
  return (
    <GeneralLoader>
      <span />
      <div className="message-container">
        <h2>{message}</h2>
      </div>
    </GeneralLoader>
  );
};

export default Spiner;
