import { forwardRef, ComponentPropsWithoutRef } from 'react';
import { ErrorContainer } from './inputField.styles';

type ErrorMessageProps = {
  errorMessages: string[];
  errorRegex?: boolean;
  errorRegexMessage?: string;
  errorType?: 'error' | 'warning';
} & ComponentPropsWithoutRef<'span'>;

export const ErrorMessage = forwardRef<HTMLSpanElement, ErrorMessageProps>((props, ref) => {
  const { errorMessages, errorRegex, errorRegexMessage, errorType = 'error', ...restProps } = props;

  return (
    <ErrorContainer ref={ref} errortype={errorType} {...restProps}>
      {
        <ul>
          {errorMessages && errorMessages.map((element, index) => <li key={index}>{element}</li>)}
          {errorRegex && <li>{errorRegexMessage}</li>}
        </ul>
      }
    </ErrorContainer>
  );
});

ErrorMessage.displayName = 'ErrorMessage';
