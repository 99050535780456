import {
  ModalCreditSuccess,
  ContentContainer,
  ButtonsContainer,
  ConfirmButton,
  MainInfo,
  ComplementaryInfo,
  Success,
  Headers,
  Totals,
  IntermediateInfo
} from './creditWithdrawSuccess.styles';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import axios from 'axios';
import { url } from 'src/environments';
import { useSelector } from 'react-redux';
import getCurrentDate from 'src/functions/getCurrentDate';
import { setAppError } from 'src/store/app/app.slice';
export default function CreditPaymentModal({
  showModalCreditSuccess,
  closeModal,
  campaignData,
  personalInformation
}) {
  //PARA DOBLE FACTOR DE AUT
  const [showInput, setShowInput] = useState(false);
  const [messageId, setMessageId] = useState();
  const [otp, setOtp] = useState('');
  const [localLoading, setLocalLoading] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const saldoDisponible = personalInformation?.data?.getApplicant?.STP?.recursosDisponibles;
  const saldoDisponibleNum = parseFloat(saldoDisponible);
  const user = useSelector((state) => state.user.currentUser);
  function formatCurrency(amount) {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN'
    }).format(amount);
  }

  function getElapsedDays(date) {
    const currentDate = new Date(Date.now());
    const startDate = new Date(date);
    const differenceInTime = currentDate - startDate;
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays >= 0 ? differenceInDays : null;
  }
  const elapsedDays = getElapsedDays(campaignData?.signDate);
  const diasMora = elapsedDays - campaignData?.plazoCredito;

  const updateCampaign = {
    type: 'update_campaign_money_related_final',
    input: {
      campaignId: campaignData?.id,
      paymentDateFinal: getCurrentDate()
    }
  };
  const montoPorLiquidar =
    campaignData?.collectedAmount +
    campaignData?.interesesCreditoPesosConIVA +
    (diasMora > 0 ? campaignData?.interesesMoratorioPesosConIVA : 0);
  const montoPorLiquidarRounded = parseFloat(montoPorLiquidar.toFixed(2));
  const isMontoGreater = montoPorLiquidarRounded > saldoDisponibleNum;
  const handleSendEmail = () => {
    setLocalLoading(true);
    const input = {
      id: user.id,
      typeOfUser: 'applicant',
      typeMessage: 'payCampaign',
      email: user.email,
      timestamp: Date.now()
    };
    axios
      .post(`${url}/admin/send-otp-to-email`, input)
      .then((response) => {
        const parsedBody = JSON.parse(response.data.body);
        setMessageId(parsedBody.messageId);
        setShowInput(true);
      })
      .catch((error) => {
        console.error(error);
        setLocalLoading(false);
      });
    setShowInput(true);
    setLocalLoading(false);
  };

  const onConfirm = async () => {
    setLocalLoading(true);
    const input = {
      id: user.id,
      messageId: messageId,
      code: otp,
      timestamp: Date.now(),
      typeOfUser: 'applicant'
    };
    const validationCode = await axios.post(`${url}/admin/email/validate-otp`, input);
    const parsedBody = JSON.parse(validationCode.data.body);
    if (!parsedBody.isVerified) {
      alert('Validación de código fallida');
      return;
    }
    //JWT agregarlo en este endpoint
    const session = await Auth.currentSession();
    const Finsphera_Auth = session.getIdToken().getJwtToken();
    const resources = {
      Finsphera_Auth,
      campaignId: campaignData?.id
    };
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_STPURL_FINSPHERA}/stp/finish-complete`,
      headers: {
        apiKey: `${process.env.REACT_APP_APIKEY_STP}`,
        'Content-Type': 'application/json'
      },
      data: resources
    };
    try {
      const update = await axios.post(`${url}/admin/dynamo/update`, updateCampaign);
      if (update.status === 200) {
        const stp = await axios(config);
        if (stp.status === 201) {
          setSuccessText(true);
        }
      } else {
        setAppError();
        setLocalLoading(false);
        console.error('Second request did not return the expected result');
      }
    } catch (error) {
      setAppError();
      setLocalLoading(false);
      console.error(error);
    }
  };

  return (
    <ModalCreditSuccess showModalCreditSuccess={showModalCreditSuccess}>
      {!successText ? (
        <ContentContainer>
          <Headers>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </Headers>
          <h2>
            ¡Estás apunto de realizar el pago del crédito que tienes vigente con{' '}
            <span>FINSPHERA!</span>
          </h2>
          <MainInfo>
            <div>
              <h3>Nombre de campaña:</h3>
              <p> {campaignData?.nombreCampania ? campaignData?.nombreCampania : '-'} </p>
            </div>
            <div>
              <h3>Monto del crédito:</h3>
              <p>
                {campaignData?.collectedAmount
                  ? formatCurrency(campaignData?.collectedAmount)
                  : '-'}
              </p>
            </div>
            <div>
              <h3>Monto por liquidar:</h3>
              <p>{montoPorLiquidar ? formatCurrency(montoPorLiquidar) : '-'}</p>
            </div>
            <div>
              <h3>Plazo del crédito:</h3>
              <p>{campaignData?.plazoCredito ? campaignData?.plazoCredito : '-'} días</p>
            </div>
            <div>
              <h3>Días transcurridos:</h3>
              <p>{elapsedDays}</p>
            </div>
          </MainInfo>
          <IntermediateInfo>
            <div>
              <h3>Tasa de interés:</h3>
              <p>{(campaignData?.tasaCreditoPorcentaje * 100).toFixed(2)} %</p>
            </div>
            <div>
              <h3>Tasa de interés moratoria:</h3>
              <p>{(campaignData?.tasaInteresMoratorioPorcentaje * 100).toFixed(2)}%</p>
            </div>
          </IntermediateInfo>
          <ComplementaryInfo>
            <div>
              <h3>Intereses generados hasta hoy:</h3>
              <p>
                {campaignData?.interesCreditoPesos
                  ? formatCurrency(campaignData?.interesCreditoPesos)
                  : '-'}
              </p>
            </div>
            <div>
              <h3>IVA de los intereses generados:</h3>
              <p>
                {campaignData?.IVAinteresCreditoPesos
                  ? formatCurrency(campaignData?.IVAinteresCreditoPesos)
                  : '-'}
              </p>
            </div>
            <Totals>
              <h3>Intereses + IVA:</h3>
              <p>
                {campaignData?.interesesCreditoPesosConIVA
                  ? formatCurrency(campaignData?.interesesCreditoPesosConIVA)
                  : '-'}
              </p>
            </Totals>
            {diasMora >= 1 && (
              <div>
                <h3>Intereses moratorios generados hasta hoy:</h3>
                <p>
                  {campaignData?.interesMoratorioPesos
                    ? formatCurrency(campaignData?.interesMoratorioPesos)
                    : '-'}
                </p>
              </div>
            )}
            {diasMora >= 1 && (
              <div>
                <h3>IVA de los intereses moratorios generados:</h3>
                <p>
                  {campaignData?.IVAinteresMoratorio
                    ? formatCurrency(campaignData?.IVAinteresMoratorio)
                    : '-'}
                </p>
              </div>
            )}
            {diasMora >= 1 && (
              <Totals>
                <h3>Intereses moratorios + IVA:</h3>
                <p>
                  {campaignData?.interesesMoratorioPesosConIVA
                    ? formatCurrency(campaignData?.interesesMoratorioPesosConIVA)
                    : '-'}
                </p>
              </Totals>
            )}
            {diasMora >= 1 && (
              <Totals>
                <h2>Intereses Totales:</h2>
                <p>
                  {campaignData?.interesesMoratorioPesosConIVA +
                  campaignData?.interesesCreditoPesosConIVA
                    ? formatCurrency(
                        campaignData?.interesesMoratorioPesosConIVA +
                          campaignData?.interesesCreditoPesosConIVA
                      )
                    : '-'}
                </p>
              </Totals>
            )}
          </ComplementaryInfo>
          {showInput ? (
            <ButtonsContainer>
              <p>Ingresa el código que enviamos a tu correo:</p>
              <input
                type="text"
                value={otp}
                onChange={(e) => {
                  setOtp(e.target.value);
                }}
              />
              <Button isLoading={localLoading} size="small" onClick={onConfirm}>
                Confirmar código
              </Button>
            </ButtonsContainer>
          ) : (
            <ButtonsContainer>
              {isMontoGreater && (
                <p>
                  No puedes realizar el pago ya que el monto que tienes por liquidar supera el saldo
                  disponible en tu cuenta. ¡Fondea tu cuenta ahora!
                </p>
              )}
              <ConfirmButton disabled={isMontoGreater} onClick={handleSendEmail}>
                Aceptar
              </ConfirmButton>
            </ButtonsContainer>
          )}
        </ContentContainer>
      ) : (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="icono de correo electrónico"
            />
          </figure>
          <h2>¡Tu pago se ha realizado de forma exitosa!</h2>
          <p>
            Si tienes alguna duda respecto a esta operación, no dudes en contactar al equipo de
            Finsphera.
          </p>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      )}
    </ModalCreditSuccess>
  );
}
