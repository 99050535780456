import React from 'react';

import {
  InvestDetailContainer,
  Banner,
  InvestInfo,
  InvestDetailInfo,
  InvestDetailCost
} from './investDetail.styles';

export default function InvestDetail() {
  return (
    <InvestDetailContainer>
      <Banner>
        <figure>
          <img
            src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_dash_investDetail_banner.png`}
            alt="Finsphera configuración de seguridad"
          />
        </figure>
        <h2>¡Bienvenido a Finsphera!</h2>
        <p>
          ¡Ya eres un inversionista y estás listo para impactar positivamente a nuestra Comunidad y
          Medio Ambiente! Cualquier duda estámos a tu disposición en{' '}
          <span>atencionaclientes@finsphera.com</span>.
        </p>
      </Banner>
      <InvestInfo>
        <h2>Detalles de tu inversión:</h2>
        <InvestDetailInfo>
          <div>
            <h3>Tasa:</h3>
            <h3>Monto de inversión:</h3>
            <h3>Monto a transferir:</h3>
          </div>
          <div>
            <p>15%</p>
            <p>$1,000</p>
            <p>$1,000</p>
          </div>
        </InvestDetailInfo>
        <InvestDetailCost>
          <div>
            <h3>Costos de la inversión</h3>
            <div>
              <p>
                Comisión FinSphera: <em>cobrado al inicio de la inversión</em>.
              </p>
              <p>$0.00</p>
            </div>
            <hr />
            <div>
              <p>
                Cobro por cobranza:{' '}
                <em>se descontará de los intereses una vez liquidado el crédito</em>.
              </p>
              <p>$8.97</p>
            </div>
          </div>
          <div>
            <h3>Retorno de la inversión:</h3>
            <div>
              <p>Plazo estimado de retorno de la inversión:</p>
              <p>160 días</p>
            </div>
            <hr />
            <div>
              <p>Monto bruto:</p>
              <p>$1,048.33</p>
            </div>
            <hr />
            <div>
              <p>Utilidad neta al fin del crédito:</p>
              <p>$39.36</p>
            </div>
          </div>
        </InvestDetailCost>
      </InvestInfo>
    </InvestDetailContainer>
  );
}
