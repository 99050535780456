import styled from 'styled-components/macro';
import { UploadButton } from 'src/utilities/UILibrary/Forms/UploadButton/UploadButton';
import { ChangeEvent, useState } from 'react';

const TestViewContainer = styled.div`
  padding: 60px 6.25%;
  background-color: #e5e5e5;
  display: flex;
  min-height: 80vh;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const TestView = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>, file?: File) => {
    setIsLoading(true);
    setIsLoading(false);
  };

  return (
    <TestViewContainer>
      <form>
        <UploadButton
          isLoading={isLoading}
          onChange={(event, file) => handleFileChange(event, file)}
        >
          Upload
        </UploadButton>
      </form>
    </TestViewContainer>
  );
};
