import { useState, useEffect } from 'react';
import axios from 'axios';
import {
  BeneficiariesCard,
  InfoBeneficiary,
  ButtonContainer,
  AddressBeneficiary
} from './cardBeneficiaries.styles';
import ModalWarning from 'src/components/shared/modals/warning/warning';
import { url } from 'src/environments';

export default function CardBeneficiaries(props) {
  const [editable, setEditable] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [updatedBeneficiaryData, setUpdatedBeneficiaryData] = useState(
    props.beneficiaryData || defaultBeneficiaryData
  );
  const defaultBeneficiaryData = {
    firstName: '',
    lastNameP: '',
    lastNameM: '',
    dateOfBirth: '',
    relationship: '',
    percentage: '',
    street: '',
    numInt: '',
    numExt: '',
    country: '',
    state: '',
    district: '',
    postalCode: ''
  };
  const beneficiaryUpdate = {
    type: 'update_beneficiary_percentage',
    input: {
      id: updatedBeneficiaryData.id,
      percentage: updatedBeneficiaryData.percentage
    }
  };
  const handleInputChange = (event) => {
    setUpdatedBeneficiaryData({
      ...updatedBeneficiaryData,
      [event.target.name]: event.target.value
    });
  };
  useEffect(() => {
    setUpdatedBeneficiaryData(props.beneficiaryData);
  }, [props.beneficiaryData]);
  const sendDataToServer = async () => {
    try {
      const result = await axios.post(`${url}/admin/dynamo/update`, beneficiaryUpdate);
      setEditable(false);
      props.onRefreshData();
      window.location.reload();
    } catch (error) {
      console.error('Error updating beneficiary:', error);
    }
  };

  //Calcs to consider the available percentage options
  const percentageOptions = [0.1, 0.2, 0.25, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1];
  const remainingPercentage = parseFloat((1 - props.totalAssignedPercentage).toFixed(2));
  const currentBeneficiaryPercentage = props.beneficiaryData.percentage;
  const maxPercentageForBeneficiary = parseFloat(
    (currentBeneficiaryPercentage + remainingPercentage).toFixed(2)
  );
  let availableOptions = percentageOptions.filter(
    (option) => option <= maxPercentageForBeneficiary
  );

  const handleEdit = () => {
    setEditable(true);
    setUpdatedBeneficiaryData(props.beneficiaryData);
  };
  const handleDelete = () => {
    setWarningModal(true);
  };

  const cancelEdit = () => {
    setEditable(false);
  };
  if (!updatedBeneficiaryData) {
    return null;
  }
  return (
    <BeneficiariesCard editData={handleEdit}>
      <ModalWarning
        showModal={warningModal}
        onClose={() => setWarningModal(false)}
        beneficiaryId={props.beneficiaryData.id}
        onDelete={props.onDelete}
      />
      {editable ? (
        <ButtonContainer>
          <button onClick={sendDataToServer}>Aceptar</button>
          <button onClick={cancelEdit}>Cancelar</button>
        </ButtonContainer>
      ) : (
        <ButtonContainer>
          <button onClick={handleDelete}>Eliminar</button>
          <button onClick={() => setEditable(true)}>Editar</button>
        </ButtonContainer>
      )}
      <h2>Datos del beneficiario:</h2>
      <InfoBeneficiary>
        <div>
          <h3>Nombre(s):</h3>
          <p>{props.beneficiaryData.firstName ? props.beneficiaryData.firstName : '-'}</p>
        </div>
        <div>
          <h3>Apellido paterno:</h3>
          <p>{props.beneficiaryData.lastNameP}</p>
        </div>
        <div>
          <h3>Apellido materno:</h3>
          <p>{props.beneficiaryData.lastNameM}</p>
        </div>
        <div>
          <h3>Relación o parentesco:</h3>
          <p>{props.beneficiaryData.relationship}</p>
        </div>
        <div>
          <h3>Fecha de nacimiento:</h3>
          <p>{props.beneficiaryData.dateOfBirth}</p>
        </div>
        {editable ? (
          <div>
            <h3>Porcentaje:</h3>
            <select
              value={updatedBeneficiaryData.percentage}
              onChange={handleInputChange}
              name="percentage"
            >
              <option value={0}>Seleccionar...</option>
              {availableOptions.map((option) => (
                <option key={option} value={option}>
                  {Math.round(option * 100)}%
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div>
            <h3>Porcentaje:</h3>
            <p>{props.beneficiaryData.percentage * 100} %</p>
          </div>
        )}
      </InfoBeneficiary>
      <h2>Domicilio actual:</h2>
      <AddressBeneficiary>
        <div>
          <h3>Calle:</h3>
          <p>{props.beneficiaryData.street}</p>
        </div>
        <div>
          <h3>Número externo:</h3>
          <p>{props.beneficiaryData.numExt}</p>
        </div>
        <div>
          <h3>Número interno:</h3>
          <p>{props.beneficiaryData.numInt}</p>
        </div>
        <div>
          <h3>País: </h3>
          <p>{props.beneficiaryData.country}</p>
        </div>
        <div>
          <h3>Estado:</h3>
          <p>{props.beneficiaryData.state}</p>
        </div>
        <div>
          <h3>Municipio o Alcaldía:</h3>
          <p>{props.beneficiaryData.district}</p>
        </div>
        <div>
          <h3>Código postal:</h3>
          <p>{props.beneficiaryData.postalCode}</p>
        </div>
      </AddressBeneficiary>
    </BeneficiariesCard>
  );
}
