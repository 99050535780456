import styled from 'styled-components/macro';
import { brandBlack, whiteSphera, brandYellowgreen } from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const PersonalInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);
  padding-bottom: 2.5%;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    padding-bottom: 2.5%;
  }
`;

export const Options = styled.div`
  display: flex;
  width: 90%;
  margin: 5% auto 0 auto;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
  justify-content: space-around;
`;

export const Option = styled.div`
  font-size: ${uIFontSize.medium};
  color: ${(props) => (props.isSelected ? brandBlack : whiteSphera)};
  text-align: center;
  flex: 1;
  padding: 1.5% 0.5%;
  background-color: ${(props) => (props.isSelected ? brandYellowgreen : brandBlack)};
  cursor: pointer;
  &:hover {
    background-color: ${brandYellowgreen};
    color: ${brandBlack};
  }
  @media (max-width: 600px) {
    font-size: ${uIFontSize.small};
  }
`;
