import styled from 'styled-components';
import { PlatformBannerProps } from './platformBanner.types';
import { whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const PlatformBannerContainer = styled.div<PlatformBannerProps>`
  background-color: ${whiteSphera};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 1rem 3rem;

  .image-container {
    width: fit-content;

    img {
      width: 65px;
    }
  }

  .body {
    h1 {
      margin-bottom: 1rem;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 1rem 5%;
    justify-content: left;
    gap: 2rem;

    .image-container {
      img {
        max-width: 100px;
        width: 100%;
      }
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 1rem 15%;
  }

  @media (min-width: ${breakpoints.largeDesktop}) {
    padding: 1rem 20%;
  }
`;
