// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUserFlow = (currentUser: any) => {
  if (currentUser.groupName === 'investor') {
    if (currentUser.typeOfPerson === 'FISICA') {
      return 'physicalInvestor';
    }
    if (currentUser.typeOfPerson === 'MORAL') {
      return 'moralInvestor';
    }
  }

  if (currentUser.groupName === 'applicant') {
    return 'moralApplicant';
  }

  return 'moralInvestor';
};
