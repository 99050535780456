import { CardContainer } from './styles';
import { CardProps } from './types';
import { forwardRef } from 'react';

export const Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { children, justifyContent = 'center', alignItems = 'center', ...restProps } = props;

  return (
    <CardContainer justifyContent={justifyContent} alignItems={alignItems} ref={ref} {...restProps}>
      {children}
    </CardContainer>
  );
});

Card.displayName = 'Card';
