import styled from 'styled-components/macro';
import { InputUploadProps } from './inputUpload.types';

type InputUploadContainerProps = Pick<InputUploadProps, 'wrapLabel'>;

export const InputUploadContainer = styled.div<InputUploadContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 9.375rem;
  gap: 0.3rem;

  label {
    white-space: ${(p) => (p.wrapLabel ? 'normal' : 'nowrap')};
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
