import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { ProtectedRoute, ProtectedRouteProps } from 'src/routes/ProtectedRoute';
import { getCurrentOnboardingStep, getUserFlow } from 'src/functions';
import { useNavigate } from 'react-router-dom';
import { flowTypeToRoutesMap } from 'src/constants';
import { isByPass } from 'src/environments';
import { useEffect } from 'react';

type ProtectedOnboardingRouteProps = Omit<ProtectedRouteProps, 'condition'> & {
  protectDashboard?: boolean;
};

export const ProtectedOnboardingRoute = (props: ProtectedOnboardingRouteProps) => {
  const { protectDashboard = false, ...rest } = props;
  const user = useSelector((state: RootState) => state.user?.currentUser);
  const navigate = useNavigate();
  const { onboarding } = user;

  const currentBackendStep = onboarding?.currentStep;
  const isOnboardingCompleted = onboarding?.complete;

  const userFlow = getUserFlow(user);
  const userRoutes = flowTypeToRoutesMap[userFlow];
  const currentActualStep = getCurrentOnboardingStep(user);

  const condition: boolean =
    (protectDashboard && isOnboardingCompleted === false) ||
    (!protectDashboard && isOnboardingCompleted);

  useEffect(() => {
    if (onboarding?.isBlocked) navigate('/pagina-validacion');

    if (
      currentBackendStep !== currentActualStep &&
      currentBackendStep &&
      !protectDashboard &&
      !isByPass
    ) {
      navigate(userRoutes[currentBackendStep - 1].path);
    }
  }, [onboarding, currentBackendStep, currentActualStep, protectDashboard]);

  return <ProtectedRoute {...rest} condition={condition} />;
};
