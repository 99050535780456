import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Success, Figure, ActionSection, ModalComplaint } from './complaint.styles';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Button } from 'src/utilities/UILibrary/UI/Button/Button';
import { InputTextArea } from 'src/utilities/UILibrary/Forms/InputTextArea/InputTextArea';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { createComplaint } from 'src/store/User/user.slice';
import { Card } from 'src/utilities/UILibrary';
import { useNavigate } from 'react-router-dom';
import { setAppError } from 'src/store/app/app.slice';

export default function Complaint({ complaintModal, setComplaintModal }) {
  const [successText, setSuccessText] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();
  const typeOfContact = watch('typeOfContact');
  const closeModal = () => {
    setComplaintModal(false);
    if (user.groupName === 'applicant') {
      navigate(`/solicitante/dashboard/account`);
    } else {
      navigate(`/inversionista/dashboard`);
    }
  };
  const typeOptions = [
    { value: 'DUDA', label: 'DUDA' },
    { value: 'RECLAMO', label: 'RECLAMO' },
    { value: 'QUEJA', label: 'QUEJA' }
  ];
  const contactOptions = [
    { value: 'telephone', label: 'Teléfono' },
    { value: 'email', label: 'Correo electrónico' }
  ];

  const onSubmit = async (data) => {
    setLocalLoading(true);
    let body = {
      type: data.type,
      typeOfContact: data.typeOfContact,
      contact: data.contact,
      description: data.description
    };
    if (user.groupName === 'applicant') {
      body = { ...body, applicantId: user.id };
    } else {
      body = { ...body, investorId: user.id };
    }
    try {
      await dispatch(createComplaint(body));
      setSuccessText(true);
    } catch (error) {
      console.error('Error unwrapping result:', error);
      setAppError();
    } finally {
      setLocalLoading(false);
    }
  };
  return (
    <ModalComplaint showModal={complaintModal}>
      {successText ? (
        <Success>
          <figure>
            <img
              src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_success.png`}
              alt="ícono de correo electrónico"
            />
          </figure>
          <h2>
            ¡Tu solicitud ha sido enviada de forma exitosa! En breve nos estaremos comunicando
            contigo
          </h2>
          <Button onClick={closeModal}>Cerrar</Button>
        </Success>
      ) : (
        <div>
          <Figure>
            <div></div>
            <figure onClick={closeModal}>
              <img
                src={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_modal_close.png`}
                alt="ícono para cerrar la ventana"
              />
            </figure>
          </Figure>
          <ActionSection>
            <Card isForm onSubmit={handleSubmit(onSubmit)}>
              <p>
                En Finsphera estamos comprometidos con la atención y servicio que te brindamos. Por
                eso ponemos a tu disposición este buzón para ingresar cualquier comentario, duda,
                problemática y darle solución cuanto antes. Por favor proporciona la siguiente
                información:
              </p>
              <Card.Row>
                <Controller
                  name="type"
                  control={control}
                  defaultValue={undefined}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field, fieldState, ...rest }) => (
                    <InputField
                      label="Tipo de solicitud:"
                      placeholder="Selecciona una opción"
                      isDropdown
                      options={typeOptions}
                      onChangeInternalValue={field.onChange}
                      errorMessages={
                        fieldState.error && fieldState.error.message
                          ? [fieldState.error.message]
                          : []
                      }
                      {...rest}
                    />
                  )}
                />
              </Card.Row>
              <Card.Row>
                <Controller
                  name="typeOfContact"
                  control={control}
                  defaultValue={undefined}
                  rules={{ required: 'Es un campo requerido.' }}
                  render={({ field, fieldState, ...rest }) => (
                    <InputField
                      label="Medio para contactarte y llevar seguimiento:"
                      placeholder="Selecciona una opción"
                      isDropdown
                      options={contactOptions}
                      onChangeInternalValue={field.onChange}
                      errorMessages={
                        fieldState.error && fieldState.error.message
                          ? [fieldState.error.message]
                          : []
                      }
                      {...rest}
                    />
                  )}
                />
              </Card.Row>
              {typeOfContact === 'telephone' && (
                <Card.Row>
                  <Controller
                    name="contact"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: 'Es un campo requerido.' }}
                    render={({ field, fieldState, ...rest }) => (
                      <InputField
                        isDialCode
                        onChangeInternalValue={field.onChange}
                        placeholder="Número de teléfono"
                        minLength={10}
                        required
                      />
                    )}
                  />
                </Card.Row>
              )}
              {typeOfContact === 'email' && (
                <Card.Row>
                  <Controller
                    name="contact"
                    control={control}
                    defaultValue={undefined}
                    rules={{ required: 'Es un campo requerido.' }}
                    render={({ field, fieldState, ...rest }) => (
                      <InputField
                        onChangeInternalValue={field.onChange}
                        placeholder="Correo electrónico"
                        minLength={10}
                        required
                      />
                    )}
                  />
                </Card.Row>
              )}
              <Card.Row>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    minLength: {
                      value: 10,
                      message: 'Descripción de mínimo 10 caracteres'
                    },
                    maxLength: {
                      value: 300,
                      message: 'Descripción de máximo 300 caracteres'
                    },
                    required: 'Es un campo requerido.'
                  }}
                  render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                    <InputTextArea
                      label="Ingresa una descripción de la situación:"
                      placeholder="..."
                      maxLength={400}
                      onChange={onChange}
                      errorMessages={error && error.message ? [error.message] : []}
                      {...rest}
                    />
                  )}
                />
              </Card.Row>
              <Button
                submitAction={handleSubmit(onSubmit)}
                isLoading={localLoading}
                isFullWidth
                size="small"
                type="submit"
              >
                Enviar solicitud
              </Button>
            </Card>
          </ActionSection>
        </div>
      )}
    </ModalComplaint>
  );
}
