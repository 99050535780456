import styled from 'styled-components/macro';
import {
  whiteSphera,
  neutralBlue,
  neutralBlueGray,
  neutralLight,
  greenSp,
  brandBlack
} from 'src/utilities/UIStyles/variables/colors';

export const ProfessionalInfoContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 2.5%;
  justify-content: space-between;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  > h3 {
    margin-bottom: 2%;
    text-align: center;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const ProfesionalSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 3% 2.5%;
  width: 100%;
  > h3 {
    color: ${greenSp};
    font-size: 1.5vw;
    text-align: center;
    width: 100%;
  }
  > div {
    width: 80%;
    margin: 3% 20%;
    display: flex;
    justify-content: space-evenly;
    > p {
      color: ${neutralBlueGray};
      font-size: 1.3vw;
      padding: 1% 0;
      background-color: ${neutralLight};
    }
    > input {
      border-radius: 5px;
      border-style: none;
      width: 80%;
      padding: 1% 0;
      border: 1px solid ${neutralBlue};
    }
  }
`;

export const DashboardAccountCLABEButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto 5% auto;

  > button {
    font-size: 1.2vw;
    font-weight: bold;
    color: ${whiteSphera};
    width: 45%;
    padding: 2% 0;
    border: none;
    border-radius: 5px;
    background-color: ${brandBlack};
    cursor: pointer;
    transition: all 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > button {
      &:hover {
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto 5% auto;

    > button {
      font-size: 4.2vw;
      width: 90%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;

      &:hover {
      }
    }
  }
`;

export const DashboardAccountCLABETable = styled.table`
  width: 90%;
  margin: auto;

  > tbody {
    > tr {
      > th {
        font-size: 1.2vw;
        color: ${whiteSphera};
        text-align: left;
        height: 50px;
        padding: 1% 1.5% 1% 0.5%;
        background-color: ${brandBlack};
        > button {
          font-size: 1.2vw;
          font-weight: bold;
          color: ${greenSp};
          width: 35%;
          margin-left: 10%;
          padding: 2% 0;
          border: 1px solid ${greenSp};
          border-radius: 5px;
          background-color: ${brandBlack};
          cursor: pointer;
          transition: all 0.5s;
        }
      }

      > td {
        color: ${brandBlack};
        font-size: 1vw;
        text-align: left;
        padding: 1% 0 1% 0.5%;
      }
    }

    > tr:nth-child(2n) {
      background-color: #f0f5ff;
    }

    > tr:nth-child(3n) {
      background-color: #e1e8f6;
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > tbody {
      > tr {
        > th {
        }

        > td {
        }

        > td:last-child {
          > button {
          }
        }
      }

      > tr:nth-child(2n) {
      }

      > tr:nth-child(3n) {
      }
    }
  }

  @media (max-width: 600px) {
    width: 400px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    > tbody {
      > tr {
        > th {
          font-size: 3.5vw;
        }

        > td {
          font-size: 3vw;
        }

        > td:last-child {
          > button {
            font-size: 3vw;
          }
        }
      }

      > tr:nth-child(2n) {
      }

      > tr:nth-child(3n) {
      }
    }
  }
`;
