import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { PersonalInformationContainer } from './phoneConfirmation.styles';
import Card from 'src/utilities/UILibrary/UI/Card/Card';
import { InputField } from 'src/utilities/UILibrary/Forms/InputField/InputField';
import { useManageSteps } from 'src/hooks/useManageSteps';
import { url } from 'src/environments';
import { setLoadingState } from 'src/store/User/user.slice';
import { Link, useNavigate } from 'react-router-dom';
import { RootState, useAppThunkDispatch } from 'src/store/store';
import { PlatformBanner } from 'src/utilities/UILibrary/Layout/OnboardingBanner/PlatformBanner';
import { FormActionButtons } from 'src/components/features/onboarding/FormActionButtons/FormActionButtons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormValues } from './phoneConfirmation.types';
import { setAppError } from 'src/store/app/app.slice';
import { isByPass, config } from 'src/environments';
import { ComposedModal } from 'src/components/shared/compositionComponents/ComposedModal/ComposedModal';
function formatCurrency(amount: number) {
  return new Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN'
  }).format(amount);
}
const generateModalMessage = (
  typeOfUser: string,
  data: { interestRateApplicantFinal: number; creditLineFinal: number }
) => {
  if (typeOfUser === 'applicant') {
    return (
      <>
        <h3>
          Tu línea de crédito aprobada es: <span> {formatCurrency(data.creditLineFinal)} </span>
        </h3>
        <h3>
          Tu tasa de interés es: <span> {data.interestRateApplicantFinal.toFixed(2)} %</span>
        </h3>
        <p>
          Para que puedas hacer uso de tu crédito, es necesario que cargues todos los documentos que
          te pedimos en el apartado de:
          <Link to={'/solicitante/dashboard/mi-perfil'}> Mi Perfil / Mis documentos. </Link>{' '}
        </p>
      </>
    );
  } else if (typeOfUser === 'investor') {
    return (
      <>
        <strong>
          Recuerda que para comenzar a invertir en campañas, debes ir al apartado de tu
          <Link to={'/inversionista/dashboard'}> perfil </Link>
          para completar tu información y firmar tus contratos. <br /> ¡Es muy sencillo!
        </strong>
      </>
    );
  }
};

type PhoneConfirmationProps = {
  typeOfUser: 'investor' | 'applicant';
};

export function PhoneConfirmation(props: PhoneConfirmationProps) {
  const [modalData, setModalData] = useState({ interestRateApplicantFinal: 0, creditLineFinal: 0 });
  const { typeOfUser } = props;
  const [codeSended, setCodeSended] = useState(false);
  const [referenceId, setReferenceId] = useState('');
  const [showFinishedModal, setShowFinishedModal] = useState(false);
  const dispatch = useAppThunkDispatch();
  const user = useSelector((state: RootState) => state.user.currentUser);
  const { nextStep } = useManageSteps();
  const navigate = useNavigate();
  const { control, handleSubmit, reset } = useForm<FormValues>();

  const resetInputs = () => {
    setCodeSended(false);
    reset({
      phoneNumber: '',
      validationCode: ''
    });
  };

  const handleClick = (typeOfUser: string) => {
    if (typeOfUser === 'investor') {
      navigate('/inversionista/dashboard');
    } else if (typeOfUser === 'applicant') {
      navigate('/solicitante/dashboard/mi-perfil');
    }
  };

  const handleGetCode = async (phoneNumber: string) => {
    dispatch(setLoadingState(true));

    try {
      const input = {
        id: user.id,
        typeOfUser
      };

      const phone = await axios.post(`${url}/admin/mobile/${phoneNumber}/get-otp`, input);
      const parsedBody = JSON.parse(phone.data.body);
      const referenceId = parsedBody.ReferenceId;
      setReferenceId(referenceId);
      setCodeSended(true);
    } catch {
      dispatch(setAppError({}));
      resetInputs();
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const handleValidateCode = async (phoneNumber: string, validationCode: string) => {
    dispatch(setLoadingState(true));

    try {
      const body = {
        id: user.id,
        otp: validationCode,
        referenceId: referenceId,
        typeOfUser
      };
      const trully = {
        id: user.id,
        typeOfUser,
        phone: phoneNumber
      };
      const credit = {
        id: user.id
      };

      const response = await axios.post(`${url}/admin/mobile/${phoneNumber}/validate-otp`, body);
      const parsedBody = JSON.parse(response.data.body);
      const codeResponse = parsedBody.isVerified;
      if (!codeResponse) {
        return dispatch(setAppError({ errorCode: 'Código incorrecto' }));
      }
      if (!isByPass && config.env === 'prod') {
        const trullyTwo = await axios.post(`${url}/trully/enrich-data/predict/second-step`, trully);
        const trullyBody = JSON.parse(trullyTwo.data.body);
        const label = trullyBody.label;
        if (label !== 'No Threat') return navigate('/validation-page');
        if (typeOfUser === 'applicant') {
          await axios.post(`${url}/admin/credit-analysis/circulo-de-credito/onboarding`, credit);
          const creditResult = await axios.get(`${url}/admin/credit-analysis/result?id=${user.id}`);
          const parsedBody = JSON.parse(creditResult.data.body);
          setModalData({
            interestRateApplicantFinal: parsedBody.interestRateApplicantFinal,
            creditLineFinal: parsedBody.creditLineFinal
          });
        }
      }
      await nextStep();
      return setShowFinishedModal(true);
    } catch {
      dispatch(setAppError({}));
      resetInputs();
    } finally {
      dispatch(setLoadingState(false));
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (codeSended) return handleValidateCode(data.phoneNumber, data.validationCode);

    return handleGetCode(data.phoneNumber);
  };

  return (
    <PersonalInformationContainer className="onboarding-view-content">
      <ComposedModal
        messageTitle={'¡Haz concluido tu proceso exitosamente!'}
        messageChildren={generateModalMessage(typeOfUser, modalData)}
        type="success"
        isOpen={showFinishedModal}
        onClose={() => handleClick}
        buttons={[
          {
            label: 'Continuar',
            onClick: () => handleClick(typeOfUser),
            color: 'green'
          }
        ]}
        isCloseButton={false}
        onOverlayClose={false}
      />
      <PlatformBanner
        imgSrc={`${process.env.REACT_APP_BUCKET_FINSPHERA}/icon_inv_personalInformation6_banner.png`}
        imgAlt="Finsphera configuración de seguridad"
        title="Configuración de seguridad"
        className="onboarding-banner"
      />
      <Card isSlim className="onboarding-card" isForm>
        <Card.Row>
          <p>
            {!codeSended
              ? 'Tu seguridad es lo más importante, por eso es fundamental que mantengas actualizado tu número de celular. Proporciónanos tu teléfono celular a 10 dígitos:'
              : 'Ingresa el código de verificación que enviamos a tu número celular'}
          </p>
        </Card.Row>
        <Card.Row>
          <Controller
            name="phoneNumber"
            control={control}
            rules={{
              minLength: {
                value: 12,
                message: 'El número debe tener 10 dígitos'
              },
              required: 'Es un campo requerido.'
            }}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <InputField
                isDialCode
                onChangeInternalValue={(value) => onChange(value.toString().replace(/ /g, ''))}
                placeholder="Número de teléfono"
                minLength={12}
                errorMessages={error && error.message ? [error.message] : []}
                disabled={codeSended}
                {...rest}
              />
            )}
          />
        </Card.Row>
        {codeSended ? (
          <Card.Row>
            <Controller
              name="validationCode"
              control={control}
              rules={{
                minLength: {
                  value: 5,
                  message: 'El código de verificación debe ser por lo menos 5 dígitos'
                },
                required: 'Es un campo requerido.'
              }}
              render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                <InputField
                  onChangeInternalValue={(value) => onChange(value.toString().replace(/ /g, ''))}
                  type="number"
                  regex={/^\d{1,5}$/}
                  restrictRegex
                  errorRegexMessage="El código de verificación debe ser de 5 dígitos"
                  placeholder="Código de verificación"
                  errorMessages={error && error.message ? [error.message] : []}
                  {...rest}
                />
              )}
            />
          </Card.Row>
        ) : null}
      </Card>
      <FormActionButtons
        submitAction={handleSubmit(onSubmit)}
        submitButtonText={
          !codeSended ? 'Enviar código de verificación ' : 'Validar código de verificación'
        }
      />
    </PersonalInformationContainer>
  );
}
