import { API } from 'aws-amplify';
import { CreateBankAccount } from 'src/graphql/customized_mutations';

export const createNewBankAccountAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: CreateBankAccount,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const createApplicantNewBankAccountAPI = async (input) => {
  try {
    const { data } = await API.graphql({
      query: CreateBankAccount,
      variables: {
        input
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
