import styled from 'styled-components/macro';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { blackSphera, whiteSphera } from 'src/utilities/UIStyles/variables/colors';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';

export const Sponsors = styled.section`
  padding: 6% 0;

  > h2 {
    font-size: ${uIFontSize.title};
    color: ${blackSphera};
    text-align: center;
    margin-bottom: 2.5%;
  }

  > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 95%;
    gap: 2.5%;
    margin: auto;

    > a {
      > figure {
        background-color: ${whiteSphera};
        > img {
          width: 100%;
          height: 100%;
        }
      }
    }

    > a:nth-child(1) {
      width: 20%;
    }

    > a:nth-child(2) {
      width: 12%;
    }

    > a:nth-child(3) {
      width: 15%;
    }

    > a:nth-child(4) {
      width: 18%;
    }

    > a:nth-child(5) {
      width: 15%;
    }

    > a:nth-child(6) {
      width: 20%;
    }
  }

  @media (min-width: 2561px) {
    > h2 {
      font-size: 2.8rem;
    }
  }

  @media (max-width: 600px) {
    padding: 8% 0;

    > h2 {
      font-size: ${uIFontSize.title};
      margin-bottom: 5%;
    }
  }
`;
