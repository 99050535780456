import {
  createSlice,
  createAsyncThunk,
  createDraftSafeSelector,
  createAction
} from '@reduxjs/toolkit';
import {
  SignUserProps,
  SignProps,
  ErrorObject,
  ConfirmSignUpApplicantArgs,
  ConfirmSignUpInvestorArgs,
  SignOutAllResponse,
  IncrementalId,
  CreditAnalysisResponse,
  CreditCircleInput,
  UpdateOnboardingBlockingStatusProps
} from './user.interface';
import {
  createNewApplicant,
  createNewInvestor,
  udpateApplicantAPI,
  udpateInvestorAPI,
  internalIdAPI,
  udpateApplicantOnb,
  SatwsOnboardingAPI,
  udpateInvestorRisk,
  updateInvestorCompanyAPI,
  getCreditApprovedAPI,
  updateInvestorInfoAPI,
  trullyFirstStepAPI
} from '../../middlewares/API/User/user';
import {
  signUpApplicantAPI,
  signUpInvestorAPI,
  confirmSignupAPI,
  signInApplicantAPI,
  signInInvestorAPI,
  signInUserAPI,
  signOutAllDevices,
  signOut as signOutApi
} from '../../middlewares/Auth/index';

import { errorHandler } from 'src/middlewares/Auth/index.utilities';
import { RootState } from '../store';
import { reqSatCompany } from 'src/middlewares/API/SatWS';
import {
  circleCreditAPI,
  evidenceRisksAPI,
  literaxCreditAnalysisAPI,
  literaxCreditCircleAPI,
  literaxRisksAPI,
  literaxSatwsAPI,
  pdfInvestorGeneralContractAPI,
  simafReqAPI
} from 'src/middlewares/API/Onboarding';
import {
  createNewCampaignAPI,
  createObligadoSolidarioAPI
} from 'src/middlewares/API/Campaigns/campaign';
import {
  createApplicantNewBankAccountAPI,
  createNewBankAccountAPI
} from 'src/middlewares/API/Banks/bank';
import { UserState } from 'src/types/UserState';
import { createComplaintAPI } from 'src/middlewares/API/Profile/profile';
import { updateOnboardingBlockingStatusRequest } from 'src/requests';

//THUNKS
// APPLICANT THUNKS
export const signUpApplicant = createAsyncThunk(
  'signUpApplicant',
  async ({ username, password }: SignUserProps, { dispatch, rejectWithValue }) => {
    try {
      const userSub = await signUpApplicantAPI(username, password);
      return { userId: userSub, email: username, password: password };
    } catch (error: unknown) {
      if ((error as ErrorObject).code === 'UserAlreadyExists') {
        errorHandler(dispatch, error as ErrorObject);
      }
      return rejectWithValue((error as Error).message);
    }
  }
);

//El SignupSucess abarca el confirmSignUpApplicant y el signInApplicant
export const confirmSignUpApplicant = createAsyncThunk<
  { showSuccessModal: boolean },
  ConfirmSignUpApplicantArgs
>('confirmSignUpApplicant', async ({ user }, { dispatch, rejectWithValue }) => {
  const { id, email, password, isInMarketing, ip, geolocation, date, code } = user;
  let response: string;
  try {
    response = await confirmSignupAPI(email, code);
  } catch (error: unknown) {
    errorHandler(dispatch, error as ErrorObject);
    return rejectWithValue((error as Error).message);
  }
  try {
    await createNewApplicant({
      id,
      email,
      password,
      isInMarketing,
      ip,
      geolocation,
      date
    });
  } catch (error) {
    console.error('Error confirmSignUpApplicant', { error });
  }
  return {
    showSuccessModal: true
  };
});

export const signInApplicant = createAsyncThunk(
  'signInApplicant ',
  async ({ email, password }: SignProps, { dispatch, rejectWithValue }) => {
    if (!email || !password) {
      return rejectWithValue('Email or password is empty');
    }
    try {
      const response = await signInApplicantAPI(email, password);
      return response;
    } catch (error: unknown) {
      errorHandler(dispatch, error as ErrorObject);
      return rejectWithValue((error as Error).message);
    }
  }
);

// INVESTOR THUNKS Authentication
export const signUpInvestor = createAsyncThunk(
  'signUpInvestor',
  async (
    { username, password, typeOfPerson = '' }: SignUserProps,
    { dispatch, rejectWithValue }
  ) => {
    try {
      const userSub = await signUpInvestorAPI(username, password);
      return { userId: userSub, email: username, password: password, typeOfPerson };
    } catch (error: unknown) {
      if ({ code: error }.code === 'UserAlreadyExists') {
        errorHandler(dispatch, error as ErrorObject);
      }
      return rejectWithValue((error as Error).message);
    }
  }
);

//El SignupSucess abarca el confirmSignUpInvestor y el signInInvestor
export const confirmSignUpInvestor = createAsyncThunk<
  { showSuccessModal: boolean },
  ConfirmSignUpInvestorArgs
>('confirmSignUpInvestor', async ({ user }, { dispatch, rejectWithValue }) => {
  const { id, email, password, isInMarketing, geolocation, ip, date, code, typeOfPerson } = user;
  let response: string;
  try {
    response = await confirmSignupAPI(email, code);
  } catch (error: unknown) {
    errorHandler(dispatch, error as ErrorObject);
    return rejectWithValue((error as Error).message);
  }
  try {
    await createNewInvestor({
      id,
      typeOfPerson,
      email,
      password,
      isInMarketing,
      ip,
      geolocation,
      date
    });
  } catch (error) {
    errorHandler(dispatch, error as ErrorObject);
    return rejectWithValue((error as Error).message);
  }
  return {
    showSuccessModal: true
  };
});

export const signInInvestor = createAsyncThunk(
  'signInInvestor',
  async ({ email, password }: SignProps, { dispatch, rejectWithValue }) => {
    if (!email || !password) {
      return rejectWithValue('Email or password is empty');
    }
    try {
      const response = await signInInvestorAPI(email, password, dispatch);
      return response;
    } catch (error: unknown) {
      errorHandler(dispatch, error as ErrorObject);
      return rejectWithValue((error as Error).message);
    }
  }
);

export const signInUser = createAsyncThunk(
  'signInUser',
  async ({ email, password, isRetrievingUser }: SignProps, thunkAPI) => {
    try {
      const response = isRetrievingUser
        ? await signInUserAPI('', '', true)
        : await signInUserAPI(email, password);

      if (response.onboarding === null) {
        delete response.onboarding;
      }
      thunkAPI.dispatch(setUserGroup(response.groupName));
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue((error as Error).message);
    }
  }
);

export const signOutAll = createAsyncThunk<SignOutAllResponse>('signOutAll', async () => {
  const response = await signOutAllDevices();
  return { success: response };
});

export const signOut = createAsyncThunk('signOut', async () => {
  const response = await signOutApi();

  return { success: response };
});

// Updates para IP en Login de usuarios
export const updateApplicant = createAsyncThunk(
  'updateApplicant',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await udpateApplicantAPI(input);
      return { user: response.data };
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateInvestor = createAsyncThunk(
  'updateInvestor',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await udpateInvestorAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

// Thunk para crear id incremental interno
export const incrementalId = createAsyncThunk(
  'createIncrementalId',
  async ({ id, typeOfUser }: IncrementalId, { rejectWithValue }) => {
    try {
      const response = await internalIdAPI({ id, typeOfUser });
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

// Thunk para first step Trully
export const trullyFirstStep = createAsyncThunk(
  'trullyFirstStep',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await trullyFirstStepAPI(body);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

////// Onboarding //////////////////////////////////////////////////////////////
//THUNK SATWS
export const satwsInfo = createAsyncThunk(
  'satwsInfo',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (body: any, { rejectWithValue }) => {
    try {
      const response = await reqSatCompany(body);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK Update Onboarding Applicant
export const updateApplicantOnboarding = createAsyncThunk(
  'updateApplicantOnboarding',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await udpateApplicantOnb(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateInvestorCompany = createAsyncThunk(
  'updateInvestorCompany',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (updateInput: any, { rejectWithValue }) => {
    try {
      const response = await updateInvestorCompanyAPI(updateInput);
      return response;
    } catch (error: unknown) {
      rejectWithValue((error as Error).message);
      throw new Error((error as Error).message);
    }
  }
);

export const updateInvestorRisk = createAsyncThunk(
  'updateInvestorRisk',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (updateInput: any, { rejectWithValue }) => {
    try {
      const response = await udpateInvestorRisk(updateInput);
      return response;
      //return { user: response.data };
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateInvestorInformation = createAsyncThunk(
  'updateInvestorInformation',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (updateInput: any, { rejectWithValue }) => {
    try {
      const response = await updateInvestorInfoAPI(updateInput);
      return response;
    } catch (error) {
      rejectWithValue((error as Error).message);
      throw new Error((error as Error).message);
    }
  }
);

//THUNK CREDIT ANALYSIS 1ST PART
export const creditAnalysis = createAsyncThunk<
  CreditAnalysisResponse,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  { rejectValue: string }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
>('creditAnalysis', async (body: any, { rejectWithValue }) => {
  try {
    const response = await SatwsOnboardingAPI(body);
    const approved = await getCreditApprovedAPI(body);
    return approved;
  } catch (error: unknown) {
    return rejectWithValue((error as Error).message);
  }
});

//THUNK RIESGOS 1ER ENDPOINT
export const evidenceRisks = createAsyncThunk(
  'evidenceRisks',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await evidenceRisksAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK RIESGOS LITERAX ENDPOINT
export const literaxRisks = createAsyncThunk(
  'literaxRisks',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await literaxRisksAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK RIESGOS 2DO ENDPOINT YA NO SERÁ NECESARIO

//THUNK RIESGOS 3er ENDPOINT CREAR CONTRATO
export const createGeneralContract = createAsyncThunk(
  'createGeneralContract',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (generalContract: any, { rejectWithValue }) => {
    try {
      const response = await pdfInvestorGeneralContractAPI(generalContract);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK APPLICANT CÍRCULO DE CRÉDITO
export const creditCircle = createAsyncThunk(
  'creditCircle',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async ({ inputCreditAutofill, inputGovernance }: CreditCircleInput, { rejectWithValue }) => {
    try {
      const response = await circleCreditAPI(inputCreditAutofill, inputGovernance);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK APPLICANT SATWS PDF'S A S3 LITERAX ENDPOINT
export const literaxSatws = createAsyncThunk(
  'literaxSatws',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await literaxSatwsAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK APPLICANT ANÁLISIS DE CRÉDITO LITERAX ENDPOINT
export const literaxCreditAnalysis = createAsyncThunk(
  'literaxCreditAnalysis',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await literaxCreditAnalysisAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK APPLICANT CÍRCULO DE CRÉDITO LITERAX ENDPOINT
export const literaxCreditCircle = createAsyncThunk(
  'iteraxCreditCircle',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await literaxCreditCircleAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK SIMAF IDS
export const simafReq = createAsyncThunk(
  'simafReq',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await simafReqAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//
export const createNewCampaign = createAsyncThunk(
  'createCampaign',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createNewCampaignAPI(input);
      return response;
    } catch (error: unknown) {
      rejectWithValue((error as Error).message);
      throw new Error((error as Error).message);
    }
  }
);

//BUZÓN DE QUEJAS, DUDAS, ETC. DESDE ACCOUNT
export const createComplaint = createAsyncThunk(
  'createComplaint',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createComplaintAPI(input);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  }
);

//THUNK CREAR CUENTA BANCARIA APPLICANT E INVESTOR
export const createBankAccount = createAsyncThunk(
  'createBankAccount',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createNewBankAccountAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK CREAR CUENTA BANCARIA APPLICANT E INVESTOR
export const createApplicantBankAccount = createAsyncThunk(
  'createBankAccount',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createApplicantNewBankAccountAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//THUNK CREAR CUENTA OBLIGADO SOLIDARIO
export const createObligadoSolidario = createAsyncThunk(
  'createObligadoSolidario',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async (input: any, { rejectWithValue }) => {
    try {
      const response = await createObligadoSolidarioAPI(input);
      return response;
    } catch (error: unknown) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateOnboardingBlockingStatus = createAsyncThunk(
  'updateOnboardingBlockingStatus',
  async ({ type, input }: UpdateOnboardingBlockingStatusProps) => {
    try {
      const response = await updateOnboardingBlockingStatusRequest(type, input);

      return response;
    } catch (error: unknown) {
      throw new Error((error as Error).message);
    }
  }
);

export const setUserGroup = createAction<string>('setUserGroup');

const userSlice = createSlice({
  name: 'user',
  initialState: {
    //Onboarding
    onboardingFinished: false,
    currentStep: {},
    creditAnalysisLoading: false,
    isApproved: '',
    //
    currentUser: {
      onboarding: {
        complete: false,
        currentStep: undefined
      }
    },
    isSignedIn: false,
    loading: {
      isLoading: true,
      message: ''
    },
    showErrorModal: false,
    showSuccessModal: false,
    errorType: {},
    id: '',
    typeOfPerson: '',
    dataNeeded: '',
    email: '',
    password: '',
    userGroup: '',
    users: []
  } as unknown as UserState,
  reducers: {
    setLoggedUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setLoadingState: (state, action) => {
      state.loading.isLoading = action.payload;
    },
    setLoadingMessage: (state, action) => {
      state.loading.message = action.payload;
    },
    resetState: () => {
      return;
    },
    setTypeOfPerson: (state, action) => {
      state.typeOfPerson = action.payload;
    },
    openErrorModal: (state, action) => {
      state.showErrorModal = true;
      state.errorType = action.payload;
    },
    closeErrorModal: (state) => {
      state.errorType = {};
      state.showErrorModal = false;
    },
    openSucessModal: (state) => {
      state.showSuccessModal = true;
    },
    closeSuccessModal: (state) => {
      state.showSuccessModal = false;
    },
    setOnboardingFinished: (state, action) => {
      state.currentUser.onboarding = {
        ...state.currentUser.onboarding,
        complete: action.payload
      };
    },
    setCurrentStep: (state, action) => {
      state.currentUser.onboarding = {
        ...state.currentUser.onboarding,
        currentStep: action.payload
      };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signInApplicant.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
      state.isSignedIn = true;
    });
    builder.addCase(signInApplicant.rejected, (state) => {
      state.isSignedIn = false;
    });
    builder.addCase(signInInvestor.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
      state.loading.isLoading = false;
      state.isSignedIn = true;
    });
    builder.addCase(signInInvestor.rejected, (state) => {
      state.loading.isLoading = false;
    });
    builder.addCase(updateInvestorInformation.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(signInUser.rejected, (state) => {
      state.isSignedIn = false;
    });
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
      state.typeOfPerson = action.payload.typeOfPerson;
      state.email = action.payload.email;
      state.isSignedIn = true;
    });
    builder.addCase(signUpApplicant.fulfilled, (state, action) => {
      state.id = action.payload.userId;
      state.showSuccessModal = true;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(signUpInvestor.pending, (state) => {
      state.showSuccessModal = true;
    });
    builder.addCase(signUpInvestor.rejected, (state) => {
      state.isSignedIn = false;
      state.showSuccessModal = false;
    });
    builder.addCase(signUpInvestor.fulfilled, (state, action) => {
      state.id = action.payload.userId;
      state.showSuccessModal = true;
      state.email = action.payload.email;
      state.password = action.payload.password;
      state.typeOfPerson = action.payload.typeOfPerson;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(confirmSignUpApplicant.rejected, (state) => {
      state.isSignedIn = false;
      return;
    });
    builder.addCase(confirmSignUpApplicant.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
      state.isSignedIn = true;
      // state.id = action.payload.userId;
    });

    builder.addCase(confirmSignUpInvestor.rejected, (state) => {
      state.isSignedIn = false;
      return;
    });
    builder.addCase(confirmSignUpInvestor.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
      state.isSignedIn = true;
      // state.id = action.payload.userId;
    });
    builder.addCase(signOutAll.pending, (state) => {
      // state.loading.isLoading = true;
    });
    builder.addCase(signOutAll.rejected, (state) => {
      state.isSignedIn = true;
      // state.loading.isLoading = false;
    });
    builder.addCase(signOutAll.fulfilled, (state) => {
      state.isSignedIn = false;
      state.users = [];
      state.currentUser = {};
      state.id = '';
      state.dataNeeded = '';
      state.email = '';
      state.password = '';
      state.showSuccessModal = false;
      // state.loading.isLoading = false;
    });
    builder.addCase(signOut.rejected, (state) => {
      state.isSignedIn = true;
      // state.loading.isLoading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      state.isSignedIn = false;
      state.users = [];
      state.currentUser = {};
      state.id = '';
      state.dataNeeded = '';
      state.email = '';
      state.password = '';
      state.showSuccessModal = false;
      // state.loading.isLoading = false;
    });
    builder.addCase(updateApplicant.fulfilled, (state, action) => {
      state.loading.isLoading = false;
      state.isSignedIn = true;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(updateInvestor.fulfilled, (state, action) => {
      state.loading.isLoading = false;
      state.isSignedIn = true;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(creditAnalysis.pending, (state) => {
      state.creditAnalysisLoading = true;
    });
    builder.addCase(creditAnalysis.fulfilled, (state, action) => {
      state.creditAnalysisLoading = false;
      state.currentUser = {
        ...state.currentUser,
        ...action.payload.response
      };
      // state.isApproved = action.payload.response;
    });
    builder.addCase(creditAnalysis.rejected, (state) => {
      state.creditAnalysisLoading = false;
    });
    builder.addCase(createNewCampaign.pending, (state) => {
      state.loading.isLoading = true;
    });
    builder.addCase(createNewCampaign.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(createBankAccount.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
    builder.addCase(setUserGroup, (state, action) => {
      state.userGroup = action.payload;
    });
    builder.addCase(updateOnboardingBlockingStatus.fulfilled, (state, action) => {
      state.currentUser = {
        ...state.currentUser,
        ...action.payload
      };
    });
  }
});

export const {
  setLoggedUser,
  setLoadingState,
  setLoadingMessage,
  resetState,
  setTypeOfPerson,
  openErrorModal,
  closeErrorModal,
  openSucessModal,
  closeSuccessModal,
  setOnboardingFinished,
  setCurrentStep
} = userSlice.actions;

export default userSlice.reducer;

const selectSelf = (state: RootState) => state;

export const userSelector = createDraftSafeSelector(selectSelf, (state) => state.user);
export const loadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.loading.isLoading
);
export const signInSelector = createDraftSafeSelector(selectSelf, (state) => state.user.isSignedIn);
export const selectIsApproved = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.isApproved
);
export const loadingMessageSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.user.loading.message
);

//Navbar added
export const userGroupSelector = (state: RootState) => state.user.userGroup;
