import styled, { css, FlattenSimpleInterpolation } from 'styled-components/macro';
import { blackSphera, whiteSphera, brandYellowgreen } from '../../../UIStyles/variables/colors';
import { uIFontSize } from '../../../UIStyles/variables/typography';
import { ButtonProps } from './button.interface';

const sizes = ({
  size = 'medium',
  isSlim = false,
  isFullWidth
}: ButtonProps): FlattenSimpleInterpolation => {
  const sizes = {
    small: css`
      width: ${isFullWidth ? '100%' : '10.625rem'};
      font-size: ${uIFontSize.small};
      height: ${isSlim ? '2rem' : '2.5rem'};
    `,
    medium: css`
      width: ${isFullWidth ? '100%' : '15.625rem'};
      height: ${isSlim ? '2.5rem' : '3.125rem'};
      font-size: ${uIFontSize.medium};
    `,
    large: css`
      width: ${isFullWidth ? '100%' : '20.625rem'};
      height: ${isSlim ? '3.125rem' : '3.75rem'};
      font-size: ${uIFontSize.large};
    `
  };

  return sizes[size];
};

const colorStyles = {
  primary: css`
    background-color: ${blackSphera};
    color: ${whiteSphera};
  `,
  secondary: css`
    background-color: ${whiteSphera};
    color: ${blackSphera};
    border: 2px solid ${blackSphera};
  `,
  green: css`
    background-color: ${brandYellowgreen};
    color: ${blackSphera};
  `
};

const disabledOrLoading = ({ disabled, isLoading }: ButtonProps): FlattenSimpleInterpolation => {
  if (disabled || isLoading) {
    return css`
      filter: grayscale(100%) opacity(0.5);
      pointer-events: none;
    `;
  }
  return css``;
};

export const ButtonContainer = styled.button<ButtonProps>`
  ${sizes}
  ${disabledOrLoading}

  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  border: 0;

  ${({ color = 'primary' }) => colorStyles[color] || colorStyles.primary}

  &:hover {
    cursor: pointer;
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
