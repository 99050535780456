import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { colors } from 'src/utilities/UILibrary2024/stylesTokens';
import { breakpoints } from 'src/utilities/UIStyles/variables/breakpoints';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';
import { NavbarProps } from './types';

const isTransparentStyles = ({ isTransparent }: NavbarProps): FlattenSimpleInterpolation => {
  if (isTransparent) {
    return css`
      backdrop-filter: blur(30px);
      -webkit-backdrop-filter: blur(30px);
      background-color: rgba(${colors.background.navbar}, 0.7);
    `;
  }
  return css`
    background-color: ${colors.background.navbar};
  `;
};

export const NavbarContainer = styled.header<NavbarProps & { show: boolean }>`
  transform: ${({ show }) => (show ? 'translateY(0)' : 'translateY(-100%)')};
  transition: transform 0.3s ease-in-out;

  display: flex;
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  height: 100px;

  ${isTransparentStyles}

  .navbar-left {
    display: flex;
    align-items: center;

    .logo {
      width: 120px;
    }
  }

  .navbar-right {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      color: ${colors.text.gray};
      font-size: 1rem;
      text-align: center;

      &.active {
        border-bottom: 2px solid #000;
      }
    }

    a:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 1.75rem 3rem;
    gap: 3rem;

    .navbar-right {
      gap: 2rem;

      ul {
        display: flex;
        gap: 3rem;
        list-style: none;
        margin: 0;
        padding: 0;
        align-items: center;
      }
    }

    .logo {
      width: 150px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    .navbar-right {
      a {
        font-size: ${uIFontSize.large};
      }

      button {
        font-size: ${uIFontSize.large};
      }
    }

    padding: 1.75rem 6.25rem;
  }
`;
