import styled from 'styled-components/macro';
import {
  brandBlack,
  grayLightSphera,
  grayPlaceholder,
  whiteSphera,
  brandYellowgreen
} from 'src/utilities/UIStyles/variables/colors';
import { uIFontSize } from 'src/utilities/UIStyles/variables/typography';

export const PersonalInformationContainer = styled.section`
  background-color: rgb(245, 245, 245);
  padding-bottom: 2.5%;

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    padding-bottom: 2.5%;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${whiteSphera};
  padding: 3% 8%;

  > figure {
    width: 85px;
    height: 85px;
    margin-right: 5%;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  > h2 {
    color: ${brandBlack};
    font-size: 2.5vw;
    width: 30%;
  }

  > p {
    color: ${brandBlack};
    font-size: 1.5vw;
    width: 50%;
  }

  @media (min-width: 600px) and (max-width: 1024px) {
    > figure {
      > img {
      }
    }

    > h2 {
    }

    > p {
    }
  }

  @media (max-width: 600px) {
    justify-content: center;
    flex-direction: column;
    padding: 2.5% 0 2.5% 0%;

    > figure {
      > img {
      }
    }

    > h2 {
      font-size: 6vw;
      width: 100%;
      margin-right: 0;
      margin-bottom: 5%;
      text-align: center;
    }

    > p {
      text-align: center;
      font-size: 4vw;
      width: 90%;
      margin-bottom: 5%;
    }
  }
`;

export const Options = styled.div`
  display: flex;
  width: 90%;
  margin: 5% auto 0 auto;
  -webkit-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
`;

export const Option = styled.div`
  font-size: clamp(${uIFontSize.small}, 1.2vw, ${uIFontSize.large});
  color: ${(props) => (props.isSelected ? brandBlack : whiteSphera)};
  text-align: center;
  width: 20%;
  padding: 1.5% 0.5%;
  background-color: ${(props) => (props.isSelected ? brandYellowgreen : brandBlack)};
  cursor: pointer;
  &:hover {
    background-color: ${brandYellowgreen};
    color: ${brandBlack};
  }
  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const Annexes = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5% 2.5% 2.5% 5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  > div {
    display: flex;
    margin-bottom: 2.5%;

    > div {
      width: 33%;

      > p {
        color: ${brandBlack};
        font-size: 1.5vw;
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const Contracts = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5% 2.5% 2.5% 5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  > div {
    display: flex;
    margin-bottom: 2.5%;

    > div {
      width: 33%;

      > p {
        color: ${brandBlack};
        font-size: 1.5vw;
        width: 100%;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const AccountState = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5% 2.5% 2.5% 5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 2.5%;

    > div {
      width: 33%;

      > p {
        color: ${brandBlack};
        font-size: 1.5vw;
        width: 100%;
      }

      > select {
        color: ${grayPlaceholder};
        font-size: 1.2vw;
        width: 80%;
        height: 35px;
        margin-top: 2%;
        margin-bottom: 2%;
        padding-left: 2.5%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }
    }
  }

  > div:nth-child(2) {
    > p {
      color: ${brandBlack};
      font-size: 1.5vw;
      width: 100%;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > select {
        color: ${grayPlaceholder};
        font-size: 1.2vw;
        width: 26%;
        height: 35px;
        margin: 2% 7% 2% 0;
        padding-left: 1%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }

      > button:nth-child(2) {
        font-size: 1.2vw;
        font-weight: bold;
        color: ${whiteSphera};
        width: 15%;
        padding: 0.6%;
        border: none;
        border-radius: 5px;
        background-color: ${brandBlack};
        cursor: pointer;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;

export const Invoice = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 2.5% 2.5% 2.5% 5%;
  background-color: ${whiteSphera};
  border-radius: 5px;
  -webkit-box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 2.5%;

    > div {
      width: 33%;

      > p {
        color: ${brandBlack};
        font-size: 1.5vw;
        width: 100%;
      }

      > select {
        color: ${grayPlaceholder};
        font-size: 1.2vw;
        width: 80%;
        height: 35px;
        margin-top: 2%;
        margin-bottom: 2%;
        padding-left: 2.5%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }
    }
  }

  > div:nth-child(2) {
    > p {
      color: ${brandBlack};
      font-size: 1.5vw;
      width: 100%;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > select {
        color: ${grayPlaceholder};
        font-size: 1.2vw;
        width: 26%;
        height: 35px;
        margin: 2% 7% 2% 0;
        padding-left: 1%;
        border: 1px solid ${grayLightSphera};
        border-radius: 5px;
      }

      > button:nth-child(2) {
        font-size: 1.2vw;
        font-weight: bold;
        color: ${whiteSphera};
        width: 15%;
        padding: 0.6%;
        border: none;
        border-radius: 5px;
        background-color: ${brandBlack};
        cursor: pointer;
      }
    }
  }

  @media (min-width: 600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }
`;
