import styled from 'styled-components/macro';
import { spaceFlowContainer } from 'src/utilities/UIStyles/variables/spacing';
import {
  brandBlack,
  brandYellowgreen,
  grayDarkSphera,
  graySphera,
  greenSp,
  whiteSphera
} from 'src/utilities/UIStyles/variables/colors';

export const EsgInformationForm = styled.div`
  padding-bottom: 2%;
  width: 80%;
  flex-wrap: wrap;
  margin: 2% 10%;
  justify-content: space-between;
  border-bottom: 2px solid ${graySphera};
  /* background-color: ${whiteSphera}; */
  > h4 {
    color: ${grayDarkSphera};
    font-size: 1.2vw;
    text-align: left;
    width: 90%;
    margin-bottom: 2%;
  }
  > figure {
    > img {
      width: 25px;
      height: auto;
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > h4 {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    width: 90%;
    > h4 {
      font-size: 3.5vw;
    }
    > figure {
      > img {
        width: 15px;
        height: auto;
      }
    }
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  > div {
    width: 100%;
    display: flex;
    margin-bottom: 1%;
    > p {
      width: 80%;
      font-size: 1.5vw;
      > span {
        font-weight: bold;
        font-size: 1.5vw;
      }
    }
  }
  @media (min-width: 600px) and (max-width: 1024px) {
    > div {
      > p {
        font-size: 1.8vw;
        > span {
          font-size: 1.8vw;
        }
      }
    }
  }

  @media (max-width: 600px) {
    > div {
      > p {
        font-size: 3.5vw;
        > span {
          font-size: 3.5vw;
        }
      }
    }
  }
`;
